import { IonButton, IonItem, IonLabel, IonText, IonToggle, ToggleChangeEventDetail } from '@ionic/react';
import { IonToggleCustomEvent } from '@ionic/core';
import { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { ISupportGroupKey } from '../caremap/ICareMap';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { DrawerPage } from '../components/DrawerPage';
import { DrawerSectionHeading } from '../components/DrawerSectionHeading';
import { DeleteSupportGroupButton } from './DeleteSupportGroupButton';
import { SupportGrouIconProvider } from '../caremap/SupportGroupIconProvider';
import { ShortContactMethodsList } from '../contact-methods/ShortContactMethodsList';
import { NotesList } from '../notes/NotesList';
import { NudgeBanner } from '../components/NudgeBanner';
import { useBreakpoints } from '../theme/useBreakpoints';
import { DrawerListSection } from '../components/DrawerListSection';
import { AppointmentsList } from '../appointments/AppointmentsList';
import { AppointmentsState, NEW_APPOINTMENT_ID } from '../appointments/AppointmentsState';
import { Checklists } from '../checklists/Checklists';
import { HelpCenterState } from '../support/help-center/HelpCenterState';
import { HelpCenterArticleList } from '../support/help-center/HelpCenterArticleList';
import { AsyncStorageProvider } from '../support/AsyncStorageProvider';
import { useAsyncEffect } from '../support/useAsyncEffect';

const useStyles = createUseStyles({
    iconContainer: {
        marginTop: 10,
        marginBottom: 10
    },

    memberLabel: {
        lineHeight: '1.3em'
    },

    memberTitle: {
        fontWeight: 500
    },

    memberInfo: {
        display: 'block',
        fontSize: '0.95em',
        color: 'var(--ion-color-step-750)'
    }
});

export interface ISupportGroupMemberList {
    supportGroupKey: ISupportGroupKey
};

// currently doesn't use IonList even though List is in the name
export const SupportGroupMemberList: FC<ISupportGroupMemberList> = ({ supportGroupKey }) => {
    const classes = useStyles();

    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);

    const iconProvider = useInstance(SupportGrouIconProvider);

    const history = useInstance(HistoryViewModel);
    const appointmentsState = useInstance(AppointmentsState);

    const helpCenterState = useInstance(HelpCenterState);
    useSubscription(() => helpCenterState);

    const storageProvider = useInstance(AsyncStorageProvider);

    const {isBelowBreakpoint} = useBreakpoints();

    const [hideDoneChecklistItems, setHideDoneChecklistItems] = useState<boolean>(false);

    useEffect(() => {
        helpCenterState.load();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const hideDoneStorageKey = `${supportGroupKey}-hide-done-checklists`;
    useAsyncEffect(async () => {
        const hideDone = await storageProvider.getBooleanProvider(hideDoneStorageKey).get();
        setHideDoneChecklistItems(hideDone || false);
    }, [storageProvider]);

    const onAdd = () => {
        history.push(`/caremaps/${careMapStateInstance.getActiveCaremap()?.careMapId}/drawer/support-group/${supportGroupKey}/add-member`);
    };

    const viewMoreNotes = () => {
        history.push(`/caremaps/${careMapStateInstance.getActiveCaremap()?.careMapId}/drawer/support-group/${supportGroupKey}/notes`);
    };

    const editNote = (noteId?: string) => {
        history.push(`/caremaps/${careMapStateInstance.getActiveCaremap()?.careMapId}/drawer/support-group/${supportGroupKey}/notes/${noteId}`);
    };

    const viewMoreAppointments = () => {
        history.push(`${history.getCurrentLocation().pathname}/appointments`);
    };

    const editAppointment = (appointmentId?: string) => {
        if (appointmentId === NEW_APPOINTMENT_ID) {
            appointmentsState.trackNewAppointmentOpened({ supportGroupKey });
        }

        history.push(`${history.getCurrentLocation().pathname}/appointments/${appointmentId}`);
    };

    const viewMoreArticles = () => {
        history.push(`${history.getCurrentLocation().pathname}/help-center/articles`);
    };

    const labels = careMapStateInstance.getSupportGroupLabels(supportGroupKey);
    const { color, MemberIcon } = iconProvider.getSupportGroupIcons(supportGroupKey);

    const canAdd = (careMapStateInstance.getActiveCaremap()?.links.self.actions.includes('add-support'));
    const canDelete = careMapStateInstance.canDeleteSupportGroup(supportGroupKey);

    const buttons = <>
        {canAdd && (
            <IonButton data-type="add-member-button" onClick={onAdd}>
                + Add {canDelete && isBelowBreakpoint('sm') ? labels.shortMemberTitle : labels.memberTitle}
            </IonButton>
        )}
        {canDelete && (
            <DeleteSupportGroupButton supportGroupKey={supportGroupKey} />
        )}
    </>;

    const listShowCount = 2;
    const members = careMapStateInstance.getSupportGroupMembers(supportGroupKey);
    const showNameFirst = careMapStateInstance.shouldShowNameFirst(supportGroupKey);

    const checkLists = careMapStateInstance.getCareMap()[supportGroupKey].checklists;
    const hasChecklists = checkLists.length > 0;

    const collections = helpCenterState.getCollectionsAndArticlesForCategory(supportGroupKey);
    const hasArticles = helpCenterState.hasArticles(collections);

    return (
        <DrawerPage title={labels.title} buttons={buttons}>

            {hasChecklists && (
                <DrawerListSection underDrawerTitle>
                    <DrawerSectionHeading text="Checklist">
                        <IonToggle
                            style={{fontSize: 14}}
                            data-type="hide-done-toggle"
                            checked={hideDoneChecklistItems}
                            onIonChange={(e: IonToggleCustomEvent<ToggleChangeEventDetail<any>>) => {
                                setHideDoneChecklistItems(e.detail.checked);
                                storageProvider.getBooleanProvider(hideDoneStorageKey).set(e.detail.checked);
                            }}
                        >
                            Hide Completed
                        </IonToggle>
                    </DrawerSectionHeading>
                    <Checklists
                        checklists={checkLists}
                        hideDone={hideDoneChecklistItems}
                        supportGroupKey={supportGroupKey}
                    />
                </DrawerListSection>
            )}

            <DrawerListSection underDrawerTitle={!hasChecklists && !hasArticles}>
                <NotesList
                    showCount={listShowCount}
                    editNoteHandler={editNote}
                    moreNotesHandler={viewMoreNotes}
                    notes={careMapStateInstance.getCareMap()[supportGroupKey].notes}
                />
            </DrawerListSection>

            <DrawerListSection>
                <AppointmentsList
                    showViewMore
                    numDaysToShow={1}
                    showCount={listShowCount}
                    minDate={(new Date()).toISOString()}
                    supportGroupKey={supportGroupKey}
                    editAppointment={editAppointment}
                    viewMore={viewMoreAppointments}
                    data-type="appointments-list"
                />
            </DrawerListSection>

            {hasArticles && (
                <DrawerListSection data-id="articles-section">
                    <DrawerSectionHeading text="Help Articles" />
                    <HelpCenterArticleList
                        collections={collections}
                        viewMore={viewMoreArticles}
                        numCollectionsToShow={1}
                        numArticlesToShow={2}
                        supportGroupKey={supportGroupKey}
                    />
                </DrawerListSection>
            )}

            <div data-type="member-list">
                <div style={{paddingInlineStart: 5}}>
                    <DrawerSectionHeading text={`${labels.memberTitle}s`} />
                </div>
                <div>
                    {!members.length && careMapStateInstance.hasPermissionTo('put') &&
                        <NudgeBanner
                            buttonLabel="Add"
                            buttonClickHandler={onAdd}
                            message={`Enhance your care map by adding ${labels.memberTitle.toLowerCase()}s`}
                        />
                    }
                    {members.map(member => {
                        const title = (showNameFirst ? member.name : member.role);
                        const subtitle = (showNameFirst ? member.role : member.name);

                        return (
                            <IonItem
                                button
                                detail
                                key={member.id}
                                onClick={() => history.push(`/caremaps/${careMapStateInstance.getActiveCaremap()?.careMapId}/drawer/support-group/${supportGroupKey}/member/${member.id}`)}
                            >
                                <div slot="start" className={classes.iconContainer}>
                                    <MemberIcon size="xlarge" shape="round" color={color} />
                                </div>
                                <IonLabel className={classes.memberLabel}>
                                    <div>
                                        <div data-type="title" className={classes.memberTitle}>{title || ''}</div>
                                        <IonText data-type="detail1" className={classes.memberInfo}>{subtitle || ''}</IonText>
                                        <IonText data-type="detail2" className={classes.memberInfo}>{member.organization}</IonText>
                                    </div>
                                    <ShortContactMethodsList
                                        contactMethods={member.contactMethods}
                                        address={member.address}
                                    />
                                </IonLabel>
                            </IonItem>
                        );
                    })}
                </div>
            </div>
        </DrawerPage>
    );
};
