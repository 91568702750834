import { IonText } from '@ionic/react';

// simulate ionic label behavior
export const Label = ({ text, stacked, color, disabled }: { text: string, stacked: boolean, color: string, disabled: boolean }) => {
    return (
        <IonText
            style={{
                zIndex: 1,
                position: 'absolute',
                width: stacked ? undefined : '100%',
                top: stacked ? -7 : 19,
                left: stacked ? 14 : 17,
                paddingLeft: stacked ? 4 : 0,
                paddingRight: stacked ? 4 : 0,
                fontSize: stacked ? 12 : 16,
                backgroundColor: stacked ? 'white' : undefined,
                color,
                transition: 'top 0.15s, font-size 0.1s',
                pointerEvents: 'none'
            }}
        >
            {text}
            {disabled && (
                <div style={{
                    position: 'absolute',
                    top: 7,
                    width: '100%',
                    left: 0,
                    height: 1,
                    background: 'white',
                    zIndex: -1
                }}>&nbsp;</div>
            )}
        </IonText>
    );
};
