import { ReactFlowProvider } from 'reactflow';
import { CareMapFlow } from './CareMapFlow';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { ICareMap, ISupportGroupKey } from './ICareMap';
import { CareMapDrawerState } from '../caremap/CareMapDrawerState';
import { useSubscription } from '@meraki-internal/state';
import { FocusedCareMapProvider } from './FocusedCareMapProvider';
import { CareMapState } from './CareMapState';
import { AvatarFormState } from '../avatar/AvatarFormState';
import { MemberFormState } from '../avatar/MemberFormState';
import { PatientFormState } from '../avatar/PatientFormState';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    focusedMapWrapper: {
        width: '100%',
        height: '100%',
    },

    '@keyframes zoom-in': {
        '0%': {
            position: 'absolute',
            transform: 'scale(0, 0)'
        },
        '100%': {
            top: 0,
            left: 0,
            position: 'relative',
            transform: 'scale(1, 1)'
        }
    },

    '@keyframes zoom-out': {
        '0%': {
            top: 0,
            left: 0,
            position: 'absolute',
            transform: 'scale(1, 1)'
        },
        '100%': {
            position: 'relative',
            transform: 'scale(0, 0)'
        }
    },

    animateIn: {
        animation: '$zoom-in ease 0.5s'
    },

    animateOut: {
        animation: '$zoom-out ease 0.5s'
    }
});

export type IFocusedCareMapAnimationOrigin = {
    x: number;
    y: number;
};

export const FocusedCareMap: React.FC<{
    onDismiss: () => void;
    focusOn: {supportGroupKey?: ISupportGroupKey;};
    // TODO: this tells it to animate in vs out. maybe should have values?
    //  animationDirection = 'in | 'out'
    animate: boolean;
    animateOrigin?: IFocusedCareMapAnimationOrigin;
}> = ({
    onDismiss,
    focusOn,
    animate,
    animateOrigin
}) => {
    const classes = useStyles();

    const drawerState = useInstance(CareMapDrawerState);
    useSubscription(() => drawerState);
    const focusedCareMapProvider = useInstance(FocusedCareMapProvider);

    const careMapState = useInstance(CareMapState);
    useSubscription(() => careMapState);

    const avatarState = useInstance(AvatarFormState);
    useSubscription(() => avatarState);

    const memberFormState = useInstance(MemberFormState);
    useSubscription(() => memberFormState);

    const patientFormState = useInstance(PatientFormState);
    useSubscription(() => patientFormState);

    if (!careMapState.state){
        return null;
    }

    const focusedCareMap = focusedCareMapProvider.getFocusedCareMap({
        careMap: careMapState.getActiveCaremap() as ICareMap,
        avatarForm: avatarState.state,
        focusOn,
        memberForm: memberFormState.state.member,
        patientForm: patientFormState.state.patient
    });

    return (
        <ReactFlowProvider>
            <div
                style={{
                    top: animateOrigin ? animateOrigin.y - (!animate ? 30 : 0) : undefined,
                    left: animateOrigin ? animateOrigin.x : undefined
                }}
                className={`${classes.focusedMapWrapper} ${animate ? classes.animateIn : classes.animateOut}`}
            >
                <CareMapFlow
                    name="focus"
                    disablePan
                    disableZoom
                    maxZoom={1.2}
                    onDismiss={onDismiss}
                    fit="default"
                    careMap={focusedCareMap}
                    selectedItemId={drawerState.selectedMemberId}
                />
            </div>
        </ReactFlowProvider>
    );
};
