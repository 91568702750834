import { useIonViewDidEnter } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { MixPanelPageViewEventEmitter as MixpanelService } from './MixPanelPageViewEventEmitter';

// NOTE: this only works for pages that animate in and out of view
// for example it doesn't work for the /caremap for the initial render
// nor for when you "come back" to it (when the drawer closes)
export const TrackPageView: React.FC<{ page: string; if?: boolean; meta?: any; }> = ({ page, if: predicate = true, meta }) => {
    const tracking = useInstance(MixpanelService);

    // at first we used useEffect, which worked for the first render of everything
    // but this failed to fire when navigating from a list view to a detail view, because the
    // detail view wasn't mounting, it was simply animating back into view
    // then we switched to useIonViewDidEnter, which solved the list to details problem
    // but never fired for /caremap. So now this embraces the ionic router animating issue, and solved
    // the /caremap problem in `PageViewTracking`
    useIonViewDidEnter(() => {
        if (predicate){
            tracking.startPageView(page, () => meta || {});
        }
    }, [predicate, tracking, page]);

    return null;
};
