import { IonButton, IonInput, IonItem } from '@ionic/react';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { useClipboard } from '../utils/useClipboard';
import { CheckIcon } from '../theme/Icons';

const useStyles = createUseStyles({
    linkItem: {
        // margin needed to match height of linkButton
        marginTop: 2,

        '--background': 'var(--ion-color-step-50)',
        '--border-color': 'var(--ion-color-step-500, gray)',
        '--border-width': '1px',

        '&::part(native)': {
            borderRadius: 8,
            '--min-height': '40px'
        }
    },

    copyButton: {
        height: '100%',
        width: 85,
        marginLeft: 9,
        marginRight: -18,
        '--padding-top': 0,
        '--padding-bottom': 0,

        '&::part(native)': {
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8
        }
    }
});

export type ICopyTextButtonProps = {
    text: string;
    disabled?: boolean;
    'data-type'?: string;
    onTextCopied?: () => void;
};

export const CopyTextButton: FC<ICopyTextButtonProps> = ({ text, disabled = false, 'data-type': dataType, onTextCopied = () => {} }) => {
    const classes = useStyles();
    const [copyToClipboard, justCopied] = useClipboard();

    const onCopyClicked = () => {
        copyToClipboard({
            value: text,
            onCopied: onTextCopied
        });
    };

    return (
        <IonItem lines="none" className={classes.linkItem}>
            <IonInput
                readonly
                value={text}
                data-type={dataType}
                style={{minHeight: 'unset'}}
            />
            <IonButton
                slot="end"
                expand="full"
                size="default"
                disabled={disabled}
                data-type="copy-button"
                onClick={onCopyClicked}
                className={classes.copyButton}
            >
                {justCopied ? <CheckIcon color="light" shape="round" /> : 'Copy'}
            </IonButton>
        </IonItem>
    );
};
