import { History as IHistory, createHashHistory, LocationListener, LocationState } from 'history';
import { Logger } from '../support/Logger';

/**
 * NOTE: if we ever want to "hook" into all history changes, our proxy methods are not
 *          where to do it. Instead hook into / subscribe to this.history  for that.
 *          eg our proxy methods won't know about a <a href="/cool" /> click
 */
export class HistoryViewModel {
    history: IHistory<unknown>;

    private initialLength = 0;
    private currentLength = 0;

    static inject = () => [Logger];
    constructor(private log: Logger) {

        this.history = createHashHistory();
        this.initialLength = this.history.length;
        this.currentLength = this.history.length;

        this.history.listen((loc, action) => {
            if (action === 'PUSH') {
                this.currentLength++;
            }
            if (action === 'POP') {
                // rapid page reloads during test setup can cause bogus events to be
                // received, so don't let a POP push us back past the initial length
                if (this.currentLength > this.initialLength) {
                    this.currentLength--;
                }
            }
            log.info('Navigated', loc);
        });
    }

    getLength = () => this.currentLength;

    getRawHistory = () => this.history;

    push = (path: string) => {
        this.history.push(path);
    };

    replace = (path: string) => {
        // if there is a hash, remove it
        if (path && path[0] === '#'){
            path = path.substring(1);
        }
        this.history.replace(path);
    };

    back = (pages: number = 1) => {
        if (pages < 1) {
            throw new Error('back pages must be >= 1');
        }
        if (this.currentLength - pages < this.initialLength) {
            // the app may have started from a deep link to a page that has a back button,
            // in which case the user may trigger history.back() when there is no hisory,
            // but we don't want to go back to a page outside the app, so in this case
            // just replace the current page with the root path (to return to the map)
            this.history.replace('/');
        }
        else {
            this.history.goBack();

            // ideally we'd use history.go(-pages) but ionic doesn't support this;
            // it updates the url correctly, but renders the first page in history
            // (see https://github.com/ionic-team/ionic-framework/issues/23775);
            // the only thing that seems to work is multiple calls to goBack()
            // and we need timeouts between them to get it to work headless
            if (pages > 1) {
                setTimeout(() => this.back(pages - 1), 100);
            }
        }
    };

    // returns [[k1: v1], [k2, v2]]
    getCurrentSearchParams = (): URLSearchParams => {
        return new URLSearchParams(this.history.location.search || '?');
    };

    // returns {k1: v2, k2: v2}
    getCurrentSearchParamMap = (): any => {
        return Array.from(this.getCurrentSearchParams().entries()).reduce((m, [k, v]) => ({ ...m, [k]: v }), {});
    };

    getCurrentLocation = () => {
        return this.history.location;
    };

    listen = (listener: LocationListener<LocationState>) => this.history.listen(listener);


    // Gets the value of a query param, then silently removes it from the url without triggering a
    // router state change (so that react will allow this method to be invoked during render).
    //
    // WARNING: This intentionally bypasses the router by calling replaceState() instead of this.replace().
    // But of course this couples us to knowledge of hash router internals, and causes the router to be out
    // of sync with the browser url (which in turn forces us to use location.hash to get the initial params
    // instead of getCurrentSearchParams()).
    //
    // TODO: To avoid the issues mentioned above, we should revisit this strategy.
    popSearchParam = ({ key }: { key: string }) => {
        const search = window.location.hash.split('?')[1];
        const params = new URLSearchParams('?' + (search || ''));

        const value = params.get(key);

        if (params.has(key)) {
            params.delete(key);
            const rest = params.toString();
            const qs = rest ? `?${rest}` : '';
            const path = this.history.location.pathname;
            window.history.replaceState(null, '', `${window.location.search}#${path}${qs}`);
        }

        return value === null ? undefined : value;
    };

}
