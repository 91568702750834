import { createUseStyles } from 'react-jss';
import { WrapWithCardOnLargerScreen } from '../components/WrapWithCardOnLargerScreen';
import { CenteredMessageOverlay } from '../components/CenteredMessageOverlay';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { UserState } from '../user/UserState';
import { CaregiverVideoBackground } from './CaregiverVideoBackground';
import { CheckinCard } from './CheckinCard';
import { InnerhivePage } from '../InnerhivePage';
import { useEffect } from 'react';
import { NavigationMenuState } from '../navigation/NavigationMenuState';
import { TipCard } from './TipCard';
import { MixPanelEventEmitter } from '../metrics/MixPanelEventEmitter';
import { NavigationMenuToggle } from '../navigation/NavigationMenu';
import { EditCheckinModal } from './EditCheckinModal';

const useStyles = createUseStyles({
    page: {
        width: '100%'
    },
    banner: {
        position: 'relative',
        top: 0,
        width: '100%',
        height: 300,
    },
    content: {
        position: 'relative',
        width: '100%',
        height: '100%',
        maxWidth: 650,
        margin: 'auto'
    },
    menuToggleWrapper: {
        borderRadius: '50%',
        background: 'rgba(0, 0, 0, 0.3)',
        position: 'absolute',
        zIndex: 10,
        top: 6,
        left: 10,
        marginTop: 'var(--ion-safe-area-top)',
        display: 'flex',
        alignItems: 'center',
        height: 42,
        width: 42,
        justifyContent: 'center'
    }
});

export const CaregiverHomePage: React.FC = () => {
    const classes = useStyles();
    const userState = useInstance(UserState);
    const navMenuState = useInstance(NavigationMenuState);
    const mixpanel = useInstance(MixPanelEventEmitter);

    const name = userState.state.firstName || '';
    const welcomeMessage = (name ? `Welcome, ${name}` : 'Welcome');

    useEffect(() => {
        navMenuState.setSelectedNavItem('caregiver');
        mixpanel.track(`Caregiver's Corner Opened`);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className={classes.menuToggleWrapper}>
                <NavigationMenuToggle />
            </div>
            <InnerhivePage hideAppHeader backgroundColor="var(--ion-color-light)" >
                <div className={classes.page}>

                    <div className={classes.banner}>
                        <CaregiverVideoBackground />
                        <CenteredMessageOverlay>{welcomeMessage}</CenteredMessageOverlay>
                    </div>

                    <div className={classes.content}>
                        <WrapWithCardOnLargerScreen marginTop={-30} marginBottom={30}>

                            <div style={{minHeight: 400, padding: 10}}>
                                <CheckinCard />
                                <TipCard />
                            </div>

                        </WrapWithCardOnLargerScreen>
                    </div>

                </div>
            </InnerhivePage>

            <EditCheckinModal />
        </>

    );
};
