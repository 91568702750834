import { MouseEvent, useEffect, useRef, useState } from 'react';
import { escapeRegExp } from 'lodash';
import { IonButton, IonContent, IonInput, IonItem, IonList, IonSearchbar, IonToolbar } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { ISearchSelectOption, ISelected, ISearchableSelectFieldProps } from './SearchableSelectField';
import { CloseIcon, SearchIcon } from '../theme/Icons';
import { DrawerModal } from './DrawerPage';

const useStyles = createUseStyles({
    input: {
        '& .native-input': {
            cursor: 'pointer',
            left: 25, // allow space for search icon
            maxWidth: 'calc(100% - 45px)', // account for search icon and delete button
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    }
});

interface ITypeaheadProps<T> {
    noSort? :boolean;
    allowCustom?: boolean;
    placeholder?: string;
    options: ISearchSelectOption<T>[];
    onSelect: (option: ISelected<T>) => void;
}

const Typeahead = <T,>({
    noSort = false,
    allowCustom = true,
    placeholder = 'Search',
    options,
    onSelect
}: ITypeaheadProps<T>) => {
    const searchBar = useRef<HTMLIonSearchbarElement>(null);
    const searchRef = useRef<string>('');

    const sortedOptions = noSort ? options : [...options].sort((o1, o2) => o1.label.localeCompare(o2.label));
    const [filteredOptions, setFilteredOptions] = useState<ISelected<T>[]>(sortedOptions);

    useEffect(() => {
        setTimeout(() => {
            searchBar.current?.setFocus();
        }, 800);
    }, [searchBar]);

    const filterList = (search: string | null | undefined) => {
        searchRef.current = search || '';

        if (!search) {
            setFilteredOptions(sortedOptions);
        } else {
            const normalizedQuery = search.toLowerCase();

            // add "enter your own" option at the end if none match exactly
            const customOption = sortedOptions.find(o => o.label === search) ? [] : allowCustom ? [{label: search, value: undefined}] : [];

            setFilteredOptions([
                ...sortedOptions.filter(option => option.label.toLowerCase().includes(normalizedQuery)),
                ...customOption
            ]);
        }
    };

    const formatOptionLabel = (option: ISelected<T>) => {
        if (!searchRef.current) {
            return option.label;
        }
        const escapedInput = escapeRegExp(searchRef.current);
        let formatted = option.label.replace(
            new RegExp(escapedInput, 'gi'),
            highlighted => `<strong>${highlighted}</strong>`
        );

        if (allowCustom && option.value === undefined) {
            formatted += ' (enter your own)';
        }
        return (
            <span dangerouslySetInnerHTML={{ __html: formatted }} />
        );
    };

    return (
        <div style={{marginTop: -10, height: '100%', overflowY: 'hidden'}}>
            <IonToolbar>
                <IonSearchbar ref={searchBar} mode="ios" onIonInput={e => filterList(e.target.value)} placeholder={placeholder} />
            </IonToolbar>
            <IonContent>
                <IonList id="modal-list">
                    {filteredOptions.map((option) => (
                        <IonItem key={option.label} style={{fontSize: 15}} button onClick={() => onSelect(option)}>
                            {formatOptionLabel(option)}
                        </IonItem>
                    ))}
                </IonList>

            </IonContent>
        </div>
    );
};

export const SearchableSelectFieldMobile = <T,>({
    'data-type': dataType,
    label,
    labelPlacement = 'stacked',
    placeholder,
    autoLookupOnEmpty,
    allowCustom = true,
    disabled = false,
    marginBottom,
    selected,
    onChange,
    options
}: ISearchableSelectFieldProps<T>) => {
    const classes = useStyles();

    const inputRef = useRef<HTMLIonInputElement>(null);

    const isShown = (autoLookupOnEmpty ? Boolean(selected) : true);
    const [isLookupOpen, setLookupOpen] = useState(!isShown);

    const openLookup = () => {
        setLookupOpen(true);
    };

    const closeLookup = () => {
        // ionic seems to get confused when the modal closes, and leaves the
        // readonly IonInput looking like it has focus, so we remove the class manually
        inputRef.current?.classList.remove('has-focus');
        setLookupOpen(false);
    };

    const onLookupCanceled = () => {
        // if we close the modal while adding, remove the blank item
        if (!selected) {
            onChange(undefined);
        }
        closeLookup();
    };

    const onItemSelected = (item: ISelected<T>) => {
        onChange(item);
        closeLookup();
    };

    const onItemRemoved = (e: MouseEvent) => {
        e.stopPropagation();
        onChange(undefined);
    };

    return (
        <div data-type={dataType} className="searchable-select-field" style={{marginBottom: isShown ? marginBottom : undefined}}>

            {isShown &&
                <IonInput
                    disabled={disabled}
                    ref={inputRef}
                    readonly
                    className={classes.input}
                    fill="outline"
                    labelPlacement={labelPlacement}
                    label={label}
                    value={selected?.label || ''}
                    onClick={!disabled ? openLookup : undefined}
                >
                    <div style={{display: 'flex', alignItems: 'center', position: 'absolute', top: 0, left: 15, height: '100%'}}>
                        <SearchIcon color="medium" />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', position: 'absolute', top: 0, right: 0, height: '100%'}}>
                        {selected && !disabled &&
                            <IonButton style={{zIndex: 1000, height: '100%'}} fill="clear" size="small" onClick={onItemRemoved}><CloseIcon color="medium" /></IonButton>
                        }
                    </div>
                </IonInput>
            }

            <DrawerModal
                hasClose
                title={label}
                excludeAutoDismiss
                isOpen={isLookupOpen}
                onClose={onLookupCanceled}
            >
                <Typeahead
                    allowCustom={allowCustom}
                    placeholder={placeholder}
                    options={options}
                    onSelect={onItemSelected}
                />
            </DrawerModal>

        </div>
    );
};
