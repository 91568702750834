import dayjs from 'dayjs';
import { APIClient } from '@innerhive-internal/innerhive-api-client';
import { State } from '@meraki-internal/state';
import { ICheckin } from './ICheckin';
import { INote } from '../notes/INote';
import { NoteSavingQueue } from '../notes/NoteSavingQueue';

export const NEW_NOTE_ID = 'NEW';

const ISO_FORMAT_WITH_TZ_OFFSET = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

type ICheckinState = {
    notes: INote[];
    editing: boolean;
    editingCheckin?: ICheckin;
};

export class CheckinState extends State<ICheckinState> {

    static inject = () => [APIClient, NoteSavingQueue];
    constructor(private apiClient: APIClient, private savingQueue: NoteSavingQueue) {
        super({
            notes: [],
            editing: false
        });
    }

    private getNote = (noteId: string) => {
        const note = this.state.notes.find(n => n.noteId === noteId);
        if (!note) {
            throw new Error(`Could not find note with id ${noteId}`);
        }
        return note;
    };

    private addNote = (newNote: INote) => {
        this.setState({
            notes: [...this.state.notes, newNote]
        });
    };

    private updateNote = (updated: INote) => {
        this.setState({
            notes: this.state.notes.map(note => {
                return (note.noteId === updated.noteId ? updated : note);
            })
        });
    };

    private buildCheckinFromNote = (note: any): ICheckin => ({
        // default to createdAt, but should get overwritten by timestamp from
        // otherAttributes, which is in the timezone where the checkin was created
        timestamp: note.createdAt,

        noteId: note.noteId,
        note: note.html,
        attachments: note.attachments,

        ...note.otherAttributes
    });

    load = async () => {
        const entry = await this.apiClient.entry();

        const checkinsLink = entry.links.checkins;
        if (!checkinsLink) {
            // checkins not supported for this user
            return;
        }

        const notes: INote[] = await this.apiClient.get(checkinsLink);

        // sort by timestamp (which is in timezone where checkin was created)
        notes.sort((c1, c2) => {
            const ts1 = this.buildCheckinFromNote(c1).timestamp;
            const ts2 = this.buildCheckinFromNote(c2).timestamp;
            return ts1.localeCompare(ts2);
        });

        this.setState({ notes });
    };

    getCheckins = () => {
        return this.state.notes.map(this.buildCheckinFromNote);
    };

    // return checkin date in format "YYYY-MM-DD"
    getCheckinDate = (checkin: ICheckin) => {
        // NEVER use dayjs here or you can get the wrong answer!!

        // For example, if I go to LA and create a checkin at 10pm there,
        // its timestamp will be "2024-06-30T22:00:00.000Z-08:00",
        // if I then return to NY, dayjs(timestamp).format('YYYY-MM-DD')
        // will return "2024-07-01" which is not what we want.

        // We ALWAYS just want to look at the first 10 characters.
        return checkin.timestamp.substring(0, 10);
    };

    // get final checkin for each day
    getFinalCheckins = () => {
        const checkins = this.getCheckins();

        const checkinMap: any = {};
        for (const checkin of checkins) {

            // intentionally using YYYY-MM-DD as the key so we group checkins
            // based on the date they were created in the local time zone
            const key = this.getCheckinDate(checkin);
            checkinMap[key] = checkin;
        }

        return Object.keys(checkinMap).map(k => checkinMap[k]);
    };

    // get latest checkin logged for this day
    getFinalCheckin = (date: string) => {
        const checkins = this.getCheckins();

        // intentionally using YYYY-MM-DD so we filter checkins
        // based on the date they were created in the local time zone
        const timestampPrefix = dayjs(date).format('YYYY-MM-DD');

        const checkin = checkins.findLast(c => {
            return c.timestamp.startsWith(timestampPrefix);
        });

        return checkin;
    };

    getCheckinForDay = (date: string): ICheckin => {
        const checkin = this.getFinalCheckin(date);

        if (checkin) {
            return checkin;
        }

        // if no saved checkin for this date, create a new one...
        let day: dayjs.Dayjs;
        if (dayjs(date).isToday()) {
            // if today, set timestamp to current time
            day = dayjs();
        } else {
            // if a day in the past, set timestamp to midday
            day = dayjs(date).set('h', 12).startOf('h');
        }

        // timestamp is in user's current local time zone
        // (so ends with offset like "+04:00" instead of "Z")
        const timestamp = day.format(ISO_FORMAT_WITH_TZ_OFFSET);

        return {
            noteId: NEW_NOTE_ID,
            timestamp,
            note: '',
            attachments: []
        };
    };

    getCheckinForToday = () => {
        return this.getCheckinForDay(dayjs().toISOString());
    };

    saveCheckin = (checkin: ICheckin) => {
        this.state.editingCheckin = checkin;

        const { noteId: checkinNoteId, note = '', attachments = [], ...otherAttributes } = checkin;

        // optimistically update local state before saving
        this.setState({ editingCheckin: checkin });
        if (checkinNoteId !== NEW_NOTE_ID) {
            this.updateNote({ ...this.getNote(checkinNoteId), otherAttributes });
        }

        this.savingQueue.enqueue({

            noteId: checkinNoteId,

            action: async () => {
                let noteId = checkinNoteId;

                // check if we have a saved note for this day, in case this was a second
                // "NEW" request submitted while the previous one was still saving
                if (noteId === NEW_NOTE_ID) {
                    const timestampDate = this.getCheckinDate(checkin);
                    ({ noteId } = this.getCheckinForDay(timestampDate));
                }

                if (noteId === NEW_NOTE_ID) {
                    const entry = await this.apiClient.entry();
                    if (!entry.links.checkins) {
                        throw new Error('checkins not supported for this user');
                    }
                    const noteToSave = { title: '', html: note, attachments, otherAttributes };
                    const savedNote = await this.apiClient.post(entry.links.checkins, noteToSave);
                    this.addNote(savedNote);
                } else {
                    const noteToSave = { ...this.getNote(noteId), html: note, attachments, otherAttributes };
                    const savedNote = await this.apiClient.put(noteToSave);
                    this.updateNote(savedNote);
                }
            }
        });
    };

    startEditing = (checkin: ICheckin) => {
        this.setState({ editing: true, editingCheckin: checkin });
    };

    stopEditing = () => {
        // leave editingCheckin set so modal doesn't change while animating closed
        this.setState({ editing: false });
    };

    isEditing = () => {
        return this.state.editing;
    };

    getEditingCheckin = () => {
        return this.state.editingCheckin;
    };
};
