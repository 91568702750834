import { InnerhivePlusText } from '../components/InnerhivePlusText';
import { PaywallAwareButton } from '../components/PaywallAwareButton';
import { createUseStyles } from 'react-jss';
import { Modal } from '../components/Modal';
import { IonContent, isPlatform } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { Text } from '../components/Text';
import { Divider } from '../components/Divider';
import { SectionHeading } from '../components/SectionHeading';
import { TextField } from '../components/TextField';
import { InvitationState } from './InvitationState';
import { ShareLinkButton } from './ShareLinkButton';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { useRef, useState } from 'react';
import { RevenueCatModel } from '../innerhive-plus/revenue-cat/RevenueCatModel';

const useStyles = createUseStyles({
    button: {
        height: 40
    },

    upperSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '& > ion-text': {
            paddingTop: 20,
            paddingBottom: 20,
            maxWidth: 315,
            textAlign: 'center'
        }
    },

    lowerSection: {
        padding: 20
    },

    textFieldWrapper: {
        paddingTop: 20,
        paddingBottom: 20
    },

    sendButtonWrapper: {
        paddingTop: 12
    }
});

export const InviteCoOwnerButton: React.FC = () => {
    const classes = useStyles();

    const careMapState = useInstance(CareMapState);
    const invitationState = useInstance(InvitationState);
    const trackingService = useInstance(MixpanelService);
    const revCat = useInstance(RevenueCatModel);

    const [inviteeName, setInviteeName] = useState<string>();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const fieldRef = useRef<HTMLIonInputElement>(null);
    const focusInvitee = () => {
        const timer = setTimeout(() => {
            if ((isPlatform('desktop') || isPlatform('capacitor')) && fieldRef.current) {
                fieldRef.current.setFocus();
                clearTimeout(timer);
            }
        }, 10);
    };

    const didPresentHandler = () => {
        focusInvitee();
        trackingService.track('Invite Co-Owner Modal Opened');
    };

    const shareDescription = `Join our hive and become a co-owner of ${careMapState.getPatientFirstName()}'s Care Map with me. Open the link to get started!`;

    const onLinkCopied = () => {
        trackingService.track('Co-Owner Share Link Copied');
    };

    const onMobileSharingOpened = () => {
        trackingService.track('Co-Owner Mobile Sharing Opened');
    };

    const getCoOwnerLink = async () => {
        const url = await careMapState.getCoOwnerLink({ inviteeName });
        await invitationState.load();
        return url;
    };

    return (
        <>
            <PaywallAwareButton
                fill="outline"
                expand="block"
                data-id="invite-co-owner-button"
                className={classes.button}
                onClick={() => setIsOpen(true)}
                triggerSource="co-owner invite"
                requiresInnerhivePlus={() => true}
                innerhivePlusMessage={<>Get <InnerhivePlusText/> to invite co-owners</>}
            >
                <>Invite Co-Owner</>
            </PaywallAwareButton>

            {revCat.hasInnerhivePlus() && (
                <Modal
                    stackedModal
                    isOpen={isOpen}
                    title="Invite Co-Owner"
                    data-id="invite-co-owner-modal"
                    closeHandler={() => setIsOpen(false)}
                    onWillPresent={() => setInviteeName('')}
                    onDidPresent={didPresentHandler}
                >
                    <IonContent>
                        <div className={classes.upperSection}>
                            <img style={{width: '30%', maxWidth: 150}} src="./assets/images/share/invite-co-owner.png" alt="invite co-owner" />
                            <Text>Share {careMapState.getPatientFirstName()}'s Care Map and get the help you need coordinating care</Text>
                        </div>
                        <Divider />
                        <div className={classes.lowerSection}>
                            <SectionHeading text="send invite link" />
                            <div className={classes.textFieldWrapper}>
                                <TextField
                                    //autoFocus
                                    blurOnEnter
                                    inputRef={fieldRef}
                                    name="co-owner-name"
                                    label="Co-Owner's Name"
                                    value={inviteeName}
                                    autocapitalize="words"
                                    onInput={(value) => setInviteeName(value)}
                                />
                            </div>
                            <Text type="subtitle">
                                <span style={{fontWeight: 'normal'}}>
                                    A <b>Co-Owner</b> has full access to {careMapState.getPatientFirstName()}'s Care Map (including anything marked private) and can invite collaborators.
                                </span>
                            </Text>
                            <div className={classes.sendButtonWrapper}>
                                <ShareLinkButton
                                    disabled={!inviteeName}
                                    data-type="send-co-owner-invite-button"
                                    label={isPlatform('capacitor') ? 'Send Invite' : 'Invite Co-Owner'}
                                    shareLabel="Share Care Map with Co-Owner"
                                    shareDescription={shareDescription}
                                    createLinkUrl={getCoOwnerLink}
                                    onLinkCopied={onLinkCopied}
                                    onMobileSharingOpened={onMobileSharingOpened}
                                    onMobileSharingDone={() => setIsOpen(false)}
                                />
                            </div>
                        </div>
                    </IonContent>
                </Modal>
            )}
        </>
    );
};
