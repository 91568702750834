import { FC } from 'react';
import { SearchableSelectField, ISelected, InternalSelect } from '../components/SearchableSelectField';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { SupportGroupDataProvider } from '../caremap/SupportGroupDataProvider';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { CustomTypeAddedMetaBuilder } from '../metrics/CustomTypeAddedMetaBuilder';

interface IAllergySelectFieldProps {
    label: string;
    allergyId?: string;
    allergy?: string;
    marginBottom?: number;
    innerRef?: React.Ref<InternalSelect<ISelected<string>>>;
    onChange: (params: { allergyId?: string; allergy?: string; }) => void;
    onBlur?: () => void;
}

export const AllergySelectField: FC<IAllergySelectFieldProps> = ({ label, allergyId, allergy, marginBottom, innerRef, onChange, onBlur }) => {
    const tracking = useInstance(MixpanelService);
    const dataProvider = useInstance(SupportGroupDataProvider);

    let selected: ISelected | undefined;
    if (allergy){
        selected = {
            value: allergyId,
            label: allergy
        };
    }

    const sendCustomAllergyMetric = (newlySelected?: ISelected) => {
        if (newlySelected?.label && !newlySelected.value ){
            tracking.track('Custom Type Added', () => new CustomTypeAddedMetaBuilder()
                .origin('caremap')
                .patient()
                .field({ field: 'allergy', fieldLabelKey: 'CARE_RECIPIENT_ALLERGY', value: newlySelected?.label  })
                .build()
            );
        }
    };

    const onSelected = (newlySelected?: ISelected) => {
        sendCustomAllergyMetric(newlySelected);

        onChange({
            allergyId: newlySelected?.value || undefined,
            allergy: newlySelected?.label || undefined
        });
    };

    return (
        <SearchableSelectField
            noSort
            data-type="allergy-dropdown"
            innerRef={innerRef}
            label={label}
            placeholder="Search or select allergen"
            autoLookupOnEmpty={true}
            marginBottom={marginBottom}
            selected={selected}
            options={dataProvider.getAllergySelectOptions()}
            onChange={onSelected}
            onBlur={onBlur}
        />
    );
};
