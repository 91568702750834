import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { SectionHeading } from './SectionHeading';

type IStyleKeys = 'wrapper';
interface IStyleProps {
    tight: boolean;
};

const useStyles = createUseStyles<IStyleKeys, IStyleProps>({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: 2,
        minHeight: ({ tight }) => tight ? 25: 40
    }
});

export interface IDrawerSectionHeading {
    text: string;
    children?: ReactNode;
    tight?: boolean;
};

export const DrawerSectionHeading: FC<IDrawerSectionHeading> = ({ text, children, tight = false }) => {
    const classes = useStyles({ tight });
    return (
        // position text aligned left and children aligned right
        <div className={classes.wrapper}>
            <SectionHeading text={text} />
            {children}
        </div>
    );
};
