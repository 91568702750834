import { useEffect, useRef } from 'react';
import { IChecklist, ISupportGroupKey } from '../caremap/ICareMap';
import { createUseStyles } from 'react-jss';
import { IntercomService } from '../support/IntercomService';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { MixPanelEventEmitter } from '../metrics/MixPanelEventEmitter';
import { EnvConfiguration } from '../config/EnvConfiguration';

const animationDelay = 500;
const animationDuration = 500;
const useStyles = createUseStyles({
    root: {
        paddingLeft: 28,

        '& ul': {
            listStyleType: 'none',
            margin: 0,
            padding: 10,
            paddingLeft: 0,
            paddingBottom: 0
        },
        '& ul li': {
            marginBottom: 8
        },
        '& ul li.checked > span': {
            textDecoration: 'line-through',
            color: 'var(--ion-color-medium)'
        },
        '& p': {
            margin: 0,
            padding: '3px 0',
            color: 'var(--ion-color-step-750)',

            '&:nth-child(1)': {
                fontWeight: 600,
            },

            '&:nth-child(2)': {
                paddingTop: 6
            }
        },
        '& input[type=checkbox]': {
            marginRight: 8,
            marginBottom: 6,
            cursor: 'pointer',
            position: 'relative'
        },
        '& input[type=checkbox]:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: 14,
            height: 14,
            top: -2,
            left: 0,
            border: '2px solid #555555',
            borderRadius: 3,
            backgroundColor: 'white'
        },
        '& input[type=checkbox][checked=true]:after': {
            content: '""',
            display: 'block',
            width: 4,
            height: 9,
            borderStyle: 'solid',
            borderColor: 'var(--ion-color-medium)',
            borderWidth: '0 2px 2px 0',
            transform: 'rotate(45deg)',
            position: 'absolute',
            left: 6
        },
        '& li.checked > input[type=checkbox]:before': {
            borderColor: 'var(--ion-color-medium)',
        },

        '& a': {
            cursor: 'pointer',
            textDecoration: 'none',
            color: 'var(--ion-color-primary)',
            '&:hover': {
                textDecoration: 'underline'
            }
        },

        '& li > ul': {
            paddingLeft: 24,
            paddingBottom: 0
        }
    },

    hideDone: {
        '& ul li.checked': {
            animationFillMode: 'forwards',
            animationDelay: `${animationDelay}ms`,
            animation: `$hidingDone ${animationDuration}ms`
        }
    },

    '@keyframes hidingDone': {
        from: {
            opacity: 1
        },
        to: {
            opacity: 0,
            display: 'none',
            position: 'absolute'
        }
    }
});

export const HtmlChecklist: React.FC<{
    checklist: IChecklist;
    onChanged: (html: string) => void;
    supportGroupKey: ISupportGroupKey;
    hideDone?: boolean;
    sectionCompletionHandler: (isComplete: boolean, templateId: string) => void
}> = ({
    checklist,
    onChanged,
    supportGroupKey,
    hideDone,
    sectionCompletionHandler = () => {}
}) => {
    const classes = useStyles();
    const intercomService = useInstance(IntercomService);
    const mixpanel = useInstance(MixPanelEventEmitter);
    const { INTERCOM_APP_ID } = useInstance(EnvConfiguration);
    const checklistContainerRef = useRef<HTMLDivElement>(null);

    const trackItemComplete = (input: HTMLInputElement) => {
        const articleId = checklist.templateId?.substring(checklist.templateId?.lastIndexOf('/')+1);
        const parentLi = input.parentElement?.parentElement?.parentElement;
        const isChild = parentLi?.tagName === 'LI';

        mixpanel.track('Checklist Item Completed', () => ({
            'Category': supportGroupKey,
            'Type': isChild ? 'Child' : 'Parent',
            'Label': input.parentElement?.querySelector('span')?.innerText,
            'Section': `https://app.intercom.com/a/apps/${INTERCOM_APP_ID}/articles/articles/${articleId})}`
        }));
    };

    const checkForSectionCompletion = () => {
        if (checklist.templateId) {
            let complete = true;
            checklistContainerRef.current?.querySelectorAll('input[type=checkbox]').forEach(el => {
                const input = el as HTMLInputElement;
                if (!input.checked) {
                    complete = false;
                }
            });

            sectionCompletionHandler(complete, checklist.templateId);
        }
    };

    useEffect(() => {
        if (checklistContainerRef.current && checklistContainerRef.current.innerHTML) {
            checkForSectionCompletion();
        }
        // check for completion of list when hideDone or the containre ref changes
    }, [hideDone, checklistContainerRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (checklistContainerRef.current){
            checklistContainerRef.current.innerHTML = checklist.html;

            // article://9123883
            // setup click handling for intercom articles
            checklistContainerRef.current.querySelectorAll('a').forEach(a => {
                if (a.href.includes('article://')) {
                    const articleId = Number(a.href.substring(a.href.lastIndexOf('/')+1));
                    a.removeAttribute('target');
                    a.addEventListener('click', (e: MouseEvent) => {
                        e.preventDefault();
                        intercomService.showArticle(articleId);
                    });
                }
            });

            // when a parent or child is checked / unchecked, handle the children or parent
            const toggleChildrenOrParent = (input: HTMLInputElement, check: boolean) => {
                const parentEl = input.parentElement;
                const childUl = parentEl?.querySelector('ul');

                const parentLi = parentEl?.parentElement?.parentElement;
                if (parentLi?.tagName === 'LI' && !check) { // input checked is a child, handle parent
                    parentLi.classList.remove('checked');
                    const parentInput = parentLi.querySelector('li > input');
                    parentInput?.removeAttribute('checked');
                    (parentInput as any).checked = false;
                } else if (childUl) { // parent was checked, handle children
                    const chkbxs = childUl.querySelectorAll('li > input');
                    chkbxs.forEach(chkbx => {
                        if (check) {
                            chkbx.setAttribute('checked', 'true');
                            (chkbx as any).checked = true;
                            chkbx.parentElement!.classList.add('checked');
                        } else {
                            chkbx.removeAttribute('checked');
                            (chkbx as any).checked = false;
                            chkbx.parentElement!.classList.remove('checked');
                        }
                    });
                }
            };

            checklistContainerRef.current.querySelectorAll('input[type=checkbox]').forEach(el => {
                // very important that we minimize how we change the html since we'll be persisting this html

                const input = el as HTMLInputElement;

                input.addEventListener('change', () => {
                    // update the html so it can be serialized and persisted
                    if (input.checked){

                        // we have to set attribute so that it being checked can be persisted
                        input.setAttribute('checked', 'true');
                        (input as any).checked = true;

                        // we also add this class as a compromise, so we can style the checklist item text
                        // beware the slippery slope, b/c it won't take long and we should just build out
                        // checklist items as first class citizens
                        input.parentElement!.classList.add('checked');

                        if (hideDone) {
                            setTimeout(checkForSectionCompletion, animationDelay + animationDuration - 50);
                        }

                        trackItemComplete(input);
                    }
                    else {
                        input.removeAttribute('checked');
                        (input as any).checked = false;
                        input.parentElement!.classList.remove('checked');
                    }

                    toggleChildrenOrParent(input, input.checked);
                    onChanged(checklistContainerRef.current!.innerHTML);
                });

            });
        }

        // intentionally don't want to update the html as it changes in the data layer
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checklistContainerRef, hideDone]);
    return (
        <div
            data-type="html-checklist"
            ref={checklistContainerRef}
            className={`${classes.root} ${hideDone ? classes.hideDone : ''}`}
        />
    );
};
