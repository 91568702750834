
import { useSubscription } from '@meraki-internal/state';
import { InnerhivePlusDialogViewModel } from '../innerhive-plus/InnerhivePlusDialogViewModel';
import { RevenueCatModel } from '../innerhive-plus/revenue-cat/RevenueCatModel';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { InnerhivePlusIcon } from '../theme/Icons';

export const InnerhiveNavLogo: React.FC = () => {
    const dialogVM = useInstance(InnerhivePlusDialogViewModel);

    const revenueCat = useInstance(RevenueCatModel);
    useSubscription(() => revenueCat);

    const hasInnerhivePlus = revenueCat.hasInnerhivePlus();

    const onLogoClicked = () => {
        if (hasInnerhivePlus) {
            dialogVM.open({ triggerSource: 'menu logo'});
        }
    };

    const src = (hasInnerhivePlus ? '/assets/images/logos/logo-with-text-secondary.svg' : '/assets/images/logos/logo-with-text-primary.svg');

    return (
        <div style={{display: 'flex', alignItems: 'center', gap: 5, cursor: hasInnerhivePlus ? 'pointer' : 'default'}} data-id="innerhive-plus-button" data-type="downgrade">
            <img height="25px" src={src} alt="logo" onClick={onLogoClicked} />
            {hasInnerhivePlus && <InnerhivePlusIcon color="secondary" />}
        </div>
    );
};
