import { FC, ReactNode, useState } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { IChecklist, ISupportGroupKey } from '../caremap/ICareMap';
import { CareMapState } from '../caremap/CareMapState';
import { HtmlChecklist } from './HtmlChecklist';
import { CircleChevronRightIcon } from '../theme/Icons';
import { createUseStyles } from 'react-jss';
import { IonButton, IonButtons } from '@ionic/react';
import { AsyncStorageProvider } from '../support/AsyncStorageProvider';
import { useAsyncEffect } from '../support/useAsyncEffect';

const useStyles = createUseStyles({
    root: {
        width: '100%',
        minHeight: 34,
        overflow: 'hidden',

        '&$collapsed': {
            maxHeight: 34
        }
    },

    animateExpand: {
        animation: '$expandingRoot ease 0.8s'
    },

    animateCollapse: {
        animation: '$collapsingRoot ease 0.5s'
    },

    '@keyframes collapsingRoot': {
        from: { maxHeight: 1000 },
        to: { maxHeight: 34 }
    },

    '@keyframes expandingRoot': {
        from: { maxHeight: 34 },
        to: { maxHeight: 1000 }
    },

    buttons: {
        width: '100%',
        position: 'absolute',

        '& ion-button': {
            top: -8,
            height: 40,
            width: '95%',
            position: 'absolute',
            marginInlineStart: 0,

            '&::part(native)': {
                paddingInlineStart: 0
            }
        }
    },

    arrow: {
        top: 2,
        left: 2,
        rotate: '90deg',
        position: 'absolute',
        animation: '$expandingArrow ease 0.3s',

        '& ion-text': {
            verticalAlign: 'sub'
        }
    },

    '@keyframes collapsingArrow': {
        from: { rotate: '90deg' },
        to: { rotate: '0deg' }
    },

    '@keyframes expandingArrow': {
        from: { rotate: '0deg' },
        to: { rotate: '90deg' }
    },

    collapsed: {
        '& $arrow': {
            rotate: '0deg',
            animation: '$collapsingArrow ease 0.3s',
        }
    },

    hideSection: {
        animationFillMode: 'forwards',
        animation: '$hidingSection 0.25s'
    },

    '@keyframes hidingSection': {
        from: {
            display: 'block'
        },
        to: {
            opacity: 0,
            display: 'none',
            position: 'absolute',
            transform: 'translateX(500px)'
        }
    }
});

export type ICollapsibleSectionProps = {
    id: string;
    children: ReactNode;
    expanded: boolean;
    hide?: boolean;
};

export const CollapsibleSection: FC<ICollapsibleSectionProps> = ({ id, children, expanded, hide }) => {
    const classes = useStyles();
    const storageProvider = useInstance(AsyncStorageProvider);
    const [collapsed, setCollapsed] = useState<boolean>(!expanded);
    const [animateClassName, setAnimateClassname] = useState<string>('');

    const storageKey = `${id.substring(id.lastIndexOf('/')+1)}-collapsed`;

    useAsyncEffect(async () => {
        const exists = await storageProvider.getBooleanProvider(storageKey).exists();
        const storageValue = exists ? await storageProvider.getBooleanProvider(storageKey).get() : collapsed;
        setCollapsed(storageValue!);
    }, [storageProvider, id]);

    const toggle = () => {
        setCollapsed(val => {
            storageProvider.getBooleanProvider(storageKey).set(!val);
            setAnimateClassname(val ? classes.animateExpand : classes.animateCollapse);
            return !val;
        });
    };

    return (
        <div
            data-type="collapsible-section"
            className={`${classes.root} ${collapsed ? classes.collapsed : ''} ${animateClassName} ${hide ? classes.hideSection : ''}`}
        >
            <IonButtons className={classes.buttons}>
                <div className={classes.arrow}>
                    <CircleChevronRightIcon color="medium" />
                </div>
                <IonButton fill="clear" onClick={toggle}></IonButton>
            </IonButtons>
            {children}
        </div>
    );
};

export const Checklists: FC<{
    checklists: IChecklist[];
    supportGroupKey: ISupportGroupKey;
    hideDone?: boolean;
}> = ({ checklists, supportGroupKey, hideDone }) => {
    const careMapState = useInstance(CareMapState);
    // we don't subscribe b/c downsteam owns the html and we don't update it

    const [completedTemplates, setCompletedTemplates] = useState<string[]>([]);
    const onSectionCompletion = (isComplete: boolean, templateId: string) => {
        setCompletedTemplates(completed => {
            if (isComplete) {
                completed.push(templateId);
                // TODO: find the section so can set display none?
            } else {
                completed = completed.filter(id => id !== templateId);
            }
            return [...completed];
        });
    };

    return (
        <>
            {checklists.map((checklist, i) => (
                <CollapsibleSection
                    key={i}
                    expanded={i === 0}
                    id={checklist.templateId || ''}
                    hide={completedTemplates.find(id => id === checklist.templateId) !== undefined && hideDone}
                >
                    <HtmlChecklist
                        hideDone={hideDone}
                        checklist={checklist}
                        supportGroupKey={supportGroupKey}
                        onChanged={html => {
                            checklist.html = html;
                            careMapState.enqueueSave();
                        }}
                        sectionCompletionHandler={onSectionCompletion}
                    />
                </CollapsibleSection>
            ))}
        </>
    );
};
