import * as React from 'react';
import { IThemeColor } from '../theme/IThemeColor';

// all hexagon/honeycomb sizing and positioning is based off this value,
// so if we want different sized hexagons we should only need to adjust this
export const HEXAGON_WIDTH = 160;

// for a regular symmetrical hexagon, the height is always width / 1.1547
// (see https://en.wikipedia.org/wiki/Hexagon for the math)
export const HEXAGON_HEIGHT = HEXAGON_WIDTH / 1.1547;

const BORDER_WIDTH_PCT = 4;

/*
We want a drop shadow around the hexagon...
But we can't use boxShadow on the hexagon itself because it gets clipped by the clipPath;
And we tried using filter(boxShadow) on the parent li, but this performed very poorly on ios;
So instead we're using boxShadow on three rotated rectangles positioned behind the hexagon,
NOTE: boxShadow x/y offsets are different on each rectangle to make the shadow appear to be
offset to the bottom right of the hexagon
*/
const Shadow: React.FC = () => {
    const commonStyles: React.CSSProperties = {
        position: 'absolute',
        left: '25%',
        right: '25%',

        // start shadow at edges of hexagon, but back off 1px
        // to ensure shadow div background doesn't peek out
        top: 1,
        bottom: 1
    };
    return (
        <>
            <div
                style={{
                    ...commonStyles,
                    boxShadow: '0px 8px 16px rgba(100, 100, 100, 0.4)',
                }}
            />
            <div
                style={{
                    ...commonStyles,
                    rotate: '60deg',
                    boxShadow: '0px 0px 16px rgba(100, 100, 100, 0.4)',
                }}
            />
            <div
                style={{
                    ...commonStyles,
                    rotate: '120deg',
                    boxShadow: '0px -8px 16px rgba(100, 100, 100, 0.4)',
                }}
            />
        </>
    );
};

interface IHexagonProps {
    color: IThemeColor;
    fill: 'outline' | 'solid';
    children: React.ReactNode;
}

export const Hexagon: React.FC<IHexagonProps> = ({
    color,
    fill,
    children
}) => {
    const clipPath = `polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)`;

    return (
        <div style={{position: 'relative'}}>

            <Shadow />

            <div
                style={{
                    pointerEvents: 'auto',
                    position: 'relative',
                    width: HEXAGON_WIDTH,
                    height: HEXAGON_HEIGHT,
                    backgroundColor: fill === 'outline' ? `var(--ion-color-${color})` : 'white',
                    clipPath
                }}
            >
                <div
                    data-type="hexagon"
                    style={{
                        pointerEvents: 'auto',
                        position: 'absolute',
                        top: HEXAGON_HEIGHT * BORDER_WIDTH_PCT / 100,
                        left: HEXAGON_WIDTH * BORDER_WIDTH_PCT / 100,
                        right: HEXAGON_WIDTH * BORDER_WIDTH_PCT / 100,
                        bottom: HEXAGON_HEIGHT * BORDER_WIDTH_PCT / 100,
                        backgroundColor: fill === 'solid' ? `var(--ion-color-${color})` : 'white',
                        color: fill === 'solid' ? 'white' : undefined,
                        clipPath
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};
