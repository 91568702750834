import * as Sentry from '@sentry/react';
import { ILogger } from './ILogger';
import { CloudwatchLogger } from './CloudwatchLogger';

const sensitiveKeys = [ 'accesstoken', 'password' ];

/**
 * NTH - would like to refactor OriginalLogger to split out the sentry concern to a SentryLogger
 */

class CompositeLogger implements ILogger {
    constructor(...loggers: ILogger[]){
        this.loggers = loggers;
    }

    private loggers: ILogger[];
    error = (error: any, extras?: any) => {
        for (const logger of this.loggers){
            try {
                logger.error(error, extras);
            }
            catch {
                // do nothing
            }
        }
    };
    info = (message: string, extras?: any) => {
        for (const logger of this.loggers){
            try {
                logger.info(message, extras);
            }
            catch {
                // do nothing
            }
        }
    };
}

export class OriginalLogger implements ILogger {
    private recursivelyScrubSensitiveKeys = (data: any) => {
        if (!data || typeof data !== 'object') {
            return data;
        }
        const result = { ...data };
        for (const key of Object.keys(result)) {
            if (sensitiveKeys.includes(key.toLowerCase())) {
                result[key] = '***';
            } else {
                result[key] = this.recursivelyScrubSensitiveKeys(data[key]);
            }
        }
        return result;
    };

    private consoleLog(...args: any[]) {
        // "undefined" / "null" in logs can be distracting/confusing
        console.log(...args.filter(a => a !== undefined && a !== null));
    }

    private log = (message: string, extras: any) => {
        try {
            extras = this.recursivelyScrubSensitiveKeys(extras);

            this.consoleLog(message, extras);

        } catch (ignore) {
            // do nothing so that logging isn't the cause of app failures
        }
    };

    error = (error: any, extras?: any) => {
        try {
            extras = this.recursivelyScrubSensitiveKeys(extras);

            this.consoleLog(error, extras);

            const is400 = error && error.status && error.status >= 400 && error.status < 500;

            // don't send unsupported device errors to sentry
            // don't send 400s to sentry, as thse are expected errors (eg validation)
            // don't send errors that happen while offline, as these are likely a result
            //  of being offline and therefore aren't actionable
            if (!is400){
                Sentry.withScope(scope => {
                    if (extras) {
                        Object.keys(extras).forEach(key => {
                            scope.setExtra(key, extras[key]);
                        });
                    }
                    Sentry.captureException(error);
                });
            }
        } catch (ignore) {
            // don't ever want to throw anything from this class!
        }
    };

    info = (message: string, extras?: any) => {
        this.log(message, extras);
    };
}

export class Logger extends CompositeLogger {
    static inject = () => [CloudwatchLogger, OriginalLogger];
}
