import { createUseStyles } from 'react-jss';
import { TabRadioButton } from './TabRadioButton';

const useStyles = createUseStyles({
    contactRadios: {
        display: 'flex',
        alignItems: 'stretch'
    },
});

type ITabRadioButtonProps<T> = {
    value: T;
    onChange: (value: T) => void;
    buttons: {label: string, value: T}[];
    dataTypePrefix?: string;
    tighterXs?: boolean;
    // NOTE: tighterXs was introduced to stop the '30 days' label in the share menu from wrapping,
    //  and felt to tight for the contact method labels. it is passed down to TabRadioButton where the style is applied.
    //  when the next use case to use this component arises, it might be worth revisting the TabRadioButton layout
    //  to see if we can accomplish a similar result through css layout, instead of using a configuration parameter
};

export const TabRadioButtons = <T,>({ value, onChange, buttons, dataTypePrefix, tighterXs = false }: ITabRadioButtonProps<T>) => {
    const classes = useStyles();
    return (
        <div className={classes.contactRadios}>
            {buttons.map(btn => (
                <TabRadioButton
                    tighterXs={tighterXs}
                    key={`${btn.value}-key`}
                    label={btn.label}
                    selected={value === btn.value}
                    onClick={() => onChange(btn.value)}
                    dataType={`${btn.value}${dataTypePrefix ? `-${dataTypePrefix}` : ''}-radio`}
                />
            ))}
        </div>
    );
};
