import { IonCardContent, IonPopover } from '@ionic/react';
import { FC } from 'react';
import { Divider } from './Divider';
import { createUseStyles } from 'react-jss';
import { useBreakpoints } from '../theme/useBreakpoints';

type IStyleKeys = 'popover';

interface IStyleProps {
    maxWidth: IMaxWidth;
    isBelowSmall: boolean;
}

const useStyles = createUseStyles<IStyleKeys, IStyleProps>({
    popover: {
        '&::part(content)': {
            width: 'auto',
            minWidth: ({ maxWidth }) => Math.min(maxWidth === 'smaller' ? 280 : 350, window.innerWidth - 25),
            maxWidth: ({ maxWidth }) => Math.min(maxWidth === 'larger' ? 650 : maxWidth === 'smaller' ? 280 : 350, window.innerWidth - 25),
            transformOrigin: ({ isBelowSmall }) => isBelowSmall ? 'right top !important' : undefined
        },

        '& hr': {
            marginBlockEnd: 0,
            marginBlockStart: 0
        }
    }
});

export const HeaderPopoverTitle: FC<{title: string; centered?: boolean;}> = ({ title, centered }) => {
    return (
        <h2 style={{fontWeight: '600', marginBottom: '18px', textAlign: centered ? 'center' : undefined}}>{title}</h2>
    );
};


type IMaxWidth = 'smaller' | 'default' | 'larger';

export interface IHeaderPopoverProps {
    trigger: string;
    isOpen: boolean;
    title?: string;
    centerTitle?: boolean;
    content?: React.ReactNode;
    lowerContent?: React.ReactNode;
    onDidDismiss?: () => void;
    maxWidth?: IMaxWidth;
};

export const HeaderPopover: FC<IHeaderPopoverProps> = ({
    trigger,
    isOpen,
    title,
    centerTitle,
    content,
    lowerContent,
    onDidDismiss,
    maxWidth = 'default'
}) => {
    const {isBelowBreakpoint} = useBreakpoints();
    const classes = useStyles({ maxWidth, isBelowSmall: isBelowBreakpoint('sm') });

    return (
        <IonPopover
            alignment="end"
            trigger={trigger}
            showBackdrop={false}
            isOpen={isOpen}
            onDidDismiss={onDidDismiss}
            className={classes.popover}

            // need to set keepContentsMounted, otherwise content that expands popover
            // beyond minWidth sometimes pushes it off the right side of the screen
            // (see https://github.com/ionic-team/ionic-framework/issues/24716)
            keepContentsMounted
        >
            {(title || content) &&
                <IonCardContent>
                    {title && <HeaderPopoverTitle title={title} centered={centerTitle} />}
                    {content}
                </IonCardContent>
            }
            {lowerContent && (
                <>
                    {content && <Divider />}
                    {lowerContent}
                </>
            )}
        </IonPopover>
    );
};
