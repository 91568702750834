import { Card } from '../components/Card';
import { CardHeading } from './CardHeading';
import { createUseStyles } from 'react-jss';
import { CheckinDetailsToggle } from './CheckinDetailsToggle';
import { ICheckinTag } from './ICheckin';
import { CheckinDetailsConfig, ICheckinDetailsType } from './CheckinDetailsConfig';

const useStyles = createUseStyles({
    icons: {
        marginTop: 18,
        display: 'grid',
        gridRowGap: 28,
        justifyItems: 'center',
        gridTemplateColumns: 'repeat(4, 1fr)'
    }
});

interface ICheckinsDetailsCardProps {
    type: ICheckinDetailsType;
    tags: ICheckinTag[];
    onChange: (tags: ICheckinTag[]) => void;
};

export const CheckinDetailsCard: React.FC<ICheckinsDetailsCardProps> = ({ type, tags, onChange }) => {
    const classes = useStyles();

    const typeConfig = CheckinDetailsConfig[type];

    const isActive = (tag: ICheckinTag) => {
        return tags.some(t => t === tag);
    };

    const clickHandler = (tag: ICheckinTag) => {
        let updatedTags: ICheckinTag[];
        if (tags.includes(tag)) {
            updatedTags = tags.filter(t => t !== tag);
        } else {
            updatedTags = [...tags, tag];
        }
        onChange(updatedTags);
    };

    return (
        <Card padded>
            <CardHeading>{typeConfig.title}</CardHeading>
            <div className={classes.icons}>
                {typeConfig.icons.map(config => (
                    <CheckinDetailsToggle
                        key={config.label}
                        data-type={type}
                        data-id={config.label}
                        Icon={config.Icon}
                        label={config.label}
                        activeColor={config.activeColor}
                        onClick={() => clickHandler(config.label)}
                        active={isActive(config.label)}
                    />
                ))}
            </div>
        </Card>
    );
};
