import { State } from '@meraki-internal/state';
import { CareMapState } from '../caremap/CareMapState';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { IHouseholdMember } from '../caremap/ICareMap';
import { AppPrincipal } from '../auth/AppPrincipal';
import { UserState } from '../user/UserState';

export class InitialWizardState extends State<{}> {
    static inject = () => [CareMapState, HistoryViewModel, AppPrincipal, UserState];
    constructor(private careMap: CareMapState, private history: HistoryViewModel, private principal: AppPrincipal, private user: UserState){
        super({});

        this.careMap.subscribe(() => {
            this.buildSteps();
            this.findCurrentStep();
        });
        this.history.listen(() => {
            this.findCurrentStep();
        });
    }

    private stepIndex = 0;
    private steps: string[] = [];
    private validationStatusByPath: { [path: string]: { isValid: boolean; }} = {};

    private doesUserHaveName = () => Boolean(this.user.getName());

    isLastStep = () => {
        return this.stepIndex === this.steps.length - 1;
    };

    getDefaultRoutePath = () => {
        return this.getRouteForStep(0)!; // there must always be steps therefore there will always be a default (zero-index) one
    };

    getDefaultRoutePathForCaremap = (careMapId: string) => {
        return this.getRouteForStepV2({ index: 0, careMapId })!;
    };

    getCurrentStepIndex = () => this.stepIndex;

    getNumberOfSteps = () => this.steps.length;

    getCurrentStep = () => this.steps[this.getCurrentStepIndex()];

    getRouteForStep = (index: number): string | undefined => {
        const step = this.steps[index];
        const careMapId = this.careMap.getActiveCaremap()?.careMapId;
        if (!careMapId){
            throw new Error(`cannot get route for step index ${index} (${step}) without being in a caremap context (${careMapId} ${JSON.stringify(this.careMap.getActiveCaremap())})`);
        }
        if (step){
            return `/questionnaire/${careMapId}/${step}`;
        }
    };

    getRouteForStepV2 = ({index, careMapId }: { index: number; careMapId: string; }): string | undefined => {
        const step = this.steps[index];
        if (step){
            return `/questionnaire/${careMapId}/${step}`;
        }
    };

    getPreviousRoute = (): string | undefined => {
        return this.getRouteForStep(this.stepIndex - 1);
    };

    getNextRoute = (): string | undefined => {
        return this.getRouteForStep(this.stepIndex + 1);
    };

    canGoToNextStep = () => {
        // not if current step is invalid
        const currentPath = this.getRouteForStep(this.stepIndex);
        // important: if setStepValidity() has not been invoked for currentPath yet, return false
        const isValid = currentPath && this.validationStatusByPath[currentPath]?.isValid;

        return isValid;
    };

    setStepValidity = ({ isValid, stepPath }: { isValid: boolean; stepPath: string; }) => {
        this.validationStatusByPath[stepPath] = { isValid };
        this.setState({ });
    };

    private findCurrentStep = () => {
        const fullStepRoutes = this.steps.map(step => `/questionnaire/${this.careMap.getActiveCaremap()?.careMapId}/${step}`);
        this.stepIndex = fullStepRoutes.indexOf(this.history.getCurrentLocation().pathname);

        if (this.stepIndex === -1){
            // TODO: then go to 0
        }

        this.setState({ });
    };

    private buildSteps = () => {

        const members: IHouseholdMember[] = this.careMap.getActiveCaremap()?.household.members || [];

        const isSchoolRelevant = Boolean(this.careMap.getActiveCaremap()?.initialWizardAnswers['school']);

        const downstreamSchoolSteps = isSchoolRelevant ? [
            'school/common',
            'school/additional'
        ] : [];

        const downstreamExtracurricularCommunitySteps = this.careMap.isCommunityExtracurricularRelevant() ? [
            'community/extracurricular'
        ] : [];

        const downstreamCommunityAdvocacySteps = this.careMap.isCommunityAdvocacyRelevant() ? [
            'community/advocacy'
        ] : [];

        const isForSomeoneElse = !this.careMap.isUserThePatient();

        // if the user has created care maps, and none of them are for themselves, start at recipient-self
        // otherwise, they HAVE created one for themselves so start at consent-someone-else
        const hasMoreMaps = this.careMap.getCareMaps().length > 1;

        const hasOtherCareMapForSelf = this.careMap.hasOtherCareMapForSelf();

        this.steps = [
            ...(this.doesUserHaveName() ? [] : ['my-name']),
            ...(hasMoreMaps && hasOtherCareMapForSelf ? [] : [`recipient-self`]),
            isForSomeoneElse ? `consent-someone-else` : `consent-self`,
            ...(isForSomeoneElse ? [`name`, `recipient`] : []),
            `dob`,
            `household`,
            `patient-photo`,
            ...members.map(member => `household-photo/${member.id}`),
            `school`,
            'community',
            'community2',
            ...downstreamSchoolSteps,
            // always shown
            'medical/common',
            'medical/additional',
            // always shown
            'specialists/common',
            'specialists/additional',
            ...downstreamExtracurricularCommunitySteps,
            ...downstreamCommunityAdvocacySteps,
            // always shown
            'community/general',
            // always shown
            'financial/common'
        ];

        const stepsToSkip = this.careMap.getActiveCaremap()?.initialWizardAnswers['skip-steps'] || [];

        // filtering out steps to skip set by CareMapState.addCaremapToClientUser
        // TODO: should consider moving other skip logic to here
        this.steps = this.steps.filter(s => !stepsToSkip.includes(s));


        this.setState({ });
    };
}
