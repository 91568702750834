import { FC } from 'react';
import { ICareMap } from './ICareMap';
import { MenuItem } from '../components/MenuItem';
import { IonText } from '@ionic/react';
import { MemberAvatar } from '../household/MemberAvatar';
import { ArrowRightIcon } from '../theme/Icons';
import { createUseStyles } from 'react-jss';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from './CareMapState';
import { useSubscription } from '@meraki-internal/state';

const useStyles = createUseStyles({
    recipientAvatar: {
        marginTop: 12,
        marginBottom: 12,
        marginInlineEnd: 18,
        borderRadius: '50%',
        border: '2px solid white',
        boxShadow: '2px 4px 12px #9a9a9a'
    },

    incompleteQuestionnaire: {
        display: 'block',
        fontSize: '0.95em',
        color: 'var(--ion-color-step-750)'
    }
});

export type ICareMapMenuItem = {
    careMap: ICareMap;
    disabled?: boolean;
    onClick?: (map: ICareMap) => void;
};

export const CareMapMenuItem: FC<ICareMapMenuItem> = ({ careMap, disabled = false, onClick = () => {} }) => {
    const classes = useStyles();

    const careMapState = useInstance(CareMapState);
    useSubscription(() => careMapState);

    return (
        <MenuItem
            data-id={careMap.careMapId}
            onClick={disabled ? undefined : () => onClick(careMap)}
            label={(
                <>
                    {careMapState.getPatientFullName(careMap) || 'New Recipient'}
                    {!careMap.essentialMapComplete && (
                        <IonText
                            data-type="incomplete-questionnaire"
                            className={classes.incompleteQuestionnaire}>care map in progress
                        </IonText>
                    )}
                </>
            )}>
            <div slot="start" className={classes.recipientAvatar}>
                <MemberAvatar small member={{photo: careMap.patient.photo, color: careMap.patient.color, avatar: careMap.patient.avatar}} />
            </div>
            {!disabled ? <ArrowRightIcon slot="end" color="medium" /> : undefined}
        </MenuItem>
    );
};
