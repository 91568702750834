import { HEXAGON_HEIGHT, HEXAGON_WIDTH } from './Hexagon';

const GAP_PCT = 5;

export class HoneycombBuilder {

    // indicatorCenter: [x, y] in pixels
    // hexagonOffsets: [ [x1, y1], [x2, y2], ... [xn, yn] ] in hexagons
    // memberCount: number of member hexagons in care map group
    buildNodeConfig = ({ indicatorCenter, hexagonOffsets, memberCount }: { indicatorCenter: [number, number], hexagonOffsets: [number, number][], memberCount: number }) => {

        // check offsets have no duplicates (otherwise hexagons will be on top of each other)
        const duplicate = hexagonOffsets.find((o1, i1) => hexagonOffsets.some((o2, i2) => i2 !== i1 && o2.toString() === o1.toString()));
        if (duplicate) {
            throw new Error(`Found duplicate ${JSON.stringify(duplicate)} in hexagonOffsets ${JSON.stringify(hexagonOffsets)}`);
        }

        const indicatorLeft = indicatorCenter[0] - (HEXAGON_WIDTH / 2);
        const indicatorTop = indicatorCenter[1] - (HEXAGON_HEIGHT / 2);

        // indicator hexagon is at [0,0] so insert it at the start
        const allOffsets = [ [0, 0], ...hexagonOffsets.slice(0, memberCount) ];

        // this will be the extent of the entire honeycomb
        const extent = {
            minX: indicatorCenter[0],
            maxX: indicatorCenter[0],
            minY: indicatorCenter[1],
            maxY: indicatorCenter[1]
        };

        const absolutePositions: [number, number][] = [];

        for (const [x, y] of allOffsets) {
            const isOddCol = (x % 2);

            const left = indicatorLeft + (HEXAGON_WIDTH * x * (75 + GAP_PCT) / 100);
            const top = indicatorTop - (HEXAGON_HEIGHT * (isOddCol ? y - 0.5 : y) * (100 + GAP_PCT) / 100);

            absolutePositions.push([left, top]);

            extent.minX = Math.min(extent.minX, left);
            extent.maxX = Math.max(extent.maxX, left + HEXAGON_WIDTH);
            extent.minY = Math.min(extent.minY, top);
            extent.maxY = Math.max(extent.maxY, top + HEXAGON_HEIGHT);
        }

        // convert absolute positions to relative positions
        const hexagonPositions: [number, number][] = absolutePositions.map(([x, y]) => ([x - extent.minX, y - extent.minY]));

        return {
            position: { x: extent.minX, y: extent.minY },
            style: { width: extent.maxX - extent.minX, height: extent.maxY - extent.minY },
            hexagonPositions,
            simpleHexagonPositions: allOffsets as [number, number][]
        };
    };
}
