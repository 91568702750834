import { IonText } from '@ionic/react';
import { IFeeling } from './ICheckin';
import { MouseEvent } from 'react';
import { FeelingIcon } from './FeelingIcon';

const FEELING_LABELS: {[feeling in IFeeling]: string} = {
    1: 'very good',
    2: 'good',
    3: 'neutral',
    4: 'bad',
    5: 'very bad'
};

export interface IFeelingToggleProps {
    feeling: IFeeling;
    active?: boolean;
    solid?: boolean;
    hideLabel?: boolean;
    onClick?: () => Promise<void>;
}

export const FeelingToggle: React.FC<IFeelingToggleProps> = ({ feeling, active, solid, hideLabel, onClick }) => {
    const opacity = (active ? 1 : 0.5);
    const label = FEELING_LABELS[feeling];

    const handleClick = async (e: MouseEvent) => {
        if (onClick) {
            e.stopPropagation();
            await onClick();
        }
    };

    return (
        <div
            onClick={handleClick}
            data-id={`feeling-${feeling}`}
            data-type={`${active ? 'active' : 'inactive'}`}
            style={{display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: onClick ? 'pointer' : undefined}}
        >
            <div style={{position: 'relative', opacity}}>
                <FeelingIcon
                    feeling={feeling}
                    noColor={!active}
                    solid={solid}
                    size="xlarge"
                />
            </div>
            <IonText color="mediumtext">
                {!hideLabel &&
                    <small><b>{label}</b></small>
                }
            </IonText>
        </div>
    );
};
