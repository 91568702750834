import { ISupportGroupKey } from '../caremap/ICareMap';
import { Position } from 'reactflow';
import { HEXAGON_WIDTH } from '../honeycomb/Hexagon';
import { InnerhiveReactFlowHandle } from '../react-flow/InnerhiveReactFlowHandle';

interface ISupportGroupHandleProps {
    supportGroupKey: ISupportGroupKey;

    // offset of the main hex for the support group
    // we don't render with this, but if that shifts, we have to let react-flow know
    // to recalculate where this handle is
    origin: [number, number];
}
export const SupportGroupHandle: React.FC<ISupportGroupHandleProps> = ({ supportGroupKey, origin }) => {
    return (
        <InnerhiveReactFlowHandle
            type="target"
            isConnectable={false}
            position={Position.Left}
            style={{ left: HEXAGON_WIDTH / 2}}

            // if the origin changes (which hex in the support group container is the main hex)
            // then we need to update react flow internals so it knows the handle moved
            positionKey={`${origin[0]} ${origin[1]}`}
            nodeIdToUpdateOnPositionChanged={`${supportGroupKey}-group`}
        />
    );
};
