import { FC } from 'react';
import { Redirect, Route } from 'react-router';
import { IonCol, isPlatform } from '@ionic/react';
import { DismissableNudgeBanner } from '../components/DismissableNudgeBanner';
import { ReactFlowProvider } from 'reactflow';
import { CareMapPage } from '../caremap/CareMapPage';
import { SearchIcon } from '../theme/Icons';
import { useBreakpoints } from '../theme/useBreakpoints';
import { CareMapState } from '../caremap/CareMapState';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { WelcomePageState } from '../QuestionnaireWelcomePage';
import { ActiveCareMapProviderV2 } from '../ActiveCareMapProvider';
import { useAsyncEffect } from '../support/useAsyncEffect';
import { RedirectToDefaultCaremap } from '../caremap/RedirectToDefaultCaremap';

// TODO: move me
// TODO: this coupling (magic state) is error prone, consider refactor
//          - welcome page state isn't smart enough on its own?
//          - why not have caremapState.addNewCareMap go one step futher and get an id
//              so we can redirect to specific questionaiire and get rid of the empty handler
const AddCareMapRouteHandler: React.FC<{}> = () => {

    const history = useInstance(HistoryViewModel);
    const caremapState = useInstance(CareMapState);
    const welcomePageState = useInstance(WelcomePageState);

    useAsyncEffect(async () => {
        const { careMapId } = await caremapState.addNewCaremapV2();
        welcomePageState.setState({ hasContinued: true });
        history.push(`/questionnaire/${careMapId}`);
    }, [history, caremapState, welcomePageState]);

    return null;
};

export const CareMapRouter: FC = () => {
    const { isBelowBreakpoint } = useBreakpoints();

    const careMapState = useInstance(CareMapState);
    useSubscription(() => careMapState);

    // usage of careMapState should be limited, we can't assume activeCareMap is set or the correct caremap
    return (
        <>
            {/* capture legacy /caremap/ routes and redirect to new /caremaps/ */}
            <Route path="/caremap">
                <Redirect to="/caremaps" />
            </Route>

            <Route path="/add-caremap" render={(props) => {
                // if not it's own component, rendering happens multiple times
                return (
                    <AddCareMapRouteHandler />
                );
            }} />

            <Route path="/caremaps" exact >
                <RedirectToDefaultCaremap />
            </Route>


            <Route path="/caremaps/:careMapId" render={(routeProps) => {
                const careMapId = routeProps.match.params.careMapId;

                return (
                    <ActiveCareMapProviderV2 careMapId={careMapId}>
                        <IonCol size="12">
                            {isBelowBreakpoint('md') &&
                                <DismissableNudgeBanner
                                    nudgeId="larger-screen"
                                    Icon={SearchIcon}
                                    message={`Zoom out to see full map, zoom in for detail. ${isPlatform('capacitor') ? 'You can also use on a computer or tablet!' : 'Try a larger screen for the best experience.'}`}
                                />
                            }
                            <ReactFlowProvider>
                                <CareMapPage />
                            </ReactFlowProvider>
                        </IonCol>
                    </ActiveCareMapProviderV2>
                );
            }}>
            </Route>
        </>
    );
};
