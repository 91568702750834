import { IRelationship, ISupportGroupKey, RELATIONSHIP_KEYS } from './ICareMap';

export type ICommunitySubKey = 'extracurricular' | 'advocacy' | 'general';

type ILookupMap = {[index: string]: string};

interface ISelectOption<T = string> {
    label: string;
    value: T;
}

export class SupportGroupDataProvider {

    getDiagnosisSelectOptions = () => {
        return this.getSelectOptions(this.diagnoses) as ISelectOption[];
    };

    diagnoses: {[diagnosisId: string]: string} = {
        'acquired-immunodeficiency-syndrome': 'Acquired Immunodeficiency Syndrome (AIDS)',
        'alcohol-related-birth-defects': 'Alcohol-Related Birth Defects (ARBD)',
        'alzheimers-disease': 'Alzheimer’s Disease',
        'anemia': 'Anemia',
        'anxiety': 'Anxiety',
        'anxiety-and-depression': 'Anxiety and Depression',
        'anxiety-disorder': 'Anxiety Disorder',
        'arthritis': 'Arthritis',
        'asthma': 'Asthma',
        'attention-deficit-disorder': 'Attention Deficit Disorder',
        'attention-deficit-hyperactivity-disorder': 'Attention Deficit Hyperactivity Disorder (ADHD)',
        'auditory-processing-disorder': 'Auditory Processing Disorder',
        'autism-spectrum-disorder': 'Autism Spectrum Disorder (ASD)',
        'bladder-cancer': 'Bladder Cancer',
        'blindness': 'Blindness',
        'brain-cancer': 'Brain Cancer',
        'breast-cancer': 'Breast Cancer',
        'bronchitis': 'Bronchitis',
        'bronchopulmonary-dysplasia': 'Bronchopulmonary Dysplasia (BPD)',
        'cancer': 'Cancer',
        'celiac-disease': 'Celiac Disease',
        'cerebral-palsy': 'Cerebral Palsy (CP)',
        'chronic-bronchitis': 'Chronic Bronchitis',
        'chronic-fatigue-syndrome': 'Chronic Fatigue Syndrome (CFS)',
        'chronic-heart-failure': 'Chronic Heart Failure',
        'chronic-kidney-disease': 'Chronic Kidney Disease (CKD)',
        'chronic-liver-disease': 'Chronic Liver Disease',
        'chronic-lung-disease': 'Chronic Lung Disease',
        'chronic-lymphocytic-leukemia': 'Chronic Lymphocytic Leukemia (CLL)',
        'chronic-myeloid-leukemia': 'Chronic Myeloid Leukemia (CML)',
        'chronic-obstructive-pulmonary-disease': 'Chronic Obstructive Pulmonary Disease (COPD)',
        'chronic-pain': 'Chronic Pain',
        'chronic-respiratory-disease': 'Chronic Respiratory Disease',
        'colorectal-cancer': 'Colorectal Cancer',
        'conduct-disorder': 'Conduct Disorder (CDD)',
        'congenital-heart-disease': 'Congenital Heart Disease',
        'coronary-artery-disease': 'Coronary Artery Disease (CAD)',
        'coronary-heart-disease': 'Coronary Heart Disease (CHD)',
        'cystic-fibrosis': 'Cystic Fibrosis',
        'deafness': 'Deafness',
        'dementia': 'Dementia',
        'depression': 'Depression',
        'developmental-disabilities': 'Developmental Disabilities',
        'diabetes-type-1': 'Diabetes (Type 1)',
        'diabetes-type-2': 'Diabetes (Type 2)',
        'down-syndrome': 'Down Syndrome',
        'dwarfism': 'Dwarfism',
        'dyscalculia': 'Dyscalculia',
        'dysgraphia': 'Dysgraphia',
        'dyslexia': 'Dyslexia',
        'dyspraxia': 'Dyspraxia',
        'eating-disorder': 'Eating Disorder',
        'eczema': 'Eczema',
        'emphysema': 'Emphysema',
        'epilepsy': 'Epilepsy',
        'executive-function-disorder': 'Executive Function Disorder (EFD)',
        'fetal-alcohol-spectrum-disorder': 'Fetal Alcohol Spectrum Disorder (FASD)',
        'fetal-alcohol-syndrome': 'Fetal Alcohol Syndrome (FAS)',
        'fragile-x-syndrome': 'Fragile X Syndrome (FXS)',
        'gastroesophageal-reflux-disease': 'Gastroesophageal Reflux Disease (GERD)',
        'head-trauma': 'Head Trauma',
        'hearing-impairment': 'Hearing Impairment',
        'hearing-loss': 'Hearing Loss',
        'heart-disease': 'Heart Disease',
        'hemophilia': 'Hemophilia',
        'hodgkin-lymphoma': 'Hodgkin Lymphoma',
        'human-immunodeficiency-virus': 'Human Immunodeficiency Virus (HIV)',
        'hyperlipidemia': 'Hyperlipidemia (High Cholesterol)',
        'hypertension': 'Hypertension (High Blood Pressure)',
        'hypothyroidism': 'Hypothyroidism',
        'inflammatory-bowel-disease': 'Inflammatory Bowel Disease',
        'intellectual-and-developmental-disabilities': 'Intellectual and Developmental Disabilities (IDD)',
        'intellectual-disability': 'Intellectual Disability',
        'learning-disabilities': 'Learning Disabilities',
        'leukemia': 'Leukemia',
        'lung-cancer': 'Lung Cancer',
        'melanoma': 'Melanoma',
        'muscular-dystrophy': 'Muscular Dystrophy',
        'myalgic-encephalomyelitis': 'Myalgic Encephalomyelitis (ME)',
        'non-hodgkin-lymphoma': 'Non-Hodgkin Lymphoma',
        'obesity': 'Obesity',
        'obsessive-compulsive-disorder': 'Obsessive-Compulsive Disorder (OCD)',
        'oppositional-defiant-disorder': 'Oppositional Defiant Disorder (ODD)',
        'osteoporosis': 'Osteoporosis',
        'pancreatic-cancer': 'Pancreatic Cancer',
        'parkinsons-disease': 'Parkinson’s Disease',
        'partial-fetal-alcohol-syndrome': 'Partial Fetal Alcohol Syndrome (pFAS)',
        'polycystic-ovary-syndrome': 'Polycystic Ovary Syndrome (PCOS)',
        'post-traumatic-stress-disorder': 'Post-Traumatic Stress Disorder (PTSD)',
        'prematurity': 'Prematurity',
        'prostate-cancer': 'Prostate Cancer',
        'recurring-bronchitis': 'Recurring Bronchitis',
        'respiratory-syncytial-virus': 'Respiratory Syncytial Virus (RSV)',
        'sensory-processing-disorder': 'Sensory Processing Disorder',
        'severe-combined-immunodeficiency': 'Severe Combined Immunodeficiency (SCID)',
        'shingles': 'Shingles',
        'short-bowel-syndrome': 'Short Bowel Syndrome',
        'sickle-cell-disease': 'Sickle Cell Disease',
        'specific-language-impairment': 'Specific Language Impairment (SLI)',
        'spina-bifida': 'Spina Bifida',
        'spinal-muscular-atrophy': 'Spinal Muscular Atrophy (SMA)',
        'spondyloepiphyseal-dysplasia-congenita': 'Spondyloepiphyseal Dysplasia Congenita (SEDC)',
        'stroke': 'Stroke',
        'tourette-syndrome': 'Tourette Syndrome',
        'vision-and-hearing-impairments': 'Vision and Hearing Impairments',
        'vision-impairment': 'Vision Impairment',
        'vision-loss': 'Vision Loss',
    };

    getAllergySelectOptions = () => {
        return this.getSelectOptions(this.allergies) as ISelectOption[];
    };

    allergies: {[allergyId: string]: string} = {
        'milk': 'Milk',
        'dairy': 'Dairy',
        'eggs': 'Eggs',
        'peanuts': 'Peanuts',
        'tree-nuts': 'Tree Nuts',
        'fish': 'Fish',
        'shellfish': 'Shellfish',
        'wheat': 'Wheat',
        'soy': 'Soy',
        'sesame': 'Sesame',
        'mustard': 'Mustard',
        'celery': 'Celery',
        'lupin': 'Lupin',
        'sulfites': 'Sulfites',
        'kiwi': 'Kiwi',
        'banana': 'Banana',
        'avocado': 'Avocado',
        'corn': 'Corn',
        'buckwheat': 'Buckwheat',
        'garlic': 'Garlic',
        'oats': 'Oats',
        'poultry-meat': 'Poultry Meat',
        'red-meat': 'Red Meat',
        'rice': 'Rice',
        'tartrazine': 'Tartrazine',
        'balsam-of-peru': 'Balsam of Peru',
        'pollen': 'Pollen',
        'cats': 'Cats',
        'dogs': 'Dogs',
        'bees': 'Bees',
        'cockroaches': 'Cockroaches',
        'mold': 'Mold',
        'fragrances': 'Fragrances',
        'cosmetics': 'Cosmetics',
        'dust-mites': 'Dust mites',
        'latex': 'Latex',
        'penicillin': 'Penicillin',
        'local-anesthetics': 'Local Anesthetics',
        'non-steroidal-anti-inflammatories': 'Non-Steroidal Anti-Inflammatories',
        'tetracycline': 'Tetracycline',
        'sulfonamides': 'Sulfonamides',
        'cephalosporins': 'Cephalosporins',
        'phenytoin': 'Phenytoin',
        'carbamazepine': 'Carbamazepine',
        'intravenous-contrast-dye': 'Intravenous Contrast Dye'
    };

    getRelationshipSelectOptions = () => {
        return this.getSelectOptions(this.relationships) as ISelectOption<IRelationship>[];
    };

    getSelectOptions = (map: ILookupMap): ISelectOption[] => {
        return Object.keys(map).reduce((acc: any[], curr: string) => {
            acc.push({value: curr, label: map[curr]});
            return acc;
        }, []);
    };

    getSupportGroupMemberSelectOptions = (supportGroupKey: ISupportGroupKey) => {
        if (supportGroupKey === 'school') {
            return this.getSchoolSupportGroupMemberSelectOptions();
        }
        if (supportGroupKey === 'medical') {
            return this.getMedicalSupportGroupMemberSelectOptions();
        }
        if (supportGroupKey === 'specialists') {
            return this.getSpecialistsSupportGroupMemberSelectOptions();
        }
        if (supportGroupKey === 'community') {
            return this.getCommunitySupportGroupMemberSelectOptions();
        }
        if (supportGroupKey === 'financial') {
            return this.getFinancialSupportGroupMemberSelectOptions();
        }
        return [];
    };

    getSchoolSupportGroupMemberSelectOptions = (): ISelectOption[] => {
        return this.getSelectOptions(this.roles['school']);
    };

    getMedicalSupportGroupMemberSelectOptions = (): ISelectOption[] => {
        return this.getSelectOptions(this.roles['medical']);
    };

    getSpecialistsSupportGroupMemberSelectOptions = (): ISelectOption[] => {
        return this.getSelectOptions(this.roles['specialists']);
    };

    getFinancialSupportGroupMemberSelectOptions = (): ISelectOption[] => {
        return this.getSelectOptions(this.roles['financial']);
    };

    getCommunitySupportGroupMemberSelectOptions = (subKey?: ICommunitySubKey): ISelectOption[] => {
        const roles: ILookupMap = {};
        Object.keys(this.roles['community']).forEach((key) => {
            if (!subKey || key.indexOf('_' + subKey) > -1) {
                roles[key] = this.roles['community'][key];
            }
        });
        return this.getSelectOptions(roles);
    };

    getRolesArray = ({ supportGroupKey, subKey }: {supportGroupKey: ISupportGroupKey; subKey?: ICommunitySubKey }): { roleId: string; label: string; }[] => {
        let roles = this.roles[supportGroupKey];

        if (subKey) {
            roles = {};
            Object.keys(this.roles['community']).forEach((key) => {
                if (key.indexOf('_' + subKey) > -1) {
                    roles[key] = this.roles['community'][key];
                }
            });
        }

        return Object.keys(roles).map(roleId => ({ roleId, label: roles[roleId] }));
    };

    getRoleLabel = ({ supportGroupKey, roleId }: { supportGroupKey: ISupportGroupKey, roleId: string }) => {
        const map = this.roles[supportGroupKey];
        const role = map[roleId];
        if (!role) {
            throw new Error(`${supportGroupKey} roleId "${roleId}" not found`);
        }
        return role;
    };

    relationships: {[relationshipId in IRelationship]: string} = {
        'adoptive-parent': 'Adoptive Parent',
        'aunt': 'Aunt',
        'boyfriend': 'Boyfriend',
        'brother': 'Brother',
        'brother-in-law': 'Brother-in-Law',
        'caregiver': 'Caregiver',
        'child': 'Child',
        'child-in-law': 'Child-in-Law',
        'cousin': 'Cousin',
        'cousin-in-law': 'Cousin-in-Law',
        'daughter': 'Daughter',
        'daughter-in-law': 'Daughter-in-Law',
        'domestic-partner': 'Domestic Partner',
        'father': 'Father',
        'father-in-law': 'Father-in-Law',
        'foster-parent': 'Foster Parent',
        'friend': 'Friend',
        'girlfriend': 'Girlfriend',
        'granddaughter': 'Granddaughter',
        'grandchild': 'Grandchild',
        'grandfather': 'Grandfather',
        'grandmother': 'Grandmother',
        'grandparent': 'Grandparent',
        'grandson': 'Grandson',
        'guardian': 'Guardian',
        'half-brother': 'Half-Brother',
        'half-sibling': 'Half-Sibling',
        'half-sister': 'Half-Sister',
        'housemate': 'Housemate',
        'husband': 'Husband',
        'mother': 'Mother',
        'mother-in-law': 'Mother-in-Law',
        'niece': 'Niece',
        'nephew': 'Nephew',
        'nibling': 'Nibling',
        'parent': 'Parent',
        'parent-in-law': 'Parent-in-Law',
        'partner': 'Partner',
        'pibling': 'Pibling',
        'roommate': 'Roommate',
        'sibling': 'Sibling',
        'sibling-in-law': 'Sibling-in-Law',
        'significant-other': 'Significant Other',
        'sister': 'Sister',
        'sister-in-law': 'Sister-in-Law',
        'son': 'Son',
        'son-in-law': 'Son-in-Law',
        'spouse': 'Spouse',
        'step-brother': 'Step-Brother',
        'step-father': 'Step-Father',
        'step-mother': 'Step-Mother',
        'step-parent': 'Step-Parent',
        'step-sibling': 'Step-Sibling',
        'step-sister': 'Step-Sister',
        'stepchild': 'Stepchild',
        'uncle': 'Uncle',
        'wife': 'Wife'
    };

    roles: {
        [key in ISupportGroupKey]: ILookupMap
    } = {
            community: {
                'adaptive-athletics_extracurricular': 'Adaptive Athletics',
                'art-and-crafts_extracurricular': 'Art and Crafts',
                'camps_extracurricular': 'Camps',
                'clubs-and-organizations_extracurricular': 'Clubs and Organizations',
                'cooking-and-food_extracurricular': 'Cooking and Food',
                'drama-and-theater_extracurricular': 'Drama and Theater',
                'gaming-and-esports_extracurricular': 'Gaming and Esports',
                'health-and-fitness_extracurricular': 'Health and Fitness',
                'hobby_extracurricular': 'Hobby',
                'language-and-cultural_extracurricular': 'Language and Cultural',
                'music-and-dance_extracurricular': 'Music and Dance',
                'outdoor-and-adventure_extracurricular': 'Outdoor and Adventure',
                'science-and-stem_extracurricular': 'Science and STEM',
                'spiritual-and-religious_extracurricular': 'Spiritual and Religious',
                'sports-and-athletics_extracurricular': 'Sports and Athletics',
                'volunteering-and-community-service_extracurricular': 'Volunteering and Community Service',
                'writing-and-literary_extracurricular': 'Writing and Literary',

                'support-group_advocacy': 'Support Group',
                'community-group_advocacy': 'Community Group',
                'advocacy-group_advocacy': 'Advocacy Group',
                'nonprofit_advocacy': 'Nonprofit',
                'blog_advocacy': 'Blog',
                'forum_advocacy': 'Forum',
                'spiritual-group_advocacy': 'Spiritual Group',
                'church-group_advocacy': 'Church Group',
                'church_advocacy': 'Church',
                'family-group_advocacy': 'Family Group',
                'neighborhood-group_advocacy': 'Neighborhood Group',

                'neighbors_general': 'Neighbor(s)',
                'personal-care-attendant_general': 'Personal Care Attendant (PCA)',
                'respite-caregiver_general': 'Respite Caregiver',
                // plus all relationships from list above
                // e.g. 'adoptive-parent_general': 'Adoptive Parent',
                ...RELATIONSHIP_KEYS.reduce((m, k) => ({...m, [k + '_general']: this.relationships[k]}), {})
            },
            medical: {
                'primary-care-physician': 'Primary Care Physician',
                'care-manager': 'Care Manager',
                'registered-nurse': 'Registered Nurse (RN)',
                'social-worker': 'Social Worker',
                'nurse-practitioner': 'Nurse Practitioner',
                'physician-assistant': 'Physician Assistant',
                'medical-office-receptionist': 'Medical Office Receptionist',
                'medical-equipment-specialist': 'Medical Equipment Specialist',
                'dietician': 'Dietician',
                'interpreter': 'Interpreter',
                'care-coordinator': 'Care Coordinator',
                'case-manager': 'Case Manager',
                'clinical-nurse-specialist': 'Clinical Nurse Specialist',
                'developmental-pediatrician': 'Developmental Pediatrician',
                'home-health-aide': 'Home Health Aide',
                'home-health-nurse': 'Home Health Nurse',
                'licensed-practical-nurse': 'Licensed Practical Nurse',
                'medical-technologist': 'Medical Technologist',
                'nurse': 'Nurse',
                'nutritionist': 'Nutritionist',
                'pediatric-specialist': 'Pediatric Specialist',
                'pediatrician': 'Pediatrician',
                'family-physician': 'Family Physician',
                'general-practitioner': 'General Practitioner'
            },
            financial: {
                'attorney':'Attorney',
                'attorney-and-trust':'Attorney and Trust',
                'childrens-health-insurance-program':'Children’s Health Insurance Program (CHIP)',
                'dietary-assistance':'Dietary Assistance',
                'early-intervention':'Early Intervention',
                'education-attorney':'Education Attorney',
                'education-consultant':'Education Consultant',
                'federal':'Federal',
                'financial':'Financial',
                'financial-planning':'Financial Planning',
                'government':'Government',
                'government-benefits-specialist':'Government Benefits Specialist',
                'government-program':'Government Program',
                'grants':'Grant(s)',
                'guardianship-attorney':'Guardianship Attorney',
                'health-insurance':'Health Insurance',
                'health-insurance-specialist':'Health Insurance Specialist',
                'housing':'Housing',
                'housing-assistance-advisor':'Housing Assistance Advisor',
                'housing-coordinator':'Housing Coordinator',
                'information':'Information',
                'insurance':'Insurance',
                'legal':'Legal',
                'local-representative':'Local Representative',
                'mediation-services':'Mediation Services',
                'national-representative':'National Representative',
                'program-liaison':'Program Liaison',
                'respite':'Respite',
                'social-security':'Social Security',
                'special-education-advocate':'Special Education Advocate',
                'state':'State',
                'state-representative':'State Representative',
                'supplemental-nutrition-assistance-program':'Supplemental Nutrition Assistance Program (SNAP)',
                'supplemental-security-income':'Supplemental Security Income (SSI)',
                'temporary-assistance-for-needy-families':'Temporary Assistance for Needy Families (TANF)',
                'transition-planning':'Transition Planning',
                'transition-planning-coordinator':'Transition Planning Coordinator',
                'transportation':'Transportation',
                'transportation-assistance':'Transportation Assistance',
                'trust':'Trust',
                'trust-estate-attorney':'Trust Estate Attorney',
                'trust-estate-money-planner':'Trust Estate Money Planner',
                'trusts':'Trust(s)',
                'va-pensions':'VA Pensions',
                'waiver-coordinator':'Waiver Coordinator',
                'workplace-accommodation-specialist':'Workplace Accommodation Specialist'
            },
            school: {
                'classroom-teacher': 'Classroom Teacher',
                'paraeducator': 'Paraeducator',
                'individual-education-plan-director': 'Individual Education Plan (IEP) Director',
                'school-nurse': 'School Nurse',
                'guidance-counselor': 'Guidance Counselor',
                'principal': 'Principal',
                'assistive-technology-specialist': 'Assistive Technology Specialist',
                'school-physical-therapist': 'School Physical Therapist (PT)',
                'school-speech-language-pathologist': 'School Speech-Language Pathologist (SLP)',
                'transportation-support': 'Transportation Support',
                'special-education-aide': 'Special Education Aide',
                'adapted-physical-education-specialist': 'Adapted Physical Education Specialist',
                'adaptive-education-teacher': 'Adaptive Education Teacher',
                'adaptive-pe-teacher': 'Adaptive PE Teacher',
                'administrative-assistant': 'Administrative Assistant',
                'alternative-education-teacher': 'Alternative Education Teacher',
                'art-teacher': 'Art Teacher',
                'assistant-coach': 'Assistant Coach',
                'assistant-principal': 'Assistant Principal',
                'assistant-teacher': 'Assistant Teacher',
                'athletic-director': 'Athletic Director',
                'behavior-specialist': 'Behavior Specialist',
                'board-certified-behavior-analyst': 'Board Certified Behavior Analyst (BCBA)',
                'bus-driver': 'Bus Driver',
                'transport-driver': 'Transport Driver',
                'cafeteria-staff': 'Cafeteria Staff',
                'career-and-technical-education-(cte)-teacher': 'Career and Technical Education (CTE) Teacher',
                'career-counselor': 'Career Counselor',
                'case-manager': 'Case Manager',
                'classroom-aide': 'Classroom Aide',
                'coach': 'Coach',
                'crossing-guard': 'Crossing Guard',
                'curriculum-specialist': 'Curriculum Specialist',
                'dance-teacher': 'Dance Teacher',
                'development-consultant': 'Development Consultant',
                'district-nurse': 'District Nurse',
                'district-therapist': 'District Therapist',
                'drama-teacher': 'Drama Teacher',
                'theater-teacher': 'Theater Teacher',
                'educational-diagnostician': 'Educational Diagnostician',
                'english-as-a-second-language-teacher': 'English as a Second Language (ESL) Teacher',
                'english-teacher': 'English Teacher',
                'language-arts-teacher': 'Language Arts Teacher',
                'food-service-manager': 'Food Service Manager',
                'food-service-worker': 'Food Service Worker',
                'foreign-language-teacher': 'Foreign Language Teacher',
                'general-education-teacher': 'General Education Teacher',
                'gifted-and-talented-coordinator': 'Gifted and Talented Coordinator',
                'health-aide': 'Health Aide',
                'individual-education-plan-case-manager': 'Individual Education Plan (IEP) Case Manager',
                'instructional-coach': 'Instructional Coach',
                'intervention-specialist': 'Intervention Specialist',
                'learning-support-specialist': 'Learning Support Specialist',
                'media-specialist': 'Media Specialist',
                'lunchroom-staff': 'Lunchroom Staff',
                'main-office-staff': 'Main Office Staff',
                'math-specialist': 'Math Specialist',
                'math-teacher': 'Math Teacher',
                'music-teacher': 'Music Teacher',
                'orientation-and-mobility-specialist': 'Orientation and Mobility Specialist',
                'paraprofessional': 'Paraprofessional',
                'reading-specialist': 'Reading Specialist',
                'school-administrator': 'School Administrator',
                'school-attendance-officer': 'School Attendance Officer',
                'school-autism-specialist': 'School Autism Specialist',
                'school-behavioral-technician': 'School Behavioral Technician',
                'school-braille-specialist': 'School Braille Specialist',
                'school-community-engagement-coordinator': 'School Community Engagement Coordinator',
                'school-counselor': 'School Counselor',
                'school-crisis-intervention-specialist': 'School Crisis Intervention Specialist',
                'school-custodian': 'School Custodian',
                'school-data-analyst': 'School Data Analyst',
                'school-deaf-blind-specialist': 'School Deaf-Blind Specialist',
                'school-event-planner': 'School Event Planner',
                'school-fundraising-coordinator': 'School Fundraising Coordinator',
                'school-garden-coordinator': 'School Garden Coordinator',
                'school-grant-writer': 'School Grant Writer',
                'school-hearing-specialist': 'School Hearing Specialist',
                'school-interventionist': 'School Interventionist',
                'school-it-technician': 'School IT Technician',
                'school-janitor': 'School Janitor',
                'school-librarian': 'School Librarian',
                'school-lunch-coordinator': 'School Lunch Coordinator',
                'school-maintenance-worker': 'School Maintenance Worker',
                'school-communications-specialist': 'School Communications Specialist',
                'school-nutritionist': 'School Nutritionist',
                'school-dietician': 'School Dietician',
                'school-occupational-therapist': 'School Occupational Therapist (OT)',
                'school-occupational-therapy-assistant': 'School Occupational Therapy (OT) Assistant',
                'school-parent-liaison': 'School Parent Liaison',
                'school-parent-coordinator': 'School Parent Coordinator',
                'school-photographer': 'School Photographer',
                'school-videographer': 'School Videographer',
                'school-physical-therapy-assistant': 'School Physical Therapy (PT) Assistant',
                'school-psychologist': 'School Psychologist',
                'school-registrar': 'School Registrar',
                'school-resource-officer': 'School Resource Officer',
                'school-safety-coordinator': 'School Safety Coordinator',
                'school-safety-officer': 'School Safety Officer',
                'school-secretary': 'School Secretary',
                'school-security-officer': 'School Security Officer',
                'school-sign-language-interpreter': 'School Sign Language Interpreter',
                'school-social-worker': 'School Social Worker',
                'school-speech-and-language-pathology-assistant': 'School Speech and Language Pathology (SLP) Assistant',
                'school-speech-therapist': 'School Speech Therapist',
                'school-technology-specialist': 'School Technology Specialist',
                'school-vision-specialist': 'School Vision Specialist',
                'school-vision-therapist': 'School Vision Therapist',
                'school-volunteer-coordinator': 'School Volunteer Coordinator',
                'science-teacher': 'Science Teacher',
                'social-studies-teacher': 'Social Studies Teacher',
                'history-teacher': 'History Teacher',
                'special-education-director': 'Special Education Director',
                'special-education-liason': 'Special Education Liason',
                'special-education-teacher': 'Special Education Teacher',
                'student-teacher': 'Student Teacher',
                'substitute-teacher': 'Substitute Teacher',
                'superintendent': 'Superintendent',
                'teaching-assistant': 'Teaching Assistant',
                'technology-teacher': 'Technology Teacher',
                'computer-teacher': 'Computer Teacher',
                'transition-specialist': 'Transition Specialist',
                'vice-principal': 'Vice Principal'
            },
            specialists: {
                'dentist': 'Dentist',
                'physical-therapist': 'Physical Therapist',
                'clinical-therapist': 'Clinical Therapist',
                'nutritionist': 'Nutritionist',
                'neurologist': 'Neurologist',
                'cardiologist': 'Cardiologist',
                'dermatologist': 'Dermatologist',
                'speech-language-pathologist': 'Speech-Language Pathologist (SLP)',
                'palliative-care-specialist': 'Palliative Care Specialist',
                'occupational-therapist': 'Occupational Therapist',
                'abdominal-radiologist': 'Abdominal Radiologist',
                'addiction-psychiatrist': 'Addiction Psychiatrist',
                'advocacy-specialist': 'Advocacy Specialist',
                'allergist': 'Allergist',
                'anesthesiologist': 'Anesthesiologist',
                'art-therapist': 'Art Therapist',
                'assistive-communication-specialist': 'Assistive Communication Specialist',
                'assistive-technology-engineer': 'Assistive Technology Engineer',
                'assistive-technology-specialist': 'Assistive Technology Specialist',
                'athletic-trainer': 'Athletic Trainer',
                'audiologist': 'Audiologist',
                'augmentative-and-alternative-communication-specialist': 'Augmentative and Alternative Communication (AAC) Specialist',
                'behavior-technician': 'Behavior Technician',
                'behavioral-analyst': 'Behavioral Analyst',
                'behavioral-health-counselor': 'Behavioral Health Counselor',
                'behavioral-therapist': 'Behavioral Therapist',
                'bioethicist': 'Bioethicist',
                'biomedical-engineer': 'Biomedical Engineer',
                'cardiothoracic-radiologist': 'Cardiothoracic Radiologist',
                'cardiovascular-radiologist': 'Cardiovascular Radiologist',
                'caregiver': 'Caregiver',
                'family-navigator': 'Family Navigator',
                'chaplain': 'Chaplain',
                'spiritual-counselor': 'Spiritual Counselor',
                'chest-radiologist': 'Chest Radiologist',
                'developmental-pediatrician': 'Developmental Pediatrician',
                'developmental-therapist': 'Developmental Therapist',
                'diagnostic-radiologist': 'Diagnostic Radiologist',
                'dietician': 'Dietician',
                'ear-nose-throat-doctor': 'Ear Nose Throat (ENT) Doctor',
                'early-intervention-specialist': 'Early Intervention Specialist',
                'echocardiogram-technician': 'Echocardiogram Technician',
                'education-specialist': 'Education Specialist',
                'electrophysiologist': 'Electrophysiologist',
                'emergency-medicine-physician': 'Emergency Medicine Physician',
                'emergency-radiologist': 'Emergency Radiologist',
                'endocronologist': 'Endocronologist',
                'endovascular-surgical-neuroradiologist': 'Endovascular Surgical Neuroradiologist',
                'family-support-specialist': 'Family Support Specialist',
                'gastroenterologist': 'Gastroenterologist',
                'gastrointestinal-radiologist': 'Gastrointestinal Radiologist',
                'geneticist': 'Geneticist',
                'genitourinary-radiologist': 'Genitourinary Radiologist',
                'geriatric-doctor': 'Geriatric Doctor',
                'gynecologist': 'Gynecologist',
                'head-and-neck-radiologist': 'Head and Neck Radiologist',
                'health-data-analyst': 'Health Data Analyst',
                'health-educator': 'Health Educator',
                'health-information-specialist': 'Health Information Specialist',
                'health-insurance-specialist': 'Health Insurance Specialist',
                'health-policy-analyst': 'Health Policy Analyst',
                'hearing-specialist': 'Hearing Specialist',
                'hematologist': 'Hematologist',
                'hepatology': 'Hepatology',
                'hospice-care-specialist': 'Hospice Care Specialist',
                'immunopathologist': 'Immunopathologist',
                'interventional-radiologist': 'Interventional Radiologist',
                'medical-equipment-specialist': 'Medical Equipment Specialist',
                'medical-researcher': 'Medical Researcher',
                'musculoskeletal-radiologist': 'Musculoskeletal Radiologist',
                'music-therapist': 'Music Therapist',
                'neonatologist': 'Neonatologist',
                'nephrologist': 'Nephrologist',
                'neuro-opthalmologist': 'Neuro-Opthalmologist',
                'neuro-urlogist': 'Neuro-Urlogist',
                'neurodevelopmental-specialist': 'Neurodevelopmental Specialist',
                'neuropathologist': 'Neuropathologist',
                'neuroradiologist': 'Neuroradiologist',
                'neurosurgeon': 'Neurosurgeon',
                'nuclear-radiologist': 'Nuclear Radiologist',
                'oncologist': 'Oncologist',
                'opthamologist': 'Opthamologist',
                'optometrist': 'Optometrist',
                'orthopedic-doctor': 'Orthopedic Doctor',
                'orthopedic-surgeon': 'Orthopedic Surgeon',
                'orthotist': 'Orthotist',
                'otolaryngologists': 'Otolaryngologists',
                'palliative-physician': 'Palliative Physician',
                'parent-trainer': 'Parent Trainer',
                'parent-educator': 'Parent Educator',
                'pathologist': 'Pathologist',
                'pharmacist': 'Pharmacist',
                'pharmacologist': 'Pharmacologist',
                'physiatrist': 'Physiatrist',
                'physician-assistant': 'Physician Assistant',
                'physiotherapist': 'Physiotherapist',
                'plastic-surgeon': 'Plastic Surgeon',
                'play-therapist': 'Play Therapist',
                'program-manager': 'Program Manager',
                'program-coordinator': 'Program Coordinator',
                'prosthetist': 'Prosthetist',
                'psychiatrist': 'Psychiatrist',
                'psychologist': 'Psychologist',
                'psychotherapist': 'Psychotherapist',
                'public-health-specialist': 'Public Health Specialist',
                'pulmonologist': 'Pulmonologist',
                'quality-improvement-specialist': 'Quality Improvement Specialist',
                'radiation-oncologist': 'Radiation Oncologist',
                'radiologist': 'Radiologist',
                'recreational-therapist': 'Recreational Therapist',
                'rehabilitation-counselor': 'Rehabilitation Counselor',
                'rehabilitation-engineer': 'Rehabilitation Engineer',
                'respiratory-therapist': 'Respiratory Therapist',
                'respite-care-provider': 'Respite Care Provider',
                'rheumatologist': 'Rheumatologist',
                'seating-and-mobility-specialist': 'Seating and Mobility Specialist',
                'social-skills-trainer': 'Social Skills Trainer',
                'speech-therapist': 'Speech Therapist',
                'surgeon': 'Surgeon',
                'telehealth-provider': 'Telehealth Provider',
                'toxicologist': 'Toxicologist',
                'urologist': 'Urologist',
                'vascular-and-interventional-radiologist': 'Vascular and Interventional Radiologist',
                'vision-specialist': 'Vision Specialist',
                'vision-therapist': 'Vision Therapist',
                'orthodontist': 'Orthodontist'
            },
            social: {
                // TODO
            }
        };
}
