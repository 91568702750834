import { FC } from 'react';
import { Checkbox, ICheckboxProps } from './Checkbox';

export const PrivateCheckbox: FC<Omit<ICheckboxProps, 'label'>> = ({ checked, onChange, 'data-id': dataId }) => {
    return (
        <Checkbox
            label="Private"
            checked={checked}
            data-id={dataId}
            onChange={onChange} />
    );
};
