import { State } from '@meraki-internal/state';

export type IOrgSelectedNavItem = 'clients' | 'team' | undefined;

export class OrgNavigationMenuState extends State<{selectedNavItem: IOrgSelectedNavItem}> {
    constructor() {
        super({selectedNavItem: undefined});
    };

    setSelectedNavItem = (selectedNavItem: IOrgSelectedNavItem) => {
        this.setState({ selectedNavItem });
    };
}
