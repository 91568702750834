import { FC } from 'react';
import { CareMapDrawer } from '../caremap/CareMapDrawer';
import { Redirect, Route } from 'react-router';
import { TrackPageView } from '../metrics/TrackPageView';
import { HouseholdMemberList } from '../household/HouseholdMemberList';
import { PatientView } from '../household/PatientView';
import { HouseholdMemberView } from '../household/HouseholdMemberView';
import { PatientEdit } from '../household/PatientEdit';
import { HouseholdMemberEdit } from '../household/HouseholdMemberEdit';
import { NotesView } from '../notes/NotesView';
import { CaremapNoteEditor } from '../notes/CaremapNoteEditor';
import { AppointmentsView } from '../appointments/AppointmentsView';
import { AppointmentEditorDrawer } from '../appointments/ApointmentEditorDrawer';
import { useDismissOverlays } from '../utils/useDismissOverlays';
import { AppPrincipal, isCaremapViewer } from '../auth/AppPrincipal';
import { useInstance } from '@meraki-internal/react-dependency-injection';

export const HouseholdRouter: FC = () => {
    const isViewer = isCaremapViewer(useInstance(AppPrincipal));

    const dismissOverlays = useDismissOverlays();
    return (
        <CareMapDrawer
            basePath="/caremaps/:careMapId/drawer/household"
        >
            <Route exact path="/caremaps/:careMapId/drawer/household" render={() => (
                <>
                    <TrackPageView page="Household List" />
                    <HouseholdMemberList />
                </>
            )} />

            <Route exact path="/caremaps/:careMapId/drawer/household/member/:memberId" render={(routeProps) => {
                const memberId = routeProps.match.params.memberId;
                if (memberId === 'patient') {
                    return (
                        <>
                            <TrackPageView page="Care Recipient Details" />
                            <PatientView />
                        </>
                    );
                }
                return (
                    <>
                        <TrackPageView page="Household Member Details" />
                        <HouseholdMemberView memberId={memberId} />
                    </>
                );
            }} />

            <Route exact path="/caremaps/:careMapId/drawer/household/member/:memberId/edit" render={(routeProps) => {
                if (isViewer) {
                    const careMapId = routeProps.match.params.careMapId;
                    return <Redirect to={`/caremaps/${careMapId}`} />;
                }
                const memberId = routeProps.match.params.memberId;
                if (memberId === 'patient') {
                    return <PatientEdit />;
                }
                return <HouseholdMemberEdit memberId={memberId} />;
            }} />

            <Route exact path="/caremaps/:careMapId/drawer/household/add-member" render={(routeProps) => {
                const careMapId = routeProps.match.params.careMapId;
                return isViewer ? <Redirect to={`/caremaps/${careMapId}`} /> :  <HouseholdMemberEdit />;
            }} />

            <Route exact path="/caremaps/:careMapId/drawer/household/notes" render={() => {
                return (
                    <NotesView
                        titlePrefix="Household"
                        noteTarget={{section: 'household'}} />
                );
            }} />
            <Route exact path="/caremaps/:careMapId/drawer/household/notes/:noteId" render={(routeProps) => {
                const noteId = routeProps.match.params.noteId;
                return (
                    <CaremapNoteEditor
                        noteId={noteId}
                        noteTarget={{section: 'household'}}
                    />
                );
            }} />
            <Route exact path="/caremaps/:careMapId/drawer/household/notes/add-note" render={(routeProps) => {
                return (
                    <CaremapNoteEditor
                        noteId=""
                        noteTarget={{section: 'household'}}
                    />
                );
            }} />
            <Route exact path="/caremaps/:careMapId/drawer/household/member/:memberId/notes" render={(routeProps) => {
                const memberId = routeProps.match.params.memberId;
                const isPatient = memberId === 'patient';
                const titlePrefix = isPatient ? 'Care Recipient' : 'Household Member';

                return (
                    <NotesView
                        titlePrefix={titlePrefix}
                        noteTarget={{section: isPatient ? 'patient' : 'household', memberId: isPatient ? '' : memberId}}
                    />
                );
            }} />
            <Route exact path="/caremaps/:careMapId/drawer/household/member/:memberId/notes/:noteId" render={(routeProps) => {
                const {memberId} = routeProps.match.params;
                const isPatient = memberId === 'patient';
                const {noteId} = routeProps.match.params;

                return (
                    <CaremapNoteEditor
                        noteId={noteId === 'add-note' ? '' : noteId}
                        noteTarget={{section: isPatient ? 'patient' : 'household', memberId: isPatient ? '' : memberId}}
                    />
                );
            }} />
            <Route exact path="/caremaps/:careMapId/drawer/household/member/patient/appointments" render={(routeProps) => {
                return <AppointmentsView />;
            }} />
            <Route exact path="/caremaps/:careMapId/drawer/household/member/patient/appointments/:appointmentId" render={(routeProps) => {
                dismissOverlays();

                const {appointmentId} = routeProps.match.params;
                return (
                    <AppointmentEditorDrawer
                        from={'care recipient'} // tracking
                        appointmentId={appointmentId}
                    />
                );
            }} />
        </CareMapDrawer>
    );
};
