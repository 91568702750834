import { IonButton, IonCol, IonContent, IonGrid, IonRow, IonText } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { IPaymentTerm, RevenueCatModel } from './revenue-cat/RevenueCatModel';
import { useSubscription } from '@meraki-internal/state';
import { InnerhivePlusDialogViewModel } from './InnerhivePlusDialogViewModel';
import { CalendarPlusIcon, StarsIcon, IconComponent, ImproveCareIcon, InnerhivePlusIcon, ShareCareIcon, SupportInnerhiveIcon, UnlimitedStorageIcon, CheckIcon } from '../theme/Icons';
import React, { ReactNode, useState } from 'react';
import { EnvConfiguration } from '../config/EnvConfiguration';
import { DeviceInfo } from '../support/DeviceInfo';
import { Spinner } from '../components/Spinner';
import { AlertPresenter } from '../AlertBinder';
import { RevenueCatSDKPurchaseError } from './revenue-cat/PurchasesToInnerhiveErrorAdapter';
import { FooterLink } from '../components/FooterLink';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { DevSettings } from '../support/DevSettings';
import { useAsyncEffect } from '../support/useAsyncEffect';
import { AsyncStorageProvider } from '../support/AsyncStorageProvider';
import { useBreakpoints } from '../theme/useBreakpoints';
import { NudgeBanner } from '../components/NudgeBanner';
import { Modal } from '../components/Modal';
import { Href } from '../components/Href';
import dayjs from 'dayjs';

const Heading: React.FC<{fontSize: number; previouslyHadPlus?: boolean;}> = ({ fontSize, previouslyHadPlus = false }) => {
    const message = previouslyHadPlus ? <>It looks like you no longer have</> : <>Your care, your way with</> ;
    return (
        <h1 style={{fontSize, marginTop: 0}}>
            {message} <span style={{whiteSpace: 'nowrap'}}>Innerhive Plus&nbsp;<InnerhivePlusIcon size="large" color="secondary" /></span>
        </h1>
    );
};

const Price: React.FC<{children: ReactNode}> = ({ children }) => (
    <b><p style={{marginTop: 20, marginBottom: 20, textAlign: 'center', fontSize: 18}}><b>{children}</b></p></b>
);

const CenteredText: React.FC<{children: ReactNode}> = ({ children }) => (
    <p style={{marginTop: 20, marginBottom: 20, textAlign: 'center'}}>{children}</p>
);

const StoreButton: React.FC<{imgSrc: string, altText: string, storeUrl: string}> = ({ imgSrc, altText, storeUrl }) => (
    <div style={{marginTop: 20, marginBottom: 20, textAlign: 'center'}}>
        <Href url={storeUrl}>
            <img style={{maxWidth: 220}} src={imgSrc} alt={altText} />
        </Href>
    </div>
);

const Footer: React.FC<{children: ReactNode}> = ({ children }) => (
    <div style={{marginTop: 20, marginBottom: 20, display: 'flex', gap: 30, alignItems: 'center', justifyContent: 'center'}}>{children}</div>
);

const Benefit: React.FC<{Icon: IconComponent, children: React.ReactNode}> = ({ Icon, children }) => (
    <div style={{display: 'flex', alignItems: 'center', gap: 20}}>
        <div style={{width: 30, textAlign: 'center'}}><Icon size="large" color="secondary" /></div>
        <span>{children}</span>
    </div>
);

const Benefits: React.FC = () => (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: 10, height: '100%'}}>
        <Benefit Icon={ShareCareIcon}>Invite co-owners to help</Benefit>
        <Benefit Icon={ImproveCareIcon}>Unlimited care maps</Benefit>
        <Benefit Icon={UnlimitedStorageIcon}>Unlimited file storage</Benefit>
        <Benefit Icon={SupportInnerhiveIcon}>Support Innerhive's vision</Benefit>
        <Benefit Icon={CalendarPlusIcon}>Track appointments</Benefit>
        <Benefit Icon={StarsIcon}>Future Innerhive+ features</Benefit>
    </div>
);

const inactivePaymentTermGray = '--ion-color-step-150';

const PaymentTermButton: React.FC<{ disabled: boolean; isActive: boolean; onClick: () => void; termLabel: string; termPriceString: string; discountBadge?: string; }> = ({ disabled, isActive, onClick, termLabel, termPriceString, discountBadge }) => {

    return (
        <div style={{ position: 'relative' }}>
            <IonButton
                disabled={disabled}
                expand="block"
                style={{
                    marginBottom: 25,

                    '--border-color': isActive ? 'primary' : `var(${inactivePaymentTermGray})`,
                    '--padding-start': 0,
                }}
                fill="outline"
                onClick={onClick}
            >
                <div style={{ textAlign: 'left', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div>
                        <div style={{
                            // make it round
                            width: 20, height: 20, backgroundColor: isActive ? 'var(--ion-color-primary)' : `var(${inactivePaymentTermGray})`, borderRadius: 10,

                            // position the check
                            paddingLeft: 3, paddingTop: 3,

                            margin: 10
                        }}>
                            {isActive && (
                                <CheckIcon color="light" size="small" />
                            )}
                        </div>
                    </div>
                    <div>
                        <p style={{
                            fontWeight: 'bold',
                            padding: 0,
                            margin: 0,
                            marginTop: 6,
                            marginBottom: 8
                        }}>
                            <IonText color="dark">{termLabel}</IonText>
                        </p>
                        <p style={{
                            padding: 0,
                            margin: 0,
                            marginBottom: 6
                        }}>
                            <IonText color="dark">Innerhive+ for just {termPriceString}</IonText>
                        </p>
                    </div>
                </div>

            </IonButton>
            {discountBadge && (
                <div
                    style={{
                        color: 'var(--ion-color-light)',
                        fontSize: 14,
                        fontWeight: 'bold',
                        backgroundColor: 'var(--ion-color-primary)',
                        height: 30,
                        borderRadius: 15,
                        padding: '6px 15px',
                        position: 'absolute',
                        top: -15,
                        right: 15,
                        cursor: 'pointer'
                    }}
                    onClick={onClick}

                >
                    <IonText>{discountBadge}</IonText>
                </div>
            )}
        </div>

    );
};

export const InnerhivePlusDialog: React.FC = () => {
    const { APP_STORE_URLS } = useInstance(EnvConfiguration);
    const { platform, operatingSystem } = useInstance(DeviceInfo);
    const { enableWebBypassUpgrade } = useInstance(DevSettings);


    const alert = useInstance(AlertPresenter);

    const tracking = useInstance(MixpanelService);

    const revenueCat = useInstance(RevenueCatModel);
    useSubscription(() => revenueCat);

    const dialogVM = useInstance(InnerhivePlusDialogViewModel);
    useSubscription(() => dialogVM);

    const storageProvider = useInstance(AsyncStorageProvider);

    const {isBelowBreakpoint} = useBreakpoints();

    const [working, setWorking] = useState(false);

    const [previouslyHadPlus, setPreviouslyHadPlus] = useState<boolean>(false);
    useAsyncEffect(async () => {
        const hasPlusStorageKey = 'has-innerhive-plus';
        const lastSeen = await storageProvider.getStringProvider(dialogVM.LAST_SEEN_STORAGE_KEY).get();

        if (revenueCat.hasInnerhivePlus()) {
            //console.log('I HAVE PLUS, PUT IN STORAGE');
            storageProvider.getBooleanProvider(hasPlusStorageKey).set(true);
        } else {
            //console.log('I DO NOT HAVE PLUS, LOOK IN STORAGE IF HAD PREVIOUSLY');
            if (await storageProvider.getBooleanProvider(hasPlusStorageKey).get()) {
                //console.log('STORAGE SAYS I HAD IT, SHOW MESSAGE AND REMOVE FROM STORAGE');
                storageProvider.getBooleanProvider(hasPlusStorageKey).remove();
                setPreviouslyHadPlus(true);
                dialogVM.open({triggerSource: 'subscription expired'});
            } else if (dayjs().isAfter(dayjs(lastSeen).add(14, 'days'))) {
                dialogVM.open({triggerSource: 'recurring'});
            }
        }
    }, [revenueCat, storageProvider]);

    const upgrade = async () => {
        try {
            setWorking(true);
            tracking.track('Paywall Continued', () => ({
                Offer: revenueCat.getOfferMeta().paywallInfo?.priceString,
                Offering: revenueCat.getOfferMeta().offering_id,
                Term: term
            }));
            await revenueCat.upgrade(term);
            dialogVM.close();
        } catch (e: any) {
            if (e && e.errorType === 'RevenueCatSDKPurchaseError'){
                const error = e as RevenueCatSDKPurchaseError;
                if (error.cancelled){
                    // then do nothing
                }
            }
            else {
                alert.showAndLogError(e);
            }
        } finally {
            setWorking(false);
        }
    };

    const restore = async () => {
        try {
            setWorking(true);
            await revenueCat.restore();
            dialogVM.close();
        } catch (e: any) {
            if (e && e.errorType === 'RevenueCatSDKPurchaseError'){
                const error = e as RevenueCatSDKPurchaseError;
                if (error.cancelled){
                    // then do nothing
                }
            }
            else {
                alert.showAndLogError(e);
            }
        } finally {
            setWorking(false);
        }
    };

    const canUpgrade = (platform !== 'web' || enableWebBypassUpgrade);
    const priceString = revenueCat.getOfferMeta().paywallInfo?.priceString;

    const annualPriceString = revenueCat.getOfferMeta().paywallInfo?.annualPriceString;
    const hasAnnual = revenueCat.hasAnnualOffer() && annualPriceString;

    const [term, setTerm] = useState<IPaymentTerm>(hasAnnual ? 'annual' : 'monthly');

    const hasInnerhivePlus = revenueCat.hasInnerhivePlus();

    const combinedPriceString = hasAnnual ? `${annualPriceString} or ${priceString}` : priceString;

    return (
        <Modal
            title=""
            data-id="innerhive-plus"
            desktopMinHeight={525}
            isOpen={dialogVM.isOpen()}
            closeHandler={dialogVM.close}
        >
            <IonContent>
                <div style={{margin: '0 35px'}}>

                    <div style={{marginBottom: 15}}>
                        <Heading
                            fontSize={isBelowBreakpoint('sm') ? 25 : 30}
                            previouslyHadPlus={previouslyHadPlus}
                        />
                        {previouslyHadPlus && (
                            <NudgeBanner
                                color="secondary"
                                excludeIcon={true}
                                data-type="previous-plus-badge"
                                message={
                                    <div style={{textAlign: 'center'}}>
                                        If you didn’t mean to unsubscribe or want to regain access to your Innerhive Plus features, renew your subscription!
                                    </div>
                                }
                            />
                        )}
                    </div>

                    {revenueCat.hasInnerhivePlus() && (
                        <>
                            <div style={{marginTop: 40, marginBottom: 20}}>
                                <Price>You're getting all these benefits!</Price>
                            </div>

                            <div style={{height: 220}}>
                                <Benefits />
                            </div>

                            {revenueCat.canMaybeDowngrade() && (
                                <div style={{position: 'absolute',  bottom: '5%', marginLeft: -35, width: '100%', textAlign: 'center'}}>
                                    <IonButton
                                        color="dark"
                                        fill="clear"
                                        data-id="manage-subscription-button"
                                        disabled={working}
                                        onClick={revenueCat.maybeDowngrade}
                                    >
                                        Manage Subscription
                                    </IonButton>
                                </div>
                            )}
                        </>
                    )}

                    {!revenueCat.hasInnerhivePlus() && (
                        <IonGrid style={{ padding: 0 }}>
                            <IonRow class="ion-align-items-center ion-justify-content-between" style={{ padding: 0 }}>

                                <IonCol size="12" sizeMd="6" style={{ padding: 0 }}>
                                    <div style={{height: 280}}>
                                        <Benefits />
                                    </div>
                                </IonCol>

                                <IonCol size="12" sizeMd="6" style={{ padding: 0, marginTop: 21 }}>

                                    {(canUpgrade) &&
                                        <>
                                            <PaymentTermButton
                                                disabled={working}
                                                isActive={term === 'monthly'}
                                                onClick={() => setTerm('monthly')}
                                                termLabel="Monthly"
                                                termPriceString={priceString!}
                                            />
                                            {hasAnnual && (
                                                <PaymentTermButton
                                                    disabled={working}
                                                    isActive={term === 'annual'}
                                                    onClick={() => setTerm('annual')}
                                                    termLabel="Annual"
                                                    termPriceString={annualPriceString!}
                                                    discountBadge={revenueCat.getOfferMeta().paywallInfo?.discountBadge}
                                                />
                                            )}

                                            <IonButton
                                                disabled={working}
                                                expand="block"
                                                fill="solid"
                                                color="secondary"
                                                onClick={upgrade}
                                                data-id="continue"
                                            >
                                                <InnerhivePlusIcon color="light" />
                                                <span style={{paddingLeft: 10}}>Continue</span>
                                            </IonButton>


                                            {revenueCat.canRestore() &&
                                                <IonButton disabled={working} expand="block" fill="clear" color="secondary" onClick={restore} data-id="restore">Restore Purchases</IonButton>
                                            }

                                            <Footer>
                                                <FooterLink href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/" target="_blank">Terms of Use</FooterLink>
                                                <FooterLink href="https://www.innerhive.com/privacy-policy" target="_blank">Privacy Policy</FooterLink>
                                            </Footer>
                                        </>
                                    }

                                    {!canUpgrade && operatingSystem === 'ios' &&
                                        <>
                                            <Price>{combinedPriceString}! Cancel any time.</Price>
                                            <CenteredText>Available to purchase on the App Store</CenteredText>

                                            <StoreButton imgSrc="assets/images/innerhive-plus/app-store-badge.svg" altText="App Store" storeUrl={APP_STORE_URLS.ios} />

                                            <Footer>
                                                <FooterLink href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/" target="_blank">Terms of Use</FooterLink>
                                                <FooterLink href="https://www.innerhive.com/privacy-policy" target="_blank">Privacy Policy</FooterLink>
                                            </Footer>
                                        </>
                                    }

                                    {!canUpgrade && operatingSystem === 'android' &&
                                        <>
                                            <Price>{combinedPriceString}! Cancel any time.</Price>
                                            <CenteredText>Available to purchase on Google Play</CenteredText>

                                            <StoreButton imgSrc="assets/images/innerhive-plus/play-store-badge.svg" altText="Google Play" storeUrl={APP_STORE_URLS.android} />

                                            <Footer>
                                                <FooterLink href="https://www.innerhive.com/privacy-policy" target="_blank">Privacy Policy</FooterLink>
                                            </Footer>
                                        </>
                                    }

                                    {!canUpgrade && !['ios', 'android'].includes(operatingSystem) &&
                                        <>
                                            <Price>{combinedPriceString}!</Price>

                                            <CenteredText>Purchase on the App Store or Google Play and unlock for web. You can cancel any time.</CenteredText>

                                            <StoreButton imgSrc="assets/images/innerhive-plus/app-store-badge.svg" altText="App Store" storeUrl={APP_STORE_URLS.ios} />
                                            <StoreButton imgSrc="assets/images/innerhive-plus/play-store-badge.svg" altText="Google Play" storeUrl={APP_STORE_URLS.android} />
                                        </>
                                    }
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    )}
                </div>

                {!hasInnerhivePlus && (
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
                        <IonButton fill="clear" onClick={dialogVM.close}>
                            No thanks, continue for free
                        </IonButton>
                    </div>
                )}

                {working &&
                    <Spinner centered />
                }
            </IonContent>
        </Modal>
    );
};
