// simple encapsulated class to help with the router as IonPage and IonContent are required for transition animations
import { IonPage, IonContent } from '@ionic/react';
export const RouterPage: React.FC<{children?: React.ReactNode}> = ({ children }) => {
    return (
        <IonPage>
            <IonContent>
                {children}
            </IonContent>
        </IonPage>
    );
};
