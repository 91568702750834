// token used to register ionic Device.getInfo() in ioc
// (need our own token because ionic Device class doesn't play nice with aurelia)
export class DeviceInfo {

    constructor() {
        throw new Error('DeviceInfo must be instantiated via ioc');
    }

    platform: 'ios' | 'android' | 'web' = 'web';
    operatingSystem: 'ios' | 'android' | 'windows' | 'mac' | 'unknown';

    manufacturer: string;
    model: string;
    name?: string;
    uuid: string;
}
