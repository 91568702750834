import { isPlatform } from '@ionic/react';
import { RefObject } from 'react';

export const useAutoFocus = (ref: RefObject<HTMLIonInputElement>) => {
    return () => {
        setTimeout(() => {
            if (isPlatform('desktop') || isPlatform('capacitor')) {
                ref?.current?.setFocus();
            }
        }, 50);
    };
};
