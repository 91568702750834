import { FC } from 'react';
import { EnvelopeIcon, LocationIcon, MobilePhoneIcon, PhoneIcon } from '../theme/Icons';
import { IContactMethodTypeOrAddress } from './IContactMethodOrAddress';
import { IThemeColor } from '../theme/IThemeColor';

export const ContactMethodIcon: FC<{methodType: IContactMethodTypeOrAddress, color?: IThemeColor}> = ({ methodType, color = 'medium' }) => {
    if (methodType === 'mobile-phone') {
        return (
            <MobilePhoneIcon size="medium" color={color} />
        );
    }
    if (methodType === 'work-phone') {
        return (
            <PhoneIcon size="medium" color={color} />
        );
    }
    if (methodType === 'email') {
        return (
            <EnvelopeIcon size="medium" color={color} />
        );
    }
    if (methodType === 'address') {
        return (
            <LocationIcon size="medium" color={color} />
        );
    }
    return null;
};
