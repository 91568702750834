import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    content: {
        '--padding-top': '36px'
    },

    currentCareRecipient: {
        fontSize: 18,
        fontWeight: 700,
        paddingRight: 20,
        paddingLeft: 30,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end'
    },

    selected: {},
    navListItems: {
        '& > ion-item': {
            fontSize: 18,
            fontWeight: 500,
            '--inner-padding-start': '14px',

            '&:not($selected)': {
                '--background-hover-opacity': 0.2,
                '--background-hover': 'var(--ion-color-primary)',

                '& svg': {
                    fill: 'var(--ion-color-mediumtext)'
                    //stroke: 'var(--ion-color-mediumtext)'
                }
            },

            '&$selected': {
                '--background-hover-opacity': 0,
                '--background': 'rgba(var(--ion-color-primary-rgb), 0.2)',

                '& svg': {
                    fill: 'var(--ion-color-primary)'
                    //stroke: 'var(--ion-color-primary)'
                }
            }
        }
    }
});

export type INavigationItemListProps = {
    children: ReactNode;
};

export const NavigationItemList: FC<INavigationItemListProps> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.navListItems}>
            {children}
        </div>
    );
};
