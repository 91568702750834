import { FC, MouseEvent } from 'react';
import { IonCheckbox, IonLabel } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { mediaMaxWidth } from '../theme/utils';

type IStyleKeys = 'contactRadio' | 'selectedContactRadio';

interface IStyleProps {
    tighterXs: boolean;
}

const useStyles = createUseStyles<IStyleKeys, IStyleProps>({
    contactRadio: {
        flex: 1,
        textAlign: 'center',
        padding: 8,
        cursor: 'pointer',
        border: 'var(--app-theme-border)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '&:hover': {
            // TODO: why don't these variables work?
            // backgroundColor: 'var(--background-hover)'
            // --background-hover: var(--ion-color-primary, #3880ff);
            // --background-activated-opacity: 0;
            // --background-focused-opacity: .12;
            // --background-hover-opacity: .04;
            backgroundColor: 'rgb(var(--ion-color-primary-rgb), 0.04)'
        },

        '& ion-checkbox': {
            '--size': '16px',
            '--checkmark-width': 3
        },

        '& ion-label': {
            paddingLeft: 8,
            [mediaMaxWidth.sm]: {
                paddingLeft: ({ tighterXs }) => tighterXs ? 5 : null
            },
        },

        '&:first-child': {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8
        },

        '&:last-child': {
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8
        },

        '&:not(:last-child)': {
            borderRight: 0
        }
    },

    selectedContactRadio: {
        color: 'var(--ion-color-primary)',
        backgroundColor: 'rgb(var(--ion-color-primary-rgb), 0.2)',

        '&:hover': {
            backgroundColor: 'rgb(var(--ion-color-primary-rgb), 0.2)',
        }
    }
});

type ITabRadioProps = {
    label: string;
    selected: boolean;
    onClick: () => void;
    dataType: string;
    tighterXs?: boolean;
};

export const TabRadioButton: FC<ITabRadioProps> = ({ label, selected, onClick, dataType, tighterXs = false }) => {
    // we're handling the click event on the div, so we need preventDefault() to disable the
    // ionic state management of the checkbox, otherwise it toggles itself every tine it's clicked
    const onCheckboxClicked = (e:  MouseEvent) => {
        e.preventDefault();
    };

    const classes = useStyles({tighterXs});
    return (
        <div className={`${classes.contactRadio} ${selected ? classes.selectedContactRadio : ''}`} onClick={onClick} data-type={dataType}>
            <IonCheckbox mode="ios" checked={selected} onClick={onCheckboxClicked} />
            <IonLabel>{label}</IonLabel>
        </div>
    );
};
