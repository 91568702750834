import { useEffect, useRef } from 'react';
import ReactFlow, {
    useNodesState,
    useEdgesState,
    useReactFlow,
    Controls,
    ControlButton,
    useStore,
    MarkerType,
} from 'reactflow';

import { useInstance } from '@meraki-internal/react-dependency-injection';

import { HouseholdMemberNode } from '../nodes/HouseholdMemberNode';
import { PatientNode } from '../nodes/PatientNode';
import { HouseholdNodeGroup } from '../nodes/HouseholdNodeGroup';
import { HoneycombNodeGroup } from '../nodes/HoneycombNodeGroup';

import 'reactflow/dist/style.css';
import { NodeAdapter, INodeType } from '../nodes/NodeAdapter';
import { FlowDownloader } from './FlowDownloader';
import { ICareMap } from './ICareMap';
import { createUseStyles } from 'react-jss';
import { zoomDuration, deriveMinZoom, ensureVisible, fitCareMapBounds } from './CareMapBoundsHelper';
import { DevSettings } from '../support/DevSettings';

const proOptions = { hideAttribution: true };
const defaultEdgeOptions = { type: 'straight' };

const nodeTypes: { [key in INodeType]: any } = {
    household: HouseholdNodeGroup,
    patient: PatientNode,
    familyNode: HouseholdMemberNode,
    honeycombGroup: HoneycombNodeGroup
};

const useStyles = createUseStyles({
    'no-pan-care-map': {
        '& .react-flow__pane': {
            cursor: 'default'
        }
    },

    blur: {
        filter: 'blur(4px)'
    }
});

interface FlowProps {
    // we have a name b/c we expect to add zoomed in flows and will need to be able to distinguish them
    name?: string;
    careMap: Partial<ICareMap>;
    disablePan?: boolean;
    disableZoom?: boolean;
    onDismiss?: () => void;
    selectedItemId?: string;
    blur?: boolean;
    maxZoom?: number;
    includeControls?: boolean;
    fit: 'default' | 'custom' | 'none'
}

export const CareMapFlow: React.FC<FlowProps> = ({
    name = 'full',
    careMap,
    disablePan,
    disableZoom,
    onDismiss,
    selectedItemId,
    blur = false,
    maxZoom,
    includeControls = false,
    fit = 'default'
}) => {
    const styles = useStyles();
    const adapter = useInstance(NodeAdapter);
    const downloader = useInstance(FlowDownloader);
    const devSettings = useInstance(DevSettings);

    // TODO: instead of using useState, this gives us the onNodesChange handler (https://reactflow.dev/docs/concepts/core-concepts/)
    //  I'm not sure yet if we need it. I kept it for removing, but that might be if a user deletes a node vs us programatically doing it.
    //  Therefore in the end, we might only need useState if it makes mroe since to progromatically remove the node.
    //  My gut says, keep it within their framework (useNodesState), and possibly we might want to allow a user to remove a support (hexagon)
    //  using the 'delete' key. If so, remove deleteKeyCode={null} below and it works off the shelf
    // TODO: discuss shifting to use a State class instead?
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const reactFlowInstance = useReactFlow();
    const elRef = useRef<HTMLDivElement>(null);

    const setMinZoom = useStore(state => state.setMinZoom);

    // ReactFlow doesn't deep compare so everytime the state changes, we have to setNodes with the new array from state
    useEffect(() => {
        const flow = adapter.adapt(careMap, { selectedItemId });
        setNodes(flow.nodes);

        const _edges = flow.edges.map(e => {
            if (devSettings.enableHexagonTools){
                return {
                    ...e,
                    markerEnd: { type: MarkerType.Arrow, },
                    markerStart: { type: MarkerType.Arrow, },

                    zIndex: 100000000
                };
            }
            return e;
        });
        setEdges(_edges);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adapter, careMap, setEdges, setNodes]);

    useEffect(() => {
        // use our helper fitBounds to mitigate honeycomb whitespace issues
        fitCareMapBounds(reactFlowInstance, { fit });
    }, [nodes, reactFlowInstance, fit]);

    useEffect(() => {
        // odd, this is called twice b/c reactFlowInstance changes
        (reactFlowInstance as any).innerHiveEl = elRef.current;
        downloader.addReactFlow(name, reactFlowInstance);
    }, [name, downloader, elRef, reactFlowInstance]);

    return (
        <ReactFlow
            fitView
            ref={elRef}
            minZoom={deriveMinZoom()}
            maxZoom={maxZoom}
            data-name={`${name}-care-map`}
            className={disablePan ? styles['no-pan-care-map']: blur ? styles.blur : ''}
            onInit={rfInstance => {
                // I was not able to get this working with const { fitView } = useReactFlow();
                // best guess based on discussion in https://github.com/wbkd/react-flow/issues/1712
                // is that that version of the function is memoized over the intial state where there
                // are no nodes yet, where as reactFlowInstance is stateful and so it has access to nodes
                // as they are now

                // TODO: may want to debounce this
                // TODO: GC unless this flow is always visible and never gets dismounted
                window.addEventListener('resize', async function(event) {
                    // timeout is needed to get the new dimensions after rotating a device
                    this.setTimeout(() => {
                        // use our helper fitBounds to mitigate honeycomb whitespace issues in the wizard
                        setMinZoom(deriveMinZoom());
                        fitCareMapBounds(rfInstance, { fit });
                    }, 200);
                });
            }}
            proOptions={proOptions}
            deleteKeyCode={null}
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodesDraggable={false}
            nodesConnectable={false}
            nodesFocusable={false}
            edgesFocusable={false}
            elementsSelectable={false}
            defaultEdgeOptions={defaultEdgeOptions}

            panOnDrag={!disablePan}
            zoomOnScroll={!disableZoom}
            zoomOnPinch={!disableZoom}
            zoomOnDoubleClick={!disableZoom}

            onMoveEnd={(e: any) => {
                ensureVisible(reactFlowInstance);
            }}

            onPaneClick={() => {
                if (onDismiss){
                    onDismiss();
                }
            }}
        >
            {includeControls && (
                <Controls
                    style={{bottom: 65}} // leave room for intercom
                    showZoom={false}
                    showFitView={false}
                    showInteractive={false}
                >
                    {/* zoom in */}
                    <ControlButton onClick={() => {
                        reactFlowInstance.zoomIn({duration: zoomDuration});
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                            <path d="M32 18.133H18.133V32h-4.266V18.133H0v-4.266h13.867V0h4.266v13.867H32z"></path>
                        </svg>
                    </ControlButton>

                    {/* zoom out */}
                    <ControlButton onClick={() => {
                        reactFlowInstance.zoomOut({duration: zoomDuration});
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 5">
                            <path d="M0 0h32v4.2H0z"></path>
                        </svg>
                    </ControlButton>

                    {/* fit view */}
                    <ControlButton onClick={() => {
                        fitCareMapBounds(reactFlowInstance, { fit: 'custom' });
                    }}>
                        <img src="assets/images/fit-view.svg" alt="fit view" />
                    </ControlButton>
                </Controls>
            )}
        </ReactFlow>
    );
};
