import { FeelingToggle } from './FeelingToggle';
import { IFeeling } from './ICheckin';

export const FeelingSelector: React.FC<{ value?: IFeeling, onChange: (value?: IFeeling) => Promise<void> }> = ({ value, onChange }) => {

    const onClick = async (feeling: IFeeling) => {
        await onChange(feeling === value ? undefined : feeling);
    };

    return (
        <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'flex-start', gap: 20, maxWidth: 400, margin: 'auto'}}>
            <FeelingToggle
                feeling={IFeeling.VeryGood}
                active={!value || value === IFeeling.VeryGood}
                solid={value === IFeeling.VeryGood}
                onClick={() => onClick(IFeeling.VeryGood)}
            />
            <FeelingToggle
                feeling={IFeeling.Good}
                active={!value || value === IFeeling.Good}
                solid={value === IFeeling.Good}
                onClick={() => onClick(IFeeling.Good)}
                hideLabel
            />
            <FeelingToggle
                feeling={IFeeling.Neutral}
                active={!value || value === IFeeling.Neutral}
                solid={value === IFeeling.Neutral}
                onClick={() => onClick(IFeeling.Neutral)}
                hideLabel
            />
            <FeelingToggle
                feeling={IFeeling.Bad}
                active={!value || value === IFeeling.Bad}
                solid={value === IFeeling.Bad}
                onClick={() => onClick(IFeeling.Bad)}
                hideLabel
            />
            <FeelingToggle
                feeling={IFeeling.VeryBad}
                active={!value || value === IFeeling.VeryBad}
                solid={value === IFeeling.VeryBad}
                onClick={() => onClick(IFeeling.VeryBad)}
            />
        </div>
    );
};
