import { IAcceptedCareMapInvite } from './ICareMapInvite';
import { ILink } from './WithLinks';
import { ISO8601 } from '../support/ISO8601';
import { INote } from '../notes/INote';

export const RELATIONSHIP_KEYS = [
    'adoptive-parent',
    'aunt',
    'boyfriend',
    'brother',
    'brother-in-law',
    'caregiver',
    'child',
    'child-in-law',
    'cousin',
    'cousin-in-law',
    'daughter',
    'daughter-in-law',
    'domestic-partner',
    'father',
    'father-in-law',
    'friend',
    'foster-parent',
    'girlfriend',
    'granddaughter',
    'grandchild',
    'grandfather',
    'grandmother',
    'grandparent',
    'grandson',
    'guardian',
    'half-brother',
    'half-sibling',
    'half-sister',
    'housemate',
    'husband',
    'mother',
    'mother-in-law',
    'niece',
    'nephew',
    'nibling',
    'parent',
    'parent-in-law',
    'partner',
    'pibling',
    'roommate',
    'sibling',
    'sibling-in-law',
    'significant-other',
    'sister',
    'sister-in-law',
    'son',
    'son-in-law',
    'spouse',
    'step-brother',
    'step-father',
    'step-mother',
    'step-parent',
    'step-sibling',
    'step-sister',
    'stepchild',
    'uncle',
    'wife'
] as const;

export type IRelationship = typeof RELATIONSHIP_KEYS[number];

export const SUPPORT_GROUP_KEYS = ['community', 'financial', 'school', 'medical', 'specialists', 'social'] as const;
export const KEYS_WITH_MEMBERS = [...SUPPORT_GROUP_KEYS, 'household'] as const;
export const KEYS_WITH_NOTES = [...KEYS_WITH_MEMBERS, 'patient'] as const;

export type ISupportGroupKey = typeof SUPPORT_GROUP_KEYS[number];
export type IKeyWithMembers = typeof KEYS_WITH_MEMBERS[number];
export type ICareMapSection = typeof KEYS_WITH_NOTES[number];

export interface IFamilyMember {
    firstName?: string;
    lastName?: string;
    photo?: string;
    photoDataURI?: string;
    color?: string;
    avatar?: string;
    address?: string;
    contactMethods: IContactMethod[];
    notes: INote[];
};

export interface IDiagnosis {
    diagnosisId?: string;
    diagnosis?: string;
};

export interface IAllergy {
    allergyId?: string;
    allergy?: string;
};

export interface IPatient extends IFamilyMember {
    dob?: string;
    diagnoses?: IDiagnosis[];
    diagnosesIsPublic?: boolean;
    allergies?: IAllergy[];
    allergiesIsPublic?: boolean;
    links?: {
        notes: ILink;
    }
};

export interface IHouseholdMember extends IFamilyMember {
    id: string;
    relationshipId?: IRelationship;
    relationship?: string;
    links?: {
        notes: ILink;
    }
};

export type IContactMethodType = 'mobile-phone' | 'work-phone' | 'email';
export interface IContactMethod {
    type: IContactMethodType;
    value: string;
};

export interface ISupportGroupMember {
    id: string;
    name?: string;
    roleId?: string;
    role?: string;
    organization?: string;
    address?: string;
    contactMethods: IContactMethod[];
    notes: INote[];
    links?: {
        notes: ILink;
    }
};

export interface IHousehold {
    members: IHouseholdMember[];
    notes: INote[];
    checklists: IChecklist[];
    links?: {
        notes: ILink;
    }
};

export interface IChecklist {
    html: string;
    templateId?: string;
};

export interface ISupportGroup {
    members: ISupportGroupMember[];
    isRelevant: boolean | undefined;
    isRelevantSince?: string;
    notes: INote[];
    checklists: IChecklist[];
    links?: {
        notes: ILink;
    }
};

export interface INewCareMap {
    links?: any;
    careMapId?: string;
    patient: IPatient;
    household: IHousehold;
    community: ISupportGroup;
    financial: ISupportGroup;
    school: ISupportGroup;
    medical: ISupportGroup;
    specialists: ISupportGroup;
    social: ISupportGroup;
    essentialMapComplete: boolean;
    initialWizardAnswers: {
        [questionId: string]: any;
    }
};

export type ICaremapAccess = 'co-owner' | 'view-only';
export interface ICareMap extends INewCareMap {
    userId: string;
    careMapId: string;
    version: number;
    household: IHousehold;
};

export interface ICareMapKey {
    careMapId: string;
    userId: string;
};

// TODO: ILightCareMap does NOT extend ICareMap (it is a lighter version)
export interface ILightCareMap extends ICareMap {
    orgShares: {
        userId: string;
        access: ICaremapAccess;
        invite: IAcceptedCareMapInvite;
        careMapKey: ICareMapKey;
        consentedAt: ISO8601;
        consentedBy: {
            email: string;
            userId: string;
        }
    }[];
};
