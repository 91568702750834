import { IonSpinner, IonThumbnail, isPlatform } from '@ionic/react';
import { ReactNode, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { DuoDocFileIcon, DuoFileIcon, DuoPDFFileIcon } from '../../theme/Icons';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { Href } from '../../components/Href';

const useStyles = createUseStyles({
    thumbnail: {
        width: 110,
        '--size': '70px',
        height: 70,
        '--border-radius': '5px',
        borderRadius: 5,
        border: '1px solid var(--ion-color-step-200)',
        backgroundColor: 'var(--ion-color-light)',
        //overflow: 'hidden',
        position: 'relative'
    },

    document: {
        padding: 6
    },

    iconWrapper: {
        marginRight: 4,
        display: 'inline-block'
    },

    thumbnailMask: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white',
        opacity: 0.8,

        '& ion-spinner': {
            width: '70%',
            height: '90%'
        }
    },

    innerWrapper: {
        fontSize: 14,
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 3,
        // for non-webkit browsers (Firefox)
        minHeight: '100%',
        maxHeight: '3.8rem',
        lineHeight: 'initial'
    }
});

type INoteAttachmentProps = {
    url: string;
    fileName: string;
    isUploading?: boolean;
    contentType?: string;
};

type IAttachmentButton = INoteAttachmentProps & {
    children: ReactNode;
};

const attachmentsDirPath = 'attachments';
const NoteAttachmentButton: React.FC<IAttachmentButton> = ({ url, fileName, children, isUploading }) => {
    const classes = useStyles();
    const [downloading, setDownloading] = useState<boolean>(false);

    return !isUploading ? isPlatform('capacitor') ? (
        // native device
        downloading ? (
            <>
                {children}
                <div className={classes.thumbnailMask}>
                    <IonSpinner name="dots" color="primary" />
                </div>
            </>
        ) : (
            <Href
                onClick={async () => {
                    try {
                        // create unique file path (directory) for each file
                        let localFilePath: string | undefined;
                        const base64S3Path = btoa(encodeURIComponent(url));
                        const filePath = `${attachmentsDirPath}/${base64S3Path}/${fileName}`;

                        try {
                            // has the file already been downloaded?
                            const { uri } = await Filesystem.stat({
                                path: filePath,
                                directory: Directory.Cache
                            });

                            localFilePath = uri;
                        } catch (err: any) {
                            // file hasn't been downloaded, download it and show an indicator in case slow
                            setDownloading(true);

                            // android needs us to create the directories first
                            if (isPlatform('android')) {
                                await Filesystem.mkdir({
                                    recursive: true,
                                    directory: Directory.Cache,
                                    path: `${attachmentsDirPath}/${base64S3Path}`
                                });
                            }

                            const { path } = await Filesystem.downloadFile({
                                url,
                                path: filePath,
                                directory: Directory.Cache
                            });

                            setDownloading(false);
                            if (path) {
                                localFilePath = path;
                            } else {
                                throw new Error(`Filesystem.downloadFile failed? ${filePath} was not downloaded successfully`);
                            }
                        }

                        // open it in the viewer
                        await FileOpener.open({
                            openWithDefault: true,
                            filePath: localFilePath
                        });
                    } catch (err: any) {
                        // '8' FILE_NOT_SUPPORTED
                        // https://github.com/capacitor-community/file-opener?tab=readme-ov-file#api
                        if (err.code === '8') {
                            const link = document.createElement('a');
                            link.href = url;
                            link.target = '_blank';
                            link.click();
                        }

                        // log to Sentry so we can see if we need to deal with any specific errors
                        throw err;
                    }
                }}
            >
                {children}
            </Href>
        )
    ) : (
        // browser
        // PDF / Image - on web, opens in another tab, no issue
        // Image - on android, opens in separate chrome app, no issue
        // PDF - on android, opens  in separate chrome app, downloads, then I have to open it, finally picking what to open it with
        //    by then, back is hosed (maybe)
        <Href url={url}>{children}</Href>

    ) : children;
};

export const NoteAttachment: React.FC<INoteAttachmentProps> = (props) => {
    const classes = useStyles();

    const {fileName, url, isUploading = false, contentType} = props;

    const getCompForContentType = () => {
        const mimeType = contentType?.split('/')[1] || '';
        const isImage: boolean = contentType?.startsWith('image/') || false;

        const fileIcons: any = {
            'pdf': <DuoPDFFileIcon color="danger" />,
            'msword': <DuoDocFileIcon color="google-docs" />,
            'vnd.openxmlformats-officedocument.wordprocessingml.document': <DuoDocFileIcon />
        };

        return isImage && !isUploading ? (
            <IonThumbnail className={classes.thumbnail}>
                <img src={url} alt="attachment" className={classes.thumbnail} />
            </IonThumbnail>
        ) : (
            <div className={`${classes.thumbnail} ${classes.document}`}>
                <div className={classes.innerWrapper}>
                    <div className={classes.iconWrapper}>
                        {fileIcons[mimeType] || <DuoFileIcon />}
                    </div>
                    {fileName.substring(0, fileName.lastIndexOf('.')) || fileName}
                </div>
                {isUploading && (
                    <div className={classes.thumbnailMask}>
                        <IonSpinner name="dots" color="primary" />
                    </div>
                )}
            </div>
        );
    };

    const dataProps = {
        'data-type': isUploading ?  'uploading-file': 'attachment-button'
    };

    return (
        <div {...dataProps} style={{position: 'relative'}}>
            <NoteAttachmentButton {...props}>
                {getCompForContentType()}
            </NoteAttachmentButton>
        </div>

    );
};
