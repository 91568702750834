import { FC } from 'react';
import { IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { useBreakpoints } from '../theme/useBreakpoints';
import { NavigationMenuToggle } from '../navigation/NavigationMenu';

const useStyles = createUseStyles({
    header: {
        borderBottom: 'var(--app-theme-border)',

        '& > ion-toolbar': {
            color: 'white',
            '--ion-toolbar-background': 'var(--ion-color-primary)'
        }
    }
});

export interface IBasicHeader {
    title?: React.ReactNode;
    children?: React.ReactNode;
    isViewer?: boolean;
    style?: React.CSSProperties;
}

export const BasicHeader: FC<IBasicHeader> = ({ title, children, isViewer = false, style  }) => {
    const classes = useStyles();
    const {isAboveBreakpoint} = useBreakpoints();

    return (
        <IonHeader className={`${classes.header} ion-no-border`} style={style}>
            <IonToolbar color="primary" style={{paddingLeft: 10, paddingRight: 10}}>
                {!isViewer && <NavigationMenuToggle slot="start" />}
                {isAboveBreakpoint('sm') &&
                    <IonTitle style={{textAlign: 'center'}}>
                        {title}
                    </IonTitle>
                }
                {children}
            </IonToolbar>
        </IonHeader>
    );
};
