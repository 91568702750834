import { IonButton, IonMenu, IonMenuToggle} from '@ionic/react';
import { FC, useRef, useState } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { createUseStyles } from 'react-jss';
import { HamburgerMenuIcon } from '../theme/Icons';
import { mediaMinWidth } from '../theme/utils';
import { LoadingPage } from '../LoadingPage';
import { NavigationHeader } from './NavigationHeader';
import { NavigationContent } from './NavigationContent';
import { NavigationFooter } from './NavigationFooter';
import { NavigationMenuState } from './NavigationMenuState';

const width = 375;
const useStyles = createUseStyles({
    menu: {
        '--max-width': `${width}px`,

        [mediaMinWidth.sm]: {
            '--min-width': `${width}px`,
        },

        '&::part(backdrop)': {
            backgroundColor: 'black',
            opacity: '0.55 !important'
        }
    }
});

type INavigationMenuToggle = {
    slot?: 'start' | 'end'
};

export const NavigationMenuToggle: FC<INavigationMenuToggle> = ({slot}) => {
    // this component will auto-hide if the corresponding menu is disabled
    // https://ionicframework.com/docs/api/menu-toggle
    return (
        <IonMenuToggle slot={slot} menu="navigation-menu">
            <IonButton fill="clear" id="navigation-menu-button">
                <HamburgerMenuIcon color="light" />
            </IonButton>
        </IonMenuToggle>
    );
};

export const NavigationMenu: FC<{contentId: string}> = ({ contentId }) => {
    const classes = useStyles();
    const navMenuState = useInstance(NavigationMenuState);

    const closeBtnRef = useRef<HTMLDivElement>(null);

    const [deleting, setDeleting] = useState(false);

    const closeMenu = () => {
        closeBtnRef.current?.click();
    };

    if (deleting) {
        return (
            <LoadingPage showLogo />
        );
    }

    return (
        <IonMenu
            data-id="navigation-menu"
            menuId="navigation-menu"
            contentId={contentId}
            className={classes.menu}
            onIonWillOpen={() => navMenuState.setOpen(true)}
            onIonDidClose={() => navMenuState.setOpen(false)}
        >
            <NavigationHeader
                closeBtnRef={closeBtnRef}
                onDelete={() => setDeleting(true)}
            />

            <NavigationContent
                popoverWidth={width}
                closeMenuHandler={closeMenu}
            />

            <NavigationFooter closeMenu={closeMenu} />
        </IonMenu>
    );
};
