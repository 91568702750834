import { useInstance } from '@meraki-internal/react-dependency-injection';
import { InnerhivePage } from '../InnerhivePage';
import { OrganizationsState } from './OrganizationsState';
import { useSubscription } from '@meraki-internal/state';
import { IonButton, IonButtons, IonItem, IonLabel, IonList, IonSkeletonText, IonText, IonToolbar } from '@ionic/react';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { useEffect, useState } from 'react';
import { IOrganizationUser } from './IOrganization';
import { Text } from '../components/Text';
import { AddClient } from './AddClient';
import { UserState } from '../user/UserState';
import { createUseStyles } from 'react-jss';
import { Menu } from '../components/Menu';
import { DeleteIcon } from '../theme/Icons';
import { ILightCareMap } from '../caremap/ICareMap';
import { CareMapState } from '../caremap/CareMapState';
import { useAsyncEffect } from '../support/useAsyncEffect';
import { AppPrincipal } from '../auth/AppPrincipal';
import { AlertPresenter } from '../AlertBinder';
import { OrgNavigationMenuState } from './OrgNavigationMenuState';
import { OrganizationCareMapMenuItem } from './OrganizationCareMapMenuItem';

const useStyles = createUseStyles({
    clientList: {
        '& ion-item': {
            '--inner-padding-end': '12px'
        }
    },

    clientRow: {
        marginTop: 20,
        display: 'flex !important',
        alignItems: 'start',
        justifyContent: 'space-between'
    },

    name: {
        fontWeight: 500
    },

    email: {
        fontSize: '0.95em',
        color: 'var(--ion-color-step-750)'
    }

});

export const OrganizationClients: React.FC<{ orgId: string; }> = ({ orgId }) => {
    const classes = useStyles();

    const history = useInstance(HistoryViewModel);
    const principal = useInstance(AppPrincipal);

    const orgState = useInstance(OrganizationsState);
    useSubscription(() => orgState);

    const userState = useInstance(UserState);
    useSubscription(() => userState);

    const careMapState = useInstance(CareMapState);
    useSubscription(() => careMapState);

    const orgNavMenuState = useInstance(OrgNavigationMenuState);

    const alertPresenter = useInstance(AlertPresenter);

    const [careMapsLoaded, setCareMapsLoaded] = useState<boolean>(false);
    const [addClientOpen, setAddClientOpen] = useState<boolean>(false);

    const org = orgState.getOrganization(orgId);
    const allOrgClients = orgState.getOrganizationClientUsers(orgId);

    useEffect(() => {
        orgNavMenuState.setSelectedNavItem('clients');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useAsyncEffect(async () => {
        setCareMapsLoaded(false);
        await orgState.loadOrganizationCareMaps(orgId);
        setCareMapsLoaded(true);
    }, [orgId, orgState]);

    if(!org){
        return null;
    }

    const deleteOrg = async () => {
        alertPresenter.showAlertV2({
            header: `Delete ${org.name}?`,
            message: `${org.name} will only be removed if there are no existing clients. Former clients will still retain access to their own care maps, but ${org.name} will lose access to all associated care maps and data.`,
            options: [{
                label: 'Delete',
                id: 'delete',
                role: 'destructive',
                handler: async () => {
                    try {
                        await orgState.deleteOrganization(orgId);
                        history.push('/');
                    }
                    catch (err){
                        alertPresenter.showAndLogError(err);
                    }
                }
            }, {
                label: 'Cancel',
                role: 'cancel',
                id: 'cancel'
            }]
        });
    };

    const closeOrDismissAddClient = () => {
        setAddClientOpen(false);
    };

    const deleteUser = async (user: IOrganizationUser) => {
        const name = `${user.firstName} ${user.lastName}`;
        alertPresenter.showAlertV2({
            header: `Delete ${name}?`,
            message: `${name} will be removed from your organization. They will still retain access to their own care map(s), but ${org.name} will lose access to their care maps and data.`,
            options: [{
                label: 'Delete',
                id: 'delete',
                role: 'destructive',
                handler: async () => {
                    await orgState.removeUser(user);
                }
            }, {
                label: 'Cancel',
                id: 'cancel',
                role: 'cancel'
            }]
        });
    };

    const onCareMapClick = async (map: ILightCareMap) => {
        history.push(`/caremaps/${map.careMapId}`);
    };

    const teamMemberId = (new URLSearchParams(history.getCurrentLocation().search)).get('user');
    const clients = teamMemberId ? orgState.getClientsCoOwnedByUser({ orgId, teamMemberId }) : orgState.getOrganizationClientUsers(orgId);
    const sortedClients = (clients.sort((u1, u2) => u1.firstName.localeCompare(u2.firstName)));

    return (
        <InnerhivePage title={org.name}>
            {orgState.canAddOrganizationUser(orgId, 'org-client') && (
                <IonToolbar>
                    <Text type="title">Clients ({sortedClients.length} of {allOrgClients.length})</Text>
                    <IonButtons slot="end">
                        <IonButton
                            fill="solid"
                            color="primary"
                            data-id="add-org-client"
                            onClick={() => setAddClientOpen(true)}
                        >+ Add Client</IonButton>
                    </IonButtons>
                </IonToolbar>
            )}

            <IonList className={classes.clientList} data-id="org-client-list">
                {sortedClients.map(user => (
                    <IonItem key={user.email} data-client-email={user.email}>
                        <IonLabel>
                            <div className={classes.clientRow}>
                                <div>
                                    <div className={classes.name}>{user.firstName} {user.lastName}</div>
                                    <IonText data-type="email" className={classes.email}>{user.email}</IonText>
                                </div>
                                <Menu actions={[
                                    // adding a 2nd caremap is an edge case
                                    // 1. we create their "owner" caremap for them when we created the client
                                    // 2. adding a caremap to an org user assumes it is going to be an "owner" caremap
                                    //      and so work remains to support adding a caremap on the client, for someone
                                    //      other than the client
                                    // 3. we might need this when supporting existing users (eg add existing user, but
                                    //      they don't have a owner caremap yet). (once we get their permission)
                                    // {
                                    //     Icon: PlusIcon,
                                    //     text: 'Add Care Map',
                                    //     'data-type': 'add-client-caremap',
                                    //     handler: () => history.push(`/add-caremap/${org.orgId}/${user.userId}`),
                                    //     disabled: !orgState.canAddClientCareMap(user)
                                    // },
                                    {
                                        Icon: DeleteIcon,
                                        text: 'Delete Client',
                                        role: 'destructive',
                                        handler: () => deleteUser(user),
                                        'data-type': 'delete-client',
                                        disabled: !orgState.canRemoveOrganizationUser(user)
                                    }
                                ]} />
                            </div>
                            <div data-type="org-client-caremap-list">
                                {careMapsLoaded ? (
                                    orgState.getOrganizationCareMapsForUser(org.orgId, user.userId).map(map => (
                                        <OrganizationCareMapMenuItem
                                            key={map.careMapId}
                                            careMap={map}
                                            onClick={onCareMapClick}
                                        />
                                    ))
                                ): (
                                    <div style={{display: 'flex', width: '100%', marginTop: 10, paddingLeft: 16}}>
                                        <IonSkeletonText animated style={{'--border-radius': '999px', width: 48, height: 48, marginRight: 10}}></IonSkeletonText>
                                        <div style={{paddingTop: 4}}>
                                            <IonSkeletonText animated style={{'--border-radius': '999px', width: 200, height: '30%'}}></IonSkeletonText>
                                            <IonSkeletonText animated style={{'--border-radius': '999px', width: 200, height: '30%', marginTop: 10}}></IonSkeletonText>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </IonLabel>
                    </IonItem>
                ))}
            </IonList>

            {principal.isAdmin && (
                <>
                    <br/><br/><br/><br/>
                    <IonButton data-id="delete-org" onClick={deleteOrg}>Delete Org</IonButton>
                </>
            )}

            <AddClient
                orgId={orgId}
                isOpen={addClientOpen}
                closeHandler={closeOrDismissAddClient}
            />
        </InnerhivePage>
    );
};
