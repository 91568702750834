import { FC, useState } from 'react';
import { HeaderButton } from '../components/HeaderButton';
import { AddAppointmentIcon } from '../theme/Icons';
import { Modal } from '../components/Modal';
import { IonButton, IonCardContent, IonContent, IonFooter, IonToolbar } from '@ionic/react';
import { AppointmentEditor } from './AppointmentEditor';
import { createUseStyles } from 'react-jss';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AppointmentsState, NEW_APPOINTMENT_ID } from './AppointmentsState';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { useToast } from '../components/useToast';
import { RevenueCatModel } from '../innerhive-plus/revenue-cat/RevenueCatModel';
import { useSubscription } from '@meraki-internal/state';
import { InnerhivePlusDialogViewModel } from '../innerhive-plus/InnerhivePlusDialogViewModel';
import { CareMapState } from '../caremap/CareMapState';

const useStyles = createUseStyles({
    buttonBar: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 20px',

        '& > ion-button': {
            minWidth: 120
        }
    }
});

export const AddAppointmentModalAndButton: FC = () => {
    const classes = useStyles();

    const history = useInstance(HistoryViewModel);
    const appointmentsState = useInstance(AppointmentsState);
    const careMapState = useInstance(CareMapState);
    const dialogVM = useInstance(InnerhivePlusDialogViewModel);

    const revenueCat = useInstance(RevenueCatModel);
    useSubscription(() => revenueCat);

    const hasInnerhivePlus = revenueCat.hasInnerhivePlus();

    const [showToast] = useToast();
    const [isOpen, setIsOpen] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [saving, setSaving] = useState(false);

    if (!careMapState.hasActiveCareMap()) {
        return null;
    }

    const closeOrDismiss = () => {
        setIsOpen(false);
        setIsValid(false);
    };

    const save = async () => {
        try {
            setSaving(true);
            await appointmentsState.saveCurrentAppointment('menu');

            setSaving(false);
            setIsOpen(false);

            // wait for modal to close
            setTimeout(() => {
                showToast({
                    duration: 3000,
                    message: 'Appointment Added!',
                    buttons: [{
                        text: 'View Appointments',
                        handler: () => {
                            history.push(`/caremaps/${appointmentsState.getCareMapId()}/drawer/household/member/patient`);
                        },
                    }]
                });
            }, 350);
        } catch (err: any) {
            setSaving(false);
            showToast({ message: err.displayMessage || 'Sorry something went wrong', duration: 3000 });
        }
    };

    const cancel = () => {
        appointmentsState.clearCurrentAppointment();
        setIsOpen(false);
    };

    const buttonClicked = async () => {
        const clickHandler = () => {
            setIsOpen(true);
            appointmentsState.trackNewAppointmentOpened({from: 'menu'});
        };

        if (hasInnerhivePlus) {
            clickHandler();
        } else {
            const nowHasInnerhivePlus = await dialogVM.requirePurchase({ triggerSource: 'menu add appointment' });
            if (nowHasInnerhivePlus) {
                clickHandler();
            } else {
                // do nothing, user canceled paywall
            }
        }
    };

    if (!appointmentsState.canAdd()) {
        return null;
    }

    return (
        <>
            <HeaderButton id="quick-add-appointment-button" onClick={buttonClicked}>
                <div style={{marginTop: 2, marginLeft: 2}}>
                    {hasInnerhivePlus ? (
                        <AddAppointmentIcon color="primary" />
                    ) : (
                        <img style={{maxWidth: 20}} src="/assets/images/innerhive-plus/add-appointment-paywall.svg" alt="add appointment" />
                    )}
                </div>
            </HeaderButton>

            <Modal
                isOpen={isOpen}
                title="Add Appointment"
                data-id="add-appointment-modal"
                data-type="appointment-editor"
                closeHandler={closeOrDismiss}
            >
                <IonContent>
                    <IonCardContent>
                        <AppointmentEditor
                            appointmentId={NEW_APPOINTMENT_ID}
                            reportValidity={(valid) => setIsValid(valid)}
                        />
                    </IonCardContent>
                </IonContent>

                <IonFooter>
                    <IonToolbar className={classes.buttonBar}>
                        <IonButton slot="start" data-type="cancel-button" fill="outline" onClick={cancel}>Cancel</IonButton>
                        <IonButton slot="end" data-type="save-button" disabled={!isValid || saving} onClick={save}>Save</IonButton>
                    </IonToolbar>
                </IonFooter>
            </Modal>
        </>
    );
};
