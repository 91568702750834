import { IonItem, IonText } from '@ionic/react';
import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    nothing: {},
    selected: {},
    disabled: {
        '--background-hover-opacity': 0,
        '--background-hover': 'white',
    },
    navListItem: {
        '& > ion-item': {
            fontSize: 18,
            fontWeight: 500,
            '--inner-padding-start': '14px',

            '& > div': {
                minWidth: 50,
                display: 'flex'
            },

            '&:not($selected):not($disabled)': {
                '--background-hover-opacity': 0.2,
                '--background-hover': 'var(--ion-color-primary)',

                '& svg': {
                    fill: 'var(--ion-color-mediumtext)'
                    //stroke: 'var(--ion-color-mediumtext)'
                }
            },

            '&$selected': {
                '--background-hover-opacity': 0,
                '--background': 'rgba(var(--ion-color-primary-rgb), 0.2)',

                '& svg': {
                    fill: 'var(--ion-color-primary)'
                    //stroke: 'var(--ion-color-primary)'
                }
            }
        }
    }
});

export type INavigationItemProps = {
    label: string;
    icon: ReactNode;
    disabled?: boolean;
    isSelected?: boolean;
    onClick: () => void;
    'data-type'?: string;
};

export const NavigationItem: FC<INavigationItemProps> = ({
    label,
    icon,
    disabled = false,
    isSelected,
    onClick = () => {},
    'data-type': dataType
}) => {
    const classes = useStyles();

    return (
        <div className={classes.navListItem} data-type={dataType}>
            <IonItem
                lines="none"
                button={!disabled}
                onClick={disabled ? undefined : onClick}
                // IonItem bug?? instead of classes.nothing, '' or ' ' or undefined or string template
                // does not re-render IonItem when pushing a path to history with a query param (ie /orgs/{orgId}/clients?user=blee)
                className={`${isSelected ? classes.selected : classes.nothing} ${disabled ? classes.disabled : ''}`}
            >
                <div>
                    {icon}
                </div>
                <IonText>
                    {label}
                </IonText>
            </IonItem>
        </div>
    );
};
