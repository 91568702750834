import { CareMapState } from '../caremap/CareMapState';
import { InitialWizardState } from './InitialWizardState';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { Logger } from '../support/Logger';
import dayjs from 'dayjs';
import { CustomTypeAddedMetaBuilder } from '../metrics/CustomTypeAddedMetaBuilder';
import { UserState } from '../user/UserState';
import { BufferedEventBus } from '../utils/BufferedEventBus';

export class WizardTrackingService {
    static inject = () => [
        MixpanelService,
        InitialWizardState,
        CareMapState,
        Logger,
        UserState,
        BufferedEventBus
    ];
    constructor(
        private tracking: MixpanelService,
        private wizard: InitialWizardState,
        private careMap: CareMapState,
        private log: Logger,
        private user: UserState,
        private events: BufferedEventBus
    ) { }

    private routeOnFinishedStepHandler: { [route: string]: () => void; } = {
        'name': () => {
            this.tracking.track('Care Recipient Name Step Completed', () => {
                const fieldsUsed = [
                    this.careMap.getActiveCaremap()?.patient.firstName ? 'first' : '',
                    this.careMap.getActiveCaremap()?.patient.lastName ? 'last' : '',
                ].filter(field => Boolean(field));

                return {
                    'Data Entered': fieldsUsed
                };
            });
        },
        'recipient': () => {
            const relationshipToCareMapPatient = this.careMap.getActiveCaremap()?.initialWizardAnswers['recipient'];

            const relationToCareRecipient = relationshipToCareMapPatient.value || relationshipToCareMapPatient.label!;

            this.events.emit('WizardTrackingService.relationshp-to-care-recipient-set', { relationToCareRecipient });

            this.tracking.track('Relation to Recipient Step Complete', () => ({
                relationToCareRecipient
            }));

            if (!relationshipToCareMapPatient.value && relationshipToCareMapPatient.label) {
                this.tracking.track('Custom Type Added', () => new CustomTypeAddedMetaBuilder()
                    .origin('questionnaire')
                    .creator()
                    .field({ field: 'relationship', fieldLabelKey: 'CARE_RECIPIENT_RELATIONSHIP_TO_CARE_MAP_CREATOR', value: relationshipToCareMapPatient.label })
                    .build());
            }
        },
        'dob': () => {
            this.tracking.track('Date of Birth Step Completed', () => ({
                year: dayjs(this.careMap.getPatientDOB()).year(),
                age: dayjs().diff(this.careMap.getPatientDOB(), 'year')
            }));
        },
        'school/common': () => {
            this.tracking.track('School: Common Items Step Completed', () => ({
                '# Added': this.careMap.getActiveCaremap()!.school.members.length,
                roles: this.careMap.getActiveCaremap()!.school.members.map(m => m.roleId || m.role || '')
            }));
        },
        'medical/common': () => {
            this.tracking.track('Primary Care: Common Items Step Completed', () => ({
                '# Added': this.careMap.getActiveCaremap()!.medical.members.length,
                roles: this.careMap.getActiveCaremap()!.medical.members.map(m => m.roleId || m.role || '')
            }));
        },
        'specialists/common': () => {
            this.tracking.track('Specialists: Common Items Step Completed', () => ({
                '# Added': this.careMap.getActiveCaremap()!.specialists.members.length,
                roles: this.careMap.getActiveCaremap()!.specialists.members.map(m => m.roleId || m.role || '')
            }));
        },
        'school/additional': () => {
            this.tracking.track('School: Add More Step Completed', () => {
                const members = this.careMap.getActiveCaremap()!.initialWizardAnswers['school/additional'] || [];
                return {
                    '# Added': members.length,
                    roles: members.map((m: any) => m.roleId || m.role || '')
                };
            });

            const customRoles = this.careMap.getActiveCaremap()!.school.members.filter(mem => !mem.roleId && mem.role).map(mem => mem.role as string);
            for (const value of customRoles) {
                this.tracking.track('Custom Type Added', () => new CustomTypeAddedMetaBuilder()
                    .origin('questionnaire')
                    .supportGroup('school')
                    .field({ field: 'role', fieldLabelKey: 'SUPPORT_GROUP__SCHOOL__ROLE', value})
                    .build()
                );
            }
        },
        'medical/additional': () => {
            this.tracking.track('Primary Care: Add More Step Completed', () => {
                const members = this.careMap.getActiveCaremap()!.initialWizardAnswers['medical/additional'] || [];

                return {
                    '# Added': members.length,
                    roles: members.map((m: any) => m.roleId || m.role || '')
                };
            });

            const customRoles = this.careMap.getActiveCaremap()!.medical.members.filter(mem => !mem.roleId && mem.role).map(mem => mem.role as string);
            for (const value of customRoles) {
                this.tracking.track('Custom Type Added', () => new CustomTypeAddedMetaBuilder()
                    .origin('questionnaire')
                    .supportGroup('medical')
                    .field({ field: 'role', fieldLabelKey: 'SUPPORT_GROUP__MEDICAL__ROLE', value })
                    .build()
                );
            }
        },
        'specialists/additional': () => {
            this.tracking.track('Specialists: Add More Step Completed', () => {
                const members = this.careMap.getActiveCaremap()!.initialWizardAnswers['specialists/additional'] || [];
                return {
                    '# Added': members.length,
                    roles: members.map((m: any) => m.roleId || m.role || '')
                };
            });

            const customRoles = this.careMap.getActiveCaremap()!.specialists.members.filter(mem => !mem.roleId && mem.role).map(mem => mem.role as string);
            for (const value of customRoles) {
                this.tracking.track('Custom Type Added', () => new CustomTypeAddedMetaBuilder()
                    .origin('questionnaire')
                    .supportGroup('specialists')
                    .field({ field: 'role', fieldLabelKey: 'SUPPORT_GROUP__SPECIALISTS__ROLE', value })
                    .build()
                );
            }
        },
        'financial/common': () => {
            this.tracking.track('Financial & Legal Completed');
        },
        'patient-photo': () => {
            this.tracking.track('Care Recipient Visual Step Completed');
        },
        'community/extracurricular': () => {
            this.tracking.track('Recreational & Extracurricular Step Completed');
            const customRoles = (this.careMap.getActiveCaremap()!.initialWizardAnswers['community/extracurricular'] || []).filter((mem: any) => !mem.roleId && mem.role).map((mem: any) => mem.role as string);
            for (const value of customRoles) {
                this.tracking.track('Custom Type Added', () => new CustomTypeAddedMetaBuilder()
                    .origin('questionnaire')
                    .supportGroup('community')
                    .field({ field: 'role', fieldLabelKey: 'SUPPORT_GROUP__COMMUNITY__ROLE_EXTRA_CURRICULAR', value })
                    .build()
                );
            }
        },
        'community/advocacy': () => {
            this.tracking.track('Support & Advocacy Step Completed');

            const customRoles = (this.careMap.getActiveCaremap()!.initialWizardAnswers['community/advocacy'] || []).filter((mem: any) => !mem.roleId && mem.role).map((mem: any) => mem.role as string);
            for (const value of customRoles) {
                this.tracking.track('Custom Type Added', () => new CustomTypeAddedMetaBuilder()
                    .origin('questionnaire')
                    .supportGroup('community')
                    .field({ field: 'role', fieldLabelKey: 'SUPPORT_GROUP__COMMUNITY__ROLE_ADVOCACY', value })
                    .build()
                );
            }
        },
        'community/general': () => {
            this.tracking.track('Catch-All Community Step Completed');

            const customRoles = (this.careMap.getActiveCaremap()!.initialWizardAnswers['community/general'] || []).filter((mem: any) => !mem.roleId && mem.role).map((mem: any) => mem.role as string);
            for (const value of customRoles) {
                this.tracking.track('Custom Type Added', () => new CustomTypeAddedMetaBuilder()
                    .origin('questionnaire')
                    .supportGroup('community')
                    .field({ field: 'role', fieldLabelKey: 'SUPPORT_GROUP__COMMUNITY__ROLE_GENERAL', value })
                    .build()
                );
            }
        },
        'household': () => {
            this.tracking.track('Household Member Step Completed');

            const customMemberRelationShips = this.careMap.getActiveCaremap()!.household.members.filter(mem => !mem.relationshipId && mem.relationship).map(mem => mem.relationship as string);
            for (const value of customMemberRelationShips) {
                this.tracking.track('Custom Type Added', () => new CustomTypeAddedMetaBuilder()
                    .origin('questionnaire')
                    .household()
                    .field({ field: 'relationship', fieldLabelKey: 'HOUSEHOLD_MEMBER__RELATIONSHIP', value  })
                    .build()
                );
            }
        },
        'school': () => {
            this.tracking.track('Baseline School Step Completed');
        },
        'community': () => {
            this.tracking.track('Baseline Recreational and Extracurricular Step Completed');
        },
        'community2': () => {
            this.tracking.track('Baseline Support and Advocacy Step Completed');
        },
        'recipient-self': () => {
            this.tracking.track('Care Mapping for Myself or Someone Else Step Completed', () => ({
                Selection: this.careMap.getActiveCaremap()!.initialWizardAnswers['recipient-self'] ? 'myself': 'someone else'
            }));
        },
        'my-name': () => {
            this.tracking.track('User Name Step Completed', () => {
                const fieldsUsed = [
                    this.user.state.firstName ? 'first' : '',
                    this.user.state.lastName ? 'last' : '',
                ].filter(field => Boolean(field));

                return {
                    'Data Entered': fieldsUsed
                };
            });
        },
        'consent-self': () => {
            this.tracking.track('Data Consent Step Completed', () => {
                return {
                    'Care Recipient Is': 'myself'
                };
            });
        },
        'consent-someone-else': () => {
            this.tracking.track('Data Consent Step Completed', () => {
                return {
                    'Care Recipient Is': 'someone-else'
                };
            });
        }
    };
    private routeRegexOnFinishedStepHandler: { regex: RegExp; handler: () => void; }[] = [
        {
            // /questionnaire/household-photo/:member-id
            regex: /^household-photo\/[^/]*$/,
            handler: () => {
                this.tracking.track('Household Visual Step Completed');
            }
        }
    ];
    onFinishedStep = () => {
        try {
            const stepId = this.wizard.getCurrentStep();
            if (this.routeOnFinishedStepHandler[stepId]) {
                this.routeOnFinishedStepHandler[stepId]();
                return;
            }

            const regexMatch = this.routeRegexOnFinishedStepHandler.find(({ regex }) => regex.test(stepId));
            if (regexMatch) {
                regexMatch.handler();
                return;
            }


        }
        catch (err: any) {
            this.log.error(`WizardTrackingService.onFinishedStep threw ${err.toString()}\n${err.stack}`);
        }
    };
}
