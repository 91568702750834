import { State } from '@meraki-internal/state';
import { OrganizationsService } from './OrganizationsService';
import { IOrganization, IOrganizationBaseUser, IOrganizationUser, IOrganizationRole } from './IOrganization';
import { MaybeWithLinks, WithLinks } from '../caremap/WithLinks';
import { ILightCareMap } from '../caremap/ICareMap';
import { CareMapState } from '../caremap/CareMapState';
import { BufferedEventBus } from '../utils/BufferedEventBus';
import { UserFacingError } from '../innerhive-plus/revenue-cat/UserFacingError';

export class OrganizationsState extends State<Record<string, never>> {
    static inject = () => [
        OrganizationsService,
        CareMapState,
        BufferedEventBus
    ];
    constructor(
        private api: OrganizationsService,
        private caremaps: CareMapState,
        private events: BufferedEventBus
    ){
        super({});

        // load all orgs when accepting invite because you are in caremap context
        // so there's no _activeOrganization to only load that one orgs info
        this.events.on('InvitationService.accepted', this.load);
    }

    private organizations!: MaybeWithLinks<IOrganization>[];

    // TODO: might need when getting to client experience
    /* getOrganizations = () => {
        return this.organizations;
    }; */

    private _activeOrganization?: IOrganization;
    getActiveOrganization = () => {
        return this._activeOrganization;
    };

    hasActiveOrganization = () => {
        return Boolean(this.getActiveOrganization());
    };

    setActiveOrganization = async (orgId: string) => {
        const org = this.getOrganization(orgId);

        if (!org) {
            throw Error(`Organization with ID ${orgId} not found`);
        }

        this._activeOrganization = org;
        this.setState({ });
    };

    unsetActiveOrganization = async () => {
        this._activeOrganization = undefined;
        this.setState({ });
    };

    getNonClientOrganizations = () => {
        return this.organizations.filter(org => org.role !== 'org-client');
    };

    getAllOrganizations = () => this.organizations;

    isOrgTeamMember = () => {
        return this.getNonClientOrganizations().length > 0;
    };

    getOrganization = (orgId: string) => this.organizations.find(o => o.orgId === orgId);
    getOrganizationOrThrow = (orgId: string) => {
        const org = this.getOrganization(orgId);
        if (!org){
            throw new Error(`cannot find org ${orgId}`);
        }
        return org;
    };

    private organizationUsers: {
        [orgId: string]: WithLinks<IOrganizationUser>[]
    } = {};

    private organizationCareMaps: {
        [orgId: string]: ILightCareMap[]
    } = {};

    getOrganizationUser = ({ orgId, userId }: { orgId: string, userId: string; }) => {
        const users = this.organizationUsers[orgId];
        return users ? users.find(u => u.userId === userId) : undefined;
    };

    load = async () => {
        this.organizations = await this.api.getOrganizations();
        await Promise.all(this.organizations.map(o => this.loadOrganizationUsers(o.orgId)));
        await Promise.all(this.organizations.map(o => this.loadOrganizationCareMaps(o.orgId)));
    };

    createOrganization = async (org: { name: string; }) => {
        const res = await this.api.createOrganization(org);
        await this.load();
        return res;
    };

    deleteOrganization = async (orgId: string) => {
        // TODO: not found, handle?
        await this.api.deleteOrganization(this.getOrganization(orgId)!);
        await this.load();
    };

    canAddOrganizationUser = (orgId: string, userType: IOrganizationRole) => {
        const org = this.getOrganization(orgId);
        return org?.links.users && org.links.users.actions?.includes(`add-org-users--${userType}`);
    };

    canRemoveOrganizationUser = (user: MaybeWithLinks<IOrganizationUser>) => {
        return user?.links?.self && user.links.self.actions?.includes(`remove-org-user--${user.role}`);
    };

    canAddClientCareMap = (user: MaybeWithLinks<IOrganizationUser>) => {
        return user.links?.caremaps?.actions?.includes('create-caremap');
    };

    // TODO: dead param
    canViewClientCareMap = (map: ILightCareMap, userId: string): boolean => {
        return Boolean(map.links.self);
    };

    private loadOrganizationUsers = async (orgId: string) => {
        const org = this.getOrganization(orgId);
        if (org) {
            // can't load users if i'm not allowed to
            if (org.links.users) {
                const users = await this.api.getOrganizationUsers(org);
                this.organizationUsers[org.orgId] = users;
                this.setState({ });
            } else {
                this.organizationUsers[org.orgId] = [];
            }
        }
    };

    loadOrganizationCareMaps = async (orgId: string) => {
        const org = this.getOrganization(orgId);
        if (org && org.links.caremaps?.actions?.includes('get-caremaps')) {
            // can't load caremaps if i'm not allowed to
            if (org.links.caremaps) {
                const maps = await this.api.getOrganizationCareMaps(org);
                this.organizationCareMaps[org.orgId] = maps;
                this.setState({ });
            } else {
                this.organizationCareMaps[org.orgId] = [];
            }
        }
    };

    getOrganizationUsers = (orgId: string) => {
        return this.organizationUsers[orgId] || [];
    };

    getOrganizationTeamMembers = (orgId: string) => {
        return this.getOrganizationUsers(orgId).filter(user => user.role !== 'org-client');
    };

    getOrganizationClientUsers = (orgId: string) => {
        return this.getOrganizationUsers(orgId).filter(user => user.role === 'org-client');
    };

    addOrganizationClient = async (orgId: string, client: IOrganizationBaseUser) => {
        const org = this.getOrganizationOrThrow(orgId);
        const userId = await this.api.createOrganizationClient(org, {...client, ...{role: 'org-client'}});
        await this.loadOrganizationUsers(org.orgId);
        await this.loadOrganizationCareMaps(org.orgId);

        const hasCaremaps = this.getOrganizationCareMapsForUserV2({ orgId: org.orgId, userId }).length > 0;
        if (hasCaremaps){
            throw new UserFacingError({
                // the api operation was successful, hence 200, but from a client perspective, its not in the state we want it to be in
                // hence throwing this error. (409 was another option, but that is also being used for a duplicate, and it suggests the
                // operation failed.)
                status: 200,
                errorCode: 'client-added-with-caremaps',
                message: `userId ${userId} was added to the organization as a client, but already has caremaps`,
                displayMessage: 'We found the user with that email and they were added to your organization. They already have at least one caremap. For their privacy, you must request that they add you as a co owner to the caremaps they want your help managing, to gain access to their caremaps.',
                displayHeader: 'User Added',
            });
        }

        return userId;
    };

    addClientCaremap = async ({ orgId, userId }: { orgId: string; userId: string; }) => {
        const users = this.getOrganizationClientUsers(orgId);
        const user = users.find(u => u.userId === userId);
        if (!user){
            throw new Error(`user ${userId} not found`);
        }
        // TODO: refactor to getUser
        const { careMapId } = await this.caremaps.addCaremapToClientUser(user);

        return { careMapId };
    };

    addOrganizationUser = async (orgId: string, user: IOrganizationBaseUser) => {
        const org = this.getOrganizationOrThrow(orgId);
        const userId = await this.api.createOrganizationUser(org, user);
        await this.loadOrganizationUsers(org.orgId);
        return userId;
    };

    removeUser = async (user: IOrganizationUser) => {
        await this.api.removeOrganizationUser(user);

        // note we used to re-fetch the org users from the API
        // but we saw test flake, likely due to eventually consistency (org-users is on an index)
        let orgUsers = this.organizationUsers[user.orgId] || [];
        orgUsers = orgUsers.filter(u => u.userId !== user.userId);
        this.organizationUsers[user.orgId] = orgUsers;

        this.setState({ });
    };

    private getOrganizationCareMaps = (orgId: string) => {
        return this.organizationCareMaps[orgId] || [];
    };

    getOrganizationCareMapsForUser = (orgId: string, userId: string) => {
        const orgMaps = this.getOrganizationCareMaps(orgId);
        return orgMaps.filter(map => map.userId === userId);
    };

    getOrganizationCareMapsForUserV2 = ({ orgId, userId }: { orgId: string; userId: string; }) => {
        const orgMaps = this.getOrganizationCareMaps(orgId);
        return orgMaps.filter(map => map.userId === userId);
    };

    getClientsCoOwnedByUser = ({ orgId, teamMemberId }: { orgId: string, teamMemberId: string }): MaybeWithLinks<IOrganizationUser>[] => {
        return this.getOrganizationClientUsers(orgId)
            .filter(client => {
                const caremapsOwnedByClient = this.getOrganizationCareMapsForUser(orgId, client.userId);
                const hasAnyCaremapsDirectlySharedToTeamMember = caremapsOwnedByClient.some(m => m.orgShares.some(share => share.userId === teamMemberId));

                return hasAnyCaremapsDirectlySharedToTeamMember;
            });
    };

    userHasClientCoOwnedCareMaps = (orgId: string, userId: string) => {
        return this.getClientsCoOwnedByUser({ orgId, teamMemberId: userId }).length > 0;
    };

    private getAllCaremaps = () => {
        let caremaps: ILightCareMap[] = [];
        for (const orgId of Object.keys(this.organizationCareMaps)){
            caremaps = [
                ...caremaps,
                ...this.organizationCareMaps[orgId]
            ];
        }
        return caremaps;
    };

    getOrganizationCareMap = (caremapId: string) => {
        return this.getAllCaremaps().find(c => c.careMapId === caremapId);
    };

    transferClients = async ({ orgId, fromMemberId, toMemberId }: { orgId: string; fromMemberId: string; toMemberId: string; }) => {
        const clients = this.getClientsCoOwnedByUser({ orgId, teamMemberId: fromMemberId });

        for (const clientUser of clients){
            await this.api.transferClient({
                clientUser: clientUser as WithLinks<IOrganizationUser>,
                fromMemberId,
                toMemberId,
            });
        }

        await this.loadOrganizationCareMaps(orgId);
    };

    canTransferClients = (user: MaybeWithLinks<IOrganizationUser>) => {
        const orgId = user.orgId;

        const hasCoOwnedMaps = this.userHasClientCoOwnedCareMaps(orgId, user.userId);
        return hasCoOwnedMaps && user.links && user.links['transfer-caremaps'] && this.getOrganizationTeamMembers(orgId).length > 1;
    };
};
