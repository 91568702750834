import { ReactElement, Ref, useEffect, useRef } from 'react';
import { IonButton, useIonViewDidLeave, useIonViewWillEnter, isPlatform } from '@ionic/react';

interface IRenderRowProps<T> {
    item: T;
    focusRef: Ref<any>;
    onChange: (item: T) => void;
    onBlur: () => void;
}

export interface IEditableListRowProps<T> {
    item: T;
    render: (props: IRenderRowProps<T>) => ReactElement;
    onChange: (item: T) => void;
    onBlur: () => void;
    isBlank: (item: T) => boolean;
}

const EditableListRow = <T,>({ item, render, onChange, onBlur, isBlank }: IEditableListRowProps<T>) => {
    const focusRef = useRef<any>(null);

    // focus new item
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (isBlank(item)) {
                if (focusRef.current) {
                    if (typeof focusRef.current.setFocus === 'function') {
                        // ionic components expose setFocus(), but on ios this
                        // gets confused about whether a keyboard is visible
                        if (isPlatform('desktop') || isPlatform('capacitor')) {
                            focusRef.current.setFocus();
                        }
                    } else if (typeof focusRef.current.focus === 'function') {
                        // other elements expose focus(), which should work on all devices
                        // NOTE: this is required for lists of dropdowns (such as diagnoses)
                        // because we rely on blur to clean up empty rows
                        focusRef.current.focus();
                    } else {
                        console.error('Unable to set focus, element does not expose focus() or setFocus()');
                    }
                }
            }
        }, 350); // need to wait 350ms for step animation to complete
        return () => clearTimeout(timeout);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return render({ item, focusRef, onChange, onBlur });
};

export interface IEditableListProps<T> {
    items: T[];
    isBlank: (item: T) => boolean;
    buttonLabel: string;
    buttonDataType?: string;
    buttonFill?: 'solid' | 'outline';
    retainBlanks?: boolean;
    autoAddFirst?: boolean;
    preventAdd?: boolean;
    createNew: () => T;
    onChange: (items: T[]) => void;
    onAdd?: (item: T) => void;
    onUpdate?: (item: T) => void;
    onRemove?: (item: T) => void;
    renderRow: (props: IRenderRowProps<T>) => any;
    'data-type'?: string;
}

export const EditableList = <T,>({
    items,
    isBlank,
    buttonLabel,
    buttonDataType = 'add-item',
    buttonFill,
    retainBlanks,
    autoAddFirst,
    preventAdd,
    createNew,
    onChange,
    onAdd,
    onUpdate,
    onRemove,
    renderRow,
    'data-type': dataType
}: IEditableListProps<T>) => {

    const buttonRef = useRef<HTMLIonButtonElement>(null);
    const lastCount = useRef<number>();

    // if "add button" not visible, scroll it into view
    useEffect(() => {
        const timeout = setTimeout(() => {
            // scrollIntoView() makes ionic animations janky, so only do it when the
            // item count increases (i.e. when the user has clicked the "add" button)
            if (lastCount.current !== undefined && items.length > lastCount.current) {
                if (buttonRef.current) {
                    buttonRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end'
                    });
                }
            }
            lastCount.current = items.length;
            // in native, there's other things that make it so we need to delay the scroll further,
            // such as the webview resizing and scrolling to the activeElement
        }, isPlatform('desktop') ? 10 : isPlatform('capacitor') ? 1000 : 350);
        return () => clearTimeout(timeout);
    }, [items.length]);

    useIonViewWillEnter(() => {
        clearBlankRows();

        if (autoAddFirst && !items.length) {
            addNew();
        }
    }, [items]);

    useIonViewDidLeave(() => {
        clearBlankRows();
    }, [items]);

    const clearBlankRows = () => {
        // if we allow blanks while viewing the page,
        // we need to clear them when we enter/leave
        if (retainBlanks) {
            onChange(items.filter(r => !isBlank(r)));
        }
    };

    const addNew = () => {
        onChange([ ...items, createNew() ]);
    };

    const remove = (idx: number) => {
        const updated = [...items];
        updated!.splice(idx, 1); // mutates!
        onChange(updated);
    };

    const update = (item: T, idx: number) => {
        const updated = [...items];
        updated!.splice(idx, 1, { ...updated[idx], ...item }); // mutates!
        onChange(updated);
    };

    const handleChange = (item: T, idx: number) => {
        // fire item-level events if consumer wants them
        const wasBlank = !items[idx] || isBlank(items[idx]);
        if (wasBlank && !isBlank(item) && onAdd) {
            onAdd(item);
        }
        if (!wasBlank && !isBlank(item) && onUpdate) {
            onUpdate(item);
        }
        if (!wasBlank && isBlank(item) && onRemove) {
            onRemove(item);
        }

        // fire list-level onChange event
        if (!retainBlanks && isBlank(item)) {
            remove(idx);
        } else {
            update(item, idx);
        }
    };

    const handleBlur = (item: T, idx: number) => {
        if (!retainBlanks && isBlank(item)) {
            remove(idx);
        }
    };

    return (
        <>
            {items.map((item, idx) => (
                <EditableListRow
                    key={`editable-row-${idx}`}
                    item={item}
                    onChange={(updates) => handleChange(updates, idx)}
                    onBlur={() => handleBlur(item, idx)}
                    render={renderRow}
                    isBlank={isBlank}
                />
            ))}

            <IonButton
                data-type={buttonDataType}
                style={{scrollMarginBottom: 20}}
                ref={buttonRef}
                fill={buttonFill}
                disabled={preventAdd || items.some(isBlank)}
                onClick={addNew}
            >
                {buttonLabel}
            </IonButton>
        </>
    );
};
