import { IonAvatar, IonButton, IonButtons, IonHeader, IonMenuToggle, IonPopover, IonToolbar } from '@ionic/react';
import { FC, LegacyRef, useState } from 'react';
import { MenuCloseButton } from '../components/MenuCloseButton';
import { BellIcon, DeleteIcon, GearIcon, InnerhivePlusIcon, LogoutIcon } from '../theme/Icons';
import { MenuItem } from '../components/MenuItem';
import { Divider } from '../components/Divider';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AlertPresenter } from '../AlertBinder';
import { AuthService, IAuthService } from '../auth/AuthService';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { UserState } from '../user/UserState';
import { useSubscription } from '@meraki-internal/state';
import { createUseStyles } from 'react-jss';
import { RevenueCatModel } from '../innerhive-plus/revenue-cat/RevenueCatModel';
import { NotificationSettingsModal } from '../user/NotificationSettingsModal';

const useStyles = createUseStyles({
    headerToolbar: {
        '--background': 'var(--ion-color-primary)'
    },

    userName: {
        color: 'white',
        fontSize: 20,
        fontWeight: 500
    },

    userEmail: {
        color: 'white',
        fontSize: 16,
        fontWeight: 400,
        opacity: 0.9
    },

    accountInfo: {
        display: 'flex',
        gap: 10,
        overflow: 'hidden'
    },

    accountRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '20px 4px 25px 30px'
    },

    avatar: {
        width: 48,
        height: 48,
        border: '2px solid white',
        filter: 'drop-shadow(0px 4.545454502105713px 13.636362075805664px rgba(0, 0, 0, 0.15))'
    },

    accountMenuPopover: {
        '& hr': {
            marginBlockStart: 0,
            marginBlockEnd: 0
        }
    }
});

export type INavigationHeader = {
    onDelete: () => void;
    closeBtnRef?: LegacyRef<HTMLDivElement>;
};

export const NavigationHeader: FC<INavigationHeader> = ({ onDelete, closeBtnRef }) => {
    const classes = useStyles();
    const [isAccountMenuOpen, setAccountMenuOpen] = useState<boolean>(false);
    const [isNotificationSettingsOpen, setIsNotificationSettingsOpen] = useState<boolean>(false);

    const authService = useInstance(AuthService) as IAuthService;
    const alert = useInstance(AlertPresenter);
    const tracking = useInstance(MixpanelService);

    const revenueCat = useInstance(RevenueCatModel);
    useSubscription(() => revenueCat);

    const userState = useInstance(UserState);
    useSubscription(() => userState);

    const toggleAccountMenu = () => {
        setAccountMenuOpen(oldState => {
            if (oldState === false) {
                tracking.track('Profile Settings Opened');
            }
            return !oldState;
        });
    };

    const onSignOut = () => {
        tracking.track('Signed Out');
        authService.logout();
    };

    const onManageSubscription = async () => {
        await revenueCat.maybeDowngrade();
        setAccountMenuOpen(false);
    };

    const onDeleteAccount = async () => {
        toggleAccountMenu();

        const destroyOrCancel = await alert.confirmDestructiveAction({
            header: 'Delete Account?',
            message: 'Your account and care map will be deleted and cannot be recovered.'
        });

        if (destroyOrCancel === 'destroy'){
            onDelete();
            await userState.deleteAccount();
        }
    };

    const openNotificationSettings = () => {
        toggleAccountMenu();
        setIsNotificationSettingsOpen(true);
    };

    return (
        <>
            <IonHeader className="ion-no-border">
                <IonToolbar className={classes.headerToolbar}>
                    <IonButtons slot="end">
                        <IonMenuToggle>
                            <div ref={closeBtnRef}>
                                <MenuCloseButton color="light" />
                            </div>
                        </IonMenuToggle>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar className={classes.headerToolbar}>
                    <div className={classes.accountRow}>
                        <div className={classes.accountInfo}>
                            <div>
                                <IonAvatar className={classes.avatar}>
                                    <img style={{objectFit: 'contain'}} src={userState.state.pictureUrl} alt="avatar"/>
                                </IonAvatar>
                            </div>
                            <div style={{minWidth: 0}}>
                                <div className={`${classes.userName} ellipsis`}>
                                    {userState.getName()}
                                </div>
                                <div className={`${classes.userEmail} ellipsis`}>
                                    {userState.state.email}
                                </div>
                            </div>
                        </div>
                        <IonButtons slot="end">
                            <IonButton shape="round" color="light" onClick={toggleAccountMenu} id="account-menu-button">
                                <GearIcon color="light" size="medium" />
                            </IonButton>
                        </IonButtons>
                    </div>
                </IonToolbar>
            </IonHeader>

            {/* account settings (gear) menu */}
            <IonPopover
                alignment="end"
                trigger="account-menu-button"
                isOpen={isAccountMenuOpen}
                onDidDismiss={() => setAccountMenuOpen(false)}
                className={classes.accountMenuPopover}
            >
                <>
                    <MenuItem data-id="sign-out" onClick={onSignOut} label="Sign Out" Icon={LogoutIcon} />
                    {revenueCat.hasInnerhivePlus() && revenueCat.canMaybeDowngrade() &&
                        <MenuItem data-id="manage-subscriptions" onClick={onManageSubscription} label="Manage Subscription" Icon={InnerhivePlusIcon} />
                    }
                    <Divider />
                    <MenuItem data-id="notification-settings"
                        Icon={BellIcon}
                        label="Notification Settings"
                        onClick={openNotificationSettings}
                    />
                    <Divider />
                    <MenuItem data-id="delete-account" onClick={onDeleteAccount} color="danger" label="Delete Account" Icon={DeleteIcon} />
                </>
            </IonPopover>

            <NotificationSettingsModal
                isOpen={isNotificationSettingsOpen}
                closeHandler={() => setIsNotificationSettingsOpen(false)}
            />
        </>
    );
};
