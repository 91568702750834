import { IonButton, IonSpinner, isPlatform } from '@ionic/react';
import { useEffect, useState } from 'react';
import { Share as CapacitorShare } from '@capacitor/share';
import { createUseStyles } from 'react-jss';
import { TabRadioButtons } from '../components/TabRadioButtons';
import { CopyTextButton } from '../components/CopyTextButton';

const useStyles = createUseStyles({
    linkButton: {
        height: 40
    }
});

export interface ILinkInfo {
    linkDurationDays: number | undefined;
};

export interface IShareLinkButtonProps {
    'data-type': string;
    label: string;
    shareLabel: string;
    shareDescription: string;
    hasDuration?: boolean;
    disabled?: boolean;
    getDurationMessage?: (linkDurationDays: number) => any;
    createLinkUrl: (linkInfo: ILinkInfo) => Promise<string>;
    onMobileSharingOpened?: (linkInfo: ILinkInfo) => void;
    onMobileSharingDone?: () => void;
    onLinkCopied?: (linkInfo: ILinkInfo) => void;
}

export const ShareLinkButton: React.FC<IShareLinkButtonProps> = ({
    'data-type': dataType,
    label,
    shareLabel,
    shareDescription,
    hasDuration,
    disabled,
    getDurationMessage,
    createLinkUrl,
    onMobileSharingOpened = () => {},
    onMobileSharingDone = () => {},
    onLinkCopied = () => {}
}) => {
    const classes = useStyles();

    const [viewerUrl, setViewerUrl] = useState<string>('');

    const [isCreating, setIsCreating] = useState(false);

    const [canShare, setCanShare] = useState(false);

    const [linkDurationDays, setLinkDurationDays] = useState<number | undefined>(hasDuration ? 1 : undefined);

    useEffect(() => {
        if (isPlatform('capacitor')) {
            // mobile native
            CapacitorShare.canShare().then((result: any) => {
                setCanShare(result.value);
            });
        } else if (!isPlatform('desktop')) {
            // mobile web
            setCanShare(navigator.canShare && navigator.canShare({ url: 'https://app.innerhive.com' }));
        }
    }, []);

    const shareLink = async () => {
        let url = viewerUrl;
        if (!url) {
            setIsCreating(true);
            url = await createLinkUrl({ linkDurationDays });
            setViewerUrl(url);
            setIsCreating(false);
        }

        if (canShare) {
            onMobileSharingOpened({ linkDurationDays });

            try {
                const shareInfo = {
                    url,
                    title: shareLabel,
                    text: shareDescription
                };
                if (isPlatform('capacitor')) {
                    await CapacitorShare.share(shareInfo);
                } else {
                    await navigator.share(shareInfo);
                }

                onMobileSharingDone();
            } catch (err: any) {
                // if the user closes the share sheet without sharing, we don't care
                // (in web it's "AbortError", in native it's "share canceled")
                if (err?.name !== 'AbortError' && !err?.toString().toLowerCase().includes('share canceled')) {
                    throw err;
                }
            }
        }
    };

    if (canShare || !viewerUrl){
        return (
            <div data-type={dataType}>
                {hasDuration &&
                    <div style={{padding: '0 2px 2px 2px'}}>
                        <TabRadioButtons
                            tighterXs
                            value={linkDurationDays}
                            onChange={v => setLinkDurationDays(v)}
                            dataTypePrefix="day"
                            buttons={[{
                                label: '1 Day',
                                value: 1
                            },{
                                label: '7 Days',
                                value: 7
                            },{
                                label: '30 Days',
                                value: 30
                            }]}
                        />
                    </div>
                }

                <IonButton
                    fill="outline"
                    expand="block"
                    disabled={isCreating || disabled}
                    className={classes.linkButton}
                    onClick={shareLink}
                    data-type="link-button"
                >
                    {isCreating ? <IonSpinner name="dots" /> : <>{canShare ? shareLabel : label}</>}
                </IonButton>

                {canShare && hasDuration && getDurationMessage && <p style={{marginTop: 8}}>{getDurationMessage(linkDurationDays!)}</p>}
            </div>
        );
    } else {
        return (
            <div data-type={dataType}>
                {hasDuration && getDurationMessage &&
                    <p style={{marginTop: 25, marginBottom: 16}}>{getDurationMessage(linkDurationDays!)}</p>
                }

                <CopyTextButton
                    text={viewerUrl}
                    data-type="link-url"
                    onTextCopied={() => onLinkCopied({ linkDurationDays })}
                />
            </div>
        );
    }
};
