import { useIonRouter } from '@ionic/react';
import { useEffect } from 'react';
import { App, URLOpenListenerEvent } from '@capacitor/app';

export const AppUrlListener: React.FC<any> = () => {
    const router = useIonRouter();

    useEffect(() => {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            // example url: https://app.innerhive.com/#/caremap, path = /caremap
            const path = event.url.split('#').pop();
            if (path) {
                console.log(`Deep linked to "${path}"`);
                router.push(path);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
};
