import { FC } from 'react';
import { AppointmentsList } from './AppointmentsList';
import { DrawerPage } from '../components/DrawerPage';
import { CareMapDrawerState } from '../caremap/CareMapDrawerState';
import { useSubscription } from '@meraki-internal/state';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AddAppointmentButton } from './AddAppointmentButton';
import { AppointmentsState, NEW_APPOINTMENT_ID } from './AppointmentsState';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { MixPanelEventEmitter } from '../metrics/MixPanelEventEmitter';
import dayjs from 'dayjs';
import { ISupportGroupKey } from '../caremap/ICareMap';
import { CareMapState } from '../caremap/CareMapState';

export const AppointmentsView: FC<{memberId?: string; supportGroupKey?: ISupportGroupKey;}> = ({ memberId, supportGroupKey }) => {
    const history = useInstance(HistoryViewModel);

    const drawerState = useInstance(CareMapDrawerState);
    useSubscription(() => drawerState);

    const appointmentsState = useInstance(AppointmentsState);
    useSubscription(() => appointmentsState);

    const mixpanel = useInstance(MixPanelEventEmitter);
    const careMapState = useInstance(CareMapState);

    const trackingCategory = memberId ? careMapState.findSupportGroupMemberCategory(memberId) : supportGroupKey;
    const track = () => {
        // sort descending chronologically
        let appointments = appointmentsState.state.appointments;
        appointments = (appointments || []).sort((n1, n2) => dayjs(n1.start).isAfter(n2.start) ? 1 : -1);
        // find upcoming
        appointments = appointments.filter(appt => dayjs(appt.start).isAfter((new Date()).toISOString()));
        mixpanel.track('Appointments List Viewed', () => ({
            'Viewed From': `${trackingCategory || ''}${memberId ? ' member' : ''}` || 'care recipient',
            'Number of Total Upcoming Appointments': appointments.length
        }));
    };

    const onDidEnter = () => {
        track();
        drawerState.setIsMaskingFocusedCareMap(true);
    };

    const onWillLeave = () => {
        drawerState.setIsMaskingFocusedCareMap(false);
    };

    const editAppointment = (appointmentId?: string) => {
        if (appointmentId === NEW_APPOINTMENT_ID) {
            appointmentsState.trackNewAppointmentOpened({supportGroupKey: trackingCategory, memberId});
        }

        history.push(`${history.getCurrentLocation().pathname}/${appointmentId}`);
    };

    return (
        <>
            <DrawerPage
                hasBack={true}
                title="Appointments"
                enteredHandler={onDidEnter}
                leavingHandler={onWillLeave}
                headerButtons={
                    <AddAppointmentButton
                        onClick={editAppointment}
                        canAdd={appointmentsState.canAdd()}
                    />
                }
            >
                <AppointmentsList
                    showHeading={false}
                    supportMemberId={memberId}
                    supportGroupKey={supportGroupKey}
                    data-type="more-appointments-list"
                    editAppointment={editAppointment}
                />
            </DrawerPage>
        </>
    );
};
