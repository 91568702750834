import { ToastOptions, isPlatform, useIonToast } from '@ionic/react';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import { HookOverlayOptions } from '@ionic/react/dist/types/hooks/HookOverlayOptions';
import { useBreakpoints } from '../theme/useBreakpoints';

export const useToast = () => {
    const [showToast, dismissToast] = useIonToast();
    const {isBelowBreakpoint} = useBreakpoints();

    if (isPlatform('capacitor')) {
        window.addEventListener('keyboardWillShow', () => {
            dismissToast();
        });
    }

    return [(options: ToastOptions & HookOverlayOptions) => {
        showToast({
            ...options,
            ...{
                mode: 'ios',
                keyboardClose: true,
                swipeGesture: 'vertical' // leaving for when we upgrade ionic to > 7.6
            },
            onWillPresent: (event: CustomEvent<OverlayEventDetail<any>>) => {
                // because it's in the shadow dom and couldn't figure out how to access via css selector in app.css
                if (isBelowBreakpoint('sm')) {
                    const toast = document.querySelector('ion-toast');
                    if (toast && toast.shadowRoot) {
                        const wrapper = toast.shadowRoot.querySelector('.toast-wrapper.toast-bottom');
                        wrapper!.setAttribute('style', 'bottom: 65px');
                    }
                }

                if (options.onWillPresent) {
                    options.onWillPresent(event);
                }
            }
        });
    }];
};
