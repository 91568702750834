import dayjs from 'dayjs';
import { IonButton } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { DuoCalendarDaysIcon } from '../theme/Icons';
import { CheckinState } from './CheckinState';
import { Card } from '../components/Card';
import { Text } from '../components/Text';
import { IFeeling } from './ICheckin';
import { CheckinsModal } from './CheckinsModal';
import { MouseEvent, useState } from 'react';
import { FeelingSelector } from './FeelingSelector';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { MixPanelEventEmitter } from '../metrics/MixPanelEventEmitter';

export const CheckinCard: React.FC = () => {
    const history = useInstance(HistoryViewModel);
    const mixpanel = useInstance(MixPanelEventEmitter);

    const checkinState = useInstance(CheckinState);
    useSubscription(() => checkinState);

    const [checkinsOpen, setCheckinsOpen] = useState<boolean>(false);

    if (!checkinState.state) {
        return null;
    }

    const todayCheckin = checkinState.getCheckinForToday();

    const onClickCard = async () => {
        mixpanel.track('Daily Log Opened', () => ({
            'Day': mixpanel.createMixpanelDateWithoutTime(dayjs().format('YYYY-MM-DD')),
            'From': 'caregiver corner'
        }));

        history.push('/caregiver/daily-log');
    };

    const onClickFeeling = async (feeling?: IFeeling) => {
        mixpanel.track('Daily Log Opened', () => ({
            'Day': mixpanel.createMixpanelDateWithoutTime(dayjs().format('YYYY-MM-DD')),
            'From': 'caregiver corner'
        }));

        const qs = (feeling ? `?feeling=${feeling}` : '');
        history.push(`/caregiver/daily-log${qs}`);
    };

    const onClickViewAll = (e: MouseEvent) => {
        e.stopPropagation();
        setCheckinsOpen(true);
    };

    const closeOrDismissCheckins = () => {
        setCheckinsOpen(false);
    };

    return (
        <>
            <Card data-type="checkin-card" padded onClick={onClickCard}>

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 30}}>
                    <div>
                        <Text><b>How was your day?</b></Text>
                        <div style={{marginTop: 5, marginBottom: 5}}><Text type="detail">Today, {dayjs().format('dddd MMMM D')}</Text></div>
                    </div>
                    <div>
                        <IonButton
                            fill="clear"
                            onClick={onClickViewAll}
                            data-id="checkin-calendar-button"
                            style={{'--padding-top': '15px', '--padding-bottom': '15px'}}
                        >
                            <DuoCalendarDaysIcon slot="start" />
                            &nbsp;&nbsp;All Days
                        </IonButton>
                    </div>
                </div>

                <FeelingSelector value={todayCheckin.feeling} onChange={onClickFeeling} />

            </Card>

            <CheckinsModal
                isOpen={checkinsOpen}
                closeHandler={closeOrDismissCheckins}
            />
        </>
    );
};
