import { IonCol } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { ReactFlowProvider } from 'reactflow';
import React, { useEffect } from 'react';
import { AppPrincipal, isCaremapViewer } from '../auth/AppPrincipal';
import { CareMapState } from '../caremap/CareMapState';
import { useBreakpoints } from '../theme/useBreakpoints';
import { QuestionnaireWelcomePage, WelcomePageState } from '../QuestionnaireWelcomePage';
import { LoadingPage } from '../LoadingPage';
import { CareMapFlow } from '../caremap/CareMapFlow';
import { InitialWizard } from './InitialWizard';
import { mediaMinWidth } from '../theme/utils';
import { RedirectToDefaultQuestion } from './RedirectToDefaultQuestion';

const useStyles = createUseStyles({
    wizardPane: {
        padding: 0,
    },
    [mediaMinWidth.md]: {
        wizardPane: {
            borderLeft: 'var(--app-theme-border)',
            boxShadow: '0px 0px 10px 1px #aaa'
        }
    }
});

export const QuestionnaireRouteHandler: React.FC<{ careMapId?: string; }> = ( {careMapId }) => {
    const classes = useStyles();

    const isViewer = isCaremapViewer(useInstance(AppPrincipal));

    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);

    const {isAboveBreakpoint} = useBreakpoints();

    const welcomePageState = useInstance(WelcomePageState);
    useSubscription(() => welcomePageState);

    useEffect(() => {
        if (!careMapStateInstance.hasStartedWizard() && welcomePageState.hasContinued()) {
            // get a new care map from api
            careMapStateInstance.enqueueSave();
        }
    }, [welcomePageState.state]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!careMapStateInstance.hasStartedWizard() && !welcomePageState.hasContinued()) {
        return (
            <QuestionnaireWelcomePage />
        );
    }

    if (isViewer || careMapStateInstance.getActiveCaremap()!.essentialMapComplete) {
        return <Redirect to="/caremaps" />;
    }

    // catch old / bookmarked urls and redirect (ie /questionnaire/my-name)
    const id = careMapId;
    if (id && isNaN(Number(id))) {
        return <Redirect to="/questionnaire" />;
    }

    if (!careMapStateInstance.hasStartedWizard()) {
        return <LoadingPage showLogo />;
    }

    return (
        <>
            <Route exact path="/questionnaire/:careMapId" render={(props) => (
                <RedirectToDefaultQuestion careMapId={props.match.params.careMapId} />
            )} />
            {isAboveBreakpoint('md') ? (
                <IonCol sizeXs="0" sizeMd="6" sizeLg="7" sizeXl="8">
                    <ReactFlowProvider>
                        <CareMapFlow
                            name="wizard"
                            fit="custom"
                            careMap={careMapStateInstance.getActiveCaremap()!}
                        />
                    </ReactFlowProvider>
                </IonCol>
            ) : null}
            <IonCol sizeXs="12" sizeMd="6" sizeLg="5" sizeXl="4" className={classes.wizardPane}>
                <InitialWizard />
            </IonCol>
        </>
    );
};
