import { useInstance } from '@meraki-internal/react-dependency-injection';
import { FeatureFlagState } from './FeatureFlagState';
import { useSubscription } from '@meraki-internal/state';
import { IonContent, IonItem, IonList, IonPage, IonToggle } from '@ionic/react';

export const FeatureFlags: React.FC = () => {
    const flagsState = useInstance(FeatureFlagState);
    useSubscription(() => flagsState);
    return (
        <IonPage>
            <IonContent className="ion-padding">
                <h1>Feature Flags</h1>
                <IonList>
                    {flagsState.getSupportedFlags().map(key => (
                        <IonItem key={key}>
                            <IonToggle checked={flagsState.getFlagValue(key)} onIonChange={e => flagsState.setFlagValue(key, e.detail.checked)} >{key}</IonToggle>
                        </IonItem>
                    ))}
                </IonList>
                <p>You may need to restart the app for flag changes to fully take effect.</p>
            </IonContent>
        </IonPage>
    );

};
