import { FC } from 'react';
import { Spinner } from './components/Spinner';

export const LoadingPage: FC<{showLogo: boolean}> = ({showLogo}) => {
    // can't use useInstance(AsyncStorageProvider) to get the live updates path here,
    // as container doesn't exist yet, so we handle that upstream in AppContainer and pass showLogo down
    return (
        <div style={{
            gap: 20,
            position: 'absolute',
            left: 0,
            top:0,
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'var(--ion-background-color)'
        }}>
            <img src="./assets/images/logos/logo.svg" alt="Innerhive Logo" height="69px" style={{visibility: showLogo ? 'visible' : 'hidden'}} />
            <Spinner />
        </div>
    );
};
