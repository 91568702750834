import { State } from '@meraki-internal/state';
import { AppPrincipal } from '../../auth/AppPrincipal';
import { DeviceInfo } from '@capacitor/device';
import { DevSettings } from '../../support/DevSettings';
import { Logger } from '../../support/Logger';
import { UserFacingError } from './UserFacingError';
import { IFreeTierLimits, IOfferMeta } from './IFreeTierLimits';
import { APIClient } from '@innerhive-internal/innerhive-api-client';

export const ENTITLEMENTS = {
    INNERHIVE_PLUS: 'e_plus'
};
const sensibleDefaults: IFreeTierLimits = {
    freeAttachmentsPerCaremap: 9,
    freeCaremapsOwned: 2
};

interface IRevenueCatModel {
    canHaveInnerhivePlus: () => boolean;
    hasInnerhivePlus:() => boolean;
    upgrade: () => Promise<void>;
    maybeDowngrade: () => Promise<void>;
}

export interface IRevenueCatAPIPort {
    init: () => Promise<void>;
    fetchEntitlements: () => Promise<void>;
    hasInnerhivePlus:() => boolean;
    canBypassPayments:() => boolean;
    upgrade:() => Promise<void>;
    maybeDowngrade:() => Promise<void>;
    canDowngrade: () => boolean;
    subscribe: (callback: ()=> void) => void;
    getOfferMeta: () => IOfferMeta;
}

export interface IRevenueCatSDKPort {
    init: () => Promise<void>;
    upgrade: (term: IPaymentTerm) => Promise<void>;
    restore: () => Promise<void>;
    canMakeRealPayment: () => boolean;
    subscribe: (callback: ()=> void) => void;
    getFreeTierLimits: () => IFreeTierLimits | undefined;
    hasInnerhivePlusOffer: (term?: IPaymentTerm) => boolean;
}

export interface IDevicePort {
    getDeviceInfo: () => Promise<DeviceInfo>;
}

type IPaymentMode = 'real' | 'bypass' | 'none';

export type IPaymentTerm = 'monthly' | 'annual';

/**
 * Using the Orthogonal Pattern to manage the BLL around payments
 */
export class RevenueCatModel extends State<Record<string, never>> implements IRevenueCatModel {
    // injecting is causing tests to break due to down stream EnvConfiguration dependency
    constructor(
        private api: IRevenueCatAPIPort,
        private sdk: IRevenueCatSDKPort,
        private principal: AppPrincipal,
        private devSettings: DevSettings,
        private logger: Logger,
        private deviceInfoProvider: IDevicePort,
        private apiClient: APIClient
    ){
        super({ });
        this.api.subscribe(() => {
            this.setState({});
        });
        this.sdk.subscribe(() => {
            this.setState({});
        });
    }

    // gets set during init
    private deviceInfo!: DeviceInfo;

    /**
     * Viewing user cannot have innerhive plus. Note there is an edge case where
     * a user, that is meant to go through the SDK, but has no offer, cannot get inner hive plus
     * but we don't expose that here, instead we throw when they try and purchase
     * because this drives if they should see it, and we still want them to see it.
     */
    canHaveInnerhivePlus = () => {
        return this.principal.tokenType === 'user';
    };

    getPaymentMode = (): IPaymentMode => {
        const canBypass = this.api.canBypassPayments();
        const useRealPaymentsEvenIfBypassEnabled = this.devSettings.useRealPaymentsEvenIfBypassEnabled;

        const canReal = this.sdk.canMakeRealPayment();

        // if we prefer real, but real isn't an option
        if (canBypass && useRealPaymentsEvenIfBypassEnabled && !canReal){
            return 'bypass';
        }
        if (canBypass && useRealPaymentsEvenIfBypassEnabled && canReal){
            return 'real';
        }

        if (canBypass){
            return 'bypass';
        }

        if (canReal){
            return 'real';
        }

        return 'none';
    };

    hasInnerhivePlus = () => {
        return this.api.hasInnerhivePlus();
    };

    getOfferMeta = (): IOfferMeta => {
        return this.api.getOfferMeta();
    };

    hasAnnualOffer = (): boolean => {
        const mode = this.getPaymentMode();
        if (mode === 'none'){
            return false;
        }
        else if (mode === 'bypass'){
            return true;
        }
        else { // real
            return this.sdk.hasInnerhivePlusOffer('annual');
        }
    };

    getFreeTierLimits = () : IFreeTierLimits => {
        if (this.hasInnerhivePlus()){
            throw new Error('the user has innerhive plus and has no limits');
        }

        let limits: IFreeTierLimits | undefined = undefined;

        if (this.getPaymentMode() === 'real'){
            limits = this.sdk.getFreeTierLimits();
        } else {
            const { freeAttachmentsPerCaremap, freeCaremapsOwned } = this.api.getOfferMeta();
            if (typeof freeAttachmentsPerCaremap == 'number' && typeof freeCaremapsOwned === 'number'){
                limits = { freeAttachmentsPerCaremap, freeCaremapsOwned };
            }
        }

        if (!limits){
            this.logger.error(`WARN: Revenue Cat is using "sensible defaults" b/c user ${this.principal.userId} was not able to get limits from their offer`);
            limits = sensibleDefaults;
        }

        return limits;
    };

    init = async () => {
        this.logger.info('RevenueCatModel.init-ing');
        this.deviceInfo = await this.deviceInfoProvider.getDeviceInfo();

        if (!this.canHaveInnerhivePlus()){
            return;
        }

        await this.api.init();
        await this.sdk.init();
        this.logger.info('RevenueCatModel.init-ed', this.getDiagnostics().diagnostics);
    };

    // the app always shows the purchase page and throws an error if they cannot
    // kept this around for a hint in the debug page for DX
    canUpgrade = () => {
        if (this.hasInnerhivePlus()){
            return false;
        }
        return this.getPaymentMode() !== 'none';
    };

    private guardAgainstCannotUpgrade = () => {
        if (this.getPaymentMode() === 'bypass'){
            return;
        }

        if (this.deviceInfo.platform === 'web' && this.deviceInfo.operatingSystem === 'android'){
            throw new UserFacingError({
                status: 400,
                message: 'web is not yet supported',
                errorCode: 'android-web-not-supported',
                displayHeader: 'Not Supported Yet',
                displayMessage: 'But you can upgrade using our Android App',
                links: [{
                    label: 'Get Android App',
                    href: 'https://play.google.com/store/apps/details?id=com.innerhive.app.v2'
                }],
                okLabel: 'Not Yet'
            });
        }
        if (this.deviceInfo.platform === 'web' && this.deviceInfo.operatingSystem === 'ios'){
            throw new UserFacingError({
                status: 400,
                message: 'web is not yet supported',
                errorCode: 'ios-web-not-supported',
                displayHeader: 'Not Supported Yet',
                displayMessage: 'But you can upgrade using our iOS App',
                links: [{
                    label: 'Get iOS App',
                    href: 'https://apps.apple.com/gb/app/innerhive-caregiver-support/id6469646845'
                }],
                okLabel: 'Not Yet'
            });
        }
        if (this.deviceInfo.platform === 'web'){
            throw new UserFacingError({
                status: 400,
                message: 'web is not yet supported',
                errorCode: 'web-not-supported',
                displayHeader: 'Cannot upgrade on Web',
                displayMessage: 'But you can upgrade using our App',
                links: [
                    {
                        label: 'Get Android App',
                        href: 'https://play.google.com/store/apps/details?id=com.innerhive.app.v2'
                    },
                    {
                        label: 'Get iOS App',
                        href: 'https://apps.apple.com/gb/app/innerhive-caregiver-support/id6469646845'
                    }
                ],
                okLabel: 'Not Yet'
            });
        }

        if (!this.canUpgrade()){
            throw new Error('upgrade was called when .canUpgrade() returns false');
        }
    };

    upgrade = async (term: IPaymentTerm = 'monthly') => {
        this.guardAgainstCannotUpgrade();

        const paymentMode = this.getPaymentMode();
        if (paymentMode === 'real'){
            await this.handleSDKUpgrade(term);
        }
        else if (paymentMode === 'bypass'){
            await this.api.upgrade();
        }
        else {
            throw new Error(`mode ${paymentMode} is not supported`);
        }

        if (!this.hasInnerhivePlus()){
            this.logger.error(`WARN: user ${this.principal.userId} just completed an upgrade in mode ${this.getPaymentMode()} but does not appear to have innerhive-plus`);
        }
    };

    private handleSDKUpgrade = async (term: 'monthly' | 'annual') => {
        await this.sdk.upgrade(term);
        await this.api.fetchEntitlements();
    };

    canRestore = () => {
        if (this.hasInnerhivePlus()){
            return false;
        }
        if (this.deviceInfo.platform === 'web') {
            return false;
        }
        if (this.getPaymentMode() === 'none') {
            return false;
        }
        return true;
    };

    restore = async () => {
        if (!this.canRestore()){
            throw new Error('restore was called when .canRestore() returns false');
        }

        if (this.getPaymentMode() === 'bypass') {
            throw new UserFacingError({
                status: 400,
                message: 'restore not supported',
                errorCode: 'restore-not-supported',
                displayHeader: 'Restore not supported',
                displayMessage: 'Cannot restore in bypass mode'
            });
        }

        await this.sdk.restore();
        await this.api.fetchEntitlements();

        if (!this.hasInnerhivePlus()) {
            throw new UserFacingError({
                status: 400,
                message: 'nothing to restore',
                errorCode: 'no-entitlements-to-restore',
                displayHeader: 'No Prior Purchases',
                displayMessage: 'Sorry, we did not find any prior purchases to restore.'
            });
        }
    };

    canMaybeDowngrade = () => {
        if (!this.hasInnerhivePlus()){
            return false;
        }
        return this.api.canDowngrade();
    };

    /**
     * For internal users that are bypassing payment. This will downgrade.
     *
     * For Android users, this will launch into play store subscriptions. Where this might cancel.
     *
     * If they do, it won't be effective for 3 minutes.
     */
    maybeDowngrade = async () => {
        await this.api.maybeDowngrade();
    };

    getDiagnostics = () => {
        const diagnostics: { [label: string]: any } = {
            userId: this.principal.userId,
            canHaveInnerhivePlus: this.canHaveInnerhivePlus(),
            hasInnerhivePlus: this.hasInnerhivePlus(),
            paymentMode: this.getPaymentMode(),
            deviceInfo: this.deviceInfo,
            freeTierLimits: !this.hasInnerhivePlus() ? this.getFreeTierLimits() : undefined,
            offerMeta: !this.hasInnerhivePlus() ? this.getOfferMeta() : undefined,
            canMaybeDowngrade: this.canMaybeDowngrade()
        };

        return {
            diagnostics,
        };
    };
}
