import debounce from 'lodash/debounce';
import { Logger } from '../support/Logger';
import { DebouncedFunc } from 'lodash';
import { BufferedEventBus } from '../utils/BufferedEventBus';
import { ITrackingContext } from './MixpanelService';
import dayjs from 'dayjs';

// re-exporting for conveniece to consumers
export {  type ITrackingContext };

/**
 * A strongly typed wrapper around BufferedEventBus specifically for MixPanel.
 *
 * Primary concerns of this calss
 * 1. encapsulate the name of the event on the bridge, and the schema of the payload
 *    to reduce related errors / improve DX
 * 2. minimal dependencies so this can be instantiated immediately in the app container
 */
export class MixPanelEventEmitter {
    static inject = () => [BufferedEventBus, Logger];
    constructor(private events: BufferedEventBus, private log: Logger){}

    /**
     * If we send a date string like "2024-07-01" to Mixpanel, it always converts it to an
     * ISO8601 string in UTC time by appending "T00:00:00.000Z" to the end before storing it.
     * We have no control over this behavior, which is applied to any string that "looks like"
     * some kind of date (see https://mixpanel.com/blog/tapping-into-advanced-data-types/).
     *
     * Mixpanel then formats these dates in US Eastern Time when displaying on their website.
     * This causes "2024-07-01" to display as "Sun, Jun 30, 2024 8:00 PM", which is the wrong day.
     *
     * So if we ever want to send a date without a time to Mixpanel, we first pass it through
     * this helper function, which converts it to the UTC ISO8601 string that will produce
     * our desired formatted output in Mixpanel (if we ignore the time component).
     *
     * Example:
     * createMixpanelDateWithoutTime('2024-07-01') => '2024-07-01T04:00:00.000Z'
     * which displays in Mixpanel as "Mon, Jul 01, 2024 12:00 AM"
     *
     * @param dateWithoutTime a date string like "2024-01-01"
     * @returns an ISO8601 string that will display the equivalent date in mixpanel
     */
    createMixpanelDateWithoutTime = (dateWithoutTime: string) => {

        // check the format at runtime to catch easy bug where we accidentally pass an IOS8601 string
        const regex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
        if (!regex.test(dateWithoutTime)) {
            throw new Error('Invalid dateWithoutTime, should be in format YYYY-MM-DD');
        }

        return dayjs.tz(dateWithoutTime, 'America/New_York').toISOString();
    };

    onTrack = (listener: (event: string, context: ITrackingContext) => void) => {
        return this.events.on('MixPanelService.track', (payload) => {
            const { event, context } = payload as { event: string; context: ITrackingContext };
            listener(event, context);
        });
    };

    track = (event: string, getContext?: () => ITrackingContext) => {
        try {
            const context = getContext ? getContext() : undefined;
            this.sendToBridge(event, context);
        } catch (e: any) {
            this.log.error(new Error(`Failed to track event "${event}": ${e.message}`));
        }
    };

    private eventsToDebounceFunction: { [event:string]: DebouncedFunc<(event: string, getContext?: () => ITrackingContext) => void>} = {};

    trackWithDebounce = ({ event, getContext, waitMS = 2000 }: {event: string, getContext?: () => ITrackingContext, waitMS?: number }) => {
        try {
            if (!this.eventsToDebounceFunction[event]){
                this.eventsToDebounceFunction[event] = debounce(this.track, waitMS);
            }

            this.eventsToDebounceFunction[event](event, getContext);

        } catch (e: any) {
            this.log.error(new Error(`Failed to track event with debounce "${event}": ${e.message}`));
        }
    };

    private sendToBridge = (event: string, context?: ITrackingContext) => {
        context = { ...context };
        this.log.info(`Track "${event}"`, context);

        this.events.emit('MixPanelService.track', { event, context });
    };
}
