import { EventEmitter } from 'events';
import { Logger } from '../support/Logger';

type IUnsubscribe = () => void;

class EventBus {
    private events: EventEmitter;
    constructor(){
        this.events = new EventEmitter();
    }
    emit = <TPayload>(name: string, payload?: TPayload) => {
        this.events.emit(name, payload);
    };

    on = <TPayload>(name: string, listener: (payload?: TPayload) => void): IUnsubscribe => {
        this.events.addListener(name, listener);

        return () => {
            this.events.removeListener(name, listener);
        };
    };
}

export class BufferedEventBus {
    static inject = () => [EventBus, Logger];
    constructor(private events: EventBus, private log: Logger){

    }

    private buffer: { [eventName: string]: any[]} = {};

    emit = <TPayload>(name: string, payload?: TPayload) => {
        this.events.emit(name, payload);
        try {
            this.addToBuffer(name, payload);
        } catch (err: any){
            this.log.error(`Failed to push event into the buffer. ${err.toString()}`);
        }
    };

    private addToBuffer = (name: string, payload?: any) => {
        if (!this.buffer[name]){
            this.buffer[name] = [];
        }

        this.buffer[name].push(payload);

        // limit buffer to last 100
        this.buffer[name] = this.buffer[name].slice(-100);
    };

    on = <TPayload>(name: string, listener: (payload?: TPayload) => void): IUnsubscribe => {
        // next tick, playback what was missed
        Promise.resolve().then(() => {
            (this.buffer[name] || []).forEach((payload: any | undefined) => {
                try {
                    listener(payload);
                }
                catch (err: any){
                    this.log.error(`failed catching listener up with buffer for ${name}. Error: ${err.toString()}`);
                }
            });
        });
        return this.events.on(name, listener);
    };
}
