import { FC } from 'react';
import { IonAvatar } from '@ionic/react';
import { IFamilyMember } from '../caremap/ICareMap';
import { EditIcon2 } from '../theme/Icons';

export const MemberAvatar: FC<{
    member: Partial<IFamilyMember>;
    small?: boolean;
    onClick?: (e: React.MouseEvent) => void;
}> = ({ member, small = false, onClick }) => {
    const imageUrl = member.photo || member.avatar;
    const size = {width: small ? 42 : 64, height: small ? 42 : 64 };

    const avatar = (
        <IonAvatar style={{ ...size, backgroundColor: member.color }} data-avatar={imageUrl || member.color}>
            {imageUrl &&
                <div style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    backgroundImage: `url("${encodeURI(imageUrl)}")`,
                    // TODO: better way to set these up / scale / position them, based on our "profile image" effort in the future
                    position: 'relative',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '75% 40%'
                }}></div>
            }
        </IonAvatar>
    );

    if (onClick) {
        return (
            <div style={{ cursor: 'pointer', position: 'relative'}} onClick={(e: React.MouseEvent) => onClick(e)}>
                {avatar}
                <div style={{ position: 'absolute', bottom: -2, right: -2 }}>
                    <EditIcon2 size="small" shape="round" backgroundColor="white" borderColor="medium" />
                </div>
            </div>
        );
    }
    else {
        return avatar;
    }
};
