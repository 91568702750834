

import { ICareMap, INewCareMap, ISupportGroupKey } from '../caremap/ICareMap';
import { SupportGroupKeySorter } from '../support-group/SupportGroupKeySorter';

interface ISupportGroupPositionConfig {
    indicatorCenter: [number, number],
    hexagonOffsets: IHoneyCombPositions,
}

type IHexagonPosition = [number, number];

export type IHoneyCombPositions = [
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
    IHexagonPosition,
]

type ISupportGroupPositionsName = 'five-or-less' | 'six';
const namedSupportGroupPositions: { [key in ISupportGroupPositionsName ]: ISupportGroupPositionConfig[]; } = {
    'five-or-less': [
        {
            indicatorCenter: [-375, 720],
            hexagonOffsets: [ [-1, 1], [-1, 0], [0, -1], [-2, 0], [-2, -1], [-1, -1], [-2, 1], [-3, 1], [-3, 0], [-3, -1], [-4, 0], [-4, -1], [-4, 1], [-5,1], [-5,0], [-5,-1], [-4,-2], [-2,-2], [0,-2], [-1,-2], [-3,-2], [-5,-2], [-5,2], [-5,3] ],
        },
        {
            indicatorCenter: [-315, 335],
            hexagonOffsets: [ [0, 1], [-1, 1], [-1, 0], [-1, 2], [-2, 1], [-2, 0], [-2, 2], [-3, 2], [-3, 1], [-3, 0], [-4, 1], [-4, 0], [-5,2], [-4,2], [-3,3], [-6,1], [-6,2], [-5,3], [-4,3], [-6, 3], [-5,4], [-4,4], [-6,4], [-5,5] ],
        },
        {
            indicatorCenter: [0, 300],
            hexagonOffsets: [ [-1, 1], [0, 1], [1, 1], [-1, 2], [0, 2], [1, 2], [-1, 3], [0, 3], [1, 3], [-2, 2], [-2, 3], [-3, 3], [-4, 3], [-3, 4], [-2,4], [-1,4], [0, 4], [1, 4], [1, 5], [-1,5], [-4,4], [-3,5], [-5,4], [-5,5] ],
        },
        {
            indicatorCenter: [320, 400],
            hexagonOffsets: [ [1, 1], [0, 1], [1, 0], [1, 2], [2, 1], [2, 0], [3, 1], [3, 0], [3, 2], [2, 2], [1, 3], [0, 2], [3, 3], [4, 2], [4, 1], [4, 0], [2, 3], [4, 3], [3, 4], [1, 4], [0, 3], [5, 1], [5, 2], [5, 3] ],
        },
        {
            indicatorCenter: [375, 720],
            hexagonOffsets: [ [1, 1], [1, 0], [0, -1], [2, 0], [2, -1], [1, -1], [3, 0], [3, -1], [3, 1], [4, 0], [4, -1], [4, 1], [5,2], [5,1], [5,0], [5, -1], [4,-2], [2,-2], [0,-2], [1,-2], [3,-2], [5,-2], [-1, -1], [-1, -2] ],
        }
    ],
    'six': [
        {
            indicatorCenter: [-500, 800],
            hexagonOffsets: [ [-1, 1], [-1, 0], [0, -1], [-2, 0], [-2, -1], [-1, -1], [-2, 1], [-3, 1], [-3, 0], [-3, -1], [-4, 0], [-4, -1], [-4, 1], [-5,1], [-5,0], [-5,-1], [-4,-2], [-2,-2], [0,-2], [-1,-2], [-3,-2], [-5,-2], [-5,2], [-5,3] ],
        },
        {
            indicatorCenter: [-315, 335],
            hexagonOffsets: [ [0, 1], [-1, 1], [-1, 0], [-1, 2], [-2, 1], [-2, 0], [-2, 2], [-3, 2], [-3, 1], [-3, 0], [-4, 1], [-4, 0], [-5,2], [-4,2], [-3,3], [-6,1], [-6,2], [-5,3], [-4,3], [-6, 3], [-5,4], [-4,4], [-6,4], [-5,5] ],
        },
        {
            indicatorCenter: [0, 300],
            hexagonOffsets: [ [-1, 1], [0, 1], [1, 1], [-1, 2], [0, 2], [1, 2], [-1, 3], [0, 3], [1, 3], [-2, 2], [-2, 3], [-3, 3], [-4, 3], [-3, 4], [-2,4], [-1,4], [0, 4], [1, 4], [1, 5], [-1,5], [-4,4], [-3,5], [-5,4], [-5,5] ],
        },
        {
            indicatorCenter: [320, 400],
            hexagonOffsets: [ [1, 1], [0, 1], [1, 0], [1, 2], [2, 1], [2, 0], [3, 1], [3, 0], [3, 2], [2, 2], [1, 3], [0, 2], [3, 3], [4, 2], [4, 1], [4, 0], [2, 3], [4, 3], [3, 4], [1, 4], [0, 3], [5, 1], [5, 2], [5, 3] ],
        },
        {
            indicatorCenter: [375, 720],
            hexagonOffsets: [ [1, 1], [1, 0], [0, -1], [2, 0], [2, -1], [1, -1], [3, 0], [3, -1], [3, 1], [4, 0], [4, -1], [4, 1], [5,2], [5,1], [5,0], [5, -1], [4,-2], [2,-2], [0,-2], [1,-2], [3,-2], [5,-2], [-1, -1], [-1, -2] ],
        },
        {
            indicatorCenter: [320, 1120],
            hexagonOffsets: [ [1, 1], [1, 0], [0, -1], [2, 0], [2, -1], [1, -1], [3, 0], [3, -1], [3, 1], [4, 0], [4, -1], [4, 1], [5,2], [5,1], [5,0], [5, -1], [4,-2], [2,-2], [0,-2], [1,-2], [3,-2], [5,-2], [-1, -1], [-1, -2] ],
        }
    ],
};

const supportGroupCountToPositions: { [count: string]: ISupportGroupPositionsName; } = {
    '0': 'five-or-less',
    '1': 'five-or-less',
    '2': 'five-or-less',
    '3': 'five-or-less',
    '4': 'five-or-less',
    '5': 'five-or-less',
    '6': 'six',
};

export class HoneycombConfigProvider {
    static inject = () => [
        SupportGroupKeySorter
    ];
    constructor(
        supportGroupKeySorter: SupportGroupKeySorter
    ){
        this.defaultSortOrder = supportGroupKeySorter.sort({
            community: 1,
            school: 2,
            medical: 3,
            specialists: 4,
            financial: 5,
            social: 6
        });
    }
    activeCaremap?: ICareMap | INewCareMap;

    private defaultSortOrder: ISupportGroupKey[];

    private getActveCaremapSortOrder = (): ISupportGroupKey[] => {
        if (!this.activeCaremap){
            return this.defaultSortOrder;
        }

        const supportGroups = this.defaultSortOrder.map((supportGroupKey, index) => {
            return {
                supportGroupKey,
                isRelevant: this.activeCaremap![supportGroupKey].isRelevant,
                isRelevantSince: this.activeCaremap![supportGroupKey].isRelevantSince || new Date(index).toISOString(),
            };
        });

        const sortOrder = supportGroups.filter(s => s.isRelevant)
            .sort((l, r) => l.isRelevantSince.localeCompare(r.isRelevantSince))
            .map(s => s.supportGroupKey);

        return sortOrder;
    };

    getSupportGroupIndexByKey = (key: ISupportGroupKey): number => {
        const index = this.getActveCaremapSortOrder().indexOf(key);

        // mostly this shouldn't happen, but as state changes it can so embrace it
        // eg caremap two is active, but a render of caremap one is asking for a key it has but caremap two doesn't
        // maybe...
        if (index === -1){
            return 0;
        }
        return index;
    };

    private getSupportGroupPositions = () => {
        const count = this.getActveCaremapSortOrder().length;
        const positionsName = supportGroupCountToPositions[String(count)];
        if (!positionsName){
            throw new Error(`do not support positions for ${count} support groups`);
        }
        return namedSupportGroupPositions[positionsName];
    };

    getSupportGroupPositionByKey = (key: ISupportGroupKey): ISupportGroupPositionConfig => {
        return this.getSupportGroupPositions()[this.getSupportGroupIndexByKey(key)];
    };

    getMaxHoneycombHexagons = (): number => {
        return namedSupportGroupPositions['five-or-less'][0].hexagonOffsets.length;
    };
}
