import { PushNotifications } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { DeviceInfo } from '../support/DeviceInfo';
import { Logger } from '../support/Logger';

export class PushNotificationService {

    static inject = () => [
        DeviceInfo,
        Logger
    ];
    constructor(
        private device: DeviceInfo,
        private log: Logger
    ) {}

    /**
     * B/c pre Android API 33, notifications were assumed granted. The
     * package PushNotifications is assuming as much. And so documentations says to use
     * LocalNotifications for API 33 and above.
     */

    shouldRequest = async () => {
        // capacitor plugin doesn't support web
        if (!['ios', 'android'].includes(this.device.platform)) {
            return false;
        }
        // per store rules, we must not ask repeatedly
        const { display: currentStatus } = await LocalNotifications.checkPermissions();
        return !['granted', 'denied'].includes(currentStatus);
    };

    requestPermission = async () => {
        // note: ios will prompt, android will grant without prompting
        const { display: newStatus } = await LocalNotifications.requestPermissions();

        if (newStatus === 'granted') {
            this.log.info('Push notifications permission granted');

            // since user had not previously granted, register() will have failed at app start
            // (in PushNotificationListener), so we need to invoke register() again now
            PushNotifications.register();

        } else {
            this.log.info('Push notifications permission denied');
        }

        return newStatus;
    };

    checkPermissions = async () => {
        // PermissionState
        // 'prompt' | 'prompt-with-rationale' | 'granted' | 'denied'
        const { display: newStatus } = await LocalNotifications.checkPermissions();
        return newStatus;
    };
}
