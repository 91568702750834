import React, { useEffect, useState } from 'react';
import { IonButton, IonContent } from '@ionic/react';
import { App } from '@capacitor/app';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Modal } from '../components/Modal';
import { AsyncStorageProvider } from '../support/AsyncStorageProvider';
import { PushNotificationService } from './PushNotificationService';
import { CareMapState } from '../caremap/CareMapState';
import { MixPanelEventEmitter } from '../metrics/MixPanelEventEmitter';
import { UserSettingsState } from '../user/UserSettingsState';

const STORAGE_KEY = 'notifications-prompt-shown';

export const NotificationConsentDialog: React.FC = () => {

    const [isOpen, setOpen] = useState(false);

    const mixpanel = useInstance(MixPanelEventEmitter);
    const storageProvider = useInstance(AsyncStorageProvider);
    const promptShownAt = storageProvider.getStringProvider(STORAGE_KEY);

    const caremapState = useInstance(CareMapState);
    const pushNotificationService = useInstance(PushNotificationService);
    const userSettingsState = useInstance(UserSettingsState);

    const maybeOpen = async () => {
        // don't show if already shown
        if (await promptShownAt.get()) {
            return;
        }
        // don't show if permission already requested or not applicable
        if (!(await pushNotificationService.shouldRequest())) {
            return;
        }
        // wait for active caremap to be set (difficult to manage this
        // using state for both app open and app foregrounded)
        while (!caremapState.getActiveCaremap()) {
            await new Promise(res => setTimeout(res, 500));
        }
        // don't show if still in the wizard...
        if (!caremapState.getActiveCaremap()?.essentialMapComplete) {
            return;
        }
        // passed all the checks, so show it
        setOpen(true);
    };

    const close = ({ response }: { response?: 'Yes' | 'Maybe later' } = {}) => {
        setOpen(wasOpen => {
            if (wasOpen) {
                mixpanel.track('Notification Dialog Viewed', () => ({
                    // Response intentionally undefined if modal dismissed without clicking either button
                    Response: response
                }));

                promptShownAt.set(new Date().toISOString());
            }
            return false;
        });
    };

    // maybe open dialog when app is opened or foregrounded
    useEffect(() => {
        maybeOpen();
        App.addListener('appStateChange', ({ isActive: isForegrounding}) => {
            if (isForegrounding) {
                maybeOpen();
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const onAllowClicked = async () => {
        await pushNotificationService.requestPermission();
        close({ response: 'Yes' });
    };

    const onLaterClicked = () => {
        close({ response: 'Maybe later' });
        userSettingsState.disableNotifications();
        userSettingsState.save();
    };

    return (
        <Modal
            title=""
            data-id="notification-consent"
            desktopMinHeight={525}
            isOpen={isOpen}
            closeHandler={close}
        >
            <IonContent>
                <div style={{marginLeft: 30, marginRight: 30}}>

                    <h1 style={{fontSize: 24}}>
                        Stay Informed and Inspired!
                    </h1>

                    <p style={{marginTop: 40, marginBottom: 50, marginRight: 10}}>
                        Allow notifications to receive caregiving tips, wellness inspiration,
                        and suport when you need it most!
                    </p>

                    <div style={{maxWidth: 400, width: '100%', margin: 'auto'}}>
                        <img src="./assets/images/notifications/sample-notification.png" alt="Sample notification" />
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', gap: 10, position: 'fixed', bottom: 0, marginLeft: -25, marginBottom: 70, paddingLeft: 35, paddingRight: 35, width: '100%'}}>
                        <div style={{maxWidth: 400, width: '100%', margin: 'auto'}}>
                            <IonButton
                                expand="block"
                                fill="solid"
                                color="primary"
                                onClick={onAllowClicked}
                                data-id="allow"
                            >
                                Yes, turn on
                            </IonButton>

                            <IonButton
                                expand="block"
                                fill="clear"
                                color="primary"
                                onClick={onLaterClicked}
                                data-id="later"
                            >
                                Maybe later
                            </IonButton>
                        </div>
                    </div>
                </div>
            </IonContent>
        </Modal>
    );
};
