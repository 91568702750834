import { FC, useEffect } from 'react';
import { DeviceValidator, IDeviceValidation } from './DeviceValidator';
import { BasicHeader } from '../components/BasicHeader';
import { createUseStyles } from 'react-jss';
import { IonButton, IonPage } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { ITrackingContext, MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { Href } from '../components/Href';

const useStyles = createUseStyles({
    body: {
        height: '100%',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        background: 'var(--ion-color-primary)',
        // cover the bottom border of BasicHeader
        marginTop: -1,
        zIndex: 999
    },

    mobileBody: {
        padding: 0,
        display: 'block'
    },

    messageBox: {
        background: 'white',
        borderRadius: 8,
        padding: 50,
        paddingTop: 20,
        boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        position: 'relative',
        width: 760,
        height: 510,
        margin: 'auto'
    },

    mobileMessageBox: {
        width: '100%',
        height: '100%',
        borderRadius: 0,
        boxShadow: 'none',
        padding: 30,
        paddingTop: 0
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        marginBottom: 30
    },

    message: {
        marginBottom: 30,
        textAlign: 'center'
    },

    image: {
        height: '70%',

        '& > img': {
            margin: 'auto',
            display: 'block',
            height: '100%'
        }
    }
});

const getInfoForMixpanel = (result: UAParser.IResult | undefined): ITrackingContext => {
    return {
        'Unsupported Device Info': `${result?.device.vendor}, ${result?.device.model}, ${result?.device.type || '[No Type], '},
            w: ${window.innerWidth} h: ${window.innerHeight}`,
        'UA String': result?.ua
    };
};

const UnsupportedBrowser: FC<{validSize?: boolean; result?: UAParser.IResult}> = ({ validSize = true, result }) => {
    const classes = useStyles();
    const tracking = useInstance(MixpanelService);

    useEffect(() => {
        tracking.trackWithDebounce({
            event: validSize ? 'Unsupported Browser' : 'Unsupported Browser Size',
            getContext: () => getInfoForMixpanel(result)
        });
    }, [tracking]);  // eslint-disable-line react-hooks/exhaustive-deps

    const title = validSize ? 'Oops! This browser is not supported' : 'Oops! This window size is too small';
    const message = validSize ? <>For the optimal experience, we recommend using the latest version of <Href url="https://www.google.com/chrome/dr/download/">Chrome</Href> to use Innerhive!</> : <>For the optimal experience, we recommend using a device with a larger screen or increasing the size of your browser’s window to use Innerhive!</>;

    return (
        <div className={classes.body}>
            <div className={classes.messageBox}>
                <div className={classes.title}><h2>{title}</h2></div>
                <div className={classes.message}>{message}</div>
                <div className={classes.image}>
                    <img src="./assets/images/unsupported/care-map.svg" alt="care map" />
                </div>
            </div>
        </div>
    );
};

const UnsupportedDevice: FC<{result?: UAParser.IResult}> = ({ result }) => {
    const classes = useStyles();
    const tracking = useInstance(MixpanelService);

    useEffect(() => {
        tracking.track('Unsupported Device', () => getInfoForMixpanel(result));
    }, [tracking]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${classes.body} ${classes.mobileBody}`}>
            <div className={`${classes.messageBox} ${classes.mobileMessageBox}`}>
                <div className={classes.title}><h2>Coming Soon!</h2></div>
                <div className={classes.message}>
                    We are hard at work on the mobile version of Innerhive.<br /><br />
                    In the meantime, we recommend using Innerhive on Chrome on a computer.
                </div>
                <div className={classes.image} style={{height: '30%'}}>
                    <img src="./assets/images/unsupported/mobile-coming-soon.png" alt="care map" />
                </div>
                <div style={{marginTop: 20, textAlign: 'center'}}>
                    Don't forget to sign up on our website so we can notify you as soon as the mobile version is ready!
                </div>
                <div style={{textAlign: 'center', marginTop: 20}}>
                    <IonButton color="primary" style={{width: 170}} href="https://www.innerhive.com/#subscribe">Sign Up</IonButton>
                </div>
            </div>
        </div>
    );
};

export const Unsupported: FC<{validation: IDeviceValidation}> = ({ validation }) => {
    const validator = useInstance(DeviceValidator);
    const result = validator.getResult();

    return (
        <IonPage>
            <BasicHeader />
            {!validation.validDevice ? <UnsupportedDevice result={result} /> :
                !validation.validBrowser ? <UnsupportedBrowser result={result} /> : null
            }
        </IonPage>
    );
};

export const UnsupportedV2: FC = () => {
    const validator = useInstance(DeviceValidator);
    return <Unsupported validation={validator.validate()} />;
};
