import React from 'react';
import InternalSelect from 'react-select/dist/declarations/src/Select';
import { useBreakpoints } from '../theme/useBreakpoints';
import { SearchableSelectFieldDesktop } from './SearchableSelectFieldDesktop';
import { SearchableSelectFieldMobile } from './SearchableSelectFieldMobile';

// export internal component type so consumer can use it to strongly type innerRef
export type { default as InternalSelect } from 'react-select/dist/declarations/src/Select';

export interface ISearchSelectOption<T = string> {
    label: string;
    value: T;
}

export interface ISelected<T = string> {
    label: string;
    value?: T;
}

export interface ISearchableSelectFieldProps<T> {
    disabled?: boolean;
    selected?: ISelected<T>;
    options: ISearchSelectOption<T>[];

    /**
     * we only emit undefined if the user selected Clear
     */
    onChange: (selected?: ISelected<T>) => void;

    onBlur?: () => void;
    placeholder?: string;
    label: string;
    labelPlacement?: 'floating' | 'stacked';
    autoLookupOnEmpty?: boolean;

    noSort?: boolean;
    allowCustom?: boolean;
    // TODO: currently desktop only
    noOptionsMessage?: string;

    // allow consumer to specify bottom margin to be applied only if field is rendered
    // (if autoLookupOnEmpty=true, field doesn't render on mobile until option is selected)
    marginBottom?: number;

    innerRef?: React.Ref<InternalSelect<ISelected<T>>>;
    'data-type'?: string;
}

export const SearchableSelectField = <T,>({
    'data-type': dataType,
    label,
    labelPlacement = 'stacked',
    placeholder,
    autoLookupOnEmpty,
    noSort = false,
    allowCustom = true,
    disabled = false,
    noOptionsMessage = 'Nothing found',
    marginBottom,
    selected,
    onChange,
    onBlur,
    options,
    innerRef
}: ISearchableSelectFieldProps<T>) => {
    const { isBelowBreakpoint } = useBreakpoints();
    if (isBelowBreakpoint('sm')) {
        return (
            <SearchableSelectFieldMobile
                disabled={disabled}
                data-type={dataType}
                label={label}
                labelPlacement={labelPlacement}
                placeholder={placeholder}
                autoLookupOnEmpty={autoLookupOnEmpty}
                noSort={noSort}
                allowCustom={allowCustom}
                marginBottom={marginBottom}
                selected={selected}
                onChange={onChange}
                options={options}
            />
        );
    } else {
        return (
            <SearchableSelectFieldDesktop
                disabled={disabled}
                data-type={dataType}
                label={label}
                labelPlacement={labelPlacement}
                placeholder={placeholder}
                noSort={noSort}
                allowCustom={allowCustom}
                noOptionsMessage={noOptionsMessage}
                marginBottom={marginBottom}
                selected={selected}
                onChange={onChange}
                onBlur={onBlur}
                options={options}
                innerRef={innerRef}
            />
        );
    }
};
