import { DevSettings } from '../support/DevSettings';

export type IEnvironment = 'live' | 'staging' | 'local';

export class EnvConfiguration{
    constructor(){
        throw new Error('should not use ctor, see AppContainer');
    }

    ENV: IEnvironment;
    WEB_BASE_URL: string;
    API_BASE_URL: string;
    MIXPANEL_TOKEN: string;
    INTERCOM_APP_ID: string;
    CLOUDWATCH_LOGS: {
        accessKeyId: string;
        secretAccessKey: string;
    };
    APP_STORE_URLS: {
        android: string;
        ios: string;
    };
    REVENUE_CAT_ANDROID_PUBLIC_KEY: string;
    REVENUE_CAT_IOS_PUBLIC_KEY: string;
}

export const getConfiguration = (env: IEnvironment) => {

    const config: {[env: string]: EnvConfiguration} = {
        live: {
            ENV: 'live',
            WEB_BASE_URL: 'https://app.innerhive.com',
            API_BASE_URL: 'https://api.innerhive.com/v1',
            MIXPANEL_TOKEN: '9fac8bd6e6bdf74fc546570fe1a1784e',
            INTERCOM_APP_ID: 'vortkqmn',
            CLOUDWATCH_LOGS: {
                // NOTE these are low priviledge access keys
                // which only have access to putLogEvents to the log group
                // specific to this app. They could be abused by writing logs
                // to another user, or unnecessary use of resources. The first risk
                // doesn't yet justify the complexity to fix it (go through our API).
                // the second risk is a constant (eg they could create unlimited dymnamo
                // caremaps)
                accessKeyId: 'AKIA2VIUDCJOVVPLLVWH',
                secretAccessKey: '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'
            },
            APP_STORE_URLS: {
                android: 'https://play.google.com/store/apps/details?id=com.innerhive.app.v2',
                ios: 'https://apps.apple.com/us/app/innerhive-caregiver-support/id6469646845'
            },
            REVENUE_CAT_ANDROID_PUBLIC_KEY: 'goog_qadhAAzxUsdvbDcwAUFDoYZVQBK',
            REVENUE_CAT_IOS_PUBLIC_KEY: 'appl_uoOAjbxbXcZnjjBcSsrAmAOqRct'
        },
        staging: {
            ENV: 'staging',
            WEB_BASE_URL: 'https://app.staging.innerhive.com',
            API_BASE_URL: 'https://api.staging.innerhive.com/v1',
            MIXPANEL_TOKEN: '9ff87ea1b911de784d51d836336a8b58',
            INTERCOM_APP_ID: 'dkd03r9q',
            CLOUDWATCH_LOGS: {
                accessKeyId: 'AKIAXQT5CFMDV6RNNFVV',
                secretAccessKey: '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'
            },
            APP_STORE_URLS: {
                android: 'https://play.google.com/store/apps/details?id=com.innerhive.app.v2',
                ios: 'https://apps.apple.com/us/app/innerhive-caregiver-support/id6469646845'
            },
            REVENUE_CAT_ANDROID_PUBLIC_KEY: 'goog_HPNckEARRhwqGLhotEUtpgjESCZ',
            REVENUE_CAT_IOS_PUBLIC_KEY: 'appl_ngCpRNvzvPdFZSgYmfuimvaRofz'
        },
        local: {
            ENV: 'local',
            WEB_BASE_URL: 'http://localhost:3000',
            API_BASE_URL: 'https://api.staging.innerhive.com/v1',
            MIXPANEL_TOKEN: '', // no token on purpose, this disables it
            INTERCOM_APP_ID: 'dkd03r9q',
            CLOUDWATCH_LOGS: {
                accessKeyId: 'AKIAXQT5CFMDV6RNNFVV',
                secretAccessKey: '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'
            },
            APP_STORE_URLS: {
                android: 'https://play.google.com/store/apps/details?id=com.innerhive.app.v2',
                ios: 'https://apps.apple.com/us/app/innerhive-caregiver-support/id6469646845'
            },
            REVENUE_CAT_ANDROID_PUBLIC_KEY: 'goog_HPNckEARRhwqGLhotEUtpgjESCZ',
            REVENUE_CAT_IOS_PUBLIC_KEY: 'appl_ngCpRNvzvPdFZSgYmfuimvaRofz'
        }
    };

    const envConfig = config[env];

    // copy env vars to config (note: vite only passes through vars with "VITE_" prefix)
    // important: vite uses static replacement, so these cannot be accessed dynamically,
    // e.g. import.meta.env[key] (see https://vitejs.dev/guide/env-and-mode.html)
    if (import.meta.env.VITE__API_BASE_URL) {
        envConfig.API_BASE_URL = import.meta.env.VITE__API_BASE_URL;
    }

    return envConfig;
};

export const getEnvironment = (devSettings: DevSettings): IEnvironment => {
    const { hostname, protocol, origin } = window.location;

    // detect app installed on device (NOT dev build running locally)
    const iOSInstall = (protocol === 'com.innerhive.app:');
    const androidInstall = (origin === 'http://localhost');
    if (iOSInstall || androidInstall) {
        return devSettings.useStaging ? 'staging' : 'live';
    }

    // if running a deployed web build from aws, the api env
    // is derived directly from the web host
    if (hostname === 'app.staging.innerhive.com') {
        return 'staging';
    }
    if (hostname === 'app.innerhive.com') {
        return 'live';
    }

    // everything else must be a dev build running locally
    // e.g. "localhost" or a private IP like "192.168.0.1"
    return 'local';
};
