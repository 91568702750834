import { FC, ReactElement, useState, useEffect } from 'react';
import { IonButton } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { Step, IStepValidProps } from './Step';

const useStyles = createUseStyles({
    buttons: {
        display: 'flex',
        alignItems: 'center',

        '& > ion-button': {
            width: 120,
            marginLeft: 20
        }
    }
});

export type ISupportGroupStepProps = IStepValidProps & {
    question: string | ReactElement;
    currentAnswer: boolean | undefined;
    questionAnswered: (answer: boolean) => void;
    stepId: string;
    subTitle?: string;
    labels?: {
        yes: string;
        no: string;
    };
}

type IYesOrNo = 'yes' | 'no' | undefined;

export const YesOrNoStep: FC<ISupportGroupStepProps> = ({ question, setStepValidity, currentAnswer, questionAnswered, stepId, subTitle, labels }) => {
    const classes = useStyles();
    const [yesOrNo, setYesOrNo] = useState<IYesOrNo>(currentAnswer ? 'yes' : currentAnswer === false ? 'no' : undefined);

    useEffect(() => {
        setStepValidity(yesOrNo !== undefined);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getFill = (answer: IYesOrNo) => {
        return yesOrNo === answer ? 'solid' : 'outline';
    };

    const buttonClicked = (answer: IYesOrNo) => {
        // if no change, do nothing (don't want to allow adding multiple)
        if (answer === yesOrNo) {
            return;
        }

        setYesOrNo(answer);
        setStepValidity(true);
        questionAnswered(answer === 'yes');
    };

    return (
        <Step question={question} data-id={stepId} subTitle={subTitle}>
            <div className={classes.buttons}>
                <IonButton data-id="yes" fill={getFill('yes')} onClick={() => buttonClicked('yes')}>{labels?.yes || 'Yes'}</IonButton>
                <IonButton data-id="no" fill={getFill('no')} onClick={() => buttonClicked('no')}>{labels?.no || 'No'}</IonButton>
            </div>
        </Step>
    );
};
