import { ReactFlowInstance } from 'reactflow';

export const zoomDuration = 300;
const getHexagons = () => document.querySelectorAll('[data-type="hexagon"]');

export const getCareMapBounds = () => {
    let left: number | undefined;
    let right: number | undefined;
    let top: number | undefined;
    let bottom: number | undefined;

    const hexagons = getHexagons();
    const patient = document.querySelector('.react-flow__node-patient');
    const familyNodes = document.querySelectorAll('.react-flow__node-familyNode');

    const items: Element[] = [];
    if (patient) {
        items.push(patient);
    }
    hexagons.forEach(h => items.push(h));
    familyNodes.forEach(n => items.push(n));

    if (items.length === 0) {
        return undefined;
    }

    for (const item of items) {
        const rect = item.getBoundingClientRect();
        if (left === undefined || rect.left < left) {
            left = rect.left;
        }
        if (right === undefined || rect.right > right) {
            right = rect.right;
        }
        if (top === undefined || rect.top < top) {
            top = rect.top;
        }
        if (bottom === undefined || rect.bottom > bottom) {
            bottom = rect.bottom;
        }
    }

    const bounds = {
        top: top!,
        bottom: bottom!,
        left: left!,
        right: right!,
        width: right! - left!,
        height: bottom! - top!,
        centerX: (right! + left!) / 2,
        centerY: (top! + bottom!) / 2
    };

    return bounds;
};

export const deriveMinZoom = () => {
    const minZoomW = window.innerWidth/2000;
    const minZoomH = window.innerHeight/1800;
    return Math.min(minZoomW, minZoomH);
};

export const fitCareMapBounds = (rf: ReactFlowInstance, options: {fit?: 'default' | 'custom' | 'none'} = {}) => {
    if (options.fit === 'custom') {
        rf.fitView({ duration: zoomDuration, minZoom: 0.4 });
    } else if (options.fit === 'default') {
        rf.fitView({ duration: zoomDuration });
    } else {
        // do nothing if fit is 'none'
    }
};

export const ensureVisible = (rf: ReactFlowInstance) => {
    const bounds = getCareMapBounds();

    if (bounds) {
        const vp = rf.getViewport();

        const zoomFactor = (vp.zoom > 1 ? vp.zoom : 1);
        const marginX = window.innerWidth / 3 * zoomFactor;
        const marginY = (window.innerHeight / 4 + 50) * zoomFactor;

        const oobTop = Math.max(0, marginY - bounds.bottom);
        const oobBottom = Math.max(0, bounds.top - (window.innerHeight - marginY));
        const oobLeft = Math.max(0, marginX - bounds.right);
        const oobRight = Math.max(0, bounds.left - (window.innerWidth - marginX));

        if (oobTop || oobBottom || oobLeft || oobRight) {
            rf.setViewport({
                x: vp.x + oobLeft - oobRight,
                y: vp.y + oobTop - oobBottom,
                zoom: vp.zoom
            });
        }
    }
};
