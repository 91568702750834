import { FC, useEffect, useState } from 'react';
import {  IonDatetime, IonDatetimeButton, IonPopover } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { TextField } from '../components/TextField';
import { TextArea } from '../components/TextArea';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AppointmentsState, DEFAULT_APPOINTMENT_SECTION, NEW_APPOINTMENT_ID } from './AppointmentsState';
import { useSubscription } from '@meraki-internal/state';
import { IAppointment } from './IAppointment';
import dayjs from 'dayjs';
import { ClockOutlineIcon } from '../theme/Icons';
import { CareMapState } from '../caremap/CareMapState';
import { ISelected, SearchableSelectField } from '../components/SearchableSelectField';
import { ICareMapSection, ISupportGroupKey, ISupportGroupMember } from '../caremap/ICareMap';

type IStyleKeys = 'form' | 'dateTimeWrapper' | 'dateTimeIconButtonWrapper' | 'dateTimeBtn';

interface IStyleProps {
    readOnly: boolean;
};

const useStyles = createUseStyles<IStyleKeys, IStyleProps>({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: ({ readOnly }) => readOnly ? 30 : 20
    },

    dateTimeWrapper: {
        gap: 20,
        display: 'flex',
        flexDirection: 'column'
    },

    dateTimeIconButtonWrapper: {
        gap: 20,
        paddingLeft: 8,
        paddingRight: 20,
        display: 'flex',
        alignItems: 'center',

        '& ion-datetime-button': {
            '&::part(native)': {
                opacity: 1
            }
        }
    },

    dateTimeBtn: {
        flex: 1,
        justifyContent: 'space-between',

        '&::part(native)': {
            background: ({ readOnly }) => readOnly ? 'transparent' : 'var(--ion-color-step-100)'
        }
    }
});

export type IAppointmentEditorProps = {
    appointmentId: string;
    supportMemberId?: string;
    supportGroupKey?: ISupportGroupKey;
    readOnly?: boolean;
    disableRole?: boolean;
    reportValidity?: (valid: boolean) => void;
};

export const AppointmentEditor: FC<IAppointmentEditorProps> = ({
    appointmentId,
    supportMemberId,
    supportGroupKey,
    readOnly = false,
    disableRole = false,
    reportValidity = (valid) => {}
}) => {
    const classes = useStyles({ readOnly });

    const appointmentsState = useInstance(AppointmentsState);
    useSubscription(() => appointmentsState);

    const careMapState = useInstance(CareMapState);
    useSubscription(() => careMapState);

    const [selectedMember, setSelectedMember] = useState<ISelected | undefined>();

    const checkValidity = () => {
        reportValidity(appointmentsState.isCurrentAppointmentValid());
    };

    const getMemberLabel = (member: ISupportGroupMember, showNameFirst: boolean) => {
        const name = member.name || '';
        const role = member.role || '';
        return (
            showNameFirst ?
                name + (name ? ` | ${role}` : role) :
                role + (role && name ? ` | ${name}` : name)
        ) || '';
    };

    const getAutofillTitle = (member: ISupportGroupMember | undefined, showNameFirst: boolean) => {
        const name = member?.name || '';
        const role = member?.role || '';
        const organization = member?.organization || '';

        return member ? (
            (showNameFirst ?
                name + (name ? ` | ${role}` : role) :
                role + (role && name ? ` | ${name}` : name)) +
            (organization ? ` | ${organization}` : '')
        ) : supportGroupKey ? careMapState.getSupportGroupLabels(supportGroupKey).title : '';
    };

    const getAutofillLocation = (member: ISupportGroupMember | undefined) => {
        const address = member?.address || '';
        const organization = member?.organization || '';
        return member ? `${organization}${address ? (organization ? ' - ' : '') + address : address}` : '';
    };

    // initial load
    useEffect(() => {
        if (appointmentId === NEW_APPOINTMENT_ID) {
            appointmentsState.addAppointment();

            const {member, showNameFirst} = careMapState.getSupportGroupMemberInformation(
                appointmentsState.state.currentAppointment?.supportMemberId || supportMemberId
            );

            appointmentsState.updateCurrentAppointment({
                supportMemberId: member?.id,
                title: getAutofillTitle(member, showNameFirst),
                location: getAutofillLocation(member),
                section: (supportGroupKey as ICareMapSection) || DEFAULT_APPOINTMENT_SECTION
            });

            checkValidity();
        } else {
            appointmentsState.setCurrentAppointment(appointmentId);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // set selected support member on edit / appointmentId changes
    useEffect(() => {
        const {member, showNameFirst} = careMapState.getSupportGroupMemberInformation(
            appointmentsState.state.currentAppointment?.supportMemberId || supportMemberId
        );
        setSelectedMember(member ? {
            value: member.id,
            label: getMemberLabel(member, showNameFirst)
        } : undefined);
    }, [appointmentId, appointmentsState.state.currentAppointment?.supportMemberId, careMapState, supportMemberId]);

    const appointment = appointmentsState.state.currentAppointment;

    if (!appointment) {
        return null;
    }

    const appointmentChanged = (changes: Partial<IAppointment>) => {
        // if start date changes and end date is earlier than start date, update end date to be an hour later
        if (changes.start) {
            const start = dayjs(changes.start);
            const end = dayjs(appointment.end);
            if (start.isAfter(end)) {
                changes = {...changes, ...{end: start.add(1, 'hour').toISOString()}};
            }
        }

        appointmentsState.updateCurrentAppointment(changes);
        checkValidity();
    };

    const getSupportGroupMembers = () => {
        // TODO: typed better, [{label: string, value: string}] or ISearchSelectOption[]?
        let list: any[] = [];
        let showNameFirst: boolean;

        if (supportGroupKey) {
            const supportGroupMembers = careMapState.getSupportGroupMembers(supportGroupKey);
            showNameFirst = careMapState.shouldShowNameFirst(supportGroupKey);

            list = list.concat(supportGroupMembers?.map(member => {
                return {
                    value: member.id,
                    label: getMemberLabel(member, showNameFirst)
                };
            }));
        } else {
            const supportGroupMembersMap = careMapState.getAllSupportGroupsMembersAsMap();

            Object.keys(supportGroupMembersMap).forEach(key => {
                const members = supportGroupMembersMap[key as ISupportGroupKey];
                showNameFirst = careMapState.shouldShowNameFirst(key as ISupportGroupKey);

                list = list.concat(members?.map(member => {
                    return {
                        value: member.id,
                        label: getMemberLabel(member, showNameFirst)
                    };
                }));
            });
        }

        return list;
    };

    const onMemberSelected = (newSelected?: ISelected) => {
        setSelectedMember(newSelected?.label ? {
            value: newSelected?.value,
            label: newSelected?.label
        } : undefined);

        const {member, categoryId, showNameFirst} = careMapState.getSupportGroupMemberInformation(newSelected?.value);
        appointmentsState.updateCurrentAppointment({
            supportMemberId: member?.id,
            section: categoryId || supportGroupKey || DEFAULT_APPOINTMENT_SECTION,
            title: getAutofillTitle(member, showNameFirst),
            location: getAutofillLocation(member),
        });

        checkValidity();
    };

    return (
        <div className={classes.form}>
            {readOnly ? (
                <TextField
                    name=""
                    readOnly={readOnly}
                    value={selectedMember?.label}
                    label="Who is this appointment with?"
                />
            ) : (
                <SearchableSelectField
                    allowCustom={false}
                    disabled={disableRole}
                    data-type="support-members-field"
                    options={getSupportGroupMembers()}
                    onChange={onMemberSelected}
                    selected={selectedMember}
                    noOptionsMessage="No supports found"
                    placeholder="Search or select support"
                    label="Who is this appointment with?"

                    // TODO: ??
                    //autoLookupOnEmpty={boolean}
                />
            )}

            <div style={{marginTop: readOnly ? -30 : undefined}}>
                <TextArea
                    autoGrow
                    rows={1}
                    name="title"
                    moreObviousFont
                    readOnly={readOnly}
                    value={appointment.title}
                    data-id="appointment-title"
                    label={readOnly ? '' : 'Title'}
                    onInput={(value) => appointmentChanged({title: value})}
                />
            </div>

            <div className={classes.dateTimeWrapper}>
                <div className={classes.dateTimeIconButtonWrapper}>
                    <ClockOutlineIcon color="medium" />
                    <IonDatetimeButton
                        disabled={readOnly}
                        datetime="start-date"
                        className={classes.dateTimeBtn}
                    />
                </div>

                <IonPopover keepContentsMounted={true}>
                    <IonDatetime id="start-date"
                        value={dayjs(appointment.start).format()}
                        onIonChange={(ev) => {
                            // ev.detail.value | ev.detail.value[0]
                            const value = ev.detail.value as string;
                            appointmentChanged({start: dayjs(value).toISOString()});
                        }}
                    />
                </IonPopover>

                <div className={classes.dateTimeIconButtonWrapper}>
                    <div style={{width: 18}}>&nbsp;</div>
                    <IonDatetimeButton
                        disabled={readOnly}
                        datetime="end-date"
                        className={classes.dateTimeBtn}
                    />
                </div>

                <IonPopover keepContentsMounted={true}>
                    <IonDatetime id="end-date"
                        min={dayjs(appointment.start).format()}
                        value={dayjs(appointment.end).format()}
                        onIonChange={(ev) => {
                            // ev.detail.value | ev.detail.value[0]
                            const value = ev.detail.value as string;
                            appointmentChanged({end: dayjs(value).toISOString()});
                        }}
                    />
                </IonPopover>
            </div>

            <TextField
                name="location"
                label="Location"
                readOnly={readOnly}
                value={appointment.location}
                onInput={(value) => appointmentChanged({location: value})}
            />

            <TextArea
                autoGrow
                rows={3}
                data-id="appointment-description"
                name="description"
                label="Description"
                readOnly={readOnly}
                value={appointment.description}
                onInput={(value) => appointmentChanged({description: value})}
            />
        </div>
    );
};
