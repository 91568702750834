import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    currentEntity: {
        fontSize: 18,
        fontWeight: 700,
        paddingRight: 20,
        paddingLeft: 30,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end'
    }
});
export type INavigationCurrentEntityProps = {
    children: ReactNode;
};

export const NavigationCurrentEntity: FC<INavigationCurrentEntityProps> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.currentEntity}>
            {children}
        </div>
    );
};
