import { DependencyContainerContext } from '@meraki-internal/react-dependency-injection';
import { getErrorSafeContainer } from './AppContainer';
import { BasicHeader } from './components/BasicHeader';
import { UnsupportedV2 } from './support/Unsupported';
import { IonButton } from '@ionic/react';

type IErrorComponent = React.FC<{ error: any }>;

const PageWrapper: React.FC<{children: React.ReactNode}> = ({ children }) => {
    return (
        <>
            <BasicHeader title="Innerhive Error">
                <div style={{paddingRight: 8, width: 110}} slot="end">&nbsp;</div>
            </BasicHeader>
            <div style={{margin: 50, textAlign: 'center'}}>
                {children}
            </div>
        </>
    );
};

const errorCodePages: { [errorCode: string]: IErrorComponent } = {
    'login-aborted': () => {
        return (
            <PageWrapper>
                <h2 style={{marginTop: 150}}>Login Canceled</h2>
                <IonButton onClick={() => window.location.reload()}>Start Over</IonButton>
            </PageWrapper>
        );
    },
    'viewer.jwt.expired': () => {
        return (
            <PageWrapper>
                <h2 style={{marginTop: 150}}>
                    We're sorry, the link to view this care map has expired.
                </h2>
                <p style={{fontSize: 22}}>
                    If you still need access to this care map, please ask the owner to generate a new link.
                </p>
            </PageWrapper>
        );
    },
    'device-not-allowed': () => {
        return ( <UnsupportedV2 />);
    },
    default: ({ error }) => {
        let errorInfo = error.stack || error.toString();
        if (error.message && !errorInfo.includes(error.message)) {
            errorInfo = error.message + '\n' + errorInfo;
        }

        return (
            <PageWrapper>
                <h2>We're sorry, something seems to have gone wrong.</h2>
                <p>
                    We've been notified about this problem, and are looking into it.
                    <br />
                    Please try closing and reopening the app.
                </p>
                <div id="error-info" style={{display: 'none'}}>{errorInfo}</div>
            </PageWrapper>
        );
    }
};

class ErrorContainer extends DependencyContainerContext {
    containerMounted() {};
}

export const ErrorPage: React.FC<{ error: any }> = ({ error }) => {
    const ErrorComponent = errorCodePages[error?.errorCode] || errorCodePages.default;
    return (
        <ErrorContainer container={getErrorSafeContainer()}>
            <ErrorComponent error={error} />
        </ErrorContainer>
    );
};
