import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useAsyncEffect } from '../support/useAsyncEffect';
import { AsyncStorageProvider } from '../support/AsyncStorageProvider';

// public/assets/videos/caregiver expected to contain mp4 and jpg files for each video;
// the jpg is a low resolution copy of the first frame of the video, extracted with
// ffmpeg -i filename.mp4 -vf "select=eq(n\,0)" -vf scale=640:-2 -q:v 10 filename.jpg
const videoFiles = [
    'fall-stream.mp4',
    'white-flowers.mp4',
    'blue-river-green-moss.mp4',
    'grass.mp4',
    'river-rocks.mp4',
    'orange-flowers.mp4',
    'water-ripples.mp4',
    'snowy-branch.mp4',
    'mossy-stream.mp4',
    'yellow-flowers.mp4',
    'fog-over-mountain.mp4',
    'sunlight-leaves.mp4',
    'waterfall.mp4'
];

const useStyles = createUseStyles({
    wrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden'
    },
    video: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    }
});

export const CaregiverVideoBackground: React.FC = () => {
    const classes = useStyles();

    const storage = useInstance(AsyncStorageProvider);
    const storageProvider = storage.getJSONProvider<number>('caregiver-video-index');
    const [videoIndex, setVideoIndex] = useState<number>();

    // show the next video each time the page is loaded
    useAsyncEffect(async () => {
        const index = (await storageProvider.get()) || 0;
        setVideoIndex(index);
        const nextIndex = (index >= videoFiles.length - 1 ? 0 : index + 1);
        await storageProvider.set(nextIndex);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (videoIndex === undefined) {
        return null;
    }

    const videoUrl = `./assets/videos/caregiver/${videoFiles[videoIndex]}`;
    const posterUrl = videoUrl.replace('.mp4', '.jpg');

    return (
        <div className={classes.wrapper}>
            <video
                className={classes.video}
                src={videoUrl}
                muted
                playsInline
                autoPlay={true}
                loop={true}
                poster={posterUrl}
            />
        </div>
    );
};
