import { IonButton, IonItem, IonLabel, IonText } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { DrawerSectionHeading } from '../components/DrawerSectionHeading';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { PrivacyBadge } from '../components/PrivacyBadge';
import { CareMapState } from '../caremap/CareMapState';
import { INote } from './INote';
import dayjs from 'dayjs';
import { AttachmentList } from './attachments/AttachmentList';
import { AddNoteButton } from './AddNoteButton';

const useStyles = createUseStyles({
    content: {
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        // for non-webkit browsers (Firefox)
        maxHeight: '3.8rem',
        // .sc-ion-label-md-s p sets a smaller font-size than we want
        fontSize: 'inherit !important'
    },

    titleWrapper: {
        fontWeight: '500 !important',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        // .sc-ion-label-md-s h3 sets a smaller font-size than we want
        fontSize: 'inherit !important'
    },

    title: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },

    addLink: {
        cursor: 'pointer',
        color: 'var(--ion-color-primary)'
    }
});

type INotesProps = {
    notes?: INote[];
    moreNotesHandler?: () => void;
    editNoteHandler: (noteId?: string) => void;
    showCount?: number;
    showHeading?: boolean;
    'data-type'?: string;
};
export const NotesList: React.FC<INotesProps> = ({
    notes,
    moreNotesHandler,
    editNoteHandler,
    showCount,
    showHeading = true,
    'data-type': dataType = 'notes-list'
}) => {
    const classes = useStyles();
    const careMapState = useInstance(CareMapState);

    // intentionanlly not subscribing b/c this cannot change
    const canEdit = careMapState.getActiveCaremap()!.links.self.actions.includes('edit-notes');

    const formatDate = (date: string) => {
        const dayjsDate = dayjs(date);
        const format = dayjsDate.isToday() ? 'h:mma' : 'MM/DD/YY';
        return dayjsDate.format(format);
    };

    notes = (notes || []).sort((n1, n2) => dayjs(n1.updatedAt).isAfter(n2.updatedAt) ? -1 : 1);

    const visibleNotes: INote[] = notes.slice(0, showCount);

    const isAnytingToSee = canEdit || visibleNotes.length > 0;
    if(!isAnytingToSee){
        return null;
    }

    return (
        <div style={{ width: '100%' }} data-type={dataType}>
            {showHeading && (
                <DrawerSectionHeading text="Notes">
                    <AddNoteButton
                        canAdd={canEdit}
                        onClick={editNoteHandler}
                    />
                </DrawerSectionHeading>
            )}

            {visibleNotes.length === 0 && canEdit && (
                <IonText color="medium" style={{paddingLeft: 12}}>
                    No notes yet, <span className={classes.addLink} onClick={() => editNoteHandler('add-note')}>add one</span>
                </IonText>
            )}

            {(canEdit || visibleNotes.length > 0) && visibleNotes.map(note => (
                <IonItem key={note.noteId}
                    button
                    detail
                    onClick={() => editNoteHandler(note.noteId)}>
                    <IonLabel class="ion-text-wrap">
                        <h3 className={classes.titleWrapper}>
                            <div className={classes.title}>
                                <span style={{color: 'var(--ion-color-step-600, #666666)'}}>
                                    {formatDate(note.updatedAt!)}
                                </span>&nbsp;<span data-type="title">{note.title || 'Untitled'}</span>
                            </div>
                            <div>
                                {note.isPrivate ? <PrivacyBadge /> : null}
                            </div>
                        </h3>
                        <p className={classes.content} data-type="content">{note.html}</p>
                        <AttachmentList
                            note={note}
                            attachments={note.attachments}
                        />
                    </IonLabel>
                </IonItem>
            ))}

            {(showCount && showCount < notes.length && moreNotesHandler) && (
                <div style={{display: 'flex', justifyContent: 'space-around', marginBottom: -24}}>
                    <IonButton fill="clear" onClick={moreNotesHandler} data-id="view-more-notes-button">View More Notes</IonButton>
                </div>
            )}
        </div>
    );
};
