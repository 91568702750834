import { APIClient } from '@innerhive-internal/innerhive-api-client';
import { CloudWatchLogsClient, PutLogEventsCommand } from '@aws-sdk/client-cloudwatch-logs';
import { ILogger } from './ILogger';
import { EnvConfiguration } from '../config/EnvConfiguration';

const MAX_BUFFERED_EVENT_COUNT = 100;

export class CloudwatchLogger implements ILogger {
    private cloudwatch?: CloudWatchLogsClient;
    private logGroupName?: string;
    private logStreamName?: string;

    getStream = () => this.logStreamName || '';

    init = async ({ api, config }: {api: APIClient; config: EnvConfiguration }) => {
        const entry = await api.entry();
        const { stream } = await api.post(entry.links.createLogs, {});
        this.logGroupName = 'innerhive-app';
        this.logStreamName = stream;

        const { accessKeyId, secretAccessKey } = config.CLOUDWATCH_LOGS;
        this.cloudwatch = new CloudWatchLogsClient({
            region: 'us-east-1',
            credentials: { accessKeyId, secretAccessKey }
        });

        this.sendLogEventsBatch();
    };
    error = (error: any, extras?: any) => {
        this.sendLogEvent({
            message: `ERROR ${error} ${extras ? JSON.stringify(extras) : ''}`,
            at: new Date()
        });
    };
    info = (message: string, extras?: any) => {
        this.sendLogEvent({
            message: `INFO ${message} ${extras ? JSON.stringify(extras) : ''}`,
            at: new Date()
        });
    };

    private batchedEvents: { message: string; at: Date; }[] = [];
    private batchTimeoutId?: NodeJS.Timeout;

    sendLogEvent = async ({ message, at }: { message: string; at: Date; }) => {
        this.batchedEvents.push({ message, at });

        this.sendBatchLater();
    };

    private sendBatchLater = () => {
        if (!this.batchTimeoutId){
            this.batchTimeoutId = setTimeout(() => {
                this.batchTimeoutId = undefined;

                this.sendLogEventsBatch();
            }, 3000) ;
        }

        // otherwise its already queued up
    };

    private sendLogEventsBatch = async () => {
        // if there is nothing to send, then done
        if (this.batchedEvents.length === 0){
            return;
        }

        // if we can't send yet, then wait again
        if (!this.logGroupName || !this.logStreamName || !this.cloudwatch) {
            // but lets avoid memory leak if we're never going to send
            this.batchedEvents = this.batchedEvents.slice(-MAX_BUFFERED_EVENT_COUNT);

            this.sendBatchLater();
            return;
        }

        // clear the array sync, so we don't send the batch more than once on accident
        const batch = this.batchedEvents;
        this.batchedEvents = [];

        // we shouldn't come close to AWS limits so not proactively guarding for anything
        // 1500 req / second
        // 1 MB for entire payload
        // not to exceed 10 k log events (count) per this.cloudwatch.send
        // 256 KB per event
        try {
            await this.cloudwatch.send(new PutLogEventsCommand({
                logGroupName: this.logGroupName,
                logStreamName: this.logStreamName,
                logEvents: batch.map(({ message, at }) => ({
                    message,
                    timestamp: at.valueOf()
                }))
            }));
        }
        catch (err: any){
            await this.cloudwatch.send(new PutLogEventsCommand({
                logGroupName: this.logGroupName!,
                logStreamName: this.logStreamName!,
                logEvents: [{
                    message:`got error ${err} sending the logs`,
                    timestamp: new Date().valueOf()
                }]
            })).catch((err2: any) => console.log(`got error ${err} sending the logs and got ${err2} sending the error to cloudwatch`));
        }

    };

    /**
     * NOTE: this doesn't handle the case where
     *  - this.batchTimeoutId triggered, and cleared itself
     *  - so this.batchTimeoutId is undefined
     *  - it is in the process of sending the events to cloudwatch
     *  - but hasn't finished
     *  - this function will return earlier than it should
     * BUT I don't have time to address that right now (edge case / higher priorities)
     */
    flush = async () => {
        if (!this.batchTimeoutId){
            return;
        }
        clearTimeout(this.batchTimeoutId);
        await this.sendLogEventsBatch();
    };
}
