import { Card } from '../components/Card';
import { INote } from '../notes/INote';
import { NoteEdit } from '../notes/NoteEdit';
import { CardHeading } from './CardHeading';
import { ICheckin } from './ICheckin';

export interface ICheckinNoteCardProps {
    checkin: ICheckin;
    heading?: string;
    onChange: (note: INote) => void;
}

export const CheckinNoteCard: React.FC<ICheckinNoteCardProps> = ({ checkin, heading, onChange }) => {
    return (
        <Card padded>
            <CardHeading>{heading || 'Note'}</CardHeading>
            {checkin &&
                <div data-type="checkin-note-editor" style={{display: 'flex', flexDirection: 'column', height: 300, marginTop: -15, marginBottom: -20}}>
                    <NoteEdit
                        note={{
                            noteId: checkin.noteId,
                            title: '',
                            html: checkin.note || '',
                            attachments: checkin.attachments || []
                        }}
                        canEdit
                        noteTarget={{section: 'checkin'}}
                        noteChanged={onChange}
                    />
                </div>
            }
        </Card>
    );
};
