import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useIonAlert } from '@ionic/react';
import { ISupportGroupKey } from '../caremap/ICareMap';
import { useSubscription } from '@meraki-internal/state';
import { CareMapState } from '../caremap/CareMapState';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';

export const useDeleteMember = ({ supportGroupKey, memberId, onDelete }: { supportGroupKey: ISupportGroupKey, memberId: string, onDelete?: () => void }) => {
    const tracking = useInstance(MixpanelService);

    const [presentAlert] = useIonAlert();

    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);

    const labels = careMapStateInstance.getSupportGroupLabels(supportGroupKey);

    // ionic role is not strongly typed
    const destructiveRole = 'destructive';

    return () => {
        presentAlert({
            header: `Delete ${labels.memberTitle}?`,
            message: `This ${labels.memberTitle} will be removed from the care map and cannot be recovered.`,
            cssClass: 'drawer-alert',
            onDidDismiss: (e) => {
                if (e.detail.role === destructiveRole && onDelete) {
                    onDelete();
                }
            },
            buttons: [{
                text: 'Delete',
                role: destructiveRole,
                handler: () => {
                    careMapStateInstance.removeSupportGroupMember({ supportGroupKey, memberId });
                    tracking.track('Care Member Removed');
                }
            }, {
                text: 'Cancel',
                role: 'cancel'
            }]
        });

    };
};
