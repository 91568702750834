import { PredefinedColors } from '@ionic/core';
import { IonSpinner, SpinnerTypes } from '@ionic/react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    spinnerWrapper: {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        position: 'fixed',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-around'
    }
});

export interface SpinnerProps {
    size?: 'small' | 'medium' | 'large';
    centered?: boolean;
    color?: PredefinedColors;
    name?: SpinnerTypes
}

export const Spinner: React.FC<SpinnerProps> = ({ size = 'medium', centered = false, color = 'primary', name = 'dots'  }) => {
    const classes = useStyles();
    const scale = size === 'large' ? 3 : size === 'small' ? 1 : 2;

    return (
        <div className={centered ? classes.spinnerWrapper : ''}>
            <IonSpinner name={name} color={color} style={{transform: `scale(${scale})`}}/>
        </div>
    );
};
