import { memo } from 'react';
import { NodeProps } from 'reactflow';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    // needs a size for some (flow) reason
    group: {
        width: 1,
        height: 1
    }
});

export const HouseholdNodeGroup = memo(({ id, data }: NodeProps) => {
    const classes = useStyles();

    return (
        <>
            {/*
                https://reactflow.dev/docs/guides/custom-nodes/
                NOTE that if you are programmatically changing the position or number of handles in your custom node, you will need to use the useUpdateNodeInternals hook to properly notify ReactFlow of changes.
            */}
            <div className={classes.group} style={{ /*minWidth, minHeight, left, top*/}}>
                <div></div>
            </div>
        </>
    );
});
