import { FC, useEffect, useRef } from 'react';
import { Step, IStepValidProps } from './Step';
import { ISelected, InternalSelect, SearchableSelectField } from '../components/SearchableSelectField';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { isPlatform } from '@ionic/react';
import { SupportGroupDataProvider } from '../caremap/SupportGroupDataProvider';

type IIntendedRecipientProps = IStepValidProps & {
    stepId: string;
};

export class IntendedRecipientStepConfig {
    label = 'Care Recipient';
}

export const IntendedRecipientStep: FC<IIntendedRecipientProps> = ({ stepId, setStepValidity }) => {
    const caremap = useInstance(CareMapState);
    const dataProvider = useInstance(SupportGroupDataProvider);

    useSubscription(() => caremap);
    const fieldRef = useRef<InternalSelect<ISelected>>(null);

    useEffect(() => {
        setStepValidity(caremap.getActiveCaremap()?.initialWizardAnswers.recipient ? true : false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const initialWizardAnswers = caremap.getActiveCaremap()?.initialWizardAnswers;

    useEffect(() => {
        if (isPlatform('desktop')) {
            const timeout = setTimeout(() => {
                if (!initialWizardAnswers?.recipient) {
                    fieldRef.current?.focus();
                }
            }, 350); // need to wait 350ms for step animation to complete
            return () => clearTimeout(timeout);
        }
    }, [initialWizardAnswers]);

    const onRecipientSelected = (recipient?: ISelected) => {
        caremap.answerInitialWizardQuestion({
            questionId: stepId,
            answer: recipient ? {
                value: recipient?.value || '',
                label: recipient?.label || ''
            } : recipient
        });
        setStepValidity(recipient?.label ? true : false);
    };

    return (
        <Step question={`What is your relationship to ${caremap.getPatientFirstName()}?`}>
            <SearchableSelectField
                data-type="recipient-dropdown"
                innerRef={fieldRef}
                options={dataProvider.getRelationshipSelectOptions()}
                onChange={onRecipientSelected}
                placeholder="Search or select relationship"
                label={caremap.getLabel('CARE_RECIPIENT_RELATIONSHIP_TO_CARE_MAP_CREATOR')}
                selected={caremap.getActiveCaremap()?.initialWizardAnswers.recipient}
            />
        </Step>
    );
};
