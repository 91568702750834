import { FC } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { State, useSubscription } from '@meraki-internal/state';
import { UserState } from './user/UserState';
import { IonButton } from '@ionic/react';
import { DrawerSectionHeading } from './components/DrawerSectionHeading';
import { AppHeaderOverride } from './AppHeader';
import { MixPanelEventEmitter as MixpanelService } from './metrics/MixPanelEventEmitter';
import { createUseStyles } from 'react-jss';
import { mediaMaxWidth } from './theme/utils';

const useStyles = createUseStyles({
    welcomePage: {
        fontSize: 18,
        zIndex: 999,
        width: '100%',
        height: '100%',
        backgroundColor: 'var(--ion-color-primary)'
    },

    card: {
        position: 'relative',
        padding: '30px 100px 20px',
        backgroundColor: 'white',
        width: 760,
        height: 510,
        borderRadius: 8,
        margin: 'auto',
        top: '50%',
        transform: 'translateY(-300px)'
    },

    imgFaq: {
        display: 'flex',
        alignItems: 'start',
        gap: 40
    },

    buttonContainer: {
        marginTop: 40,
        textAlign: 'center'
    },

    // up to lg breakpoint
    [mediaMaxWidth.lg]: {
        card: {
            width: 700,
            height: 580
        }
    },

    // up to md breakpoint
    [mediaMaxWidth.md]: {
        card: {
            width: '100%',
            height: '100%',
            top: 'unset',
            transform: 'unset',
            borderRadius: 0
        }
    },

    // up to sm breakpoint
    [mediaMaxWidth.sm]: {
        welcomePage: {
            fontSize: 15
        },

        card: {
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 10
        },

        imgFaq: {
            gap: 30,
            flexWrap: 'wrap',
            '& > img': {
                margin: 'auto'
            }
        },

        buttonContainer: {
            marginTop: 15
        }
    }
});

export class WelcomePageState extends State<{hasContinued: boolean}> {

    // this gets overridden in AppContainer (to avoid circular dependency)
    isNonClientOrgUser = () => false;

    hasContinued = () => {
        // if this is an org user, then don't show them welcome
        if (this.isNonClientOrgUser()){
            return true;
        }

        return Boolean(this.state?.hasContinued);
    };
};

export const QuestionnaireWelcomePage: FC = () => {
    const classes = useStyles();
    const tracking = useInstance(MixpanelService);

    const userState = useInstance(UserState);
    useSubscription(() => userState);

    const pageState = useInstance(WelcomePageState);

    const name = userState.state?.firstName;
    const heading = (name ? `Welcome, ${name}!` : 'Welcome!');

    const onContinue = () => {
        tracking.track('Welcome Page Completed');

        pageState.setState({ hasContinued: true });
    };

    return (
        <>
            <AppHeaderOverride
                style={{
                    // we don't want a non-blue line separating the header from the welcome content (when full screen)
                    //  TODO: maybe we don't want this for other break points, need to check in with Jamie
                    // it isn't obious to me how we were addressing it before... maybe the IonPage marginTop: -1 ?
                    border: 'none'
                }}

                title={(
                    <img height="24px" src="/assets/images/logos/logo-with-text-white.svg" alt="logo" />
                )}
            />
            <div id="welcome-page-div" data-id="welcome-page" className={classes.welcomePage}>

                <div className={classes.card}>

                    <h2 style={{textAlign: 'center'}}><b>{heading}</b></h2>

                    <p style={{margin: '30px 5px'}}>You're in the right place to simplify care coordination. It all starts with creating a visual map of your support network: a care map.</p>

                    <div className={classes.imgFaq}>

                        <img style={{width: '50%'}} src="./assets/images/welcome/care-map.svg" alt="care map" />

                        <div>
                            <DrawerSectionHeading text="FAQs" />

                            <div style={{margin: '10px 0'}}>
                                <div style={{marginRight: -50, fontWeight: 600}}>How long will it take to create?</div>
                                <div>Only 5-10 minutes!</div>
                            </div>

                            <div style={{margin: '10px 0'}}>
                                <div style={{marginRight: -50, fontWeight: 600}}>Then what happens?</div>
                                <div>Your care map will be created as you go through the guided questionnaire. You can edit and share it once complete!</div>
                            </div>
                        </div>

                    </div>

                    <div className={classes.buttonContainer}>
                        <IonButton data-type="continue-button" color="secondary" style={{width: 170}} onClick={onContinue}>Get Started</IonButton>
                    </div>

                </div>
            </div>
        </>
    );
};
