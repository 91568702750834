import { State } from '@meraki-internal/state';
import { InvitationService } from './InvitationService';
import { IAcceptedCareMapInvite, IPendingCareMapInvite } from '../caremap/ICareMapInvite';
import { ICareMapKey } from '../caremap/ICareMap';
import { IUser } from '../user/UserState';

export type ICollaboratorPermission = {
    access: string;
    careMapKey: ICareMapKey;
    consentedBy: Pick<IUser, 'userId' | 'email'>;
    invite: IAcceptedCareMapInvite;
    links: {
        self: {
            href: string;
        }
    };
    userId: string;
}

type IInvitationState = {
    needsReset: boolean;
    invites: IPendingCareMapInvite[];
    collaborators: {
        owner: Pick<IUser, 'userId' | 'email'> | undefined;
        permissions: ICollaboratorPermission[];
    };
};

const defaultState = {
    needsReset: false,
    invites: [],
    collaborators: {
        owner: undefined,
        permissions: []
    }
};
export class InvitationState extends State<IInvitationState | undefined> {
    static inject = () => [
        InvitationService
    ];

    constructor(private service: InvitationService) {
        super(defaultState);
    };

    private initialDataLoaded: boolean = false;
    private previousInvites: IPendingCareMapInvite[] = [];
    private newlyAddedInvites: IPendingCareMapInvite[] | undefined;
    getNewlyAddedInvites = (): IPendingCareMapInvite[] | undefined => {
        return this.newlyAddedInvites;
    };

    loadInvites = async () => {
        this.previousInvites = [...(this.state?.invites || [])];

        const invites = await this.service.getInvites();
        this.setState({ invites });

        if (this.initialDataLoaded) {
            this.newlyAddedInvites = this.findNewlyAddedInvites();
        }

        this.initialDataLoaded = true;
    };

    private findNewlyAddedInvites = (): IPendingCareMapInvite[] | undefined  => {
        return this.state?.invites.filter(invite => !this.previousInvites.find(prevInvite => prevInvite.token === invite.token));
    };

    loadCollaborators = async () => {
        const collaborators = await this.service.getCollaborators();
        this.setState({ collaborators });
    };

    load = async (reset?: boolean) => {
        if (this.state?.needsReset) {
            this.setState(defaultState);
        }

        this.loadInvites();
        this.loadCollaborators();
    };

    needsReset = () => {
        this.setState({ needsReset: true });
    };

    deleteInvite = (token: string) => {
        const item: any = this.state?.invites.find(i => i.token === token);

        if (!item) {
            throw new Error(`No invite found to delete for token ${token}`);
        }

        this.service.delete(item);
        this.setState({ invites: this.state?.invites.filter(i => i.token !== token) });
    };

    deleteCollaborator = (token: string) => {
        const item = this.state?.collaborators.permissions.find(i => i.invite.token === token);

        if (!item) {
            throw new Error(`No collaborator found to delete for token ${token}`);
        }

        this.service.delete(item);
        this.setState({
            collaborators: {
                ...this.state!.collaborators,
                permissions: this.state?.collaborators.permissions.filter(i => i.invite.token !== token) || []
            }
        });
    };
};
