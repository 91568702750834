import { useInstance } from '@meraki-internal/react-dependency-injection';
import { OrganizationsState } from './OrganizationsState';
import { useSubscription } from '@meraki-internal/state';
import { useState } from 'react';
import { Modal } from '../components/Modal';
import { IonButton, IonCardContent, IonContent, IonFooter, IonSpinner, IonToolbar } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { TeamMemberSelectField } from './TeamMemberSelectField';
import { ClientListSpan } from './ClientListSpan';
import { AlertPresenter } from '../AlertBinder';

const useStyles = createUseStyles({
    buttonBar: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 20px',

        '& > ion-button': {
            minWidth: 120
        }
    }
});

interface ITransferClientsModalProps {
    fromMemberId: string;
    orgId: string;
    onTransferred: () => void;
    closeHandler: () => void;
    title?: string;
    transferButtonLabel?: string;
}

export const TransferClientsModal: React.FC<ITransferClientsModalProps> = ({
    fromMemberId,
    orgId,
    onTransferred,
    closeHandler,
    title,
    transferButtonLabel
}) => {
    const classes = useStyles();

    const orgState = useInstance(OrganizationsState);
    useSubscription(() => orgState);

    const alertPresenter = useInstance(AlertPresenter);

    const commonModalProps = { title: title || 'Transfer Clients' };

    const [ toMemberId, setToMemberId ] = useState<string>('');
    const [saving, setSaving] = useState<boolean>(false);

    const member = orgState.getOrganizationTeamMembers(orgId).find(m => m.userId === fromMemberId);
    if (!member){
        throw new Error(`attempted to transfer from a member ${fromMemberId} which does not exist on org ${orgId}`);
    }
    const coOwnedClients = orgState.getClientsCoOwnedByUser({ orgId, teamMemberId: fromMemberId });

    const onTransfer = async () => {
        setSaving(true);
        try {
            await orgState.transferClients({ orgId, fromMemberId, toMemberId });
            onTransferred();
        }
        catch (err: any){
            alertPresenter.showAndLogError(err);
        }
        finally {
            setSaving(false);
        }
    };

    return (
        <Modal isOpen={true} {...commonModalProps} closeHandler={closeHandler} desktopMaxHeight={260}>
            <IonContent>
                <IonCardContent>
                    <p style={{marginBottom: 12}}>
                        Transfer <strong><ClientListSpan clients={coOwnedClients} /></strong> from <strong>{member.firstName} {member.lastName}</strong> to
                    </p>
                    <TeamMemberSelectField
                        orgId={orgId}
                        excludeMemberIds={[fromMemberId]}
                        onSelected={memberId =>setToMemberId(memberId)}
                        selectedMemberId={toMemberId}
                    />
                </IonCardContent>
            </IonContent>

            <IonFooter>
                <IonToolbar className={classes.buttonBar}>
                    <IonButton slot="start" data-type="cancel-button" fill="outline" onClick={closeHandler}>Cancel</IonButton>
                    <IonButton
                        slot="end"
                        data-id="transfer"
                        disabled={!toMemberId || saving}
                        onClick={onTransfer}
                    >
                        {saving ? <IonSpinner name="dots" style={{height: 20}} /> : transferButtonLabel || 'Transfer'}
                    </IonButton>
                </IonToolbar>
            </IonFooter>
        </Modal>
    );
};
