import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix, library } from '@fortawesome/fontawesome-svg-core';
import { IonText } from '@ionic/react';

import {
    faPeopleGroup,
    faShieldHalved,
    faSchoolFlag,
    faHouseMedical,
    faHeartPulse,
    faCloudArrowUp,
    faArrowRightFromBracket,
    faArrowUpFromBracket,
    faCloudArrowDown,
    faUserDoctor,
    faChalkboardUser,
    faHandHoldingHeart,
    faHospitalUser,
    faLocationDot,
    faEnvelope,
    faPhone,
    faMobileScreen,
    faEllipsis,
    faPenToSquare,
    faTrashCan,
    faPlus,
    faMinus,
    faCopy,
    faCheck,
    faPencil,
    faSearch,
    faClose,
    faChevronLeft,
    faChevronRight,
    faClipboardList,
    faBars,
    faGear,
    faArrowRightArrowLeft,
    faArrowRight,
    faPaperclip,
    faCalendarCirclePlus,
    faEye,
    faQuoteLeft,
    faPeopleArrows,
    faFaceSmileBeam,
    faFaceSmile,
    faFaceMeh,
    faFaceFrownSlight,
    faFaceDisappointed,
    faBell
} from '@fortawesome/pro-solid-svg-icons';

import {
    faEnvelope as farEnvelope,
    faLightbulb as farLightbulb,
    faHandsHoldingHeart as farHandsHoldingHeart,
    faHandHoldingHeart as farHandHoldingHeart,
    faPeopleRoof as farPeopleRoof,
    faFileContract as farFileContract,
    faHeart as farHeart,
    faCalendarCirclePlus as farCalendarCirclePlus,
    faClock as farClock,
    faStars as farStars,
    faCircleChevronRight as farCircleChevronRight,
    faCirclePlus as farCirclePlus,
    faFaceSmileBeam as farFaceSmileBeam,
    faFaceSmile as farFaceSmile,
    faFaceMeh as farFaceMeh,
    faFaceFrownSlight as farFaceFrownSlight,
    faFaceDisappointed as farFaceDisappointed
} from '@fortawesome/pro-regular-svg-icons';

import {
    faFile as faDuoFile,
    faFilePdf as faDuoFilePdf,
    faFileDoc as faDuoFileDoc,
    faSparkles as faDuoSparkles,
    faSun as faDuoSun,
    faStarShooting as faDuoStarShooting,
    faTrophyStar as faDuoTrophyStar,
    faHandshakeAngle as faDuoHandshakeAngle,
    faFlowerDaffodil as faDuoDaffodil,
    faSnooze as faDuoSnooze,
    faVolcano as faDuoVolcano,
    faChair as faDuoChair,
    faWave as faDuoWave,
    faBatteryQuarter as faDuoBatteryQuarter,
    faRaindrops as faDuoRaindrops,
    faCircleMinus as faDuoCircleMinus,
    faArrowsToCircle as faDuoArrowsToCircle,
    faCrow as faDuoCrow,
    faLeaf as faDuoLeaf,
    faCloudBolt as faDuoCloudBolt,
    faGavel as faDuoGavel,
    faBed as faDuoBed,
    faAlarmClock as faDuoAlarmClock,
    faDumbbell as faDuoDumbbell,
    faBenchTree as faDuoBenchTree,
    faPeople as faDuoPeople,
    faHotTub as faDuoHotTub,
    faBottleWater as faDuoBottleWater,
    faEggFried as faDuoEggFried,
    faSalad as faDuoSalad,
    faPlateUtensils as faDuoPlateUtensils,
    faCitrus as faDuoCitrus,
    faShower as faDuoShower,
    faKitchenSet as faDuoKitchenSet,
    faCalendarDay as faDuoCalendarDay,
    faCalendarDays as faDuoCalendarDays,
    faPeopleLine as faDuoPeopleLine,
    faMedal as faDuoMedal,
    faPills as faDuoPills,
    faPersonWalking as faDuoPersonWalking,
    faCards as faDuoCards,
    faVacuum as faDuoVacuum,
    faCartShopping as faDuoCartShopping,
    faVanShuttle as faDuoVanShuttle,
    faDiamondExclamation as faDuoDiamondExclamation,
    faHousePersonReturn as faDuoHousePersonReturn,
    faHandHoldingMedical as faDuoHandHoldingMedical,
    faClipboardListCheck as faDuoClipboardListCheck,
    faHospital as faDuoHospital,
    faCirclePlus as faDuoCirclePlus,
    faFileLines as faDuoFileLines
} from '@fortawesome/pro-duotone-svg-icons';

import { IBrandColors, IThemeColor } from './IThemeColor';

library.add(
    faPeopleGroup,
    faShieldHalved,
    faSchoolFlag,
    faHouseMedical,
    faHeartPulse,
    faCloudArrowUp,
    faArrowRightFromBracket,
    faArrowUpFromBracket,
    faCloudArrowDown,
    faUserDoctor,
    faChalkboardUser,
    faHandHoldingHeart,
    faHospitalUser,
    faLocationDot,
    faEnvelope,
    farEnvelope,
    faPhone,
    faMobileScreen,
    faPencil,
    faEllipsis,
    faPenToSquare,
    faTrashCan,
    faPlus,
    faMinus,
    faCopy,
    faCheck,
    farLightbulb,
    faSearch,
    faClose,
    faChevronLeft,
    faChevronRight,
    faClipboardList,
    faBars,
    faGear,
    faArrowRightArrowLeft,
    faArrowRight,
    faPaperclip,
    farHandsHoldingHeart,
    farHandHoldingHeart,
    farPeopleRoof,
    farFileContract,
    farHeart,
    farCalendarCirclePlus,
    faFaceSmileBeam,
    faFaceSmile,
    faFaceMeh,
    faFaceFrownSlight,
    faFaceDisappointed,
    faDuoFile,
    faDuoFilePdf,
    faDuoFileDoc,
    faDuoSparkles,
    faDuoSun,
    faDuoStarShooting,
    faDuoTrophyStar,
    faDuoHandshakeAngle,
    faDuoDaffodil,
    faDuoSnooze,
    faDuoVolcano,
    faDuoChair,
    faDuoWave,
    faDuoBatteryQuarter,
    faDuoRaindrops,
    faDuoCircleMinus,
    faDuoArrowsToCircle,
    faDuoCrow,
    faDuoLeaf,
    faDuoCloudBolt,
    faDuoGavel,
    faDuoBed,
    faDuoAlarmClock,
    faDuoDumbbell,
    faDuoBenchTree,
    faDuoPeople,
    faDuoHotTub,
    faDuoBottleWater,
    faDuoEggFried,
    faDuoSalad,
    faDuoPlateUtensils,
    faDuoCitrus,
    faDuoShower,
    faDuoKitchenSet,
    faDuoCalendarDay,
    faDuoCalendarDays,
    faDuoPeopleLine,
    faDuoMedal,
    faDuoPills,
    faDuoPersonWalking,
    faDuoCards,
    faDuoVacuum,
    faDuoCartShopping,
    faDuoVanShuttle,
    faDuoDiamondExclamation,
    faDuoHousePersonReturn,
    faDuoHandHoldingMedical,
    faDuoClipboardListCheck,
    faDuoHospital,
    faDuoCirclePlus,
    faDuoFileLines,
    faCalendarCirclePlus,
    farClock,
    farStars,
    farCircleChevronRight,
    faEye,
    farCirclePlus,
    farFaceSmileBeam,
    farFaceSmile,
    farFaceMeh,
    farFaceFrownSlight,
    farFaceDisappointed,
    faQuoteLeft,
    faPeopleArrows,
    faBell
);

export type IIconSize = 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
export type IIconShape = 'round';

interface IIconProps {
    color?: IThemeColor | IBrandColors,
    backgroundColor?: IThemeColor | 'white',
    borderColor?: IThemeColor,
    size?: IIconSize,
    shape?: IIconShape,
    slot?: string
}

const Icon: FC<IIconProps & { name: IconName, prefix: IconPrefix }> = ({
    name,
    prefix,
    color = 'primary',
    backgroundColor,
    borderColor,
    size = 'medium',
    shape,
    slot
}) => {
    let fontSize = {small: 14, medium: 18, large: 24, xlarge: 32, xxlarge: 48}[size];

    let wrapperStyle: React.CSSProperties = {};

    if (shape === 'round') {
        wrapperStyle = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: {small: '1.5em', medium: '2em', large: '2.5em', xlarge: '3em', xxlarge: '4em'}[size],
            height: {small: '1.5em', medium: '2em', large: '2.5em', xlarge: '3em', xxlarge: '4em'}[size],
            backgroundColor: backgroundColor === 'white' ? 'white' : backgroundColor ? `var(--ion-color-${backgroundColor})` : `rgb(var(--ion-color-${color}-rgb), 0.2)`,
            boxShadow: borderColor ? `0 0 1px 1px var(--ion-color-${borderColor})` : undefined,
            borderRadius: '50%',
        };

        // reduce size slightly so it fits nicely in the circle
        fontSize = fontSize * (size === 'small' ? 0.85 : 0.75);

        return (
            <div style={wrapperStyle} slot={slot || ''}>
                <IonText color={color} style={{fontSize}}>
                    <FontAwesomeIcon icon={[prefix, name]} />
                </IonText>
            </div>
        );
    }

    return (
        <IonText color={color} style={{fontSize}} slot={slot || ''}>
            <FontAwesomeIcon icon={[prefix, name]} />
        </IonText>
    );
};

export type IconComponent = FC<IIconProps>;

const buildIcon = (name: IconName, prefix: IconPrefix = 'fas'): IconComponent => {
    return (props: IIconProps) => <Icon name={name} prefix={prefix} {...props} />;
};

export const LogoutIcon = buildIcon('arrow-right-from-bracket');
export const LocationIcon = buildIcon('location-dot');
export const EnvelopeIcon = buildIcon('envelope');
export const EnvelopeOutlineIcon = buildIcon('envelope', 'far');
export const CopyIcon = buildIcon('copy');
export const CheckIcon = buildIcon('check');
export const PhoneIcon = buildIcon('phone');
export const MobilePhoneIcon = buildIcon('mobile-screen');
export const MoreIcon = buildIcon('ellipsis');
export const EditIcon = buildIcon('pen-to-square');
export const EditIcon2 = buildIcon('pencil');
export const DeleteIcon = buildIcon('trash-can');
export const ShareIcon = buildIcon('arrow-up-from-bracket');
export const DownloadIcon = buildIcon('cloud-arrow-down');
export const UploadIcon = buildIcon('cloud-arrow-up');
export const PlusIcon = buildIcon('plus');
export const MinusIcon = buildIcon('minus');
export const LightbulbOutlineIcon = buildIcon('lightbulb', 'far');
export const SearchIcon = buildIcon('search');
export const BackIcon = buildIcon('chevron-left');
export const CloseIcon = buildIcon('close');
export const ClipboardListIcon = buildIcon('clipboard-list');
export const HamburgerMenuIcon = buildIcon('bars');
export const GearIcon = buildIcon('gear');
export const ArrowRightArrowLeftIcon = buildIcon('arrow-right-arrow-left');
export const ArrowRightIcon = buildIcon('arrow-right');
export const ChevronRightIcon = buildIcon('chevron-right');
export const ChevronLeftIcon = buildIcon('chevron-left');
export const CircleChevronRightIcon = buildIcon('circle-chevron-right', 'far');
export const PaperclipIcon = buildIcon('paperclip');
export const AddAppointmentIcon = buildIcon('calendar-circle-plus');
export const ClockOutlineIcon = buildIcon('clock', 'far');
export const EyeIcon = buildIcon('eye');
export const LeftQuoteIcon = buildIcon('quote-left');
export const PeopleArrowsIcon = buildIcon('people-arrows');
export const DuoFileLinesIcon = buildIcon('file-lines', 'fad');
export const BellIcon = buildIcon('bell');

// support groups
export const SchoolGroupIcon = buildIcon('school-flag');
export const MedicalGroupIcon = buildIcon('house-medical');
export const CommunityGroupIcon = buildIcon('people-group');
export const SpecialistsGroupIcon = buildIcon('heart-pulse');
export const FinancialGroupIcon = buildIcon('shield-halved');

// support group members
export const SchoolMemberIcon = buildIcon('chalkboard-user');
export const MedicalMemberIcon = buildIcon('user-doctor');
export const CommunityMemberIcon = buildIcon('hand-holding-heart');
export const SpecialistsMemberIcon = buildIcon('hospital-user');
export const FinancialMemberIcon = buildIcon('shield-halved');

// doc types
export const DuoFileIcon = buildIcon('file', 'fad');
export const DuoPDFFileIcon = buildIcon('file-pdf', 'fad');
export const DuoDocFileIcon = buildIcon('file-doc', 'fad');

// innerhive plus
export const InnerhivePlusIcon = buildIcon('sparkles', 'fad');
export const ShareCareIcon = buildIcon('hands-holding-heart', 'far');
export const ImproveCareIcon = buildIcon('people-roof', 'far');
export const UnlimitedStorageIcon = buildIcon('file-contract', 'far');
export const SupportInnerhiveIcon = buildIcon('heart', 'far');
export const StarsIcon = buildIcon('stars', 'far');
export const CalendarPlusIcon = buildIcon('calendar-circle-plus', 'far');

// caregiver
export const AddFeelingIcon = buildIcon('circle-plus', 'fad');
export const VeryHappyIcon = buildIcon('face-smile-beam', 'far');
export const HappyIcon = buildIcon('face-smile', 'far');
export const MehIcon = buildIcon('face-meh', 'far');
export const SadIcon = buildIcon('face-frown-slight', 'far');
export const VerySadIcon = buildIcon('face-disappointed', 'far');
export const SolidVeryHappyIcon = buildIcon('face-smile-beam');
export const SolidHappyIcon = buildIcon('face-smile');
export const SolidMehIcon = buildIcon('face-meh');
export const SolidSadIcon = buildIcon('face-frown-slight');
export const SolidVerySadIcon = buildIcon('face-disappointed');

export const DuoSunIcon = buildIcon('sun', 'fad');
export const DuoShootingStarIcon = buildIcon('star-shooting', 'fad');
export const DuoTrophyStarIcon = buildIcon('trophy-star', 'fad');
export const DuoHandshakeIcon = buildIcon('handshake-angle', 'fad');
export const DuoDaffodilIcon = buildIcon('flower-daffodil', 'fad');
export const DuoSnoozeIcon = buildIcon('snooze', 'fad');
export const DuoVolvanoIcon = buildIcon('volcano', 'fad');
export const DuoChairIcon = buildIcon('chair', 'fad');
export const DuoWaveIcon = buildIcon('wave', 'fad');
export const DuoBatteryQuarterIcon = buildIcon('battery-quarter', 'fad');
export const DuoRaindropsIcon = buildIcon('raindrops', 'fad');
export const DuoCircleMinusIcon = buildIcon('circle-minus', 'fad');
export const DuoArrowsToCircleIcon = buildIcon('arrows-to-circle', 'fad');
export const DuoCrowIcon = buildIcon('crow', 'fad');
export const DuoLeafIcon = buildIcon('leaf', 'fad');
export const DuoCloudBoltIcon = buildIcon('cloud-bolt', 'fad');
export const DuoGavelIcon = buildIcon('gavel', 'fad');
export const DuoBedIcon = buildIcon('bed', 'fad');
export const DuoAlarmClockIcon = buildIcon('alarm-clock', 'fad');
export const DuoDumbbellIcon = buildIcon('dumbbell', 'fad');
export const DuoBenchTreeIcon = buildIcon('bench-tree', 'fad');
export const DuoPeopleIcon = buildIcon('people', 'fad');
export const DuoHotTubIcon = buildIcon('hot-tub', 'fad');
export const DuoBottleWaterIcon = buildIcon('bottle-water', 'fad');
export const DuoEggFriedIcon = buildIcon('egg-fried', 'fad');
export const DuoSaladIcon = buildIcon('salad', 'fad');
export const DuoPlateUtensilsIcon = buildIcon('plate-utensils', 'fad');
export const DuoCitrusIcon = buildIcon('citrus', 'fad');
export const DuoShowerIcon = buildIcon('shower', 'fad');
export const DuoKitchenSetIcon = buildIcon('kitchen-set', 'fad');
export const DuoCalendarDayIcon = buildIcon('calendar-day', 'fad');
export const DuoCalendarDaysIcon = buildIcon('calendar-days', 'fad');
export const DuoPeopleLineIcon = buildIcon('people-line', 'fad');
export const DuoMedalIcon = buildIcon('medal', 'fad');
export const DuoPillsIcon = buildIcon('pills', 'fad');
export const DuoPersonWalkingIcon = buildIcon('person-walking', 'fad');
export const DuoCardsIcon = buildIcon('cards', 'fad');
export const DuoVacuumIcon = buildIcon('vacuum', 'fad');
export const DuoCartShoppingIcon = buildIcon('cart-shopping', 'fad');
export const DuoVanShuttleIcon = buildIcon('van-shuttle', 'fad');
export const DuoDiamondExclamationIcon = buildIcon('diamond-exclamation', 'fad');
export const DuoHousePersonReturnIcon = buildIcon('house-person-return', 'fad');
export const DuoHandHoldingMedicalIcon = buildIcon('hand-holding-medical', 'fad');
export const DuoClipboardListCheckIcon = buildIcon('clipboard-list-check', 'fad');
export const DuoHospitalIcon = buildIcon('hospital', 'fad');
