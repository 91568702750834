import { IonContent } from '@ionic/react';
import { AppHeaderOverride } from './AppHeader';
import { NavigationMenu } from './navigation/NavigationMenu';
import { createUseStyles } from 'react-jss';
import { mediaMinWidth } from './theme/utils';
import React from 'react';
import { RequireNameModal } from './user/RequireNameModal';

type IStyleKeys = 'content' | 'paddedContent';

interface IStyleProps {
    backgroundColor: string | undefined;
};

const useStyles = createUseStyles<IStyleKeys, IStyleProps>({
    paddedContent: {
        '--padding-start': 'var(--ion-padding, 16px)', // from .ion-padding
        '--padding-end': 'var(--ion-padding, 16px)',
        '--padding-top': 'var(--ion-padding, 16px)',

        [mediaMinWidth.lg]: {
            '--padding-start': '250px',
            '--padding-end': '250px',
            '--padding-top': '50px'
        },
    },

    content: {
        '--background': ({ backgroundColor }) => backgroundColor
    }
});

interface IInnerhivePageProps {
    title?: string;
    children: React.ReactNode;
    hideAppHeader?: boolean;
    backgroundColor?: string;
    skipAddingNavigation?: boolean;
    skipAddingIonContent?: boolean;
    skipRequireName?: boolean;
}

export const InnerhivePage: React.FC<IInnerhivePageProps> = ({
    title = '',
    children,
    backgroundColor,
    hideAppHeader = false,
    skipAddingNavigation,
    skipAddingIonContent,
    skipRequireName
}) => {
    const classes = useStyles({backgroundColor: backgroundColor});

    return (
        <>
            {!skipRequireName && <RequireNameModal />}

            {/* TODO: key is not ideal way to get AppHeaderOverride to detect changes */}
            <AppHeaderOverride key={title} title={title} hidden={hideAppHeader} />

            {!skipAddingIonContent && (
                <IonContent
                    id="page-content"
                    className={`${classes.content} ${!hideAppHeader ? classes.paddedContent : ''}`}
                >
                    {children}
                </IonContent>
            )}

            {skipAddingIonContent && children}

            {!skipAddingNavigation && <NavigationMenu contentId="page-content" />}
        </>
    );
};
