import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useState } from 'react';
import { useAsyncEffect } from '../useAsyncEffect';
import { HistoryViewModel } from '../HistoryViewModel';
import { AsyncStorageProvider } from '../AsyncStorageProvider';
import { Logger } from '../Logger';

export const RedirectAfterLiveUpdateInstall: React.FC<{ children: any; }> = ({ children }) => {
    const [liveUpdatesAppStartPathStatus, setLiveUpdatesAppStartPathStatus] = useState<'initializing' | 'redirecting' | 'done'>('initializing');
    const storageProvider = useInstance(AsyncStorageProvider);
    const historyViewModel = useInstance(HistoryViewModel);
    const logger = useInstance(Logger);

    useAsyncEffect(async () => {

        const storage = storageProvider.getStringProvider('LiveUpdatesAppStartPath', {userNeutral: true});
        const path = await storage.get();

        if (path){
            logger.info('detected path from a live update install, redirecting to', { path });
            await storage.remove();
            historyViewModel.replace(path);
        }

        setLiveUpdatesAppStartPathStatus('done');
    }, []);

    if (liveUpdatesAppStartPathStatus !== 'done'){
        return null;
    }

    return children;
};
