import { APIClient } from '@innerhive-internal/innerhive-api-client';
import { IOrganization, IOrganizationBaseUser, IOrganizationUser } from './IOrganization';
import { MaybeWithLinks, WithLinks } from '../caremap/WithLinks';

export class OrganizationsService {
    static inject = () => [APIClient];
    constructor(private apiClient: APIClient){}

    createOrganization = async (org: { name: string; } ): Promise<{ orgId: string; }> => {
        const entry = await this.apiClient.entry();
        if (!entry.links.organizations){
            throw new Error('cannot create an organization');
        }
        const res = await this.apiClient.post(entry.links.organizations, org);
        return res;
    };

    getOrganizations = async (): Promise<IOrganization[]> => {
        const entry = await this.apiClient.entry();
        if (!entry.links.organizations){
            return [];
        }
        return await this.apiClient.get(entry.links.organizations);
    };

    deleteOrganization = async (org: IOrganization) => {
        await this.apiClient.delete(org.links.self);
    };

    createOrganizationClient = async (org: IOrganization, clientInfo: IOrganizationBaseUser) => {
        const { userId } = await this.apiClient.post(org.links.users, clientInfo);
        return userId;
    };

    createOrganizationUser = async (org: IOrganization, userInfo: IOrganizationBaseUser) => {
        const { userId } = await this.apiClient.post(org.links.users, userInfo);
        return userId;
    };

    getOrganizationUsers = async (org: IOrganization) => {
        return await this.apiClient.get(org.links.users);
    };

    removeOrganizationUser = async (user: MaybeWithLinks<IOrganizationUser>) => {
        await this.apiClient.delete(user.links?.self);
    };

    getOrganizationCareMaps = async (org: IOrganization) => {
        return await this.apiClient.get(org.links.caremaps);
    };

    transferClient = async ({ fromMemberId, toMemberId, clientUser }: { fromMemberId: string; toMemberId: string; clientUser: WithLinks<IOrganizationUser>; }) => {
        await this.apiClient.post(clientUser.links['transfer-caremaps'], {
            fromMemberId,
            toMemberId
        });
    };
}
