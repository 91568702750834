import { PredefinedColors } from '@ionic/core';
import { CommunityGroupIcon, CommunityMemberIcon, FinancialGroupIcon, FinancialMemberIcon, IconComponent, MedicalGroupIcon, MedicalMemberIcon, SchoolGroupIcon, SchoolMemberIcon, SpecialistsGroupIcon, SpecialistsMemberIcon } from '../theme/Icons';
import { ISupportGroupKey } from './ICareMap';
import { HoneycombConfigProvider } from '../nodes/HoneycombConfigProvider';

export interface ISupportGroupIcons {
    GroupIcon: IconComponent;
    MemberIcon: IconComponent;
};

export class SupportGrouIconProvider {
    static inject = () => [HoneycombConfigProvider];
    constructor(private honeycombConfigProvider: HoneycombConfigProvider){}

    getAllSupportGroupIcons = (): {[key in ISupportGroupKey]: ISupportGroupIcons} => {
        const icons: {[key in ISupportGroupKey]: ISupportGroupIcons} = {
            'community': {
                GroupIcon: CommunityGroupIcon,
                MemberIcon: CommunityMemberIcon,
            },
            'financial': {
                GroupIcon: FinancialGroupIcon,
                MemberIcon: FinancialMemberIcon,
            },
            'school':{
                GroupIcon: SchoolGroupIcon,
                MemberIcon: SchoolMemberIcon,
            },
            'medical': {
                GroupIcon: MedicalGroupIcon,
                MemberIcon: MedicalMemberIcon,
            },
            'specialists': {
                GroupIcon: SpecialistsGroupIcon,
                MemberIcon: SpecialistsMemberIcon,
            },
            // TODO: icons
            'social': {
                GroupIcon: SpecialistsGroupIcon,
                MemberIcon: SpecialistsMemberIcon,
            }
        };

        return icons;
    };

    getSupportGroupIcons = (supportGroupKey: ISupportGroupKey): ISupportGroupIcons & { color: PredefinedColors } => {
        const { GroupIcon, MemberIcon } = this.getAllSupportGroupIcons()[supportGroupKey];
        const index = this.honeycombConfigProvider.getSupportGroupIndexByKey(supportGroupKey);
        const color: PredefinedColors = index % 2 === 0 ? 'primary' : 'secondary';

        return { GroupIcon, MemberIcon, color };
    };

}
