import React from 'react';
import { Text } from '../components/Text';
import { IonButtons, isPlatform } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { CopyButton } from '../components/CopyButton';
import { ITrackingContext, MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { ContactMethodIcon } from './ContactMethodIcon';
import { IContactMethodOrAddress } from './IContactMethodOrAddress';
import { IContactMethod } from '../caremap/ICareMap';

const useStyles = createUseStyles({
    contactMethodActions: {
        // on desktop, show buttons only on hover
        visibility: isPlatform('desktop') ? 'hidden' : 'visible',
        paddingRight: 8
    },

    contactMethod: {
        display: 'flex',
        alignItems: 'center',
        gap: 20,
        cursor: 'pointer',

        // 'contact-method-button' icon buttons are 36
        height: 36 + 10,

        '&:hover': {
            borderRadius: 6,
            // set hover background on desktop only, to avoid "sticky hover" on mobile
            backgroundColor: isPlatform('desktop') ? 'var(--ion-color-light)' : undefined,
        },
        '&:hover $contactMethodActions': {
            visibility: 'visible'
        }
    }
});

interface IContactMethodsListProps {
    contactMethods: IContactMethod[];
    address?: string;
    iconWidth?: number;
    emailToName?: string;
    trackingContext?: ITrackingContext;
}

export const ContactMethodsList: React.FC<IContactMethodsListProps> = ({ contactMethods, address, iconWidth = 50, emailToName, trackingContext = {} }) => {
    const classes = useStyles();
    const tracking = useInstance(MixpanelService);

    // address first, then contact methods
    const contactMethodsWithAddress: IContactMethodOrAddress[] = [];
    if (address) {
        contactMethodsWithAddress.push({ type: 'address', value: address });
    }
    contactMethodsWithAddress.push(...contactMethods);

    const getHref = ({ type, value }: IContactMethodOrAddress): string => {
        if (type === 'email' && emailToName){
            return `mailto:${`"${encodeURIComponent(emailToName.replaceAll('"', ''))}"<${value}>`}`;
        }
        else if (type === 'email') {
            return `mailto:${value}`;
        }
        else if (type === 'address') {
            return `https://www.google.com/maps/dir/?api=1&destination=${value}`;
        }
        else {
            return `tel:${value}`;
        }
    };

    const trackNativeAction = (method: IContactMethodOrAddress) => {
        tracking.track('Contact Info Opened', () => ({
            ...trackingContext,
            type: method.type
        }));
    };

    const onClickHandler = (method: IContactMethodOrAddress) => {
        var link = document.createElement('a');
        link.href = getHref(method);
        link.target = '_blank';
        link.click();
        trackNativeAction(method);
    };

    const methodColor = !isPlatform('desktop') ? 'primary' : undefined;

    return (
        <div>
            {contactMethodsWithAddress.map((method, idx) => (
                <div data-type="contact-method"
                    className={classes.contactMethod}
                    key={`method-${idx}`}
                    onClick={() => onClickHandler(method)}
                >
                    <div data-type="icon" style={{ flexShrink: 0, width: iconWidth, textAlign: 'center', paddingLeft: (iconWidth - 20), marginTop: 4}}>
                        <ContactMethodIcon methodType={method.type} color={methodColor} />
                    </div>

                    <div data-type="value" style={{ flexGrow: 1, minWidth: 0 }}>
                        <Text type="detail"  color={methodColor} ellipsize>{method.value}</Text>
                    </div>

                    <IonButtons className={classes.contactMethodActions}>
                        <CopyButton
                            value={method.value}
                            size="small"
                            trackingEvent="Contact Info Copied"
                            trackingContext={{ ...trackingContext, type: method.type }}
                        />
                    </IonButtons>
                </div>
            ))}
        </div>
    );
};
