import { useInstance } from '@meraki-internal/react-dependency-injection';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { useEffect, useState } from 'react';
import { IonButton, IonInput } from '@ionic/react';
import { InnerhivePage } from '../InnerhivePage';
import { OrganizationsState } from './OrganizationsState';
import { useSubscription } from '@meraki-internal/state';
import { AlertPresenter } from '../AlertBinder';
import { LoadingPage } from '../LoadingPage';

export const CreateOrgPage: React.FC = () => {
    const orgsState = useInstance(OrganizationsState);
    useSubscription(() => orgsState);
    const history = useInstance(HistoryViewModel);
    const alertPresenter = useInstance(AlertPresenter);

    const searchParams = history.getCurrentSearchParamMap();
    const [name, setName] = useState<string>(searchParams.name || '');
    const [loading, setLoading] = useState<boolean>(true);

    const createOrg = async () => {
        if (!name) {
            alert('Name is required');
            return;
        }
        try {
            const { orgId } = await orgsState.createOrganization({ name });
            history.push(`/org/${orgId}`);
        } catch (e: any) {
            alertPresenter.showAndLogError(e);
        }
    };

    useEffect(() => {
        const auto = searchParams.autocreate || searchParams.a;
        const alreadyExistingOrgWithName = orgsState.getAllOrganizations().find(o => o.name === searchParams.name);
        if (auto && alreadyExistingOrgWithName) {
            history.push(`/org/${alreadyExistingOrgWithName.orgId}`);
        }
        else if (auto) {
            createOrg();
        }
        else {
            setLoading(false);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <InnerhivePage title="Create A New Innerhive Organization" skipRequireName>
            {!loading ? (
                <div style={{paddingTop: 50, paddingLeft: 50}}>
                    <IonInput
                        data-id="name"
                        value={name}
                        label="Name"
                        labelPlacement="stacked"
                        fill="outline"
                        style={{width: 300}}
                        onIonInput={e => setName(e.detail.value || '')}
                    />
                    <IonButton data-id="create-org" onClick={createOrg}>Create</IonButton>
                </div>
            ) : <LoadingPage showLogo />}
        </InnerhivePage>
    );
};
