import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useMemo } from 'react';
import { OrganizationsState } from './OrganizationsState';
import { useSubscription } from '@meraki-internal/state';
import { DeleteMemberConfirmationAlert } from './remove-member/DeleteMemberConfirmationAlert';
import { RemoveTeamMemberWithClientsModal } from './remove-member/RemoveTeamMemberWithClientsModal';

interface IRemoveTeamMemberModalProps {
    orgId: string;
    memberId: string;
    onDeleted: () => void;
    onCancel: () => void;
}
// TODO: remove vs delete

export const RemoveTeamMemberModal: React.FC<IRemoveTeamMemberModalProps> = ({ orgId, memberId, onDeleted, onCancel }) => {
    const orgs = useInstance(OrganizationsState);
    useSubscription(() => orgs);

    const member = orgs.getOrganizationUser({ orgId, userId: memberId });

    const hasClients = useMemo(() => orgs.getClientsCoOwnedByUser({ orgId, teamMemberId: memberId }).length > 0, [ memberId, orgs, orgId ]);

    if (!member){
        throw new Error('no member found, this should not happen');
    }

    if (!hasClients){
        return (
            <DeleteMemberConfirmationAlert user={member} onDeleted={onDeleted} />
        );
    }

    return (
        <RemoveTeamMemberWithClientsModal
            orgId={orgId}
            memberId={memberId}
            onRemoved={onDeleted}
            onCancel={onCancel}
        />
    );
};
