import { FC, useEffect, useState } from 'react';
import { DrawerPage } from '../components/DrawerPage';
import { IContactMethod, ISupportGroupKey, ISupportGroupMember } from '../caremap/ICareMap';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { TextField } from '../components/TextField';
import { SupportGroupDataProvider } from '../caremap/SupportGroupDataProvider';
import { SupportGrouIconProvider } from '../caremap/SupportGroupIconProvider';
import { RoleSelectField } from '../components/RoleSelectField';
import { IonButton } from '@ionic/react';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { useDeleteMember } from './useDeleteMember';
import { CareMapDrawerState } from '../caremap/CareMapDrawerState';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { ContactMethodsSection } from '../contact-methods/ContactMethodsSection';
import { DeleteMemberButton } from './DeleteMemberButton';
import { CustomTypeAddedMetaBuilder } from '../metrics/CustomTypeAddedMetaBuilder';
import { ILabelKey } from '../config/LabelProvider';

export interface ISupportGroupMemberEditProps {
    supportGroupKey: ISupportGroupKey;
    memberId?: string;
};

export const SupportGroupMemberEdit: FC<ISupportGroupMemberEditProps> = ({ supportGroupKey, memberId }) => {
    const history = useInstance(HistoryViewModel);
    const tracking = useInstance(MixpanelService);

    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);

    const drawerState = useInstance(CareMapDrawerState);

    useEffect(() => {
        drawerState.setIsMaskingFocusedCareMap(true);

        return () => {
            drawerState.setIsMaskingFocusedCareMap(false);
        };
    }, [supportGroupKey, drawerState]);

    const dataProvider = useInstance(SupportGroupDataProvider);
    const roleOptions = dataProvider.getSupportGroupMemberSelectOptions(supportGroupKey);

    const iconProvider = useInstance(SupportGrouIconProvider);

    const isAdding = (!memberId);

    let initialMember: ISupportGroupMember | undefined;
    if (isAdding) {
        initialMember = careMapStateInstance.getNewSupportGroupMember({ supportGroupKey });
    } else {
        initialMember = careMapStateInstance.getSupportGroupMember({ supportGroupKey, memberId });
    }

    // initializing with ! here because we don't need to consider undefined after guard just below
    const [member, setMember] = useState(initialMember!);

    const onDelete = useDeleteMember({ supportGroupKey, memberId: memberId!, onDelete: () => {
        // need to go back twice to skip through read-only page
        history.back(2);
    }});

    if (!careMapStateInstance.state || !member) {
        return null;
    }

    const onCancel = () => {
        history.back();
    };

    const onSave = () => {
        if (isAdding) {
            careMapStateInstance.addSupportGroupMember(supportGroupKey, member);

            tracking.track('Care Member Added', () => ({
                '# of Contact Methods': member.contactMethods.length
            }));

        } else {
            careMapStateInstance.updateSupportGroupMember(supportGroupKey, member);

            tracking.track('Care Member Edited', () => ({
                '# of Contact Methods': member.contactMethods.length
            }));
        }

        history.back();
    };

    const isValid = Boolean(member.name?.trim() || member.role);

    const labels = careMapStateInstance.getSupportGroupLabels(supportGroupKey);
    const { color, MemberIcon } = iconProvider.getSupportGroupIcons(supportGroupKey);

    const onMethodsChanged = (contactMethods: IContactMethod[]) => {
        setMember(m => ({ ...m, contactMethods }));
    };

    const buttons = <>
        <IonButton data-type="save-button" disabled={!isValid} onClick={onSave}>Save</IonButton>
        <IonButton data-type="cancel-button" fill="outline" onClick={onCancel}>Cancel</IonButton>
    </>;

    const onRoleChangedMetrics = (updates: { roleId?: string; role?: string; }) => {
        if (updates.role && !updates.roleId){
            tracking.trackWithDebounce({
                event: 'Custom Type Added',
                getContext: () => new CustomTypeAddedMetaBuilder()
                    .origin('caremap')
                    .supportGroup(supportGroupKey)
                    .field({ field: 'role', fieldLabelKey: `SUPPORT_GROUP__${supportGroupKey.toUpperCase()}__ROLE` as ILabelKey, value: updates.role! })
                    .build()
            });
        }
    };

    const onRoleChanged = (updates: { roleId?: string; role?: string; }) => {
        setMember(m => ({ ...m, ...updates }));
        onRoleChangedMetrics(updates);
    };

    const roleField = (
        <div style={{flexGrow: 1}}>
            <RoleSelectField
                roleId={member.roleId}
                role={member.role}
                label={labels.roleLabel}
                placeholder={labels.rolePlaceholder}
                options={roleOptions}
                onChange={onRoleChanged}
            />
        </div>
    );

    const nameField = (
        <TextField
            name="name"
            label={labels.memberNameLabel}
            value={member.name}
            autocapitalize="words"
            onInput={name => setMember(m => ({ ...m, name }))}
        />
    );

    const showNameFirst = careMapStateInstance.shouldShowNameFirst(supportGroupKey);

    return (
        <DrawerPage
            title={`${isAdding ? 'Add' : 'Edit'} ${labels.memberTitle}`}
            hasBack={true}
            buttons={buttons}
        >
            <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>

                <div style={{display: 'flex', alignItems: 'center', width: '100%', gap: 20}}>
                    <div style={{flexShrink: 0}}>
                        <MemberIcon color={color} size="xlarge" shape="round" />
                    </div>
                    {showNameFirst ? nameField : roleField}
                </div>

                {showNameFirst ? roleField : nameField}

                <TextField
                    name="organization"
                    label="Organization"
                    value={member.organization}
                    autocapitalize="words"
                    onInput={organization => setMember(m => ({ ...m, organization }))}
                />

                <TextField
                    name="address"
                    label="Address"
                    value={member.address}
                    autocapitalize="words"
                    onInput={address => setMember(m => ({ ...m, address }))}
                />

                <ContactMethodsSection contactMethods={member.contactMethods} onMethodsChanged={onMethodsChanged} />
            </div>

            {!isAdding &&
                <DeleteMemberButton onDelete={onDelete} memberLabel={labels.memberTitle} />
            }

        </DrawerPage>
    );
};
