import { FC, useEffect, useState } from 'react';
import { Badge } from './Badge';
import { IconComponent, LightbulbOutlineIcon } from '../theme/Icons';
import { IonButton, IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import { IAsyncSmartStorageProvider, AsyncStorageProvider } from '../support/AsyncStorageProvider';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    '@keyframes show': {
        from: { opacity: 0 },
        to: { opacity: 1 }
    },
    '@keyframes hide': {
        from: { opacity: 1 },
        to: { opacity: 0 }
    },
    wrapper: {
        position: 'absolute',
        width: '100%',
        zIndex: 1, // keep above react-flow
        boxShadow: '0px 5px 5px 0px #ddd',
        animation: '$show ease 0.5s'
    },
    hiding: {
        animation: '$hide ease 0.5s',
        opacity: 0
    }
});

class NudgeStorageProvider {
    shownNudges: IAsyncSmartStorageProvider<string[]>;

    static inject = () => [AsyncStorageProvider];
    constructor(private storage: AsyncStorageProvider) {
        this.shownNudges = this.storage.getJSONProvider('shown-nudges');
    }
}

type IDismissableNudgeBannerProps = {
    nudgeId: string;
    message: string;
    Icon?: IconComponent;
};

export const DismissableNudgeBanner: FC<IDismissableNudgeBannerProps> = ({
    nudgeId,
    message,
    Icon = LightbulbOutlineIcon
}) => {

    const classes = useStyles();

    const [visible, setVisible] = useState(false);
    const [hiding, setHiding] = useState(false);

    const storage = useInstance(NudgeStorageProvider);

    useEffect(() => {
        Promise.resolve().then(async () => {
            const shown = await storage.shownNudges.get() || [];
            if (!shown.includes(nudgeId)) {
                setVisible(true);
                await storage.shownNudges.set([...shown, nudgeId]);
            }
        });

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!visible) {
        return null;
    }

    const onDismiss = () => {
        setHiding(true);
        setTimeout(() => setVisible(false), 500);
    };

    return (
        <div className={`${classes.wrapper} ${hiding ? classes.hiding : ''}`}>
            <Badge color="success" size="small" expand="block">
                <div style={{display: 'flex', alignItems: 'center'}}>

                    <div style={{paddingLeft: 6, paddingRight: 12}}>
                        <Icon color="success" size="medium" />
                    </div>

                    <div style={{paddingTop: 8, paddingBottom: 12, flexGrow: 1}}>
                        {message}
                    </div>

                    <IonButton onClick={onDismiss} style={{alignSelf: 'start', '--padding-start': '2px', '--padding-end': '2px'}} color="medium" fill="clear" shape="round" size="small">
                        <IonIcon slot="icon-only" icon={close} />
                    </IonButton>

                </div>
            </Badge>
        </div>
    );
};
