import dayjs from 'dayjs';
import { State } from '@meraki-internal/state';
import { APIClient } from '@innerhive-internal/innerhive-api-client';
import {
    KEYS_WITH_MEMBERS,
    KEYS_WITH_NOTES,
    ICareMap,
    IContactMethod,
    IHouseholdMember,
    IKeyWithMembers,
    INewCareMap,
    IPatient,
    ISupportGroup,
    ISupportGroupKey,
    ISupportGroupMember,
    SUPPORT_GROUP_KEYS,
    IChecklist
} from './ICareMap';
import { CareMapFactory } from './CareMapFactory';
import { FlowDownloader } from './FlowDownloader';
import { maxHouseholdMembers } from '../nodes/NodeAdapter';
import { ILabelKey, LabelProvider } from '../config/LabelProvider';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { AppPrincipal } from '../auth/AppPrincipal';
import { INote } from '../notes/INote';
import { ICareMapNoteTarget } from '../notes/INoteTarget';
import { AsyncStorageProvider } from '../support/AsyncStorageProvider';
import { BufferedEventBus } from '../utils/BufferedEventBus';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { WelcomePageState } from '../QuestionnaireWelcomePage';
import { AlertPresenter } from '../AlertBinder';
import { EnvConfiguration } from '../config/EnvConfiguration';
import { RevenueCatModel } from '../innerhive-plus/revenue-cat/RevenueCatModel';
import { InnerhivePlusDialogViewModel } from '../innerhive-plus/InnerhivePlusDialogViewModel';
import { IOrganizationUser } from '../organizations/IOrganization';
import { OrganizationsState } from '../organizations/OrganizationsState';
import { WithLinks } from './WithLinks';
import { HoneycombConfigProvider } from '../nodes/HoneycombConfigProvider';

// At some point, we likely will want userIsPatient to be a more first class
// property on caremap... but for now, it is stored in the exiting
//   initialWizardAnswers['recipient'] as if they choose Myself
// I did this because it was expedient (making use of infrastructure already in place)
//
// It is encapsulated by CareMapState.isUserThePatient(): boolean
//
// and the const that follows encapsulates what the value should be

const CAREMAP_MEDIA_TYPE = 'application/vnd.innerhive.caremap.v2+json';

export const MYSELF = { value: 'myself', label: 'Myself' };

export interface ISupportGroupLabels {
    title: string;
    memberTitle: string;
    shortMemberTitle: string;
    memberNameLabel: string;
    roleLabel: string;
    rolePlaceholder: string;
};

type ISupportGroupMembersMap = {[key in ISupportGroupKey]: ISupportGroupMember[] | undefined};

export class CareMapState extends State<Record<string, never>> {
    static inject = () => [
        APIClient,
        CareMapFactory,
        FlowDownloader,
        LabelProvider,
        MixpanelService,
        AppPrincipal,
        AsyncStorageProvider,
        BufferedEventBus,
        HistoryViewModel,
        WelcomePageState,
        AlertPresenter,
        EnvConfiguration,
        RevenueCatModel,
        InnerhivePlusDialogViewModel,
        HoneycombConfigProvider
    ];

    constructor(
        private apiClient: APIClient,
        private factory: CareMapFactory,
        private flowDownloader: FlowDownloader,
        private labelProvider: LabelProvider,
        private tracking: MixpanelService,
        private principal: AppPrincipal,
        private storageProvider: AsyncStorageProvider,
        private events: BufferedEventBus,
        private history: HistoryViewModel,
        private welcomePageState: WelcomePageState,
        private alert: AlertPresenter,
        private envConfig: EnvConfiguration,
        private revenueCat: RevenueCatModel,
        private dialogVM: InnerhivePlusDialogViewModel,
        private honeycombConfigProvider: HoneycombConfigProvider
    ) {
        super({});

        // if the caremap is for the user, then set up labels
        // to use the MY_CAREMAP__ prefix when possible
        // so we can thing like ""...your caremap" instead of "...Lance's caremap"
        this.subscribe(() => {
            if (this.isUserThePatient() !== this.labelProvider.use_MY_CAREMAP__PrefixIfApplicable){
                this.labelProvider.use_MY_CAREMAP__PrefixIfApplicable = this.isUserThePatient();
            }
        });

        this.subscribe(() => {
            // HoneyCombProvider and CareMapState have a ciruclar dependency, so managing it here
            // so CareMapState depends on HoneyCombProvider
            // and HoneyCombProvider depends on activeCaremap
            this.honeycombConfigProvider.activeCaremap = this.getActiveCaremap();
        });

        this.events.on('UserState.deleted', () => {
            this.storageProvider.getStringProvider('current-caremap-id').remove();
        });
    }

    // injected at IOC due to circular reference
    _orgState!: OrganizationsState;

    private _activeCaremap?: ICareMap | INewCareMap;

    // hold all care maps and expose helper methods
    private careMaps: ICareMap[] = [];
    getCareMaps = (): ICareMap[] => {
        return this.careMaps;
    };

    getCareMapById = (careMapId: string) => {
        return this.careMaps.find(map => map.careMapId === careMapId);
    };

    getUnamedCareMap = (): ICareMap | undefined => {
        return this.careMaps.find(
            map => !map.essentialMapComplete && !map.patient.firstName && !map.patient.lastName
        );
    };

    // returns true if there is a caremap other than the current one that is MYSELF
    hasOtherCareMapForSelf = (): boolean => {
        const selfMap = this.careMaps.find(map => map.userId === this.principal.userId && map.initialWizardAnswers['recipient']?.value === MYSELF.value);
        return Boolean(selfMap && selfMap.careMapId !== this._activeCaremap?.careMapId);
    };

    hasCareMaps = (): boolean => {
        return this.getCareMaps().length > 0;
    };

    setActiveCaremap = async (careMapId: string) => {
        // if it is already active then do nothing
        // because otherwise we might stomp on changes in there
        // eg delete support group is waiting for its changes to save while redirecting
        if (this._activeCaremap?.careMapId === careMapId){
            return;
        }

        let map: ICareMap | INewCareMap | undefined = this.getCareMapById(careMapId);

        if (!map){
            const orgCaremapLite = this._orgState.getOrganizationCareMap(careMapId);

            if (orgCaremapLite?.links.self){
                map = await this.apiClient.get(orgCaremapLite?.links.self, CAREMAP_MEDIA_TYPE);
            }
        }

        if (!map) {
            const error: any = Error(`Care Map with ID ${careMapId} not found (or don't have access)`);
            error.errorCode = 'not-found';
            throw error;
        }

        this._activeCaremap = map;
        this.setState({ });

        this.storageProvider.getStringProvider('current-caremap-id').set(careMapId);
    };

    setActiveQuestionnaire = () => {
        let questionnaire: (ICareMap | INewCareMap) | undefined = this.careMaps.find(c => !c.essentialMapComplete);

        if (!questionnaire) {
            questionnaire = this.factory.createBlank();
        }

        this._activeCaremap = questionnaire;
        this.setState({});
    };

    unsetCurrentCareMap = async () => {
        this._activeCaremap = undefined;
        this.setState({ });
        await this.storageProvider.getStringProvider('current-caremap-id').remove();
    };

    // which caremap should be shown by default?
    getDefaultCaremapId = async (): Promise<string | undefined> => {
        let currCareMapId = await this.storageProvider.getStringProvider('current-caremap-id').get();
        if (!currCareMapId){
            currCareMapId = this.careMaps[0]?.careMapId;
        }
        return currCareMapId;
    };

    hasActiveCareMap = () => {
        return Boolean(this.getActiveCaremap());
    };

    getActiveCaremap = (): ICareMap | INewCareMap| undefined => {
        return this._activeCaremap;
    };

    hasCompletedCareMap = () => {
        return this.getCareMaps().some(map => map.essentialMapComplete === true);
    };

    getLabel = (labelName: ILabelKey, placeholders: any = {}) => {
        return this.labelProvider.getLabel(labelName, { careRecipient: this.getPatientFirstName(), ...placeholders });
    };

    hasStartedWizard = () => Boolean(this._activeCaremap?.careMapId);

    deleteAndRestart = async () => {
        if (this._activeCaremap?.links){
            await this.apiClient.delete(this._activeCaremap.links.self);
            window.location.hash = '';
            window.location.reload();
        }
    };

    loadWithoutActive = async () => {
        const entry = await this.apiClient.entry();
        this.careMaps = await this.apiClient.get(entry.links.careMaps, CAREMAP_MEDIA_TYPE);
        this.setState({});
    };

    private adaptCareMapForApi = (careMap: ICareMap | INewCareMap): ICareMap => {
        // remove photoDataURI b/c we never want to persist that to the API
        // set notes to empty array because those aren't saved by PUT Caremap

        const copy: ICareMap = JSON.parse(JSON.stringify(careMap));
        delete copy.patient.photoDataURI;
        copy.patient.notes = [];
        for (const section of KEYS_WITH_MEMBERS) {
            copy[section].notes = [];
            for (const member of copy[section].members){
                if (section === 'household') {
                    delete (member as IHouseholdMember).photoDataURI;
                }
                member.notes = [];
            }
        }
        return copy;
    };

    private savingPromise?: Promise<void>;
    private isSaveEnqueued = false;

    isSaved = () => !this.savingPromise && !this.isSaveEnqueued;

    enqueueSave = (): void => {
        // if there is a save in flight, then enqueue for when they are all done
        if (this.savingPromise){
            this.isSaveEnqueued = true;
        }
        // otherwise, do it now
        else {
            this.dequeueSave();
        }
    };

    enqueuedSaveError?: any;
    private enqueuedSaveErrorTimeoutId?: NodeJS.Timeout;

    private setEnqueuedSaveError = (err: any) => {
        this.enqueuedSaveError = err;

        // emit so consumers know to check enqueuedSaveError
        this.setState({});
    };

    private clearEnqueuedSaveError = () => {
        if (this.enqueuedSaveError){
            this.enqueuedSaveError = undefined;

            // emit so consumers know to check enqueuedSaveError
            this.setState({ });
        }
        if (this.enqueuedSaveErrorTimeoutId){
            clearTimeout(this.enqueuedSaveErrorTimeoutId);
        }
    };

    private dequeueSave = () => {
        // NOTE: maybe we shouldn't try and save while there is an error
        // or maybe the latest state would clear the error...
        // currently we still let it save b/c likely it will result in the same error
        // which does little harm, but it might clear the error

        this.isSaveEnqueued = false;

        // NOTE: important that we sync start a save, so that this.savingPromise gets set
        this.save().then(() => {
            this.clearEnqueuedSaveError();
        }).catch(err => {
            // if we were displaying a different error, that one doesn't matter anymore, display this one
            // and don't let that ones timeout clear this new one
            this.clearEnqueuedSaveError();

            this.setEnqueuedSaveError(err);

            // clear the error in a few seconds
            // note we do NOT clear the error on subsequent save attempts b/c that can happen before the user has a chance to see the error
            // we do clear the error on a successful save
            this.enqueuedSaveErrorTimeoutId = setTimeout(() => {
                this.enqueuedSaveError = undefined;
            }, 3000);
        });
    };

    private lastPatchedState?: string = undefined;

    save = async ({ isComplete }: { isComplete?: boolean } = {}): Promise<void> => {
        // if we already saving, then queue it up for when that one is done
        if (this.savingPromise){
            return this.savingPromise
                .catch(err => {
                    // ignore an error on the previous save, it is
                    // that callers responsibility to handle that failure
                })
                .then(() => this.save({ isComplete }));
        }

        this.savingPromise = Promise.resolve().then(async () => {
            try {
                const careMap = this.getActiveCaremap();
                if (!careMap){
                    // TODO: we should still save, but we no longer have the memory reference to save
                    return;
                }
                if (!careMap.careMapId) {
                    // this creates a copy of the body, so when we save this.lastPatchedState it reflects what we actually sent
                    const finalBody = this.adaptCareMapForApi(careMap);
                    const headers = {
                        accept: CAREMAP_MEDIA_TYPE,
                        'content-type': CAREMAP_MEDIA_TYPE
                    };

                    // API errors out otherwise
                    // we're only temporarily using to get the appropriate POST link
                    delete finalBody.links;

                    const entry = await this.apiClient.entry();
                    const result = await this.apiClient.post(
                        entry.links.careMaps,
                        finalBody,
                        headers
                    );

                    this.lastApiResponse = result;
                    this.lastPatchedState = JSON.stringify({ ...finalBody, version: undefined });

                    // add the new care map to our collection
                    this.careMaps.push(result);
                    this.setActiveCaremap(result.careMapId);
                } else {
                    const body = {
                        ...careMap,
                    };
                    if (isComplete){
                        body.essentialMapComplete = true;
                    }

                    // this creates a copy of the body, so when we save this.lastPatchedState it reflects what we actually sent
                    const finalBody = this.adaptCareMapForApi(body);

                    // don't save if nothing changed
                    if (this.lastPatchedState !== JSON.stringify({ ...finalBody, version: undefined })){
                        const result = await this.apiClient.put(
                            careMap,
                            finalBody,
                            {
                                accept: CAREMAP_MEDIA_TYPE,
                                'content-type': CAREMAP_MEDIA_TYPE
                            }
                        );

                        this.lastApiResponse = result;
                        this.lastPatchedState = JSON.stringify({ ...finalBody, version: undefined });

                        if (this._activeCaremap){
                            this._activeCaremap = {
                                ...this._activeCaremap!,
                                version: result.version,
                                essentialMapComplete: result.essentialMapComplete
                            };
                        }

                        this.setState({ });

                        // replace the care map in this.careMaps with the updated care map
                        this.careMaps = this.careMaps.map(map => result.careMapId === map.careMapId ? result : map);

                        // a care map was just completed, they can have innerhive+ and there's only one care map
                        if(isComplete && this.careMaps.length === 1 && !this.revenueCat.hasInnerhivePlus()) {
                            this.dialogVM.open({ triggerSource: 'first questionnaire completed'});
                        }
                    }
                }
            } catch (e: any) {
                if (e.status === 409) {
                    // TODO: throw new AppError from innerhive-api??
                    e.displayMessage = 'Sorry, this care map has already been updated. Please refresh and try again.';
                    throw e;
                }

                if (e.status === 404 && e.errorCode === 'caremap.deleted') {
                    const name = this.getPatientFirstName();
                    await this.alert.showAlertV2({
                        header: 'Care Map Deleted',
                        message: `Sorry... ${name ? `${name}'s` : 'This'} care map has been deleted in a different session, so you can no longer make changes.`
                    });

                    await this.afterDelete();
                    return;
                }

                throw e;
            }
        });

        return this.savingPromise.finally(() => {
            this.savingPromise = undefined;

            // if a save was enqueued, attempt to dequeue it
            if (this.isSaveEnqueued){
                this.dequeueSave();
            }
        });
    };

    delete = async () => {
        await this.apiClient.delete(this._activeCaremap?.links.self);

        const self = this._activeCaremap?.initialWizardAnswers['recipient-self'];
        this.tracking.track('Care Map Deleted', () => ({
            'Care Map State': this._activeCaremap?.essentialMapComplete ? 'completed' : 'questionnaire',
            'Care Recipient': self ? 'myself' : self === false ? 'someone else' : undefined
        }));

        await this.afterDelete();
    };

    private afterDelete = async () => {
        // delete the care map in this.careMaps and remove id from storage
        this.careMaps = this.careMaps.filter(map => this._activeCaremap?.careMapId !== map.careMapId);
        await this.storageProvider.getStringProvider('current-caremap-id').remove();

        if (!this.hasCareMaps()) {
            this.welcomePageState.setState({hasContinued: false});
        }

        this.history.replace('/');
    };

    updateHouseholdMembers = (members: IHouseholdMember[]) => {
        this._activeCaremap = {
            ...this._activeCaremap!,
            household: {
                ...this._activeCaremap!.household,
                members: members.map(member => ({
                    ...member,
                    contactMethods: this.cleanupContactMethods(member.contactMethods)
                })),
            }
        };
        this.setState({ });
        this.enqueueSave();
    };

    addHouseholdMember = (member: IHouseholdMember) => {
        console.log('ADD', member);

        try {
            if (this._activeCaremap!.household.members.length === maxHouseholdMembers) {
                this.tracking.track('Tried to Add Too Many Members', () => ({
                    category: 'household'
                }));
                const error: any = new Error(`cannot add more than ${maxHouseholdMembers} household members`);
                error.displayMessage = `Sorry, currently you cannot add more than ${maxHouseholdMembers} household members.`;
                throw error;
            }

            const members = [
                ...this._activeCaremap!.household.members,
                {
                    ...member,
                    contactMethods: this.cleanupContactMethods(member.contactMethods),
                }
            ];
            this._activeCaremap = {
                ...this._activeCaremap!,
                household: {
                    ...this._activeCaremap!.household,
                    members,
                }
            };
            this.setState({ });
            this.enqueueSave();

        } catch (err) {
            // caller assumes enqueueSave and therefore isn/t expecting to deal with errors directly
            this.setEnqueuedSaveError(err);
        }
    };

    removeHouseholdMember = (memberId: string) => {
        console.log('REMOVE', memberId);
        const members = this._activeCaremap!.household.members.filter(m => m.id !== memberId);
        this._activeCaremap = {
            ...this._activeCaremap!,
            household: {
                ...this._activeCaremap!.household,
                members
            }
        };
        this.setState({ });
        this.enqueueSave();
    };

    updatePatient = (patient: IPatient) => {
        this._activeCaremap = {
            ...this._activeCaremap!,
            patient: {
                ...patient,
                contactMethods: this.cleanupContactMethods(patient.contactMethods)
            }
        };
        this.setState({ });
        this.enqueueSave();
    };

    updateHouseholdMember = (member: IHouseholdMember) => {
        console.log('UPDATE', member);
        const idx = this._activeCaremap!.household.members.findIndex(item => item.id === member.id);

        this._activeCaremap!.household.members[idx] = {
            ...member,
            contactMethods: this.cleanupContactMethods(member.contactMethods)
        };

        this._activeCaremap = {
            ...this._activeCaremap!,
            household: {
                ...this._activeCaremap!.household,
                members: this._activeCaremap!.household.members
            }
        };

        this.setState({ });
        this.enqueueSave();
    };

    getHouseholdMember = (memberId: string): IHouseholdMember => {
        const member = this.maybeGetHouseholdMember(memberId);
        if (!member) {
            // this really should never happen
            throw new Error(`Member with memberId ${memberId} not found`);
        }
        return member;
    };

    maybeGetHouseholdMember = (memberId: string): IHouseholdMember | undefined => {
        return this.getCareMap().household.members.find(m => m.id === memberId);
    };

    answerInitialWizardQuestion = ({ questionId, answer }: { questionId: string; answer: any }) => {
        const initialWizardAnswers = this._activeCaremap!.initialWizardAnswers || {};

        this._activeCaremap = {
            ...this._activeCaremap!,
            initialWizardAnswers: {
                ...initialWizardAnswers,
                [questionId]: answer
            }
        };

        this.setState({ });
        this.enqueueSave();
    };

    isCommunityExtracurricularRelevant = () => {
        return Boolean(this._activeCaremap?.initialWizardAnswers['community']);
    };

    isCommunityAdvocacyRelevant = () => {
        return Boolean(this._activeCaremap?.initialWizardAnswers['community2']);
    };

    addChecklists = (supportGroupKey: ISupportGroupKey, checklists: {templateId: string, html: string}[]) => {
        // all or nothing. if there are ANY duplicates, no checklists are added
        for (const checklist of checklists) {
            if (checklist.templateId && this._activeCaremap![supportGroupKey].checklists.some((c: IChecklist) => c.templateId === checklist.templateId)){
                throw new Error(`a checklist for templateId ${checklist.templateId} already exists for Care Map ${this._activeCaremap!.careMapId}. You must remove it in order to add this template again.`);
                // why? b/c duplicating a checklist is almost certainly not what is wanted and merging a newer version
                // of the checklist with an older version of the checklist is out of scope (for the foreseeable future)
            }
        }

        for (const checklist of checklists) {
            this._activeCaremap![supportGroupKey].checklists.push(checklist);
        }

        this.enqueueSave();
    };

    getSupportGroup = (supportGroupKey: ISupportGroupKey) => {
        return this._activeCaremap![supportGroupKey];
    };

    addOrUpdateSupportGroup = ({ supportGroupKey, members = [] }: { supportGroupKey: ISupportGroupKey; members?: ISupportGroupMember[] }) => {
        const isAdd = !this._activeCaremap![supportGroupKey].isRelevant;

        const supportGroup = {
            ...this._activeCaremap![supportGroupKey]!,
            members,
            isRelevant: true
        };
        if (isAdd){
            supportGroup.isRelevantSince = new Date().toISOString();
        }
        this.setSupportGroupState(supportGroupKey, supportGroup);

        this.enqueueSave();

        if (isAdd) {
            this.events.emit('CareMapState.SupportGroupAdded', supportGroupKey);
        }
    };

    removeSupportGroup = ({ supportGroupKey }: { supportGroupKey: ISupportGroupKey }) => {
        if (this._activeCaremap![supportGroupKey].members.length > 0){
            throw new Error(`cannot remove ${supportGroupKey} it has members ${JSON.stringify(this._activeCaremap![supportGroupKey].members)}`);
        }
        this.setSupportGroupState(supportGroupKey, {
            // we aren't yet explicitly deleting their notes, in part because
            // we don't want to require them to delete their notes in order to get the delete button
            // like we do members.
            // By not actually deleting notes, they are recoverable when they add the group
            // and easy for the user to then delete them if they don't want them
            ...this._activeCaremap![supportGroupKey],

            // remove checklists
            checklists: [],

            // this is what actually causes it to delete / not show up anymore
            isRelevant: false,
            isRelevantSince: undefined
        });
        this.enqueueSave();
    };

    shouldShowNameFirst = (supportGroupKey: ISupportGroupKey) => {
        return (['community', 'financial'].includes(supportGroupKey));
    };

    getAllSupportGroupLabels = (): {[key in ISupportGroupKey]: ISupportGroupLabels} => {
        const labels: {[key in ISupportGroupKey]: ISupportGroupLabels} = {
            'community': {
                title: 'Community',
                memberTitle: 'Community Support',
                shortMemberTitle: 'Support',
                memberNameLabel: 'Support Name',
                roleLabel: this.labelProvider.getLabel('SUPPORT_GROUP__COMMUNITY__ROLE'),
                rolePlaceholder: 'Search or select support type'
            },
            'financial': {
                title: 'Financial & Legal',
                memberTitle: 'Support',
                shortMemberTitle: 'Support',
                memberNameLabel: this.labelProvider.getLabel('SUPPORT_GROUP__FINANCIAL__ROLE'),
                roleLabel: 'Support Type',
                rolePlaceholder: 'Search or select support type'
            },
            'school': {
                title: 'School',
                memberTitle: 'School Team Member',
                shortMemberTitle: 'Member',
                memberNameLabel: 'Name',
                roleLabel: this.labelProvider.getLabel('SUPPORT_GROUP__SCHOOL__ROLE'),
                rolePlaceholder: 'Search or select member'
            },
            'medical': {
                title: 'Primary Care',
                memberTitle: 'Care Team Member',
                shortMemberTitle: 'Member',
                memberNameLabel: 'Name',
                roleLabel: this.labelProvider.getLabel('SUPPORT_GROUP__MEDICAL__ROLE'),
                rolePlaceholder: 'Search or select member'
            },
            'specialists': {
                title: 'Specialists',
                memberTitle: 'Specialist',
                shortMemberTitle: 'Specialist',
                memberNameLabel: 'Name',
                roleLabel: this.labelProvider.getLabel('SUPPORT_GROUP__SPECIALISTS__ROLE'),
                rolePlaceholder: 'Search or select specialist'
            },
            'social': {
                // TODO:
                title: 'Social Title',
                memberTitle: 'Social Member Title',
                shortMemberTitle: 'Social MT',
                memberNameLabel: 'Name',
                roleLabel: this.labelProvider.getLabel('SUPPORT_GROUP__SOCIAL__ROLE'),
                rolePlaceholder: 'Search or select Social'
            }
        };

        return labels;
    };

    getSupportGroupLabels = (supportGroupKey: ISupportGroupKey): ISupportGroupLabels => {
        return this.getAllSupportGroupLabels()[supportGroupKey];
    };

    canDeleteSupportGroup = (supportGroupKey: ISupportGroupKey): boolean => {
        return this._activeCaremap?.links.self.actions.includes('delete-support-group')
            && this._activeCaremap[supportGroupKey].members.length === 0;
    };

    hasPermissionTo = (...operations:string[]) => {
        const allowed = this._activeCaremap?.links?.self?.actions || [];
        return operations.every(o => allowed.includes(o));
    };

    getNewSupportGroupMember = ({supportGroupKey, roleId}: {supportGroupKey: ISupportGroupKey, roleId?: string}): ISupportGroupMember => {
        return this.factory.createSupportGroupMember(supportGroupKey, roleId);
    };

    private cleanupContactMethods = (contactMethods: IContactMethod[]): IContactMethod[] => {
        // shouldn't have to coalesce to empty array, but wizard tests were failing without doing this
        return (contactMethods || []).filter(method => method.value);
    };

    addSupportGroupMember = (supportGroupKey: ISupportGroupKey, member: ISupportGroupMember) => {
        // caller assumes enqueueSave and therefore isn/t expecting to deal with errors directly
        Promise.resolve().then(() => {
            const maxHoneycombHexagons = this.honeycombConfigProvider.getMaxHoneycombHexagons();

            if (this._activeCaremap![supportGroupKey].members.length === maxHoneycombHexagons) {
                this.tracking.track('Tried to Add Too Many Members', () => ({
                    category: supportGroupKey
                }));
                const error: any = new Error(`cannot add more than ${maxHoneycombHexagons} members to the ${supportGroupKey} support group`);
                error.displayMessage = `Sorry, currently you cannot add more than ${maxHoneycombHexagons} supports to ${this.getSupportGroupLabels(supportGroupKey).title}`;
                throw error;
            }

            const members = [
                ...this._activeCaremap![supportGroupKey].members,
                {
                    ...member,
                    contactMethods: this.cleanupContactMethods(member.contactMethods)
                }
            ];

            if (!this.findSupportGroupMember({ supportGroupKey, memberId: member.id })){
                this.addOrUpdateSupportGroup({ supportGroupKey, members });
            }
        }).catch(err => {
            this.setEnqueuedSaveError(err);
        });
    };

    private setSupportGroupState = (supportGroupKey: ISupportGroupKey, state: ISupportGroup) => {
        this._activeCaremap = {
            ...this._activeCaremap!,
            [supportGroupKey]: state
        };
        this.setState({ });
    };

    findSupportGroupMember = ({supportGroupKey, memberId}: {supportGroupKey: ISupportGroupKey, memberId: string}): ISupportGroupMember | undefined => {
        const members = this._activeCaremap![supportGroupKey].members.filter(m => m.id === memberId);
        if (members.length > 1){
            throw new Error(`found ${members.length} with memberId ${memberId}`);
        }
        return members[0];
    };

    findSupportGroupMemberByRole = ({supportGroupKey, roleId}: {supportGroupKey: ISupportGroupKey, roleId: string | undefined}): ISupportGroupMember | undefined => {
        const members = this._activeCaremap ? this._activeCaremap[supportGroupKey].members.filter(m => m.roleId === roleId) : [];
        if (members.length > 1){
            console.warn(`found ${members.length} with role ${roleId}`);
        }
        return members[0];
    };

    updateSupportGroupMember = (supportGroupKey: ISupportGroupKey, member: ISupportGroupMember) => {
        console.log('UPDATE SUPPORT GROUP MEMBER', member);
        const members = this._activeCaremap![supportGroupKey].members;
        const idx = members.findIndex(item => item.id === member.id);

        members[idx] = {
            ...member,
            contactMethods: this.cleanupContactMethods(member.contactMethods)
        };

        this.setSupportGroupState(supportGroupKey, {
            ...this._activeCaremap![supportGroupKey],
            members,
        });
        this.enqueueSave();
    };

    private getIsRelevantWhenRemovingMemberWhileInWizard = ({ group, supportGroupKey }: {group: ISupportGroup; supportGroupKey: ISupportGroupKey; }): boolean => {
        if (group.members.length > 0){
            return true;
        }

        // if no members but answered yes to one of many is relevant questions
        if (supportGroupKey === 'community') {
            return this.isCommunityAdvocacyRelevant() || this.isCommunityExtracurricularRelevant();
        }

        // otherwise, removed last member, no longer relevant
        return false;
    };

    removeSupportGroupMember = ({ supportGroupKey, memberId }: {supportGroupKey: ISupportGroupKey; memberId: string; }) => {
        const group: ISupportGroup = this._activeCaremap![supportGroupKey];

        const updatedGroup: ISupportGroup = {
            ...group,
            members: group.members.filter(m => m.id !== memberId),
        };

        if (!this._activeCaremap?.essentialMapComplete){
            updatedGroup.isRelevant = this.getIsRelevantWhenRemovingMemberWhileInWizard({
                group: updatedGroup,
                supportGroupKey
            });
        }

        if (!updatedGroup.isRelevant) {
            updatedGroup.checklists = [];
        }

        this.setSupportGroupState(supportGroupKey, updatedGroup);

        this.enqueueSave();
    };

    getSupportGroupMembers = (supportGroupKey: ISupportGroupKey): ISupportGroupMember[] => {
        return this._activeCaremap![supportGroupKey].members;
    };

    // get all the members in all support groups from the care map
    getAllSupportGroupsMembers = (): ISupportGroupMember[] => {
        let members: ISupportGroupMember[] = [];

        SUPPORT_GROUP_KEYS.forEach(key => {
            members = members.concat(this._activeCaremap![key].members);
        });

        return members;
    };

    // get all the members in all support groups from the care map, as a map / json object
    getAllSupportGroupsMembersAsMap = (): Partial<ISupportGroupMembersMap> => {
        const members: Partial<ISupportGroupMembersMap> = {};

        SUPPORT_GROUP_KEYS.forEach(key => {
            members[key] = this._activeCaremap![key].members;
        });

        return members;
    };

    getSupportGroupMember = ({ supportGroupKey, memberId }: { supportGroupKey?: ISupportGroupKey, memberId: string }) => {
        let members: ISupportGroupMember[];

        if (supportGroupKey) {
            members = this.getCareMap()[supportGroupKey].members;
        } else {
            members = this.getAllSupportGroupsMembers();
        }

        return members.find(m => m.id === memberId);
    };

    findSupportGroupMemberCategory = (memberId: string | undefined): ISupportGroupKey | undefined => {
        let categoryId: ISupportGroupKey | undefined = undefined;
        const members = this.getAllSupportGroupsMembersAsMap();

        Object.keys(members).forEach(key => {
            if (members[key as ISupportGroupKey]?.find(member => member.id === memberId)) {
                categoryId = key as ISupportGroupKey;
            };
        });

        return categoryId;
    };

    getSupportGroupMemberInformation = (memberId: string | undefined) => {
        const member = this.getSupportGroupMember({memberId: memberId || ''});
        const categoryId = this.findSupportGroupMemberCategory(member?.id);
        const showNameFirst = this.shouldShowNameFirst(categoryId as ISupportGroupKey);
        return {member, categoryId, showNameFirst};
    };

    setPatientFirstName = (firstName: string) => {
        this._activeCaremap = {
            ...this._activeCaremap!,
            patient: { ...this._activeCaremap!.patient, firstName }
        };
        this.setState({ });
        this.enqueueSave();
    };

    isUserThePatient = (): boolean => {
        const isPatientTheOwner = this._activeCaremap?.initialWizardAnswers['recipient']?.value === MYSELF.value;

        // if the caremap doesn't have a userId yet (because it is in the process of being created)
        // then this user owns it
        const ownerUserId = (this._activeCaremap as ICareMap | undefined)?.userId || this.principal.userId;

        const isUserTheOwner = ownerUserId  === this.principal.userId;

        return isPatientTheOwner && isUserTheOwner;
    };

    getPatientFirstName = (careMap?: ICareMap): string => {
        return (careMap ? careMap.patient.firstName : this._activeCaremap ? this._activeCaremap.patient.firstName : '') || '';
    };

    setPatientLastName = (lastName: string) => {
        this._activeCaremap = {
            ...this._activeCaremap!,
            patient: { ...this._activeCaremap!.patient, lastName }
        };
        this.setState({ });
        this.enqueueSave();
    };

    getPatientLastName = (careMap?: ICareMap): string => {
        return (careMap ? careMap.patient.lastName : this._activeCaremap ? this._activeCaremap.patient.lastName : '') || '';
    };

    getPatientFullName = (careMap?: ICareMap): string => {
        const first = this.getPatientFirstName(careMap);
        const last = this.getPatientLastName(careMap);
        return first + (first && last ? ' ' : '') + last;
    };

    setPatientDOB = (dob: string) => {
        const dayjsDob = dayjs(dob);

        if (!dayjsDob.isValid() && dob !== '') {
            throw new Error('Invalid date for patient DOB');
        }

        this._activeCaremap = {
            ...this._activeCaremap!,
            patient: { ...this._activeCaremap!.patient, dob }
        };
        this.setState({});
        this.enqueueSave();
    };

    getPatientDOB = (): string => {
        return this._activeCaremap!.patient.dob || '';
    };

    uploadFile = async (file: File) => {
        const entry = await this.apiClient.entry();
        const { uploadUrl, cdnUrl, headers } = await this.apiClient.post(entry.links.uploadsV2, { fileName: file.name });

        const res = await window.fetch(uploadUrl, {
            method: 'PUT',
            body: file,
            headers,
        });
        if (res.status !== 200){
            throw new Error(`Upload failed with status ${res.status}`);
        }

        return { url: cdnUrl };
    };

    download = async () => {
        const filename = `${this.getPatientFullName()} Care Map ${dayjs().format('YYYY-MM-DD')}.png`;

        await this.flowDownloader.downloadFlowAsPNG({ flowName: 'full', filename });
    };

    createViewerAccessToken = async (days: number): Promise<{ access_token: string; session_token: string; }> => {
        return await this.apiClient.post(this._activeCaremap!.links.createViewerAccessToken, {
            expiresISO8601: dayjs().add(days, 'days').toISOString()
        });
    };

    getCoOwnerLink = async ({ inviteeName }: { inviteeName: string | undefined; }) => {
        const { token } = await this.apiClient.post(this._activeCaremap!.links.invites, {name: inviteeName});
        return `${this.envConfig['WEB_BASE_URL']}/#/accept-invite?invite=${encodeURI(token)}`;
    };

    // internal use
    copy = {
        out: () => console.log(JSON.stringify({
            ...this.adaptCareMapForApi(this._activeCaremap!),
            careMapId: undefined,
            userId: undefined,
            links: undefined,
            version: undefined
        })),
        in: async (incomingCareMap: any) => {
            if (!incomingCareMap){
                throw new Error('incomingCareMap required');
            }
            const { careMapId, links, version } = (this._activeCaremap as any) || {};

            this._activeCaremap = { ...incomingCareMap, careMapId, links, version };
            this.setState({ });
            await this.save();

            window.location.reload();
        }
    };

    // internal use
    duplicateCareMap = async () => {
        const duplicate = {
            ...this.adaptCareMapForApi(this._activeCaremap!),
            careMapId: undefined,
            version: undefined,
            links: undefined
        };
        this._activeCaremap = duplicate;

        this.setState({});
        await this.save();
        window.location.reload();
    };

    updateNotes = async (note: WithLinks<INote>) => {
        const link = note.links.self;

        const { version: newVersion } = await this.apiClient.put(link, {
            ...note,
            version: (this.getActiveCaremap()! as ICareMap).version
        });

        this.setVersion(newVersion);
    };

    deleteNotes = async (note: WithLinks<INote>) => {
        const link = note.links.delete;

        const { version: newVersion } = await this.apiClient.delete(link, {
            version: (this.getActiveCaremap()! as ICareMap).version
        });

        this.setVersion(newVersion);
    };

    localUpdateNotes = ({ note, to }: { note: INote; to: ICareMapNoteTarget; }) => {
        let notes: INote[] = [];

        if (KEYS_WITH_MEMBERS.includes(to.section as IKeyWithMembers) && to.memberId){
            const member = this._activeCaremap![to.section as IKeyWithMembers].members.find((mem: IHouseholdMember | ISupportGroupMember) => mem.id === to.memberId);
            if (!member){
                throw new Error(`member with id ${to.memberId} not found`);
            }
            notes = member.notes;
        }
        else if (KEYS_WITH_NOTES.includes(to.section)){
            notes = this._activeCaremap![to.section].notes;
        }
        else {
            throw new Error('not supported');
        }

        // api will set the date for real, but we need it client side
        const updatedAt = (new Date()).toISOString();
        const noteFromState = notes.find(n => n.noteId === note.noteId);
        if (!noteFromState){
            note.updatedAt = updatedAt;
            notes.push(note);
        } else {
            noteFromState.updatedAt = updatedAt;
            noteFromState.title = note.title;
            noteFromState.html = note.html;
            noteFromState.isPrivate = note.isPrivate;
            noteFromState.attachments = note.attachments;
        }

        // we mutated state, let subscribers know
        this.setState({});
    };

    localDeleteNote = ({ note, to }: { note: INote; to: ICareMapNoteTarget; }) => {
        if (KEYS_WITH_MEMBERS.includes(to.section as IKeyWithMembers) && to.memberId){
            const member = this._activeCaremap![to.section as IKeyWithMembers].members.find((mem: IHouseholdMember | ISupportGroupMember) => mem.id === to.memberId);
            if (!member){
                throw new Error(`member with id ${to.memberId} not found`);
            }
            member.notes = member.notes.filter(n => n.noteId !== note.noteId);
        }
        else if (KEYS_WITH_NOTES.includes(to.section)){
            const section = this._activeCaremap![to.section];
            section.notes = section.notes.filter(n => n.noteId !== note.noteId);
        }
        else {
            throw new Error('not supported');
        }

        // we mutated state, let subscribers know
        this.setState({});
    };

    setVersion = (version: number) => {
        this._activeCaremap = {
            ...this._activeCaremap!,
            version
        };
        this.setState({});

        // a new version means lastApiResponse is out of date, so side-effect updating it
        this.apiClient.get(this._activeCaremap!.links.self, CAREMAP_MEDIA_TYPE).then(cm => {
            this.lastApiResponse = cm;
        }).catch(err => {
            this.enqueuedSaveError(err);
        });
    };

    private lastApiResponse?: ICareMap;

    // everything should use this in the future, but will need to be careful
    // how and when because we have things that mutate state
    getCareMap = (): ICareMap => {
        if (!this.lastApiResponse){
            return this._activeCaremap! as ICareMap;
        }

        const copy: ICareMap = {
            // this copy is redundant, b/c POST / PUT are already copying in version and root links
            // but it should be happening here, so doing it here to make it easier to remove from there, eventually
            ...this.lastApiResponse,
            ...this._activeCaremap!,
        };

        const apiResponse = this.lastApiResponse;
        const state = this._activeCaremap!;

        const nodesToCopyFromAPI = KEYS_WITH_NOTES;
        for (const key of nodesToCopyFromAPI){
            copy[key] = {
                ...apiResponse[key],
                ...state[key],
                notes: this.mergeNotes({
                    apiResponse: apiResponse[key].notes as WithLinks<INote>[] | undefined,
                    state: state[key].notes
                })
            } as any;
        }

        const nodesWithMembersToCopyFromAPI = KEYS_WITH_MEMBERS;
        for (const key of nodesWithMembersToCopyFromAPI){
            copy[key].members = copy[key].members.map(stateMember => {
                const apiMember = apiResponse[key].members.find(mem => mem.id === stateMember.id);
                return {
                    ...apiMember,
                    ...stateMember,
                    notes: this.mergeNotes({
                        apiResponse: apiMember?.notes as WithLinks<INote>[],
                        state: stateMember.notes
                    })
                };
            });
        }

        return copy as ICareMap;
    };

    getAttachmentCount = () => {
        if (!this._activeCaremap) {
            throw Error('Care map state not loaded');
        }

        let count = 0;

        const getCountForNotes = (notes: INote[]) => notes.reduce((total, note) => total + note.attachments.length, 0);

        for (const key of KEYS_WITH_NOTES) {
            count += getCountForNotes(this._activeCaremap[key].notes);
        }

        for (const key of KEYS_WITH_MEMBERS) {
            for (const member of this._activeCaremap[key].members) {
                count += getCountForNotes(member.notes);
            }
        }

        return count;
    };

    private mergeNotes = ({ apiResponse, state }: { apiResponse?: WithLinks<INote>[], state: INote[] }) => {
        // local notes plus specific attributes from matching api notes (if there is one)
        // ignore if there is an api one that the client doesn't know about yet
        return state.map(stateNote => {
            const apiNote = apiResponse?.find(n => n.noteId === stateNote.noteId);
            if (!apiNote) {
                return stateNote;
            }
            const {
                createdAt,
                createdBy,
                updatedAt,
                updatedBy,
                deletedAt,
                deletedBy,
                links
            } = apiNote;
            return {
                ...stateNote,
                createdAt,
                createdBy,
                updatedAt,
                updatedBy,
                deletedAt,
                deletedBy,
                links
            };
        });
    };

    addCaremapToClientUser = async (user: WithLinks<IOrganizationUser>) => {
        const headers = {
            accept: CAREMAP_MEDIA_TYPE,
            'content-type': CAREMAP_MEDIA_TYPE
        };
        const body = this.factory.createBlank();
        body.initialWizardAnswers['recipient']  = {value: 'myself', label: 'Myself'};
        body.initialWizardAnswers['recipient-self'] = true;
        body.initialWizardAnswers['skip-steps'] = [
            'my-name', 'name',
            'recipient-self', 'recipient'
        ];

        body.patient.firstName = user.firstName;
        body.patient.lastName = user.lastName;

        const result = await this.apiClient.post(user.links.caremaps, body, headers);
        this.careMaps = [
            ...(this.careMaps || []), result
        ];

        return { careMapId: result.careMapId };
    };

    addNewCaremapV2 = async () => {
        const headers = {
            accept: CAREMAP_MEDIA_TYPE,
            'content-type': CAREMAP_MEDIA_TYPE
        };
        const body = this.factory.createBlank();

        const entry = await this.apiClient.entry();

        const result = await this.apiClient.post(entry.links.careMaps, body, headers);
        this.careMaps = [
            ...(this.careMaps || []), result
        ];

        return { careMapId: result.careMapId };
    };
};
