import { HTMLIonOverlayElement } from '@ionic/core';

type IOverlayElement = HTMLIonOverlayElement & {
    excludeAutoDismiss?: boolean;
};

// adjust selector to fit your needs
// add excludeAutoDismiss to your modal for it to be ignored / not auto dismissed
export const useDismissOverlays = (
    selector = 'ion-alert, ion-action-sheet, ion-loading, ion-modal, ion-picker, ion-popover, ion-toast'
) => {
    return () => {
        const overlays = document.querySelectorAll(selector);
        if (overlays.length) {
            const overlaysArr = Array.from(overlays) as IOverlayElement[];
            overlaysArr.forEach((o: IOverlayElement) => {
                if (!o.excludeAutoDismiss) {
                    o.dismiss();
                }
            });
        }
    };
};
