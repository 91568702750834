import { FC, ReactNode } from 'react';
import { IonText } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { IThemeColor } from '../theme/IThemeColor';

const useStyles = createUseStyles({
    title: {
        display: 'block',
        fontSize: 18,
        fontWeight: 600
    },
    subtitle: {
        display: 'block',
        fontWeight: 600
    },
    detail: {
        display: 'block'
    },
    normal: {
        fontSize: 16, // .card-content-md makes font size 0.875rem
        display: 'block'
    }
});

export interface ITextProps {
    'data-type'?: string;
    type?: 'title' | 'subtitle' | 'detail' | 'normal';
    ellipsize?: boolean;
    children: ReactNode;
    color?: IThemeColor;
};

export const Text: FC<ITextProps> = ({
    'data-type': dataType,
    type = 'normal',
    ellipsize = false,
    children,
    color
}) => {
    const classes = useStyles();

    // if no color prop provided, override default text color to a lighter gray for subtitle/detail
    const styleColor = !color && (type === 'subtitle' || type === 'detail') ? 'var(--ion-color-step-750)' : undefined;

    const ellipsisClass = (ellipsize ? 'ellipsis' : '');

    return (
        <IonText
            data-type={dataType}
            color={color}
            className={`${classes[type]} ${ellipsisClass}`}
            style={{color: styleColor}}
        >
            {children}
        </IonText>
    );
};
