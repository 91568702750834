import './support/sentry'; // do this first so we catch all errors
import { createRoot } from 'react-dom/client';
import { App } from './App';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isToday from 'dayjs/plugin/isToday';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(customParseFormat);

const container = document.getElementById('root');
const root = createRoot(container!);

// TODO: try to reinstate <React.StrictMode> without
// it causing a re-render on every unhandled error
// (even when it's not in our code!)

root.render(
    <App />
);
