import { ISmartStorageProvider, LocalStorageProvider } from '../support/LocalStorageProvider';

export class LocalStorageLastCodeVerifierProvider {
    private codeVerifierStorage: ISmartStorageProvider<string>;

    static inject = () => [LocalStorageProvider];
    constructor(storage: LocalStorageProvider) {
        this.codeVerifierStorage = storage.getStringProvider('PKCE.code_verifier', { userNeutral: true });
    }

    store = ({ code_verifier }: { code_verifier: string }) => {
        this.codeVerifierStorage.set(code_verifier);
    };

    getLastVerifier = (): { code_verifier?: string } => {
        const code_verifier = this.codeVerifierStorage.get();
        return { code_verifier };
    };
}
