import {
    getRectOfNodes,
    getTransformForBounds,
    ReactFlowInstance
} from 'reactflow';
import { toPng } from 'html-to-image';
import UAParser from 'ua-parser-js';

const IS_SAFARI = new UAParser().getBrowser().name === 'Safari';

export class FlowDownloader {
    private flows: { [name: string]: ReactFlowInstance } = {};
    addReactFlow = (name: string, flow: ReactFlowInstance) => {
        this.flows[name] = flow;
    };

    downloadFlowAsPNG = async ({ flowName, filename }: { flowName: string; filename: string; }): Promise<void> => {
        // if we continue to see issues, we may also want to try https://github.com/cburgmer/rasterizeHTML.js

        // we calculate a transform for the nodes so that all nodes are visible
        // we then overwrite the transform of the `.react-flow__viewport` element
        // with the style option of the html-to-image library

        const dataUrl =  await this.getPngDataURLEvenOnSafari(flowName);

        const a = document.createElement('a');
        a.setAttribute('download', filename);
        a.setAttribute('href', dataUrl);
        a.click();

        // wait a couple seconds for download to be done
        // TODO: consider finding a way to actually know when it's done
        return new Promise(res => setTimeout(res, 2000));
    };

    private getPngDataURLEvenOnSafari = async (flowName: string) => {
        const countForLikelyImagesDownloaded = this.hasSVGImageOnLoadBug() ? 3 : 1;
        let countWithConsistentLength = 0;

        let lastLength: number | undefined = undefined;

        while (true) {
            console.log('attempting');
            const dataUrl = await this.getPngDataURL(flowName);
            if (dataUrl.length === lastLength){
                countWithConsistentLength++;
            }
            else {
                countWithConsistentLength = 1;
            }

            if (countWithConsistentLength >= countForLikelyImagesDownloaded){
                return dataUrl;
            }
            else {
                lastLength = dataUrl.length;
            }
        }
    };

    private hasSVGImageOnLoadBug = () => {
        return IS_SAFARI;
    };

    private getPngDataURL = async (flowName: string) => {
        const imageWidth = 1024;
        const imageHeight = 768;

        const reactFlowInstance = this.flows[flowName];
        if (!reactFlowInstance){
            throw new Error(`flow ${flowName} does not exist. Maybe you tried to download before the flow rendered?`);
        }

        const nodesBounds = getRectOfNodes(reactFlowInstance.getNodes());
        const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);

        const viewport = (reactFlowInstance as any).innerHiveEl.querySelector('.react-flow__viewport') as HTMLElement;
        const dataUrl = await toPng(viewport, {
            cacheBust: false,
            backgroundColor: 'white',
            width: imageWidth,
            height: imageHeight,
            style: {
                width: String(imageWidth),
                height: String(imageHeight),
                transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
            }
        });
        return dataUrl;
    };
}
