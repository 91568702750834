import { ReactNode } from 'react';
import { Text } from '../components/Text';

export const CardHeading: React.FC<{children: ReactNode}> = ({ children }) => {
    return (
        <div style={{marginBottom: 18}}>
            <Text>
                <b>{children}</b>
            </Text>
        </div>
    );
};
