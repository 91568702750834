import { useEffect, useRef } from 'react';
import { ActionPerformed, PushNotificationSchema,PushNotifications, Token } from '@capacitor/push-notifications';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Logger } from '../support/Logger';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { DeviceInfo } from '../support/DeviceInfo';
import { APIClient } from '@innerhive-internal/innerhive-api-client';
import { MixPanelEventEmitter } from '../metrics/MixPanelEventEmitter';

interface IAPPushNotificationExtras {
    // eg /insurance
    goto?: string;

    // this gets sent to mix panel
    campaignId?: string;
}

export const PushNotificationListener: React.FC = () => {

    const logger = useInstance(Logger);
    const mixpanel = useInstance(MixPanelEventEmitter);

    const history = useInstance(HistoryViewModel);

    const deviceInfo = useInstance(DeviceInfo);

    const apiClient = useInstance(APIClient);

    const registrationErrors = useRef<string[]>([]);

    // save device token to dynamo
    const persistTokenWithRetry = async (token: string, attempt = 1) => {
        try {
            const entry = await apiClient.entry();
            const { name, manufacturer, model } = deviceInfo;
            const device = { token, description: `${name}/${manufacturer}/${model}` };
            await apiClient.put(entry.links.pushNotificationDevices, device);
            logger.info('Persisted device token');
        } catch (e: any) {
            if (attempt < 5) {
                logger.info('Retrying persist device token: ' + e.message);
                setTimeout(() => persistTokenWithRetry(token, attempt + 1), 10000);
            } else {
                e.message = `Failed to persist device token after 5 attempts: ${e.message}`;
                logger.error(e);
            }
        }
    };

    useEffect(() => {

        if (!['ios', 'android'].includes(deviceInfo.platform)) {
            // capacitor plugin doesn't support web
            return;
        }

        PushNotifications.addListener('registration',
            async (token: Token) => {
                logger.info('Push notifications registration success, token: ' + token.value);
                registrationErrors.current = [];

                // save device token to dynamo
                await persistTokenWithRetry(token.value);
            }
        );

        PushNotifications.addListener('registrationError',
            ({ error }: { error: string }) => {
                registrationErrors.current.push(error);

                if (registrationErrors.current.length < 5) {
                    logger.info('Retrying push notification registration: ' + error);
                    setTimeout(PushNotifications.register, 10000);
                } else {
                    logger.error(`Push notifications registration failed ${registrationErrors.current.length} times. Errors: ${JSON.stringify(registrationErrors.current)}`);
                }

            }
        );

        // invoked when a notification is received while the app is in the foreground
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                logger.info('Push notification received in app: ' + JSON.stringify(notification));

                // current doing nothing here
                // in future, might consider showing toast
            }
        );

        // invoked when user taps on ios notification while app is in the background or closed
        PushNotifications.addListener('pushNotificationActionPerformed',
            (action: ActionPerformed) => {
                logger.info('Push notification clicked outside app: ' + JSON.stringify(action.notification));

                const { title, body, data } = action.notification;
                const { goto, campaignId } = data as IAPPushNotificationExtras;
                mixpanel.track('Push Notification Opened', () => ({ title, body, goto, campaignId }));

                if (goto) {
                    history.push(goto);
                }
            }
        );

        // on android, we need a high-importance channel, so we can send
        // notifications that pop up and show on lock screen
        if (deviceInfo.platform === 'android') {
            PushNotifications.listChannels().then(result => {
                if (!result.channels?.find(c => c.id === 'high-importance')) {
                    PushNotifications.createChannel({
                        id: 'high-importance',
                        name: 'High Priority',
                        importance: 5
                    });
                }
            });
        }

        // always trigger registration on app start to be sure we get the latest token in the listener below,
        // if user has not yet granted permission, expect this to fail
        PushNotifications.register();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
};
