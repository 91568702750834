import dayjs from 'dayjs';
import { State } from '@meraki-internal/state';
import { IAppointment } from './IAppointment';
import { APIClient } from '@innerhive-internal/innerhive-api-client';
import { CareMapState } from '../caremap/CareMapState';
import { ILink } from '../caremap/WithLinks';
import { MixPanelEventEmitter } from '../metrics/MixPanelEventEmitter';
import { AppPrincipal, isCaremapViewer } from '../auth/AppPrincipal';
import { ICareMapSection, ISupportGroupKey } from '../caremap/ICareMap';

export const NEW_APPOINTMENT_ID = 'add-appointment';

const MEDIA_TYPE = 'application/vnd.innerhive.appointment.v1+json';

type IAppointmentsState = {
    appointments: IAppointment[];
    currentAppointment: IAppointment | undefined;
    loaded: boolean;
    icsUrl: string;
};

// FUTURE: [school] | [school] member etc...
export type IAppointmentTrackingFrom = ISupportGroupKey | 'care recipient' | 'community member' | 'financial member' | 'school member' | 'medical member' | 'specialists member' | 'social member';

export const DEFAULT_APPOINTMENT_SECTION: ICareMapSection = 'patient';

const defaultState = {
    appointments: [],
    currentAppointment: undefined,
    loaded: false,
    icsUrl: ''
};

export class AppointmentsState extends State<IAppointmentsState> {

    private careMapId?: string;
    private appointmentsLink?: ILink;

    static inject = () => [APIClient, CareMapState, MixPanelEventEmitter, AppPrincipal];
    constructor(
        private apiClient: APIClient,
        private careMapState: CareMapState,
        private mixpanel: MixPanelEventEmitter,
        private principal: AppPrincipal
    ) {
        super(defaultState);

        careMapState.subscribe(() => {
            const careMap = careMapState.getActiveCaremap();

            if (careMap && careMap.careMapId !== this.careMapId) {
                this.careMapId = careMap.careMapId;
                if (careMap.links && careMap.links.appointments) {
                    this.appointmentsLink = careMap.links.appointments;
                    this.load();
                }
            }
        });
    };

    getCareMapId = () => {
        return this.careMapId;
    };

    private load = async () => {
        const appointments = await this.apiClient.get(this.appointmentsLink, MEDIA_TYPE);
        this.setState({ appointments, loaded: true });
    };

    loadIcsUrl = async () => {
        if (!this.state.icsUrl){
            const caremap = this.careMapState.getActiveCaremap();
            if (caremap?.links && caremap?.links.calendarICS) {
                const { url } = await this.apiClient.post(caremap!.links.calendarICS, {});
                this.setState({ icsUrl: url });
            }
        }
    };

    private findAppointment = (appointmentId: string): IAppointment => {
        const appointment = this.state.appointments.find(appt => appt.appointmentId === appointmentId);

        if (appointment === undefined) {
            throw new Error(`Appointment with id ${appointmentId} was not found`);
        }

        return appointment;
    };

    canView = () => Boolean(isCaremapViewer(this.principal) || this.appointmentsLink?.actions?.includes('get-appointments'));

    canAdd = () => Boolean(!isCaremapViewer(this.principal) && this.appointmentsLink?.actions?.includes('post-appointment'));

    canEdit = () => Boolean(!isCaremapViewer(this.principal) && this.state?.currentAppointment?.links?.self);

    canDelete = () => Boolean(!isCaremapViewer(this.principal) && this.state?.currentAppointment?.links?.delete);

    addAppointment = () => {
        const start = dayjs(new Date()).add(1, 'hour').minute(0);
        const currentAppointment: IAppointment = {
            appointmentId: NEW_APPOINTMENT_ID,
            section: DEFAULT_APPOINTMENT_SECTION,
            title: '',
            start: start.toISOString(),
            end: start.add(1, 'hour').toISOString(),
            links: {},
        };

        this.setState({ currentAppointment });
    };

    setCurrentAppointment = (appointmentId: string) => {
        const currentAppointment = this.findAppointment(appointmentId);
        this.setState({ currentAppointment });
    };

    updateCurrentAppointment = (changes: Partial<IAppointment>) => {
        if (!this.state.currentAppointment){
            throw new Error('there is no currentAppointment to update');
        }

        this.setState({
            currentAppointment: {
                ...this.state.currentAppointment,
                ...changes
            }
        });
    };

    isCurrentAppointmentValid = (): boolean => {
        const appt = this.state.currentAppointment;
        return appt?.title !== '' && appt?.start !== undefined && appt.end !== undefined;
    };

    clearCurrentAppointment = () => {
        this.setState({ currentAppointment: undefined });
    };

    trackNewAppointmentOpened = ({supportGroupKey, memberId, from}: {supportGroupKey?: ISupportGroupKey, memberId?: string, from?: string}) => {
        this.mixpanel.track('Add Appointment Opened', () => ({
            'Opened From': from || (`${supportGroupKey ? supportGroupKey : ''}${memberId ? ' member' : ''}`) || 'care recipient'
        }));
    };

    getTrackingMeta = (association?: IAppointmentTrackingFrom) => {
        const currentAppointment = this.state.currentAppointment;

        const supportMemberInfo = this.careMapState.getSupportGroupMemberInformation(currentAppointment?.supportMemberId);
        if (!association && supportMemberInfo.categoryId) {
            association = `${supportMemberInfo.categoryId} member`;
        }

        let hasOrganization = undefined;
        if (supportMemberInfo && supportMemberInfo.member) {
            hasOrganization =  supportMemberInfo.member.organization ? 'Yes' : 'No';
        }

        return {association: association || 'care recipient', hasOrganization};
    };

    saveCurrentAppointment = async (from: IAppointmentTrackingFrom | 'menu', association?: IAppointmentTrackingFrom) => {
        const currentAppointment = this.state.currentAppointment;
        const isAdd = currentAppointment?.appointmentId === NEW_APPOINTMENT_ID;
        const trackingInfo = this.getTrackingMeta(association);

        const meta: any = {
            'Association': trackingInfo.association,
            'Has Description': currentAppointment?.description ? 'Yes': 'No',
            'Has Location': currentAppointment?.location ? 'Yes' : 'No',
            'Has Organization':  trackingInfo.hasOrganization
        };

        if (isAdd) {
            meta['Added From'] = from;
        }

        this.mixpanel.track(`Calendar Event ${isAdd ? 'Added' : 'Edited'}`, () => meta);

        if (!currentAppointment){
            throw new Error('there is no currentAppointment to save');
        }

        if (isAdd) {
            const { appointmentId, ...newAppointment } = currentAppointment;
            const appointment = await this.apiClient.post(this.appointmentsLink, newAppointment, { accept: MEDIA_TYPE, 'content-type': MEDIA_TYPE });

            this.setState({
                appointments: [ ...this.state.appointments, appointment ],
                currentAppointment: appointment
            });
        } else {
            const updated = await this.apiClient.put(currentAppointment, currentAppointment, { accept: MEDIA_TYPE, 'content-type': MEDIA_TYPE });

            this.setState({
                appointments: this.state.appointments.map(a => a.appointmentId === updated.appointmentId ? updated : a),
                currentAppointment: updated
            });
        }
    };

    deleteCurrentAppointment = async (from: IAppointmentTrackingFrom) => {
        const currentAppointment = this.state.currentAppointment;
        const trackingInfo = this.getTrackingMeta();

        this.mixpanel.track('Calendar Event Deleted', () => ({
            'Association': trackingInfo.association,
            'Has Description': currentAppointment?.description ? 'Yes': 'No',
            'Has Location': currentAppointment?.location ? 'Yes' : 'No',
            'Has Organization':  trackingInfo.hasOrganization
        }));

        if (!currentAppointment){
            throw new Error('there is no currentAppointment to delete');
        }

        if (!currentAppointment.links?.delete){
            throw new Error('currentAppointment has no delete link');
        }

        await this.apiClient.delete(currentAppointment.links.delete, { version: currentAppointment.version });

        this.setState({
            appointments: this.state.appointments.filter(a => a.appointmentId !== currentAppointment.appointmentId),
            currentAppointment: undefined
        });
    };

    getAppointmentsForSupportMember = (memberId: string) => {
        return this.state.appointments.filter(appt => appt.supportMemberId === memberId);
    };

    getAppointmentsForSupportGroup = (groupKey: ISupportGroupKey) => {
        return this.state.appointments.filter(appt => appt.section === groupKey);
    };
};
