import { useInstance } from '@meraki-internal/react-dependency-injection';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { Route, matchPath } from 'react-router-dom';
import { CareMapState } from './CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { HEADER_HEIGHT, HEADER_Z_INDEX } from '../AppHeader';
import { useEffect, useRef, useState } from 'react';
import { AvatarFormState } from '../avatar/AvatarFormState';
import { FocusedCareMap, IFocusedCareMapAnimationOrigin } from './FocusedCareMap';
import { useBreakpoints } from '../theme/useBreakpoints';
import { Drawer, FINAL_DRAWER_WIDTH } from '../components/Drawer';
import { CareMapDrawerState } from './CareMapDrawerState';
import { DrawerState } from '../components/DrawerState';

// managed by ion-menu
const DRAWER_Z_INDEX = 1000;

// has to be > DRAWER_Z_INDEX or else ion-menu will intercept all clicks
const FOCUSED_CARE_MAP_Z_INDEX = DRAWER_Z_INDEX + 1;

export interface ICareMapDrawerProps {
    basePath: string;
    children: React.ReactNode;
};

export const CareMapDrawer: React.FC<ICareMapDrawerProps> = ({ basePath, children }) => {
    const [animateFocusedMap, setAnimateFocusedMap] = useState<boolean>(true);

    const drawerState = useInstance(CareMapDrawerState);
    useSubscription(() => drawerState);

    const history = useInstance(HistoryViewModel);

    const careMapState = useInstance(CareMapState);
    useSubscription(() => careMapState);
    const avatarState = useInstance(AvatarFormState);
    useSubscription(() => avatarState);

    const { isAboveBreakpoint, isBelowBreakpoint } = useBreakpoints();

    const drawerRef = useRef<HTMLIonMenuElement>(null);

    const rawDrawerState = useInstance(DrawerState);
    useEffect(() => {
        if (drawerRef.current){
            rawDrawerState.setIonMenu(drawerRef.current);
        }
    }, [drawerRef, rawDrawerState]);

    const onClose = () => {
        setAnimateFocusedMap(false);
    };

    const closeDrawer = () => {
        drawerRef.current!.close();
    };

    // keep selected member in sync with the route
    // (see corresponding code in CareMapDrawerRouter to keep route in sync with selected member)
    useEffect(() => {
        const unsub = history.listen((loc) => {
            if (matchPath(loc.pathname, { path: basePath })) {
                const regex =/\/member\/([^/]*)/;
                const result = regex.exec(loc.pathname);
                if (result && result[1]){
                    const memberId = result[1];
                    drawerState.setSelectedMemberId(memberId);
                } else {
                    drawerState.removeSelectedMemberId();
                }
            }
        });

        return () => {
            unsub();
        };
    }, [history, drawerState, basePath]);

    // TODO: this would all probably be better if we could use the node or node group as reference instead of the mouse event position
    const focusedRef = useRef<HTMLDivElement>(null);
    const [animateOrigin, setAnimateOrigin] = useState<IFocusedCareMapAnimationOrigin>();
    useEffect(() => {
        const handler = (e: MouseEvent) => {
            if (focusedRef.current && !drawerState.isOpen()) {
                setAnimateOrigin({
                    x: e.x - (focusedRef.current.offsetWidth / 2),
                    y: e.y - (focusedRef.current.offsetHeight / 2)
                });
                setAnimateFocusedMap(true);
            }
        };
        window.addEventListener('click', handler);
        return () => window.removeEventListener('click', handler);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Route path={basePath} render={(props) => {
            return (
                <>
                    {/*
                    * create a mask over the full caremap (below the focused caremap)
                    * we implement this ourselves (and pass hideBackdrop to Drawer below)
                    * because we need to inject more dom between the parent (full caremap and the ion menu)
                    */}
                    <div style={{
                        position: 'absolute',
                        backgroundColor: 'black',
                        opacity: .55,
                        inset: 0,
                        zIndex: HEADER_Z_INDEX + 1
                    }} />

                    <div
                        ref={focusedRef}
                        data-id="focused-care-map"
                        style={{
                            position: 'absolute',
                            top: HEADER_HEIGHT,
                            right: FINAL_DRAWER_WIDTH,
                            bottom: 0,
                            left: 0,
                            zIndex: FOCUSED_CARE_MAP_Z_INDEX
                        }}
                    >
                        {isAboveBreakpoint('lg') &&
                            <FocusedCareMap
                                animate={animateFocusedMap}
                                animateOrigin={animateOrigin}
                                focusOn={props.match.params as any}
                                onDismiss={closeDrawer}
                            />
                        }
                        {isBelowBreakpoint('lg') &&
                            <div style={{width: '100%', height: '100%'}} onClick={closeDrawer} />
                        }

                        {/*
                        * Mask over the focused care map (eg while editing, when you shoudln't be able to switch to another member
                        * by clicking on another hexagon in the focused care map)
                        * NOTE: This mask would ideally be UNDER the drawer, but
                        * this mask is not UNDER the drawer, because it would be OVER the drawer it needs to be OVER the caremap which is OVER the drawer
                        */}
                        <div
                            onClick ={(e: React.MouseEvent) => {
                                // NOTE: we intentionally do nothing at this time.
                                // it would be easy to abandon your changes and dismiss the form
                                // but ideally, we would know if the form is dirty, and not abandon your changes
                                // so we're going to wait til that is a priority
                                e.stopPropagation();
                            }}
                            style={{
                                position: 'absolute',
                                //backgroundColor: 'white',
                                opacity: .2,
                                display: drawerState.isMaskingFocusedCareMap ? 'block' : 'none',
                                top: -HEADER_HEIGHT,
                                right: 0,
                                left: 0,
                                bottom: 0
                            }}
                        />
                    </div>

                    <Drawer
                        basePath={basePath}
                        returnToPath={`/caremaps/${careMapState.getActiveCaremap()?.careMapId}`}
                        hideBackdrop
                        onClose={onClose}
                        menuRef={drawerRef}
                    >
                        {children}
                    </Drawer>
                </>
            );

        }} />
    );
};
