export class NameHelper {
    getName = ({ firstName, lastName }: { firstName?: string; lastName?: string; }) => {
        let name = firstName || '';
        if (name && lastName){
            name += ' ';
        }
        if (lastName){
            name += lastName;
        }

        return name;
    };
}
