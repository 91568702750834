import constrain from 'lodash/clamp';
import { useZoom } from '../utils/useZoom';
import { Hexagon } from '../honeycomb/Hexagon';
import { createUseStyles } from 'react-jss';
import { NodeProps } from 'reactflow';
import { CareMapDrawerRouter } from '../caremap/CareMapDrawerRouter';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { SupportGrouIconProvider } from '../caremap/SupportGroupIconProvider';
import { IThemeColor } from '../theme/IThemeColor';
import { DevSettings } from '../support/DevSettings';
import { IonButton } from '@ionic/react';
import { MinusIcon, PlusIcon } from '../theme/Icons';
import { SupportGroupHandle } from './SupportGroupHandle';
import { ISupportGroupNodeData } from './NodeAdapter';

type IStyleKeys = 'content' | 'memberTitle' | 'identifierTitle' | 'icon' | 'hexagonTools' | 'hexagonOutline';

interface IStyleProps {
    zoom: number;
}

const useStyles = createUseStyles<IStyleKeys, IStyleProps>({
    content: {
        fontSize: 14,
        fontWeight: 600,
        width: '100%',
        height: '100%',
        padding: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },

    memberTitle: {
        textAlign: 'center',
        width: '120%',
        overflowWrap: 'initial',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        // as the user zooms in, reduce the font size slightly so we lose the ellipses
        // (but constrain it to a reasonable range)
        fontSize: ({zoom}) => `${constrain(0.8/zoom, 0.7, 0.9)}rem`
    },

    identifierTitle: {
        textAlign: 'center',
        width: '90%',
        overflowWrap: 'initial',
        fontSize: '0.9rem',
        textTransform: 'uppercase'
    },

    icon: {
        marginBottom: 8
    },

    hexagonTools: {
        zIndex: 10000,
        '& ion-button': {
            pointerEvents: 'auto',
            width: 35,
            '--background': 'white'
        }
    },

    hexagonOutline: {
        width: '100%',
        height: '100%',
        border: '1px solid red',
        zIndex: 999
    }
});

const getBorderColor = ({ selectedItemId, memberId, themeColor  }: { selectedItemId?: string; memberId: string; themeColor: IThemeColor; }) => {
    if (!selectedItemId || memberId !== selectedItemId){
        return themeColor;
    }

    //return (themeColor === 'primary' ? 'secondary' : 'primary');
    return 'tertiary';
};

export const HoneycombNodeGroup = ({ data }: NodeProps<ISupportGroupNodeData>) => {

    const { indicator: group, items: members, hexagonPositions, simpleHexagonPositions } = data;

    const zoom = useZoom();

    const classes = useStyles({zoom});

    const devSettings = useInstance(DevSettings);

    const drawerRouter = useInstance(CareMapDrawerRouter);

    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);

    const iconProvider = useInstance(SupportGrouIconProvider);

    const onItemClicked = async (item: any) => {
        // can't click items in the wizard
        if (!careMapStateInstance.getActiveCaremap()?.essentialMapComplete) {
            return;
        }
        drawerRouter.navigateToSelectedMemberId({
            basePath: `/caremaps/${careMapStateInstance.getActiveCaremap()?.careMapId}/drawer/support-group/${group.supportGroupKey}`,
            memberId: (item.identifier ? undefined : item.id)
        });
    };

    const { color } = iconProvider.getSupportGroupIcons(group.supportGroupKey);
    const title = careMapStateInstance.getSupportGroupLabels(group.supportGroupKey).title;
    const Icon = iconProvider.getSupportGroupIcons(group.supportGroupKey).GroupIcon;

    const origin = hexagonPositions[0];

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            {devSettings.enableHexagonTools &&
                <>
                    <div className={classes.hexagonOutline} />
                    <div className={classes.hexagonTools} style={{position: 'absolute', left: origin[0], top: origin[1], zIndex: 10000 }}>
                        <IonButton disabled={!members.length} onClick={() => careMapStateInstance.removeSupportGroupMember({ supportGroupKey: group.supportGroupKey, memberId: members[members.length - 1].id })} fill="outline"><MinusIcon /></IonButton>
                        <IonButton onClick={() => careMapStateInstance.addSupportGroupMember(group.supportGroupKey, { id: String(Date.now()), name: `Test ${members.length + 1}`, contactMethods: [], notes: [] })} fill="outline"><PlusIcon /></IonButton>
                    </div>
                </>
            }

            <div style={{position: 'absolute', left: origin[0], top: origin[1]}}>
                <Hexagon color={color} fill="solid">
                    <div
                        data-type="support-group"
                        data-intercom-target={group.useForOnboarding ? 'onboarding-group' : undefined}
                        onClick={e => onItemClicked(group)}
                        className={classes.content}
                        style={{cursor: careMapStateInstance.getActiveCaremap()?.essentialMapComplete ? 'pointer' : 'unset' }}
                    >
                        {Icon &&
                            <div className={classes.icon}>
                                <Icon color="light" size="xlarge" />
                            </div>
                        }
                        <div className={classes.identifierTitle}>{title}</div>
                    </div>
                </Hexagon>
                <SupportGroupHandle supportGroupKey={group.supportGroupKey} origin={origin} />

            </div>

            {members.map((member: any, idx: number) => {
                // idx + 1 b/c  0 is the indicator hexagon
                const [left, top] = hexagonPositions[idx + 1] || [];
                const simplePosition = (simpleHexagonPositions || [])[idx + 1] || [];
                const borderColor = getBorderColor({ memberId: member.id, selectedItemId: data.selectedItemId, themeColor: color });

                let memberTitle: string;
                if (careMapStateInstance.shouldShowNameFirst(group.supportGroupKey)) {
                    memberTitle = (member.name || member.role || '');
                } else {
                    memberTitle = (member.role || member.name || '');
                }

                return (
                    <div key={member.id} style={{position: 'absolute', left, top}}>
                        <Hexagon color={borderColor} fill="outline">
                            <div
                                data-type="group-member"
                                data-id={`member-${member.id}`}
                                onClick={e => onItemClicked(member)}
                                className={classes.content}
                                style={{cursor: careMapStateInstance.getActiveCaremap()?.essentialMapComplete ? 'pointer' : 'unset' }}
                            >
                                <div className={classes.memberTitle}>
                                    {devSettings.enableHexagonTools && (
                                        <span>
                                            <span>[{simplePosition[0]},{simplePosition[1]}]</span>
                                            <span style={{ display: 'block'}}> Member {idx + 1}</span>
                                        </span>
                                    )}
                                    {!devSettings.enableHexagonTools && memberTitle}
                                </div>
                            </div>
                        </Hexagon>
                    </div>
                );
            })}
        </div>
    );
};
