import { APIClient } from '@innerhive-internal/innerhive-api-client';
import { State } from '@meraki-internal/state';
import { UserState } from './UserState';

export type INotificationType = 'scheduledTips' | 'dailyLog';

export interface IUserSettings {
    userId: string;
    updatedAt?: string;
    notifications: {
        scheduledTips: {
            enabled: boolean;
        },
        dailyLog: {
            enabled: boolean;
        }
    }
};

export class UserSettingsState extends State<IUserSettings | undefined> {
    static inject = () => [
        APIClient,
        UserState
    ];

    constructor(
        private apiClient: APIClient,
        private userState: UserState
    ) {
        super(undefined);
    };

    load = async () => {
        const settings = await this.apiClient.get(this.userState.state.links?.settings);
        if (settings) {
            this.setState(settings);
        }
    };

    save = async () => {
        const settings = await this.apiClient.put(this.userState.state.links?.settings, this.state);
        this.setState(settings);
    };

    updateNotificationSetting = (type: INotificationType, value: boolean) => {
        const notification = {
            [type]: {enabled: value}
        };

        this.setState({...this.state, ...{notifications: {...this.state!.notifications, ...notification}}});
    };

    disableNotifications = () => {
        this.updateNotificationSetting('dailyLog', false);
        this.updateNotificationSetting('scheduledTips', false);
    };
};
