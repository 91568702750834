import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { useEffect } from 'react';

export const HideIntercomOnQuestionniare: React.FC = () => {

    const caremapState = useInstance(CareMapState);
    useSubscription(() => caremapState);

    const activeCaremap = caremapState.getActiveCaremap();
    const isActiveCaremapAndQuestionnaire = activeCaremap && !activeCaremap.essentialMapComplete;

    useEffect(() => {
        if (!isActiveCaremapAndQuestionnaire) {
            // set a class on body to use for styling global elements
            // outside react (e.g. intercom launcher)
            document.body.classList.remove('incomplete-essential-map');
        } else {
            document.body.classList.add('incomplete-essential-map');
        }

        return () => {
            document.body.classList.remove('incomplete-essential-map');
        };
    }, [isActiveCaremapAndQuestionnaire]);

    return null;
};
