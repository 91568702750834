import { INoteTarget } from '../INoteTarget';
import { INoteTemplate, TemplatesConfig } from './TemplatesConfig';

export class TemplateProvider {
    static inject = () => [TemplatesConfig];
    constructor(private config: TemplatesConfig){}

    getTemplates = (noteTarget: INoteTarget): INoteTemplate[] => {
        return this.config.templates
            .filter(t => {
                return noteTarget.section === t.target.section && Boolean(noteTarget.memberId) === Boolean(t.target.forMember);
            }).map(t => ({
                ...t,

                // trim out the leading whitespace added by using ` but don't trim out the trailing whitespace b/c it is likely there for the user to type in
                body: t.body.trimStart()
            }));
    };
};
