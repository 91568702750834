import { IonButton, IonCard, IonCardContent, IonCardHeader, IonLabel, } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { RevenueCatModel } from './RevenueCatModel';
import { useSubscription } from '@meraki-internal/state';
import { RevenueCatAPIModel } from './RevenueCatAPIModel';
import { RevenueCatSDKModel } from './RevenueCatSDKModel';
import { AlertPresenter } from '../../AlertBinder';
import React, { useState } from 'react';

const AsyncIonButton: React.FC<{ children: React.ReactNode; onClick: ()=> Promise<void>; disabled?: boolean; }> = ({ children, onClick, disabled }) => {
    const [isWorking, setIsWorking] = useState(false);
    const alert = useInstance(AlertPresenter);

    const handleClick = () => {
        Promise.resolve()
            .then(async () => {
                setIsWorking(true);
                await onClick();
            })
            .catch(err => {
                if (err && err.cancelled){
                    // do nothing
                } else {
                    alert.showAlertV2({ header: 'Error', message: err.toString() });
                }
            })
            .then(() => {
                setIsWorking(false);
            });
    };

    disabled = disabled || isWorking;


    return <IonButton disabled={disabled} onClick={handleClick}>{children}</IonButton>;
};

const PreJSON: React.FC<{ json: any }> = ({ json }) => {
    const [isShowing, setIsShowing] = useState(false);
    if (!json){
        return null;
    }
    return (
        <>
            {isShowing && <pre>{JSON.stringify(json, null, 2)}</pre>}
            <IonButton onClick={() => setIsShowing(!isShowing)}>{isShowing ? 'Hide' : 'Show'}</IonButton>
        </>
    );
};

const FetchableJSON: React.FC<{ json: any, fetch: () => Promise<any>; name: string; }> = ({ json, fetch, name }) => {
    return (
        <>
            {json && (<h3>{name}</h3>)}
            <PreJSON json={json} />
            <AsyncIonButton onClick={() => fetch()}>Fetch {name}</AsyncIonButton>
        </>
    );
};

const Diagnostics: React.FC<{ diagnostics: { [label: string]: any } }> = ({ diagnostics }) => {
    return (
        <ul>
            {Object.keys(diagnostics)
                .map(label => ({ label, value: diagnostics[label] }))
                .filter(({ value }) => value !== undefined)
                .map(({ label, value }) => (
                    <li key={label}>
                        <IonLabel>{label}</IonLabel>:
                        <span style={{ marginLeft: 10 }}>{JSON.stringify(value)}</span>
                    </li>
                ))
            }
        </ul>
    );
};

export const RevenueCatDebugPage: React.FC = () => {
    const facade = useInstance(RevenueCatModel);
    useSubscription(() => facade);

    const api = useInstance(RevenueCatAPIModel);
    useSubscription(() => api);

    const sdk = useInstance(RevenueCatSDKModel);
    useSubscription(() => sdk);

    const facadeDiagnostics = facade.getDiagnostics();
    const apiDiagnostics = api.getDiagnostics();
    const sdkDiagnostics = sdk.getDiagnostics();

    return (
        <>
            <h1>Revenue Cat Debug Page</h1>
            <IonCard>
                <IonCardHeader>Facade</IonCardHeader>
                <IonCardContent>
                    <Diagnostics diagnostics={{
                        ...facadeDiagnostics.diagnostics,
                        deviceInfo: undefined
                    }} />
                    <PreJSON json={facadeDiagnostics.diagnostics.deviceInfo} />
                    <AsyncIonButton disabled={!facade.canUpgrade()} onClick={() => facade.upgrade()}>Upgrade</AsyncIonButton>
                    <AsyncIonButton disabled={!facade.canMaybeDowngrade()} onClick={() => facade.maybeDowngrade()}>Downgrade</AsyncIonButton>
                </IonCardContent>
            </IonCard>
            <IonCard>
                <IonCardHeader>IH API</IonCardHeader>
                <IonCardContent>
                    <Diagnostics diagnostics={{
                        ...apiDiagnostics.diagnostics,
                        entitlementsApiResponse: undefined
                    }} />
                    <FetchableJSON json={apiDiagnostics.diagnostics['entitlementsApiResponse']} name="entitlementsApiResponse" fetch={apiDiagnostics.diagnosticsMethods.refresh} />
                    <AsyncIonButton onClick={() => apiDiagnostics.diagnosticsMethods.refresh()}>Refresh</AsyncIonButton>
                </IonCardContent>
            </IonCard>
            <IonCard>
                <IonCardHeader>@revenuecat/purchases-capacitor</IonCardHeader>
                <IonCardContent>
                    <Diagnostics diagnostics={{
                        ...sdkDiagnostics.diagnostics,
                        customerInfo: undefined,
                        offers: undefined
                    }} />
                    {!sdkDiagnostics.diagnostics.isInitialized && (
                        <AsyncIonButton onClick={() => sdk.init()}>Initialize</AsyncIonButton>
                    )}
                    {sdkDiagnostics.diagnostics.isInitialized && (
                        <>
                            <FetchableJSON json={sdkDiagnostics.diagnostics['customerInfo']} name="customerInfo" fetch={sdkDiagnostics.diagnosticsMethods.fetchSubscriber} />
                            <FetchableJSON json={sdkDiagnostics.diagnostics['offers']} name="offers" fetch={sdkDiagnostics.diagnosticsMethods.fetchOffers} />
                            {(sdkDiagnostics.diagnostics['offers']?.current?.availablePackages || []).map((pkg: any) => (
                                <AsyncIonButton onClick={() => sdkDiagnostics.diagnosticsMethods.purchasePackage(pkg)}>Purchase {pkg.product.identifier}</AsyncIonButton>
                            ))}
                        </>
                    )}
                </IonCardContent>
            </IonCard>
        </>

    );
};
