import { ICareMapSection } from '../../caremap/ICareMap';

export interface INoteTemplate {
    templateId: string;
    label: string;
    body: string;
    target: INoteTemplateTarget;
    focusLocation?: number;
};

export interface ICareMapNoteTemplateTarget {
    section: ICareMapSection;
    forMember?: boolean;
};

export interface ICheckinNoteTemplateTarget {
    section: 'checkin';
    forMember?: boolean;
}

export type INoteTemplateTarget = ICareMapNoteTemplateTarget | ICheckinNoteTemplateTarget;

type ITemplateId = 'gratitude' | 'mindful-reflection' | 'self-reflection' | 'affirmation';

const templateBodyById: { [key in ITemplateId]: string } = {
    'gratitude': `
🙏 Gratitude

List three things you are grateful for today:

1.\xA0`,

    'mindful-reflection': `
🌅 Mindful Reflection

Describe a moment today when you felt completely present. What were you doing, and how did it make you feel?
➡️\xA0`,

    'self-reflection': `
🤔 Self-Reflection

How am I feeling right now?
➡️\xA0

What makes me hopeful?
➡️\xA0

What makes me worried?
➡️\xA0

What can I accept that I cannot change?
➡️\xA0`,

    'affirmation': `
🙌 Affirmation

Write down three positive things about yourself or your caregiving today.
How do these positive thoughts make you feel and change your outlook?

1.\xA0`
};

const templateLabelById: { [key in ITemplateId]: string } = {
    'gratitude': '🙏 Gratitude',
    'mindful-reflection': '🌅 Mindful Reflection',
    'self-reflection': '🤔 Self-Reflection',
    'affirmation': '🙌 Affirmation'
};

const templateFocusLocationById: { [key in ITemplateId]: number | undefined } = {
    'gratitude': undefined,
    'mindful-reflection': undefined,
    'self-reflection': 51,
    'affirmation': undefined
};

const templateIdsByTarget: { [target: string]: ITemplateId[] } = {
    'patient': [],
    'household/member': [],
    'household': [],
    'checkin': [
        'gratitude',
        'mindful-reflection',
        'self-reflection',
        'affirmation'
    ]
};

const buildTemplates = (): INoteTemplate[] => {
    const templates:  INoteTemplate[] = [];
    for (const targetString of Object.keys(templateIdsByTarget)){
        const [section, member] = targetString.split('/');
        const target: INoteTemplateTarget = {
            // this cast isn't safe but isn't worth adding ATM, the result is a template wouldn't get targeted
            // which we should notice when adding a template
            section: section as ICareMapSection,
            forMember: member === 'member'
        };

        for (const templateId of templateIdsByTarget[targetString]){
            const body = templateBodyById[templateId];
            const label = templateLabelById[templateId];
            const focusLocation = templateFocusLocationById[templateId];
            templates.push({
                templateId,
                label,
                body,
                target,
                focusLocation
            });
        }
    }

    return templates;
};

export class TemplatesConfig {
    constructor(){
        this.templates = buildTemplates();
    }

    templates: INoteTemplate[];
};
