import { FC, LegacyRef, ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { RouterPage } from '../components/RouterPage';
import { mediaMaxWidth } from '../theme/utils';

const useStyles = createUseStyles({
    step: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'auto',
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,

        '& > h2': {
            paddingBottom: 20
        },

        '& > .searchable-select-field': {
            width: '80%'
        },

        '& > ion-input, & > ion-select': {
            width: '80%',
            paddingBottom: '20px !important',

            '&.md.input-fill-outline, &.md.select-fill-outline': {
                boxSizing: 'content-box'
            }
        },

        '& > ion-button': {
            '&.ios': {
                minHeight: '3.1em'
            },

            '&.md': {
                minHeight: 36
            }
        }
    },

    [mediaMaxWidth.lg]: {
        step: {
            '& > .searchable-select-field': {
                width: '90%'
            },

            '& > ion-input, & > ion-select': {
                width: '90%'
            }
        }
    },

    subTitle: {
        marginTop: -24,
        marginBottom: 25,
        color: 'var(--ion-color-step-700)'
    },

    question: {
        textAlign: 'center'
    },

    [mediaMaxWidth.md]: {
        question: {
            fontSize: 20
        }
    }
});

export interface IStepValidProps {
    setStepValidity: (isValid: boolean) => void
}

export interface IStepProps {
    question: string | ReactElement;
    subTitle?: string;
    children?: React.ReactNode;
    divRef?: LegacyRef<HTMLDivElement>;
    ['data-id']?: string;
}

export const Step: FC<IStepProps> = (props) => {
    const classes = useStyles();
    const { question, subTitle, children, divRef, ...rest } = props;
    return (
        <RouterPage>
            <div
                ref={divRef}
                className={classes.step}
                {...rest}
            >
                <h2 data-id="question" className={classes.question}>{question}</h2>
                {subTitle &&
                    <span className={classes.subTitle}>{subTitle}</span>
                }
                {children}
            </div>
        </RouterPage>
    );
};
