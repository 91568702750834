import { FC } from 'react';
import { IFeeling } from './ICheckin';
import { IThemeColor } from '../theme/IThemeColor';
import { HappyIcon, IconComponent, IIconSize, MehIcon, SadIcon, SolidHappyIcon, SolidMehIcon, SolidSadIcon, SolidVeryHappyIcon, SolidVerySadIcon, VeryHappyIcon, VerySadIcon } from '../theme/Icons';

const FEELING_ICONS: {[feeling in IFeeling]: IconComponent} = {
    1: VeryHappyIcon,
    2: HappyIcon,
    3: MehIcon,
    4: SadIcon,
    5: VerySadIcon
};

const SOLID_FEELING_ICONS: {[feeling in IFeeling]: IconComponent} = {
    1: SolidVeryHappyIcon,
    2: SolidHappyIcon,
    3: SolidMehIcon,
    4: SolidSadIcon,
    5: SolidVerySadIcon
};

const FEELING_COLORS: {[feeling in IFeeling]: IThemeColor} = {
    1: 'theme-dark-green',
    2: 'theme-teal',
    3: 'theme-purple',
    4: 'theme-red-orange',
    5: 'theme-red'
};

export type IFeelingIconProps = {
    size?: IIconSize;
    feeling: IFeeling;
    noColor?: boolean;
    solid?: boolean;
};

export const FeelingIcon: FC<IFeelingIconProps> = ({ feeling, noColor, size, solid }) => {
    const iconSet = solid ? SOLID_FEELING_ICONS : FEELING_ICONS;
    const Icon = iconSet[feeling || 0];
    const color = !noColor ? FEELING_COLORS[feeling || 0] : 'medium';

    return (
        <span data-id={`feeling-${feeling}-icon`}>
            <Icon color={color} size={size} />
        </span>
    );
};
