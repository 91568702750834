import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { CareMapState } from '../caremap/CareMapState';
import { CheckboxStep } from './CheckboxStep';

export type IConsentType = 'consent-self' | 'consent-someone-else';

interface IConsentCheckboxStepProps {
    setStepValidity: (isValid: boolean) => void;
    stepId: IConsentType;
}

export const ConsentCheckboxStep: React.FC<IConsentCheckboxStepProps> = ({ stepId, setStepValidity }) => {
    const careMap = useInstance(CareMapState);
    useSubscription(() => careMap);

    return (
        <CheckboxStep
            stepId={stepId}
            setStepValidity={setStepValidity}
            question={`We need your permission to continue:`}
            checkboxLabel={careMap.getLabel('CONSENT')}
            validate={(isChecked) => isChecked === true}
            disabled={Boolean(careMap.getActiveCaremap()?.initialWizardAnswers[`${stepId}-date`])}
        />
    );
};
