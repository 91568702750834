import { FC } from 'react';
import { Text } from '../../components/Text';
import { HelpCenterState, IArticle, ICollectionWithArticles } from './HelpCenterState';
import { IonButton } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { IntercomService } from '../IntercomService';
import { Href } from '../../components/Href';
import { MixPanelEventEmitter } from '../../metrics/MixPanelEventEmitter';
import { ISupportGroupKey } from '../../caremap/ICareMap';

export type IHelpCenterArticleListProps = {
    collections: ICollectionWithArticles[];
    numCollectionsToShow?: number;
    numArticlesToShow?: number;
    viewMore?: () => void;
    supportGroupKey: ISupportGroupKey;
};

export const HelpCenterArticleList: FC<IHelpCenterArticleListProps> = ({
    collections,
    viewMore,
    numCollectionsToShow,
    numArticlesToShow,
    supportGroupKey
}) => {
    const intercomService = useInstance(IntercomService);
    const mixpanel = useInstance(MixPanelEventEmitter);
    const helpCenterState = useInstance(HelpCenterState);

    const articleClicked = (article: IArticle, collection: ICollectionWithArticles) => {
        const groupCollectionId = helpCenterState.getCollectionIdForCategory(supportGroupKey);
        mixpanel.track('Help Article Opened', () => ({
            'Opened From': `${supportGroupKey}${!numCollectionsToShow ? ' article page' : ''}`,
            'Article Name': article.title.replace('&amp;', '&'),
            'Article URL': article.url,
            'Collection': collection.name.replace('&amp;', '&'),
            'Grandparent Collection': (helpCenterState.state.collections.find(col => col.id === groupCollectionId))?.name.replace('&amp;', '&')
        }));

        intercomService.showArticle(Number(article.id));
    };

    return (
        <>
            {collections.map((col: ICollectionWithArticles, idx: number) => (
                (!numCollectionsToShow || idx < numCollectionsToShow) && (
                    <div key={col.id} style={{marginTop: idx > 0 ? 48 : undefined}} data-type="article-collection">
                        <Text type="subtitle">
                            <div dangerouslySetInnerHTML={{__html: col.name}} />
                        </Text>
                        <ul>
                            {col.articles.map((article, idx2) => (
                                (!numArticlesToShow || idx2 < numArticlesToShow) && (
                                    <li key={article.id} style={{marginTop: idx2 > 0 ? 8 : undefined}}>
                                        <Href onClick={() => articleClicked(article, col)}>
                                            <div dangerouslySetInnerHTML={{__html: article.title}} />
                                        </Href>
                                    </li>
                                )
                            ))}
                        </ul>
                    </div>
                )
            ))}

            {viewMore && numCollectionsToShow && collections.length > numCollectionsToShow && (
                <div style={{display: 'flex', justifyContent: 'space-around', marginBottom: -24}}>
                    <IonButton fill="clear" onClick={viewMore} data-id="view-more-articles-button">View More Articles</IonButton>
                </div>
            )}
        </>
    );
};
