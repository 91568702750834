import { useInstance } from '@meraki-internal/react-dependency-injection';
import { ICareMapNoteTarget } from './INoteTarget';
import { useSubscription } from '@meraki-internal/state';
import { CareMapState } from '../caremap/CareMapState';
import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { DrawerPage } from '../components/DrawerPage';
import { NoteEdit } from './NoteEdit';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { getNoteTrackingContext } from './NotesTrackingHelper';
import { NoteSavingQueue } from './NoteSavingQueue';
import { WithLinks } from '../caremap/WithLinks';
import { INote } from './INote';
import { CareMapDrawerState } from '../caremap/CareMapDrawerState';
import { FileUploader } from './attachments/FileUploader';
import { IFreeTierLimits } from '../innerhive-plus/revenue-cat/IFreeTierLimits';
import { useToast } from '../components/useToast';
import { ICareMapSection } from '../caremap/ICareMap';

export const CaremapNoteEditor: React.FC<{noteId?: string; noteTarget: ICareMapNoteTarget}> = ({ noteId, noteTarget }) => {
    const drawerState = useInstance(CareMapDrawerState);
    useSubscription(() => drawerState);
    const cmState = useInstance(CareMapState);
    useSubscription(() => cmState);
    const uploader = useInstance(FileUploader);
    useSubscription(() => uploader);

    // some might be "done" but if they are still in there it means it hasn't been saved to the note yet
    // so for this components perspective, if they are in there, they are still uploading
    const isUploadingFiles = uploader.uploadingFiles.length > 0;

    const tracking = useInstance(MixpanelService);

    const history = useInstance(HistoryViewModel);

    const isAdd = !noteId;
    const [_noteId] = useState(noteId || nanoid());

    const section = cmState.getCareMap()[noteTarget.section as ICareMapSection];

    let node = section;
    if (noteTarget.memberId) {
        node = (section as any).members.find((m: any) => m.id === noteTarget.memberId);
    }

    const newNote = { title: '', html: '', isPrivate : true, noteId: _noteId, attachments: [] };
    const note = node.notes.find(n => n.noteId === _noteId) || newNote;

    const newNotesLink = node.links?.notes;
    const hasEditLink = Boolean((note as any)?.links?.self || newNotesLink);
    const hasDeleteLink = Boolean((note as any)?.links?.delete);

    // intentionanlly not subscribing b/c this cannot change
    const canEdit = cmState.getActiveCaremap()!.links.self.actions.includes('edit-notes') && hasEditLink;

    const savingQueue = useInstance(NoteSavingQueue);
    useSubscription(() => savingQueue);

    const [present] = useToast();

    const error = savingQueue.getError(note);

    useEffect(() => {
        if (error){
            present({
                duration: 3000,
                message: (error as any).displayMessage || 'Sorry something went wrong'
            });
            setTimeout(() => {
                savingQueue.clearError({ noteId: note?.noteId || '', error });
            }, 3000);
        }
    }, [ error, present, savingQueue, note.noteId ]);

    const onDidEnter = () => {
        drawerState.setIsMaskingFocusedCareMap(true);
    };

    const onWillLeave = () => {
        tracking.trackWithDebounce({
            event: isAdd ? 'Note Added' : 'Note Viewed / Edited',
            getContext: () => {
                return {
                    ...getNoteTrackingContext({noteTarget, node, note}),
                    ...{'# of attached files': note?.attachments.length}
                };
            }
        });
        drawerState.setIsMaskingFocusedCareMap(false);
    };

    const uploadingCount = uploader.uploadingFiles.filter(f => !f.isUploaded).length;
    const addAttachmentRequiresInnerhivePlus = (limits: IFreeTierLimits) => cmState.getAttachmentCount() + uploadingCount >= limits.freeAttachmentsPerCaremap;

    return (
        <DrawerPage
            title=""
            hasBack={!isUploadingFiles}
            hasClose={!isUploadingFiles}
            stretchHeight
            enteredHandler={onDidEnter}
            leavingHandler={onWillLeave}
            data-type="caremap-note-editor"
        >
            <NoteEdit
                note={note}
                isAdd={isAdd}
                canEdit={canEdit}
                noteTarget={noteTarget}
                hasTitle
                hasPrivateCheckbox
                hasDeleteLink={hasDeleteLink}
                addAttachmentRequiresInnerhivePlus={addAttachmentRequiresInnerhivePlus}
                noteChanged={updatedNote => {
                    cmState.localUpdateNotes({
                        to: noteTarget,
                        note: updatedNote
                    });
                    savingQueue.enqueue({
                        noteId: updatedNote.noteId,
                        action: () => cmState.updateNotes({ links: { self: newNotesLink }, ...updatedNote })
                    });
                }}
                noteDeleted={deletedNote => {
                    cmState.localDeleteNote({
                        to: noteTarget,
                        note: deletedNote
                    });
                    savingQueue.enqueue({
                        noteId: note.noteId,
                        action: () => cmState.deleteNotes(note as WithLinks<INote>)
                    });
                    tracking.trackWithDebounce({
                        event: 'Note Deleted',
                        getContext: () => getNoteTrackingContext({noteTarget, node, note})
                    });
                    history.back();
                }}
            />
        </DrawerPage>
    );
};
