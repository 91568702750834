import { FC, useEffect, useRef } from 'react';
import { isPlatform } from '@ionic/react';
import { Step, IStepValidProps } from './Step';
import { TextField } from '../components/TextField';

export type INameStepProps = IStepValidProps & {
    firstName: string | undefined
    lastName: string | undefined
    lastNameLabel?: string;
    onFirstNameChange: (value: string) => void
    onLastNameChange: (value: string) => void;
    question: string;
}

export const NameStep: FC<INameStepProps> = ({
    firstName,
    lastName,
    lastNameLabel,
    setStepValidity,
    onFirstNameChange,
    onLastNameChange,
    question,
    ...dataProps
}) => {
    const firstNameFieldRef = useRef<HTMLIonInputElement>(null);

    useEffect(() => {
        if (isPlatform('desktop') || isPlatform('capacitor')) {
            const timer = setTimeout(() => {
                if (firstNameFieldRef.current && !firstName) {
                    firstNameFieldRef.current.setFocus();
                }
            }, 350);
            return () => clearTimeout(timer);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setStepValidity(firstName && firstName.length ? true : false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const firstNameChanged = (value: string) => {
        onFirstNameChange(value);
        setStepValidity(value.length ? true : false);
    };

    return (
        <Step question={question} {...dataProps}>
            <TextField
                inputRef={firstNameFieldRef}
                label="First Name"
                name="firstname"
                value={firstName}
                autocapitalize="words"
                onInput={firstNameChanged}
            />
            <TextField
                label={`${lastNameLabel || 'Last Name (optional)'}`}
                value={lastName}
                name="lastname"
                autocapitalize="words"
                onInput={onLastNameChange}
            />
        </Step>
    );
};
