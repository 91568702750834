import { FC, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { MoreIcon } from '../theme/Icons';
import { IonButton, IonItem, IonList, IonPopover, IonText } from '@ionic/react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    popover: {
        '&::part(content)': {
            width: 'auto',
            minWidth: 200,
            maxWidth: 650
        }
    }
});

export interface IAction {
    text: string;
    Icon?: any;
    role?: 'destructive';
    'data-type'?: string;
    handler: () => void;
    hidden?: boolean;
    disabled?: boolean;
}
export interface IMenuProps {
    actions: IAction[]
}

export const Menu: FC<IMenuProps> = ({ actions }) => {
    const classes = useStyles();

    // generate an id ionic can use to connect the button to the menu
    const idRef = useRef(nanoid());

    const [isOpen, setOpen] = useState(false);

    const onClickMenuItem = async (handler: () => void) => {
        handler();
        setOpen(false);
    };

    return (
        <>
            <IonButton
                data-type="menu-button"
                id={idRef.current}
                shape="round"
                fill="clear"
                style={{'--padding-start': '10px', '--padding-end': '10px'}}
                onClick={() => setOpen(prev => !prev)}
            >
                <MoreIcon color="dark" />
            </IonButton>

            <IonPopover keepContentsMounted trigger={idRef.current} alignment="end" className={classes.popover} isOpen={isOpen} onDidDismiss={() => setOpen(false)}>
                <IonList data-type="menu-list" lines="none" style={{paddingTop: 0, paddingBottom: 0}}>
                    {actions.map(({ text, Icon, role, hidden, disabled, handler, 'data-type': dataType }, idx) => !hidden && (
                        <IonItem
                            button
                            data-type={dataType}
                            key={`${text}-${idx}`}
                            lines={idx === actions.length - 1 ? 'none' : 'full'}
                            disabled={disabled}
                            onClick={() => onClickMenuItem(handler)}
                        >
                            <Icon slot="start" color={role === 'destructive' ? 'danger' : 'medium'} />
                            <IonText style={{paddingRight: 30}} color={role === 'destructive' ? 'danger' : undefined}>{text}</IonText>
                        </IonItem>
                    ))}
                </IonList>
            </IonPopover>

        </>
    );
};
