import { Position } from 'reactflow';
import React from 'react';
import { InnerhiveReactFlowHandle } from '../react-flow/InnerhiveReactFlowHandle';
import { householdNodeSizes } from './NodeAdapter';

export const PatientHandle : React.FC = () => {
    return (
        <InnerhiveReactFlowHandle
            type="source"
            isConnectable={false}
            position={Position.Right}
            style={{ left: householdNodeSizes.large / 2}}
        />
    );
};
