import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { Redirect, Route } from 'react-router';
import { CareMapState } from '../caremap/CareMapState';

// This /active-caremap route simply redirects to the equivalent route under the active caremap
// (or / if there is no completed active caremap). This is intended to be used as an entry point
// for deep linking from notifications, emails, etc
export const ActiveCaremapRouter = () => {

    const careMapState = useInstance(CareMapState);
    useSubscription(() => careMapState);
    const activeCaremap = careMapState.getActiveCaremap();

    return (
        <Route path="/active-caremap" render={(props) => {
            if (!activeCaremap?.careMapId) {
                return <Redirect to="/" />;
            } else {
                let url = props.location.pathname.replace('/active-caremap', `/caremaps/${activeCaremap.careMapId}`);
                if (props.location.search) {
                    url = `${url}?${props.location.search}`;
                }
                return <Redirect to={url} />;
            }
        }} />
    );
};
