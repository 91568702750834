import { FC, useEffect } from 'react';
import { ISupportGroupKey } from '../../caremap/ICareMap';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { HelpCenterState } from './HelpCenterState';
import { useSubscription } from '@meraki-internal/state';
import { HelpCenterArticleList } from './HelpCenterArticleList';
import { DrawerPage } from '../../components/DrawerPage';
import { CareMapDrawerState } from '../../caremap/CareMapDrawerState';
import { MixPanelEventEmitter } from '../../metrics/MixPanelEventEmitter';

export type IHelpCenterArticlesViewProps = {
    supportGroupKey: ISupportGroupKey
};

export const HelpCenterArticlesView: FC<IHelpCenterArticlesViewProps> = ({ supportGroupKey }) => {
    const helpCenterState = useInstance(HelpCenterState);
    useSubscription(() => helpCenterState);

    const drawerState = useInstance(CareMapDrawerState);
    useSubscription(() => drawerState);

    const mixpanel = useInstance(MixPanelEventEmitter);

    useEffect(() => {
        helpCenterState.load();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onDidEnter = () => {
        mixpanel.track('Help Articles List Viewed', () => ({
            'Viewed From': supportGroupKey
        }));

        drawerState.setIsMaskingFocusedCareMap(true);
    };

    const onWillLeave = () => {
        drawerState.setIsMaskingFocusedCareMap(false);
    };

    const collections = helpCenterState.getCollectionsAndArticlesForCategory(supportGroupKey);
    const hasArticles = helpCenterState.hasArticles(collections);

    return (
        hasArticles && (
            <DrawerPage
                hasBack={true}
                title="Help Articles"
                enteredHandler={onDidEnter}
                leavingHandler={onWillLeave}
            >
                <HelpCenterArticleList
                    collections={collections}
                    supportGroupKey={supportGroupKey}
                />
            </DrawerPage>
        )
    );
};
