import { createUseStyles } from 'react-jss';
import { IonButtons, IonHeader, IonModal, IonTitle, IonToolbar, isPlatform } from '@ionic/react';
import { MenuCloseButton } from '../components/MenuCloseButton';
import { MenuBackButton } from './MenuBackButton';
import { useBreakpoints } from '../theme/useBreakpoints';

type IStyleKeys = 'modal' | 'modalHeader';
interface IStyleProps { desktopMaxHeight?: number; desktopMinHeight?: number; desktopMaxWidth?: number; isAboveMd: boolean; };

const useStyles = createUseStyles<IStyleKeys, IStyleProps>({
    modal: {
        '--max-width': ({ desktopMaxWidth, isAboveMd }) => (isPlatform('desktop') || isAboveMd) && desktopMaxWidth ? `${desktopMaxWidth}px` : undefined,
        '--max-height': ({ desktopMaxHeight, isAboveMd }) => (isPlatform('desktop') || isAboveMd) && desktopMaxHeight ? `${desktopMaxHeight}px` : undefined,
        '--min-height': ({ desktopMinHeight, isAboveMd }) => (isPlatform('desktop') || isAboveMd) && desktopMinHeight ? `${desktopMinHeight}px` : undefined
    },

    modalHeader: {
        '& ion-title': {
            paddingInlineStart: 16
        }
    }
});

type IModalProps = {
    'data-id'?: string;
    'data-type'?: string;
    title: string;
    children: React.ReactNode;
    isOpen: boolean;
    backHandler?: () => void;
    closeHandler: () => void;
    onWillPresent?: () => void;
    onDidPresent?: () => void;
    stackedModal?: boolean;
    keepContentsMounted?: boolean;
    desktopMaxWidth?: number;
    desktopMaxHeight?: number;
    desktopMinHeight?: number;
    excludeAutoDismiss?: boolean;
    preventBackdropDismiss?: boolean;
    hideCloseButton?: boolean;
    centerTitle?: boolean;
};

export const Modal: React.FC<IModalProps> = ({
    'data-id': dataId,
    'data-type': dataType,
    title,
    children,
    isOpen = false,
    backHandler,
    closeHandler,
    onWillPresent = () => {},
    onDidPresent = () => {},
    stackedModal = false,
    keepContentsMounted,
    desktopMaxWidth,
    desktopMaxHeight,
    desktopMinHeight,
    preventBackdropDismiss = false,
    hideCloseButton = false,
    centerTitle,
    ...rest
}) => {
    if (desktopMinHeight && desktopMinHeight > 590) {
        console.warn('Modal desktop heights cannot be greater than 590');
        desktopMinHeight = 590;
    }

    const { isAboveBreakpoint } = useBreakpoints();
    const classes = useStyles({ desktopMaxHeight, desktopMinHeight, desktopMaxWidth, isAboveMd: isAboveBreakpoint('md') });

    const handleIntercom = (modalOpen: boolean) => {
        if (!stackedModal) {
            // set a class on body to use for styling global elements
            // outside react (e.g. intercom launcher)
            document.body.classList[modalOpen ? 'add' : 'remove']('modal-presented');
        }
    };

    const titleStyles: React.CSSProperties = {};
    if (centerTitle){
        titleStyles.textAlign = 'center';
        titleStyles.marginLeft = (backHandler ? undefined : 52);
        titleStyles.marginRight = (!hideCloseButton ? undefined : 52);
    }

    return (
        <IonModal
            {...rest} // do it this way so excludeAutoDismiss gets added without IDE complaining
            data-id={dataId}
            data-type={dataType}
            isOpen={isOpen}
            className={classes.modal}
            canDismiss={!preventBackdropDismiss}
            keepContentsMounted={keepContentsMounted}
            onWillPresent={() => {
                onWillPresent();
                handleIntercom(true);
            }}
            onDidPresent={onDidPresent}
            onWillDismiss={(e: any) => {
                // invoke closeHandler, but only if the user clicked on the backdrop (or hit the escape key)
                // (this avoids invoking handler twice when close button clicked, or modal closed by consumer)
                if (e.detail.role === 'backdrop') {
                    closeHandler();
                }
            }}
            onDidDismiss={() => {
                handleIntercom(false);
            }}
        >
            <IonHeader translucent className={`ion-no-border ${classes.modalHeader}`}>
                <IonToolbar>
                    <IonTitle style={titleStyles}>{title}</IonTitle>
                    <IonButtons slot="start">
                        {backHandler && (
                            <MenuBackButton onClick={backHandler} />
                        )}
                    </IonButtons>
                    <IonButtons slot="end">
                        {!hideCloseButton && (
                            <MenuCloseButton onClick={closeHandler} />
                        )}
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            {children}
        </IonModal>
    );
};
