// TODO: is there a way to get them out of Ionic instead??
// https://github.com/ionic-team/ionic-framework/blob/main/core/src/utils/media.ts
// https://github.com/ionic-team/ionic-framework/blob/main/core/src/themes/ionic.globals.scss

export type IBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export const breakpoints: {[key in IBreakpoint]: number} = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
};

export const mediaMinWidth: {[key in IBreakpoint]: string} = {
    xs: `@media (min-width: ${breakpoints.xs}px)`,
    sm: `@media (min-width: ${breakpoints.sm}px)`,
    md: `@media (min-width: ${breakpoints.md}px)`,
    lg: `@media (min-width: ${breakpoints.lg}px)`,
    xl: `@media (min-width: ${breakpoints.xl}px)`
};

export const mediaMaxWidth: {[key in IBreakpoint]: string} = {
    xs: `@media (max-width: ${breakpoints.xs}px)`,
    sm: `@media (max-width: ${breakpoints.sm-1}px)`,
    md: `@media (max-width: ${breakpoints.md-1}px)`,
    lg: `@media (max-width: ${breakpoints.lg-1}px)`,
    xl: `@media (max-width: ${breakpoints.xl-1}px)`
};
