import { APIClient } from '@innerhive-internal/innerhive-api-client';
import { CareMapState } from '../caremap/CareMapState';
import { ICareMap } from '../caremap/ICareMap';
import { ICollaboratorPermission } from './InvitationState';
import { IPendingCareMapInvite } from '../caremap/ICareMapInvite';
import { BufferedEventBus } from '../utils/BufferedEventBus';

export type APIUrl = string;

const invitationAcceptanceHeaders = { 'content-type': 'application/vnd.innerhive.invitation-acceptance.v2+json' };
export class InvitationService {
    static inject = () => [
        APIClient,
        CareMapState,
        BufferedEventBus
    ];

    constructor(
        private api: APIClient,
        private careMapState: CareMapState,
        private events: BufferedEventBus
    ) { }

    validateInvite = (invite?: APIUrl) => {
        if (!invite){
            throw new Error(`/accept-invite, invite is required`);
        };
    };

    private postAcceptOrDecline = async (invite: APIUrl, consented: boolean): Promise<any> => {
        return await this.api.post(
            `/invites/${encodeURIComponent(invite as string)}/accept`,
            { consent: consented },
            invitationAcceptanceHeaders
        );
    };

    acceptedInvite = async (invite: APIUrl): Promise<ICareMap> => {
        const { careMapKey: { careMapId, userId }} = await this.postAcceptOrDecline(invite, true);

        await this.careMapState.loadWithoutActive();
        const careMap = this.careMapState.getCareMaps().find(c => c.userId === userId && c.careMapId === careMapId);

        if (!careMap){
            throw new Error('after accepting an invite, expected to see the caremap, but do not (should never happen)');
        }

        this.events.emit('InvitationService.accepted');
        return careMap;
    };

    declinedInvite = async (invite: APIUrl) => {
        await this.postAcceptOrDecline(invite, false);
    };

    getInvites = async () => {
        return await this.api.get(this.careMapState.getActiveCaremap()?.links.invites);
    };

    getCollaborators = async () => {
        return await this.api.get(this.careMapState.getActiveCaremap()?.links.permissions);
    };

    delete = async (item: ICollaboratorPermission | IPendingCareMapInvite) => {
        await this.api.delete(item.links.self.href);
    };
};
