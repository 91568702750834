import jwtDecode from 'jwt-decode';
import { Container, DependencyContainerContext } from '@meraki-internal/react-dependency-injection';
import { AuthenticationClientConfig, AuthenticationClientRedirecter, MemoryLastCodeVerifierProvider, HTTPClientConfig, HTTPClient, APIClient } from '@innerhive-internal/innerhive-api-client';
import { BrowserAuthenticationClientRedirecter } from './auth/BrowserAuthenticationClientRedirecter';
import { LocalStorageLastCodeVerifierProvider } from './auth/LocalStorageLastCodeVerifierProvider';
import { AuthService, AuthStorageProvider, IAuthService, ViewerAuthService } from './auth/AuthService';
import { Logger } from './support/Logger';
import { AppPrincipal } from './auth/AppPrincipal';
import { LocalStorageProvider } from './support/LocalStorageProvider';
import { AsyncStorageProvider, AsyncStorageProviderConfig } from './support/AsyncStorageProvider';
import { EnvConfiguration, getConfiguration, getEnvironment } from './config/EnvConfiguration';
import { UserState } from './user/UserState';
import { MixPanelServiceFacade as MixpanelService } from './metrics/MixpanelService';
import { FatalApplicationErrorState } from './FatalApplicationErrorState';
import { setSentryContext } from './support/sentry';
import { PageViewTracking } from './metrics/PageViewTracking';
import { DeviceValidator } from './support/DeviceValidator';
import { IntercomService } from './support/IntercomService';
import { CloudwatchLogger } from './support/CloudwatchLogger';
import { isPlatform } from '@ionic/react';
import { DevSettings } from './support/DevSettings';
import { DeviceInfo } from './support/DeviceInfo';
import {
    LiveUpdatesService,
    LiveUpdatesServiceLogger,
    LiveUpdatesServiceNetwork,
    LiveUpdatesListenerHistory,
    configure as configureLiveUpdates
} from '@meraki-internal/live-updates';
import { HistoryViewModel } from './support/HistoryViewModel';
import { NetworkAdapter } from './support/NetworkAdapter';
import { NativeVersionManager } from './support/forced-updates/NativeVersionManager';
import { Device } from '@capacitor/device';
import { LoadingPage } from './LoadingPage';
import { State, useSubscription } from '@meraki-internal/state';
import { FeatureFlagState } from './support/feature-flags/FeatureFlagState';
import { RevenueCatModel } from './innerhive-plus/revenue-cat/RevenueCatModel';
import { RevenueCatAPIModel } from './innerhive-plus/revenue-cat/RevenueCatAPIModel';
import { RevenueCatSDKModel } from './innerhive-plus/revenue-cat/RevenueCatSDKModel';
import { OrganizationsState } from './organizations/OrganizationsState';
import { CareMapState } from './caremap/CareMapState';
import { WelcomePageState } from './QuestionnaireWelcomePage';
import { CheckinState } from './caregiver/CheckinState';

let errorSafeContainer: Container | undefined;

export const getErrorSafeContainer = () => {
    return errorSafeContainer || new Container();
};

let mountCount = 0;

const loadingPageState = new State<{showLogo: boolean}>({showLogo: false});
const SmartLoadingPage: React.FC = () => {
    useSubscription(() => loadingPageState);
    return <LoadingPage showLogo={loadingPageState.state.showLogo} />;
};
export class AppContainer extends DependencyContainerContext {
    renderLoading() {
        return <SmartLoadingPage /> as any;
    };

    /**
     * This is awaited from componentDidMount in the base class DependencyContainerContext.
     * WARNING: Because this is async, errors thrown from here are silently swallowed by React.
     * So we must catch and handle them manually, or the app will white screen.
     */
    async containerMounted(container: Container) {
        try {
            // ignore if we get a second mount - this is from react attempting to remount
            // after an error, but we have already rendered the error page at this point
            mountCount++;
            if (mountCount > 1){
                container.get(Logger).info('AppContainer tried to mount again');
                return;
            }

            errorSafeContainer = container;

            await this.configureContainer(container);
        } catch (e: any) {
            try {
                container.get(MixpanelService).init();
            }
            catch (err: any){
                container.get(Logger).info('failed to init mixpanel while trying to render failed app start');
            }

            container.get(Logger).error(e);
            FatalApplicationErrorState.singleton.throwError(e);
        }
    }

    async configureContainerForViewer(container: Container) {
        const log = container.get(Logger);
        log.info('Initializing principal for viewer...');
        const config = container.get(EnvConfiguration);

        container.registerInstance(AuthService, container.get(ViewerAuthService));

        const session_token = new URLSearchParams(window.location.search).get('v')!;

        let accessToken: string;

        try{
            const result = await new HTTPClient({ baseURL: config.API_BASE_URL }).get(`/sessions/${session_token}`);

            accessToken = result.data.access_token;

            if (!accessToken){
                throw new Error('no access token on a successful response, this is not expected');
            }

        }
        catch (err: any){
            if (err.status === 404){
                // it may not have ever existed, but that should only be true for a malicious user
                // so lets assume it did exist but is now expired
                err.errorCode = 'viewer.jwt.expired';
            }
            throw err;
        }


        const decoded = jwtDecode(accessToken) as any;
        if (decoded.tokenType !== 'caremap-viewer'){
            throw new Error(`unsupported viewer access token ${JSON.stringify(decoded)}`);
        }
        const principal: AppPrincipal = {
            tokenType: decoded.tokenType,
            userId: decoded.sub,
            email: '',
            isAdmin: false
        };
        container.registerInstance(AppPrincipal, principal);
        log.info('principal', principal);

        // Should do this as early as possible; if anything depends on HTTPClient such
        // that HTTPClientConfig is created before this is called, we'll be in a bad state
        log.info('Configuring HTTP client...');

        const httpClientConfig: HTTPClientConfig = {
            baseURL: config.API_BASE_URL,

            // intentionally omitted, so it will throw
            // on401Callback:

            accessToken
        };

        container.registerInstance(HTTPClientConfig, httpClientConfig);
    }

    async configureContainerForUser(container: Container) {
        const log = container.get(Logger);
        const config = container.get(EnvConfiguration);

        // If not logged in, send user to login
        log.info('Getting credentials for for user...');
        container.registerInstance(AuthenticationClientConfig, { baseURL: config.API_BASE_URL });
        const authService: IAuthService = container.get(AuthService);

        const { accessToken } = await authService.getToken();

        log.info('Initializing principal for user...');
        const { userId, email, isAdmin } = jwtDecode(accessToken!) as any;
        const principal: AppPrincipal = {
            userId,
            email,
            isAdmin,
            tokenType: 'user'
        };
        container.registerInstance(AppPrincipal, principal);
        log.info('principal', principal);

        // Should do this as early as possible; if anything depends on HTTPClient such
        // that HTTPClientConfig is created before this is called, we'll be in a bad state
        log.info('Configuring HTTP client...');

        const httpClientConfig: HTTPClientConfig = {
            baseURL: config.API_BASE_URL,
            on401Callback: authService.on401Callback,
            accessToken
        };

        container.registerInstance(HTTPClientConfig, httpClientConfig);
        if (container.get(HTTPClientConfig) !== httpClientConfig) {
            throw new Error(`HTTPClientConfig already registered! ${JSON.stringify(container.get(HTTPClientConfig))}`);
        }
    }

    private stopIfTestParkingLotPage = async () => {
        if (window.location.search === '?__test_parking_lot_page'){
            const el = document.createElement('h1');
            el.setAttribute('data-id', 'parked');
            el.innerHTML = 'Welcome to the parking lot';
            document.body.appendChild(el);
            await new Promise(resolve => {});
        }
    };

    async configureContainer(container: Container) {

        const log = container.get(Logger);

        log.info('Starting app initialization...');

        const isAutomatedTests = localStorage.getItem('IS_AUTOMATED_TESTS') === 'true';

        log.info('Loading environment config...');

        const device = await Device.getInfo();
        container.registerInstance(DeviceInfo, device);

        container.registerInstance(AsyncStorageProviderConfig, {
            platform: device.platform,
            backwardsCompatibilityWebKeys: [
                'ih:auth',
                'shown-nudges'
            ]
        } as AsyncStorageProviderConfig);

        const devSettings = container.get(DevSettings);

        const appEnv = getEnvironment(devSettings);
        const config = getConfiguration(appEnv);

        if (isAutomatedTests){
            // disable tracking
            config.MIXPANEL_TOKEN = '';
            config.INTERCOM_APP_ID = '';
        }

        container.registerInstance(EnvConfiguration, config);
        log.info('config', config);

        await this.configureNativeVersionManager(container);

        const storageProvider = container.get(LocalStorageProvider);
        storageProvider.setEnv(config.ENV === 'live' ? 'live' : 'staging');
        container.get(AsyncStorageProvider).setEnv(config.ENV === 'live' ? 'live' : 'staging');

        // get it into window.IOC so tests can get to it
        container.get(AuthStorageProvider);
        container.get(EnvConfiguration);

        // for LoadingPage
        const liveUpdatePath = await container.get(AsyncStorageProvider).getStringProvider('LiveUpdatesAppStartPath', {userNeutral: true}).get();
        loadingPageState.setState({showLogo: liveUpdatePath ? false : true});

        // stop here if on the test blank page
        // why here, because tests will check EnvConfiguration to ensure it is consistent
        // with what tests expect (eg are both are talking to the staging api)
        await this.stopIfTestParkingLotPage();

        // platform "capacitor" means running native on a device
        // (in which case we don't want to do any validation)
        if (!isPlatform('capacitor')) {
            this.validateDevice({ deviceValidator: container.get(DeviceValidator) });
        }

        container.registerAlias(BrowserAuthenticationClientRedirecter, AuthenticationClientRedirecter);
        container.registerAlias(LocalStorageLastCodeVerifierProvider, MemoryLastCodeVerifierProvider);

        await this.initLiveUpdates(container);

        if (new URLSearchParams(window.location.search).get('v')){
            await this.configureContainerForViewer(container);
        } else {
            await this.configureContainerForUser(container);
        }

        const principal = container.get(AppPrincipal);

        storageProvider.setUserId(principal.userId);
        container.get(AsyncStorageProvider).setUserId(principal.userId);

        setSentryContext({ user: principal, env: config.ENV });

        // this will prime the entry resource cache, and trigger on401Callback if our token is expired
        // NOTE this is when a viewing user is going to hit entry resource and discover they are timed out
        container.get(CloudwatchLogger).init({ api: container.get(APIClient), config }).catch(err => {
            err.message = `failed to init cloudwatch logger: ${err.toString()}`;
            log.error(err);
        });

        // TODO: if revenue cat is down our app won't start, ideally we'd be more resilient
        // eg if revenue cat is down, then use the last known state, or assume not premium if there isn't one
        container.registerInstance(RevenueCatModel, new RevenueCatModel(
            container.get(RevenueCatAPIModel),
            container.get(RevenueCatSDKModel),
            container.get(AppPrincipal),
            container.get(DevSettings),
            container.get(Logger),
            {
                getDeviceInfo: () => Device.getInfo()
            },
            container.get(APIClient)
        ));
        await container.get(RevenueCatModel).init();

        await container.get(FeatureFlagState).init();

        log.info('Loading user...');
        const userState = container.get(UserState);

        await userState.load();
        await container.get(OrganizationsState).load();

        container.get(WelcomePageState).isNonClientOrgUser = () => container.get(OrganizationsState).getNonClientOrganizations().length > 0;

        await container.get(CareMapState).loadWithoutActive();
        container.get(CareMapState)._orgState = container.get(OrganizationsState);

        await container.get(CheckinState).load();

        log.info('Initializing trackers...');
        container.get(IntercomService).init({ principal });
        container.get(MixpanelService).init();

        container.get(PageViewTracking).start();

        (window as any).appContainerReady = true;

        log.info('App initialization complete!');
    }

    private initLiveUpdates = async (container: Container) => {
        const config = container.get(EnvConfiguration);

        container.registerAlias(NetworkAdapter, LiveUpdatesServiceNetwork);
        container.registerAlias(Logger, LiveUpdatesServiceLogger);
        container.registerAlias(HistoryViewModel, LiveUpdatesListenerHistory);

        const logger = container.get(Logger);

        try {
            await configureLiveUpdates({
                container,
                env: config.ENV === 'live' ? 'prod' : 'staging',
                sha: process.env.VITE_APP_SHA || '(no sha)',
                isDevServer: !import.meta.env.PROD,
                LIVE_UPDATES_BASE_URL: 'https://live-updates.innerhive.com',
                onInstallingLiveUpdate: async () => {
                    logger.info('installing live update');
                    await container.get(AsyncStorageProvider).getStringProvider('LiveUpdatesAppStartPath', {userNeutral: true})
                        .set(window.location.hash);
                    await container.get(CloudwatchLogger).flush();
                }
            });

            await container.get(LiveUpdatesService).init({
                disableInstallOrDownloadUpdateIfAvailable: config.ENV === 'local'
            });

            // don't do anything if we're in the middle of auth!
            if (!window.location.hash.includes('/oauth/')) {
                await container.get(LiveUpdatesService).installOrDownloadUpdateIfAvailable();
            }
        }
        catch (err: any){
            container.get(Logger).error(`failed to init live updates, app proceeded gracefully. Error: ${err.toString()}`);
        }
    };

    private configureNativeVersionManager = async (container: Container) => {
        const log = container.get(Logger);

        log.info('initializing NativeVersionManager');
        const nativeVersionManager = container.get(NativeVersionManager);
        await nativeVersionManager.init();
        await nativeVersionManager.blockOnExpired();
        nativeVersionManager.startPolling();
        log.info('initializing NativeVersionManager complete');
    };

    private validateDevice = ({ deviceValidator }: { deviceValidator: DeviceValidator; }) => {
        const validation = deviceValidator.validate();

        if (!validation.validBrowser || !validation.validDevice){
            const error:any = new Error('device is not supported');
            error.errorCode = 'device-not-allowed';
            throw error;
        }
    };

};
