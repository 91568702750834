import { IonText } from '@ionic/react';
import { IconComponent } from '../theme/Icons';
import { IThemeColor } from '../theme/IThemeColor';
import { createUseStyles } from 'react-jss';
import { ICheckinTag } from './ICheckin';

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },

    icon: {
        padding: 8,
        marginBottom: 2,
        minWidth: 45,
        textAlign: 'center',
        borderRadius: '50%'
    }
});

export interface ICheckinDetailsToggleProps {
    Icon: IconComponent;
    label: ICheckinTag;
    active?: boolean;
    activeColor?: IThemeColor;
    'data-type'?: string;
    'data-id'?: string;
    onClick?: () => void;
};

export const CheckinDetailsToggle: React.FC<ICheckinDetailsToggleProps> = ({ Icon, active, activeColor, label, 'data-type': dataType, 'data-id': dataId, onClick }) => {
    const classes = useStyles();
    const color = active ? activeColor : 'mediumtext';
    const backgroundColor = active ? `rgb(var(--ion-color-${activeColor}-rgb), 0.1)` : 'var(--ion-color-light)';

    return (
        <div
            data-type={dataType}
            data-id={dataId}
            data-active={active}
            onClick={onClick}
            className={classes.container}
            style={{cursor: onClick ? 'pointer' : undefined}}
        >
            <div className={classes.icon} style={{backgroundColor: backgroundColor}}>
                <Icon color={color} size="large" />
            </div>
            <IonText color="mediumtext"><small><b>{label}</b></small></IonText>
        </div>
    );
};
