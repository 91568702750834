import { FC } from 'react';
import { Modal } from '../components/Modal';
import { IonButton, IonCardContent, IonContent } from '@ionic/react';
import { CheckinState } from './CheckinState';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { DayPicker, DayButtonProps, useDayPicker } from 'react-day-picker';
import { createUseStyles } from 'react-jss';
import dayjs from 'dayjs';
import { FeelingIcon } from './FeelingIcon';
import { AddFeelingIcon, ChevronLeftIcon, ChevronRightIcon } from '../theme/Icons';
import { MixPanelEventEmitter } from '../metrics/MixPanelEventEmitter';

const useStyles = createUseStyles({
    [`@media (min-width: 600px)`]: {
        calendar: {
            '--rdp-day-width': '100%'
        }
    },

    calendar: {
        '--rdp-day-height': '82px',
        '--rdp-nav-height': '48px',
        '--rdp-nav_button-width': '48px',
        '--rdp-nav_button-height': '48px',
        '--rdp-today-color': 'var(--ion-color-primary)',

        '& div.rdp-months': {
            minWidth: '100%',

            '& div.rdp-month_caption': {
                paddingLeft: 26
            },

            '& nav.rdp-nav': {
                marginRight: 4
            },

            '& div.rdp-month': {
                width: '100%',


                '& table.rdp-month_grid': {
                    width: '100%',
                    maxWidth: '100%',

                    '& th.rdp-weekday': {
                        fontSize: 16
                    }
                }
            }
        }
    },

    addFeelingIcon: {
        opacity: 0.4,

        '&:hover': {
            opacity: 1,

            '& ion-text': {
                color: 'var(--ion-color-primary)'
            }
        }
    }
});

export const CustomChevron: FC<{props: any}> = ({ props }) => {
    const picker = useDayPicker();
    const isNext = props.orientation === 'right';

    return (
        <IonButton
            fill="clear"
            shape="round"
            disabled={isNext && !picker.nextMonth}
            style={{width: 48, height: 48, '--background-hover-opacity': '0.08'}}
        >
            {isNext ? (
                <ChevronRightIcon />
            ): (
                <ChevronLeftIcon />
            )}
        </IonButton>
    );
};

export function CustomWeekday(props: any) {
    // 3 letter day headings
    const newProps = {...props, ...{children: props['aria-label'].substring(0, 3)}};
    return (
        <th {...newProps}></th>
    );
};

export const CustomDayButton: FC<{dayProps: DayButtonProps}> = (props) => {
    const classes = useStyles();
    const checkinState = useInstance(CheckinState);

    const { day, modifiers, ...buttonProps } = props.dayProps;
    const checkin = checkinState.getFinalCheckin(dayjs(day.date).toISOString());
    const today = dayjs().startOf('day');
    const theDay = dayjs(day.date).startOf('day');
    const isToday = theDay.isToday();
    const isFuture = theDay.isAfter(today, 'day');

    return (
        <button {...buttonProps} style={{ whiteSpace: 'nowrap', flexDirection: 'column', cursor: isFuture ? 'default': 'pointer' }}>
            <div style={{visibility: isFuture ? 'hidden': undefined}}>
                {checkin?.feeling ? (
                    <FeelingIcon solid feeling={checkin.feeling} size="xlarge" />
                ) : (
                    <span className={!isToday ? classes.addFeelingIcon : ''}>
                        <AddFeelingIcon size="xlarge" color={isToday ? 'primary' : 'mediumtext'} />
                    </span>
                )}
            </div>
            <div style={{fontWeight: isToday ? 'bold' : undefined, marginTop: isToday ? -1 : undefined}}>
                {props.dayProps.children}
            </div>
        </button>
    );
};

export type ICheckinsModalProps = {
    isOpen?: boolean;
    closeHandler: () => void;
};

export const CheckinsModal: FC<ICheckinsModalProps> = ({ isOpen = false, closeHandler }) => {
    const classes = useStyles();

    const checkinState = useInstance(CheckinState);
    const mixpanel = useInstance(MixPanelEventEmitter);

    const cancel = () => {
        closeHandler();
    };

    const onSelect = (day: Date) => {
        // can't create a checkin for a future day
        const isFuture = dayjs(day).startOf('day').isAfter(dayjs());
        if (isFuture) {
            return;
        }

        const selectedCheckin = checkinState.getCheckinForDay(dayjs(day).toISOString());
        checkinState.startEditing(selectedCheckin);

        mixpanel.track('Daily Log Opened', () => ({
            'Day': mixpanel.createMixpanelDateWithoutTime(dayjs(day).format('YYYY-MM-DD')),
            'From': 'calendar'
        }));
    };

    return (
        <Modal
            title=""
            isOpen={isOpen}
            desktopMinHeight={590}
            closeHandler={cancel}
            data-id="checkin-calendar-modal"
            onDidPresent={() => mixpanel.track('Daily Log Calendar Opened')}
        >
            <IonContent data-id="checkin-content">
                <IonCardContent style={{paddingTop: 0}}>
                    <DayPicker
                        className={classes.calendar}
                        onDayClick={(day) => onSelect(day)}
                        components={{
                            Weekday: CustomWeekday,
                            Chevron: (props) => <CustomChevron props={props} />,
                            DayButton: (props: DayButtonProps) => <CustomDayButton dayProps={props} />
                        }}
                    />
                </IonCardContent>
            </IonContent>
        </Modal>
    );
};
