import { FC } from 'react';
import { DrawerPage } from '../components/DrawerPage';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { EditIcon } from '../theme/Icons';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { Text } from '../components/Text';
import { Menu } from '../components/Menu';
import { MemberAvatar } from './MemberAvatar';
import { formatAge } from '../utils/format';
import { ContactMethodsList } from '../contact-methods/ContactMethodsList';
import { NameHelper } from '../contact-methods/NameHelper';
import { NotesList } from '../notes/NotesList';
import { DiagnosisList } from './DiagnosisList';
import { NudgeBanner } from '../components/NudgeBanner';
import { AppointmentsList } from '../appointments/AppointmentsList';
import { DrawerListSection } from '../components/DrawerListSection';
import { AppointmentsState, NEW_APPOINTMENT_ID } from '../appointments/AppointmentsState';
import { AllergyList } from './AllergyList';

export const PatientView: FC = () => {
    const nameHelper = useInstance(NameHelper);

    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);

    const appointmentsState = useInstance(AppointmentsState);
    useSubscription(() => appointmentsState);

    const canEdit = careMapStateInstance.hasPermissionTo('put');

    const caremap = careMapStateInstance.getActiveCaremap();

    const patient = caremap?.patient;
    const history = useInstance(HistoryViewModel);

    if (!caremap || !patient) {
        return null;
    }

    const editPatient = () => {
        history.push(`/caremaps/${careMapStateInstance.getActiveCaremap()?.careMapId}/drawer/household/member/patient/edit`);
    };

    const viewMoreNotes = () => {
        history.push(`/caremaps/${careMapStateInstance.getActiveCaremap()?.careMapId}/drawer/household/member/patient/notes`);
    };

    const editNote = (noteId?: string) => {
        history.push(`/caremaps/${careMapStateInstance.getActiveCaremap()?.careMapId}/drawer/household/member/patient/notes/${noteId}`);
    };

    const viewMoreAppointments = () => {
        history.push(`/caremaps/${appointmentsState.getCareMapId()}/drawer/household/member/patient/appointments`);
    };

    const editAppointment = (appointmentId?: string) => {
        if (appointmentId === NEW_APPOINTMENT_ID) {
            appointmentsState.trackNewAppointmentOpened({});
        }

        history.push(`/caremaps/${appointmentsState.getCareMapId()}/drawer/household/member/patient/appointments/${appointmentId}`);
    };

    const showEditNudgeBadge = canEdit && !patient.diagnoses?.length && !patient.allergies?.length && !patient.contactMethods.length && !patient.address;

    const listShowCount = 2;
    const notes = careMapStateInstance.getCareMap().patient.notes;

    return (
        <DrawerPage title="Care Recipient Detail" hasBack={true}>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 15, paddingBottom: 50 }}>
                <div style={{ display: 'flex', alignItems: 'start', gap: 20 }}>

                    <MemberAvatar member={patient} />

                    <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
                        <Text type="title" data-type="title">{patient.firstName || ''} {patient.lastName || ''}</Text>
                        <Text type="subtitle" data-type="subtitle">{formatAge(patient.dob)}</Text>
                    </div>

                    {canEdit &&
                        <div style={{marginTop: -8}}>
                            <Menu actions={[
                                { Icon: EditIcon, text: `Edit Care Recipient`, handler: editPatient }
                            ]} />
                        </div>
                    }
                </div>

                {showEditNudgeBadge &&
                    <NudgeBanner
                        buttonLabel="Edit"
                        buttonClickHandler={editPatient}
                        message="Enhance your care map with address, contact info, and diagnoses"
                    />
                }

                <ContactMethodsList
                    contactMethods={patient.contactMethods}
                    address={patient.address}
                    iconWidth={64}
                    emailToName={nameHelper.getName(patient)}
                    trackingContext={{ category: 'patient' }}
                />

                <DrawerListSection>
                    <NotesList
                        data-type="patient-notes-list"
                        showCount={listShowCount}
                        editNoteHandler={editNote}
                        moreNotesHandler={viewMoreNotes}
                        notes={notes}
                    />
                </DrawerListSection>

                <DrawerListSection>
                    <AppointmentsList
                        showViewMore
                        numDaysToShow={1}
                        showCount={listShowCount}
                        minDate={(new Date()).toISOString()}
                        editAppointment={editAppointment}
                        viewMore={viewMoreAppointments}
                        data-type="appointments-list"
                    />
                </DrawerListSection>

                <DiagnosisList
                    diagnoses={patient.diagnoses}
                    isPublic={patient.diagnosesIsPublic}
                />

                <AllergyList
                    allergies={patient.allergies}
                    isPublic={patient.allergiesIsPublic}
                />
            </div>
        </DrawerPage>
    );
};
