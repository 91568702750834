import { useInstance } from '@meraki-internal/react-dependency-injection';
import { INote } from '../INote';
import { INoteTarget } from '../INoteTarget';
import { INoteTemplate } from './TemplatesConfig';
import { TemplateProvider } from './TemplateProvider';
import { MixPanelEventEmitter } from '../../metrics/MixPanelEventEmitter';
import { IonButton, IonPopover } from '@ionic/react';
import { useRef, useState } from 'react';
import { DuoFileLinesIcon } from '../../theme/Icons';
import { MenuItem } from '../../components/MenuItem';
import { getNoteTemplateTrackingLocation } from '../NotesTrackingHelper';
import { AlertPresenter } from '../../AlertBinder';

interface INoteTemplatePickerProps {
    note: INote;
    noteTarget: INoteTarget;
    currentTextareaValue: string | null | undefined;
    onTemplateClicked: (template: INoteTemplate, append?: boolean) => void;
}

export const NoteTemplatePicker: React.FC<INoteTemplatePickerProps>  = ({ note, currentTextareaValue, noteTarget, onTemplateClicked }) => {
    const templates = useInstance(TemplateProvider).getTemplates(noteTarget);
    const mixpanel = useInstance(MixPanelEventEmitter);
    const alertPresenter = useInstance(AlertPresenter);

    const popover = useRef<HTMLIonPopoverElement>(null);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const openPopover = (event: React.MouseEvent<HTMLIonButtonElement>) => {
        popover.current!.event = event;
        setPopoverOpen(true);
    };

    const templateSelected = async (template: INoteTemplate) => {
        setPopoverOpen(false);

        let action = 'insert';
        if (currentTextareaValue) {
            action = await alertPresenter.showAlertV2({
                wider: true,
                backdropDismiss: true,
                header: 'Add or Replace?',
                message: 'Would you like to replace your existing content (it will be deleted), or add the template to your text?',
                options: [{
                    id: 'add',
                    label: 'Add'
                },{
                    id: 'replace',
                    label: 'Replace'
                },{
                    id: 'cancel',
                    label: 'Cancel',
                    role: 'cancel'
                }]
            });
        }

        if (action === 'cancel') {
            return;
        }

        if (action === 'insert' || action === 'replace') {
            onTemplateClicked(template);
        } else if (action === 'add') {
            onTemplateClicked(template, true);
        }

        mixpanel.track('Note Template Applied', () => ({
            action: action,
            template: template.label,
            templateId: template.templateId,
            location: getNoteTemplateTrackingLocation(template.target)
        }));
    };

    return (
        <>
            <IonButton
                fill="clear"
                onClick={openPopover}
                data-id="note-templates-popover-trigger"
            >
                <DuoFileLinesIcon slot="start" />
                &nbsp;&nbsp;Use A Template
            </IonButton>
            <IonPopover
                reference="trigger"
                side="bottom"
                alignment="start"
                ref={popover}
                keepContentsMounted // otherwise sometimes opens off the screen on mobile
                isOpen={popoverOpen}
                onDidDismiss={() => setPopoverOpen(false)}
            >
                {templates.map((template, i) => (
                    <MenuItem
                        key={i}
                        label={template.label}
                        data-template-id={template.templateId}
                        onClick={() => templateSelected(template)}
                    />
                ))}
            </IonPopover>
        </>
    );
};
