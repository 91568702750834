import { INote } from './INote';
import { INoteTarget } from './INoteTarget';
import { INoteTemplateTarget } from './note-templates/TemplatesConfig';

export const getNoteTrackingLocation = (noteTarget: INoteTarget) => {
    const isPatient = noteTarget.section === 'patient';
    const isCheckin = noteTarget.section === 'checkin';
    const location = `${isPatient ? 'care recipient' : isCheckin ? 'daily log' : noteTarget.section}${noteTarget.memberId ? ' member' : ''}`;
    return location;
};

export const getNoteTemplateTrackingLocation = (noteTarget: INoteTemplateTarget) => {
    const isPatient = noteTarget.section === 'patient';
    const isCheckin = noteTarget.section === 'checkin';
    const location = `${isPatient ? 'care recipient' : isCheckin ? 'daily log' : noteTarget.section}${noteTarget.forMember ? ' member' : ''}`;
    return location;
};

export const getNoteTrackingContext = ({noteTarget, node, note}: {noteTarget: INoteTarget; node: any; note: INote | undefined;}) => {
    const type = (node as any).relationshipId || (node as any).relationship || (node as any).roleId || (node as any).role || '';

    return {
        type: type,
        location: getNoteTrackingLocation(noteTarget),
        hasTitle: note?.title !== '',
        private: note?.isPrivate as boolean
    };
};
