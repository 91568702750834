import {
    IonButton,
    IonContent,
    IonFooter,
    IonToolbar,
    IonRouterOutlet,
    IonPage,
    IonProgressBar
} from '@ionic/react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { CareMapState, MYSELF } from '../caremap/CareMapState';
import { NameStep } from './NameStep';
import { DOBStep } from './DOBStep';
import { HouseholdStep } from './HouseholdStep';
import { PhotoStep } from './PhotoStep';
import { IHouseholdMember } from '../caremap/ICareMap';
import { InitialWizardState } from './InitialWizardState';
import { YesOrNoStep } from './YesOrNoStep';
import { ISupportGroupMemberStepProps, SupportGroupMemberStep } from './SupportGroupMemberStep';
import { ICommunitySubKey, SupportGroupDataProvider } from '../caremap/SupportGroupDataProvider';
import { CommonRolesStep, ICommonRoleStepProps } from './CommonRolesStep';
import { IntendedRecipientStep } from './IntendedRecipientStep';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { WizardTrackingService } from './WizardTrackingService';
import { CommonHealthInsuranceStep } from './CommonHealthInsuranceStep';
import { IAvatarPhotoColor } from '../avatar/PhotoAvatarOrColorPicker';
import { UserState } from '../user/UserState';
import { AppPrincipal } from '../auth/AppPrincipal';
import { ConsentCheckboxStep, IConsentType } from './ConsentCheckboxStep';
import { useToast } from '../components/useToast';
import { HelpCenterState } from '../support/help-center/HelpCenterState';
import { HistoryViewModel } from '../support/HistoryViewModel';

const useStyles = createUseStyles({
    buttonBar: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 10,
        paddingRight: 10,

        '& > ion-button': {
            width: 120
        }
    },

    progress: {
        top: 0,
        bottom: 'unset'
    }
});

interface IYesNoQuestion {
    questionId: string;
    onAnswered: (answer: boolean) => void;
    getQuestion: () => string;
    subTitle?: string;
    labels?: {
        yes: string;
        no: string;
    }
}

export const InitialWizard: React.FC = () => {
    const tracking = useInstance(MixpanelService);
    const wizardTracking = useInstance(WizardTrackingService);

    const classes = useStyles();
    const [present] = useToast();

    const wizard = useInstance(InitialWizardState);
    useSubscription(() => wizard);

    const user = useInstance(UserState);
    useSubscription(() => user);

    const principal = useInstance(AppPrincipal);

    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);

    const history = useInstance(HistoryViewModel);

    // need to instantiate so that it can subscribe to the BufferedEventBus and checklists get added
    useInstance(HelpCenterState);

    const supportGroupDataProvider = useInstance(SupportGroupDataProvider);

    const sendFinishedMetrics = () => {
        tracking.track('Initial Questionnaire Completed', () => {
            const schoolMembers = careMapStateInstance.getActiveCaremap()!.school.members.length;
            const communityMembers = careMapStateInstance.getActiveCaremap()!.community.members.length;
            const financialMembers = careMapStateInstance.getActiveCaremap()!.financial.members.length;
            const medicalMembers = careMapStateInstance.getActiveCaremap()!.medical.members.length;
            const specialistsMembers = careMapStateInstance.getActiveCaremap()!.specialists.members.length;

            const totalMembers = schoolMembers + communityMembers + financialMembers + specialistsMembers + medicalMembers;

            return {
                '# of Completed Care Maps': careMapStateInstance.getCareMaps().filter(m => m.essentialMapComplete ? m : null).length,
                '# of Household Members Added': careMapStateInstance.getActiveCaremap()!.household.members.length,
                '# of Total Care Members Added': totalMembers,
                '# of Care Members Per [school]': schoolMembers,
                '# of Care Members Per [community]': communityMembers,
                '# of Care Members Per [financial]': financialMembers,
                '# of Care Members Per [medical]': medicalMembers,
                '# of Care Members Per [specialists]': specialistsMembers,
            };
        });
    };

    const onNextStep = async () => {
        // an href was clicked, so we don't need to do anything to go to the next step
        // unless it is the last step

        wizardTracking.onFinishedStep();

        if (user.isDirty){
            try {
                await user.save();
            }
            catch (e: any) {
                present({duration: 3000, message: e.displayMessage});
            }
            if (careMapStateInstance.isUserThePatient()){
                careMapStateInstance.setPatientFirstName(user.state.firstName || '');
                careMapStateInstance.setPatientLastName(user.state.lastName || '');
            }
        }

        // if consented, record the timestamp (once)
        (['consent-self', 'consent-someone-else'] as IConsentType[]).forEach((consentType) => {
            if (careMapStateInstance.getActiveCaremap()?.initialWizardAnswers[consentType] && !careMapStateInstance.getActiveCaremap()?.initialWizardAnswers[`${consentType}-date`]){
                careMapStateInstance.answerInitialWizardQuestion({
                    questionId: `${consentType}-date`,
                    answer: {
                        by: principal.userId,
                        at: new Date().toISOString()
                    }
                });
            }
        });

        if (wizard.isLastStep()) {
            // then we need to block in case there is an error
            // so the user is still in the wizard to fix the error
            try {
                await careMapStateInstance.save({ isComplete: true });
                sendFinishedMetrics();
                history.push(`/caremaps/${careMapId}`);
            } catch (e: any) {
                present({duration: 3000, message: e.displayMessage});
            }
        }
    };

    const yesNoQuestions: IYesNoQuestion[] = [
        {
            // NOTE: this is coupled to the routes used in InitialWizardState
            questionId: 'school',
            getQuestion: () => careMapStateInstance.getLabel('ATTEND_SCHOOL'),
            onAnswered: (answer: boolean) => {
                if (answer){
                    careMapStateInstance.addOrUpdateSupportGroup({supportGroupKey: 'school' });
                }
                else {
                    careMapStateInstance.removeSupportGroup({supportGroupKey: 'school' });
                }
            }
        },
        {
            // NOTE: this is coupled to the routes used in InitialWizardState
            questionId: 'community',
            getQuestion: () => careMapStateInstance.getLabel('PARTICIPATE_IN_COMMUNITY'),
            onAnswered: () => {
                const relevantQuestions = ['community', 'community2'];
                if (relevantQuestions.some(questionId => careMapStateInstance.getActiveCaremap()!.initialWizardAnswers[questionId])){
                    careMapStateInstance.addOrUpdateSupportGroup({supportGroupKey: 'community' });
                } else {
                    careMapStateInstance.removeSupportGroup({supportGroupKey: 'community' });
                }
            }
        },
        {
            // NOTE: this is coupled to the routes used in InitialWizardState
            questionId: 'community2',
            getQuestion: () => careMapStateInstance.getLabel('PARTICIPATE_IN_COMMUNITY_2'),
            onAnswered: () => {
                // TODO: duplicated
                const relevantQuestions = ['community', 'community2'];
                if (relevantQuestions.some(questionId => careMapStateInstance.getActiveCaremap()!.initialWizardAnswers[questionId])){
                    careMapStateInstance.addOrUpdateSupportGroup({supportGroupKey: 'community' });
                } else {
                    careMapStateInstance.removeSupportGroup({supportGroupKey: 'community' });
                }
            }
        },
        {
            // NOTE: this is coupled to the routes used in InitialWizardState
            questionId: 'recipient-self',
            getQuestion: () => `Are you creating a care map for yourself or someone else, like your child or parent?`,
            onAnswered: (isUserRecipient: boolean) => {
                if (isUserRecipient){
                    careMapStateInstance.setPatientFirstName(user.state.firstName || '');
                    careMapStateInstance.setPatientLastName(user.state.lastName || '');
                    careMapStateInstance.answerInitialWizardQuestion({
                        questionId: 'recipient',
                        answer: MYSELF
                    });
                }
                else {
                    // no longer myself
                    careMapStateInstance.answerInitialWizardQuestion({
                        questionId: 'recipient',
                        answer: undefined
                    });

                    // clear it b/c it was user values
                    careMapStateInstance.setPatientFirstName('');
                    careMapStateInstance.setPatientLastName('');
                }
            },
            subTitle: 'Are you the Care Recipient, or are you managing care for someone else?',
            labels: {
                yes: 'Myself',
                no: 'Someone Else'
            }
        }
    ];

    const commonRoleQuestions: (Omit<ICommonRoleStepProps, 'setStepValidity'>)[] = [
        {
            supportGroupKey: 'school',
            getQuestion: () => careMapStateInstance.getLabel('SUPPORT_FROM_SCHOOL'),
        },
        {
            supportGroupKey: 'medical',
            getQuestion: () => careMapStateInstance.getLabel('SUPPORT_FROM_MEDICAL'),
        },
        {
            supportGroupKey: 'specialists',
            getQuestion: () => careMapStateInstance.getLabel('SUPPORT_FROM_SPECIALISTS'),
        },
    ];

    const allMemberLabels = careMapStateInstance.getAllSupportGroupLabels();

    const additionalRoleQuestions: (Pick<ISupportGroupMemberStepProps, 'supportGroupKey' | 'question' | 'subTitle' | 'groupMembers' | 'memberTitle' | 'roleLabel' | 'rolePlaceholder' | 'roleOptions'>)[] = [
        {
            supportGroupKey: 'school',
            question: careMapStateInstance.getLabel('ADDITIONAL_SCHOOL_MEMBERS'),
            subTitle: '(optional)',
            groupMembers: careMapStateInstance.getActiveCaremap()!.school.members,
            memberTitle: allMemberLabels['school'].memberTitle,
            roleLabel: `${allMemberLabels['school'].roleLabel}`,
            rolePlaceholder: allMemberLabels['school'].rolePlaceholder,
            roleOptions: supportGroupDataProvider.getSchoolSupportGroupMemberSelectOptions()
        },
        {
            supportGroupKey: 'medical',
            question: careMapStateInstance.getLabel('ADDITIONAL_MEDICAL_MEMBERS'),
            subTitle: '(optional)',
            groupMembers: careMapStateInstance.getActiveCaremap()!.medical.members,
            memberTitle: allMemberLabels['medical'].memberTitle,
            roleLabel: `${allMemberLabels['medical'].roleLabel}`,
            rolePlaceholder: allMemberLabels['medical'].rolePlaceholder,
            roleOptions: supportGroupDataProvider.getMedicalSupportGroupMemberSelectOptions()
        },
        {
            supportGroupKey: 'specialists',
            question: careMapStateInstance.getLabel('ADDITIONAL_SPECIALIST_MEMBERS'),
            subTitle: '(optional)',
            groupMembers: careMapStateInstance.getActiveCaremap()!.specialists.members,
            memberTitle: allMemberLabels['specialists'].memberTitle,
            roleLabel: `${allMemberLabels['specialists'].roleLabel}`,
            rolePlaceholder: allMemberLabels['specialists'].rolePlaceholder,
            roleOptions: supportGroupDataProvider.getSpecialistsSupportGroupMemberSelectOptions()
        }
    ];

    const additionalCommunityQuestions: (Pick<ISupportGroupMemberStepProps, 'question' | 'subTitle' | 'memberTitle' | 'nameLabel' | 'roleLabel' | 'rolePlaceholder'> & { subKey: ICommunitySubKey })[] = [
        {
            subKey: 'extracurricular',
            question: careMapStateInstance.getLabel('ADDITIONAL_EXTRACURRICULAR'),
            subTitle: '(optional)',
            memberTitle: 'Activity',
            nameLabel: 'Activity',
            roleLabel: careMapStateInstance.getLabel('SUPPORT_GROUP__COMMUNITY__ROLE_EXTRA_CURRICULAR'),
            rolePlaceholder: 'Search or select activity type'
        },{
            subKey: 'advocacy',
            question: careMapStateInstance.getLabel('ADDITIONAL_ADVOCACY'),
            subTitle: '(optional)',
            memberTitle: 'Group',
            nameLabel: 'Group',
            roleLabel: careMapStateInstance.getLabel('SUPPORT_GROUP__COMMUNITY__ROLE_ADVOCACY'),
            rolePlaceholder: 'Search or select group type'
        },{
            subKey: 'general',
            question: careMapStateInstance.getLabel('ANYONE_ELSE'),
            subTitle: '(optional)',
            memberTitle: 'Support',
            nameLabel: 'Support Name',
            roleLabel: careMapStateInstance.getLabel('SUPPORT_GROUP__COMMUNITY__ROLE_GENERAL'),
            rolePlaceholder: 'Search or select support type'
        }
    ];

    // helper to ensure we invoke this correctly and consistently from every step
    const buildStepValiditySetter = (routeProps: RouteComponentProps) => {
        return (isValid: boolean) => wizard.setStepValidity({ isValid, stepPath: routeProps.location.pathname });
    };

    // this should be handled upsteam by ActiveQuestionnaireProvider
    // but there seems to be some cases where it is percolating through
    if (!careMapStateInstance.getActiveCaremap()){
        return null;
    }

    const careMapId = careMapStateInstance.getActiveCaremap()?.careMapId;

    return (
        <IonPage data-id="initial-wizard">
            <IonContent scrollY={false}>
                <IonRouterOutlet>
                    <Route exact path="/questionnaire" render={(props) => {
                        if (careMapStateInstance.getActiveCaremap()!.essentialMapComplete) {
                            return <Redirect to="/caremaps" />;
                        } else {
                            return <Redirect to={wizard.getDefaultRoutePath()} />;
                        }
                    }}>
                    </Route>

                    {/* NOTE: this is coupled to the routes used in InitialWizardState */}
                    <Route exact path={`/questionnaire/${careMapId}/recipient`} render={routeProps => (
                        <IntendedRecipientStep
                            stepId="recipient"
                            setStepValidity={buildStepValiditySetter(routeProps)}
                        />
                    )}/>

                    {/* NOTE: this is coupled to the routes used in InitialWizardState */}
                    <Route exact path={`/questionnaire/${careMapId}/my-name`} render={routeProps => (
                        <NameStep
                            data-id="my-name"
                            question="What is your name?"
                            setStepValidity={buildStepValiditySetter(routeProps)}
                            firstName={user.state.firstName}
                            lastName={user.state.lastName}
                            onFirstNameChange={user.setFirstName}
                            onLastNameChange={user.setLastName}
                        />
                    )}/>
                    {(['consent-self', 'consent-someone-else'] as IConsentType[]).map(stepId => (
                        <Route exact path={`/questionnaire/${careMapId}/${stepId}`} key={stepId} render={routeProps => (
                            <ConsentCheckboxStep
                                stepId={stepId}
                                setStepValidity={buildStepValiditySetter(routeProps)}
                            />
                        )}/>
                    ))}

                    {/* NOTE: this is coupled to the routes used in InitialWizardState */}
                    <Route exact path={`/questionnaire/${careMapId}/name`} render={routeProps => (
                        <NameStep
                            data-id="care-recipient-name"
                            question="Who is the care recipient?"
                            setStepValidity={buildStepValiditySetter(routeProps)}
                            firstName={careMapStateInstance.getPatientFirstName()}
                            lastName={careMapStateInstance.getPatientLastName()}
                            onFirstNameChange={careMapStateInstance.setPatientFirstName}
                            onLastNameChange={careMapStateInstance.setPatientLastName}
                        />
                    )}/>

                    {/* NOTE: this is coupled to the routes used in InitialWizardState */}
                    <Route exact path={`/questionnaire/${careMapId}/dob`} render={routeProps => (
                        <DOBStep
                            setStepValidity={buildStepValiditySetter(routeProps)}
                            dob={careMapStateInstance.getPatientDOB()}
                            onChange={careMapStateInstance.setPatientDOB}
                        />
                    )} />

                    {/* NOTE: this is coupled to the routes used in InitialWizardState */}
                    <Route exact path={`/questionnaire/${careMapId}/household`} render={routeProps => (
                        <HouseholdStep
                            setStepValidity={buildStepValiditySetter(routeProps)}
                            householdMembers={careMapStateInstance.getActiveCaremap()!.household.members || []}
                            saveHouseholdMembers={careMapStateInstance.updateHouseholdMembers}
                        />
                    )}/>

                    {/* patient */}
                    {/* NOTE: this is coupled to the routes used in InitialWizardState */}
                    <Route exact path={`/questionnaire/${careMapId}/patient-photo`} render={(routeProps) => {
                        const onAvatarChanged = (changed: IAvatarPhotoColor) => {
                            careMapStateInstance.updatePatient({
                                ...careMapStateInstance.getActiveCaremap()!.patient,
                                ...changed
                            });

                            if (changed.photoDataURI && !changed.photo){
                                // if we don't yet have antyhing to save, then don't save yet
                            } else {
                                careMapStateInstance.enqueueSave();
                            }
                        };

                        return (
                            <PhotoStep
                                setStepValidity={buildStepValiditySetter(routeProps)}
                                subTitle="(optional)"
                                question={careMapStateInstance.getLabel('PHOTO_FOR_CARE_RECIPIENT')}
                                avatar={careMapStateInstance.getActiveCaremap()!.patient}
                                onAvatarChanged={onAvatarChanged}
                            />
                        );
                    }}>
                    </Route>

                    {/* NOTE: this is coupled to the routes used in InitialWizardState */}
                    <Route exact path={`/questionnaire/${careMapId}/household-photo/:memberId`} render={routeProps => {
                        const memberId: string = routeProps.match.params.memberId;
                        const member: IHouseholdMember = careMapStateInstance.getHouseholdMember(memberId);

                        let question = '';
                        if (member.firstName){
                            question = `Add a photo or choose a visual to represent ${member.firstName}:`;
                        }
                        else {
                            question = careMapStateInstance.getLabel('PHOTO_FOR_MEMBER_WITH_NO_NAME', { relationship: member.relationship });
                        }

                        return (
                            <PhotoStep
                                memberId={memberId}
                                setStepValidity={buildStepValiditySetter(routeProps)}
                                question={question}
                                subTitle="(optional)"
                                avatar={member}
                                onAvatarChanged={avatarProps => {
                                    careMapStateInstance.updateHouseholdMember({
                                        ...member,
                                        ...avatarProps
                                    });
                                }}
                            />
                        );
                    }} />

                    {/* yes / no questions */}
                    {yesNoQuestions.map(({ questionId, getQuestion, onAnswered, subTitle, labels }) => (
                        <Route key={questionId} exact path={`/questionnaire/${careMapId}/${questionId}`} render={routeProps => {
                            return (
                                <YesOrNoStep
                                    stepId={routeProps.match.path}
                                    currentAnswer={(careMapStateInstance.getActiveCaremap()!.initialWizardAnswers || {})[questionId]}
                                    question={getQuestion()}
                                    labels={labels}
                                    subTitle={subTitle}
                                    setStepValidity={buildStepValiditySetter(routeProps)}
                                    questionAnswered={(answer: boolean) => {
                                        careMapStateInstance.answerInitialWizardQuestion({ questionId, answer });
                                        onAnswered(answer);
                                    }} />
                            );
                        }} />
                    ))}

                    {commonRoleQuestions.map((props) => (
                        <Route
                            exact
                            key={`${props.supportGroupKey}-common-roles`}
                            path={`/questionnaire/${careMapId}/${props.supportGroupKey}/common`}
                            render={routeProps => (
                                <CommonRolesStep
                                    setStepValidity={buildStepValiditySetter(routeProps)}
                                    {...props}
                                />
                            )} />
                    ))}
                    <Route
                        exact
                        path={`/questionnaire/${careMapId}/financial/common`}
                        render={(routeProps) => <CommonHealthInsuranceStep setStepValidity={buildStepValiditySetter(routeProps)} /> }
                    />

                    {additionalRoleQuestions.map((props) => {
                        const path = `/questionnaire/${careMapId}/${props.supportGroupKey}/additional`;
                        return (
                            <Route key={`${props.supportGroupKey}-additional-roles`} exact path={path} render={routeProps => (
                                <SupportGroupMemberStep
                                    {...props}
                                    stepId={`${props.supportGroupKey}/additional`}
                                    addMember={careMapStateInstance.addSupportGroupMember}
                                    removeMember={careMapStateInstance.removeSupportGroupMember}
                                    updateMember={careMapStateInstance.updateSupportGroupMember}
                                    setStepValidity={buildStepValiditySetter(routeProps)}
                                />
                            )} />
                        );
                    })}

                    {additionalCommunityQuestions.map((props) => {
                        const subKeyLowerCase = props.subKey.toLowerCase();
                        const path = `/questionnaire/${careMapId}/community/${subKeyLowerCase}`;
                        return (
                            <Route key={`community-${subKeyLowerCase}`} exact path={path} render={() => (
                                <SupportGroupMemberStep
                                    {...props}
                                    canEditName
                                    supportGroupKey="community"
                                    stepId={`community/${subKeyLowerCase}`}
                                    roleOptions={supportGroupDataProvider.getCommunitySupportGroupMemberSelectOptions(props.subKey)}
                                    groupMembers={careMapStateInstance.getActiveCaremap()!.community.members}
                                    addMember={careMapStateInstance.addSupportGroupMember}
                                    removeMember={careMapStateInstance.removeSupportGroupMember}
                                    updateMember={careMapStateInstance.updateSupportGroupMember}
                                    setStepValidity={(isValid) => wizard.setStepValidity({ isValid, stepPath: path })}
                                />
                            )} />
                        );
                    })}
                </IonRouterOutlet>
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonProgressBar value={wizard.getCurrentStepIndex() / wizard.getNumberOfSteps()} className={classes.progress}></IonProgressBar>
                    <div className={classes.buttonBar}>
                        <IonButton
                            data-type="wizard-back"
                            routerDirection="back"
                            fill="outline"
                            style={{visibility: wizard.getPreviousRoute() ? 'visible': 'hidden'}}
                            routerLink={wizard.getPreviousRoute()}>Back
                        </IonButton>
                        <IonButton
                            data-type="wizard-next"
                            routerDirection="forward"
                            routerLink={wizard.getNextRoute()}
                            onClick={onNextStep}
                            disabled={!wizard.canGoToNextStep()}>
                            {wizard.isLastStep() ? 'Done' : 'Next'}
                        </IonButton>
                    </div>
                </IonToolbar>
            </IonFooter>
        </IonPage>
    );
};
