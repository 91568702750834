import { State } from '@meraki-internal/state';

// TODO: consolidate OrgNavigationMenuState??
export type ISelectedNavItem = 'caremap' | 'caregiver' | undefined;
export class NavigationMenuState extends State<{isOpen: boolean, selectedNavItem: ISelectedNavItem}> {
    constructor() {
        super({
            isOpen: false,
            selectedNavItem: 'caremap'
        });
    };

    setOpen = (isOpen: boolean) => {
        this.setState({ isOpen });

        // set a class on body to use for styling global elements
        // outside react (e.g. intercom launcher)
        const className = 'navigation-menu-open';
        if (isOpen) {
            document.body.classList.add(className);
        } else {
            document.body.classList.remove(className);
        }
    };

    isOpen = () => {
        return Boolean(this.state?.isOpen);
    };

    setSelectedNavItem = (selectedNavItem: ISelectedNavItem) => {
        this.setState({ selectedNavItem });
    };
}
