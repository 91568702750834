import { FC } from 'react';
import { SearchableSelectField, ISelected, InternalSelect } from '../components/SearchableSelectField';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { SupportGroupDataProvider } from '../caremap/SupportGroupDataProvider';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { CustomTypeAddedMetaBuilder } from '../metrics/CustomTypeAddedMetaBuilder';

interface IDiagnosisSelectFieldProps {
    label: string;
    diagnosisId?: string;
    diagnosis?: string;
    marginBottom?: number;
    innerRef?: React.Ref<InternalSelect<ISelected<string>>>;
    onChange: (params: { diagnosisId?: string; diagnosis?: string; }) => void;
    onBlur?: () => void;
}

export const DiagnosisSelectField: FC<IDiagnosisSelectFieldProps> = ({ label, diagnosisId, diagnosis, marginBottom, innerRef, onChange, onBlur }) => {
    const tracking = useInstance(MixpanelService);
    const dataProvider = useInstance(SupportGroupDataProvider);

    let selected: ISelected | undefined;
    if (diagnosis){
        selected = {
            value: diagnosisId,
            label: diagnosis
        };
    }

    const sendCustomDiagnosisMetric = (newlySelected?: ISelected) => {
        if (newlySelected?.label && !newlySelected.value ){
            tracking.track('Custom Type Added', () => new CustomTypeAddedMetaBuilder()
                .origin('caremap')
                .patient()
                .field({ field: 'diagnosis', fieldLabelKey: 'CARE_RECIPIENT_DIAGNOSIS', value: newlySelected?.label  })
                .build()
            );
        }
    };

    const onSelected = (newlySelected?: ISelected) => {
        sendCustomDiagnosisMetric(newlySelected);

        onChange({
            diagnosisId: newlySelected?.value || undefined,
            diagnosis: newlySelected?.label || undefined
        });
    };

    return (
        <SearchableSelectField
            data-type="diagnosis-dropdown"
            innerRef={innerRef}
            label={label}
            placeholder="Search or select diagnosis"
            autoLookupOnEmpty={true}
            marginBottom={marginBottom}
            selected={selected}
            options={dataProvider.getDiagnosisSelectOptions()}
            onChange={onSelected}
            onBlur={onBlur}
        />
    );
};
