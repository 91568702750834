import { FC, ReactElement, useEffect } from 'react';
import { IonCheckbox } from '@ionic/react';
import { Step, IStepValidProps } from './Step';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { createUseStyles } from 'react-jss';
import { useSubscription } from '@meraki-internal/state';

const useStyles = createUseStyles({
    label: {
        paddingTop: 15,
        color: 'var(--ion-color-step-700)',
        '& p': {
            marginTop: -3
        }
    }
});

export type ICheckboxStepProps = IStepValidProps & {
    question: string | ReactElement;
    stepId: string;
    checkboxLabel: string;
    validate?: (isChecked?: boolean) => boolean;
    disabled?: boolean;
}

const validateForRequired = (isChecked?: boolean) => isChecked !== undefined;

export const CheckboxStep: FC<ICheckboxStepProps> = ({ question, setStepValidity, stepId, checkboxLabel, validate = validateForRequired, disabled }) => {
    const caremap = useInstance(CareMapState);
    useSubscription(() => caremap);

    const classes = useStyles();
    const isChecked = caremap.getActiveCaremap()?.initialWizardAnswers[stepId] as boolean | undefined;

    useEffect(() => {
        setStepValidity(validate(isChecked));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (checked: boolean) => {
        if (disabled) {
            return;
        }

        caremap.answerInitialWizardQuestion({
            questionId: stepId,
            answer: checked
        });

        setStepValidity(validate(checked));
    };

    return (
        <Step question={question} data-id={stepId}>
            <div style={{display: 'flex', alignItems: 'start'}}>
                <div style={{padding: 15}} onClick={() => handleChange(!isChecked)}>
                    <IonCheckbox
                        disabled={disabled}
                        checked={isChecked}
                        onClick={(e) => e.stopPropagation()}
                        onIonChange={e => handleChange(e.detail.checked)}
                    />
                </div>

                {/* render label separately because IonCheckbox won't let us set alignment */}
                <div className={classes.label} onClick={() => handleChange(!isChecked)}>
                    {checkboxLabel.split('\n').map((para, idx) => (
                        <p key={`label-key-${idx}`}>{para}</p>
                    ))}
                </div>
            </div>
        </Step>
    );
};
