import { FC, useEffect } from 'react';
import { Step, IStepValidProps } from './Step';
import { IAvatarPhotoColor, PhotoAvatarOrColorPicker } from '../avatar/PhotoAvatarOrColorPicker';

export type IPhotoStepProps = IStepValidProps & {
    memberId?: string;
    question: string;
    subTitle?: string;
    defaultAvatar?: string;
    avatar?: string;
    color?: string;
    uploadPhoto: (file: File) => Promise<void>;
    onColorChange: (color: string) => void;
    onAvatarChange: (avatar: string) => void;
}

export const PhotoStep: FC<{
    memberId?: string;
    question: string;
    subTitle?: string;
    avatar: IAvatarPhotoColor;
    onAvatarChanged: (changed: IAvatarPhotoColor) => void;
    setStepValidity: (isValid: boolean) => void
}> = ({
    memberId,
    question,
    subTitle,
    setStepValidity,
    avatar,
    onAvatarChanged,
}) => {
    const isValid = Boolean(avatar.photo || avatar.avatar || avatar.color);

    useEffect(() => {
        setStepValidity(isValid);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setStepValidity(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid, question]);

    return (
        <Step
            data-id={`photo-step-${memberId ? `member-${memberId}` : 'patient'}`}
            question={question}
            subTitle={subTitle}
        >
            <PhotoAvatarOrColorPicker
                avatar={avatar}
                onAvatarChanged={change => {
                    onAvatarChanged(change);
                }}
            />

        </Step>
    );
};
