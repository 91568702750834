import { FC } from 'react';
import { NEW_APPOINTMENT_ID } from './AppointmentsState';
import { PaywallAwareButton } from '../components/PaywallAwareButton';

export type IAddAppointmentButtonProps = {
    canAdd: boolean;
    onClick: (apptId: string) => void;
};

export const AddAppointmentButton: FC<IAddAppointmentButtonProps> = ({ canAdd, onClick }) => {
    return canAdd && (
        <PaywallAwareButton
            fill="clear"
            triggerSource="add appointment"
            data-id="add-appointment-button"
            requiresInnerhivePlus={() => true}
            onClick={() => onClick(NEW_APPOINTMENT_ID)}
        >
            +&nbsp;Add Appointment
        </PaywallAwareButton>
    );
};
