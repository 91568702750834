import { IonButton } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { InnerhivePlusDialogViewModel } from './InnerhivePlusDialogViewModel';
import { RevenueCatModel } from './revenue-cat/RevenueCatModel';
import { useSubscription } from '@meraki-internal/state';
import { InnerhivePlusIcon } from '../theme/Icons';

export const InnerhivePlusMenuButton: React.FC = () => {
    const dialogVM = useInstance(InnerhivePlusDialogViewModel);

    const revenueCat = useInstance(RevenueCatModel);
    useSubscription(() => revenueCat);

    if (!revenueCat.canHaveInnerhivePlus() || revenueCat.hasInnerhivePlus()){
        return null;
    }

    return (
        <IonButton expand="block" color="secondary" onClick={() => dialogVM.open({ triggerSource: 'menu button'})} data-id="innerhive-plus-button" data-type="upgrade">
            <InnerhivePlusIcon color="light" />
            <span style={{paddingLeft: 10}}>Get Innerhive Plus</span>
        </IonButton>
    );
};
