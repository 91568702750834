import { IonButton, IonContent, IonPopover, IonText } from '@ionic/react';
import { FC, useState } from 'react';
import { DrawerSectionHeading } from '../components/DrawerSectionHeading';
import { ArrowRightArrowLeftIcon } from '../theme/Icons';
import { MenuItem } from '../components/MenuItem';
import { Divider } from '../components/Divider';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { ICareMap } from '../caremap/ICareMap';
import { createUseStyles } from 'react-jss';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { AddCareMapButton } from '../caremap/AddCareMapButton';
import { AppPrincipal, isCaremapViewer } from '../auth/AppPrincipal';
import { OrganizationsNavigationMenu } from '../organizations/OrganizationsNavigationMenu';
import { CareMapMenuItem } from '../caremap/CareMapMenuItem';
import { NavigationItem } from './NavigationItem';
import { OrganizationsState } from '../organizations/OrganizationsState';
import { NavigationItemList } from './NavigationItemList';
import { NavigationCurrentEntity } from './NavigationCurrentEntity';
import { NavigationMenuState } from './NavigationMenuState';

type IStyleKeys = 'content' | 'recipientMenuPopover';
interface IStyleProps {
    width: number;
};

const useStyles = createUseStyles<IStyleKeys, IStyleProps>({
    content: {
        '--padding-top': '36px'
    },

    recipientMenuPopover: {
        '& hr': {
            marginBlockStart: 0,
            marginBlockEnd: 0
        },

        '&::part(content)': {
            minWidth: ({width}) => `${width-50}px`,
            maxWidth: ({width}) => `${width-50}px`
        }
    }
});

export type INavigationContent = {
    popoverWidth: number;
    closeMenuHandler: () => void;
};

export const NavigationContent: FC<INavigationContent> = ({ popoverWidth, closeMenuHandler }) => {
    const classes = useStyles({ width: popoverWidth });
    const [isRecipientMenuOpen, setRecipientMenuOpen] = useState<boolean>(false);

    const tracking = useInstance(MixpanelService);
    const history = useInstance(HistoryViewModel);

    const isViewer = isCaremapViewer(useInstance(AppPrincipal));

    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);

    const orgState = useInstance(OrganizationsState);
    useSubscription(() => orgState);

    const navMenuState = useInstance(NavigationMenuState);
    useSubscription(() => navMenuState);

    const careMaps = careMapStateInstance.getCareMaps();
    const hasMultipleCareMaps = careMaps.length > 1;
    const activeCareMap = careMapStateInstance.getActiveCaremap();

    const showAddButton = (
        !isViewer && careMaps.length === 1 && activeCareMap?.essentialMapComplete
    ) || careMaps.length > 1;

    // specific to the individual caremap item, not org
    const onCareMapItemClick = () => {
        navMenuState.setSelectedNavItem('caremap');
        closeAndExecute(() => {
            history.push('/caremaps');
        });
    };

    const onCaregiverClick = () => {
        closeAndExecute(() => {
            history.push('/caregiver');
        });
    };

    const closeAndExecute = (handler: () => void) => {
        closeMenuHandler();
        setTimeout(() => {
            handler();
        }, 350);
    };

    const switchRecipient = (careMap: ICareMap) => {
        if (activeCareMap?.careMapId !== careMap.careMapId) {
            setRecipientMenuOpen(false);
            tracking.track('Care Recipient Switched');
            closeAndExecute(() => {
                history.push(`/caremaps/${careMap.careMapId}`);
            });
        }
    };

    const toggleRecipientMenu = () => {
        setRecipientMenuOpen(oldState => {
            return !oldState;
        });
    };

    return (
        <>
            <IonContent className={classes.content}>
                <OrganizationsNavigationMenu closeMenuHandler={closeMenuHandler} />

                {/* TODO: move to its own component? */}
                {!orgState.isOrgTeamMember() && careMapStateInstance.hasCompletedCareMap() && (
                    <NavigationItemList>
                        <NavigationItem
                            data-type="caregiver-nav-item"
                            label="Caregiver's Corner"
                            onClick={onCaregiverClick}
                            isSelected={navMenuState.state.selectedNavItem === 'caregiver'}
                            icon={
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" fill="currentColor" viewBox="0 0 576 512">
                                    <path d="M163.9 136.9c-29.4-29.8-29.4-78.2 0-108s77-29.8 106.4 0l17.7 18 17.7-18c29.4-29.8 77-29.8 106.4 0s29.4 78.2 0 108L310.5 240.1c-6.2 6.3-14.3 9.4-22.5 9.4s-16.3-3.1-22.5-9.4L163.9 136.9zM187.4 352c-7.9 0-15.6 2.3-22.2 6.7L85.3 412c-3.9 2.6-8.6 4-13.3 4H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H64.7l73.8-49.2C153 309.1 170 304 187.4 304H344c35.3 0 64 28.7 64 64c0 .7 0 1.3 0 2l64.9-40c10.7-6.6 22.9-10 35.5-10h7.4c33.3 0 60.3 27 60.3 60.3c0 20.4-10.4 39.5-27.5 50.6l-95.6 61.9c-19.4 12.6-42.1 19.3-65.2 19.3H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H387.7c13.9 0 27.5-4 39.1-11.6l95.6-61.9c3.5-2.3 5.6-6.1 5.6-10.3c0-6.8-5.5-12.3-12.3-12.3h-7.4c-3.6 0-7.2 1-10.3 2.9l-93.5 57.5c-3.8 2.3-8.1 3.6-12.6 3.6H344 320 256c-13.3 0-24-10.7-24-24s10.7-24 24-24h64 24c8.8 0 16-7.2 16-16s-7.2-16-16-16H187.4z"/>
                                </svg>
                            }
                        />
                    </NavigationItemList>
                )}

                {/* TODO: move to it's own component? */}
                {careMapStateInstance.getCareMaps().length > 0 && !orgState.isOrgTeamMember() && (
                    <>
                        {activeCareMap && (
                            <div style={{marginTop: 20}}>
                                <NavigationCurrentEntity>
                                    <div>
                                        <DrawerSectionHeading text="Care Recipient" tight></DrawerSectionHeading>
                                        <IonText>{careMapStateInstance.getPatientFullName() || 'New Recipient'}</IonText>
                                    </div>
                                    {hasMultipleCareMaps && (
                                        <IonButton
                                            fill="clear"
                                            style={{marginBottom: -8}}
                                            onClick={toggleRecipientMenu}
                                            id="switch-recipient-button"
                                        >
                                            <ArrowRightArrowLeftIcon />&nbsp;switch
                                        </IonButton>
                                    )}
                                </NavigationCurrentEntity>
                            </div>
                        )}
                        <NavigationItemList>
                            <div style={{marginTop: careMapStateInstance.hasActiveCareMap() ? 12 : undefined}}>
                                <NavigationItem
                                    label="Care Map"
                                    onClick={onCareMapItemClick}
                                    isSelected={navMenuState.state.selectedNavItem === 'caremap'}
                                    icon={
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="23" fill="currentColor" viewBox="0 0 25 23">
                                            <path fillRule="evenodd" d="M17.416.751c.537 0 1.034.287 1.302.752l5.298 9.176a1.504 1.504 0 0 1 0 1.504l-5.298 9.175a1.504 1.504 0 0 1-1.302.752H6.826a1.504 1.504 0 0 1-1.314-.772l-1.465-2.632a1 1 0 0 1 1.748-.973l1.323 2.377h10.011l5.012-8.68-5.012-8.679H7.107L5.72 5.155a1 1 0 0 1-1.732-1l1.531-2.652A1.504 1.504 0 0 1 6.821.751h10.595ZM2.88 8.683a1.005 1.005 0 1 1 1.005-1.74 1.005 1.005 0 0 1-1.005 1.74Zm-.368 5.996a1.005 1.005 0 1 0 1.74 1.005 1.005 1.005 0 0 0-1.74-1.005Z" clipRule="evenodd"/>
                                            <circle cx="1.373" cy="11.497" r="1.005" transform="rotate(120 1.373 11.497)"/>
                                        </svg>
                                    }
                                />
                            </div>
                        </NavigationItemList>
                        {showAddButton && (
                            <div style={{margin: 24}}>
                                <AddCareMapButton closeMenu={closeMenuHandler} />
                            </div>
                        )}
                        {/* switch care recipient menu */}
                        {hasMultipleCareMaps && careMapStateInstance.hasActiveCareMap() && (
                            <IonPopover
                                alignment="end"
                                trigger="switch-recipient-button"
                                isOpen={isRecipientMenuOpen}
                                onDidDismiss={() => setRecipientMenuOpen(false)}
                                className={classes.recipientMenuPopover}
                            >
                                <MenuItem data-id="switch-recipient" label="Switch Care Recipient" heading />
                                <Divider />
                                {careMaps.map((map, idx) => {
                                    const isLast = idx+1 === careMaps.length;
                                    return map.careMapId !== activeCareMap?.careMapId ? (
                                        <div key={map.careMapId}>
                                            <CareMapMenuItem careMap={map} onClick={switchRecipient} />
                                            {!isLast && <Divider />}
                                        </div>
                                    ) : null;
                                })}
                            </IonPopover>
                        )}
                    </>
                )}
            </IonContent>

        </>
    );
};
