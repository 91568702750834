export type ILabelKey =
    'HOUSEHOLD_MEMBER__RELATIONSHIP' |
    'CARE_RECIPIENT_RELATIONSHIP_TO_CARE_MAP_CREATOR' |
    'SUPPORT_GROUP__SCHOOL__ROLE' |
    'SUPPORT_GROUP__MEDICAL__ROLE' |
    'SUPPORT_GROUP__SPECIALISTS__ROLE' |
    'SUPPORT_GROUP__SOCIAL__ROLE' |
    'SUPPORT_GROUP__FINANCIAL__ROLE' |
    'SUPPORT_GROUP__COMMUNITY__ROLE' |
    'SUPPORT_GROUP__COMMUNITY__ROLE_EXTRA_CURRICULAR' |
    'SUPPORT_GROUP__COMMUNITY__ROLE_GENERAL' |
    'SUPPORT_GROUP__COMMUNITY__ROLE_ADVOCACY' |
    'CARE_RECIPIENT_DIAGNOSIS' |
    'CARE_RECIPIENT_ALLERGY' |
    'CARE_RECIPIENT_DOB' |
    'WHO_LIVES_WITH_CARE_RECIPIENT' |
    'PHOTO_FOR_CARE_RECIPIENT' |
    'ATTEND_SCHOOL' |
    'PARTICIPATE_IN_COMMUNITY' |
    'PARTICIPATE_IN_COMMUNITY_2' |
    'HAVE_DOCTOR' |
    'HAVE_DENTIST' |
    'HAVE_PHYSICAL_THERAPIST' |
    'HAVE_CLINICAL_THERAPIST' |
    'SUPPORT_FROM_SCHOOL' |
    'SUPPORT_FROM_MEDICAL' |
    'SUPPORT_FROM_SPECIALISTS' |
    'CARE_RECIPIENT' |
    'SHARE_CARE_MAP' |
    'HAVE_HEALTH_INSURANCE' |
    'ADDITIONAL_SCHOOL_MEMBERS' |
    'ADDITIONAL_MEDICAL_MEMBERS' |
    'ADDITIONAL_SPECIALIST_MEMBERS' |
    'ADDITIONAL_EXTRACURRICULAR' |
    'ADDITIONAL_ADVOCACY' |
    'ANYONE_ELSE' |
    'CONSENT' |
    'PHOTO_FOR_MEMBER_WITH_NO_NAME'
;

export class LabelProvider {
    use_MY_CAREMAP__PrefixIfApplicable = false;
    /**
     * These keys are also used in metrics (mix panel reports), but not the label itself.
     *
     * So changing the key or abandoning a key for a new key may be a breaking change to mix panel.
     *
     */
    labels = {
        HOUSEHOLD_MEMBER__RELATIONSHIP: 'Relationship to {{careRecipient}}',
        MY_CAREMAP__HOUSEHOLD_MEMBER__RELATIONSHIP: 'Relationship to you',
        CARE_RECIPIENT_RELATIONSHIP_TO_CARE_MAP_CREATOR: 'Relationship to {{careRecipient}}',
        SUPPORT_GROUP__SCHOOL__ROLE: 'School Team Member',
        SUPPORT_GROUP__MEDICAL__ROLE: 'Care Team Member',
        SUPPORT_GROUP__SPECIALISTS__ROLE: 'Specialist',

        // TODO
        SUPPORT_GROUP__SOCIAL__ROLE: 'TODO: Social Role',

        SUPPORT_GROUP__COMMUNITY__ROLE_EXTRA_CURRICULAR: 'Activity Type',
        SUPPORT_GROUP__COMMUNITY__ROLE_GENERAL: 'Support Type',
        SUPPORT_GROUP__COMMUNITY__ROLE_ADVOCACY: 'Group Type',
        SUPPORT_GROUP__COMMUNITY__ROLE: 'Support, Group, or Activity Type',
        SUPPORT_GROUP__FINANCIAL__ROLE: 'Support Name',
        CARE_RECIPIENT_DIAGNOSIS: 'Diagnosis',
        CARE_RECIPIENT_ALLERGY: 'Allergen',
        CARE_RECIPIENT_DOB: `What is {{careRecipient}}'s date of birth?`,
        MY_CAREMAP__CARE_RECIPIENT_DOB: `What is your date of birth?`,
        WHO_LIVES_WITH_CARE_RECIPIENT: 'Who lives with {{careRecipient}}?',
        MY_CAREMAP__WHO_LIVES_WITH_CARE_RECIPIENT: 'Who lives with you?',
        PHOTO_FOR_CARE_RECIPIENT: 'Add a photo or choose a visual to represent {{careRecipient}}:',
        MY_CAREMAP__PHOTO_FOR_CARE_RECIPIENT: 'Add a photo or choose a visual to represent yourself:',
        ATTEND_SCHOOL: 'Does {{careRecipient}} attend daycare, preschool, or school?',
        MY_CAREMAP__ATTEND_SCHOOL: 'Do you currently attend school?',
        PARTICIPATE_IN_COMMUNITY: 'Does {{careRecipient}} participate in any extracurricular programs or recreational activities?',
        MY_CAREMAP__PARTICIPATE_IN_COMMUNITY: 'Do you participate in any extracurricular programs or recreational activities?',
        PARTICIPATE_IN_COMMUNITY_2: 'Is {{careRecipient}} or their household a part of any support, advocacy, or communal groups?',
        MY_CAREMAP__PARTICIPATE_IN_COMMUNITY_2: 'Do you or your household participate in any support, advocacy, or communal groups?',
        HAVE_DOCTOR: 'Does {{careRecipient}} have a primary doctor?',
        MY_CAREMAP__HAVE_DOCTOR: 'Do you have a primary doctor?',
        HAVE_DENTIST: 'Does {{careRecipient}} have a dentist?',
        MY_CAREMAP__HAVE_DENTIST: 'Do you have a dentist?',
        HAVE_PHYSICAL_THERAPIST: 'Does {{careRecipient}} see a physical therapist?',
        MY_CAREMAP__HAVE_PHYSICAL_THERAPIST: 'Do you see a physical therapist?',
        HAVE_CLINICAL_THERAPIST: 'Does {{careRecipient}} see a clinical therapist?',
        MY_CAREMAP__HAVE_CLINICAL_THERAPIST: 'Do you see a clinical therapist?',
        SUPPORT_FROM_SCHOOL: 'At school, does {{careRecipient}} receive support from any of the people below?',
        MY_CAREMAP__SUPPORT_FROM_SCHOOL: 'At school, do you receive support from any of the people below?',
        SUPPORT_FROM_MEDICAL: 'Does {{careRecipient}} receive primary care from any of the people below?',
        MY_CAREMAP__SUPPORT_FROM_MEDICAL: 'Do you receive primary care from any of the people below?',
        SUPPORT_FROM_SPECIALISTS: 'Does {{careRecipient}} see any of the following specialists?',
        MY_CAREMAP__SUPPORT_FROM_SPECIALISTS: 'Do you see any of the following specialists?',
        CARE_RECIPIENT: 'Care Recipient',
        MY_CAREMAP__CARE_RECIPIENT: 'Care Recipient (you)',
        SHARE_CARE_MAP: `Share {{careRecipient}}'s Care Map`,
        MY_CAREMAP__SHARE_CARE_MAP: 'Share Your Care Map',
        HAVE_HEALTH_INSURANCE: 'Who does {{careRecipient}} receive health insurance coverage from?',
        MY_CAREMAP__HAVE_HEALTH_INSURANCE: 'Who do you receive health insurance coverage from?',
        ADDITIONAL_SCHOOL_MEMBERS: 'Does anyone else help {{careRecipient}} at school?',
        MY_CAREMAP__ADDITIONAL_SCHOOL_MEMBERS: 'Does anyone else help you at school?',
        ADDITIONAL_MEDICAL_MEMBERS: 'Does {{careRecipient}} receive primary care from anyone else?',
        MY_CAREMAP__ADDITIONAL_MEDICAL_MEMBERS: 'Do you receive primary care from anyone else?',
        ADDITIONAL_SPECIALIST_MEMBERS: `Who are {{careRecipient}}'s other specialists?`,
        MY_CAREMAP__ADDITIONAL_SPECIALIST_MEMBERS: 'Who are your other specialists?',
        ADDITIONAL_EXTRACURRICULAR: 'What recreational activities or extracurricular programs does {{careRecipient}} participate in?',
        MY_CAREMAP__ADDITIONAL_EXTRACURRICULAR: 'What recreational activities or extracurricular programs do you participate in?',
        ADDITIONAL_ADVOCACY: 'What support, advocacy, and communal groups is {{careRecipient}}, or their household, a part of?',
        MY_CAREMAP__ADDITIONAL_ADVOCACY: 'What support, advocacy, and communal groups are you or your household a part of?',
        ANYONE_ELSE: `Is there anything or anyone else that helps {{careRecipient}}, that you'd like to add to their care map?`,
        MY_CAREMAP__ANYONE_ELSE: `Is there anything or anyone else that helps you, that you'd like to add to your care map?`,
        CONSENT: 'I certify that I have permission to create a care map on behalf of the Care Recipient.\nThe Care Recipient consents that Innerhive can use the data I enter on their behalf for the purpose of creating and managing a care map.',
        MY_CAREMAP__CONSENT: 'I consent to Innerhive using the data I enter for the purpose of creating and managing a care map.',
        PHOTO_FOR_MEMBER_WITH_NO_NAME: `Add a photo or choose a visual for {{careRecipient}}'s {{relationship}}:`,
        MY_PHOTO_FOR_MEMBER_WITH_NO_NAME: `Add a photo or choose a visual for your {{relationship}}:`,
    } as { [key in ILabelKey]: string };

    getLabel = (labelName: ILabelKey, placeholders: any = {})  => {
        let label = '';

        if (this.use_MY_CAREMAP__PrefixIfApplicable){
            label = (this.labels as any)[`MY_CAREMAP__${labelName}`];
        }

        if (!label){
            label = this.labels[labelName];
        }

        if (!label){
            throw new Error(`${labelName} does not exist`);
        }
        for (const key of Object.keys(placeholders)){
            label = label.replaceAll(`{{${key}}}`,placeholders[key]);
        }

        if (label.includes('{{')){
            throw new Error(`label ${labelName} still has placeholders. It started as ${this.labels[labelName]} and after using placeholders ${JSON.stringify(placeholders)} it ended up as ${label}`);
        }

        return label;
    };
}
