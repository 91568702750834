import { State } from '@meraki-internal/state';
import { DrawerState } from '../components/DrawerState';

export class CareMapDrawerState extends State {

    static inject = () => [DrawerState];
    constructor(private drawerState: DrawerState) {
        drawerState.subscribe(state => {
            this.setState({ });
        });
        super({});
    }

    isOpen = () => this.drawerState.isOpen();

    isMaskingFocusedCareMap = false;
    setIsMaskingFocusedCareMap = (isMaskingFocusedCareMap: boolean) => {
        this.isMaskingFocusedCareMap = isMaskingFocusedCareMap;
        this.setState({ });
    };

    selectedMemberId?: string;
    setSelectedMemberId = (selectedMemberId: string) => {
        if (this.selectedMemberId !== selectedMemberId){
            this.selectedMemberId = selectedMemberId;
            this.setState({ });
        }
    };

    removeSelectedMemberId = () => {
        if (this.selectedMemberId){
            this.selectedMemberId = undefined;
            this.setState({ });
        }
    };
}
