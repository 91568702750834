import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from './CareMapState';
import { useState } from 'react';
import { useAsyncEffect } from '../support/useAsyncEffect';
import { Redirect } from 'react-router';
import { OrganizationsState } from '../organizations/OrganizationsState';

export const RedirectToDefaultCaremap: React.FC = () => {
    const caremapState = useInstance(CareMapState);
    const [state,  setState] = useState<{ defaultCaremapId?: string } | undefined>();

    const orgState = useInstance(OrganizationsState);

    useAsyncEffect(async () => {
        const defaultCaremapId = await caremapState.getDefaultCaremapId();
        setState({ defaultCaremapId });
    }, []);

    if (orgState.isOrgTeamMember()){
        // redirect to / so it can redirect to the default page
        return <Redirect to="/" />;
    }

    if (!state){
        return null;
    }

    if (state.defaultCaremapId){
        return <Redirect to={`/caremaps/${state.defaultCaremapId}`} />;
    }
    else {
        return <Redirect to="/questionnaire" />;
    }
};
