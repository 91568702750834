import { ReactNode, useEffect } from 'react';
import { CareMapState } from './caremap/CareMapState';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { AlertPresenter } from './AlertBinder';
import { HistoryViewModel } from './support/HistoryViewModel';
import { AppPrincipal, isCaremapViewer } from './auth/AppPrincipal';

export const BlockUntilActiveCaremap: React.FC<{children: ReactNode}> = ({ children }) => {
    const careMapState = useInstance(CareMapState);

    useSubscription(() => careMapState);

    return careMapState.hasActiveCareMap() ? children : null;
};

export const ActiveQuestionnaireProvider: React.FC<{children: ReactNode; careMapId?: string; }> = ({ children, careMapId }) => {
    const caremapState = useInstance(CareMapState);
    useSubscription(() => caremapState);

    useEffect(() => {
        if (!careMapId){
            caremapState.setActiveQuestionnaire();
        }

        return () => {
            if (!careMapId){
                caremapState.unsetCurrentCareMap();
            }
        };

    }, [careMapId, caremapState]);

    if (careMapId){
        return (
            <ActiveCareMapProviderV2 careMapId={careMapId}>
                {children}
            </ActiveCareMapProviderV2>
        );
    }
    return (
        <BlockUntilActiveCaremap>
            {children}
        </BlockUntilActiveCaremap>
    );
};

export const ActiveCareMapProviderV2: React.FC<{children: ReactNode; careMapId: string; }> = ({ children, careMapId }) => {
    const caremapState = useInstance(CareMapState);
    const alert = useInstance(AlertPresenter);
    const history = useInstance(HistoryViewModel);
    const isViewer = isCaremapViewer(useInstance(AppPrincipal));

    useEffect(() => {
        Promise.resolve().then(async () => {
            if (caremapState.getActiveCaremap()?.careMapId !== careMapId){
                try {
                    await caremapState.setActiveCaremap(careMapId);
                }
                catch (err: any){
                    if (err && err.errorCode === 'not-found'){
                        alert.showAlertV2({
                            header: 'Care Map Not Found',
                            message: `We're sorry, the care map you were previously viewing has been deleted, or you no longer have access to it.`
                        });
                        caremapState.unsetCurrentCareMap();
                        history.push('/');
                    }
                }
            }

        });

        return () => {
            if (caremapState.getActiveCaremap()?.careMapId === careMapId){
                caremapState.unsetCurrentCareMap();
            }
        };
    }, [careMapId, caremapState, history, alert, isViewer]);

    if (caremapState.getActiveCaremap()?.careMapId === careMapId){
        return children;
    }
    return null;
};
