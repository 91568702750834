import SemVer from 'semver';
import { ISO8601 } from '../ISO8601';

export type IUpdateStatus = 'no-update-needed' | 'should-update' | 'must-update';

type IVersion = string; // eg 1.2.3

export interface ICheckForUpdateParams { appVersion: IVersion; minVersion: IVersion; forceAfter: ISO8601; }

export class NativeVersionChecker {
    checkForUpdate = ({ appVersion, minVersion, forceAfter }: ICheckForUpdateParams): IUpdateStatus => {
        if (SemVer.gte(appVersion, minVersion)){
            return 'no-update-needed';
        }
        // if forceAfter is in the future
        else if (forceAfter > new Date().toISOString()){
            return 'should-update';
        }
        else {
            return 'must-update';
        }
    };
}
