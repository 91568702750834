import { FC, useState, useEffect } from 'react';
import { IHouseholdMember } from '../caremap/ICareMap';
import { Step, IStepValidProps } from './Step';
import { maxHouseholdMembers } from '../nodes/NodeAdapter';
import { TextField } from '../components/TextField';
import { RelationshipSelectField } from '../components/RelationshipSelectField';
import { CareMapFactory, householdMemberAvatars } from '../caremap/CareMapFactory';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { EditableList } from '../components/EditableList';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';

const isBlank = (m: IHouseholdMember) => !m.firstName?.trim() && !m.relationship;

export type IHouseholdProps = IStepValidProps & {
    householdMembers: IHouseholdMember[],
    saveHouseholdMembers: (members: IHouseholdMember[]) => void
}

export const HouseholdStep: FC<IHouseholdProps> = ({
    setStepValidity,
    householdMembers,
    saveHouseholdMembers
}) => {
    const [rows, setRows] = useState<IHouseholdMember[]>([...householdMembers]);
    const careMapFactory = useInstance(CareMapFactory);
    const careMap = useInstance(CareMapState);
    useSubscription(() => careMap);

    useEffect(() => {
        // household members are not required
        setStepValidity(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onMembersChanged = (members: IHouseholdMember[]) => {
        setRows(members);
        saveHouseholdMembers(members.filter(m => !isBlank(m)));
        setStepValidity(true);
    };

    const getNextAvatar = () => {
        return householdMemberAvatars[rows.length + 1];
    };

    return (
        <Step
            question={careMap.getLabel('WHO_LIVES_WITH_CARE_RECIPIENT')}
        >
            <EditableList
                items={rows}
                isBlank={isBlank}
                retainBlanks
                autoAddFirst
                preventAdd={rows.length >= maxHouseholdMembers}
                buttonLabel="Add Person"
                createNew={() => ({ id: careMapFactory.getNodeId(), firstName: '', avatar: getNextAvatar(), contactMethods: [], notes: [] })}
                onChange={onMembersChanged}

                renderRow={({ item, focusRef, onChange }) => {
                    return (
                        <>
                            <TextField
                                inputRef={focusRef}
                                name="firstName"
                                label="First Name"
                                value={item.firstName}
                                autocapitalize="words"
                                onInput={firstName => onChange({...item, firstName})}
                            />
                            <RelationshipSelectField
                                label={careMap.getLabel('HOUSEHOLD_MEMBER__RELATIONSHIP')}
                                relationshipId={item.relationshipId}
                                relationship={item.relationship}
                                onChange={r => onChange({...item, ...r})}
                            />
                            <div style={{height: 30}}>&nbsp;</div>
                        </>
                    );
                }}
            />
        </Step>
    );
};
