import { IAttachment } from '../notes/attachments/IAttachment';

export enum IFeeling {
    VeryGood = 1,
    Good = 2,
    Neutral = 3,
    Bad = 4,
    VeryBad = 5
}

export type IEmotion =
    'happy' |
    'excited' |
    'proud' |
    'appreciated' |
    'grateful' |
    'sad' |
    'angry' |
    'lonely' |
    'overwhelmed' |
    'stressed' |
    'afraid' |
    'calm' |
    'exhausted' |
    'frustrated' |
    'guilty' |
    'indifferent';

export type ILifeEvent =
    'good sleep' |
    'bad sleep' |
    'ok sleep' |
    'exercise' |
    'outside time' |
    'personal time' |
    'social time' |
    'drink water' |
    'breakfast' |
    'lunch' |
    'dinner' |
    'snacks';

export type ICareEvent =
    'personal care' |
    'meal prep' |
    'appointment' |
    'meeting' |
    'achievement' |
    'medication' |
    'exercise' |
    'social activity' |
    'cleaning' |
    'groceries' |
    'transport' |
    'incident' |
    'home visit' |
    'respite care' |
    'new routine' |
    'emergency';

export type ICheckinTag =
    IEmotion |
    ILifeEvent |
    ICareEvent;

export interface ICheckin {
    noteId: string;
    timestamp: string;
    feeling?: IFeeling;
    emotions?: IEmotion[];
    lifeEvents?: ILifeEvent[];
    careEvents?: ICareEvent[];
    note: string;
    attachments: IAttachment[]
};
