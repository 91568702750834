import { IAvatarFormState } from '../avatar/AvatarFormState';
import { IAvatarPhotoColor } from '../avatar/PhotoAvatarOrColorPicker';
import { ICareMap, IHouseholdMember, IPatient, ISupportGroupKey, SUPPORT_GROUP_KEYS } from '../caremap/ICareMap';

interface IGetFocusedCareMapParams {
    careMap: ICareMap;
    avatarForm: IAvatarFormState;
    memberForm?: IHouseholdMember;
    patientForm?: IPatient;
    focusOn: { supportGroupKey?: ISupportGroupKey; };
}
export class FocusedCareMapProvider {
    getFocusedCareMap = ({ careMap, focusOn, avatarForm, memberForm, patientForm }: IGetFocusedCareMapParams ): Partial<ICareMap> => {
        if (focusOn.supportGroupKey){
            return this.getSupportGroupFocusedCareMap({ careMap, supportGroupKey: focusOn.supportGroupKey as ISupportGroupKey});
        } else {

            return this.getHouseholdFocusedCareMap({ careMap, avatarForm, memberForm, patientForm });
        }
    };

    private getSupportGroupFocusedCareMap = ({ careMap, supportGroupKey}: { careMap: ICareMap; supportGroupKey: ISupportGroupKey; }): Partial<ICareMap> => {
        // create shallow copy with no household members
        const response: Partial<ICareMap> = {
            ...careMap,
            patient: undefined,
            household: undefined
        };

        // immutably, set isRelevant: true to only supportGroupKey (the one to focus on)
        for (const key of SUPPORT_GROUP_KEYS){
            response[key] = {
                ...careMap[key],
                isRelevant: key === supportGroupKey
            };
        }

        return response;
    };

    private getHouseholdFocusedCareMap = ({ careMap, avatarForm, memberForm, patientForm }: Omit<IGetFocusedCareMapParams, 'focusOn'>): Partial<ICareMap> => {
        // create shallow copy
        // incorporating form changes to avatar
        const response: Partial<ICareMap> = {
            ...careMap,
            patient: {
                ...careMap.patient,

                ...this.getAvatarPropertiesFromPatientForm(patientForm),
                // avatarForm needs to come after patientForm since avatar might be overriding member form
                // until they hit cancel / save
                ...(avatarForm.memberId === 'patient' ? avatarForm.avatar: {})
            },
            household: {
                ...careMap.household,
                members: careMap.household.members.map(member => ({
                    ...member,
                    ...this.getAvatarPropertiesFromMemberForm({ memberForm, memberId: member.id }),

                    // avatarForm needs to come after memberForm since avatar might be overriding member form
                    // until they hit cancel / save
                    ...(avatarForm.memberId === member.id ? avatarForm.avatar: {})
                }))
            }
        };

        // immutably, set isRelevant: false to all the support groups
        // so we can focus on household
        for (const key of SUPPORT_GROUP_KEYS){
            response[key] = {
                ...careMap[key],
                isRelevant: false
            };
        }

        return response;
    };

    private getAvatarPropertiesFromPatientForm = (patientForm?: IPatient): IAvatarPhotoColor => {
        if (!patientForm){
            return {};
        }
        return this.getAvatarProperties(patientForm);
    };

    private getAvatarProperties = (form: IAvatarPhotoColor): IAvatarPhotoColor => {
        const { color, avatar, photo, photoDataURI } = form;
        return { color, avatar, photo, photoDataURI };
    };

    private getAvatarPropertiesFromMemberForm = ({ memberForm, memberId }: { memberForm?: IHouseholdMember; memberId: string; }): IAvatarPhotoColor => {
        if (!memberForm || memberForm.id !== memberId){
            return {};
        }
        return this.getAvatarProperties(memberForm);
    };
}
