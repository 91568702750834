import { State } from '@meraki-internal/state';
import { RevenueCatAPIModel } from './revenue-cat/RevenueCatAPIModel';
import { RevenueCatModel } from './revenue-cat/RevenueCatModel';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { RevenueCatSDKModel } from './revenue-cat/RevenueCatSDKModel';
import { AsyncStorageProvider, IAsyncSmartStorageProvider } from '../support/AsyncStorageProvider';
import { ISO8601 } from '../support/ISO8601';

export type ITriggerPaywallSource = 'menu logo' | 'menu button' | 'care map limit' | 'attachment limit' | 'co-owner invite' | 'subscription expired' | 'connect calendar' | 'first questionnaire completed' | 'menu add appointment' | 'add appointment' | 'recurring';

export class InnerhivePlusDialogViewModel extends State<Record<string, never>> {

    private _isOpen = false;
    private lastSeenStorage: IAsyncSmartStorageProvider<ISO8601>;

    LAST_SEEN_STORAGE_KEY = 'paywall.last-seen';

    static inject = () => [RevenueCatModel, MixpanelService, RevenueCatSDKModel, AsyncStorageProvider];
    constructor(
        private revenueCat: RevenueCatAPIModel,
        private tracking: MixpanelService,
        private sdk: RevenueCatSDKModel,
        private storageProvider: AsyncStorageProvider
    ) {
        super({ });
        this.lastSeenStorage = storageProvider.getStringProvider(this.LAST_SEEN_STORAGE_KEY);
    }

    isOpen = () => this._isOpen;

    open = ({ triggerSource }: { triggerSource: ITriggerPaywallSource }) => {

        // if we're already subscribed, this doesn't count as a paywall event
        if (!this.revenueCat.hasInnerhivePlus()) {
            this.lastSeenStorage.set((new Date()).toISOString());
            this.tracking.track('Paywall Triggered', () => ({
                Location: triggerSource,
                'Free Care Map Tier Limit': this.revenueCat.getOfferMeta().freeCaremapsOwned,
                'Free Attachment Tier Limit': this.revenueCat.getOfferMeta().freeAttachmentsPerCaremap,
                'Free Co-Owner Tier Limit': 0,
                Offering: this.revenueCat.getOfferMeta().offering_id
            }));
        }

        this._isOpen = true;
        this.setState({ });
    };

    close = () => {
        this._isOpen = false;
        this.setState({ });
    };

    requirePurchase = async ({ triggerSource }: { triggerSource: ITriggerPaywallSource;  }) => {
        return new Promise((resolve, reject) => {
            try {
                this.open({ triggerSource });
                const unsubscribe = this.subscribe(() => {
                    if (!this.isOpen()) {
                        unsubscribe();
                        resolve(this.revenueCat.hasInnerhivePlus());
                    }
                });
            } catch (e) {
                reject(e);
            }
        });
    };
}
