import mixpanel from 'mixpanel-browser';
import { Logger } from '../support/Logger';
import { AppPrincipal } from '../auth/AppPrincipal';
import { EnvConfiguration } from '../config/EnvConfiguration';
import { UserState } from '../user/UserState';
import { BufferedEventBus } from '../utils/BufferedEventBus';
import { DomainToMixPanelAdapter } from './DomainToMixPanelAdapter';
import { MixPanelPageViewEventEmitter } from './MixPanelPageViewEventEmitter';
import { Container } from '@meraki-internal/react-dependency-injection';
import { UAParser } from 'ua-parser-js';

export type ITrackingContext = {[key: string]: any};

/**
 * This is a facade around mixpanel service and it supporting classes so consumers don't
 * have to reason about the inner workings. It also helps to use code to document how
 * it is all glued together
 *
 * It primarily trying to encapsulate
 *
 * 1. Lots to init on app start, and in the right order
 */
export class MixPanelServiceFacade {
    static inject = () => [
        Container
    ];
    constructor(
        private container: Container
    ){}

    init = () => {
        const log = this.container.get(Logger);
        try {
            const mixpanelService = this.container.get(MixpanelService);
            const domainToMixPanelAdapter = this.container.get(DomainToMixPanelAdapter);
            const mixPanelPageViewEventEmitter = this.container.get(MixPanelPageViewEventEmitter);
            const principal = this.container.get(AppPrincipal);
            const user = this.container.get(UserState);
            mixpanelService.init({ principal, user });

            domainToMixPanelAdapter.init();

            mixPanelPageViewEventEmitter.init();
        }
        catch (err: any) {
            log.info(`MixPanelServiceFacade.init failed. Attempting to a minimial mixpanel init. Likely failed b/c the container failed to fully start, eg invalid device. Erorr: ${err.toString()}`);
            this.container.get(MixpanelService).init({ });
        }

    };
}


/**
 * This is the class that actually talks to mix panel.
 *
 * Other classes talk to it through BufferedEventBridge
 *
 * This is not exported on purpose, see MixPanelServiceFacade and MixPanelEventEmitter
 */
class MixpanelService {
    static inject = () => [
        // only dependencies that are guaranteed to inject
        // all others should go through init and MixPanelServiceFacade
        BufferedEventBus,
        Logger,
        UAParser,
        EnvConfiguration
    ];
    constructor(
        private events: BufferedEventBus,
        private log: Logger,
        private uaParser: UAParser,
        private config: EnvConfiguration,
    ){}

    private status: 'instantiated' | 'initializing' | 'initialized' = 'instantiated';

    private contextForAll: ITrackingContext = {};

    init = ({ principal, user }: { principal?: AppPrincipal; user?: UserState; }) => {
        this.status = 'instantiated';
        if (this.status !== 'instantiated'){
            // don't init again, this should only happen when app start failed
            // and we aren't sure if init was called or not
            return;
        }

        this.log.info('TrackingService.init', { principal, user: user?.state });

        try {
            const device = this.uaParser.getDevice();

            this.contextForAll = {
                'Operating System Version': this.uaParser.getOS().version,
                'Device Vendor': device.vendor,
                'Device Model': device.model,
                'Device Type': device.type || '[No Type]'
            };

            if (!this.config.MIXPANEL_TOKEN) {
                this.log.info(`Disabling tracker for "${this.config.ENV}" env b/c no token`);
                return;
            }

            this.log.info(`Initializing tracker for "${this.config.ENV}" env`);
            mixpanel.init(this.config.MIXPANEL_TOKEN, { debug: false, persistence: 'localStorage' });

            this.log.info('init -- mixpanel.get_distinct_id()', mixpanel.get_distinct_id());
            this.status = 'initialized';
            if (principal){
                mixpanel.identify(principal.userId);

                mixpanel.people.set({
                    tokenType: principal.tokenType,
                    '$email': principal.email,
                });
            }

            this.events.on('MixPanelService.track', (payload) => {
                const { event, context } = payload as { event: string, context: ITrackingContext; };
                this.sendToMixPanel(event, context);
            });

            this.events.on('WizardTrackingService.relationshp-to-care-recipient-set', payload => {
                this.setRelationshipToCaremap(payload as string);
            });

            this.events.on('AuthService.signing-out', () => {
                this.reset();
            });

            // don't add more bindings here unless they need access to internal like signing out needs to clear the mixpanel cookies / local storage
            // if you just want to bind to send a mixpanel event, consider
            // 1. MixPanelEventEmitter if you just need to send an event
            // 2. DomainToMixPanelAdapter if you want to bind a domain event to a mixpanel event (eg UserState.saved)

        } catch (e: any) {
            this.log.error(new Error(`Failed to initialize tracking: ${e.message}`));
        }
    };

    /**
     * This will cause the last caremap they created to be their relationship.
     *
     * But Aland preferred this for ease of reporting (over looking for users with an event where they created a caremap as a parent)
     */
    setRelationshipToCaremap = (relationship: string) => {
        if (!this.log){
            throw new Error('expected init before this method was called');
        }
        this.log.info('TrackingService.setRelationshipToCaremap', { relationship, initialized: this.status });
        try{
            if (this.status === 'initialized'){
                mixpanel.people.set('relationship', relationship);
            }
        } catch (err: any){
            this.log.error(new Error(`Failed to setRelationshipToCaremap: ${err.message}`));
        }
    };

    private reset = () => {
        try {
            if (this.status === 'initialized') {
                mixpanel.reset();
            }

        } catch (e: any) {
            this.log.error(new Error(`Failed to reset tracker: ${e.message}`));
        }
    };

    private sendToMixPanel = (event: string, context?: ITrackingContext) => {
        context = {...context, ...this.contextForAll};
        this.log.info(`Track "${event}"`, context);

        if (this.status === 'initialized') {
            mixpanel.track(event, context);
        }
    };
}
