import { IonMenu, IonRouterOutlet } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { Route } from 'react-router-dom';
import { useSubscription } from '@meraki-internal/state';
import { DrawerState } from './DrawerState';
import { RefObject } from 'react';

// NOTE: if you change this, also need to change intercom and drawer-alert styling in app.css,
// and consider changing MAX_MODAL_WIDTH in DrawerPage
export const FINAL_DRAWER_WIDTH = 450;

type IStyleKeys = 'menu';
interface IStyleProps { hideBackdrop?: boolean };

const useStyles = createUseStyles<IStyleKeys, IStyleProps>({
    menu: {
        '&::part(container)': {
            minWidth: `calc(min(${FINAL_DRAWER_WIDTH}px, 100%))`
        },
        '&::part(backdrop)': {
            display: ({hideBackdrop}) => hideBackdrop ? 'none' : undefined
        }
    }
});

export interface IDrawerProps {
    basePath: string;
    returnToPath: string;
    hideBackdrop?: boolean;
    onClose?: () => void;
    menuRef?: RefObject<HTMLIonMenuElement>;
    children: React.ReactNode;
};

export const Drawer: React.FC<IDrawerProps> = ({ basePath, returnToPath, hideBackdrop, onClose, menuRef, children }) => {
    const classes = useStyles({hideBackdrop});

    const drawerState = useInstance(DrawerState);
    useSubscription(() => drawerState);

    const history = useInstance(HistoryViewModel);

    const onWillOpen = () => {
        drawerState.setOpen(true);
    };

    // the close button invokes menuControll.close(), which fires this event, where we change the route
    // (this approach ensures consistent behavior when the menu is closed another way, e.g. by clicking the mask)
    const onDidClose = () => {
        history.push(returnToPath);
        drawerState.setOpen(false);
    };

    return (
        <Route path={basePath} render={() => {
            return (
                <IonMenu
                    data-id="drawer"
                    side="end"
                    menuId="content-drawer"
                    contentId="innerhive-grid"
                    swipeGesture={false}
                    className={classes.menu}
                    onIonDidClose={onDidClose}
                    onIonWillOpen={onWillOpen}
                    onIonWillClose={onClose}
                    ref={menuRef}
                >
                    <IonRouterOutlet basePath={basePath}>
                        {children}
                    </IonRouterOutlet>
                </IonMenu>
            );

        }} />
    );
};
