import { INote } from '../INote';
import { IAttachment } from './IAttachment';
import { IUploadingFile } from './FileUploader';
import { NoteAttachment } from './NoteAttachment';
import { createUseStyles } from 'react-jss';
import { DeleteAttachment } from './DeleteAttachment';
import { MixPanelEventEmitter as MixpanelService } from '../../metrics/MixPanelEventEmitter';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { INoteTarget } from '../INoteTarget';
import { getNoteTrackingLocation } from '../NotesTrackingHelper';
import { useEffect, useRef, useState } from 'react';
import { usePrevious } from '../../utils/usePrevious';

const useStyles = createUseStyles({
    list: {
        gap: 10,
        display: 'flex',
        overflowX: 'auto',
        overflowY: 'hidden'
    }
});

interface IAttachmentListProps {
    note: INote;
    attachments: IAttachment[];
    canEdit?: boolean;
    noteTarget?: INoteTarget;
    uploadingAttachments?: IUploadingFile[],
    noteChanged?: (updatedNote: INote) => void;
};

export const AttachmentList:React.FC<IAttachmentListProps> = ({
    note,
    noteTarget,
    canEdit = false,
    attachments,
    uploadingAttachments,
    noteChanged = () => {}
}) => {
    const classes = useStyles();
    const tracking = useInstance(MixpanelService);
    const listRef = useRef<HTMLDivElement>(null);

    const [uploadCount, setUploadCount] = useState(uploadingAttachments?.length);
    const prevUploadCount = usePrevious(uploadCount);

    // doesn't get called when canEdit = false as the DeleteAttachment component won't exist
    const deleteAttachment = async ({ url }: { url: string; }) => {
        noteChanged({
            ...note,
            attachments: note.attachments.filter(a => a.url !== url)
        });

        tracking.trackWithDebounce({
            event: 'Note File Removed',
            getContext: () => {
                return {
                    location: getNoteTrackingLocation(noteTarget!),
                    type: url.substring(url.lastIndexOf('.')) || ''
                };
            }
        });
    };

    useEffect(() => {
        setUploadCount(uploadingAttachments?.length);
    }, [uploadingAttachments?.length]);

    // if uploading or just did upload, scroll to the end of the list so the user can see it
    useEffect(() => {
        if (uploadingAttachments?.length || (!uploadingAttachments?.length && prevUploadCount)) {
            listRef.current?.scrollTo({left: listRef.current?.scrollWidth});
        }
    }, [listRef, uploadingAttachments, prevUploadCount, uploadCount]);

    return (
        <div ref={listRef} className={classes.list} data-type="attachment-list">
            {attachments.map(attachment => (
                <div key={attachment.url} style={{display: 'flex', flexDirection: 'column'}}>
                    <NoteAttachment {...attachment} />

                    {canEdit && (
                        <DeleteAttachment onDelete={() => deleteAttachment(attachment)} />
                    )}
                </div>
            ))}

            {uploadingAttachments?.map((file, i) => (
                <NoteAttachment key={i} {...{url: '', ...file, isUploading: true}} />
            ))}
        </div>
    );
};
