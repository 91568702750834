import { FC, useEffect, useState } from 'react';
import {
    IonLabel,
    IonSegment,
    IonSegmentButton,
    SegmentChangeEventDetail
} from '@ionic/react';
import { IonSegmentCustomEvent } from '@ionic/core';
import { createUseStyles } from 'react-jss';
import { householdMemberAvatars, householdMemberColors } from '../caremap/CareMapFactory';
import { IAvatarPhotoColor } from '../avatar/PhotoAvatarOrColorPicker';

const circleSize: number = 80;
const smallerCircleSize: number = 70;
const useStyles = createUseStyles({
    container: {
        paddingTop: 35,
        textAlign: 'center',
        maxWidth: (circleSize * 5) - 1
    },

    circle: {
        margin: 5,
        width: circleSize,
        height: circleSize,
        borderRadius: '50%',
        display: 'inline-block',
        cursor: 'pointer',
        border: '2px solid transparent',
        boxShadow: '2px 4px 12px #9a9a9a'
        //filter: 'drop-shadow(2px 4px 6px #9a9a9a)'
    },

    // NOTE: don't make this a common practice, of media queries in between breakpoints. very specific case
    [`@media (max-width: 395px)`]: {
        container: {
            maxWidth: (smallerCircleSize * 5) - 1
        },

        circle: {
            width: smallerCircleSize,
            height: smallerCircleSize,
        }
    },

    avatar: {
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat'
    },

    selectedChip: {
        border: '4px solid var(--ion-color-tertiary-tint)'
    }
});

export type ISegmentValue = 'avatars' | 'colors';
const defaultSegmentValue: ISegmentValue = 'avatars';

export type IChipsProps = {
    avatarsOrColors: ISegmentValue;
    onClick: (value: string) => void;
    defaultSelection?: string;
    selectedValue?: string;
};

const Chips: FC<IChipsProps> = ({ onClick, avatarsOrColors = defaultSegmentValue, defaultSelection, selectedValue: currentSelection }) => {
    const classes = useStyles();
    const chipClicked = (value: string) => {
        onClick(value);
    };

    useEffect(() => {
        if (!currentSelection && defaultSelection) {
            chipClicked(defaultSelection);
        }
    }, [currentSelection, defaultSelection, avatarsOrColors]); // eslint-disable-line react-hooks/exhaustive-deps

    const isAvatars = avatarsOrColors === 'avatars';
    const array = isAvatars ? householdMemberAvatars : householdMemberColors;

    return (
        <>
            {array.map(value => (
                <div
                    key={value}
                    data-value={value}
                    data-selected={value === currentSelection}
                    onClick={() => chipClicked(value)}
                    style={isAvatars ? {backgroundImage: `url("${value}")`} : {backgroundColor: value}}
                    className={`${classes.circle} ${isAvatars ? classes.avatar : ''} ${value === currentSelection ? classes.selectedChip : ''}`}
                >
                </div>
            ))}
        </>
    );
};

export type IAvatarSelectorProps = {
    defaultAvatar?: string;
    onSelect: (type: ISegmentValue, value: string) => void;
    avatar: IAvatarPhotoColor;
};

export const AvatarOrColorSelector: FC<IAvatarSelectorProps> = ({ onSelect, defaultAvatar: defaultSelection, avatar }) => {
    const classes = useStyles();
    const [segmentValue, setSegmentValue] = useState<ISegmentValue>(avatar.color ? 'colors' : defaultSegmentValue);

    // if a color is selected then show the color tab, otherwise show the avatar tab
    useEffect(() => {
        setSegmentValue(avatar.color ? 'colors' : defaultSegmentValue); // on enter

        return () => {
            setSegmentValue(defaultSegmentValue); // on leave
        };
    }, [avatar.avatar, avatar.color, setSegmentValue]);

    const segmentChanged = (ev: IonSegmentCustomEvent<SegmentChangeEventDetail>) => {
        setSegmentValue(ev.detail.value as ISegmentValue);
    };

    const chipClicked = (value: string) => {
        onSelect(segmentValue, value);
    };

    return (
        <div>
            <IonSegment value={segmentValue} onIonChange={segmentChanged} data-id="avators-or-colors-section-switcher">
                <IonSegmentButton value="avatars">
                    <IonLabel>Illustrations</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="colors">
                    <IonLabel>Colors</IonLabel>
                </IonSegmentButton>
            </IonSegment>
            <div className={classes.container} data-avatar-or-colors={segmentValue}>
                <Chips
                    onClick={chipClicked}
                    avatarsOrColors={segmentValue}
                    defaultSelection={avatar.photo || avatar.photoDataURI ? undefined : defaultSelection}
                    selectedValue={avatar.avatar || avatar.color}
                />
            </div>
        </div>
    );
};
