import { useInstance } from '@meraki-internal/react-dependency-injection';
import { State, useSubscription } from '@meraki-internal/state';
import { CareMapState } from './caremap/CareMapState';
import { AppPrincipal, isCaremapViewer } from './auth/AppPrincipal';
import { ShareMenuAndButton } from './share-caremap/ShareMenuAndButton';
import { CareMapConfigPopover } from './support-group/CareMapConfigPopover';
import { BasicHeader } from './components/BasicHeader';
import { createUseStyles } from 'react-jss';
import { useEffect } from 'react';
import { AddAppointmentModalAndButton } from './appointments/AddAppointmentModalAndButton';

const useStyles = createUseStyles({
    buttons: {
        display: 'flex',
        alignItems: 'center'
    },

    optionalButtons: {
        display: 'flex',
        alignItems: 'center',
        height: 30,

        '&:empty': {
            border: 'none!important'
        }
    }
});

export const HEADER_HEIGHT = 57;
export const HEADER_Z_INDEX = 10;

interface IAppHeaderOverrideProps {
    title?: string | React.ReactNode;
    style?: React.CSSProperties;
    hidden?: boolean;
}
export class AppHeaderOverrideState extends State<Record<string, never>> {
    set = ({ title, style, hidden }: IAppHeaderOverrideProps) => {
        this.title = title;
        this.style = style;
        this.hidden = hidden;
        this.setState({ });
    };

    clear = () => {
        this.title = undefined;
        this.style = undefined;
        this.hidden = undefined;
        this.setState({ });
    };

    title?: string | React.ReactNode;
    style?: React.CSSProperties;
    hidden?: boolean;
}


/**
 * AppHeaderOverride will not detect changes so if you want to be able to dynamically alter the override
 * over time, then refactor useEffect dependencies below
 */
export const AppHeaderOverride: React.FC<IAppHeaderOverrideProps> = (props) => {
    const override = useInstance(AppHeaderOverrideState);

    useEffect(() => {
        override.set(props);
        return () => {
            override.clear();
        };

        // ideally we'd pass in props.title, and props.style
        // but props.style will be different every render
        // even though its value hasn't changed
        // we could refactor but until we need to support dynamic AppHeaderOverride
        // it isn't worth it
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export const AppHeader: React.FC<{title?: string | React.ReactNode}> = ({ title }) => {
    const classes = useStyles();
    const override = useInstance(AppHeaderOverrideState);
    useSubscription(() => override);

    title = override.title || title || '';

    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);

    const isViewer = isCaremapViewer(useInstance(AppPrincipal));

    if (override.hidden) {
        return null;
    }

    return (
        <BasicHeader isViewer={isViewer} style={override.style}
            title={(
                <span>{isViewer && <>Viewing </>}{title}</span>
            )}
        >
            <div data-id="header-buttons" slot="end" className={classes.buttons}>
                <div className={classes.optionalButtons}>
                    <AddAppointmentModalAndButton />
                    <CareMapConfigPopover />
                    <ShareMenuAndButton />
                </div>
            </div>
        </BasicHeader>
    );
};
