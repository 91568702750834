import { State } from '@meraki-internal/state';

export class DrawerState extends State<{isOpen: boolean}> {

    private className = 'drawer-open';

    private menu?: HTMLIonMenuElement;

    setIonMenu = (menu: HTMLIonMenuElement) => {
        this.menu = menu;
    };

    setOpen = (isOpen: boolean) => {
        this.setState({ isOpen });


        // set a class on body to use for styling global elements
        // outside react (e.g. intercom launcher)
        if (isOpen) {
            document.body.classList.add(this.className);
        } else {
            document.body.classList.remove(this.className);
        }
    };

    isOpen = () => {
        return Boolean(this.state?.isOpen);
    };
}


/**
 * More refactoring is needed such that all access to change the menu go through this class
 *
 * But we don't have time to bite off that much, first step is we now have the underlying menu
 *
 * so we can't possibly return a different answer :)
 */
// not used yet b/c it broke I can delete an apppointment from edit
// somehow causing an infinite loop in the relationship between AppointmentEditorDrawer and DrawerPage
// firing onLeaveHandler infinitely
export class DrawerStateV2 extends State<Record<string, never>> {
    private className = 'drawer-open';

    private menu?: HTMLIonMenuElement;

    setIonMenu = (menu: HTMLIonMenuElement) => {
        this.menu = menu;
    };

    setOpen = (isOpen: boolean) => {
        this.setState({ });

        // set a class on body to use for styling global elements
        // outside react (e.g. intercom launcher)
        if (isOpen) {
            document.body.classList.add(this.className);
        } else {
            document.body.classList.remove(this.className);
        }
    };

    isOpen = () => {
        return Boolean(this.menu?.isOpen());
    };
}
