import { ISmartStorageProvider, LocalStorageProvider } from './LocalStorageProvider';

export class DevSettingsStorage {
    settings: ISmartStorageProvider<{[key: string]: boolean}>;
    static inject = () => [LocalStorageProvider];
    constructor(private storage: LocalStorageProvider) {
        this.settings = this.storage.getJSONProvider('dev-settings', { userNeutral: true, envNeutral: true });
    }
}

export class DevSettings {
    static inject = () => [DevSettingsStorage];

    constructor(private storage: DevSettingsStorage) {
        const settings = this.storage.settings.get();
        if (settings){
            this.useStaging = settings.useStaging || false;
            this.enableHexagonTools = settings.enableHexagonTools || false;
            this.enableWebBypassUpgrade = settings.enableWebBypassUpgrade || false;
            this.useRealPaymentsEvenIfBypassEnabled = settings.useRealPaymentsEvenIfBypassEnabled || false;
            this.canAddSocialCategory = settings.canAddSocialCategory || false;
        }
    }

    useStaging: boolean = false;
    enableHexagonTools: boolean = false;
    enableWebBypassUpgrade: boolean = false;
    useRealPaymentsEvenIfBypassEnabled: boolean = false;
    canAddSocialCategory: boolean = false;
}

export class DevSettingsWriter {
    static inject = () => [
        DevSettings,
        DevSettingsStorage
    ];
    constructor(
        private reader: DevSettings,
        private storage: DevSettingsStorage
    ){ }

    setSetting = async (key: string, value: any) => {
        (this.reader as any)[key] = value;
        this.persistLocalStorage();
    };

    private persistLocalStorage = () => {
        this.storage.settings.set({
            useStaging: this.reader.useStaging,
            enableHexagonTools: this.reader.enableHexagonTools,
            enableWebBypassUpgrade: this.reader.enableWebBypassUpgrade,
            useRealPaymentsEvenIfBypassEnabled: this.reader.useRealPaymentsEvenIfBypassEnabled,
            canAddSocialCategory: this.reader.canAddSocialCategory,
        });
    };

}
