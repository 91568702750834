import { State } from '@meraki-internal/state';
import { IAvatarPhotoColor } from './PhotoAvatarOrColorPicker';

export interface IAvatarFormState {
    memberId?: string;
    avatar: IAvatarPhotoColor;
}

export class AvatarFormState extends State<IAvatarFormState> {
    constructor(){
        super({
            memberId: undefined,
            avatar: {}
        });
    }
    init = ({ memberId, avatar }: { memberId: string; avatar: IAvatarPhotoColor}) => {
        this.setState({ memberId, avatar });
    };

    clear = () => {
        this.setState({ memberId: undefined, avatar: {} });
    };

    setAvatar = (avatar: IAvatarPhotoColor) => {
        if (!this.state.memberId){
            throw new Error('init must be called first');
        }
        this.setState({ avatar });
    };

}
