import { BufferedEventBus } from '../utils/BufferedEventBus';
import { MixPanelEventEmitter } from './MixPanelEventEmitter';

/**
 * This class binds domain events to mixpanel events
 */
export class DomainToMixPanelAdapter {
    static inject = () => [ BufferedEventBus, MixPanelEventEmitter  ];
    constructor(private events: BufferedEventBus, private mixPanelEmitter: MixPanelEventEmitter){}
    init = () => {
        this.events.on('AuthService.authenticated', (payload) => {
            const { userId, email } = payload as { userId: string; email: string; };
            this.mixPanelEmitter.track('Signed In', () => ({ userId, email }));
        });

        this.events.on('UserState.deleted', () => {
            this.mixPanelEmitter.track('Deleted Account');
        });
    };
}
