import React from 'react';
import { CheckIcon, CopyIcon } from '../theme/Icons';
import { useClipboard } from '../utils/useClipboard';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { ITrackingContext, MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { RoundButton } from './RoundButton';

export const CopyButton: React.FC<{
    value: string;
    size?: 'small' | undefined;
    trackingEvent?: string;
    trackingContext?: ITrackingContext;
}> = ({ value, size, trackingEvent, trackingContext }) => {

    const tracking = useInstance(MixpanelService);
    const [copyToClipboard, justCopied] = useClipboard();

    const onCopyClicked = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        copyToClipboard({ value, onCopied: () => {
            if (trackingEvent) {
                tracking.track(trackingEvent, () => trackingContext || {});
            }
        }});
    };

    return (
        <RoundButton
            size={size}
            onClick={(e: React.MouseEvent) => onCopyClicked(e)}
        >
            {!justCopied && (
                <CopyIcon color="medium" />
            )}
            {justCopied && (
                <CheckIcon color="success" />
            )}
        </RoundButton>
    );
};
