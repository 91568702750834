import { ConnectionStatus, Network } from '@capacitor/network';
import { Logger } from './Logger';

export class NetworkAdapter {
    static inject = () => [Logger];
    constructor(private logger: Logger){}

    private capacitorLastKnownStatus?: ConnectionStatus;

    startListening = async () => {

        const updateStatus = (status: ConnectionStatus) => {
            // // to avoid circular dependency, we are setting a public property
            // // to not send errors to sentry, that are likely a result of the
            // // fact that we're offline
            // this.logger.skipSendingToSentry = !status.connected;

            this.logger.info('network change', status);
            this.capacitorLastKnownStatus = status;
        };

        // get the current network status and then listen for changes
        await Network.getStatus().then(updateStatus);
        Network.addListener('networkStatusChange', updateStatus);
    };

    // NOTE: this is currently assuming you're online til we know
    // this was with a bias towards error use cases where making all those use cases async for this wasn't worth it
    // in the future, we may want to wait in app container, which then guarantees the right answer to everything downstream
    // or expose another method (eg isOnlineAsync) for consumers that don't want the potentially wrong answer in the first
    // milliseconds of app start (NOTE: that is likely the only use case where it will matter, is app start, so a method for
    // app start is likely) the best compromise.
    public get isOnline (): boolean {
        return this.capacitorLastKnownStatus ? this.capacitorLastKnownStatus!.connected : true;
    };
}
