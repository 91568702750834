import { useInstance } from '@meraki-internal/react-dependency-injection';
import { OrganizationsState } from './OrganizationsState';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { useSubscription } from '@meraki-internal/state';
import { Text } from '../components/Text';
import { NavigationItem } from '../navigation/NavigationItem';
import { NavigationCurrentEntity } from '../navigation/NavigationCurrentEntity';
import { DrawerSectionHeading } from '../components/DrawerSectionHeading';
import { IonText } from '@ionic/react';
import { NavigationItemList } from '../navigation/NavigationItemList';
import { CareMapState } from '../caremap/CareMapState';
import { ICareMap } from '../caremap/ICareMap';
import { IOrgSelectedNavItem, OrgNavigationMenuState } from './OrgNavigationMenuState';
import { UserState } from '../user/UserState';

export const OrganizationsNavigationMenu: React.FC<{closeMenuHandler: () => void}> = ({ closeMenuHandler }) => {
    const orgState = useInstance(OrganizationsState);
    useSubscription(() => orgState);

    const careMapState = useInstance(CareMapState);
    useSubscription(() => careMapState);

    const orgNavMenuState = useInstance(OrgNavigationMenuState);
    useSubscription(() => orgNavMenuState);

    const userState = useInstance(UserState);
    useSubscription(() => userState);

    const history = useInstance(HistoryViewModel);

    // otherwise typescript complains about userId not being in INewCareMap
    const activeCareMap = (careMapState.getActiveCaremap() as ICareMap);
    const isCareMapContext = careMapState.hasActiveCareMap();

    const closeAndExecute = (handler: () => void) => {
        closeMenuHandler();
        setTimeout(() => {
            handler();
        }, 350);
    };

    const onClick = (path: string, selectedItem: IOrgSelectedNavItem) => {
        orgNavMenuState.setSelectedNavItem(selectedItem);
        closeAndExecute(() => {
            // why not href? if the page had a query param, it is causing a page load
            // eg downstream of using visitWithPageLoad (which adds a query param to the url)
            history.push(path);
        });
    };

    const selectCareMap = (id: string) => {
        // don't navigate if the same caremap (or questionnaire) we're on
        // https://merakisolutions.atlassian.net/browse/IH-3392
        if (activeCareMap.careMapId === id) {
            closeMenuHandler();
        } else {
            closeAndExecute(() => {
                history.push(`/caremaps/${id}`);
            });
        }
    };

    return orgState.getNonClientOrganizations().map((o, idx) => {
        const client = orgState.getOrganizationUser({ orgId: o.orgId, userId: activeCareMap?.userId });
        const clientName = `${client?.firstName} ${client?.lastName}`;

        return (
            <div key={o.orgId} style={{marginTop: idx > 0 ? 100 : undefined}} data-type={`nav-organization-${o.orgId}`}>
                <center>
                    <Text data-type="nav-org-name" type="title">{o.name}</Text>
                </center>
                <NavigationItemList>
                    <NavigationItem
                        label="Clients"
                        data-type="clients-nav-item"
                        onClick={() => onClick(`/org/${o.orgId}/clients`, 'clients')}
                        isSelected={!isCareMapContext && orgNavMenuState.state.selectedNavItem === 'clients'}
                        icon={
                            <>
                                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="26" fill="currentColor" viewBox="0 0 33 26">
                                    <path fillRule="evenodd" d="M25.416 3.751c.537 0 1.034.287 1.302.752l5.298 9.176a1.504 1.504 0 0 1 0 1.504l-5.298 9.175a1.504 1.504 0 0 1-1.302.752h-10.59a1.504 1.504 0 0 1-1.314-.772l-1.465-2.632a1 1 0 1 1 1.748-.973l1.323 2.377h10.011l5.012-8.68-5.011-8.679H15.107L13.72 8.155a1 1 0 1 1-1.732-1l1.531-2.652a1.504 1.504 0 0 1 1.303-.752h10.595ZM10.88 11.683a1.005 1.005 0 1 1 1.004-1.74 1.005 1.005 0 0 1-1.004 1.74Zm-.368 5.996a1.005 1.005 0 1 0 1.74 1.005 1.005 1.005 0 0 0-1.74-1.005Z" clipRule="evenodd"/>
                                    <circle cx="9.373" cy="14.497" r="1.005" transform="rotate(120 9.373 14.497)"/>
                                    <path fillRule="evenodd" d="M17.416.751c.537 0 1.034.287 1.302.752l5.298 9.176a1.504 1.504 0 0 1 0 1.504l-5.298 9.175a1.504 1.504 0 0 1-1.302.752H6.826a1.504 1.504 0 0 1-1.314-.772l-1.465-2.632a1 1 0 1 1 1.748-.973l1.323 2.377H17.13l5.01-8.68-5.01-8.679H7.107L5.72 5.155a1 1 0 0 1-1.732-1l1.531-2.652A1.504 1.504 0 0 1 6.821.751h10.595ZM2.88 8.683a1.005 1.005 0 1 1 1.005-1.74 1.005 1.005 0 0 1-1.005 1.74Zm-.368 5.996a1.005 1.005 0 1 0 1.74 1.005 1.005 1.005 0 0 0-1.74-1.005Z" clipRule="evenodd"/>
                                    <circle cx="1.373" cy="11.497" r="1.005" transform="rotate(120 1.373 11.497)"/>
                                </svg>
                            </>
                        }
                    />
                    <NavigationItem
                        label="Team"
                        data-type="team-nav-item"
                        onClick={() => onClick(`/org/${o.orgId}/team`, 'team')}
                        isSelected={!isCareMapContext && orgNavMenuState.state.selectedNavItem === 'team'}
                        icon={
                            <>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" viewBox="0 0 640 512"><path d="M128 128a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM269.7 336c80 0 145 64.3 146.3 144H32c1.2-79.7 66.2-144 146.3-144h91.4zM224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3zm431 208c17 0 30.7-13.8 30.7-30.7C640 392.2 567.8 320 478.7 320H417.3c-4.4 0-8.8 .2-13.2 .5c11.3 9.4 21.6 19.9 30.7 31.5h43.9c71 0 128.6 57.2 129.3 128H480c0 .8 0 1.5 0 2.3c0 10.8-2.8 20.9-7.6 29.7H609.3zM432 256c61.9 0 112-50.1 112-112s-50.1-112-112-112c-24.8 0-47.7 8.1-66.3 21.7c5.2 9.8 9.3 20.3 12.4 31.2C392.3 71.9 411.2 64 432 64c44.2 0 80 35.8 80 80s-35.8 80-80 80c-25.2 0-47.6-11.6-62.3-29.8c-4.7 10.3-10.4 19.9-17 28.9C373 243.4 401 256 432 256z"/></svg>
                            </>
                        }
                    />
                    {isCareMapContext && (
                        <>
                            <NavigationCurrentEntity>
                                <div style={{marginTop: 42}}>
                                    <DrawerSectionHeading text="Client" tight></DrawerSectionHeading>
                                    <IonText data-id="nav-client-name">{clientName}</IonText>
                                </div>
                            </NavigationCurrentEntity>
                            <div data-id="nav-client-caremap-items" style={{marginTop: 12}}>
                                {client && orgState.getOrganizationCareMapsForUserV2({ orgId: o.orgId, userId: client.userId })
                                    // only show caremaps the user can navigate to
                                    // note we don't need to worry about zero, b/c how could they be in the context of a caremap if there are none
                                    .filter(map => orgState.canViewClientCareMap(map, 'not-used'))
                                    .map(map => {

                                        const patientFirstName = map.patient.firstName;
                                        const careMapPatientLabel = patientFirstName ? `${patientFirstName}'s Care Map` : 'New Recipient';
                                        return (
                                            <NavigationItem
                                                key={map.careMapId}
                                                onClick={() => selectCareMap(map.careMapId)}
                                                isSelected={activeCareMap.careMapId === map.careMapId}
                                                label={careMapPatientLabel}
                                                disabled={!orgState.canViewClientCareMap(map, userState.state.userId || '')}
                                                icon={
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="23" fill="currentColor" viewBox="0 0 25 23">
                                                        <path fillRule="evenodd" d="M17.416.751c.537 0 1.034.287 1.302.752l5.298 9.176a1.504 1.504 0 0 1 0 1.504l-5.298 9.175a1.504 1.504 0 0 1-1.302.752H6.826a1.504 1.504 0 0 1-1.314-.772l-1.465-2.632a1 1 0 0 1 1.748-.973l1.323 2.377h10.011l5.012-8.68-5.012-8.679H7.107L5.72 5.155a1 1 0 0 1-1.732-1l1.531-2.652A1.504 1.504 0 0 1 6.821.751h10.595ZM2.88 8.683a1.005 1.005 0 1 1 1.005-1.74 1.005 1.005 0 0 1-1.005 1.74Zm-.368 5.996a1.005 1.005 0 1 0 1.74 1.005 1.005 1.005 0 0 0-1.74-1.005Z" clipRule="evenodd"/>
                                                        <circle cx="1.373" cy="11.497" r="1.005" transform="rotate(120 1.373 11.497)"/>
                                                    </svg>
                                                }
                                            />
                                        );
                                    })
                                }
                            </div>
                        </>
                    )}
                </NavigationItemList>
            </div>
        );
    });
};
