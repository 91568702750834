import { FC, useEffect } from 'react';
import { Step, IStepValidProps } from './Step';
import { DateField } from '../components/DateField';
import dayjs from 'dayjs';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';

export type IDOBStepProps = IStepValidProps & {
    dob?: string | undefined
    onChange: (value: string) => void
}

export const DOBStep: FC<IDOBStepProps> = ({ dob, onChange, setStepValidity }) => {
    const setValidity = (val?: string) => setStepValidity(dayjs(val).isValid());

    const careMap = useInstance(CareMapState);
    useSubscription(() => careMap);

    useEffect(() => {
        setValidity(dob);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const dateChanged = (value: string) => {
        onChange(value);
        setValidity(value);
    };

    const question = careMap.getLabel('CARE_RECIPIENT_DOB');

    return (
        <Step question={question}>
            <DateField
                name = "dob"
                label="Date of Birth"
                data-type="dob"
                value={dob}
                onChange={dateChanged}
            />
        </Step>
    );
};
