import { IonButton } from '@ionic/react';
import { FC } from 'react';

export type IAddNoteButtonProps = {
    canAdd: boolean;
    onClick: (noteId: string) => void;
};

export const AddNoteButton: FC<IAddNoteButtonProps> = ({ canAdd, onClick }) => {
    return (
        <IonButton
            fill="clear"
            color="primary"
            data-id="add-note-button"
            onClick={() => onClick('add-note')}
            style={{ display: canAdd ? 'block' : 'none' }}
        >
            + Add Note
        </IonButton>
    );
};
