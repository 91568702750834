import { useInstance } from '@meraki-internal/react-dependency-injection';
import { UserState } from './UserState';
import { useSubscription } from '@meraki-internal/state';
import { useEffect, useState } from 'react';
import { Modal } from '../components/Modal';
import { NameStep } from '../wizard/NameStep';
import { AppPrincipal } from '../auth/AppPrincipal';
import { IonButton, isPlatform } from '@ionic/react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    continueBtn: {
        marginBottom: 16,
        paddingLeft: 70,
        paddingRight: 70
    },

    stepWrapper: {
        '& [class=ion-page] > ion-content > div': {
            paddingTop: 40
        }
    }
});

export const RequireNameModal: React.FC = () => {
    const classes = useStyles();

    const userState = useInstance(UserState);
    useSubscription(() => userState);
    const principal = useInstance(AppPrincipal);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        Promise.resolve()
            .then(async () => {
                // wait to render until we give components a chance to redirect
                await new Promise(resolve => setTimeout(resolve, 250));
                setIsOpen(principal.tokenType !== 'caremap-viewer' && !Boolean(userState.getName()));
            });
    }, [userState, principal]);

    if (!isOpen){
        return null;
    }

    const hasValidName = Boolean(userState.state.firstName && userState.state.lastName);

    const saveAndClose = async () => {
        if (userState.isDirty){
            await userState.save();
            setIsOpen(false);
        }
        // this shouldn't happen, but if they close it and we have a name, and there is nothing to save
        // then close it
        else if (hasValidName){
            setIsOpen(false);
        }
    };

    // NOTE: I ran into issues where an empty modal would be displayed
    // this appears to be due to internal of ion-modal (eg https://github.com/ionic-team/ionic-framework/issues/25775)
    // my work around was to delay rendering the modal
    return (
        <Modal
            desktopMaxHeight={315}
            data-id="require-name-modal"
            title="Please enter your name"
            centerTitle
            isOpen={isOpen}
            preventBackdropDismiss
            closeHandler={() => setIsOpen(false)}
        >
            <div className={isPlatform('ios') ? classes.stepWrapper : undefined}>
                <NameStep
                    question="not used"
                    lastNameLabel="Last Name"
                    firstName={userState.state.firstName}
                    lastName={userState.state.lastName}
                    onFirstNameChange={name => userState.setFirstName(name)}
                    onLastNameChange={name => userState.setLastName(name)}
                    setStepValidity={() => hasValidName}
                />
            </div>
            <IonButton
                data-id="close-button"
                className={classes.continueBtn}
                disabled={!hasValidName}
                onClick={saveAndClose}
            >
                Continue
            </IonButton>
        </Modal>
    );
};
