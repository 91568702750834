import { ContactMethodIcon } from './ContactMethodIcon';
import { IContactMethod } from '../caremap/ICareMap';
import { IContactMethodOrAddress, IContactMethodTypeOrAddress } from './IContactMethodOrAddress';

export const ShortContactMethodsList: React.FC<{ contactMethods: IContactMethod[], address?: string }> = ({ contactMethods, address }) => {

    const contactMethodsWithAddress: IContactMethodOrAddress[] = [ ...contactMethods ];
    if (address) {
        contactMethodsWithAddress.push({ type: 'address', value: address });
    }

    if (!contactMethodsWithAddress.length) {
        return null;
    }

    // this is the order we want to display them in
    const orderedMethodTypes: IContactMethodTypeOrAddress[] = ['mobile-phone', 'work-phone', 'email', 'address'];
    const filteredMethodTypes = orderedMethodTypes.filter(type => contactMethodsWithAddress.some(m => m.type === type));

    return (
        <div style={{ display: 'flex', marginTop: 4}}>
            {filteredMethodTypes.map(methodType => (
                <div key={methodType} style={{ marginRight: 16 }}>
                    <ContactMethodIcon methodType={methodType} />
                </div>
            ))}
        </div>
    );
};
