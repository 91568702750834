import { useInstance } from '@meraki-internal/react-dependency-injection';
import { ICareMap, ILightCareMap } from '../caremap/ICareMap';
import { OrganizationsState } from './OrganizationsState';
import { useSubscription } from '@meraki-internal/state';
import { UserState } from '../user/UserState';
import { CareMapMenuItem } from '../caremap/CareMapMenuItem';

export type IOrganizationCareMapMenuItemProps = {
    careMap: ILightCareMap;
    onClick?: (map: ILightCareMap) => void;
};

const WarningWell: React.FC<{ children: React.ReactNode ;}> = ({ children }) => {
    return (
        <div style={{
            backgroundColor: 'var(--ion-color-warning)',
            padding: 10,
            border: '1px solid var(--ion-color-warning)',
            borderRadius: 4,
            whiteSpace: 'normal'
        }}>
            {children}
        </div>
    );
};

export const OrganizationCareMapMenuItem: React.FC<IOrganizationCareMapMenuItemProps> = (props) => {
    const orgState = useInstance(OrganizationsState);
    useSubscription(() => orgState);
    const userState = useInstance(UserState);

    const disabled= !orgState.canViewClientCareMap(props.careMap, userState.state.userId || '');

    // this is a result of me properly typing OrgnaizationCareMapMenuItem
    // while CareMapMenuItem still things it is getting an ICareMap
    // this is a band aid until we refactor CareMapMenuItem to accept either type
    const onClickAdapter = (careMap: ICareMap) => {
        if (props.onClick){
            props.onClick(careMap as ILightCareMap);
        }
    };

    return (
        <>

            <CareMapMenuItem {...props} disabled={disabled} onClick={onClickAdapter} />
            {disabled && (
                <div
                    data-id="caremap-not-shared-warning"
                    style={{
                        // some extra bottom margin to make it clear which caremap the warning goes with
                        marginBottom: 10,
                    }}
                >
                    <WarningWell>
                        This caremap is not shared with your organization and so access is very limited. Request
                        access from the client to gain full access.
                    </WarningWell>
                </div>
            )}
        </>
    );
};
