import { FC } from 'react';
import { Route } from 'react-router';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { OrganizationsState } from '../organizations/OrganizationsState';
import { useSubscription } from '@meraki-internal/state';
import { CaregiverHomePage } from '../caregiver/CaregiverHomePage';
import { ShowCheckin } from '../caregiver/ShowCheckin';

export const CaregiverRouter: FC = () => {
    const orgState = useInstance(OrganizationsState);
    useSubscription(() => orgState);

    return (
        <>
            <Route path="/caregiver" render={() => {
                return <>
                    <CaregiverHomePage />

                    <Route exact path="/caregiver/daily-log" render={() => {
                        return <ShowCheckin />;
                    }} />
                </>;
            }} />
        </>
    );
};
