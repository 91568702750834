import { FC, RefCallback } from 'react';
import { useMaskito } from '@maskito/react';
import { MaskitoOptions } from '@maskito/core';
import { IContactMethod, IContactMethodType } from '../caremap/ICareMap';
import { TextField } from '../components/TextField';
import { TabRadioButtons } from '../components/TabRadioButtons';
import { useBreakpoints } from '../theme/useBreakpoints';

type IContactMethodProps = {
    method: IContactMethod;
    autoFocus?: boolean;
    methodChanged: (method: IContactMethod) => void
};

export const ContactMethod: FC<IContactMethodProps> = ({ method, methodChanged, autoFocus }) => {
    const { isBelowBreakpoint } = useBreakpoints();

    const mobilePhoneMask: MaskitoOptions = { mask: [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] };
    const workPhoneMask: MaskitoOptions = { mask: [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ' ', 'x', /\d/, /\d/, /\d/, /\d/] };

    const mobilePhoneRef = useMaskito({ options: mobilePhoneMask });
    const workPhoneRef = useMaskito({ options: workPhoneMask });

    if (!method) {
        return null;
    }

    let fieldLabel: string = '';
    let placeholder: string;
    let inputRef: RefCallback<HTMLElement> | undefined;
    switch (method?.type) {
        case 'mobile-phone':
            fieldLabel = 'Cell Phone';
            placeholder = '999-999-9999';
            inputRef = mobilePhoneRef;
            break;
        case 'work-phone':
            fieldLabel = 'Work Phone';
            placeholder = '999-999-9999 x999';
            inputRef = workPhoneRef;
            break;
        case 'email':
        default:
            fieldLabel = 'Email';
            placeholder  = '';
            inputRef = undefined;
            break;
    };

    const typeChanged = (type: IContactMethodType) => {
        if (type !== method.type) {
            methodChanged({ ...method, value: '', type });
        }
    };

    const valueChanged = (value: string) => {
        methodChanged({ ...method, value });
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 16, padding: '0px 2px 0px 2px'}}>
            <TabRadioButtons
                value={method.type}
                onChange={typeChanged}
                buttons={[{
                    label: 'Email',
                    value: 'email'
                },{
                    label: `Cell${isBelowBreakpoint('sm') ? '' : ' Phone'}`,
                    value: 'mobile-phone'
                },{
                    label: `Work${isBelowBreakpoint('sm') ? '' : ' Phone'}`,
                    value: 'work-phone'
                }]}
            />
            <TextField
                inputRef={inputRef}
                //autoFocus={autoFocus}
                name="contactMethodValue"
                label={fieldLabel}
                placeholder={placeholder}
                value={method.value}
                autocapitalize="none"
                onInput={valueChanged}
            />
        </div>
    );
};
