import { FC, useState } from 'react';
import { NotesList } from './NotesList';
import { DrawerPage } from '../components/DrawerPage';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { ICareMapNoteTarget } from './INoteTarget';
import { CareMapState } from '../caremap/CareMapState';
import { getNoteTrackingContext } from './NotesTrackingHelper';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { CareMapDrawerState } from '../caremap/CareMapDrawerState';
import { useSubscription } from '@meraki-internal/state';
import { AddNoteButton } from './AddNoteButton';

export type INotesViewProps = {
    noteTarget: ICareMapNoteTarget;
    titlePrefix?: string;
};

export const NotesView: FC<INotesViewProps> = ({ noteTarget, titlePrefix = '' }) => {
    const drawerState = useInstance(CareMapDrawerState);
    useSubscription(() => drawerState);
    const history = useInstance(HistoryViewModel);
    const cmState = useInstance(CareMapState);
    const tracking = useInstance(MixpanelService);

    const [startTimestamp, setStartTimestamp] = useState<number>(Date.now());

    const section = cmState.getCareMap()[noteTarget.section];

    const editNote = (noteId?: string) => {
        history.push(`${history.getCurrentLocation().pathname}/${noteId}`);
    };

    let node = section;
    if (noteTarget.memberId) {
        node = (section as any).members.find((m: any) => m.id === noteTarget.memberId);
    }

    const onDidEnter = () => {
        setStartTimestamp(Date.now());
        drawerState.setIsMaskingFocusedCareMap(true);
    };

    const onWillLeave = () => {
        const timeOnPage = (Date.now() - startTimestamp) / 1000;
        const {location, type} = getNoteTrackingContext({noteTarget, node, note: undefined});
        tracking.trackWithDebounce({
            event: 'Notes List Viewed',
            getContext: () => ({location, type, timeOnPage})
        });
        drawerState.setIsMaskingFocusedCareMap(false);
    };

    const canEdit = cmState.getActiveCaremap()!.links.self.actions.includes('edit-notes');

    return (
        <DrawerPage
            hasBack={true}
            title={`${titlePrefix?.concat(' ')}Notes`}
            enteredHandler={onDidEnter}
            leavingHandler={onWillLeave}
            headerButtons={
                <AddNoteButton
                    canAdd={canEdit}
                    onClick={editNote}
                />
            }
        >
            <NotesList
                notes={node.notes}
                showHeading={false}
                editNoteHandler={editNote}
                data-type="more-notes-list"
            />
        </DrawerPage>
    );
};
