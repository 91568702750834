import { FC, useEffect, useState } from 'react';
import { DrawerPage, IDrawerModalAnimationOrigin } from '../components/DrawerPage';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { TextField } from '../components/TextField';
import { IonButton } from '@ionic/react';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { MemberAvatar } from './MemberAvatar';
import { CareMapDrawerState } from '../caremap/CareMapDrawerState';
import { DateField } from '../components/DateField';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { ContactMethodsSection } from '../contact-methods/ContactMethodsSection';
import { DrawerSectionHeading } from '../components/DrawerSectionHeading';
import { EditableList } from '../components/EditableList';
import { DiagnosisSelectField } from './DiagnosisSelectField';
import { AllergySelectField } from './AllergySelectField';
import { IAllergy, IDiagnosis } from '../caremap/ICareMap';
import { LabelProvider } from '../config/LabelProvider';
import { EditPatientAvatarDrawer } from '../avatar/EditAvatarDrawer';
import { PatientFormState } from '../avatar/PatientFormState';
import { PrivateCheckbox } from '../components/PrivateCheckbox';

export const PatientEdit: FC = () => {
    const labelProvider = useInstance(LabelProvider);
    const tracking = useInstance(MixpanelService);

    const history = useInstance(HistoryViewModel);

    const drawerState = useInstance(CareMapDrawerState);

    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);

    const [initialDiagnosesPrivacy] = useState<boolean>(careMapStateInstance.getActiveCaremap()?.patient.diagnosesIsPublic!);

    const [showingAvatarPicker, setIsShowingAvatarPicker] = useState(false);
    const [avatarDrawerAnimationOrigin, setAvatarDrawerAnimationOrigin] = useState<IDrawerModalAnimationOrigin>();
    const avatarClicked = (e: React.MouseEvent) => {
        setAvatarDrawerAnimationOrigin({x: e.clientX, y: e.clientY});
        setIsShowingAvatarPicker(true);
    };

    useEffect(() => {
        drawerState.setIsMaskingFocusedCareMap(true);

        return () => {
            drawerState.setIsMaskingFocusedCareMap(false);
        };
    }, [drawerState]);

    const patientForm = useInstance(PatientFormState);
    useSubscription(() => patientForm);

    const patient = patientForm.state.patient;

    useEffect(() => {
        const caremap = careMapStateInstance.getActiveCaremap();
        if (caremap){
            patientForm.init(caremap.patient);
        }
        return () => {
            patientForm.clear();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Boolean(careMapStateInstance.state), patientForm]);

    if (!careMapStateInstance.state || !patient) {
        return null;
    }

    const onCancel = () => {
        history.back();
    };

    const onSave = () => {
        careMapStateInstance.updatePatient(patient);
        tracking.track('Care Recipient Edited', () => ({
            '# of Contact Methods': patient.contactMethods.length,
            '# of Diagnoses': (patient.diagnoses || []).length,
            '# of Allergies': (patient.allergies || []).length,
            'Diagnoses Privacy': patient.diagnosesIsPublic ? 'public' : 'private',
            'Diagnoses Privacy Toggled': patient.diagnosesIsPublic !== initialDiagnosesPrivacy
        }));

        history.back();
    };

    const isValid = Boolean(patient.firstName?.trim());

    const buttons = <>
        <IonButton data-type="save-button" disabled={!isValid} onClick={onSave}>Save</IonButton>
        <IonButton data-type="cancel-button" fill="outline" onClick={onCancel}>Cancel</IonButton>
    </>;

    return (
        <>
            <DrawerPage
                title="Edit Care Recipient"
                hasBack={true}
                buttons={buttons}
                data-type="member-edit"
                data-member-id="patient"
            >
                <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>

                    <div style={{display: 'flex', alignItems: 'start', width: '100%', gap: 20}}>
                        <MemberAvatar
                            // this doesn't use the "form" patient b/c it can't be changed by the form fields
                            // and b/c it can be changed in CareMapState by EditAvatarDrawer
                            member={patient}
                            onClick={(e: React.MouseEvent) => avatarClicked(e)}
                        />

                        <div style={{display: 'flex', flexDirection: 'column', gap: 20, flexGrow: 1}}>
                            <TextField
                                name="first-name"
                                label="First Name"
                                value={patient.firstName}
                                autocapitalize="words"
                                onInput={firstName => patientForm.patch({ firstName })}
                            />
                            <TextField
                                name="last-name"
                                label="Last Name"
                                value={patient.lastName}
                                autocapitalize="words"
                                onInput={lastName => patientForm.patch({ lastName })}
                            />
                        </div>
                    </div>

                    <DateField label="Date of Birth" data-type="dob" value={patient.dob} onChange={dob => patientForm.patch({ dob })} />

                    <TextField
                        name="address"
                        label="Address"
                        value={patient.address}
                        autocapitalize="words"
                        onInput={address => patientForm.patch({ address })}
                    />

                    <ContactMethodsSection contactMethods={patient.contactMethods} onMethodsChanged={contactMethods => patientForm.patch({ contactMethods })} />

                    <div>
                        <DrawerSectionHeading text="Diagnoses">
                            <PrivateCheckbox
                                data-id="diagnoses-visibility-checkbox"
                                checked={!patient.diagnosesIsPublic!}
                                onChange={isPrivate => patientForm.patch({diagnosesIsPublic: !isPrivate})}
                            />
                        </DrawerSectionHeading>
                        <div data-type="diagnoses" style={{display: 'flex', flexDirection: 'column'}}>
                            <EditableList
                                items={patient.diagnoses || []}
                                isBlank={m => !m.diagnosis}
                                buttonLabel="+ Add Diagnosis"
                                buttonDataType="diagnoses-add-item"
                                buttonFill="outline"
                                createNew={(): IDiagnosis => ({})}
                                onChange={diagnoses => patientForm.patch({ diagnoses })}

                                renderRow={({ item, focusRef, onChange, onBlur }) => (
                                    <DiagnosisSelectField
                                        innerRef={focusRef}
                                        diagnosisId={item.diagnosisId}
                                        diagnosis={item.diagnosis}
                                        onChange={d => onChange({...item, ...d})}
                                        onBlur={onBlur}
                                        label={labelProvider.getLabel('CARE_RECIPIENT_DIAGNOSIS')}
                                        marginBottom={20}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div>
                        <DrawerSectionHeading text="Allergies">
                            <PrivateCheckbox
                                data-id="allergies-visibility-checkbox"
                                checked={!patient.allergiesIsPublic!}
                                onChange={isPrivate => patientForm.patch({allergiesIsPublic: !isPrivate})}
                            />
                        </DrawerSectionHeading>
                        <div data-type="allergies" style={{display: 'flex', flexDirection: 'column'}}>
                            <EditableList
                                items={patient.allergies || []}
                                isBlank={m => !m.allergy}
                                buttonLabel="+ Add Allergen"
                                buttonDataType="allergies-add-item"
                                buttonFill="outline"
                                createNew={(): IAllergy => ({})}
                                onChange={allergies => patientForm.patch({ allergies })}

                                renderRow={({ item, focusRef, onChange, onBlur }) => (
                                    <AllergySelectField
                                        innerRef={focusRef}
                                        allergyId={item.allergyId}
                                        allergy={item.allergy}
                                        onChange={d => onChange({...item, ...d})}
                                        onBlur={onBlur}
                                        label={labelProvider.getLabel('CARE_RECIPIENT_ALLERGY')}
                                        marginBottom={20}
                                    />
                                )}
                            />
                        </div>
                    </div>

                </div>

            </DrawerPage>
            <EditPatientAvatarDrawer
                isOpen={showingAvatarPicker}
                animationOrigin={avatarDrawerAnimationOrigin}
                patient={patient}
                onSave={changed => {
                    patientForm.patch(changed);
                    setIsShowingAvatarPicker(false);
                }}
                onCancel={() => setIsShowingAvatarPicker(false)}
            />
        </>
    );
};
