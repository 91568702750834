import { Component, ErrorInfo } from 'react';

import {
    IonApp,
    isPlatform,
    setupIonicReact
} from '@ionic/react';

import { IonReactHashRouter } from '@ionic/react-router';
import { iosTransitionAnimation } from '@ionic/core';

import { AppContainer, getErrorSafeContainer } from './AppContainer';
import { InnerhiveApp } from './InnerhiveApp';
import { HistoryViewModel } from './support/HistoryViewModel';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Subscribe } from '@meraki-internal/state';
import { FatalApplicationErrorState } from './FatalApplicationErrorState';
import { ErrorPage } from './ErrorPage';
import { DismissSplashScreen } from './support/DismissSplashScreen';
import { AppUrlListener } from './AppUrlListener';
import { PushNotificationListener } from './push-notifications/PushNotificationListener';
import { AlertBinder } from './AlertBinder';
import { RedirectAfterLiveUpdateInstall } from './support/live-updates/RedirectAfterLiveUpdateInstall';
import { BackButtonHandler } from './support/BackButtonHandler';
import { Logger } from './support/Logger';
import { arrowBack } from 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* apps css */
import './theme/app.css';

/* react-day-picker */
import 'react-day-picker/style.css';

setupIonicReact({
    mode: 'md',
    backButtonIcon: arrowBack,
    navAnimation: iosTransitionAnimation,
    animated: localStorage.getItem('IS_AUTOMATED_TESTS') !== 'true'
});

// NOTE: views with footers cut the active field off in native due to WebView being resized, this fixes it
if (isPlatform('capacitor')) {
    window.addEventListener('keyboardDidShow', () => {
        document.activeElement?.scrollIntoView(true);
    });
}

export class App extends Component {
    state = {
        error: null
    };

    static getDerivedStateFromError() {
        // NOTE: We are not setting state here as prescribed in the docs, but instead setting it in
        // componentDidCatch() below because a non-static method can check if error state is already
        // set, and avoid the real error getting overwritten by a bogus error when react remounts
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        try {
            // ignore if we get catch a second error - this is from react unsuccessfully attempting
            // to remount the app, and contains a misleading message and useless stack trace
            if (this.state.error) {
                return;
            }

            const log = getErrorSafeContainer().get(Logger);
            log.error(error, errorInfo);

            this.setState({ error });

        } catch (e: any) {
            console.warn(`Failed to log error in ErrorBoundary.componentDidCatch. ${e.message}`);
            console.error(e);
        }
    }

    render() {
        const { error } = this.state;

        if (error) {
            return <ErrorPage error={error} />;
        }

        return (
            <Subscribe to={() => FatalApplicationErrorState.singleton}>
                {({ error: fatalError }) => (
                    <>
                        {fatalError && (
                            <DismissSplashScreen>
                                <ErrorPage error={fatalError} />
                            </DismissSplashScreen>
                        )}
                        {!fatalError && (
                            <IonApp>
                                <AlertBinder />
                                <AppContainer>
                                    <BackButtonHandler />
                                    <DismissSplashScreen>
                                        <AppRouter />
                                    </DismissSplashScreen>
                                </AppContainer>
                            </IonApp>
                        )}
                    </>
                )}
            </Subscribe>
        );
    }
}

// this needs to be a separate component inside AppContainer
// so we can use the useInstance hook to create history
const AppRouter: React.FC = () => {
    const historyVM = useInstance(HistoryViewModel);

    return (
        <IonReactHashRouter history={historyVM.getRawHistory()}>
            <AppUrlListener />
            <PushNotificationListener />
            <RedirectAfterLiveUpdateInstall>
                <InnerhiveApp />
            </RedirectAfterLiveUpdateInstall>
        </IonReactHashRouter>
    );
};
