import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Handle, HandleProps, useUpdateNodeInternals } from 'reactflow';
import { DevSettings } from '../support/DevSettings';
import { useEffect } from 'react';

type IInnerhiveReactFlowHandleProps = HandleProps & {
    style?: React.CSSProperties;

    // a string to diff to tell when the position of the handle has changed
    // so we can update react flow internals (so it can sync it to the edge svg)
    positionKey?: string;

    // what node do we need to update in the react-flow internals
    nodeIdToUpdateOnPositionChanged?: string;
}

export const InnerhiveReactFlowHandle : React.FC<IInnerhiveReactFlowHandleProps> = ({ positionKey, nodeIdToUpdateOnPositionChanged, ...props }) => {
    const devSettings = useInstance(DevSettings);
    let handleStyles: React.CSSProperties = {
        ...props.style
    };
    if (devSettings.enableHexagonTools){
        handleStyles = {
            ...handleStyles,
            backgroundColor: 'orange',
            width: 10,
            height: 10,
            opacity: 1,
            zIndex: 1000000
        };
    }

    const updateNodeInternals = useUpdateNodeInternals();

    useEffect(() => {
        if (positionKey && nodeIdToUpdateOnPositionChanged){
            updateNodeInternals(nodeIdToUpdateOnPositionChanged);
        }
    }, [positionKey, nodeIdToUpdateOnPositionChanged, updateNodeInternals]);

    return <Handle
        {...props}
        style={handleStyles}
    />;
};
