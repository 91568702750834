import { FC } from 'react';
import { Card } from '../components/Card';
import { Text } from '../components/Text';
import { LeftQuoteIcon } from '../theme/Icons';

export const TipCard: FC = () => {
    return (
        <Card padded>
            <div style={{marginBottom: 12}}>
                <Text><b>Tips &amp; Inspiration</b></Text>
            </div>

            <img src="./assets/images/tip.png" alt="Caregivers" style={{borderRadius: 12}} />

            <div style={{marginTop: 12}}>
                <LeftQuoteIcon size="xlarge" />&nbsp;&nbsp;
                <span>Caregiving is a constant Learning Experience</span>
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 8}}>
                <Text><b style={{fontSize: 14}}>– Vivian Frazier</b></Text>
            </div>
        </Card>
    );
};
