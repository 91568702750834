import { FC, ReactNode } from 'react';

export type IDrawerListSectionProps = {
    children: ReactNode;
    // first heading at top of page doesn't need as much top margin
    underDrawerTitle?: boolean;
    'data-id'?: string;
};

export const DrawerListSection: FC<IDrawerListSectionProps> = ({ children, underDrawerTitle = false, 'data-id': dataId }) => {
    return (
        <div data-id={dataId} style={{marginTop: underDrawerTitle ? -10 : 0, marginBottom: 24}}>
            {children}
        </div>
    );
};
