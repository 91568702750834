import { State } from '@meraki-internal/state';
import { IPatient } from '../caremap/ICareMap';

export class PatientFormState extends State<{ patient?: IPatient}> {
    constructor(){
        super({ });
    }

    init = (patient: IPatient) => {
        this.setState({ patient });
    };

    patch = (patch: Partial<IPatient>) => {
        if (!this.state.patient){
            throw new Error('must call init before calling patch');
        }
        this.setState({
            patient: {
                ...this.state.patient,
                ...patch
            }
        });
    };

    clear = () => {
        this.setState({ patient: undefined });
    };
};
