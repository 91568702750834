import { IonFabButton } from '@ionic/react';
import { useBreakpoints } from '../theme/useBreakpoints';

export interface IHeaderButtonProps {
    disabled?: boolean;
    onClick?: () => void;
    children: React.ReactNode;
    id: string;
};

export const HeaderButton: React.FC<IHeaderButtonProps> = ({ id, onClick, disabled, children }) => {
    const {isBelowBreakpoint} = useBreakpoints();

    // using IonFabButton simply because it is conveniently round...
    // not using its floating characteristics, restyling it to look flat
    return (
        <IonFabButton
            style={{
                padding: 3,
                marginLeft: 3,
                '--border-width': '1px',
                '--border-color': 'white',
                '--border-style': 'solid',
                '--box-shadow': 'none',
                marginInline: isBelowBreakpoint('sm') ? 4 : null
            }}
            id={id}
            disabled={disabled}
            size="small"
            color="light"
            onClick={onClick}
        >
            {children}
        </IonFabButton>
    );
};
