import React, { useEffect, useState } from 'react';
import constrain from 'lodash/clamp';
import { useZoom } from '../utils/useZoom';
import { IonText } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { householdNodeSizes } from './NodeAdapter';
import { householdMemberAvatars, householdMemberColors, isHouseholdAvatar } from '../caremap/CareMapFactory';

type IStyleKeys = 'member' | 'selectedMember' | 'memberInner' | 'label' | 'subtext';

interface IStyleProps {
    size: 'small' | 'large';
    color?: string;
    url?: string;
    zoom: number;
}

const useStyles = createUseStyles<IStyleKeys, IStyleProps>({
    member: {
        pointerEvents: 'auto',
        color: 'white',
        border: '3px solid #fff',
        borderRadius: '50%',
        //background: '#666',
        background: 'linear-gradient(transparent 10%, #000 100%)',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '-2px 7px 14px rgba(100, 100, 100, 0.5)',
        //filter: 'drop-shadow(-2px 7px 10px rgba(100, 100, 100, 0.5))'
        width: ({size}) => (size === 'small' ? householdNodeSizes['small'] : householdNodeSizes['large']),
        height: ({size}) => (size === 'small' ? householdNodeSizes['small'] : householdNodeSizes['large']),
        paddingBottom: ({size}) => (size === 'small' ? '15%' : '10%'),
        fontWeight: ({size}) => (size === 'small' ? '600' : 'bold'),
        textShadow: ({url}) => {
            const step = isHouseholdAvatar(url) ? '700' : '900';
            return `1px 1px var(--ion-color-step-${step})`;
        },
        backgroundImage: ({url}) => (url ? `url("${url}")` : undefined),
        backgroundColor: '#efefef',
        // TODO: better way to set these up / scale / position them, based on our "profile image" effort in the future
        position: 'relative',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '75% 40%'
    },
    selectedMember: {
        border: '3px solid var(--ion-color-secondary)',
    },

    memberInner: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        borderRadius: '50%',
        top: 0,
        zIndex: 0,
        background: ({url, color}) => {
            const gradientColor = isHouseholdAvatar(url) ?
                householdMemberColors[householdMemberAvatars.findIndex(path => path === url)] : '#000';
            return url ? `linear-gradient(180.16deg, rgba(0, 0, 0, 0) 45%, ${gradientColor} 100%)`
                : color ? color : 'var(--ion-color-primary)';
        }
    },

    label: {
        // as the user zooms in, reduce the font size slightly so we lose the ellipses
        // (but constrain it to a reasonable range)
        fontSize: ({zoom, size}) => size === 'small'
            ? `${constrain(1.2/zoom, 1.2, 1.6)}rem`
            : `${constrain(2/zoom, 1.9, 2.4)}rem`,

        paddingLeft: ({size}) => (size === 'small' ? '2%' : '9%'),
        paddingRight: ({size}) => (size === 'small' ? '2%' : '9%'),
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        zIndex: 1
    },

    subtext: {
        // as the user zooms in, reduce the font size slightly so we lose the ellipses
        // (but constrain it to a reasonable range)
        fontSize: ({zoom, size}) => size === 'small'
            ? `${constrain(1.0/zoom, 0.7, 1)}rem`
            : `${constrain(1.5/zoom, 1, 1.5)}rem`,

        paddingLeft: ({size}) => (size === 'small' ? '12%' : '20%'),
        paddingRight: ({size}) => (size === 'small' ? '12%' : '20%'),
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        zIndex: 1
    }
});

export interface INodeWrapperProps {
    size: 'small' | 'large';
    label?: string;
    subtext?: string;
    photo?: string;
    color?: string;
    avatar?: string;
    memberId: 'patient' | string;
    onClick?: () => void;
    selectedItemId?: string;
}

/**
 * Mostly just a div... but it waits for backgroundImage to load before changing it, to avoid flicker
 */
const BackgroundImageDiv: React.FC<{
    className: string;
    children: any;
    backgroundImage?: string;
    memberId: 'patient' | string;
}> = ({ className, children, backgroundImage, memberId }) => {
    const [ url, setUrl ] = useState<string | undefined>();

    useEffect(() => {
        if (!backgroundImage){
            return;
        }

        if (!url){
            setUrl(backgroundImage);
        }

        const img: HTMLImageElement = document.createElement('img');
        img.src = backgroundImage;
        img.onload = () => {
            setUrl(backgroundImage);
        };

        // not wanting this to run if url changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backgroundImage]);

    return (
        <div className={className} data-type="member-photo" data-id={memberId} style={{
            backgroundImage: url ? `url("${encodeURI(decodeURI(url))}")` : undefined,
        }}>
            {children}
        </div>
    );
};

export function MemberNodeWrapper({ size = 'small', label, subtext, photo, color, avatar, memberId, onClick, selectedItemId }: INodeWrapperProps) {
    const url = photo || avatar;
    const zoom = useZoom();

    const classes = useStyles({ size, color, url, zoom });

    const isSelected = selectedItemId && selectedItemId === memberId ;

    return (
        <div data-id={`member-${memberId}`} onClick={onClick} style={{cursor: onClick ? 'pointer' : 'unset'}} data-avatar={url || color}>
            <BackgroundImageDiv
                className={`${classes.member} ${isSelected ? classes.selectedMember : ''}`}
                memberId={memberId}
                backgroundImage={url}
            >
                <div className={classes.memberInner} />

                <IonText data-type="member-label" className={classes.label}>{label}</IonText>
                <IonText className={classes.subtext}>{subtext || <>&nbsp;</>}</IonText>

            </BackgroundImageDiv>
        </div>
    );
};
