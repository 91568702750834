interface  UserFacingErrorProps{
    message: string;
    errorCode: string;
    displayHeader?: string;
    displayMessage: string;
    okLabel?: string;
    links?: { label: string; href: string;   }[];
    status: number;
}

export class UserFacingError extends Error {
    constructor({ message, errorCode, displayHeader, displayMessage, links = [], okLabel, status }: UserFacingErrorProps){
        super(message);
        this.status = status;
        this.errorCode = errorCode;
        this.displayHeader = displayHeader;
        this.displayMessage = displayMessage;
        this.links = links;
        this.okLabel = okLabel;
    }
    status: number;
    errorCode: string;
    displayHeader?: string;
    displayMessage: string;
    okLabel?: string;
    links: { label: string; href: string; }[];
    errorType = 'UserFacingError';
}
