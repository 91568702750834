import { FC, ReactElement, useEffect } from 'react';
import { Step, IStepValidProps } from './Step';
import { ISupportGroupKey, ISupportGroupMember } from '../caremap/ICareMap';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { CareMapFactory } from '../caremap/CareMapFactory';
import { ISearchSelectOption } from '../components/SearchableSelectField';
import { TextField } from '../components/TextField';
import { RoleSelectField } from '../components/RoleSelectField';
import { EditableList } from '../components/EditableList';
import { HoneycombConfigProvider } from '../nodes/HoneycombConfigProvider';

const isBlank = (m: ISupportGroupMember) => !m.name?.trim() && !m.role;

export type ISupportGroupMemberStepProps = IStepValidProps & {
    stepId: string;
    question: string | ReactElement;
    supportGroupKey: ISupportGroupKey;
    canEditName?: boolean;
    memberTitle: string;
    nameLabel?: string;
    roleLabel?: string;
    rolePlaceholder?: string;
    roleOptions: ISearchSelectOption[];
    subTitle?: string;
    groupMembers: ISupportGroupMember[];
    addMember: (supportGroupKey: ISupportGroupKey, member: ISupportGroupMember) => void;
    removeMember: ({supportGroupKey, memberId}: {supportGroupKey: ISupportGroupKey, memberId: string}) => void;
    updateMember: (supportGroupKey: ISupportGroupKey, member: ISupportGroupMember) => void;
}

export const SupportGroupMemberStep: FC<ISupportGroupMemberStepProps> = ({
    stepId,
    question,
    subTitle,
    memberTitle,
    nameLabel,
    roleLabel,
    rolePlaceholder,
    roleOptions,
    setStepValidity,
    supportGroupKey,
    canEditName,
    groupMembers,
    addMember,
    removeMember,
    updateMember
}) => {
    const caremap = useInstance(CareMapState);
    useSubscription(() => caremap);
    const careMapFactory = useInstance(CareMapFactory);

    const configProvider = useInstance(HoneycombConfigProvider);

    useEffect(() => {
        // members are not required
        setStepValidity(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onAnswerChanged = (newAnswer: ISupportGroupMember[]) => {
        caremap.answerInitialWizardQuestion({ questionId: stepId, answer: newAnswer });
        setStepValidity(true);
    };

    const maxHoneycombHexagons = configProvider.getMaxHoneycombHexagons();

    return (
        <Step
            data-id={stepId}
            question={question}
            subTitle={subTitle}
        >
            <EditableList
                items={caremap.getActiveCaremap()?.initialWizardAnswers[stepId] || []}
                isBlank={isBlank}
                retainBlanks={canEditName}
                preventAdd={groupMembers.length >= maxHoneycombHexagons}
                buttonLabel={`Add ${memberTitle}`}
                createNew={() => ({ id: careMapFactory.getNodeId(), roleId: undefined, role: undefined, contactMethods: [], notes: [] })}
                onChange={onAnswerChanged}
                onAdd={m => addMember(supportGroupKey, m)}
                onRemove={m => removeMember({ supportGroupKey, memberId: m.id })}
                onUpdate={m => updateMember(supportGroupKey, m)}

                renderRow={({ item, focusRef, onChange, onBlur }) => (
                    <>
                        {canEditName &&
                            <TextField
                                inputRef={canEditName ? focusRef : undefined}
                                name="name"
                                label={nameLabel || 'Name'}
                                value={item.name}
                                autoFocus={!item.name}
                                autocapitalize="words"
                                onInput={name => onChange({ ...item, name })}
                            />
                        }
                        <RoleSelectField
                            innerRef={canEditName ? undefined : focusRef}
                            roleId={item.roleId}
                            role={item.role}
                            onChange={updates => onChange({ ...item, ...updates })}
                            onBlur={canEditName ? undefined : onBlur}
                            label={roleLabel || 'Suppport'}
                            placeholder={rolePlaceholder}
                            options={roleOptions}
                            autoLookupOnEmpty={!canEditName}
                        />

                        {/* if no name field and role blank then nothing renders (due to autoLookupOnEmptu) so no spacer */}
                        {(canEditName || !isBlank(item)) &&
                            <div style={{height: 30}}>&nbsp;</div>
                        }
                    </>
                )}
            />
        </Step>
    );
};
