import { APIClient } from '@innerhive-internal/innerhive-api-client';
import { State } from '@meraki-internal/state';
import { ILink } from '../../caremap/WithLinks';
import { CareMapState } from '../../caremap/CareMapState';
import { ISupportGroupKey } from '../../caremap/ICareMap';
import { EnvConfiguration, IEnvironment } from '../../config/EnvConfiguration';
import { BufferedEventBus } from '../../utils/BufferedEventBus';
import { FeatureFlagState } from '../feature-flags/FeatureFlagState';

const MEDIA_TYPE = 'application/vnd.innerhive.appointment.v1+json';

type ICategoryToCollectionId = {[key in ISupportGroupKey]: string};
const CATEGORY_TO_COLLECTION_ID_MAP: {[key in IEnvironment]: ICategoryToCollectionId} = {
    live: {
        'financial': '5928358',
        'community': '9335413',
        'medical': '9335414',
        'school': '9335416',
        'specialists': '9335415',
        // TODO:
        'social': ''
    },

    staging: {
        'financial': '9133579',
        'community': '9133641',
        'medical': '9335424',
        'school': '9335422',
        'specialists': '9335423',
        // TODO:
        'social': '',
    },

    local: {
        'financial': '',
        'community': '',
        'medical': '',
        'school': '',
        'specialists': '',
        // TODO:
        'social': '',
    }
};
CATEGORY_TO_COLLECTION_ID_MAP.local = CATEGORY_TO_COLLECTION_ID_MAP.staging;

export type ICollection = {
    created_at: number;
    description: string;
    help_center_id: number;
    icon: string;
    id: string;
    name: string;
    order: number;
    parent_id: string;
    updated_at: number;
    url: string;
    workspace_id: string;
};

export type IArticle = {
    id: string;
    type: 'article';
    workspace_id: string;
    parent_id: number | null;
    parent_type: 'collection' | null;
    parent_ids: number[];
    title: string;
    description: string;
    body: string;
    author_id: number;
    state: 'draft' | 'published';
    created_at: number;
    updated_at: number;
    url: string | null;
};

export type ICollectionWithArticles = ICollection & {
    articles: IArticle[]
};

type IHelpCenterState = {
    collections: ICollection[];
    articles: IArticle[];
    lastLoaded: number;
};

const defaultState = {
    collections: [],
    articles: [],
    lastLoaded: 0
};

export class HelpCenterState extends State<IHelpCenterState> {
    private loadBuffer = 60000; // 1 minute
    private helpCenterLinks?: {
        articles: ILink;
        collections: ILink;
    };

    static inject = () => [APIClient, CareMapState, EnvConfiguration, BufferedEventBus, FeatureFlagState];
    constructor(
        private apiClient: APIClient,
        private careMapState: CareMapState,
        private config: EnvConfiguration,
        private events: BufferedEventBus,
        private featureFlags: FeatureFlagState
    ) {
        super(defaultState);

        careMapState.subscribe(() => {
            const careMap = careMapState.getActiveCaremap();
            if (careMap?.links) {
                this.helpCenterLinks = {
                    articles: careMap.links.helpCenterArticles,
                    collections: careMap.links.helpCenterCollections
                };
            }
        });

        this.events.on(
            'CareMapState.SupportGroupAdded',
            (supportGroupKey: ISupportGroupKey | undefined) => this.addSupportGroupDefaultChecklists(supportGroupKey!)
        );
    };

    load = async () => {
        const now = (new Date()).valueOf();
        const timeToReload = this.state.lastLoaded + this.loadBuffer < now;

        if (this.helpCenterLinks?.articles?.actions?.includes('get-helpcenter-articles') &&
            this.helpCenterLinks?.collections?.actions?.includes('get-helpcenter-articles') && timeToReload) {
            this.getCollections();
            this.getArticles();
            this.setState({lastLoaded: now});
        }
    };

    getCollections = async () => {
        const collections = await this.apiClient.get(this.helpCenterLinks?.collections?.href, MEDIA_TYPE);
        this.setState({ collections });
    };

    getArticles = async () => {
        const articles: IArticle[] = await this.apiClient.get(this.helpCenterLinks?.articles?.href, MEDIA_TYPE);
        this.setState({ articles });
    };

    getCollectionIdForCategory = (supportGroupKey: ISupportGroupKey) => {
        return CATEGORY_TO_COLLECTION_ID_MAP[this.config.ENV][supportGroupKey];
    };

    getCollectionsForSupportCategory = (supportGroupKey: ISupportGroupKey) => {
        const subCollections = this.state.collections.filter(collection =>
            collection.parent_id === this.getCollectionIdForCategory(supportGroupKey));
        return subCollections.sort((col1, col2) => col1.order > col2.order ? 1 : -1);
    };

    getArticlesForCollection = (collectionId: string) => {
        const articles: IArticle[] = [];
        this.state.articles.forEach(article => {
            if (String(article.parent_id) === collectionId) {
                articles.push(article);
            }
        });
        return articles;
    };

    getCollectionsAndArticlesForCategory = (supportGroupKey: ISupportGroupKey) => {
        const cols = this.getCollectionsForSupportCategory(supportGroupKey);
        const result: ICollectionWithArticles[] = cols.map(col => {
            const articles = this.getArticlesForCollection(col.id);
            return {...col, articles: articles};
        });
        return result;
    };

    hasArticles = (collections: ICollectionWithArticles[]) => {
        return collections.some(col => col.articles.length);
    };

    private getChecklistsForCategory = async (supportGroupKey: ISupportGroupKey) => {
        return await this.apiClient.get(
            this.careMapState.getActiveCaremap()?.links.defaultChecklists.href,
            '',
            {supportGroupKey}
        );
    };

    addSupportGroupDefaultChecklists = async (supportGroupKey: ISupportGroupKey) => {
        // if there's already checklists, don't try to add them all again
        const group = this.careMapState.getSupportGroup(supportGroupKey);
        if (group.checklists.length === 0) {
            const checklists = await this.getChecklistsForCategory(supportGroupKey);
            this.careMapState.addChecklists(supportGroupKey, checklists);
        }
    };
};
