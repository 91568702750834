import { FC, useEffect, useRef, useState } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { IonButton } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { DeleteIcon } from '../theme/Icons';
import { InvitationState } from './InvitationState';
import { UserState } from '../user/UserState';
import dayjs from 'dayjs';
import { mediaMaxWidth, mediaMinWidth } from '../theme/utils';
import { Spinner } from '../components/Spinner';
import { useScrollIntoViewIfNeeded } from '../utils/useScrollIntoView';
import { useBreakpoints } from '../theme/useBreakpoints';
import { InviteCoOwnerButton } from './InviteCoOwnerButton';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { RevenueCatModel } from '../innerhive-plus/revenue-cat/RevenueCatModel';

const useStyles = createUseStyles({
    collaboratorList: {
        paddingTop: 12,
        paddingBottom: 12,
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: 12
    },

    spinnerWrapper: {
        // must match height of collaboratorList below
        minHeight: 235,

        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },

    [mediaMinWidth.md]: {
        collaboratorList: {
            // always show only three rows on desktop, to keep all content
            // visible within the default modal height of 600px
            height: 235,
            borderTop: '1px solid var(--ion-color-step-200)',
            borderBottom: '1px solid var(--ion-color-step-200)'
        }
    },

    shareLinkButtonWrapper: {
        padding: '10px 40px'
    },

    [mediaMaxWidth.md]: {
        shareLinkButtonWrapper: {
            padding: 10,
            paddingBottom: 0
        }
    },

    collaboratorRowWrapper: {
        paddingLeft: 12,
        position: 'relative'
    },

    collaboratorRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    collaboratorCreated: {
        color: 'var(--ion-color-step-750)'
    },

    collaboratorEmail: {
        wordBreak: 'break-all',
        color: 'var(--ion-color-step-750)'
    },

    collaboratorEndCol: {
        minWidth: 60
    },

    collaboratorDeleteConfirm: {
        // this div needs explicit height, otherwise scrollIntoViewIfNeeded() doesn't work on desktop
        height: 48,
        paddingBottom: 8,
        display: 'flex',
        justifyContent: 'flex-end'
    },

    collaboratorDeleteClicked: {
        borderRadius: 8,
        paddingTop: 8,
        background: 'var(--ion-color-step-50)'
    },

    newIndicator: {
        position: 'absolute',
        width: 4,
        height: '100%',
        left: 2,
        backgroundColor: 'var(--ion-color-success)'
    }
});

type ICollaboratorType = 'Owner' | 'Co-Owner' | 'Invited';
type ICollaboratorRow = {
    id: string;
    name?: string;
    email: string | undefined;
    type: ICollaboratorType;
    created?: string;
    canDelete?: boolean;
    deleteClicked?: boolean;
    'data-type'?: string;
    deleteClickedHandler?: (inviteId: string) => void;
    confirmDeleteHandler?: (inviteId: string, isInvite: boolean) => void;
    scrollRef?: any;
    newlyAdded?: boolean;
};

const CollaboratorRow: FC<ICollaboratorRow> = ({
    id,
    name,
    email,
    type,
    created,
    canDelete = true,
    deleteClicked,
    'data-type': dataType,
    deleteClickedHandler = () => {},
    confirmDeleteHandler = () => {},
    scrollRef,
    newlyAdded = false
}) => {
    const classes = useStyles();
    const scrollIntoViewIfNeeded = useScrollIntoViewIfNeeded();
    const deleteConfirmBtnRef = useRef<HTMLIonButtonElement>(null);

    const isInvite = type === 'Invited';
    name = name || (isInvite ? 'Co-Owner Invite' : email);
    email = isInvite ? `Created by ${email}` : email;

    useEffect(() => {
        scrollIntoViewIfNeeded(deleteConfirmBtnRef, scrollRef);
    }, [deleteClicked, scrollIntoViewIfNeeded, scrollRef]);

    return (
        <div
            data-type={dataType}
            className={`${classes.collaboratorRowWrapper} ${deleteClicked ? classes.collaboratorDeleteClicked : ''}`}
        >
            {newlyAdded && (
                <div className={classes.newIndicator}></div>
            )}
            <div className={classes.collaboratorRow}>
                <div>
                    <div data-type="recipient-name">{name}</div>
                    <div className={classes.collaboratorEmail}>{email}</div>
                    {created && <div className={classes.collaboratorCreated}>{created}</div>}
                    <div>{!isInvite && type}</div>
                </div>
                <div className={classes.collaboratorEndCol}>
                    <IonButton
                        shape="round"
                        fill="clear"
                        data-type="delete-button"
                        disabled={!canDelete || deleteClicked}
                        style={{width: 48, height: 48}}
                        onClick={() => deleteClickedHandler(id)}
                    >
                        <DeleteIcon />
                    </IonButton>
                </div>
            </div>
            {deleteClicked && (
                <div className={classes.collaboratorDeleteConfirm}>
                    <IonButton
                        fill="clear"
                        color="danger"
                        ref={deleteConfirmBtnRef}
                        onClick={() => confirmDeleteHandler(id, isInvite)}>Confirm Delete</IonButton>
                    <IonButton fill="clear" onClick={() => deleteClickedHandler(id)}>Cancel</IonButton>
                </div>
            )}
        </div>
    );
};

type ICareMapCollaboratorManager = {
    scrollRef?: any;
};

export const CareMapCollaboratorManager: FC<ICareMapCollaboratorManager> = ({ scrollRef }) => {
    const classes = useStyles();

    const listRef = useRef<HTMLDivElement>(null);
    const {isBelowBreakpoint} = useBreakpoints();

    const [deleteClickedId, setDeleteClickedId] = useState<string>('');

    const userState = useInstance(UserState);
    useSubscription(() => userState);

    const careMapState = useInstance(CareMapState);
    useSubscription(() => careMapState);

    const invitationState = useInstance(InvitationState);
    useSubscription(() => invitationState);

    const revCat = useInstance(RevenueCatModel);
    useSubscription(() => revCat);

    const trackingService = useInstance(MixpanelService);

    const onDeleteClicked = (inviteId: string) => {
        setDeleteClickedId(prev => prev === inviteId ? '' : inviteId);
    };

    const onConfirmDelete = (inviteId: string, isInvite: boolean) => {
        invitationState[isInvite ? 'deleteInvite' : 'deleteCollaborator'](inviteId);
        trackingService.track(isInvite ? 'Invitation Cancelled' : 'Co-Owner Removed');
    };

    const formatDate = (date: string) => {
        const dayjsDate = dayjs(date);
        const isToday = dayjsDate.isToday();
        const format = isToday ? 'h:mma' : 'ddd MMM D @ h:mma';
        return `${isToday ? 'Today @' : ''} ${dayjsDate.format(format)}`;
    };

    // load data on mount
    useEffect(() => {
        invitationState.load();
    }, [invitationState]);

    const isBelowMd = isBelowBreakpoint('md');
    const owner = invitationState.state?.collaborators?.owner;
    const loaded = owner || invitationState.state?.invites.length || invitationState.state?.collaborators?.permissions.length;
    const sortedInvites = (invitationState.state?.invites || []).sort((n1, n2) => dayjs(n1.createdAt).isAfter(n2.createdAt) ? -1 : 1);
    const newlyAddedInvites = invitationState.getNewlyAddedInvites();

    return (
        <>
            <h2 style={{fontWeight: '600'}}>Collaborators</h2>
            <div className={classes.shareLinkButtonWrapper}>
                <InviteCoOwnerButton />
            </div>
            {loaded ? (
                <div
                    ref={isBelowMd ? undefined : listRef}
                    className={classes.collaboratorList}
                    data-type="collaborator-list"
                    style={revCat.hasInnerhivePlus() ? undefined : {
                        marginTop: isBelowMd ? 24 : 16,
                        height: !isBelowMd ? 219 : undefined}
                    }
                >
                    {owner && (
                        <CollaboratorRow
                            id="owner"
                            type="Owner"
                            data-type="owner"
                            canDelete={false}
                            email={owner.email}
                            name={owner.userId === userState.state.userId ? userState.getName() : ''}
                        />
                    )}

                    {invitationState.state?.collaborators.permissions.map(collaborator => (
                        <CollaboratorRow
                            id={collaborator.invite.token}
                            key={collaborator.invite.token}
                            data-type="co-owner"
                            type="Co-Owner"
                            email={collaborator.invite.acceptedBy.email}
                            name={collaborator.invite.intendedRecipient.name}
                            canDelete={userState.state.userId !== collaborator.userId}
                            deleteClicked={deleteClickedId === collaborator.invite.token}
                            deleteClickedHandler={onDeleteClicked}
                            confirmDeleteHandler={onConfirmDelete}
                            scrollRef={isBelowMd ? scrollRef : listRef}
                        />
                    ))}

                    {sortedInvites.map(invite => (
                        <CollaboratorRow
                            id={invite.token}
                            key={invite.token}
                            data-type="invite"
                            type="Invited"
                            email={invite.createdBy.email}
                            name={invite.intendedRecipient.name}
                            created={formatDate(invite.createdAt)}
                            deleteClicked={deleteClickedId === invite.token}
                            deleteClickedHandler={onDeleteClicked}
                            confirmDeleteHandler={onConfirmDelete}
                            scrollRef={isBelowMd ? scrollRef : listRef}
                            newlyAdded={newlyAddedInvites?.find(inv => inv.token === invite.token ) !== undefined}
                        />
                    ))}
                </div>
            ) : <div className={classes.spinnerWrapper}><Spinner /></div>}
        </>
    );
};
