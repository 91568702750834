import { FC } from 'react';
import { DrawerPage } from '../components/DrawerPage';
import { DrawerSectionHeading } from '../components/DrawerSectionHeading';
import { CareMapState } from '../caremap/CareMapState';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { IonButton, IonItem, IonLabel, IonText } from '@ionic/react';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { Text } from '../components/Text';
import { MemberAvatar } from './MemberAvatar';
import { ShortContactMethodsList } from '../contact-methods/ShortContactMethodsList';
import { NotesList } from '../notes/NotesList';
import { LabelProvider } from '../config/LabelProvider';
import { DrawerListSection } from '../components/DrawerListSection';

export const HouseholdMemberList: FC = () => {
    const history = useInstance(HistoryViewModel);

    const careMapState = useInstance(CareMapState);
    useSubscription(() => careMapState);

    const labelProvider = useInstance(LabelProvider);

    const caremap = careMapState.getActiveCaremap();

    if (!caremap) {
        return null;
    }

    const patient = caremap.patient;
    const members = caremap.household.members;

    const onAdd = () => {
        history.push(`/caremaps/${caremap?.careMapId}/drawer/household/add-member`);
    };

    const viewMoreNotes = () => {
        history.push(`/caremaps/${caremap?.careMapId}/drawer/household/notes`);
    };

    const editNote = (noteId?: string) => {
        history.push(`/caremaps/${caremap?.careMapId}/drawer/household/notes/${noteId}`);
    };

    const buttons = <>
        {caremap?.links.self.actions.includes('add-support') &&
            <IonButton data-type="add-member-button" onClick={onAdd}>+ Add Household Member</IonButton>
        }
    </>;

    return (
        <DrawerPage title="Household" buttons={buttons}>

            <DrawerListSection underDrawerTitle>
                <NotesList
                    showCount={3}
                    editNoteHandler={editNote}
                    moreNotesHandler={viewMoreNotes}
                    notes={careMapState.getCareMap().household.notes}
                />
            </DrawerListSection>

            <div data-type="member-list">
                <div style={{paddingLeft: 5}}>
                    <DrawerSectionHeading text="Household Members" />
                </div>
                <div>

                    <IonItem
                        button
                        detail={true}
                        onClick={() => history.push(`/caremaps/${careMapState.getActiveCaremap()?.careMapId}/drawer/household/member/patient`)}
                    >
                        <div slot="start" style={{marginTop: 10, marginBottom: 10}}>
                            <MemberAvatar member={patient} />
                        </div>
                        <IonLabel>
                            <div>
                                <IonText data-type="title" style={{fontWeight: 500}}>{patient.firstName} {patient.lastName}</IonText>
                                <Text type="detail" data-type="detail1">{labelProvider.getLabel('CARE_RECIPIENT')}</Text>
                            </div>
                            <ShortContactMethodsList contactMethods={patient.contactMethods} address={patient.address} />
                        </IonLabel>
                    </IonItem>

                    {members.map(member => {
                        return (
                            <IonItem
                                key={member.id}
                                button
                                detail={true}
                                onClick={() => history.push(`/caremaps/${careMapState.getActiveCaremap()?.careMapId}/drawer/household/member/${member.id}`)}
                            >
                                <div slot="start" style={{marginTop: 10, marginBottom: 10}}>
                                    <MemberAvatar member={member} />
                                </div>
                                <IonLabel>
                                    <div>
                                        <IonText data-type="title" style={{fontWeight: 500}}>{member.firstName} {member.lastName}</IonText>
                                        <Text type="detail" data-type="detail1">{member.relationship || ''}</Text>
                                    </div>
                                    <ShortContactMethodsList contactMethods={member.contactMethods} address={member.address} />
                                </IonLabel>
                            </IonItem>
                        );
                    })}

                </div>
            </div>
        </DrawerPage>
    );
};
