import { FC } from 'react';
import { DrawerPage } from '../components/DrawerPage';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { DeleteIcon, EditIcon } from '../theme/Icons';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { Text } from '../components/Text';
import { Menu } from '../components/Menu';
import { MemberAvatar } from './MemberAvatar';
import { useDeleteHouseholdMember } from './useDeleteHouseholdMember';
import { ContactMethodsList } from '../contact-methods/ContactMethodsList';
import { NameHelper } from '../contact-methods/NameHelper';
import { NotesList } from '../notes/NotesList';
import { NudgeBanner } from '../components/NudgeBanner';
import { DrawerListSection } from '../components/DrawerListSection';

export interface IHouseholdMemberViewProps {
    memberId: string;
};

export const HouseholdMemberView: FC<IHouseholdMemberViewProps> = ({ memberId }) => {
    const nameHelper = useInstance(NameHelper);

    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);

    const canEdit = careMapStateInstance.hasPermissionTo('put');

    const member = careMapStateInstance.maybeGetHouseholdMember(memberId);
    const history = useInstance(HistoryViewModel);

    const deleteMember = useDeleteHouseholdMember({ memberId, onDelete: () => {
        // assume household member list is back
        history.back();
    }});

    if (!careMapStateInstance.state || !member) {
        return null;
    }

    const editMember = () => {
        history.push(`/caremaps/${careMapStateInstance.getActiveCaremap()?.careMapId}/drawer/household/member/${member.id}/edit`);
    };

    const viewMoreNotes = () => {
        history.push(`/caremaps/${careMapStateInstance.getActiveCaremap()?.careMapId}/drawer/household/member/${memberId}/notes`);
    };

    const editNote = (noteId?: string) => {
        history.push(`/caremaps/${careMapStateInstance.getActiveCaremap()?.careMapId}/drawer/household/member/${memberId}/notes/${noteId}`);
    };

    const showEditNudgeBadge = canEdit && !member.contactMethods.length && !member.address;

    return (
        <DrawerPage title="Household Member Detail" hasBack>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                <div style={{ display: 'flex', alignItems: 'start', gap: 20 }}>

                    <MemberAvatar member={member} />

                    <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
                        <Text type="title" data-type="title">{member.firstName || ''} {member.lastName || ''}</Text>
                        <Text type="subtitle" data-type="subtitle">{member.relationship || ''}</Text>
                    </div>

                    {canEdit &&
                        <div style={{marginTop: -8}}>
                            <Menu actions={[
                                { Icon: EditIcon, text: `Edit Household Member`, handler: editMember },
                                { Icon: DeleteIcon, text: `Delete Household Member`, role: 'destructive', handler: deleteMember }
                            ]} />
                        </div>
                    }


                </div>

                {showEditNudgeBadge &&
                    <NudgeBanner
                        buttonLabel="Edit"
                        buttonClickHandler={editMember}
                        message="Enhance your care map with address and contact info"
                    />
                }

                <ContactMethodsList
                    contactMethods={member.contactMethods}
                    address={member.address}
                    iconWidth={64}
                    emailToName={nameHelper.getName(member)}
                    trackingContext={{ category: 'household', relationship: member.relationshipId || member.relationship }}
                />

                <DrawerListSection>
                    <NotesList
                        showCount={3}
                        editNoteHandler={editNote}
                        moreNotesHandler={viewMoreNotes}
                        notes={careMapStateInstance.getCareMap().household.members.find(m => m.id === memberId)?.notes}
                    />
                </DrawerListSection>
            </div>

        </DrawerPage>
    );
};
