import { FC } from 'react';
import { Redirect, Route } from 'react-router';
import { CreateOrgPage } from '../organizations/CreateOrgPage';
import { OrganizationClients } from '../organizations/OrganizationClients';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { OrganizationsState } from '../organizations/OrganizationsState';
import { useSubscription } from '@meraki-internal/state';
import { OrganizationTeam } from '../organizations/OrganizationTeam';
import { ActiveOrganizationProvider } from '../organizations/ActiveOrganizationProvider';

export const OrganizationsRouter: FC = () => {
    const orgState = useInstance(OrganizationsState);
    useSubscription(() => orgState);

    return (
        <>
            <Route exact path="/orgs/create" render={(routeProps) => {
                return <CreateOrgPage />;
            }} />

            <Route path="/org/:orgId" render={(routeProps) => {
                const orgId = routeProps.match.params.orgId;

                if (!orgState.getNonClientOrganizations().find(org => org.orgId === orgId)) {
                    return <Redirect to="/caremaps" />;
                }

                return (
                    <ActiveOrganizationProvider orgId={orgId}>
                        <Route exact path="/org/:orgId">
                            <Redirect to={`/org/${routeProps.match.params.orgId}/clients`} />
                        </Route>

                        <Route exact path="/org/:orgId/clients">
                            <OrganizationClients orgId={orgId} />
                        </Route>

                        <Route exact path="/org/:orgId/team">
                            <OrganizationTeam orgId={orgId} />
                        </Route>
                    </ActiveOrganizationProvider>
                );
            }} />
        </>
    );
};
