import { IonButton } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AlertPresenter } from '../../AlertBinder';

export const DeleteAttachment: React.FC<{ onDelete:() => void; }> = ({ onDelete }) => {
    const alert = useInstance(AlertPresenter);

    const deleteAttachment = async () => {
        const destroyOrCancel = await alert.confirmDestructiveAction({
            drawerAlert: true,
            header: 'Delete Attachment?',
            message: 'This file will be deleted from your care map and cannot be recovered.'
        });

        if (destroyOrCancel === 'destroy'){
            onDelete();
        }
    };

    return <IonButton fill="clear" onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        deleteAttachment();
    }} data-type="delete-attachment-button">Delete</IonButton>;
};
