import { useInstance } from '@meraki-internal/react-dependency-injection';
import { IOrganizationUser } from '../IOrganization';
import { AlertPresenter } from '../../AlertBinder';
import { OrganizationsState } from '../OrganizationsState';
import { useEffect } from 'react';

export const DeleteMemberConfirmationAlert: React.FC<{ user: IOrganizationUser; onDeleted: () => void; }> = ({ user, onDeleted }) => {
    const alertPresenter = useInstance(AlertPresenter);
    const orgState = useInstance(OrganizationsState);

    const userId = user.userId;

    useEffect(() => {
        const name = `${user.firstName} ${user.lastName}`;
        alertPresenter.showAlertV2({
            header: `Delete ${name}?`,
            message: `${name} will be removed from your organization.`,
            options: [{
                label: 'Delete',
                id: 'delete',
                role: 'destructive',
                handler: async () => {
                    await orgState.removeUser(user);
                    // TODO: tracking?

                    onDeleted();
                }
            }, {
                label: 'Cancel',
                id: 'cancel',
                role: 'cancel'
            }]
        });
        // don't want to remount if we get a new instance of user
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    return null;
};
