import { State } from '@meraki-internal/state';

export class NoteSavingQueue extends State<{}> {

    private savingPromises: {
        [noteId: string]: Promise<void> | undefined;
    } = {};

    private savingQueue: {
        [noteId: string]: { action: () => Promise<void> } | undefined
    } = {};

    private savingError: {
        [key: string]: Error | undefined
    } = {};

    isSaving = ({ noteId }: { noteId: string; }) => Boolean(this.savingPromises[noteId]);

    getError = ({ noteId }: { noteId: string; }) => this.savingError[noteId];

    clearError = ({ noteId, error }: { noteId: string; error: Error; }) => {
        if (this.savingError[noteId] === error){
            this.savingError[noteId] = undefined;
        }
    };

    enqueue = ({ noteId, action }: { noteId: string, action: () => Promise<void> }) => {
        this.savingQueue[noteId] = { action };
        this.tryDequeue({ noteId });
    };

    private tryDequeue = ({ noteId }: { noteId: string;}) => {
        if (this.savingPromises[noteId]){
            return;
        }
        if (!this.savingQueue[noteId]){
            return;
        }
        this.savingPromises[noteId] = Promise.resolve()
            .then(async () => {
                this.savingError[noteId] = undefined;

                // dequeue it
                const { action } = this.savingQueue[noteId]!;
                this.savingQueue[noteId] = undefined;

                // save it
                await action();
            })
            .catch(err => {
                this.savingError[noteId] = err;
            })
            .finally(() => {
                this.savingPromises[noteId] = undefined;
                this.tryDequeue({ noteId });

                // we may be done saving
                this.setState({});
            });
        // we're saving
        this.setState({});
    };
}
