import { FC, useEffect } from 'react';
import { Modal } from '../components/Modal';
import { IonCardContent, IonContent, isPlatform } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { AddToCalendarButton, AddToCalendarButtonType } from 'add-to-calendar-button-react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { CopyTextButton } from '../components/CopyTextButton';
import { MixPanelEventEmitter } from '../metrics/MixPanelEventEmitter';
import { HeaderPopoverTitle } from '../components/HeaderPopover';
import { Text } from '../components/Text';
import { Href } from '../components/Href';
import { AppointmentsState } from './AppointmentsState';
import { Logger } from '../support/Logger';
import { Spinner } from '../components/Spinner';
import { usePrevious } from '../utils/usePrevious';
import { ConnectCalendarViewModel } from './ConnectCalendarViewModel';

// better type safety for customLabels
type IHButtonLabelTypes = 'ical' | 'apple' | 'google' | 'outlookcom';
type IHButtonLabelTypeDef = {[K in IHButtonLabelTypes]?: string};
interface IHButtonLabelInterface extends IHButtonLabelTypeDef { };
// the component type requires HTMLElement who's properties are required, this is to make the IDE happy
// Partial makes all properties optional, but the component (in subscribe mode) requires name and startDaate
interface IHAddToCalendarButtonType extends Partial<AddToCalendarButtonType> {
    name: string;
    startDate: string;
    customLabels: IHButtonLabelInterface;
};
const IHAddToCalendarButton: React.FC<IHAddToCalendarButtonType> = AddToCalendarButton as any;
type IHButtonOptions = string | ('Google' | 'Outlook.com' | 'iCal' | 'Apple' | 'Microsoft365' | 'MicrosoftTeams' | 'Yahoo')[] | undefined;

const useStyles = createUseStyles({
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },

    orderedList: {
        left: -16,
        position: 'relative'
    }
});

export const ConnectCalendarModal: FC = () => {
    const classes = useStyles();

    const logger = useInstance(Logger);

    const careMapState = useInstance(CareMapState);
    useSubscription(() => careMapState);

    const appointmentsState = useInstance(AppointmentsState);
    useSubscription(() => appointmentsState);

    const dialogVM = useInstance(ConnectCalendarViewModel);
    useSubscription(() => dialogVM);

    useEffect(() => {
        if (dialogVM.isOpen()){
            Promise.resolve().then(async () => {
                await appointmentsState.loadIcsUrl();
            }).catch(err =>{
                logger.error('failed to load ics url', err);
            });
        }
    }, [appointmentsState, logger, dialogVM, dialogVM.state]);

    // after the user adds their "first" appointment, show the connect dialog
    const prevApptCount = usePrevious(appointmentsState.state.loaded ? appointmentsState.state.appointments.length : undefined);
    useEffect(() => {
        if (appointmentsState.state.loaded && appointmentsState.state.appointments.length === 1 && prevApptCount === 0) {
            setTimeout(dialogVM.open, 750);
        }
    }, [appointmentsState.state, dialogVM, prevApptCount]);

    const mixpanel = useInstance(MixPanelEventEmitter);

    useEffect(() => {
        if (appointmentsState.state.icsUrl) {
            // setup click handler on buttons for Mixpanel tracking and track model opened from where
            const addToCalendarBtn = document.querySelector('ion-modal[data-id=connect-calendar-modal] [class*=ion-page] ion-content ion-card-content > div add-to-calendar-button');
            const buttonList = addToCalendarBtn?.shadowRoot?.querySelector('[class*=atcb-buttons-list]');
            const btns = buttonList?.querySelectorAll('button[class*=atcb-button]');
            btns?.forEach(btn => {
                btn.addEventListener('click', () => {
                    mixpanel.track('Connect Calendar Initiated', () => ({'Calendar': btn.ariaLabel?.split(' ')[0]}));
                });
            });
        }
    }, [mixpanel, appointmentsState.state.icsUrl]);

    const icsFile = appointmentsState.state.icsUrl;
    const buttons: IHButtonOptions = ['iCal', 'Google', 'Outlook.com'];
    // remove iCal if Android
    if (isPlatform('android')) {
        buttons.shift();
    }

    return (
        <Modal
            desktopMaxHeight={420}
            isOpen={dialogVM.isOpen()}
            title="Connect Your Calendar"
            data-id="connect-calendar-modal"
            closeHandler={dialogVM.close}
        >
            <IonContent>
                {!icsFile && (
                    <IonCardContent>
                        <Spinner centered />
                    </IonCardContent>
                )}

                {icsFile && (
                    <IonCardContent>
                        <HeaderPopoverTitle title="Add Automatically" />
                        <div style={{marginBottom: 16}}>
                            <Text>
                                Add {careMapState.getPatientFirstName()}'s Innerhive appointments to your calendar app of choice.
                            </Text>
                        </div>
                        <div className={classes.buttonsContainer}>
                            <IHAddToCalendarButton
                                subscribe
                                hideBranding
                                buttonsList
                                icsFile={icsFile}
                                options={buttons}
                                startDate="1970-01-01"
                                name={`${careMapState.getPatientFirstName()}'s Innerhive Appts`}
                                customLabels={{ical: 'Default Calendar', outlookcom: 'Outlook'}}
                            />
                        </div>
                        <div style={{marginTop: 42}}>
                            <HeaderPopoverTitle title="Add Calendar Yourself" />
                        </div>
                        <div>
                            <ol className={classes.orderedList}>
                                <li><Text>Copy calendar URL below</Text></li>
                                <li><Text>Follow the <Href url="https://help.innerhive.com/en/articles/9100851">instructions</Href> to add to your calendar of choice</Text></li>
                            </ol>
                            <CopyTextButton
                                text={icsFile}
                                data-type="ics-url"
                                onTextCopied={() => mixpanel.track('Manual Calendar Link Copied')}
                            />
                        </div>
                    </IonCardContent>
                )}

            </IonContent>
        </Modal>
    );
};
