import { FC, useEffect, useRef, useState } from 'react';
import { Modal } from '../components/Modal';
import { IonButton, IonCardContent, IonCheckbox, IonContent, IonFooter, IonSpinner, IonToolbar } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { AlertPresenter } from '../AlertBinder';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Logger } from '../support/Logger';
import { TextField } from '../components/TextField';
import { OrganizationsState } from './OrganizationsState';
import { useSubscription } from '@meraki-internal/state';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { CareMapState } from '../caremap/CareMapState';
import { useAutoFocus } from '../utils/useAutoFocus';

const useStyles = createUseStyles({
    form: {
        gap: 20,
        display: 'flex',
        flexDirection: 'column'
    },

    chkBxLabel: {
        paddingTop: 15,
        cursor: 'pointer',
        color: 'var(--ion-color-step-700)',
        '& p': {
            marginTop: -3
        }
    },

    buttonBar: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 20px',

        '& > ion-button': {
            minWidth: 120
        }
    }
});

export type IAddClientProps = {
    orgId: string;
    isOpen?: boolean;
    closeHandler: () => void;
};

export const AddClient: FC<IAddClientProps> = ({ orgId, closeHandler, isOpen = false }) => {
    const classes = useStyles();

    const firstNameRef = useRef<HTMLIonInputElement>(null);
    const focusFirstName = useAutoFocus(firstNameRef);

    const [saving, setSaving] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [consentChecked, setConsentChecked] = useState<boolean>(false);

    const logger = useInstance(Logger);
    const history = useInstance(HistoryViewModel);

    const orgState = useInstance(OrganizationsState);
    useSubscription(() => orgState);

    const careMapState = useInstance(CareMapState);
    useSubscription(() => careMapState);

    const alertPresenter = useInstance(AlertPresenter);

    useEffect(() => {
        setIsValid(Boolean(firstName && lastName && email && consentChecked));
    }, [firstName, lastName, email, consentChecked]);

    const org = orgState.getOrganization(orgId)!;
    const chkBxLabel = `I certify that I have permission to add this person as a Client of ${org.name} and manage their care maps.The Client consents that ${org.name} and Innerhive can use the data I enter on their behalf for the purpose of managing care in the app.`;

    const resetForm = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setConsentChecked(false);
        setSaving(false);
    };

    const cancel = () => {
        resetForm();
        closeHandler();
    };

    const formChanged = (stateFn: (val: any) => void, value: any) => {
        stateFn(value);
    };

    const save = async () => {
        setSaving(true);

        try {
            const userId = await orgState.addOrganizationClient(orgId, { firstName, lastName, email });
            const { careMapId } = await orgState.addClientCaremap({ orgId, userId });

            closeHandler();
            // wait for animation of close to finish
            await new Promise(resolve => setTimeout(resolve, 350));
            resetForm();

            history.push(`/questionnaire/${careMapId}`);

            // TODO: tracking
            //tracking.track('Add Care Map Initiated');
        } catch (e: any) {
            setSaving(false);

            // if 409, adding duplicate user, it will not log to Sentry
            if (e.status === 409) {
                logger.error(e);
                alertPresenter.showAlertV2({header: 'Client Already Exists', message: `A client with this email has already been added to ${org.name}.`});
                closeHandler();
            }
            // if the user already exists
            else if (e.status === 200){
                alertPresenter.showAndLogError(e);
                closeHandler();
            }
            else {
                alertPresenter.showAndLogError(e);
            }
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            title="Add Client"
            desktopMaxHeight={480}
            data-id="add-client-modal"
            closeHandler={cancel}
            onDidPresent={focusFirstName}
        >
            <IonContent data-id="add-client-form">
                <IonCardContent>
                    <div className={classes.form}>
                        <TextField
                            inputRef={firstNameRef}
                            data-id="client-first-name"
                            name="firstName"
                            label="First Name"
                            value={firstName}
                            onInput={value => formChanged(setFirstName, value)}
                        />
                        <TextField
                            data-id="client-last-name"
                            name="lastName"
                            label="Last Name"
                            value={lastName}
                            onInput={value => formChanged(setLastName, value)}
                        />
                        <TextField
                            data-id="client-email"
                            name="email"
                            label="Email"
                            value={email}
                            onInput={value => formChanged(setEmail, value)}
                        />
                        <div style={{display: 'flex', alignItems: 'start'}}>
                            <div style={{padding: 15}} onClick={() => formChanged(setConsentChecked, !consentChecked)}>
                                <IonCheckbox
                                    data-id="consent-chkbx"
                                    checked={consentChecked}
                                    onClick={(e) => e.stopPropagation()}
                                    onIonChange={e => formChanged(setConsentChecked, !consentChecked)}
                                />
                            </div>

                            {/* render label separately because IonCheckbox won't let us set alignment */}
                            <div className={classes.chkBxLabel} onClick={() => formChanged(setConsentChecked, !consentChecked)}>
                                {chkBxLabel.split('\n').map((para, idx) => (
                                    <p key={`label-key-${idx}`}>{para}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                </IonCardContent>
            </IonContent>

            <IonFooter>
                <IonToolbar className={classes.buttonBar}>
                    <IonButton slot="start" data-type="cancel-button" fill="outline" onClick={cancel}>Cancel</IonButton>
                    <IonButton slot="end" data-id="save-client-button" disabled={!isValid || saving} onClick={save}>
                        {saving ? <IonSpinner name="dots" style={{height: 20}} /> : <>Add</>}
                    </IonButton>
                </IonToolbar>
            </IonFooter>
        </Modal>
    );
};
