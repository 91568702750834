

type ITokenType = 'user' | 'caremap-viewer';
export class AppPrincipal {
    constructor(){
        throw new Error('should not use ctor, see AppContainer');
    }

    tokenType: ITokenType;

    userId: string;

    email: string;
    isAdmin: boolean;
};

// encapsulate the logic
export const isCaremapViewer = (principal: AppPrincipal): boolean => {
    return principal.tokenType === 'caremap-viewer';
};
