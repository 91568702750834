import { IContactMethod } from '../caremap/ICareMap';
import { IonButton, isPlatform, useIonViewWillEnter } from '@ionic/react';
import { DrawerSectionHeading } from '../components/DrawerSectionHeading';
import { ContactMethod } from '../contact-methods/ContactMethod';

interface IContactMethodsSectionProps {
    contactMethods: IContactMethod[];
    onMethodsChanged: (contactMethods: IContactMethod[]) => void;
}

const getNewContactMethod = (): IContactMethod => {
    return {
        type: 'email',
        value: ''
    };
};

export const ContactMethodsSection: React.FC<IContactMethodsSectionProps> = ({ contactMethods, onMethodsChanged }) => {

    const onMethodChanged = (method: IContactMethod, idx: number) => {
        const copy = [ ...contactMethods ];
        copy.splice(idx, 1, method);
        onMethodsChanged(copy);
    };

    const onAddingContactMethod = () => {
        onMethodsChanged([
            ...contactMethods,
            getNewContactMethod()
        ]);
    };

    useIonViewWillEnter(() => {
        // if there are no contact methods, add the blank Primary Contact one (defaulting to email)
        if (!contactMethods || !contactMethods.length) {
            onAddingContactMethod();
        }
    }, [contactMethods]);

    return (
        <>
            <div>
                <DrawerSectionHeading text="Primary Contact" />
                <ContactMethod
                    method={contactMethods[0]}
                    methodChanged={(method: IContactMethod) => onMethodChanged(method, 0)}
                />
            </div>

            {contactMethods.length > 1 &&
                <div>
                    <DrawerSectionHeading text="Additional Contact Methods" />

                    <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                        {contactMethods.map((method: IContactMethod, idx) => {
                            // skip the first item as it's Primary Contact
                            return idx ? (
                                <ContactMethod
                                    key={`method-${idx}`}
                                    method={method}
                                    // BUG: for some reason it only autofocuses once, then not again until refreshing the browser
                                    //autoFocus={!method.value && (contactMethods.length-1 === idx)}
                                    methodChanged={(m: IContactMethod) => onMethodChanged(m, idx)}
                                />
                            ) : null;
                        })}
                    </div>
                </div>
            }

            <IonButton
                fill="outline"
                expand="block"
                onClick={(e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
                    // on desktop and native app, blur the button so the field can autofocus
                    if (isPlatform('desktop') || isPlatform('capacitor')) {
                        (e.nativeEvent?.target as HTMLIonButtonElement).blur();
                    }
                    onAddingContactMethod();
                }}
                data-type="add-contact-method">+ Add Contact Method</IonButton>
        </>
    );
};
