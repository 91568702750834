import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import React, { useEffect } from 'react';
import { CareMapState } from './CareMapState';
import { useToast } from '../components/useToast';

export const CaremapEnqueueErrorToaster: React.FC = () => {
    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);
    const [present] = useToast();

    useEffect(() => {
        if (careMapStateInstance.enqueuedSaveError){
            present({
                duration: 3000,
                message: careMapStateInstance.enqueuedSaveError?.displayMessage || 'Sorry something went wrong'
            });
        }

    }, [ careMapStateInstance.enqueuedSaveError, present ]);

    return null;
};
