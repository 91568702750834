import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect } from 'react';
import { LoadingPage } from '../LoadingPage';
import { APIUrl, InvitationService } from './InvitationService';
import { AlertPresenter } from '../AlertBinder';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { CareMapState } from '../caremap/CareMapState';
import { Logger } from '../support/Logger';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { UserState } from '../user/UserState';

export const InvitationAcceptancePage: React.FC<{ invite?: APIUrl; }> = ({ invite }) => {
    const invitationService = useInstance(InvitationService);
    const alert = useInstance(AlertPresenter);
    const history = useInstance(HistoryViewModel);
    const careMapState = useInstance(CareMapState);
    const logger = useInstance(Logger);
    const trackingService = useInstance(MixpanelService);
    const userState = useInstance(UserState);

    useEffect(() => {
        Promise.resolve().then(async () => {
            invitationService.validateInvite(invite);

            const action = await alert.showAlertV2({
                header: 'Accept Co-Owner Invite?',
                message: 'To accept this invite, please review and agree to the following: 1) I certify that I have permission to manage a care map on behalf of the Care Recipient. 2) The Care Recipient consents that Innerhive can use the data I enter on their behalf for the purpose of creating and managing a care map.',
                wider: true,
                options: [{
                    id: 'decline',
                    label: 'Decline',
                    role: 'destructive'
                }, {
                    id: 'consent',
                    label: 'Accept'
                }]
            });

            if (action === 'consent') {
                const careMap = await invitationService.acceptedInvite(invite!);
                history.push(`/caremaps/${careMap.careMapId}`);
                trackingService.track('Co-Owner Invite Accepted', () => ({'Invite Email': userState.state.email}));
            } else {
                try {
                    await invitationService.declinedInvite(invite!);
                } catch (e: any) {
                    // when accepting your OWN invite, returns a 409 err.status, err.errorCode
                    // it's not rejected from an API perspective, should show the user the message
                    // this will NOT go to Sentry (showAndLogError doesn't log to Sentry because it's < 500)
                    if (e.status === 409 && e.errorCode === 'cannot-accept-own-invite') {
                        throw e;
                    } else {
                        // ALL decline errors should be swallowed, but still send to SENTRY
                        if ((e?.status || 500) >= 500) {
                            logger.error(e);
                        }
                    }
                }

                history.push('/');
            }
        }).catch(e => {
            alert.showAndLogError(e);
            history.push('/');
        });
    }, [alert, invitationService, invite, history, careMapState, logger, trackingService, userState.state.email]);

    return <LoadingPage showLogo />;
};
