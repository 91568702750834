import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    link: {
        fontSize: 16,
        color: 'var(--ion-color-step-700)',
        cursor: 'pointer',

        '&:link, &:visited, &:active': {
            textDecoration: 'none'
        },

        '&:hover': {
            '@media (hover: hover)': {
                textDecoration: 'underline'
            }
        }
    }
});

interface IFooterLinkProps {
    size?: 'small' | 'medium' | 'large';
    href?: string;
    target?: string;
    onClick?: () => void;
    children: string | React.ReactNode;
}

export const FooterLink: React.FC<IFooterLinkProps> = ({ size, href, target, onClick, children }) => {
    const classes = useStyles();

    const handleClick = (e: any) => {
        if (!href) {
            e.preventDefault();
        }
        if (onClick) {
            onClick();
        }
    };

    const fontSize = {
        small: 12,
        medium: 14,
        large: 16
    }[size || 'medium'];

    return (
        <a className={classes.link} style={{fontSize}} href={href} target={target} onClick={handleClick}>{children}</a>
    );
};
