import React, { FC } from 'react';
import { DrawerPage } from '../components/DrawerPage';
import { ISupportGroupKey } from '../caremap/ICareMap';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { DeleteIcon, EditIcon } from '../theme/Icons';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { Text } from '../components/Text';
import { Menu } from '../components/Menu';
import { useDeleteMember } from './useDeleteMember';
import { SupportGrouIconProvider } from '../caremap/SupportGroupIconProvider';
import { ContactMethodsList } from '../contact-methods/ContactMethodsList';
import { NameHelper } from '../contact-methods/NameHelper';
import { NotesList } from '../notes/NotesList';
import { NudgeBanner } from '../components/NudgeBanner';
import { DrawerListSection } from '../components/DrawerListSection';
import { AppointmentsList } from '../appointments/AppointmentsList';
import { AppointmentsState, NEW_APPOINTMENT_ID } from '../appointments/AppointmentsState';

export interface ISupportGroupMemberViewProps {
    supportGroupKey: ISupportGroupKey;
    memberId: string;
};

export const SupportGroupMemberView: FC<ISupportGroupMemberViewProps> = ({ supportGroupKey, memberId }) => {
    const nameHelper = useInstance(NameHelper);

    const history = useInstance(HistoryViewModel);

    const deleteMember = useDeleteMember({ supportGroupKey, memberId, onDelete: history.back });

    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);

    const canEdit = careMapStateInstance.hasPermissionTo('put');

    const iconProvider = useInstance(SupportGrouIconProvider);
    const appointmentsState = useInstance(AppointmentsState);

    const member = careMapStateInstance.getSupportGroupMember({ supportGroupKey, memberId });

    if (!careMapStateInstance.state || !member) {
        return null;
    }

    const labels = careMapStateInstance.getSupportGroupLabels(supportGroupKey);
    const { color, MemberIcon } = iconProvider.getSupportGroupIcons(supportGroupKey);

    const editMember = () => {
        history.push(`/caremaps/${careMapStateInstance.getActiveCaremap()?.careMapId}/drawer/support-group/${supportGroupKey}/member/${member.id}/edit`);
    };

    const viewMoreNotes = () => {
        history.push(`/caremaps/${careMapStateInstance.getActiveCaremap()?.careMapId}/drawer/support-group/${supportGroupKey}/member/${memberId}/notes`);
    };

    const editNote = (noteId?: string) => {
        history.push(`/caremaps/${careMapStateInstance.getActiveCaremap()?.careMapId}/drawer/support-group/${supportGroupKey}/member/${memberId}/notes/${noteId}`);
    };

    const viewMoreAppointments = () => {
        history.push(`${history.getCurrentLocation().pathname}/appointments`);
    };

    const editAppointment = (appointmentId?: string) => {
        if (appointmentId === NEW_APPOINTMENT_ID) {
            appointmentsState.trackNewAppointmentOpened({ supportGroupKey, memberId });
        }

        history.push(`${history.getCurrentLocation().pathname}/appointments/${appointmentId}`);
    };

    const listShowCount = 2;
    const showNameFirst = careMapStateInstance.shouldShowNameFirst(supportGroupKey);
    const title = (showNameFirst ? member.name : member.role);
    const subtitle = (showNameFirst ? member.role : member.name);

    const needsNameOrRole = !member.name || ((supportGroupKey === 'financial' || supportGroupKey === 'community') && !member.role);
    const showEditNudgeBadge = canEdit && needsNameOrRole && !member.organization && !member.contactMethods.length && !member.address;

    return (
        <DrawerPage title={`${labels.memberTitle} Detail`} hasBack={true}>

            <div data-type="support-group-member-view" style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>

                <div style={{ display: 'flex', alignItems: 'start', gap: 20 }}>
                    <div style={{width: 50}}>
                        <MemberIcon color={color} size="xlarge" shape="round" />
                    </div>

                    <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
                        <Text type="title" data-type="title">{title || ''}</Text>
                        <Text type="subtitle" data-type="subtitle">{subtitle || ''}</Text>
                        <Text type="detail" data-type="organization">{member.organization}</Text>
                    </div>

                    {canEdit &&
                        <div style={{marginTop: -8}}>
                            <Menu actions={[
                                { Icon: EditIcon, text: `Edit ${labels.memberTitle}`, handler: editMember },
                                { Icon: DeleteIcon, text: `Delete ${labels.memberTitle}`, role: 'destructive', handler: deleteMember }
                            ]} />
                        </div>
                    }
                </div>

                {showEditNudgeBadge &&
                    <NudgeBanner
                        buttonLabel="Edit"
                        buttonClickHandler={editMember}
                        message="Enhance your care map with names, address, and contact info"
                    />
                }

                <ContactMethodsList
                    contactMethods={member.contactMethods}
                    address={member.address}
                    iconWidth={50}
                    emailToName={nameHelper.getName({ firstName: member.name })}
                    trackingContext={{ category: supportGroupKey, role: member.roleId || member.role }}
                />

                <DrawerListSection>
                    <NotesList
                        showCount={listShowCount}
                        editNoteHandler={editNote}
                        moreNotesHandler={viewMoreNotes}
                        notes={careMapStateInstance.getCareMap()[supportGroupKey].members.find(m => m.id === memberId)?.notes}
                    />
                </DrawerListSection>

                <DrawerListSection>
                    <AppointmentsList
                        showViewMore
                        numDaysToShow={1}
                        showCount={listShowCount}
                        minDate={(new Date()).toISOString()}
                        supportMemberId={memberId}
                        editAppointment={editAppointment}
                        viewMore={viewMoreAppointments}
                        data-type="appointments-list"
                    />
                </DrawerListSection>
            </div>

        </DrawerPage>
    );
};
