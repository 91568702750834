import { ReactNode } from 'react';
import { Card } from './Card';
import { useBreakpoints } from '../theme/useBreakpoints';

export const WrapWithCardOnLargerScreen: React.FC<{marginTop?: number, marginBottom?: number, children: ReactNode}> = ({ marginTop, marginBottom, children }) => {
    const { isAboveBreakpoint } = useBreakpoints();
    if (isAboveBreakpoint('md')) {
        return (
            <div style={{marginTop, marginBottom}}>
                <Card>{children}</Card>
            </div>
        );
    } else {
        return (
            <div>{children}</div>
        );
    }
};
