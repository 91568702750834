import { memo } from 'react';
import { NodeProps } from 'reactflow';
import { IHouseholdMember } from '../caremap/ICareMap';
import { INodeData } from './NodeAdapter';
import { MemberNodeWrapper } from './MemberNodeWrapper';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapDrawerRouter } from '../caremap/CareMapDrawerRouter';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { CareMapDrawerState } from '../caremap/CareMapDrawerState';

export const HouseholdMemberNode = memo(({ data }: NodeProps<INodeData<IHouseholdMember>>) => {
    const drawerState = useInstance(CareMapDrawerState);
    const drawerRouter = useInstance(CareMapDrawerRouter);

    const careMapState = useInstance(CareMapState);
    const inWizard = !careMapState.getActiveCaremap()?.essentialMapComplete;
    useSubscription(() => careMapState);

    // can't click items in the wizard
    const onClick = inWizard ? undefined : () => {

        // in regular caremap, clicking a member goes to the list view;
        // in focused caremap, clicking a member goes to the member view
        const isFocused = drawerState.isOpen();
        const memberId = (isFocused ? data.id : undefined);

        drawerRouter.navigateToSelectedMemberId({ basePath: `/caremaps/${careMapState.getActiveCaremap()?.careMapId}/drawer/household`, memberId });
    };

    return (
        <MemberNodeWrapper
            size="small"
            label={data.label}
            subtext={data.relationship}
            photo={data.photoDataURI || data.photo}
            color={data.color}
            avatar={data.avatar}
            memberId={data.id}
            onClick={onClick}
            selectedItemId={data.selectedItemId}
        />
    );
});
