import { Device, DeviceInfo } from '@capacitor/device';
import { App, AppInfo, AppState } from '@capacitor/app';
import { IUpdateStatus, NativeVersionChecker } from './NativeVersionChecker';
import { State } from '@meraki-internal/state';
import { EnvConfiguration } from '../../config/EnvConfiguration';
import { AlertPresenter } from '../../AlertBinder';
import { Logger } from '../Logger';
import { ISO8601 } from '../ISO8601';

type IVersion = string; // eg 1.2.3

const POLL_INTERVAL_MS = 1000 * 60 * 5; // every 5 minutes

interface IActiveVersionJSON {
    ios: {
        minVersion: IVersion;
        forceAfter: ISO8601;
    };
    android: {
        minVersion: IVersion;
        forceAfter: ISO8601;
    }
}

export class NativeVersionManager extends State<Record<string, never>> {
    static inject = () => [
        NativeVersionChecker,
        EnvConfiguration,
        AlertPresenter,
        Logger
    ];

    constructor(
        private versionChecker: NativeVersionChecker,
        private config: EnvConfiguration,
        private alert: AlertPresenter,
        private log: Logger
    ) {
        super({});
    }

    private deviceInfo!: DeviceInfo;
    private appInfo!: AppInfo;
    private minVersionUrl!: string;

    private status: IUpdateStatus | 'initializing' | 'initialized' = 'initializing';

    getStatus = () => this.status;

    init = async () => {
        this.deviceInfo = await Device.getInfo();
        if (this.deviceInfo.platform === 'web'){
            this.log.info('NativeVersionManager.init skipping b/c web');
            return;
        }
        if (this.config.ENV === 'local'){
            this.log.info('NativeVersionManager.init skipping b/c local');
            return;
        }
        if (!this.config.APP_STORE_URLS[this.deviceInfo.platform]){
            this.log.info(`NativeVersionManager.init skipping b/c no app store url for ${this.deviceInfo.platform}`);
            return;
        }

        this.appInfo = await App.getInfo();
        this.minVersionUrl = `https://live-updates.innerhive.com/${this.config.ENV === 'live' ? 'production' : 'staging'}.active-version.json`;

        this.subscribe(() => {
            this.maybeShowAlert();
        });

        // on foreground, remind the user
        App.addListener('appStateChange', ({ isActive }: AppState) => {
            if (isActive){
                this.maybeShowAlert();
            }
        });

        this.status = 'initialized';
        // not emitting .setState() b/c no one else needs to know
    };

    private maybeShowAlert = () => {
        if (this.status === 'must-update'){
            this.alertMustUpdateApp();
        }
        else if (this.status === 'should-update'){
            this.alertShouldUpdateApp();
        }
    };

    blockOnExpired = async () => {
        // if we weren't initialized eg b/c there are no app store urls
        // then do nothing
        if (this.status === 'initializing'){
            return;
        }
        try {
            await this.fetch();
            if (this.status === 'must-update'){
                await new Promise(resolve => {
                    // never
                });
            }

        }
        catch (err: any){
            this.log.error(err);
        }
    };

    startPolling = () => {
        // if we weren't initialized eg b/c there are no app store urls
        // then do nothing
        if (this.status === 'initializing'){
            return;
        }
        this.poll();
    };

    private poll = async () => {
        try{
            await this.fetch();
        }
        catch (err){
            this.log.error(err);
        }

        setTimeout(async () => {
            this.poll();
        }, POLL_INTERVAL_MS);
    };

    private fetch = async () => {
        this.log.info('fetching', this.minVersionUrl);
        const { android, ios }: IActiveVersionJSON = await window.fetch(this.minVersionUrl).then(res => res.json());
        this.log.info('got', { android, ios });

        const config = this.deviceInfo.platform === 'android' ? android : ios;

        const status = this.versionChecker.checkForUpdate({
            appVersion: this.appInfo.version,
            ...config
        });
        if (status !== this.status){
            this.log.info(`changing status from ${this.status} to ${status} b/c app version ${this.appInfo.version} and latest ${JSON.stringify(config)}`);
            this.status = status;
            this.setState({ });
        }
    };

    private alertMustUpdateApp = () => {
        this.alert.showAlert({
            header: 'Please Update Your Innerhive App',
            message: `To continue using the latest features and enhancements,
                you need to update to the newest version of the Innerhive app.
            `,
            buttons: [
                {
                    text: 'Update',
                    handler: () => {
                        window.location.href = this.getStoreURL();
                        return false; // so alert is not dimissed
                    }
                }
            ]
        });
    };

    private getStoreURL = () => {
        if (!this.deviceInfo || this.deviceInfo.platform === 'web'){
            throw new Error('not supported');
        }
        const appStoreUrl = this.config.APP_STORE_URLS[this.deviceInfo.platform];
        if (!appStoreUrl){
            throw new Error(`APP_STORE_URLS ${JSON.stringify(this.config.APP_STORE_URLS)} does not contain ${this.deviceInfo.platform}`);
        }
        return appStoreUrl;
    };

    private alertShouldUpdateApp = () => {
        this.alert.showAlert({
            header: 'Please Update Your Innerhive App',
            message: `To continue using the latest features and enhancements,
                you'll need to update to the newest version of the Innerhive app.
            `,
            buttons: [
                'Later',
                {
                    text: 'Update',
                    handler: () => {
                        window.location.href = this.getStoreURL();
                        return false; // so alert is not dimissed
                    }
                }
            ]
        });
    };
}
