import { EnvConfiguration } from '../config/EnvConfiguration';

export class ViewerUrlProvider {
    static inject = () => [EnvConfiguration];
    constructor(private config: EnvConfiguration) {}

    buildViewerUrl = ({ access_token }: { access_token: string; }) => {
        return `${this.config.WEB_BASE_URL}/?v=${access_token}#/caremap`;
    };
};
