import { State, useSubscription } from '@meraki-internal/state';
import { IonButton, IonContent, IonHeader, IonLabel, IonModal, IonTitle, IonToggle, IonToolbar, isPlatform } from '@ionic/react';
import { BackIcon, CloseIcon } from '../theme/Icons';
import { EnvConfiguration, IEnvironment } from '../config/EnvConfiguration';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { DevSettings, DevSettingsWriter } from './DevSettings';
import { LiveUpdatesManager, LiveUpdatesService } from '@meraki-internal/live-updates';
import { CloudwatchLogger } from './CloudwatchLogger';
import { useEffect, useState } from 'react';
import { AlertPresenter } from '../AlertBinder';
import { FeatureFlags } from './feature-flags/FeatureFlags';
import { RevenueCatDebugPage } from '../innerhive-plus/revenue-cat/RevenueCatDebugPage';

type DebugPageTab = 'settings' | 'live-updates' | 'feature-flags' | 'revenue-cat';

export class DebugPageState extends State<{visibleTab?: DebugPageTab}> {
    constructor(){
        super({ visibleTab: undefined });
    }
    showSettings = () => {
        this.setState({ visibleTab: 'settings' });
    };

    showLiveUpdates = () => {
        this.setState({ visibleTab: 'live-updates' });
    };

    showFeatureFlags = () => {
        this.setState({ visibleTab: 'feature-flags' });
    };

    hide = () => {
        this.setState({ visibleTab: undefined });
    };

    getVisibleTab = () => {
        return this.state?.visibleTab;
    };
}

const nameOverride: { [name: string]: string } = {
    allowIntercomProductionInStaging: 'allowIntercomProdInStaging'
};

type IEnvPlatformCondition = (params: { env: IEnvironment, isNative: boolean }) => boolean;

const enableCondition: {
    [settingKey: string]: IEnvPlatformCondition
} = {
    // require a device, and not local
    useStagingDeployChannel: ({ env, isNative }) => env !== 'local' && isNative,
    useStaging: ({ env, isNative }) => env !== 'local' && isNative,
};

const DevSettingsList = () => {
    const env = useInstance(EnvConfiguration).ENV;
    const isNative = isPlatform('capacitor');

    const settings = useInstance(DevSettings);
    const settingsWriter = useInstance(DevSettingsWriter);

    const alert = useInstance(AlertPresenter);

    const onChangeSetting = async (key: string, value: any) => {
        try{
            await settingsWriter.setSetting(key, value);

            if (key === 'useStaging') {
                alert.showAlertV2({
                    header: 'Restart Manually',
                    message: 'Restart the app for the env change to take effect.',
                    options: [] // no options means alert can't be dismissed
                });
            } else {
                window.location.reload();
            }
        }
        catch (err: any){
            // not logging, b/c this is internal only
            alert.showAlert({
                header: 'Failed',
                message: err.message
            });
        }

    };

    return (
        <ul style={{ listStyleType: 'none', paddingLeft: 20 }}>
            {Object.keys(settings).filter(key => typeof (settings as any)[key] === 'boolean').map(settingKey => (
                <li key={settingKey} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 4, marginLeft: 0}}>
                    <IonLabel style={{textTransform: 'capitalize'}}>{nameOverride[settingKey] || settingKey}</IonLabel>
                    <IonToggle
                        checked={(settings as any)[settingKey]}
                        onIonChange={e => onChangeSetting(settingKey, e.detail.checked) }
                        disabled={enableCondition[settingKey] && !enableCondition[settingKey]({ env, isNative })}
                    />
                </li>
            ))}
        </ul>
    );
};

export const DebugPage: React.FC = () => {
    const config = useInstance(EnvConfiguration);
    const liveUpdatesService = useInstance(LiveUpdatesService);
    const cloudwatch = useInstance(CloudwatchLogger);

    const debugPageState = useInstance(DebugPageState);
    useSubscription(() => debugPageState);

    const [throwCount, setThrowCount] = useState<{ actualThrowCount: number, requestedThrowCount: number}>({ actualThrowCount: 0, requestedThrowCount: 0});
    const requestThrow = () => {
        setThrowCount(count => ({
            ...count,
            requestedThrowCount: count.requestedThrowCount + 1
        }));
    };

    useEffect(() => {
        if (throwCount.requestedThrowCount > throwCount.actualThrowCount){
            setThrowCount(count => ({
                ...count,
                actualThrowCount: count.actualThrowCount + 1
            }));
            throw new Error('triggering render error');
        }
    }, [setThrowCount, throwCount]);

    const visibleTab = debugPageState.getVisibleTab();

    return (
        <IonModal
            // ionic doesn't easily support dynamically sized modals, so
            // this needs to be increased if we add settings/buttons below
            style={{'--min-height': '675px'}}

            isOpen={visibleTab !== undefined}
            onDidDismiss={debugPageState.hide}
        >

            <IonHeader>
                <IonToolbar>
                    {visibleTab !== 'settings' &&
                        <IonButton slot="start" fill="clear" onClick={debugPageState.showSettings}><BackIcon /></IonButton>
                    }
                    <IonTitle>Debug Settings</IonTitle>
                    <IonButton slot="end" fill="clear" onClick={debugPageState.hide}><CloseIcon /></IonButton>
                </IonToolbar>
            </IonHeader>

            <IonContent className="ion-padding">

                {visibleTab === 'settings' &&
                    <>
                        <h5>Environment</h5>
                        <ul>
                            <li>env: {config.ENV}</li>
                            <li>web: {window.location.origin}</li>
                            <li>webSHA: {process.env.VITE_APP_SHA}</li>
                            <li>version: {liveUpdatesService.getActualVersion()}</li>
                            <li>api: {config.API_BASE_URL}</li>
                            <li>logs: {cloudwatch.getStream()}</li>
                        </ul>

                        <h5>Dev Settings</h5>
                        <DevSettingsList />

                        <div style={{ display: 'flex', flexDirection: 'column'}} >
                            <IonButton onClick={debugPageState.showLiveUpdates} disabled={!liveUpdatesService.showManagerLink()}>Live Updates</IonButton>
                            <IonButton onClick={() => {throw new Error('Test Sentry Error');}}>Test Sentry Error</IonButton>
                            <IonButton onClick={requestThrow}>Test Sentry Render Error</IonButton>
                            <IonButton onClick={debugPageState.showFeatureFlags}>Feature Flags</IonButton>
                            <IonButton onClick={() => debugPageState.setState({ visibleTab: 'revenue-cat' })}>Revenue Cat</IonButton>
                        </div>
                    </>
                }

                {visibleTab === 'live-updates' &&
                    <LiveUpdatesManager />
                }
                {visibleTab === 'revenue-cat' &&
                    <RevenueCatDebugPage />
                }

                {visibleTab === 'feature-flags' &&
                    <FeatureFlags />
                }

            </IonContent>

        </IonModal>
    );
};
