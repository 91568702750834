import { IOrganizationUser } from './IOrganization';

export const ClientListSpan: React.FC<{ clients: IOrganizationUser[] }> = ({ clients }) => {
    if (clients.length === 0){
        // we get a flash of no clients as we remove the clients
        return null;
    }
    if (clients.length <= 3){
        return (
            <span>{clients.map(c => `${c.firstName} ${c.lastName}`).join(', ')}</span>
        );
    }
    return (
        <span>{clients.length} clients</span>
    );
};
