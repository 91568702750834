import { FC, useEffect } from 'react';
import { createAnimation, IonBackButton, IonButtons, IonContent, IonFooter, IonHeader, IonModal, IonPage, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { menuController } from '@ionic/core/components';
import { mediaMaxWidth } from '../theme/utils';
import { useBreakpoints } from '../theme/useBreakpoints';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapDrawerState } from '../caremap/CareMapDrawerState';
import { useSubscription } from '@meraki-internal/state';
import { MenuCloseButton } from './MenuCloseButton';

const MAX_MODAL_WIDTH = 450;

type IStyleKeys = 'header' | 'title' | 'titleWithBack' | 'headerButtonsToolbar' | 'hasHeaderButtons' | 'buttonBar' | 'modal' | 'contentStretch';

interface IStyleProps {
    width: number;
}

const useStyles = createUseStyles<IStyleKeys, IStyleProps>({
    header: {
        boxShadow: 'none',
        WebkitBoxShadow: 'none'
    },

    title: {
        textTransform: 'capitalize',

        [mediaMaxWidth.sm]: {
            fontSize: 19,
            paddingInline: '14px !important'
        }
    },

    titleWithBack: {
        // NOTE: don't make this a common practice, of media queries in between breakpoints. very specific case (School Team Member Detail)
        '@media (max-width: 389px)': {
            paddingInline: '6px !important'
        }
    },

    headerButtonsToolbar: {
        paddingLeft: 15,
        paddingRight: 22,

        '& ion-buttons': {
            marginBottom: -20
        }
    },

    hasHeaderButtons: {
        '&::part(scroll)': {
            paddingTop: 4
        }
    },

    buttonBar: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 20px',
        flexDirection: 'row-reverse',

        '& > ion-button': {
            minWidth: 120
        }
    },

    modal: {
        // this is .modal-wrapper, which is the animated element
        '&::part(content)': {
            position: 'absolute',
            opacity: 1,
            width: props => props?.width || MAX_MODAL_WIDTH,
            height: '100%'
        }
    },

    contentStretch: {
        '&::part(scroll)': {
            display: 'flex',
            flexDirection: 'column'
        }
    }
});

export type IDrawerModalAnimationOrigin = {
    x: number;
    y: number;
};

export type IDrawerPageProps = {
    hasBack?: boolean;
    hasClose?: boolean;
    title?: string;
    buttons?: React.ReactNode;
    headerButtons?: React.ReactNode;
    children: React.ReactNode;
    style?: React.CSSProperties;
    animationOrigin?: IDrawerModalAnimationOrigin;
    enteredHandler?: () => void;
    leavingHandler?: () => void;
    stretchHeight?: boolean;
    hasHeaderButtons?: boolean;
    excludeAutoDismiss?: boolean;
};

/**
 * <DrawerModal/> vs <DrawerPage /> vs <Drawer /> ?
 *
 * <Drawer/> is a right-aligned IonMenu containing an ionic router outlet so that routes can land in there
 * <DrawerPage/> contains an IonPage that is expected to render in <Drawer />'s router outlet
 * <DrawerModal/> contains an IonModal for when the router outlet isn't rendering the drawer, so we can animate it ourselves
 *
 * ---
 * <CareMapDrawer/> is the a PageLayout that contains a focused caremap (on the household or a support group) and a <Drawer/>
*/

export const DrawerModal: FC<IDrawerPageProps & { isOpen: boolean; onClose?: () => void }> = ({
    isOpen,
    onClose,
    buttons,
    hasClose,
    hasBack,
    title,
    children,
    animationOrigin,
    ...rest
}) => {
    const width =  Math.min(MAX_MODAL_WIDTH, window.innerWidth);
    const classes = useStyles({ width });

    const {isBelowBreakpoint} = useBreakpoints();
    const isBelowSm = isBelowBreakpoint('sm');

    // if < sm, we slide up from the bottom. otherwise, animate from target
    const enterAnimation = !isBelowSm ? (baseEl: HTMLElement) => {
        const root = baseEl.shadowRoot;
        const animationEl = root?.querySelector('.modal-wrapper')!;

        // if animationOrigin is not passed in, defaults to no offset and animates from center of drawer
        const offsetX = animationOrigin ? (window.innerWidth - animationOrigin.x) - (width / 2) : 0;
        const offsetY = animationOrigin ? animationOrigin.y - (window.innerHeight / 2) : 0;

        const wrapperAnimation = createAnimation()
            .addElement(animationEl)
            .beforeStyles({right: 0})
            .keyframes([
                { top: `${offsetY}px`, right: `${offsetX}px`, opacity: '0', transform: 'scale(0)' },
                { top: 0, right: 0, opacity: '0.99', transform: 'scale(1)' }
            ]);

        return createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(350)
            .addAnimation(wrapperAnimation);
    } : undefined;

    const leaveAnimation = enterAnimation ? (baseEl: HTMLElement) => {
        return enterAnimation(baseEl).direction('reverse');
    } : undefined;

    const breakpoint = isBelowSm ? 1 : 0;
    const animationProps = {
        initialBreakpoint: breakpoint,
        breakpoints: [breakpoint],
        enterAnimation: enterAnimation,
        leaveAnimation: leaveAnimation
    };

    return (
        <IonModal
            {...rest}
            {...animationProps}
            isOpen={isOpen}
            handle={false}
            showBackdrop={false}
            backdropDismiss={false}
            className={classes.modal}
            onDidDismiss={() => onClose ? onClose() : undefined}
        >
            <DrawerContent title={title} hasClose={hasClose} hasBack={hasBack} buttons={buttons} onClose={onClose}>
                {children}
            </DrawerContent>
        </IonModal>
    );
};

const DrawerContent: FC<IDrawerPageProps & {onClose?: () => void}>= ({
    title,
    hasBack,
    hasClose,
    onClose,
    buttons,
    headerButtons,
    children,
    stretchHeight,
    hasHeaderButtons
}) => {
    const classes = useStyles();

    return (
        <>
            <IonHeader className={classes.header}>
                <IonToolbar>
                    <IonButtons slot="start" style={{marginLeft: 0}}>
                        {hasBack &&
                            <IonBackButton style={{marginLeft: 4}} />
                        }
                    </IonButtons>
                    <IonTitle className={`${classes.title} ${hasBack ? classes.titleWithBack : ''}`}>
                        {title}
                    </IonTitle>
                    <IonButtons slot="end">
                        {hasClose &&
                            <MenuCloseButton onClick={onClose} />
                        }
                    </IonButtons>
                </IonToolbar>
                {headerButtons && (
                    <IonToolbar className={classes.headerButtonsToolbar}>
                        <IonButtons slot="end">
                            {headerButtons}
                        </IonButtons>
                    </IonToolbar>
                )}
            </IonHeader>
            <IonContent className={`ion-padding ${stretchHeight ? classes.contentStretch : ''} ${headerButtons ? classes.hasHeaderButtons : ''}`}>
                {children}
            </IonContent>
            {buttons &&
                <IonFooter>
                    <IonToolbar>
                        <div className={classes.buttonBar}>
                            {buttons}
                        </div>
                    </IonToolbar>
                </IonFooter>
            }
        </>
    );
};

export const DrawerPage: FC<IDrawerPageProps> = ({
    hasBack,
    hasClose = true,
    title,
    buttons,
    headerButtons,
    children,
    style = {},
    enteredHandler,
    leavingHandler,
    stretchHeight,
    ...rest
}) => {
    const drawerState = useInstance(CareMapDrawerState);
    useSubscription(() => drawerState);

    useEffect(() => {
        menuController.open('content-drawer');

        // call leave handler if closing drawer
        return () => {
            if (!drawerState.isOpen() && leavingHandler) {
                leavingHandler();
            }
        };
    }, [drawerState, leavingHandler]);

    const onClose = () => {
        menuController.close();
    };

    // TODO: this appears unsafe, assuming that enteredHandler
    // won't change on parent renders
    useIonViewDidEnter(() => {
        if (enteredHandler) {
            enteredHandler();
        }
    }, [enteredHandler]);

    // TODO: this appears unsafe, assuming that leavingHandler
    // won't change on parent renders
    useIonViewWillLeave(() => {
        if (leavingHandler) {
            leavingHandler();
        }
    }, [leavingHandler]);

    return (
        <IonPage style={style} {...rest}>
            <DrawerContent
                title={title}
                hasClose={hasClose}
                hasBack={hasBack}
                buttons={buttons}
                onClose={onClose}
                headerButtons={headerButtons}
                stretchHeight={stretchHeight}
            >
                {children}
            </DrawerContent>
        </IonPage>
    );
};
