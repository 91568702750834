import { SplashScreen } from '@capacitor/splash-screen';
import { useEffect } from 'react';

export const DismissSplashScreen: React.FC<{ children: any }> = ({ children }) => {

    useEffect(() => {
        setTimeout(async () => {

            await SplashScreen.hide();

        }, 100);
    }, []);

    return children;
};
