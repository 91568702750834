import { FC } from 'react';
import { ISearchSelectOption, ISelected, InternalSelect, SearchableSelectField } from '../components/SearchableSelectField';

interface IRoleSelectFieldProps {
    label: string;
    labelPlacement?: 'floating' | 'stacked';
    placeholder?: string;
    autoLookupOnEmpty?: boolean;
    roleId?: string;
    role?: string;
    options: ISearchSelectOption<string>[];
    onChange: (params: { roleId?: string; role?: string; }) => void;
    onBlur?: () => void;
    innerRef?: React.Ref<InternalSelect<ISelected<string>>>;
}

export const RoleSelectField: FC<IRoleSelectFieldProps> = ({ label, labelPlacement, placeholder, autoLookupOnEmpty, roleId, role, options, onChange, onBlur, innerRef }) => {

    const onRoleSelected = (newSelected?: ISelected) => {
        onChange({
            roleId: newSelected?.value || undefined,
            role: newSelected?.label || undefined
        });
    };

    let selected: ISelected | undefined;
    if (role) {
        selected = {
            value: roleId,
            label: role
        };
    }

    return (
        <SearchableSelectField
            data-type="role-dropdown"
            innerRef={innerRef}
            options={options}
            onChange={onRoleSelected}
            onBlur={onBlur}
            selected={selected}
            placeholder={placeholder}
            label={label}
            labelPlacement={labelPlacement}
            autoLookupOnEmpty={autoLookupOnEmpty}
        />
    );
};
