import { useState } from 'react';
import { useToast } from '../components/useToast';

export const useClipboard = () => {

    const [presentToast] = useToast();
    const [justCopiedTimeout, setJustCopiedTimeout] = useState<any>(undefined);

    const copyToClipboard = async ({ value, onCopied }: { value: string, onCopied?: () => void }) => {
        try {
            if (justCopiedTimeout){
                clearTimeout(justCopiedTimeout);
            }

            setJustCopiedTimeout(setTimeout(() => {
                setJustCopiedTimeout(undefined);
            }, 2000));

            await navigator.clipboard.writeText(value);

            if (onCopied) {
                onCopied();
            }

        } catch (ignore) {
            presentToast({ message: 'Sorry, we were unable to copy to your clipboard!', duration: 3000});
        }
    };

    const response: [typeof copyToClipboard, boolean] = [ copyToClipboard, Boolean(justCopiedTimeout) ];

    return response;
};
