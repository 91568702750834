import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { ViewerUrlProvider } from './ViewerUrlProvider';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { ILinkInfo, ShareLinkButton } from './ShareLinkButton';

export const ViewShareLinkButton: React.FC = () => {

    const careMapState = useInstance(CareMapState);
    const trackingService = useInstance(MixpanelService);
    const viewerUrlProvider = useInstance(ViewerUrlProvider);

    const shareDescription = `I’d like to share ${careMapState.getPatientFirstName()}'s Care Map with you. Open the view-only link to get started!`;

    const getDurationMessage = (linkDurationDays: number) => (
        <>This link allows anyone to <strong>view</strong> the care map for <strong>{linkDurationDays} day{linkDurationDays > 1 ? 's' : ''}</strong>.</>
    );

    const createLinkUrl = async ({ linkDurationDays }: ILinkInfo) => {
        const result = await careMapState.createViewerAccessToken(linkDurationDays!);
        return viewerUrlProvider.buildViewerUrl({ access_token: result.session_token });
    };

    const onSharingOpened = ({ linkDurationDays }: ILinkInfo) => {
        trackingService.track('View-Only Mobile Sharing Opened', () => ({ duration: linkDurationDays }));
    };

    const onLinkCopied = ({ linkDurationDays }: ILinkInfo) => {
        trackingService.track('View-Only Share Link Copied', () => ({ duration: linkDurationDays }));
    };

    return (
        <ShareLinkButton
            data-type="view-link"
            label="Invite Viewer"
            shareLabel="Share Care Map"
            shareDescription={shareDescription}
            createLinkUrl={createLinkUrl}
            onLinkCopied={onLinkCopied}
            onMobileSharingOpened={onSharingOpened}
            hasDuration={true}
            getDurationMessage={getDurationMessage}
        />
    );
};
