import { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { mediaMinWidth } from '../theme/utils';

const useStyles = createUseStyles({
    wrapper: {
        fontSize: 18,
        position: 'relative',
        display: 'inline-block',
        padding: 10,
        color: 'white',
        fontWeight: 600,
        background: 'rgba(0, 0, 0, 0.3)',
        borderRadius: 10,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        zIndex: 999
    },
    [mediaMinWidth.sm]: {
        wrapper: {
            fontSize: 24
        }
    }
});

export const CenteredMessageOverlay: React.FC<{children: ReactNode}> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            {children}
        </div>
    );
};
