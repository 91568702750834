import {
    IonPage,
    IonGrid,
    IonRow
} from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { CareMapState } from './caremap/CareMapState';
import { AppHeader } from './AppHeader';
import { CaremapEnqueueErrorToaster } from './caremap/CaremapEnqueueErrorToaster';
import { CareMapDrawerState } from './caremap/CareMapDrawerState';
import { useBreakpoints } from './theme/useBreakpoints';
import { DebugPage } from './support/DebugPage';
import { LiveUpdatesListener } from '@meraki-internal/live-updates';
import { IntercomService } from './support/IntercomService';
import { HistoryViewModel } from './support/HistoryViewModel';
import { NavigationMenu } from './navigation/NavigationMenu';
import { FeatureFlags } from './support/feature-flags/FeatureFlags';
import { InvitationAcceptancePage } from './share-caremap/InvitationAcceptancePage';
import { QuestionnaireRouteHandler } from './wizard/QuestionnaireRouteHandler';
import { InnerhivePlusDialog } from './innerhive-plus/InnerhivePlusDialog';
import { ConnectCalendarModal } from './appointments/ConnectCalendarModal';
import { DefaultRouter } from './routers/DefaultRouter';
import { ActiveQuestionnaireProvider, BlockUntilActiveCaremap } from './ActiveCareMapProvider';
import { HouseholdRouter } from './routers/HouseholdRouter';
import { CareMapRouter } from './routers/CareMapRouter';
import { SupportGroupRouter } from './routers/SupportGroupRouter';
import { OrganizationsRouter } from './routers/OrganizationsRouter';
import { HideIntercomOnQuestionniare } from './support/intercom/HideIntercomOnQuestionniare';
import { ActiveCaremapRouter } from './routers/ActiveCaremapRouter';
import { NotificationConsentDialog } from './push-notifications/NotificationsConsentDialog';
import { CaregiverRouter } from './routers/CaregiverRouter';
import { OrganizationsState } from './organizations/OrganizationsState';

const useStyles = createUseStyles({
    grid: {
        padding: 0,
        width: '100%',

        '--ion-grid-column-padding-xs': 0,
        '--ion-grid-column-padding-sm': 0,

        '& > ion-row': {
            height: '100%'
        }
    },
});

export const InnerhiveApp: React.FC = () => {
    const classes = useStyles();
    const { isAboveBreakpoint } = useBreakpoints();

    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);

    const organizations = useInstance(OrganizationsState);
    useSubscription(() => organizations);

    const drawerState = useInstance(CareMapDrawerState);
    useSubscription(() => drawerState);

    const intercomService = useInstance(IntercomService);
    const historyViewModel = useInstance(HistoryViewModel);

    const activeCaremap = careMapStateInstance.getActiveCaremap();
    const isInvite = historyViewModel.getCurrentLocation().pathname === '/accept-invite';
    const pageTitle = (activeCaremap?.essentialMapComplete ?
        `${isAboveBreakpoint('md') ? careMapStateInstance.getPatientFullName() + '\'s' : ''} Care Map`
        : isInvite ? <img height="24px" src="/assets/images/logos/logo-with-text-white.svg" alt="logo" /> : activeCaremap ? 'Questionnaire' :  ''
    );

    return (

        <IonPage>
            <HideIntercomOnQuestionniare />
            <LiveUpdatesListener />
            <AppHeader title={pageTitle} />
            <CaremapEnqueueErrorToaster />
            <InnerhivePlusDialog />
            <NotificationConsentDialog />
            <ConnectCalendarModal />
            <DebugPage />

            <Route exact path="/accept-invite" render={(routeProps) => {
                const params = new URLSearchParams(routeProps.location.search);
                return (
                    <InvitationAcceptancePage invite={params.get('invite') || undefined} />
                );
            }} />

            <CaregiverRouter />

            <OrganizationsRouter />

            <Route path={['/caremaps', '/caremap', '/questionnaire', '/tour', '/add-caremap', '/active-caremap']}>
                <IonGrid className={classes.grid} id="innerhive-grid">
                    <IonRow>
                        <Route exact path="/tour/:id" render={(routeProps) => {
                            const id: number = parseInt(routeProps.match.params.id);
                            const params = new URLSearchParams(routeProps.location.search);
                            const path: string = params.get('path') || 'caremaps';
                            intercomService.startTour(id);
                            historyViewModel.replace(`/#/${path}`);
                            return undefined;
                        }} />

                        <CareMapRouter />

                        {/* careMapId is optional, in order to catch old / bookmarked urls */}
                        <Route path="/questionnaire/:careMapId?" render={(props) => {

                            // the only questionnaire an org member should start will have an id
                            if (!props.match.params.careMapId && organizations.isOrgTeamMember()){
                                return <Redirect to="/" />;
                            }

                            if (!props.match.params.careMapId && careMapStateInstance.hasCareMaps()){
                                return <Redirect to="/caremaps" />;
                            }

                            return (
                                <ActiveQuestionnaireProvider careMapId={props.match.params.careMapId}>
                                    <QuestionnaireRouteHandler careMapId={props.match.params.careMapId} />
                                </ActiveQuestionnaireProvider>
                            );
                        }}>
                        </Route>
                    </IonRow>
                </IonGrid>
                <BlockUntilActiveCaremap>
                    <HouseholdRouter />
                    <SupportGroupRouter />
                    <NavigationMenu contentId="innerhive-grid" />
                </BlockUntilActiveCaremap>

                <ActiveCaremapRouter />
            </Route>

            <Route exact path="/feature-flags">
                <FeatureFlags />
            </Route>

            <Route exact path="/">
                <DefaultRouter />
            </Route>
        </IonPage>
    );
};
