import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Redirect } from 'react-router';
import { OrganizationsState } from '../organizations/OrganizationsState';

export const DefaultRouter: React.FC = () => {
    const orgsState = useInstance(OrganizationsState);

    let redirectTo = '/caremaps';

    if (orgsState.isOrgTeamMember()){
        redirectTo = `/org/${orgsState.getNonClientOrganizations()[0].orgId}/clients`;
    }

    return <Redirect to={redirectTo} />;
};
