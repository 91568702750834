export interface ILocalStorageOptions {
    envNeutral: boolean;
    userNeutral: boolean;
}

export interface ISmartStorageProvider<T> {
    /**
     * @deprecated use IAsyncSmartStorageProvider instead
     */
    exists: () => boolean;

    /**
     * @deprecated use IAsyncSmartStorageProvider instead
     */
    get: () => T | undefined;

    /**
     * @deprecated use IAsyncSmartStorageProvider instead
     */
    set: (value: T) => void;

    /**
     * @deprecated use IAsyncSmartStorageProvider instead
     */
    remove: () => void;
}

export class LocalStorageProvider {
    env: 'staging' | 'live' | 'unknown' = 'unknown';
    userId: string | undefined = undefined;

    setEnv = (env: 'staging' | 'live' | 'unknown') => {
        this.env = env;
        return this;
    };

    setUserId = (userId: string) => {
        this.userId = userId;
        return this;
    };

    private get = (key: string, options?: Partial<ILocalStorageOptions>): string | undefined => {
        const val = localStorage.getItem(this.getFullKey(key, options));
        if (!val){
            return undefined;
        }
        return val;
    };

    private exists = (key: string, options?: Partial<ILocalStorageOptions>): boolean => {
        return localStorage.getItem(this.getFullKey(key, options)) !== null;
    };

    private remove = (key: string, options?: Partial<ILocalStorageOptions>): void => {
        localStorage.removeItem(this.getFullKey(key, options));
    };

    private getJSON = <T>(key: string, options?: Partial<ILocalStorageOptions>): T | undefined => {
        const json = this.get(key, options);
        return json ? JSON.parse(json) as T : undefined;
    };

    private getFullKey = (key: string, options?: Partial<ILocalStorageOptions>) => {
        options = options || {} as ILocalStorageOptions;
        // default to NOT env neutral and NOT user neutral since that should be most things
        if (typeof options.envNeutral !== 'boolean'){
            options.envNeutral = false;
        }
        if (typeof options.userNeutral !== 'boolean'){
            options.userNeutral = false;
        }

        let fullKey = key;
        if (!options.envNeutral && this.env === 'unknown') {
            throw new Error(`attempted to get key "${key}" but env is not yet known`);
        }

        if (!options.userNeutral && !this.userId) {
            throw new Error(`attempted to get key "${key}" but userId is not yet known`);
        }

        if (!options.userNeutral) {
            fullKey = `${this.userId}:${fullKey}`;
        }

        if (!options.envNeutral) {
            fullKey = `${this.env}:${fullKey}`;
        }

        return fullKey;
    };

    private set = (key: string, value: string, options?: Partial<ILocalStorageOptions>) => {
        localStorage.setItem(this.getFullKey(key, options), value);
    };

    private setJSON = <T>(key: string, value: T, options?: Partial<ILocalStorageOptions>) => {
        localStorage.setItem(this.getFullKey(key, options), JSON.stringify(value));
    };

    getStringProvider = (key: string, options?: Partial<ILocalStorageOptions>): ISmartStorageProvider<string> => ({
        exists: () => this.exists(key, options),
        get: () => this.get(key, options),
        set: (value: string) => this.set(key, value, options),
        remove: () => this.remove(key, options)
    });

    getBooleanProvider = (key: string, options?: Partial<ILocalStorageOptions>): ISmartStorageProvider<boolean> => ({
        exists: () => this.exists(key, options),
        get: () => (this.get(key, options) === 'true'),
        set: (value: boolean) => this.set(key, String(value), options),
        remove: () => this.remove(key, options)
    });

    getJSONProvider = <T>(key: string, options?: Partial<ILocalStorageOptions>): ISmartStorageProvider<T> => ({
        exists: () => this.exists(key, options),
        get: () => this.getJSON<T>(key, options),
        set: (value: T) => this.setJSON<T>(key, value, options),
        remove: () => this.remove(key, options)
    });
}

