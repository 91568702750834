import { FC, useEffect, useRef, useState } from 'react';
import { Modal } from '../components/Modal';
import { IonButton, IonCardContent, IonCheckbox, IonContent, IonFooter, IonSpinner, IonToolbar } from '@ionic/react';
import { createUseStyles } from 'react-jss';
import { useToast } from '../components/useToast';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { Logger } from '../support/Logger';
import { TextField } from '../components/TextField';
import { OrganizationsState } from './OrganizationsState';
import { useSubscription } from '@meraki-internal/state';
import { CareMapState } from '../caremap/CareMapState';
import { useAutoFocus } from '../utils/useAutoFocus';

const useStyles = createUseStyles({
    form: {
        gap: 20,
        display: 'flex',
        flexDirection: 'column'
    },

    chkBxLabel: {
        paddingTop: 15,
        cursor: 'pointer',
        color: 'var(--ion-color-step-700)',
        '& p': {
            marginTop: -1
        }
    },

    buttonBar: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 20px',

        '& > ion-button': {
            minWidth: 120
        }
    }
});

export type IAddUserProps = {
    orgId: string;
    isOpen?: boolean;
    canAddAdmin?: boolean;
    closeHandler: () => void;
};

export const AddUser: FC<IAddUserProps> = ({ orgId, closeHandler, isOpen = false, canAddAdmin = false }) => {
    const classes = useStyles();

    const firstNameRef = useRef<HTMLIonInputElement>(null);
    const focusFirstName = useAutoFocus(firstNameRef);

    const [saving, setSaving] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [adminChecked, setAdminChecked] = useState<boolean>(false);

    const [toast] = useToast();
    const logger = useInstance(Logger);

    const orgState = useInstance(OrganizationsState);
    useSubscription(() => orgState);

    const careMapState = useInstance(CareMapState);
    useSubscription(() => careMapState);

    useEffect(() => {
        setIsValid(Boolean(firstName && lastName && email));
    }, [firstName, lastName, email]);

    const resetForm = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setAdminChecked(false);
        setSaving(false);
    };

    const cancel = () => {
        resetForm();
        closeHandler();
    };

    const formChanged = (stateFn: (val: any) => void, value: any) => {
        stateFn(value);
    };

    const save = async () => {
        setSaving(true);

        try {
            await orgState.addOrganizationUser(orgId, {
                firstName,
                lastName,
                email,
                role: adminChecked ? 'org-administrator' : 'org-staff'
            });

            closeHandler();
            // wait for animation of close to finish
            await new Promise(resolve => setTimeout(resolve, 350));
            resetForm();

            // TODO: tracking
            //tracking.track('Add Care Map Initiated');
        } catch (e: any) {
            // if 409, adding duplicate user, it will not log to Sentry
            if (e.status === 409) {
                logger.error(e);
                toast({duration: 3000, message: e.message});
            }
        }
    };

    //const org = orgState.getOrganization(orgId);

    return (
        <Modal
            isOpen={isOpen}
            title="Add Team Member"
            desktopMaxHeight={470}
            data-id="add-user-modal"
            closeHandler={cancel}
            onDidPresent={focusFirstName}
        >
            <IonContent data-id="add-user-form">
                <IonCardContent>
                    <div className={classes.form}>
                        {canAddAdmin && (
                            <div style={{display: 'flex', alignItems: 'start'}}>
                                <div style={{padding: 15}} onClick={() => formChanged(setAdminChecked, !adminChecked)}>
                                    <IonCheckbox
                                        data-id="admin-chkbx"
                                        checked={adminChecked}
                                        labelPlacement="end"
                                        onClick={(e) => e.stopPropagation()}
                                        onIonChange={e => formChanged(setAdminChecked, !adminChecked)}
                                    />
                                </div>
                                {/* render label separately because IonCheckbox won't let us set alignment */}
                                <div className={classes.chkBxLabel} onClick={() => formChanged(setAdminChecked, !adminChecked)}>
                                    <p>Administrator</p>
                                </div>
                            </div>
                        )}
                        <TextField
                            inputRef={firstNameRef}
                            data-id="user-first-name"
                            name="firstName"
                            label="First Name"
                            value={firstName}
                            onInput={value => formChanged(setFirstName, value)}
                        />
                        <TextField
                            data-id="user-last-name"
                            name="lastName"
                            label="Last Name"
                            value={lastName}
                            onInput={value => formChanged(setLastName, value)}
                        />
                        <TextField
                            data-id="user-email"
                            name="email"
                            label="Email"
                            value={email}
                            onInput={value => formChanged(setEmail, value)}
                        />
                    </div>
                </IonCardContent>
            </IonContent>

            <IonFooter>
                <IonToolbar className={classes.buttonBar}>
                    <IonButton slot="start" data-type="cancel-button" fill="outline" onClick={cancel}>Cancel</IonButton>
                    <IonButton slot="end" data-id="save-user-button" disabled={!isValid || saving} onClick={save}>
                        {saving ? <IonSpinner name="dots" style={{height: 20}} /> : <>Add</>}
                    </IonButton>
                </IonToolbar>
            </IonFooter>
        </Modal>
    );
};
