import { FC } from 'react';
import { PredefinedColors } from '@ionic/core';
import { IonButton, IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';

export const MenuCloseButton: FC<{
    onClick?: () => void;
    color?: PredefinedColors;
}> = ({ onClick, color }) => {
    return (
        <IonButton
            color={color}
            shape="round"
            onClick={onClick}
            data-id="close-button"
        >
            <IonIcon icon={close} />
        </IonButton>
    );
};
