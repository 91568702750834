import { IonFooter, IonToolbar } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { FC } from 'react';
import { FooterLink } from '../components/FooterLink';
import { AppPrincipal } from '../auth/AppPrincipal';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { IntercomService } from '../support/IntercomService';
import { createUseStyles } from 'react-jss';
import { DebugPageState } from '../support/DebugPage';
import { InnerhivePlusMenuButton } from '../innerhive-plus/InnerhivePlusMenuButton';
import { InnerhiveNavLogo } from '../innerhive-plus/InnerhiveNavLogo';

const useStyles = createUseStyles({
    footerContent: {
        gap: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: 20,
    },

    copyright: {
        fontSize: 14,
        color: 'var(--ion-color-step-400)'
    }
});

export type INavigationFooter = {
    closeMenu: () => void;
};

export const NavigationFooter: FC<INavigationFooter> = ({ closeMenu }) => {
    const classes = useStyles();

    const principal = useInstance(AppPrincipal);
    const tracking = useInstance(MixpanelService);
    const intercomService = useInstance(IntercomService);
    const debugPageState = useInstance(DebugPageState);

    const onCopyrightClicked = () => {
        if (principal.isAdmin) {
            closeMenu();
            debugPageState.showSettings();
        }
    };

    const onContactUsClicked = () => {
        closeMenu();

        tracking.track('Contact Us Opened');
        setTimeout(intercomService.showNewMessage, 350);
    };

    return (
        <IonFooter className="ion-no-border">

            <div style={{margin: 24}}>
                <InnerhivePlusMenuButton />
            </div>

            <IonToolbar>
                <div className={classes.footerContent}>
                    <InnerhiveNavLogo />
                    <div style={{display: 'flex', gap: 20}}>
                        <FooterLink size="large" onClick={onContactUsClicked}>Contact Us</FooterLink>
                        <FooterLink size="large" href="https://www.innerhive.com/privacy-policy" target="_blank"
                            onClick={() => tracking.track('Privacy Policy Opened')}>Privacy Policy
                        </FooterLink>
                    </div>
                    <div className={classes.copyright} onClick={onCopyrightClicked}>&copy; 2023 All Rights Reserved</div>
                </div>
            </IonToolbar>
        </IonFooter>
    );
};
