import { AlertPresenter } from '../AlertBinder';
import { PaywallAwareButton } from '../components/PaywallAwareButton';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { CareMapState } from './CareMapState';
import { IFreeTierLimits } from '../innerhive-plus/revenue-cat/IFreeTierLimits';
import { InnerhivePlusText } from '../components/InnerhivePlusText';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { OrganizationsState } from '../organizations/OrganizationsState';

export const AddCareMapButton: React.FC<{closeMenu: () => void}> = ({ closeMenu }) => {

    const tracking = useInstance(MixpanelService);
    const history = useInstance(HistoryViewModel);
    const alert = useInstance(AlertPresenter);

    const orgState = useInstance(OrganizationsState);
    useSubscription(() => orgState);

    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);

    const maps = careMapStateInstance.getCareMaps();

    const requiresInnerhivePlus = (limits: IFreeTierLimits) => maps.length >= limits.freeCaremapsOwned;
    const innerhivePlusMessage = <>Limit reached, get <InnerhivePlusText/> to add</>;

    const closeAndExecute = (handler: () => void) => {
        closeMenu();
        setTimeout(() => {
            handler();
        }, 350);
    };

    const onAddCareMap = async () => {
        const unNamed = careMapStateInstance.getUnamedCareMap();
        const canAdd = unNamed === undefined;
        if (canAdd) {
            closeAndExecute(() => {
                history.push(`/add-caremap`);
                tracking.track('Add Care Map Initiated');
            });
        } else {
            const action = await alert.showAlertV2({
                header: 'Care Map In Progress',
                message: 'You’ve already started a care map, continue where you left off!',
                backdropDismiss: true,
                options: [{
                    id: 'cancel',
                    label: 'Cancel',
                    role: 'cancel'
                }, {
                    id: 'continue',
                    label: 'Continue'
                }]
            });

            if (action === 'continue') {
                closeAndExecute(() => {
                    history.push(`/caremaps/${unNamed.careMapId}`);
                });
            }
        }
    };

    return (
        <PaywallAwareButton
            requiresInnerhivePlus={requiresInnerhivePlus}
            innerhivePlusMessage={innerhivePlusMessage}
            expand="block"
            data-id="add-care-map-button"
            fill="outline"
            triggerSource="care map limit"
            onClick={onAddCareMap}
        >
            +&nbsp;Add Care Map
        </PaywallAwareButton>
    );
};
