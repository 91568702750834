import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { IonSpinner, isPlatform } from '@ionic/react';
import {useDropzone, FileRejection} from 'react-dropzone';
import { UploadIcon } from '../theme/Icons';
import { Logger } from '../support/Logger';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AvatarOrColorSelector, ISegmentValue } from '../components/AvatarOrColorSelector';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { DefaultAvatarProvider } from './DefaultAvatarProvider';
import { MemberAvatar } from '../household/MemberAvatar';
import { MixPanelEventEmitter } from '../metrics/MixPanelEventEmitter';
import { useToast } from '../components/useToast';

type IStyleKeys = 'dropZone' | 'uploadIcon' | 'uploadSpinner' | 'selectionPreview' | 'dragDrop' | 'browseLink' | 'helpText' | 'rejectionMessage';
interface IStyleProps {
    isDesktop: boolean;
};

const useStyles = createUseStyles<IStyleKeys, IStyleProps>({
    dropZone: {
        width: '95%',
        height: 200,
        minHeight: 200,
        borderRadius: 12,
        border: ({ isDesktop }) => isDesktop ? '2px dashed var(--ion-color-primary)' : '',
        background: '#edf2fd',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },

    uploadIcon: {
        height: 72,
        paddingTop: 5
    },

    uploadSpinner: {
        width: 50,
        height: 50,
        marginTop: 5
    },

    selectionPreview: {
        marginTop: -15,
        marginBottom: 15,
        borderRadius: '50%',
        border: '2px solid transparent',
        boxShadow: '2px 4px 12px #9a9a9a'
    },

    dragDrop: {
        marginBottom: 15,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 'larger',
        color: 'var(--ion-text-color)'
    },

    browseLink: {
        cursor: 'pointer',
        color: 'var(--ion-color-primary)'
    },

    helpText: {
        marginBottom: 6,
        textAlign: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        color: 'var(--ion-step-color-700)'
    },

    rejectionMessage: {
        height: 18,
        minHeight: 18,
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center',
        color: 'var(--ion-color-danger)'
    }
});

// S3 PUT signed url has limit of 5 GB
// API GW has a limit of 10MB and Lambda has a limit of 6 MB
// we observed a 4.8 MB image fail
const maxFileSizeMB = 50;

// this is the actual file size we validate against
// with some padding, so that a ${maxFileSizeMB}MB file on disk, that is say ${maxFileSizeMB}.01 MB in memory
// when we validate it, doesn't throw
const maxFileSize = (maxFileSizeMB + .5) * 1024 * 1024;

const FILE_TOO_LARGE_MESSAGE = `Sorry, your image is too large. Please try again with an image that is smaller than ${maxFileSizeMB} MB.`;

const acceptedFileTypes: {[key: string]: string[]} = {
    'image/png': ['.png'],
    // 'image/gif': ['.gif'], not supported b/c animated gifs
    'image/jpeg': ['.jpeg', '.jpg']
};

export interface IAvatarPhotoColor {
    avatar?: string;
    color?: string;
    photo?: string;
    photoDataURI?: string;
}

const fileToDataURI = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = () => {
            if (!fr.result){
                reject(new Error(`FileReader yielded null which is not expected or supported`));
            } else {
                resolve(fr.result.toString());
            }
        };
        fr.readAsDataURL(file);
    });
};


export const PhotoAvatarOrColorPicker: React.FC<{
    avatar: IAvatarPhotoColor;
    onAvatarChanged: (avatar: IAvatarPhotoColor)=> void;
}> = ({ avatar, onAvatarChanged }) => {
    const log = useInstance(Logger);
    const [present] = useToast();
    const careMap = useInstance(CareMapState);
    useSubscription(() => careMap);
    const mixpanel = useInstance(MixPanelEventEmitter);

    const defaultAvatarProvider = useInstance(DefaultAvatarProvider);

    const defaultAvatar = defaultAvatarProvider.getDefaultAvatar({ careMap });

    const [uploading, setUploading] = useState<boolean>(false);

    const classes = useStyles({ isDesktop: isPlatform('desktop') });
    const [rejectionMessage, setRejectionMessage] = useState<string>();

    const rejected = (rejections: FileRejection[]) => {
        switch(rejections[0].errors[0].code) {
            case 'file-invalid-type':
                const fileTypeKeys = Object.keys(acceptedFileTypes);

                let extensions: string[] = [];

                fileTypeKeys.forEach((key, idx) => {
                    extensions = extensions.concat(acceptedFileTypes[key]);
                });

                const excludedExtensions = [

                    // .jpeg is the same as jpg so zannah wants only .jpg
                    '.jpeg'
                ];
                extensions = extensions.filter(e => !excludedExtensions.includes(e));

                const extensionsString = extensions
                    // remove the leading .
                    .map(e => e.substring(1))
                    .join(', ');

                const msg = `Please try again with a supported image type: ${extensionsString}`;

                setRejectionMessage(msg);
                break;
            case 'file-too-large':
                mixpanel.track('Visual Upload Failed', () => ({
                    reason: 'too-large',
                    size: rejections[0].file.size
                }));

                setRejectionMessage(FILE_TOO_LARGE_MESSAGE);
                break;
            default:
                setRejectionMessage(rejections[0].errors[0].message);
                break;
        }
    };

    const accepted = async (files: File[]) => {
        setRejectionMessage('');
        setUploading(true);

        try {
            const file = files[0];
            const photoDataURI = await fileToDataURI(file);
            onAvatarChanged({ photoDataURI, color: undefined, avatar: undefined, photo: undefined });

            const { url } = await careMap.uploadFile(file);

            onAvatarChanged({ photo: url, color: undefined, avatar: undefined, photoDataURI: undefined });
        } catch (e: any) {
            onAvatarChanged({ photoDataURI: undefined });

            let message = 'Sorry, we were unable to upload your photo. Please try again.';

            // this doesn't actually happen because API GW edge doesn't return our CORS headers :(
            if (e && e.status === 413){
                message = FILE_TOO_LARGE_MESSAGE;
            }

            setRejectionMessage(message);

            // they might have clicked back, so lets also toast
            present({duration: 3000, message: message});

            e.message = `uploadPhoto failed: ${e.message}`;
            log.error(e);
        }

        setUploading(false);
    };

    const {getRootProps, getInputProps, open, isDragAccept} = useDropzone({
        noClick: true,
        maxFiles: 1,
        multiple: false,
        maxSize: maxFileSize,
        noKeyboard: true,
        accept: acceptedFileTypes,
        onDropRejected: rejected,
        onDropAccepted: accepted
    });

    const avatarOrColorSelected = (type: ISegmentValue, value: string) => {
        if (type === 'avatars') {
            onAvatarChanged({ avatar: value, color: undefined, photo: undefined, photoDataURI: undefined });
        } else {
            onAvatarChanged({ color: value, avatar: undefined, photo: undefined, photoDataURI: undefined });
        }
    };

    const dropzoneVerbiage = isPlatform('desktop') ? (
        <>Drag and drop or <span className={classes.browseLink} onClick={open}>Browse</span></>
    ) : (
        <span className={classes.browseLink} onClick={open}>Choose Image</span>
    );

    return (
        <>
            <div className={classes.selectionPreview}>
                <MemberAvatar member={{photo: avatar.photo, color: avatar.color, avatar: avatar.avatar}} />
            </div>
            <div className={classes.dropZone} {...getRootProps({style: isDragAccept ? {borderColor: 'var(--ion-color-success)'} : {}})}>
                <div className={classes.uploadIcon}>
                    {!uploading ?
                        <div onClick={open} className={classes.browseLink}><UploadIcon size="xxlarge" /></div>
                        : <IonSpinner className={classes.uploadSpinner} name="dots" color="primary"></IonSpinner>
                    }
                </div>
                <div className={classes.dragDrop}>
                    {dropzoneVerbiage}
                </div>
                <div className={classes.helpText}>Tip: choose photo with subject in center</div>
                <div className={classes.helpText}>Max size: {maxFileSizeMB} MB</div>
                <input {...getInputProps()} data-type="photo-upload-input"></input>
            </div>
            <div className={classes.rejectionMessage} data-id="rejection-message">{rejectionMessage}</div>
            <AvatarOrColorSelector
                onSelect={avatarOrColorSelected}
                defaultAvatar={defaultAvatar}
                avatar={avatar}
            />
        </>
    );
};
