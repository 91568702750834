import { FC } from 'react';
import { IAllergy } from '../caremap/ICareMap';
import { DrawerSectionHeading } from '../components/DrawerSectionHeading';
import { createUseStyles } from 'react-jss';
import { ClipboardListIcon } from '../theme/Icons';
import { PrivacyBadge } from '../components/PrivacyBadge';
import { IonText } from '@ionic/react';
import { AppPrincipal, isCaremapViewer } from '../auth/AppPrincipal';
import { useInstance } from '@meraki-internal/react-dependency-injection';

const useStyles = createUseStyles({
    listWrapper: {
        paddingLeft: 16
    },

    row: {
        display: 'flex'
    },

    icon: {
        padding: '0px 8px 8px 0px'
    },

    allergy: {
        flexGrow: 1,
        paddingTop: 1
    }
});

export type IAllergyList = {
    allergies?: IAllergy[];
    isPublic?: boolean;
};

export const AllergyList: FC<IAllergyList> = ({ allergies, isPublic = false }) => {
    const styles = useStyles();
    const isViewer = isCaremapViewer(useInstance(AppPrincipal));

    if (!isPublic && isViewer) {
        return null;
    }

    return (
        <div data-type="allergies-list">
            <DrawerSectionHeading text="Allergies">
                {!isPublic && (
                    <div style={{marginRight: 38}}>
                        <PrivacyBadge data-type="allergies-private-badge" />
                    </div>
                )}
            </DrawerSectionHeading>
            <div className={styles.listWrapper}>
                {(!allergies || allergies.length === 0) && (
                    <IonText color="medium" style={{paddingLeft: 12}}>
                        No allergies
                    </IonText>
                )}
                {allergies?.map((allergy, idx) => (
                    <div key={`${allergy.allergyId}-${idx}`} className={styles.row}>
                        <div className={styles.icon}><ClipboardListIcon color="mediumtext" /></div>
                        <div className={styles.allergy}>{allergy.allergy}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
