import dayjs from 'dayjs';

export const formatAge = (dob?: string) => {
    if (!dob) {
        return '';
    }

    const today = dayjs();
    const dayjsDob = dayjs(dob);
    const years = today.diff(dayjsDob, 'years');

    const count = (years ? years : today.diff(dayjsDob, 'months'));
    const units = (years ? 'yr' : 'mo') + (count !== 1 ? 's' : '');

    return `${count} ${units} old`;
};
