import { FC, MouseEvent, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    href: {
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'var(--ion-color-primary)',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

export type IHrefProps = {
    url?: string;
    onClick?: () => void;
    children: ReactNode;
    target?: '_blank' | '_self';
};

export const Href: FC<IHrefProps> = ({ url, onClick, children, target = '_blank' }) => {
    const classes = useStyles();

    if (!url && !onClick) {
        throw new Error('Either url or onClick is required');
    }

    const handleClick = (e: MouseEvent) => {
        e.stopPropagation();

        if (onClick) {
            onClick();
        }
    };

    return (
        <a href={url} target={target} className={classes.href} onClick={handleClick}>{children}</a>
    );
};
