import { useEffect } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CheckinState } from './CheckinState';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { IFeeling } from './ICheckin';

export const ShowCheckin: React.FC = () => {
    const checkinState = useInstance(CheckinState);
    const history = useInstance(HistoryViewModel);

    useEffect(() => {
        const params = history.getCurrentSearchParamMap();

        const checkin = checkinState.getCheckinForToday();

        if (params.feeling) {
            history.replace('/caregiver/daily-log');
        }

        if (params.feeling in IFeeling) {
            checkin.feeling = Number(params.feeling);
            checkinState.saveCheckin(checkin);
        }

        checkinState.startEditing(checkin);

    }, [checkinState, history]);

    return null;
};
