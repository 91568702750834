
export const nodeOrbitLayout = ({
    radius,
    maxMoons,
    startAngles,
    planet,
    moons}: {radius: number, maxMoons: number, startAngles: number[], planet: any, moons: any[]}) => {
    // Calculate the offsets
    const planetWidth = planet.width;
    const planetTop = planet.position.y;
    const planetLeft = planet.position.x;
    const offsetToParentCenter = planetWidth ? planetWidth / 2 : 0;

    const div = 360 / maxMoons;
    let angle = 360 - startAngles[moons.length-1];

    moons.forEach((node: any, indx: number) => {
        const y = Math.sin((angle) * (Math.PI / 180)) * radius;
        const x = Math.cos((angle) * (Math.PI / 180)) * radius;

        const nodeWidth = node.width;
        const offsetToChildCenter = nodeWidth ? nodeWidth / 2 : 0;
        const totalOffset = offsetToParentCenter - offsetToChildCenter;

        node.position.y = y + totalOffset + planetTop;
        node.position.x = x + totalOffset + planetLeft;

        angle += div;
    });
};
