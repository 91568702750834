import { FC, useEffect, useState } from 'react';
import { useMaskito } from '@maskito/react';
//import options from '../utils/DateMask';
import {maskitoDateOptionsGenerator} from '@maskito/kit';
import { TextField } from './TextField';
import dayjs from 'dayjs';

export interface IDateFieldProps {
    name?: string;
    label?: string;
    value: string | undefined;
    onChange: (value: string) => void;
    'data-type'?: string;
}

const formatDate = (isoDate?: string) => {
    return isoDate ? dayjs(isoDate).format('MM/DD/YYYY') : '';
};

export const DateField: FC<IDateFieldProps> = ({ name = '', label = '', value, onChange, 'data-type': dataType }) => {
    const [fieldText, setFieldText] = useState<string | undefined>(formatDate(value));

    const options = maskitoDateOptionsGenerator({mode: 'mm/dd/yyyy', separator: '/', max: new Date()});
    const dobMask = useMaskito({options});

    useEffect(() => {
        if (value) {
            setFieldText(formatDate(value));
        }
    }, [value]);

    const inputHandler = (text: string) => {
        setFieldText(text);

        const dayjsObj = dayjs(text, 'MM/DD/YYYY', true);
        if (dayjsObj.isValid()) {
            onChange(dayjsObj.toISOString());
        } else {
            onChange('');
        }
    };

    return (
        <TextField
            inputRef={async (dobRef) => {
                if (dobRef) {
                    const input = await dobRef.getInputElement();
                    dobMask(input);
                }
            }}
            name={name}
            label={label}
            value={fieldText}
            placeholder="mm/dd/yyyy"
            data-type={dataType}
            onInput={inputHandler}
        ></TextField>
    );
};
