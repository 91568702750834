import { State } from '@meraki-internal/state';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';

export class ConnectCalendarViewModel extends State<Record<string, never>> {

    private _isOpen = false;

    static inject = () => [MixpanelService];

    constructor(
        private tracking: MixpanelService
    ) {
        super({ });
    };

    isOpen = () => this._isOpen;

    open = () => {
        this.tracking.track('Connect Calendar Modal Opened');

        this._isOpen = true;
        this.setState({ });
    };

    close = () => {
        this._isOpen = false;
        this.setState({ });
    };
}
