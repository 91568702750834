import { AppPrincipal } from '../auth/AppPrincipal';
import { EnvConfiguration } from '../config/EnvConfiguration';
import { MixPanelEventEmitter } from '../metrics/MixPanelEventEmitter';
import { UserState } from '../user/UserState';
import { HistoryViewModel } from './HistoryViewModel';
import { Logger } from './Logger';

const global: any = window;

const SUPPORTED_EVENTS = [
    'Initial Questionnaire Completed',
    'View-Only Share Link Copied'
];

// NOTE: intercom launcher is hidden when drawer is open (see styling in app.css)
export class IntercomService {

    static inject = () => [
        EnvConfiguration,
        Logger,
        UserState,
        MixPanelEventEmitter,
        HistoryViewModel
    ];
    constructor(
        private config: EnvConfiguration,
        private log: Logger,
        private user: UserState,
        private mixPanel: MixPanelEventEmitter,
        private history: HistoryViewModel
    ) {}

    private initialized = false;

    isEnabled = () => Boolean(global.Intercom) && this.initialized;

    startTour = (id: number) => global.Intercom('startTour', id);

    showArticle = (id: number) => global.Intercom('showArticle', id);

    showNewMessage = (content?: string) => global.Intercom('showNewMessage', content);

    init = ({ principal }: { principal: AppPrincipal }) => {
        if (this.config.INTERCOM_APP_ID) {
            try {

                // next line is unmodified js code block copied from intercom site
                // @ts-ignore-next-line
                // eslint-disable-next-line
                (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/vortkqmn';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

                const intercomConfig = {
                    api_base: 'https://api-iam.intercom.io',
                    app_id: this.config.INTERCOM_APP_ID,
                    email: principal.email,
                    user_id: principal.userId,

                    // intentionally only sending first name (to minimize our PII footprint)
                    name: this.user.state.firstName || '',
                };

                global.Intercom('boot', intercomConfig);

                this.initialized = true;

                this.mixPanel.onTrack((event, context) => {
                    this.triggerEvent(event, context);
                });

                this.user.onSaved(() => {
                    this.syncUserToIntercom();
                });

                this.registerArticleListener();

            } catch (e: any) {
                e.message = `Failed to initialize Intercom. ${e.message}`;
                this.log.error(e);
            }
        }

        return this;
    };

    // handles query param ?showArticle=12345 to open a help article
    private registerArticleListener = () => {
        const handleRequestedArticle = () => {
            const articleId = this.history.popSearchParam({ key: 'showArticle' });
            if (articleId) {
                this.showArticle(Number(articleId));
            }
        };
        this.history.listen(() => {
            handleRequestedArticle();
        });
        handleRequestedArticle();
    };

    private syncUserToIntercom = () => {
        if (this.user.state.firstName){
            try{
                global.Intercom('update', {
                    // intentionally only sending first name (to minimize our PII footprint)
                    name: this.user.state.firstName!
                });
            }
            catch (err: any){
                this.log.error('failed to syncUserToIntercom ' + err.toString());
            }
        }
    };

    triggerEvent = (event: string, context?: any) => {
        try {
            if (SUPPORTED_EVENTS.includes(event)) {
                this.log.info(`Triggered Intercom Event "${event}"`, context);

                if (this.isEnabled()) {
                    global.Intercom('trackEvent', event, context);
                }
            }

        } catch (e: any) {
            e.message = `Failed to track Intercom event "${event}": ${e.message}`;
            this.log.error(e);
        }
    };
}
