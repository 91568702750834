import { ReactNode, useEffect } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { OrganizationsState } from '../organizations/OrganizationsState';

// TODO: revist once ActiveCaremapProvider lands
export const ActiveOrganizationProvider: React.FC<{orgId: string; children: ReactNode}> = ({ orgId, children }) => {
    const orgState = useInstance(OrganizationsState);

    useEffect(() => {
        orgState.setActiveOrganization(orgId);

        return () => {
            orgState.unsetActiveOrganization();
        };
    }, [orgId, orgState]);

    return orgState.hasActiveOrganization() ? children : null;
};
