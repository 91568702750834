import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useIonAlert } from '@ionic/react';
import { useSubscription } from '@meraki-internal/state';
import { CareMapState } from '../caremap/CareMapState';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';

export const useDeleteHouseholdMember = ({ memberId, onDelete }: { memberId: string, onDelete?: () => void }) => {
    const tracking = useInstance(MixpanelService);

    const [presentAlert] = useIonAlert();

    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);

    // ionic role is not strongly typed
    const destructiveRole = 'destructive';

    return () => {
        presentAlert({
            header: `Delete Household Member?`,
            message: `Household Member will be deleted from care map and cannot be recovered.`,
            cssClass: 'drawer-alert',
            onDidDismiss: (e) => {
                if (e.detail.role === destructiveRole && onDelete) {
                    onDelete();
                }
            },
            buttons: [{
                text: 'Delete',
                role: destructiveRole,
                handler: () => {
                    careMapStateInstance.removeHouseholdMember(memberId);
                    tracking.track('Household Member Removed');
                }
            }, {
                text: 'Cancel',
                role: 'cancel'
            }]
        });

    };
};
