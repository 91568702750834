import { FC } from 'react';
import { SearchableSelectField, ISelected } from './SearchableSelectField';
import { IRelationship } from '../caremap/ICareMap';
import { SupportGroupDataProvider } from '../caremap/SupportGroupDataProvider';
import { useInstance } from '@meraki-internal/react-dependency-injection';

interface IRelationshipSelectFieldProps {
    label: string;
    relationshipId?: IRelationship;
    relationship?: string;
    onChange: (params: { relationshipId?: IRelationship; relationship?: string; }) => void;
}

export const RelationshipSelectField: FC<IRelationshipSelectFieldProps> = ({ label, relationshipId, relationship, onChange }) => {
    const dataProvider = useInstance(SupportGroupDataProvider);

    let selected: ISelected<IRelationship> | undefined;
    if (relationship){
        selected = {
            value: relationshipId,
            label: relationship
        };
    }

    const onSelected = (newlySelected?: ISelected<IRelationship>) => {
        onChange({
            relationshipId: newlySelected?.value || undefined,
            relationship: newlySelected?.label || undefined
        });
    };

    return (
        <SearchableSelectField
            data-type="relationship-dropdown"
            label={label}
            placeholder="Select or enter your own"
            selected={selected}
            options={dataProvider.getRelationshipSelectOptions()}
            onChange={onSelected}
        />
    );
};
