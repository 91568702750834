import { FC, useEffect, useState } from 'react';
import { DrawerPage, IDrawerModalAnimationOrigin } from '../components/DrawerPage';
import { IRelationship } from '../caremap/ICareMap';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { TextField } from '../components/TextField';
import { IonButton } from '@ionic/react';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { MemberAvatar } from './MemberAvatar';
import { CareMapDrawerState } from '../caremap/CareMapDrawerState';
import { RelationshipSelectField } from '../components/RelationshipSelectField';
import { useDeleteHouseholdMember } from './useDeleteHouseholdMember';
import { CareMapFactory } from '../caremap/CareMapFactory';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { ContactMethodsSection } from '../contact-methods/ContactMethodsSection';
import { DeleteMemberButton } from '../support-group/DeleteMemberButton';
import { CustomTypeAddedMetaBuilder } from '../metrics/CustomTypeAddedMetaBuilder';
import { EditHouseholdMemberAvatarDrawer } from '../avatar/EditAvatarDrawer';
import { DefaultAvatarProvider } from '../avatar/DefaultAvatarProvider';
import { MemberFormState } from '../avatar/MemberFormState';

export interface IHouseholdMemberEditProps {
    memberId?: string;
};

export const HouseholdMemberEdit: FC<IHouseholdMemberEditProps> = ({ memberId }) => {
    const tracking = useInstance(MixpanelService);

    const history = useInstance(HistoryViewModel);

    const drawerState = useInstance(CareMapDrawerState);

    const careMapFactory = useInstance(CareMapFactory);

    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);

    const memberForm = useInstance(MemberFormState);
    useSubscription(() => memberForm);

    const defaultAvatarProvider = useInstance(DefaultAvatarProvider);

    const getFirstUnusedAvatar = () => {
        return defaultAvatarProvider.getDefaultAvatar({ careMap: careMapStateInstance });
    };

    useEffect(() => {
        drawerState.setIsMaskingFocusedCareMap(true);

        return () => {
            drawerState.setIsMaskingFocusedCareMap(false);
        };
    }, [drawerState]);

    useEffect(() => {
        if (!memberId){
            memberForm.init({
                id: careMapFactory.getNodeId(),
                firstName: '',
                relationship: undefined,
                contactMethods: [],
                avatar: getFirstUnusedAvatar(),
                notes: []
            });
        } else {
            const initialMember = careMapStateInstance.maybeGetHouseholdMember(memberId);
            if (initialMember){
                memberForm.init(initialMember);
            }
            // otherwise, 404 handling (skipping for now)
        }

        const idToClear = memberForm.state.member?.id;

        return () => {
            // protect against edge case that this form is animating out (and so hasn't cleared yet)
            // while another form is animating in, triggering init
            // but then this form clear's that form's state
            if (idToClear && memberForm.state.member?.id === idToClear){
                memberForm.clear();
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberId, memberForm, careMapStateInstance, careMapFactory]);

    const isAdding = (!memberId);

    const onDelete = useDeleteHouseholdMember({ memberId: memberId || '', onDelete: () => {
        // need to go back twice to skip through read-only page
        // history.back(2);
        // TODO: why isn't back(2) working

        history.push(`/caremaps/${careMapStateInstance.getActiveCaremap()?.careMapId}/drawer/household`);
    }});

    const [showingAvatarPicker, setIsShowingAvatarPicker] = useState(false);
    const [avatarDrawerAnimationOrigin, setAvatarDrawerAnimationOrigin] = useState<IDrawerModalAnimationOrigin>();
    const avatarClicked = (e: React.MouseEvent) => {
        setAvatarDrawerAnimationOrigin({x: e.clientX, y: e.clientY});
        setIsShowingAvatarPicker(true);
    };

    if (!memberForm.state.member){
        // either haven't hit the useEffect above that inits it
        // or the member didn't exist
        return null;
    }

    const member = memberForm.state.member;

    const onCancel = () => {
        history.back();
    };

    const onSave = () => {
        if (isAdding) {
            careMapStateInstance.addHouseholdMember(member);
            tracking.track('Household Member Added', () => ({
                '# of Contact Methods': member.contactMethods.length
            }));
        } else {
            careMapStateInstance.updateHouseholdMember(member);
            tracking.track('Household Member Edited', () => ({
                '# of Contact Methods': member.contactMethods.length
            }));
        }

        history.back();
    };

    const relationshipChangedMetrics = ({ relationshipId, relationship }: { relationshipId?: IRelationship; relationship?: string; }) => {
        if (relationship && !relationshipId){
            tracking.trackWithDebounce({
                event: 'Custom Type Added',
                getContext: () => new CustomTypeAddedMetaBuilder()
                    .origin('caremap')
                    .household()
                    .field({
                        field: 'relationship',
                        fieldLabelKey: 'HOUSEHOLD_MEMBER__RELATIONSHIP',
                        value: relationship
                    })
                    .build()

            });
        }
    };

    const isValid = Boolean(member.firstName?.trim() || member.relationship);

    const relationshipChanged = ({ relationshipId, relationship }: { relationshipId?: IRelationship; relationship?: string; }) => {
        relationshipChangedMetrics({ relationship, relationshipId });

        memberForm.patch({ relationshipId, relationship });
    };

    const buttons = <>
        <IonButton data-type="save-button" disabled={!isValid} onClick={onSave}>Save</IonButton>
        <IonButton data-type="cancel-button" fill="outline" onClick={onCancel}>Cancel</IonButton>
    </>;



    return (
        <>
            <DrawerPage
                title={`${isAdding ? 'Add' : 'Edit'} Household Member`}
                hasBack={true}
                buttons={buttons}
                data-type="member-edit"
                data-member-id={memberId}
            >
                <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>

                    <div style={{display: 'flex', alignItems: 'start', width: '100%', gap: 20}}>
                        <MemberAvatar
                            // use the one from CareMapState if we can, b/c it gets updated by
                            // <EditAvatarDrawer /> and we need to see the latest.
                            // BUT, if the member is deleted, then we need to rever to our "form" member
                            // while this component animates out
                            member={member}
                            onClick={(e: React.MouseEvent) => avatarClicked(e)}
                        />

                        <div style={{display: 'flex', flexDirection: 'column', gap: 20, flexGrow: 1}}>
                            <TextField
                                name="first-name"
                                label="First Name"
                                value={member.firstName}
                                autocapitalize="words"
                                onInput={firstName => memberForm.patch({ firstName })}
                            />
                            <TextField
                                name="last-name"
                                label="Last Name"
                                value={member.lastName}
                                autocapitalize="words"
                                onInput={lastName => memberForm.patch({ lastName })}
                            />
                        </div>
                    </div>

                    <RelationshipSelectField
                        label={careMapStateInstance.getLabel('HOUSEHOLD_MEMBER__RELATIONSHIP')}
                        relationshipId={member.relationshipId}
                        relationship={member.relationship}
                        onChange={relationshipChanged}
                    />

                    <TextField
                        name="address"
                        label="Address"
                        value={member.address}
                        autocapitalize="words"
                        onInput={address => memberForm.patch({ address })}
                    />

                    <ContactMethodsSection contactMethods={member.contactMethods} onMethodsChanged={contactMethods => memberForm.patch({ contactMethods })} />
                </div>

                {!isAdding &&
                    <DeleteMemberButton onDelete={onDelete} memberLabel="Household Member" />
                }
            </DrawerPage>
            <EditHouseholdMemberAvatarDrawer
                isOpen={showingAvatarPicker}
                animationOrigin={avatarDrawerAnimationOrigin}
                member={member}
                onSave={changed => {
                    memberForm.patch(changed);
                    setIsShowingAvatarPicker(false);
                }}
                onCancel={() => setIsShowingAvatarPicker(false)}
            />
        </>

    );
};
