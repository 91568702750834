import { escapeRegExp } from 'lodash';
import { FormatOptionLabelMeta, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { caretDown, caretUp } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { Label } from './Label';
import { ISearchableSelectFieldProps, ISelected } from './SearchableSelectField';

const CustomControl = (props: any) => {
    const labelText = props.selectProps['data-label'];
    const labelPlacement = props.selectProps['data-labelPlacement'];
    const stacked = (labelPlacement === 'stacked' || props.isFocused || props.hasValue || Boolean(props.selectProps.inputValue));
    const color = (
        props.isDisabled ? 'hsl(0, 0%, 60%)' :
            props.isFocused ? 'var(--ion-color-primary)' :
                labelPlacement === 'floating' ?
                    'var(--app-theme-placeholder-color)' : 'var(--ion-text-color)'
    );
    return (
        <>
            <Label text={labelText} stacked={stacked} color={color} disabled={props.isDisabled} />
            <components.Control {...props} />
        </>
    );
};

// NOTE: autocapitalize only works with a virtual keyboard,
//  'Desktop' is slightly misleading as this component is used breakpoints sm+
const CustomInput = (props: any) => {
    return (
        <components.Input {...props} autoCapitalize="words" />
    );
};

const CustomDropdownIndicator = (props: any) => {
    const color = (props.isFocused ? 'var(--ion-color-step-500)' : 'var(--ion-color-step-300)');
    const icon = (props.selectProps.menuIsOpen ? caretUp : caretDown);
    return (
        <components.DropdownIndicator {...props}>
            <IonIcon icon={icon} style={{ color }} />
        </components.DropdownIndicator>
    );
};

const adaptValue = (newValue: any) => {
    // for no selection, component returns null, but we want undefined
    if (!newValue) {
        return undefined;
    }
    // for "enter your own", component returns label in value, but we want undefined
    return { label: newValue.label, value: (newValue.__isNew__ ? undefined : newValue.value) };
};

const formatOptionLabel = <T,>(data: ISelected<T>, labelMeta: FormatOptionLabelMeta<ISelected<T>>) => {
    if (!labelMeta.inputValue) {
        return (<span data-value={data.value}>{data.label}</span>);
    }
    const escapedInput = escapeRegExp(labelMeta.inputValue);
    const formatted = data.label.replace(
        new RegExp(escapedInput, 'gi'),
        highlighted => `<strong>${highlighted}</strong>`
    );
    return (
        <span data-value={data.value} dangerouslySetInnerHTML={{ __html: formatted }} />
    );
};

export const SearchableSelectFieldDesktop = <T,>({
    'data-type': dataType,
    label,
    labelPlacement = 'stacked',
    placeholder,
    noSort = false,
    allowCustom = true,
    disabled = false,
    noOptionsMessage,
    marginBottom,
    selected,
    onChange,
    onBlur,
    options,
    innerRef
}: ISearchableSelectFieldProps<T>) => {
    const sortedOptions = noSort ? options : [...options].sort((o1, o2) => o1.label.localeCompare(o2.label));

    return (
        <div data-type={dataType} className="searchable-select-field" style={{marginBottom}}>
            <CreatableSelect
                isDisabled={disabled}
                classNamePrefix="searchable-select-field"
                ref={innerRef}
                data-label={label}
                data-labelPlacement={labelPlacement}
                placeholder={placeholder}
                value={selected}
                onChange={v => onChange(adaptValue(v))}
                onBlur={onBlur}
                blurInputOnSelect={false}
                options={sortedOptions}
                formatOptionLabel={formatOptionLabel}
                isClearable
                backspaceRemovesValue={false}
                formatCreateLabel={inputValue => `${inputValue} (enter your own)`}
                // if we ever want to actually validate the custom value, this will have to change to a validation function?
                isValidNewOption={() => allowCustom}
                noOptionsMessage={() => noOptionsMessage}
                menuPortalTarget={document.body}
                menuPlacement="auto"
                minMenuHeight={200}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: 'var(--ion-color-primary)',
                        primary75: 'rgba(var(--ion-color-primary-rgb), 0.75)',
                        primary50: 'rgba(var(--ion-color-primary-rgb), 0.70)',
                        primary25: 'rgba(var(--ion-color-primary-rgb), 0.25)',
                        neutral20: 'var(--ion-color-step-300)',
                        neutral30: 'var(--ion-color-step-700)',
                        danger: 'var(--ion-color-danger)',
                        dangerLight: 'var(--ion-color-danger-tint)'
                    },
                })}
                components={{
                    // note: react-select docs advise against defining these inline
                    // (see https://react-select.com/components#defining-components)
                    Control: CustomControl,
                    Input: CustomInput,
                    DropdownIndicator: CustomDropdownIndicator
                }}
                styles={{
                    control: provided => ({
                        ...provided,
                        cursor: 'pointer'
                    }),
                    placeholder: (provided, state) => ({
                        ...provided,
                        visibility: state.isFocused ? 'visible' : 'hidden'
                    }),
                    valueContainer: provided => ({
                        ...provided,
                        height: 54,
                        paddingLeft: 14,
                        cursor: 'text'
                    })
                }}
            />
        </div>
    );
};
