import jwtDecode from 'jwt-decode';
import { SplashScreen } from '@capacitor/splash-screen';
import { IAsyncSmartStorageProvider, AsyncStorageProvider } from '../support/AsyncStorageProvider';
import { AuthenticationClient } from '@innerhive-internal/innerhive-api-client';
import { Logger } from '../support/Logger';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { BufferedEventBus } from '../utils/BufferedEventBus';

const REDIRECT_PATH = '/oauth/code';

export class AuthStorageProvider {
    auth: IAsyncSmartStorageProvider<{accessToken: string, refreshToken: string}>;
    previousPath: IAsyncSmartStorageProvider<string>;
    isTestUser: IAsyncSmartStorageProvider<boolean>;

    static inject = () => [AsyncStorageProvider];
    constructor(private storage: AsyncStorageProvider) {
        this.auth = this.storage.getJSONProvider('ih:auth', { userNeutral: true });
        this.previousPath = this.storage.getStringProvider('previous-path', { userNeutral: true });
        this.isTestUser = this.storage.getBooleanProvider('is-test-user',  { envNeutral: true, userNeutral: true });
    }
}

class LocationProvider {
    get = (): Location => window.location;
}

export interface IAuthParams {
    redirect_uri: string;
    forceInteractiveLogin: boolean;
    authorization?: string;
}

export class ViewerAuthService implements IAuthService {
    on401Callback = async () => {
        throw new Error('Not Supported');
    };

    getToken = async () => {
        throw new Error('Not Supported');
    };

    logout = async () => {
        throw new Error('Not Supported');
    };
}

export interface IAuthService {
    getToken:  () => Promise<{ accessToken: string | undefined }>;
    on401Callback: () => Promise<void>;
    logout: () => Promise<void>;
}

export class AuthService implements IAuthService {
    static inject = () =>  [
        AuthenticationClient,
        AuthStorageProvider,
        LocationProvider,
        HistoryViewModel,
        Logger,
        BufferedEventBus,
    ];
    constructor(
        private authClient: AuthenticationClient,
        private storage: AuthStorageProvider,
        private locationProvider: LocationProvider,
        private history: HistoryViewModel,
        private logger: Logger,
        private events: BufferedEventBus,
    ){}

    private isOnAuthCallbackURL = () => {
        return window.location.hash.startsWith(`#${REDIRECT_PATH}`);
    };

    private getLoginRedirectURI = () => `${window.location.origin}${window.location.search}#${REDIRECT_PATH}`;

    private savePath = (path: string) => {
        this.storage.previousPath.set(path);
    };

    private popSavedPath = async () => {
        let path = await this.storage.previousPath.get();
        if (path){
            await this.storage.previousPath.remove();
        }
        else {
            path = '';
        }
        return path;
    };

    private login = async ({ returnTo }: { returnTo?: string } = {}) => {

        // need to hide splash before redirecting to auth0
        // TODO: revisit this when we re-implement auth flow
        setTimeout(SplashScreen.hide, 750);

        // save the current / returnTo path to be restored in loginFromAuthCallback() below
        const currentLocation = this.history.getCurrentLocation();
        this.savePath(returnTo || currentLocation.pathname + currentLocation.search);

        return await this.authClient.loginWithCodeFlow({
            redirect_uri: this.getLoginRedirectURI(),
            forceInteractiveLogin: true,
            connection: undefined
        });
    };

    private loginFromAuthCallbackURL = async (): Promise<{ accessToken: string }> => {
        const location = this.locationProvider.get();
        const params = new URLSearchParams(location.hash.split('?')[1]);

        this.logger.info('AuthService.loginFromAuthCallbackURL()', { location });

        // if we fail on this login callback, then the user would be stuck there
        // it is far more likely that there are error query params (that won't fix on reload)
        // or that the code is no longer valid (which won't fix on reload)
        // so we should get the user off this has before we finish or throw any errors
        this.removeHash();

        // remove path from storage here, before error handling below
        const previousPath = await this.popSavedPath();

        if (params.has('error_description') || params.has('error')) {
            const errorCode = params.get('error');
            const message = params.get('error_description');
            const err:any = new Error(`Authentication failed: ${errorCode} - ${message}`);
            err.errorCode = errorCode;
            throw err;
        }

        const code = params.get('code') || undefined;

        if (!code) {
            throw Error('Sorry, authentication failed. Please try again.');
        }

        try{
            const { access_token, refresh_token } = await this.authClient.exchangeCode({ code });

            this.guardAgainstViewerToken(access_token);

            this.storage.auth.set({ accessToken: access_token, refreshToken: refresh_token });

            const { userId, email } = jwtDecode(access_token) as any;

            this.events.emit('AuthService.authenticated', { userId, email });

            this.history.replace(previousPath);

            return { accessToken: access_token };

        } catch (err: any){
            // we are going to throw, causing the user to get an error page
            // they will have to reload, and hopefully it will work next time.
            this.logger.error(`AuthService.loginFromAuthCallbackURL() failed calling this.authClient.exchangeCode({ code }). Error: ${err.toString()} `);

            throw err;
        }
    };

    /**
     * To minimize complexity reasoning about user vs accountless-viewer, we don't want them to mix
     * we want to throw early at where they could, to minimize reasoning about it downstream
     */
    private guardAgainstViewerToken = (access_token: string ) => {
        const decoded = jwtDecode(access_token) as any;
        const { userId, tokenType } = decoded;

        if (!userId || tokenType === 'caremap-viewer'){
            throw new Error(`attempted to use an invalid user token ${JSON.stringify(decoded)}`);
        }
    };

    on401Callback = async () => {
        // We get here when the api returns a 401, indicating the token is too old.

        const { refreshToken } = (await this.storage.auth.get())!;
        this.removeUserFromLocalStorage();

        if (!refreshToken){
            this.logger.info('AuthService.on401Callback() - no refresh_token, reloading');

            window.location.reload();
            await new Promise(resolve => {});
        }

        try{
            const { access_token } = await this.authClient.refreshAccessToken({ refresh_token: refreshToken });

            this.storage.auth.set({ accessToken: access_token, refreshToken });

            this.logger.info('AuthService.on401Callback() - used refresh_token to get new access_token, reloading');

            window.location.reload();
            await new Promise(resolve => {});
        }
        catch (err: any){
            // this can fail under normal circumstances, eg their refresh token has been revoked
            this.logger.info(`AuthService.on401Callback() - failed calling this.authClient.refreshAccessToken(), reloading. Error ${err.toString()}`);

            window.location.reload();
            await new Promise(resolve => {});
        }
    };

    logout = async () => {
        this.removeUserFromLocalStorage();

        this.events.emit('AuthService.signing-out');

        // since we always force interactive login in auth0
        // we do NOT need to redirect through auth0 to logout there,
        // but we must update the path when we reload, or we'll
        // still be on the same page when we log back in
        const location = this.locationProvider.get();
        location.href = '/';

        // never resolve, we're redirecting
        await new Promise(() => {});
    };

    getToken = async (): Promise<{ accessToken: string | undefined }> => {
        if (this.isOnAuthCallbackURL()) {
            this.logger.info('AuthService.getToken() => isOnAuthCallbackURL');
            return await this.loginFromAuthCallbackURL();
        }

        else if (await this.storage.auth.exists()) {
            this.logger.info('AuthService.getToken() => getTokenFromStorage');
            return await this.loginFromLocalStorage();
        }

        await this.login();

        // if we get here, the user must have aborted login, so we can't proceed
        const error = new Error('Login aborted') as any;
        error.errorCode = 'login-aborted';
        throw error;
    };

    private async loginFromLocalStorage(): Promise<{ accessToken: string }> {
        const result = await this.storage.auth.get();
        if (!result){
            throw new Error('loginFromLocalStorage() is only meant to be called when we know there is a token in storage');
        }

        this.guardAgainstViewerToken(result.accessToken);

        return { accessToken: result.accessToken };
    }

    private removeUserFromLocalStorage() {
        // remove token for current environment
        this.storage.auth.remove();
    };

    private removeHash = () => {
        window.history.replaceState({}, document.title, '.');
    };
}
