import { IonItem, IonLabel } from '@ionic/react';
import { ITrackingContext, MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { createUseStyles } from 'react-jss';
import { PredefinedColors } from '@ionic/core';

const useStyles = createUseStyles({
    heading: {
        fontWeight: 500
    }
});

export const MenuItem: React.FC<{
    label: React.ReactNode | string,
    Icon?: any,
    color?: PredefinedColors,
    onClick?: () => void,
    detail?: boolean;
    heading?: boolean;
    'data-id'?: string,
    disabled?: boolean,
    children?: React.ReactNode,
    trackingEvent?: string,
    trackingContext?: ITrackingContext
}> = ({ label, Icon, color, onClick, detail = false, heading = false, 'data-id': dataId, disabled = false, children, trackingEvent, trackingContext }) => {

    const classes = useStyles();

    const tracking = useInstance(MixpanelService);

    const onClickHandler = () => {
        if (onClick) {
            onClick();
        }

        if (trackingEvent) {
            tracking.track(trackingEvent, () => trackingContext || {});
        }
    };

    return (
        <IonItem
            data-id={dataId}
            lines="none"
            detail={detail}
            disabled={disabled}
            button={onClick ? true : false}
            onClick={onClick ? () => onClickHandler() : undefined}
        >
            {Icon && (
                <div slot="start" style={{marginRight: 10}}>
                    <Icon color={color || 'medium'} size="medium" />
                </div>
            )}
            <IonLabel color={color} className={heading ? classes.heading : ''}>{label}</IonLabel>
            {children}
        </IonItem>
    );
};
