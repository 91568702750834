import { HistoryViewModel } from '../support/HistoryViewModel';
import { Logger } from '../support/Logger';
import { BufferedEventBus } from '../utils/BufferedEventBus';
import { MixPanelEventEmitter } from './MixPanelEventEmitter';
import { ITrackingContext } from './MixpanelService';

export class MixPanelPageViewEventEmitter {
    static inject = () => [
        MixPanelEventEmitter,
        HistoryViewModel,
        Logger,
        BufferedEventBus
    ];
    constructor(
        private emitter: MixPanelEventEmitter,
        private history: HistoryViewModel,
        private log: Logger,
        private events: BufferedEventBus
    ){}

    private currentPage?: ITrackingContext;

    init = () => {
        // Not all pages correspond to navigation routes, so "Page Viewed" events are not fired
        // automatically by this history listener, but sent explicitly by each page as needed.
        // But since not all pages are tracked, this listener is used to terminate the current
        // page view whenever navigation is detected, e.g. if you are on missions list, then go
        // to the debug page (which is not tracked), it will end the mission list page view.
        this.history.getRawHistory().listen(async () => {
            this.endPageView();
        });

        this.events.on('AuthService.signing-out', () => {
            // TODO:   race condition
            // we want this to happen before MixPanelService calls reset
            // and we want all the to happen, before AuthService reloads the page
            this.endPageView();
        });
    };

    // Must be invoked explicitly to indicate that a page view is starting.
    // (A "page" does not need to correspond one-to-one with a url.)
    startPageView = (pageName: string, getContext?: () => ITrackingContext) => {
        if (!this.history){
            throw new Error('expected init called before this method');
        }
        try {
            this.endPageView(); // send event for previous page

            this.emitter.track(`${pageName}: Viewing`);

            const location = this.history.getCurrentLocation().pathname;
            const queryParams = this.history.getCurrentSearchParamMap();
            const startTimestamp = Date.now();

            const context = getContext ? getContext() : undefined;
            this.currentPage = { ...queryParams, pageName, location, startTimestamp, ...context };

        } catch (e: any) {
            this.log?.error(new Error(`Failed in startPageView for page "${pageName}": ${e.message}`));
        }
    };

    // Sends a track event (including calculated timeOnPage) at the end of a page view.
    // Invoked when startPageView() is called next, and when a navigation is detected.
    // May be invoked explicitly if not covered by above scenarios.
    // Requires that startPageView() has been previously invoked.
    endPageView = () => {
        try {
            if (this.currentPage?.startTimestamp) {
                const { startTimestamp, pageName, ...rest } = this.currentPage;
                const timeOnPage = (Date.now() - startTimestamp) / 1000;
                this.emitter.track(`${pageName} Viewed`, () => ({ ...rest, timeOnPage }));
                this.currentPage.startTimestamp = undefined;
            }
        } catch (e: any) {
            this.log?.error(new Error(`Failed in endPageView for page "${this.currentPage?.pageName}": ${e.message}`));
        }
    };
}
