import { IonButton } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { useEffect } from 'react';
import { CareMapState } from '../caremap/CareMapState';
import { AvatarFormState } from './AvatarFormState';
import { DrawerModal, IDrawerModalAnimationOrigin } from '../components/DrawerPage';
import { IAvatarPhotoColor, PhotoAvatarOrColorPicker } from './PhotoAvatarOrColorPicker';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { IHouseholdMember, IPatient } from '../caremap/ICareMap';

export const EditHouseholdMemberAvatarDrawer: React.FC<{
    member: IHouseholdMember;
    onSave: (changed: IAvatarPhotoColor) => void;
    onCancel: () => void;
    isOpen: boolean;
    animationOrigin?: IDrawerModalAnimationOrigin;
}> = ({ member, onSave, onCancel, isOpen, animationOrigin }) => {
    const tracking = useInstance(MixpanelService);

    let title = `Edit Household Member Avatar`;
    if (member.firstName){
        title = `Edit ${member.firstName}’s Avatar`;
    }

    return (
        <EditAvatarDrawer
            isOpen={isOpen}
            animationOrigin={animationOrigin}
            title={title}
            memberId={member.id}
            avatar={member}
            onSave={changed => {
                onSave(changed);
                tracking.track('Household Member Visual Updated');
            }}
            onCancel={onCancel}
        />
    );
};

export const EditPatientAvatarDrawer: React.FC<{
    patient: IPatient;
    onSave: (changed: IAvatarPhotoColor) => void;
    onCancel: () => void;
    isOpen: boolean;
    animationOrigin?: IDrawerModalAnimationOrigin;
}> = ({ patient, onSave, onCancel, isOpen, animationOrigin }) => {
    const tracking = useInstance(MixpanelService);


    let title = `Edit Patient Avatar`;
    if (patient.firstName){
        title = `Edit ${patient.firstName}’s Avatar`;
    }
    return (
        <EditAvatarDrawer
            isOpen={isOpen}
            animationOrigin={animationOrigin}
            memberId="patient"
            title={title}
            avatar={patient}
            onSave={changed => {
                tracking.track('Care Recipient Visual Updated');
                onSave(changed);
            }}
            onCancel={onCancel}
        />
    );
};

interface IEditAvatarDrawerProps {
    title: string;
    memberId: string;
    avatar: IAvatarPhotoColor;
    onSave: (changed: IAvatarPhotoColor) => void;
    onCancel: () => void;
    isOpen: boolean;
    animationOrigin?: IDrawerModalAnimationOrigin;
};

export const EditAvatarDrawer: React.FC<IEditAvatarDrawerProps> = ({ memberId, avatar, onSave, title, onCancel, isOpen, animationOrigin }) => {
    const careMap = useInstance(CareMapState);
    useSubscription(() => careMap);

    const formState = useInstance(AvatarFormState);
    useSubscription(() => formState);

    useEffect(() => {
        if (isOpen){
            formState.init({ memberId, avatar });
        }

        // intentionally omitting avatar b/c we don't want to remount when that changes (eg on save)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState, memberId, isOpen]);

    if (!formState.state.memberId){
        return null;
    }

    const save = () => {
        if (formState.state.memberId !== memberId){
            throw new Error('attempting to save form state when it is no longer tied to our member');
        }
        onSave(formState.state.avatar);
    };

    const buttons = <>
        <IonButton data-type="save-button" disabled={Boolean(formState.state.avatar.photoDataURI)} onClick={save}>Done</IonButton>
        <IonButton data-type="cancel-button" fill="outline" onClick={onCancel}>Cancel</IonButton>
    </>;

    return (
        <DrawerModal
            title={title}
            hasBack={false}
            hasClose={false}
            buttons={buttons}
            data-type="member-avatar-edit"
            data-member-id={memberId}
            isOpen={isOpen}
            animationOrigin={animationOrigin}
            onClose={() => formState.clear()}
        >
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <PhotoAvatarOrColorPicker
                    avatar={formState.state.avatar}
                    onAvatarChanged={changed => {
                        formState.setAvatar(changed);
                    }}
                />
            </div>
        </DrawerModal>
    );
};
