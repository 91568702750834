import { IonCheckbox, IonLabel } from '@ionic/react';
import { FC, useState } from 'react';

export type ICheckboxProps = {
    label: string;
    checked: boolean,
    'data-id'?: string;
    onChange?: (checked: boolean) => void
};

export const Checkbox: FC<ICheckboxProps> = ({ label, checked, onChange, 'data-id': dataId }) => {
    const [isChecked, setIsChecked] = useState<boolean>(checked);

    const handleChange = (check: boolean) => {
        setIsChecked(check);
        if (onChange) {
            onChange(check);
        }
    };

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <IonCheckbox
                data-id={dataId}
                style={{
                    color: 'var(--ion-color-primary)',
                    // make click target a lil bigger
                    padding: 5,
                    // but keep it aligned left
                    marginLeft: -5
                }}
                checked={isChecked}
                labelPlacement="end"
                onIonChange={e => handleChange(e.detail.checked)}
            />
            <IonLabel
                color="primary"
                style={{marginInlineStart: 4, cursor: 'pointer'}}
                onClick={() => handleChange(!isChecked)}>{label}</IonLabel>
        </div>
    );
};
