import { IonText } from '@ionic/react';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    heading: {
        fontSize: 14,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        display: 'block'
    }
});

export type ISectionHeadingProps = {
    text: string;
};

export const SectionHeading: FC<ISectionHeadingProps> = ({ text }) => {
    const classes = useStyles();
    return (
        <IonText color="medium" className={classes.heading}>
            {text}
        </IonText>
    );
};
