import { useInstance } from '@meraki-internal/react-dependency-injection';
import { OrganizationsState } from './OrganizationsState';
import { useSubscription } from '@meraki-internal/state';
import { ISearchSelectOption, SearchableSelectField } from '../components/SearchableSelectField';
import { IOrganizationUser } from './IOrganization';
import { useEffect } from 'react';

interface ITeamMemberSelectFieldProps {
    orgId: string;
    excludeMemberIds: string[];
    selectedMemberId?: string;
    onSelected: (userId: string) => void;
}

const maybeMemberToOption = (member?: IOrganizationUser): ISearchSelectOption<string> | undefined => {
    if (member){
        return {
            label: `${member.firstName} ${member.lastName}`,
            value: member.userId
        };
    }
    return undefined;
};

const memberToOption = (member: IOrganizationUser): ISearchSelectOption<string> => {
    return maybeMemberToOption(member)!;
};

export const TeamMemberSelectField: React.FC<ITeamMemberSelectFieldProps> = ({ orgId, excludeMemberIds, onSelected, selectedMemberId }) => {
    const orgState = useInstance(OrganizationsState);
    useSubscription(() => orgState);

    const members = orgState.getOrganizationTeamMembers(orgId).filter(m => !excludeMemberIds.includes(m.userId));

    const selectedMember = selectedMemberId ? members.find(m => m.userId === selectedMemberId) : undefined;

    const selected = maybeMemberToOption(selectedMember);

    useEffect(() => {
        if (members.length === 1){
            onSelected(members[0].userId);
        }
        // I don't want to rerun this if we have a new instance of members
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SearchableSelectField
            data-type="team-members-dropdown"
            label="Team Member"
            placeholder="Choose a Team Member to transfer to"
            selected={selected}
            options={members.map(memberToOption)}
            onChange={changed => onSelected(changed?.value || '')}
            allowCustom={false}
        />
    );
};
