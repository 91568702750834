import UAParser from 'ua-parser-js';

const OVERRIDE_STORAGE_KEY = 'ih:device-always-valid';
const OVERRIDE_QUERY_PARAM = '__device-always-valid';

export type IDeviceValidation = {
    validDevice: boolean;
    validBrowser: boolean;
};

type ISupportedBrowser = {
    name: string;
    minVersion: number
};

type ISupportedDevice = {
    type: 'desktop' | 'tablet' | 'mobile';
    vendor?: string;
}

export class DeviceValidator {

    constructor() {
        const searchParams = new URLSearchParams(window.location.search);
        const value = searchParams.get(OVERRIDE_QUERY_PARAM) || '';
        if (['true', 'false'].includes(value)) {
            window.localStorage.setItem(OVERRIDE_STORAGE_KEY, value);
        }
    }

    private parser = new UAParser();
    private deviceAlwaysValid = (window.localStorage.getItem(OVERRIDE_STORAGE_KEY) === 'true');

    private supportedDevices: ISupportedDevice[] = [
        { type: 'desktop' },
        { type: 'mobile' },
        { type: 'tablet', vendor: 'Apple' }
    ];

    private supportedBrowsers: ISupportedBrowser[] = [{
        name: 'Chrome',
        minVersion: 115,
    },{
        name: 'Firefox',
        minVersion: 115,
    },{
        name: 'Safari',
        minVersion: 15,
    },{
        name: 'Mobile Safari', // iPad mini and below. iPad apparently uses 'Safari'
        minVersion: 15,
    },{
        name: 'Edge',
        minVersion: 113,
    }];

    getDeviceType = () => {
        return this.parser.getDevice().type || 'desktop'; // parser returns desktop as undefined
    };

    private isValidDevice = (): boolean => {
        const device = this.parser.getDevice();
        const type = this.getDeviceType();
        const vendor = device.vendor;

        if (this.deviceAlwaysValid) {
            return true;
        }

        if (this.supportedDevices.find(d => d.type === type && d.vendor === vendor)) {
            return true;
        }

        if (this.supportedDevices.find(d => d.type === type && d.vendor === undefined)) {
            return true;
        }
        return false;
    };

    private isSupportedBrowser = (): boolean => {
        const browser = this.parser.getBrowser();
        return this.deviceAlwaysValid || this.supportedBrowsers.some((item: ISupportedBrowser) => {
            return browser.name === item.name && Number(browser.version?.split('.')[0]) >= item.minVersion;
        });
    };

    validate = (): IDeviceValidation => {
        return {
            validDevice: this.isValidDevice(),
            validBrowser: this.isSupportedBrowser()
        };
    };

    isValid = (): boolean => {
        const info = this.validate();
        return info.validDevice && info.validBrowser;
    };

    getResult = (): UAParser.IResult => {
        return this.parser.getResult();
    };
};
