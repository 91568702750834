import { State } from '@meraki-internal/state';
import { IAvatarPhotoColor } from './PhotoAvatarOrColorPicker';
import { IHouseholdMember } from '../caremap/ICareMap';

export interface IAvatarFormState {
    memberId?: string;
    avatar: IAvatarPhotoColor;
}

export class MemberFormState extends State<{ member?: IHouseholdMember}> {
    constructor(){
        super({ });
    }
    init = (member: IHouseholdMember) => {
        this.setState({ member });
    };

    patch = (patch: Partial<IHouseholdMember>) => {
        if (!this.state.member){
            throw new Error('must call init before calling patch');
        }
        this.setState({
            member: {
                ...this.state.member,
                ...patch
            }
        });
    };

    clear = () => {
        this.setState({ member: undefined });
    };
}
