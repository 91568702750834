import { FC, useState } from 'react';
import { Modal } from '../components/Modal';
import { IonCardContent, IonContent, IonSkeletonText, IonToggle, isPlatform, ToggleChangeEventDetail } from '@ionic/react';
import { IonToggleCustomEvent } from '@ionic/core';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { INotificationType, UserSettingsState } from './UserSettingsState';
import { useSubscription } from '@meraki-internal/state';
import { createUseStyles } from 'react-jss';
import { Text } from '../components/Text';
import { NudgeBanner } from '../components/NudgeBanner';
import { PushNotificationService } from '../push-notifications/PushNotificationService';
import { AlertPresenter } from '../AlertBinder';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';

const useStyles = createUseStyles({
    row: {
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    }
});

export type INotificationSettingsProps = {
    isOpen?: boolean;
    closeHandler: () => void;
};

export const NotificationSettingsModal: FC<INotificationSettingsProps> = ({ isOpen = false, closeHandler }) => {
    const classes = useStyles();
    const isDesktop = isPlatform('desktop');

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const userSettingsState = useInstance(UserSettingsState);
    useSubscription(() => userSettingsState);

    const alertPresenter = useInstance(AlertPresenter);
    const pushNotificationService = useInstance(PushNotificationService);

    const loadSettings = async () => {
        setIsLoaded(false);
        await userSettingsState.load();
        setIsLoaded(true);
    };

    const settingChanged = async (attr: INotificationType, value: boolean) => {
        const status = await pushNotificationService.checkPermissions();
        if (!isPlatform('desktop') && value === true && status !== 'granted') {
            if (status === 'prompt') {
                const requestStatus = await pushNotificationService.requestPermission();
                if (requestStatus === 'granted') {
                    userSettingsState.updateNotificationSetting(attr, value);
                    userSettingsState.save();
                }
            } else if (status === 'denied') {
                // don't save and disable the setting since notifications are not enabled
                userSettingsState.updateNotificationSetting(attr, false);

                let subHeader = '';
                let message = '';
                if (isPlatform('ios')) {
                    subHeader = 'Please enable Notifications for Innerhive in the Settings app.';
                    message = 'Click "Open Settings" below, then select Notifications > Innerhive, and enable "Allow Notifications".';
                } else if (isPlatform('android')) {
                    subHeader = 'Please enable Notifications for Innerhive in Settings.';
                    message = 'Click "Open Settings" below, and allow notifications for Innerhive.';
                }
                const action = await alertPresenter.showAlertV2({
                    header: 'Notifications Disabled',
                    subHeader: subHeader,
                    message: message,
                    backdropDismiss: true,
                    options: [{
                        id: 'cancel',
                        label: 'No Thanks',
                        role: 'cancel'
                    }, {
                        id: 'continue',
                        label: 'Open Settings'
                    }]
                });

                if (action === 'continue') {
                    NativeSettings.open({ optionIOS: IOSSettings.App, optionAndroid: AndroidSettings.ApplicationDetails });
                }
            }
        } else {
            userSettingsState.updateNotificationSetting(attr, value);
            userSettingsState.save();
        }

    };

    return (
        <Modal
            isOpen={isOpen}
            desktopMaxWidth={450}
            desktopMaxHeight={392}
            title="Notification Settings"
            data-id="notification-settings-modal"
            closeHandler={closeHandler}
            onDidPresent={loadSettings}
        >
            <IonContent data-id="notification-settings-form">
                <IonCardContent style={{paddingLeft: 22, paddingRight: 22}}>
                    {isDesktop && (
                        <div style={{marginTop: -35}}>
                            <NudgeBanner
                                excludeIcon
                                color="success"
                                message="To receive notifications, please download the Innerhive mobile app!"
                            />
                        </div>
                    )}
                    {isLoaded ? (
                        <>
                            <div className={classes.row}>
                                <div>
                                    <Text type="subtitle">Tips &amp; Inspiration</Text>
                                    <IonToggle
                                        data-id="scheduled-toggle"
                                        checked={userSettingsState.state?.notifications.scheduledTips.enabled}
                                        onIonChange={(e: IonToggleCustomEvent<ToggleChangeEventDetail<any>>) => {
                                            settingChanged('scheduledTips', e.detail.checked);
                                        }}
                                    />
                                </div>
                                <Text>Stay motivated with weekly tips and support for your caregiving journey.</Text>
                            </div>
                            <div className={classes.row} style={{marginTop: 42}}>
                                <div>
                                    <Text type="subtitle">Daily Log Reminders</Text>
                                    <IonToggle
                                        data-id="daily-toggle"
                                        checked={userSettingsState.state?.notifications.dailyLog.enabled}
                                        onIonChange={(e: IonToggleCustomEvent<ToggleChangeEventDetail<any>>) => {
                                            settingChanged('dailyLog', e.detail.checked);
                                        }}
                                    />
                                </div>
                                <Text>Get a gentle reminder each evening to log how your day went. Track trends and discover how daily reflections can enhance your wellness!</Text>
                            </div>
                        </>
                    ) : (
                        <>
                            <div style={{display: 'flex', width: '100%'}}>
                                <IonSkeletonText animated style={{'--border-radius': '999px', width: '90%', height: 20, marginRight: 10}}></IonSkeletonText>
                                <IonSkeletonText animated style={{'--border-radius': '999px', width: '10%', height: 20}}></IonSkeletonText>
                            </div>
                            <div>
                                <IonSkeletonText animated style={{'--border-radius': '999px', width: '100%', height: 20, marginRight: 10}}></IonSkeletonText>
                                <IonSkeletonText animated style={{'--border-radius': '999px', width: '100%', height: 20, marginRight: 10}}></IonSkeletonText>
                            </div>

                            <div style={{display: 'flex', width: '100%', marginTop: 42}}>
                                <IonSkeletonText animated style={{'--border-radius': '999px', width: '90%', height: 20, marginRight: 10}}></IonSkeletonText>
                                <IonSkeletonText animated style={{'--border-radius': '999px', width: '10%', height: 20}}></IonSkeletonText>
                            </div>
                            <div>
                                <IonSkeletonText animated style={{'--border-radius': '999px', width: '100%', height: 20, marginRight: 10}}></IonSkeletonText>
                                <IonSkeletonText animated style={{'--border-radius': '999px', width: '100%', height: 20, marginRight: 10}}></IonSkeletonText>
                                <IonSkeletonText animated style={{'--border-radius': '999px', width: '100%', height: 20, marginRight: 10}}></IonSkeletonText>
                            </div>
                        </>
                    )}
                </IonCardContent>
            </IonContent>
        </Modal>
    );
};
