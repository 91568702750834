import { FC } from 'react';
import { CareMapDrawer } from '../caremap/CareMapDrawer';
import { Redirect, Route } from 'react-router';
import { TrackPageView } from '../metrics/TrackPageView';
import { SupportGroupMemberList } from '../support-group/SupportGroupMemberList';
import { NotesView } from '../notes/NotesView';
import { CaremapNoteEditor } from '../notes/CaremapNoteEditor';
import { ISupportGroupKey } from '../caremap/ICareMap';
import { AppointmentsView } from '../appointments/AppointmentsView';
import { AppointmentEditorDrawer } from '../appointments/ApointmentEditorDrawer';
import { SupportGroupMemberView } from '../support-group/SupportGroupMemberView';
import { SupportGroupMemberEdit } from '../support-group/SupportGroupMemberEdit';
import { HelpCenterArticlesView } from '../support/help-center/HelpCenterArticlesView';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { useDismissOverlays } from '../utils/useDismissOverlays';
import { AppPrincipal, isCaremapViewer } from '../auth/AppPrincipal';

export const SupportGroupRouter: FC = () => {
    const isViewer = isCaremapViewer(useInstance(AppPrincipal));

    const dismissOverlays = useDismissOverlays();

    const careMapState = useInstance(CareMapState);
    useSubscription(() => careMapState);

    return (
        <>
            <CareMapDrawer
                basePath="/caremaps/:careMapId/drawer/support-group/:supportGroupKey"
            >
                <Route exact path="/caremaps/:careMapId/drawer/support-group/:supportGroupKey" render={(routeProps) => {
                    const supportGroupKey = routeProps.match.params.supportGroupKey as ISupportGroupKey;

                    // if we're not supposed to show it, don't allow a deep link to it
                    if (!careMapState.getSupportGroup(supportGroupKey).isRelevant) {
                        return <Redirect to="/caremaps" />;
                    }

                    return (
                        <>
                            <TrackPageView page="Care Member List" meta={{ category: routeProps.match.params.supportGroupKey }} />
                            <SupportGroupMemberList supportGroupKey={supportGroupKey} />
                        </>
                    );
                }} />
                <Route exact path="/caremaps/:careMapId/drawer/support-group/:supportGroupKey/notes" render={(routeProps) => {
                    const supportGroupKey = routeProps.match.params.supportGroupKey as ISupportGroupKey;
                    const titlePrefix = careMapState.getSupportGroupLabels(supportGroupKey).title;

                    return (
                        <NotesView
                            titlePrefix={titlePrefix}
                            noteTarget={{ section: supportGroupKey }} />
                    );
                }} />
                <Route exact path="/caremaps/:careMapId/drawer/support-group/:supportGroupKey/notes/:noteId" render={(routeProps) => {
                    const { supportGroupKey } = routeProps.match.params;
                    const { noteId } = routeProps.match.params;

                    return (
                        <CaremapNoteEditor
                            noteId={noteId === 'add-note' ? '' : noteId}
                            noteTarget={{ section: supportGroupKey as ISupportGroupKey }}
                        />
                    );
                }} />

                <Route exact path="/caremaps/:careMapId/drawer/support-group/:supportGroupKey/appointments" render={(routeProps) => {
                    const supportGroupKey = routeProps.match.params.supportGroupKey as ISupportGroupKey;
                    return <AppointmentsView supportGroupKey={supportGroupKey} />;
                }} />
                <Route exact path="/caremaps/:careMapId/drawer/support-group/:supportGroupKey/appointments/:appointmentId" render={(routeProps) => {
                    dismissOverlays();

                    const { appointmentId } = routeProps.match.params;
                    const supportGroupKey = routeProps.match.params.supportGroupKey as ISupportGroupKey;

                    return (
                        <AppointmentEditorDrawer
                            from={supportGroupKey}
                            supportGroupKey={supportGroupKey}
                            appointmentId={appointmentId}
                        />
                    );
                }} />

                <Route exact path="/caremaps/:careMapId/drawer/support-group/:supportGroupKey/member/:memberId" render={(routeProps) => {
                    const supportGroupKey = routeProps.match.params.supportGroupKey as ISupportGroupKey;
                    const memberId = routeProps.match.params.memberId;
                    return (
                        <>
                            <TrackPageView page="Care Member Details" />
                            <SupportGroupMemberView supportGroupKey={supportGroupKey} memberId={memberId} />
                        </>
                    );
                }} />
                <Route exact path="/caremaps/:careMapId/drawer/support-group/:supportGroupKey/member/:memberId/edit" render={(routeProps) => {
                    if (isViewer) {
                        const careMapId = routeProps.match.params.careMapId;
                        return <Redirect to={`/caremaps/${careMapId}`} />;
                    }
                    const supportGroupKey = routeProps.match.params.supportGroupKey as ISupportGroupKey;
                    const memberId = routeProps.match.params.memberId;
                    return (
                        <SupportGroupMemberEdit supportGroupKey={supportGroupKey} memberId={memberId} />
                    );
                }} />
                <Route exact path="/caremaps/:careMapId/drawer/support-group/:supportGroupKey/add-member" render={(routeProps) => {
                    if (isViewer) {
                        const careMapId = routeProps.match.params.careMapId;
                        return <Redirect to={`/caremaps/${careMapId}`} />;
                    }
                    const supportGroupKey = routeProps.match.params.supportGroupKey as ISupportGroupKey;
                    return (
                        <SupportGroupMemberEdit supportGroupKey={supportGroupKey} />
                    );
                }} />
                <Route exact path="/caremaps/:careMapId/drawer/support-group/:supportGroupKey/member/:memberId/notes" render={(routeProps) => {
                    const supportGroupKey = routeProps.match.params.supportGroupKey as ISupportGroupKey;
                    const titlePrefix = careMapState.getSupportGroupLabels(supportGroupKey).memberTitle;
                    const memberId = routeProps.match.params.memberId;

                    return (
                        <NotesView
                            titlePrefix={titlePrefix}
                            noteTarget={{ section: supportGroupKey, memberId: memberId }}
                        />
                    );
                }} />
                <Route exact path="/caremaps/:careMapId/drawer/support-group/:supportGroupKey/member/:memberId/notes/:noteId" render={(routeProps) => {
                    const supportGroupKey = routeProps.match.params.supportGroupKey as ISupportGroupKey;
                    const { memberId } = routeProps.match.params;
                    const { noteId } = routeProps.match.params;

                    return (
                        <CaremapNoteEditor
                            noteId={noteId === 'add-note' ? '' : noteId}
                            noteTarget={{ section: supportGroupKey, memberId: memberId }}
                        />
                    );
                }} />
                <Route exact path="/caremaps/:careMapId/drawer/support-group/:supportGroupKey/member/:memberId/appointments" render={(routeProps) => {
                    const { memberId } = routeProps.match.params;
                    return <AppointmentsView memberId={memberId} />;
                }} />
                <Route exact path="/caremaps/:careMapId/drawer/support-group/:supportGroupKey/member/:memberId/appointments/:appointmentId" render={(routeProps) => {
                    dismissOverlays();

                    const { appointmentId } = routeProps.match.params;
                    const { memberId } = routeProps.match.params;
                    const supportGroupKey = routeProps.match.params.supportGroupKey as ISupportGroupKey;

                    return (
                        <AppointmentEditorDrawer
                            from={`${supportGroupKey} member`}
                            supportMemberId={memberId}
                            supportGroupKey={supportGroupKey}
                            appointmentId={appointmentId}
                        />
                    );
                }} />

                <Route exact path="/caremaps/:careMapId/drawer/support-group/:supportGroupKey/help-center/articles" render={(routeProps) => {
                    const supportGroupKey = routeProps.match.params.supportGroupKey as ISupportGroupKey;
                    return <HelpCenterArticlesView supportGroupKey={supportGroupKey} />;
                }} />
            </CareMapDrawer>
        </>
    );
};
