import { Node } from 'reactflow';
import { IPatientEdge } from './EdgeConfigurations';
import { nodeOrbitLayout } from '../utils/nodeOrbitLayout';
import { ICareMap, IHouseholdMember, IPatient, ISupportGroupKey, ISupportGroupMember, SUPPORT_GROUP_KEYS } from '../caremap/ICareMap';
import { HoneycombBuilder } from '../honeycomb/HoneycombBuilder';
import { HoneycombConfigProvider } from './HoneycombConfigProvider';
import { SupportGroupKeySorter } from '../support-group/SupportGroupKeySorter';

export type INodeType = 'patient' | 'household' | 'familyNode' | 'honeycombGroup';

// P = parent item, C = child items
export type INodeData<P, C = void> = P & {
    label?: string;
    items?: C[];
    hexagonPositions?: [number, number][];
    indicator?: {
        supportGroupKey?: string;
        identifier: boolean;
        handleStyle: {
            top: number;
            left: number;
        };
        useForOnboarding: boolean;
    }
    selectedItemId?: string;
};

export interface ISupportGroupNodeData {
    hexagonPositions: [number, number][];
    simpleHexagonPositions: [number, number][];
    indicator: {
        supportGroupKey: ISupportGroupKey;
        identifier: boolean;
        useForOnboarding: boolean;
    };

    items: ISupportGroupMember[];
    selectedItemId?: string;
}

export type IMergedNode<P, C = void> = Node<INodeData<P, C>, INodeType>;

export const householdNodeSizes: {[key: string]: number} = {
    'small': 140,
    'large': 280
};

// TODO: hardcoded to allow only 7 family members and the angles for helping positioning around the patient
export const maxHouseholdMembers = 7;

// this is intended only for the ReactFlow caremap, don't try to extract data out of here, USE STATE
export class NodeAdapter {

    static inject = () => [
        HoneycombBuilder,
        HoneycombConfigProvider,
        SupportGroupKeySorter
    ];
    constructor(
        private builder: HoneycombBuilder,
        private honeycombConfigProvider: HoneycombConfigProvider,
        private supportGroupKeySorter: SupportGroupKeySorter
    ) {}

    private shouldShowGroup = (careMap: Partial<ICareMap>, groupKey: ISupportGroupKey) => {
        return Boolean(careMap[groupKey]?.isRelevant);
    };

    // pick best group to use in onboarding tour (the first group in this array that has members)
    private getSupportGroupKeyForOnboarding = (careMap: Partial<ICareMap>) => {
        const orderedKeys = this.supportGroupKeySorter.sort({
            medical: 1,
            specialists: 2,
            community: 3,
            school : 4,
            financial : 5,
            social: 6
        });
        for (const key of orderedKeys) {
            if (careMap[key]?.members?.length) {
                return key;
            }
        }
        return orderedKeys[0];
    };

    adapt = (careMap: Partial<ICareMap>, options: { selectedItemId?: string }  = {}) => {
        const onboardingKey = this.getSupportGroupKeyForOnboarding(careMap);

        const nodes: IMergedNode<any, any>[] = [];

        const edges: IPatientEdge[] = [];

        if (careMap.patient) {
            const patient: IMergedNode<IPatient, void> = {
                id: 'patient',
                type: 'patient',
                parentNode: 'household-group',
                position: { x: 0, y: 0 },
                width: householdNodeSizes['large'],
                data: {
                    ...careMap.patient,
                    label: careMap.patient.firstName,
                    selectedItemId: options.selectedItemId
                }
            };

            nodes.push(patient);

            if (careMap.household) {
                const household: IMergedNode<IHouseholdMember>[] = careMap.household.members.map((member, i) => ({
                    id: `household-${i}`,
                    type: 'familyNode',
                    width: householdNodeSizes['small'],
                    parentNode: 'household-group',
                    position: { x: 0, y: 0 }, // this gets hacked later by nodeOrbitLayout()
                    data: {
                        label: member.firstName,
                        ...member,
                        selectedItemId: options.selectedItemId
                    }
                }));

                nodeOrbitLayout({
                    radius: 200, // TODO: might want to calculate this based on patient node size
                    maxMoons: maxHouseholdMembers,
                    startAngles: [270, 295, 320, 345, 372, 398, 425],
                    planet: patient,
                    moons: household
                });

                nodes.push({
                    id: 'household-group',
                    type: 'household',
                    position: {
                        x: -150,
                        y: 500
                    },
                    data: {},
                });

                nodes.push(...household);
            }
        }

        for (const supportGroupKey of SUPPORT_GROUP_KEYS ){
            if (this.shouldShowGroup(careMap, supportGroupKey)) {
                // if it should show then it must exist  (hence !)
                const group = careMap[supportGroupKey]!;

                const positionConfig = this.honeycombConfigProvider.getSupportGroupPositionByKey(supportGroupKey);
                const { indicatorCenter, hexagonOffsets } = positionConfig;
                const { position, style, hexagonPositions, simpleHexagonPositions } = this.builder.buildNodeConfig({
                    indicatorCenter,
                    hexagonOffsets,
                    memberCount: group.members.length
                });

                const node: Node<ISupportGroupNodeData> = {
                    id: `${supportGroupKey}-group`,
                    position,
                    style,
                    data: {
                        hexagonPositions,
                        simpleHexagonPositions,
                        indicator: {
                            supportGroupKey,
                            identifier: true,
                            useForOnboarding: (onboardingKey === supportGroupKey)
                        },
                        items: group.members,
                        selectedItemId: options.selectedItemId
                    }
                };

                nodes.push({
                    ...node,
                    type: 'honeycombGroup'
                });

                edges.push({
                    id: `patient-${supportGroupKey}-group`,
                    source: 'patient',
                    target: `${supportGroupKey}-group`
                });
            }
        }

        return { nodes, edges };
    };
}
