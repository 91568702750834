import React from 'react';
import dayjs from 'dayjs';
import { IonContent } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { Modal } from '../components/Modal';
import { Card } from '../components/Card';
import { CardHeading } from './CardHeading';
import { FeelingSelector } from './FeelingSelector';
import { CheckinDetailsCard } from './CheckinDetailsCard';
import { INote } from '../notes/INote';
import { CheckinNoteCard } from './CheckinNoteCard';
import { CheckinState } from './CheckinState';
import { ICareEvent, ICheckinTag, IEmotion, IFeeling, ILifeEvent } from './ICheckin';
import { MixPanelEventEmitter } from '../metrics/MixPanelEventEmitter';
import { HistoryViewModel } from '../support/HistoryViewModel';

export const EditCheckinModal: React.FC = () => {
    const history = useInstance(HistoryViewModel);

    const checkinState = useInstance(CheckinState);
    useSubscription(() => checkinState);

    const mixpanel = useInstance(MixPanelEventEmitter);

    const checkin = checkinState.getEditingCheckin();

    const checkinDate = checkin ? checkinState.getCheckinDate(checkin) : undefined;
    const isToday = !checkinDate || dayjs(checkinDate).isToday();
    const title = (isToday ? 'Today, ' : '') + dayjs(checkinDate).format('dddd MMMM D');

    const onChangeFeeling = async (feeling?: IFeeling) => {
        if (feeling !== checkin!.feeling) {
            checkinState.saveCheckin({ ...checkin!, feeling });
        }
    };

    const onChangeEmotions = (tags: ICheckinTag[]) => {
        checkinState.saveCheckin({ ...checkin!, emotions: tags as IEmotion[] });
    };

    const onChangeLifeEvents = (tags: ICheckinTag[]) => {
        checkinState.saveCheckin({ ...checkin!, lifeEvents: tags as ILifeEvent[] });
    };

    const onChangeCareEvents = (tags: ICheckinTag[]) => {
        checkinState.saveCheckin({ ...checkin!, careEvents: tags as ICareEvent[] });
    };

    const onChangeNote = (updatedNote: INote) => {
        checkinState.saveCheckin({ ...checkin!, note: updatedNote.html, attachments: updatedNote.attachments });
    };

    const doneEditing = () => {
        mixpanel.track('Daily Log Completed', () => ({
            'Day': mixpanel.createMixpanelDateWithoutTime(checkinState.getCheckinDate(checkin!)),
            'Emotions': checkin!.emotions?.length || 0,
            'Life Balance': checkin!.lifeEvents?.length || 0,
            'Care Events': checkin!.careEvents?.length  || 0,
            'Note Length': checkin!.note.length,
            'Attachments': checkin!.attachments.length
        }));

        checkinState.stopEditing();

        history.replace('/caregiver');
    };

    return (
        <Modal
            centerTitle
            title={title}
            desktopMinHeight={590}
            isOpen={checkinState.isEditing()}
            backHandler={doneEditing}
            closeHandler={doneEditing}
            hideCloseButton
            data-id="edit-checkin-modal"
        >
            <IonContent>

                <div style={{display: 'flex', flexDirection: 'column', gap: 10, margin: '0 10px 20px'}}>

                    <Card padded>
                        <CardHeading>How was your day?</CardHeading>
                        <FeelingSelector value={checkin?.feeling} onChange={onChangeFeeling} />
                    </Card>

                    <CheckinDetailsCard
                        type="EMOTIONS"
                        tags={checkin?.emotions || []}
                        onChange={onChangeEmotions}
                    />

                    <CheckinDetailsCard
                        type="LIFE_EVENTS"
                        tags={checkin?.lifeEvents || []}
                        onChange={onChangeLifeEvents}
                    />

                    <CheckinDetailsCard
                        type="CARE_EVENTS"
                        tags={checkin?.careEvents || []}
                        onChange={onChangeCareEvents}
                    />

                    <CheckinNoteCard
                        checkin={checkin!}
                        heading={isToday ? `Today's Note` : 'Note'}
                        onChange={onChangeNote}
                    />

                </div>

            </IonContent>
        </Modal>
    );
};
