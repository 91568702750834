import { RefObject } from 'react';
import { isPlatform } from '@ionic/react';

const scrollIntoView = (elRef: RefObject<Element>) => {
    setTimeout(() => {
        if (elRef.current) {
            elRef.current.scrollIntoView({
                block: 'end',
                behavior: 'smooth'
            });
        }
        // in native, there's other things that make it so we need to delay the scroll further,
        // such as the webview resizing and scrolling to the activeElement
    }, isPlatform('desktop') ? 10 : isPlatform('capacitor') ? 1000 : 350);
};

export const useScrollIntoView = () => {
    return scrollIntoView;
};

export const useScrollIntoViewIfNeeded = () => {
    const scrollIfNeeded = (elRef: RefObject<Element>, containerRef: RefObject<Element>) => {
        if (elRef && elRef.current && containerRef && containerRef.current) {
            const elRect = elRef.current.getBoundingClientRect();
            const listRect = containerRef.current.getBoundingClientRect();

            // note that if element is styled using flexbox, elRect may report bottom equal to top (i.e. no height),
            // causing this hook not to scroll far enough; this can be fixed by giving element an explicit height
            if (elRect.bottom > listRect.bottom) {
                scrollIntoView(elRef);
            }
        }
    };

    return scrollIfNeeded;
};
