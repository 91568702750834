import { ICheckinDetailsToggleProps } from './CheckinDetailsToggle';
import {
    DuoAlarmClockIcon,
    DuoArrowsToCircleIcon,
    DuoBatteryQuarterIcon,
    DuoBedIcon,
    DuoBenchTreeIcon,
    DuoBottleWaterIcon,
    DuoCalendarDayIcon,
    DuoCardsIcon,
    DuoCartShoppingIcon,
    DuoChairIcon,
    DuoCircleMinusIcon,
    DuoCitrusIcon,
    DuoClipboardListCheckIcon,
    DuoCloudBoltIcon,
    DuoCrowIcon,
    DuoDaffodilIcon,
    DuoDiamondExclamationIcon,
    DuoDumbbellIcon,
    DuoEggFriedIcon,
    DuoGavelIcon,
    DuoHandHoldingMedicalIcon,
    DuoHandshakeIcon,
    DuoHospitalIcon,
    DuoHotTubIcon,
    DuoHousePersonReturnIcon,
    DuoKitchenSetIcon,
    DuoLeafIcon,
    DuoMedalIcon,
    DuoPeopleIcon,
    DuoPeopleLineIcon,
    DuoPersonWalkingIcon,
    DuoPillsIcon,
    DuoPlateUtensilsIcon,
    DuoRaindropsIcon,
    DuoSaladIcon,
    DuoShootingStarIcon,
    DuoShowerIcon,
    DuoSnoozeIcon,
    DuoSunIcon,
    DuoTrophyStarIcon,
    DuoVacuumIcon,
    DuoVanShuttleIcon,
    DuoVolvanoIcon,
    DuoWaveIcon
} from '../theme/Icons';

export type ICheckinDetailsType = 'EMOTIONS' | 'LIFE_EVENTS' | 'CARE_EVENTS';

export const CheckinDetailsConfig: {[key in ICheckinDetailsType]: {title: string, icons: ICheckinDetailsToggleProps[]}} = {
    EMOTIONS: {
        title: 'Emotions',
        icons: [{
            label: 'happy',
            Icon: DuoSunIcon,
            activeColor: 'theme-yellow'
        },{
            label: 'excited',
            Icon: DuoShootingStarIcon,
            activeColor: 'theme-purple'
        },{
            label: 'proud',
            Icon: DuoTrophyStarIcon,
            activeColor: 'theme-dark-green'
        },{
            label: 'appreciated',
            Icon: DuoHandshakeIcon,
            activeColor: 'theme-pink'
        },{
            label: 'grateful',
            Icon: DuoDaffodilIcon,
            activeColor: 'theme-bright-green'
        },{
            label: 'sad',
            Icon: DuoRaindropsIcon,
            activeColor: 'theme-teal'
        },{
            label: 'angry',
            Icon: DuoVolvanoIcon,
            activeColor: 'theme-red'
        },{
            label: 'lonely',
            Icon: DuoChairIcon,
            activeColor: 'theme-red-orange'
        },{
            label: 'overwhelmed',
            Icon: DuoWaveIcon,
            activeColor: 'theme-teal'
        },{
            label: 'stressed',
            Icon: DuoArrowsToCircleIcon,
            activeColor: 'theme-red-orange'
        },{
            label: 'afraid',
            Icon: DuoCrowIcon,
            activeColor: 'theme-indigo'
        },{
            label: 'calm',
            Icon: DuoLeafIcon,
            activeColor: 'theme-dark-green'
        },{
            label: 'exhausted',
            Icon: DuoBatteryQuarterIcon,
            activeColor: 'theme-red'
        },{
            label: 'frustrated',
            Icon: DuoCloudBoltIcon,
            activeColor: 'theme-pink'
        },{
            label: 'guilty',
            Icon: DuoGavelIcon,
            activeColor: 'theme-purple'
        },{
            label: 'indifferent',
            Icon: DuoCircleMinusIcon,
            activeColor: 'theme-yellow'
        }]
    },

    LIFE_EVENTS: {
        title: 'Life Balance',
        icons: [{
            label: 'good sleep',
            Icon: DuoBedIcon,
            activeColor: 'theme-bright-green'
        },{
            label: 'bad sleep',
            Icon: DuoAlarmClockIcon,
            activeColor: 'theme-red'
        },{
            label: 'ok sleep',
            Icon: DuoSnoozeIcon,
            activeColor: 'theme-yellow'
        },{
            label: 'exercise',
            Icon: DuoDumbbellIcon,
            activeColor: 'theme-purple'
        },{
            label: 'outside time',
            Icon: DuoBenchTreeIcon,
            activeColor: 'theme-dark-green'
        },{
            label: 'social time',
            Icon: DuoPeopleIcon,
            activeColor: 'theme-pink'
        },{
            label: 'personal time',
            Icon: DuoHotTubIcon,
            activeColor: 'theme-indigo'
        },{
            label: 'drink water',
            Icon: DuoBottleWaterIcon,
            activeColor: 'theme-teal'
        },{
            label: 'breakfast',
            Icon: DuoEggFriedIcon,
            activeColor: 'theme-yellow'
        },{
            label: 'lunch',
            Icon: DuoSaladIcon,
            activeColor: 'theme-bright-green'
        },{
            label: 'dinner',
            Icon: DuoPlateUtensilsIcon,
            activeColor: 'theme-dark-green'
        },{
            label: 'snacks',
            Icon: DuoCitrusIcon,
            activeColor: 'theme-red-orange'
        }]
    },

    CARE_EVENTS: {
        title: 'Care Events',
        icons: [{
            label: 'personal care',
            Icon: DuoShowerIcon,
            activeColor: 'theme-pink'
        },{
            label: 'meal prep',
            Icon: DuoKitchenSetIcon,
            activeColor: 'theme-bright-green'
        },{
            label: 'appointment',
            Icon: DuoCalendarDayIcon,
            activeColor: 'theme-indigo'
        },{
            label: 'meeting',
            Icon: DuoPeopleLineIcon,
            activeColor: 'theme-yellow'
        },{
            label: 'achievement',
            Icon: DuoMedalIcon,
            activeColor: 'theme-purple'
        },{
            label: 'medication',
            Icon: DuoPillsIcon,
            activeColor: 'theme-teal'
        },{
            label: 'exercise',
            Icon: DuoPersonWalkingIcon,
            activeColor: 'theme-dark-green'
        },{
            label: 'social activity',
            Icon: DuoCardsIcon,
            activeColor: 'theme-pink'
        },{
            label: 'cleaning',
            Icon: DuoVacuumIcon,
            activeColor: 'theme-teal'
        },{
            label: 'groceries',
            Icon: DuoCartShoppingIcon,
            activeColor: 'theme-indigo'
        },{
            label: 'transport',
            Icon: DuoVanShuttleIcon,
            activeColor: 'theme-yellow'
        },{
            label: 'incident',
            Icon: DuoDiamondExclamationIcon,
            activeColor: 'theme-red-orange'
        },{
            label: 'home visit',
            Icon: DuoHousePersonReturnIcon,
            activeColor: 'theme-pink'
        },{
            label: 'respite care',
            Icon: DuoHandHoldingMedicalIcon,
            activeColor: 'theme-dark-green'
        },{
            label: 'new routine',
            Icon: DuoClipboardListCheckIcon,
            activeColor: 'theme-purple'
        },{
            label: 'emergency',
            Icon: DuoHospitalIcon,
            activeColor: 'theme-red'
        }]
    }
};
