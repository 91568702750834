import { FC, useEffect } from 'react';
import { CareMapFlow } from './CareMapFlow';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from './CareMapState';
import { isPlatform } from '@ionic/react';
import { CareMapDrawerState } from './CareMapDrawerState';
import { useSubscription } from '@meraki-internal/state';
import { NavigationMenuState } from '../navigation/NavigationMenuState';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { AppPrincipal, isCaremapViewer } from '../auth/AppPrincipal';
import { InnerhivePage } from '../InnerhivePage';

export const CareMapPage: FC = () => {
    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);
    const drawerState = useInstance(CareMapDrawerState);
    useSubscription(() => drawerState);
    const navMenuState = useInstance(NavigationMenuState);
    useSubscription(() => navMenuState);
    const history = useInstance(HistoryViewModel);
    const isViewer = isCaremapViewer(useInstance(AppPrincipal));

    const isMenuOpen = drawerState.isOpen() || navMenuState.isOpen();


    useEffect(() => {
        const activeCaremap = careMapStateInstance.getActiveCaremap();
        const isQuestionnaire = activeCaremap && !activeCaremap.essentialMapComplete;

        if (isQuestionnaire && !isViewer){
            history.replace(`/questionnaire/${activeCaremap.careMapId}`);
        }
    }, [history, isViewer, careMapStateInstance]);

    return (
        <InnerhivePage skipAddingIonContent skipAddingNavigation>
            <CareMapFlow
                name="full"
                blur={isMenuOpen}
                fit={isMenuOpen ? 'none' : 'custom'}
                includeControls={isPlatform('desktop')}
                careMap={careMapStateInstance.getActiveCaremap()!}
            />
        </InnerhivePage>
    );
};
