import { IonTextarea } from '@ionic/react';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { ITextInputProps } from './ITextInputProps';

const useStyles = createUseStyles({
    textarea: {
        '--padding-bottom': '16px !important',

        '& textarea': {
            paddingBottom: '0px !important',
            marginTop: '18px !important',
            marginBottom: '6px !important'
        }
    },

    moreObviousFont: {
        fontSize: 'large',
        fontWeight: 500,

        '& .label-text-wrapper': {
            fontSize: 'initial',
            fontWeight: 'normal'
        }
    },

    readOnly: {
        color: 'initial',
        opacity: '1 !important',
        '--border-color': 'transparent !important',

        // native devices need more
        '& textarea': {
            color: 'initial',
            opacity: '1 !important'
        }
    }
});

type ITextAreaProps = ITextInputProps & {
    rows?: number;
    autoGrow?: boolean;
    moreObviousFont?: boolean;
};

export const TextArea: FC<ITextAreaProps> = ({
    label,
    labelPlacement='stacked',
    fill='outline',
    autoGrow,
    rows,
    name,
    value,
    readOnly,
    moreObviousFont,
    placeholder,
    'data-id': dataId,
    onInput = () => {}
}) => {
    const classes = useStyles();
    return (
        <IonTextarea
            label={label}
            labelPlacement={labelPlacement}
            fill={fill}
            autoGrow={autoGrow}
            rows={rows}
            name={name}
            value={value}
            placeholder={placeholder}
            data-id={dataId}
            disabled={readOnly}
            onIonInput={(e) => onInput(e.detail.value || '')}
            className={`${classes.textarea} ${readOnly ? classes.readOnly : ''} ${moreObviousFont ? classes.moreObviousFont : ''}`}
        />
    );
};
