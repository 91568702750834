import { memo } from 'react';
import { NodeProps } from 'reactflow';
import { formatAge } from '../utils/format';
import { IPatient } from '../caremap/ICareMap';
import { INodeData } from './NodeAdapter';
import { MemberNodeWrapper } from './MemberNodeWrapper';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapDrawerRouter } from '../caremap/CareMapDrawerRouter';
import { useSubscription } from '@meraki-internal/state';
import { CareMapState } from '../caremap/CareMapState';
import { CareMapDrawerState } from '../caremap/CareMapDrawerState';
import { PatientHandle } from './PatientHandle';

export const PatientNode = memo(({ data }: NodeProps<INodeData<IPatient>>) => {
    const drawerState = useInstance(CareMapDrawerState);
    const drawerRouter = useInstance(CareMapDrawerRouter);

    const careMapState = useInstance(CareMapState);
    const inWizard = !careMapState.getActiveCaremap()?.essentialMapComplete;
    useSubscription(() => careMapState);

    // can't click items in the wizard
    const onClick = inWizard ? undefined : () => {
        // in regular caremap, clicking a member goes to the list view;
        // in focused caremap, clicking a member goes to the member view
        const isFocused = drawerState.isOpen();
        const memberId = (isFocused ? 'patient' : undefined);

        drawerRouter.navigateToSelectedMemberId({ basePath: `/caremaps/${careMapState.getActiveCaremap()?.careMapId}/drawer/household`, memberId });
    };

    return (
        <>
            <PatientHandle />

            <MemberNodeWrapper
                onClick={onClick}
                size="large"
                label={data.label}
                subtext={data.dob ? formatAge(data.dob) : ''}
                photo={data.photoDataURI || data.photo}
                color={data.color}
                avatar={data.avatar}
                memberId="patient"
                selectedItemId={data.selectedItemId}
            />
        </>
    );
});
