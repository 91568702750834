import { ReactNode } from 'react';
import { PredefinedColors } from '@ionic/core';
import { IonButton, IonText } from '@ionic/react';
import { InnerhivePlusIcon } from '../theme/Icons';
import { RevenueCatModel } from '../innerhive-plus/revenue-cat/RevenueCatModel';
import { IFreeTierLimits } from '../innerhive-plus/revenue-cat/IFreeTierLimits';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { ITriggerPaywallSource, InnerhivePlusDialogViewModel } from '../innerhive-plus/InnerhivePlusDialogViewModel';
import { useSubscription } from '@meraki-internal/state';

interface IPaywallButtonProps {
    id?: string;
    'data-type'?: string;
    expand?: 'full' | 'block';
    fill?: 'clear' | 'outline' | 'solid' | 'default';
    color?: PredefinedColors;
    disabled?: boolean;
    className?: string;
    onClick: () => void;
    requiresInnerhivePlus?: (freeTierLimits: IFreeTierLimits) => boolean;
    innerhivePlusMessage?: string | ReactNode;
    triggerSource: ITriggerPaywallSource;
    children?: ReactNode;
}

export const PaywallAwareButton: React.FC<IPaywallButtonProps> = ({ onClick, requiresInnerhivePlus, innerhivePlusMessage, triggerSource, children, ...rest }) => {
    const dialogVM = useInstance(InnerhivePlusDialogViewModel);

    const revenueCat = useInstance(RevenueCatModel);
    useSubscription(() => revenueCat);

    const shouldPromptForInnerhivePlus = requiresInnerhivePlus && !revenueCat.hasInnerhivePlus() && requiresInnerhivePlus(revenueCat.getFreeTierLimits());

    // if user can't buy innerhive+ (e.g. a viewer) don't show the button at all
    if (shouldPromptForInnerhivePlus && !revenueCat.canHaveInnerhivePlus()) {
        return null;
    }

    const handleClick = async () => {
        if (!shouldPromptForInnerhivePlus) {
            onClick();
        } else {
            const nowHasInnerhivePlus = await dialogVM.requirePurchase({ triggerSource });
            if (nowHasInnerhivePlus) {
                onClick();
            } else {
                // do nothing, user canceled paywall
            }
        }
    };

    return (
        <div style={{position: 'relative'}}>
            <IonButton
                {...rest}
                onClick={handleClick}
                data-type="paywall-button"
            >
                {children}
                {shouldPromptForInnerhivePlus &&
                    <span data-type="paywall-icon" style={{paddingLeft: 8}}>
                        <InnerhivePlusIcon color="secondary" />
                    </span>
                }
            </IonButton>
            {shouldPromptForInnerhivePlus && innerhivePlusMessage &&
                <div style={{fontSize: 14, textAlign: 'center', position: 'absolute', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                    <IonText color="medium">{innerhivePlusMessage}</IonText>
                </div>
            }
        </div>
    );
};
