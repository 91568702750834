import { ReactNode } from 'react';
import { IonCard } from '@ionic/react';

export interface ICardProps {
    padded?: boolean;
    onClick?: () => void;
    'data-type'?: string;
    children: ReactNode
}

export const Card: React.FC<ICardProps> = ({ padded, onClick, 'data-type': dataType, children }) => {
    const style = {
        // by default, text inside a <Card> is lighter gray, unsetting this reverts it to default text color
        '--color': 'unset',

        // if clickable, show pointer
        cursor: onClick ? 'pointer' : undefined
    };

    return (
        <IonCard data-type={dataType} style={style} className={padded ? 'ion-padding': undefined} onClick={onClick}>
            {children}
        </IonCard>
    );
};
