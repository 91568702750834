import { IonButton, IonCardContent, IonContent, isPlatform } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useEffect, useRef, useState } from 'react';
import { useSubscription } from '@meraki-internal/state';
import { CareMapState } from '../caremap/CareMapState';
import { ViewShareLinkButton } from './ViewShareLinkButton';
import { DownloadIcon, ShareIcon } from '../theme/Icons';
import { MenuItem } from '../components/MenuItem';
import { HeaderPopoverTitle } from '../components/HeaderPopover';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { Divider } from '../components/Divider';
import { createUseStyles } from 'react-jss';
import { useBreakpoints } from '../theme/useBreakpoints';
import { HeaderButton } from '../components/HeaderButton';
import { CareMapCollaboratorManager } from './CareMapCollaboratorManager';
import { mediaMaxWidth } from '../theme/utils';
import { InvitationState } from './InvitationState';
import { Modal } from '../components/Modal';
import { useToast } from '../components/useToast';

const useStyles = createUseStyles({
    shareButton: {
        padding: 3,
        height: 40,
        width: 125,
        fontSize: 14,
        '--box-shadow': 'none',

        '&::part(native)': {
            'color': 'var(--ion-color-primary)'
        }
    },

    shareGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        '& ion-button': {
            minWidth: 100
        }
    },

    shareLinkButtonWrapper: {
        paddingLeft: 40,
        paddingRight: 40
    },

    [mediaMaxWidth.md]: {
        shareLinkButtonWrapper: {
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 10
        }
    }
});

const isDownloadSupported = isPlatform('desktop') && (document.implementation as any).hasFeature('http://w3.org/TR/SVG11/feature#Extensibility', '1.1');

export const ShareMenuAndButton: React.FC = () => {
    const classes = useStyles();
    const {isBelowBreakpoint} = useBreakpoints();

    const tracking = useInstance(MixpanelService);

    const [present] = useToast();

    const contentRef = useRef<HTMLIonContentElement>(null);

    const [downloading, setDownloading] = useState(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const careMapStateInstance = useInstance(CareMapState);
    useSubscription(() => careMapStateInstance);

    const invitationState = useInstance(InvitationState);


    // when the user switches care maps, we want to show spinner and load the shares for the switched to care map
    // otherwise, we want to just load the data "in the background" to update the shares for the current map, without a spinner
    const hasActiveCareMap = careMapStateInstance.hasActiveCareMap();
    const currMapId = useRef(careMapStateInstance.getActiveCaremap()?.careMapId);

    const activeCaremapId = careMapStateInstance.getActiveCaremap()?.careMapId;
    useEffect(() => {
        if (hasActiveCareMap) {
            invitationState.needsReset();
            currMapId.current = activeCaremapId;
        }
    }, [careMapStateInstance, activeCaremapId, currMapId, invitationState, hasActiveCareMap]);

    if (!hasActiveCareMap) {
        return null;
    }

    const canShare = careMapStateInstance.hasPermissionTo('share-with-viewer', 'share-with-co-owner');
    if (!canShare){
        return null;
    }

    if (!careMapStateInstance.getActiveCaremap()?.essentialMapComplete) {
        return null;
    }

    const download = async () => {
        setDownloading(true);
        try {
            await careMapStateInstance.download();
            tracking.track('Care Map Downloaded');
        } catch (e) {
            present({ duration: 3000, message: 'Sorry, we were unable to download your Care Map, please try again.' });
        } finally {
            setDownloading(false);
        }
    };

    const shareTitle = careMapStateInstance.getLabel('SHARE_CARE_MAP');

    return (
        <>
            {/* TODO: next time we add another button, make this part of HeaderButton... 8 */}
            {isBelowBreakpoint('sm') ? (
                <HeaderButton id="share-menu-button" disabled={downloading} onClick={() => setIsOpen(true)}>
                    <div style={{marginTop: 2}}>
                        <ShareIcon color="primary" />
                    </div>
                </HeaderButton>
            ) : (
                <IonButton
                    size="small"
                    color="light"
                    shape="round"
                    id="share-menu-button"
                    disabled={downloading}
                    className={classes.shareButton}
                    onClick={() => setIsOpen(true)}
                >
                    <ShareIcon color="primary" slot="start" />&nbsp;&nbsp;Share
                </IonButton>
            )}

            <Modal
                isOpen={isOpen}
                title={shareTitle}
                data-id="share-modal"
                closeHandler={() => setIsOpen(false)}
                onDidPresent={() => tracking.track('Share Menu Opened')}
            >
                <IonContent ref={contentRef}>

                    <IonCardContent style={{paddingBottom: 0, paddingTop: 0}}>
                        <CareMapCollaboratorManager
                            scrollRef={contentRef}
                        />
                    </IonCardContent>

                    <IonCardContent style={{paddingBottom: 5}}>
                        <HeaderPopoverTitle title="Invite Viewers" />
                        <div className={classes.shareLinkButtonWrapper}>
                            <ViewShareLinkButton />
                        </div>
                    </IonCardContent>

                    {isDownloadSupported &&
                        <>
                            <Divider />
                            <div style={{padding: '0px 20px'}}>
                                <MenuItem
                                    disabled={downloading}
                                    onClick={download}
                                    label="Download Care Map"
                                    Icon={DownloadIcon}
                                />
                            </div>
                        </>
                    }

                </IonContent>
            </Modal>
        </>
    );
};
