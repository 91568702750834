import { HistoryViewModel } from '../support/HistoryViewModel';
import { MixPanelPageViewEventEmitter as MixpanelService } from './MixPanelPageViewEventEmitter';

export class PageViewTracking {
    static inject = () => [HistoryViewModel, MixpanelService];
    constructor(private history: HistoryViewModel, private tracking: MixpanelService){}

    private pathsToTrack: { path: string; handler: () => void; }[] = [
        {path: '/caremap', handler: () => this.tracking.startPageView('Care Map') }
    ];

    start = () => {
        this.onPath(this.history.getCurrentLocation().pathname);

        this.history.listen((props) => {
            this.onPath(props.pathname);
        });
    };
    onPath = (path: string) => {
        const match = this.pathsToTrack.find(p => p.path === path);
        if (match){
            match.handler();
        }
    };
};
