import { FC, useState } from 'react';
import { CareMapDrawerState } from '../caremap/CareMapDrawerState';
import { useSubscription } from '@meraki-internal/state';
import { DrawerPage } from '../components/DrawerPage';
import { IonButton } from '@ionic/react';
import { AppointmentEditor } from './AppointmentEditor';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { AppointmentsState, IAppointmentTrackingFrom, NEW_APPOINTMENT_ID } from './AppointmentsState';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { Menu } from '../components/Menu';
import { DeleteIcon } from '../theme/Icons';
import { useToast } from '../components/useToast';
import { MixPanelEventEmitter } from '../metrics/MixPanelEventEmitter';
import { ISupportGroupKey } from '../caremap/ICareMap';

export type IAppointmentEditorDrawerProps = {
    appointmentId: string;
    supportMemberId?: string;
    supportGroupKey?: ISupportGroupKey;
    from: IAppointmentTrackingFrom;
};

export const AppointmentEditorDrawer: FC<IAppointmentEditorDrawerProps> = ({ appointmentId, supportMemberId, supportGroupKey, from }) => {
    const history = useInstance(HistoryViewModel);
    const mixpanel = useInstance(MixPanelEventEmitter);
    const drawerState = useInstance(CareMapDrawerState);
    useSubscription(() => drawerState);

    const [saving, setSaving] = useState(false);

    const [showToast] = useToast();

    const appointmentsState = useInstance(AppointmentsState);
    useSubscription(() => appointmentsState);

    const onDidEnter = () => {
        drawerState.setIsMaskingFocusedCareMap(true);

        if (appointmentId !== NEW_APPOINTMENT_ID) {
            mixpanel.track('Calendar Event Opened', () => ({
                'Opened From': from,
                'Association': appointmentsState.getTrackingMeta().association
            }));
        }
    };

    const onWillLeave = () => {
        drawerState.setIsMaskingFocusedCareMap(false);
    };

    const [isValid, setIsValid] = useState<boolean>(false);

    if (!appointmentsState.state.loaded) {
        return null;
    }

    const canAddOrEdit = () => {
        return appointmentsState.canAdd() || appointmentsState.canEdit();
    };

    const canDelete = () => {
        return appointmentsState.canDelete();
    };

    const deleteAppointment = async () => {
        try {
            setSaving(true);
            await appointmentsState.deleteCurrentAppointment(from);
            history.back();
        } catch (err: any) {
            setSaving(false);
            showToast({ message: err.displayMessage || 'Sorry something went wrong', duration: 3000 });
        }
    };

    const save = async () => {
        try {
            setSaving(true);
            await appointmentsState.saveCurrentAppointment(from);
            history.back();
        } catch (err: any) {
            setSaving(false);
            showToast({ message: err.displayMessage || 'Sorry something went wrong', duration: 3000 });
        }
    };

    const cancel = () => {
        appointmentsState.clearCurrentAppointment();
        history.back();
    };

    const disableRole = (supportGroupKey !== undefined && appointmentId !== NEW_APPOINTMENT_ID) || supportMemberId !== undefined;

    return (
        <DrawerPage
            title=""
            hasBack
            enteredHandler={onDidEnter}
            leavingHandler={onWillLeave}
            data-type="appointment-editor"
            buttons={
                canAddOrEdit() && <>
                    <IonButton data-type="save-button" disabled={!isValid || saving} onClick={save}>Save</IonButton>
                    <IonButton data-type="cancel-button" fill="outline" onClick={cancel}>Cancel</IonButton>
                </>
            }
        >
            {canDelete() && appointmentId !== NEW_APPOINTMENT_ID && (
                <div style={{marginTop: -8, display: 'flex', justifyContent: 'end'}}>
                    <Menu actions={[{
                        Icon: DeleteIcon,
                        role: 'destructive',
                        text: `Delete Appointment`,
                        handler: deleteAppointment,
                        'data-type': 'delete-button',
                        disabled: saving
                    }]} />
                </div>
            )}

            <AppointmentEditor
                readOnly={!canAddOrEdit()}
                disableRole={disableRole}
                appointmentId={appointmentId}
                supportMemberId={supportMemberId}
                supportGroupKey={supportGroupKey}
                reportValidity={(valid) => setIsValid(valid)}
            />
        </DrawerPage>
    );
};
