import { IonBadge } from '@ionic/react';
import { PredefinedColors } from '@ionic/core';
import React, { ReactNode } from 'react';

interface BadgeProps {
    color?: PredefinedColors;
    size?: 'xsmall' | 'small' | 'medium' | 'large';
    saturated?: boolean;
    expand?: 'block';
    children: ReactNode;
    'data-type'?: string;
}

export const Badge: React.FC<BadgeProps> = ({ color, size = 'medium', expand, saturated, children, 'data-type': dataType }) => {

    const badgeStyles: React.CSSProperties = {
        display: 'block',
        background: saturated ? `var(--ion-color-${color})` : `rgba(var(--ion-color-${color}-rgb), 0.1)`,
        color: saturated ? `var(--ion-color-${color}-contrast)` : `var(--ion-color-${color})`,
        paddingTop: {xsmall: 2, small: 4, medium: 12, large: 18}[size],
        paddingBottom: {xsmall: 2, small: 4, medium: 12, large: 18}[size],
        paddingLeft: {xsmall: 6, small: 8, medium: 24, large: 36}[size],
        paddingRight: {xsmall: 6, small: 8, medium: 24, large: 36}[size],
        fontSize: {xsmall: 12, small: 14, medium: 18, large: 24}[size],
        width: expand === 'block' ? '100%' : undefined,
        borderRadius: expand === 'block' ? 0 : 4,
        maxWidth: '100%',
        whiteSpace: 'normal',
        textAlign: 'start',
        lineHeight: '1.25',
    };

    // use a wrapper with white background because we use opacity to desaturate the
    // background of the badge above, but we don't want it to be partially transparent
    const wrapperStyles = {
        backgroundColor: 'white',
        borderRadius: expand === 'block' ? 0 : 4
    };

    return (
        <div style={wrapperStyles}>
            <IonBadge data-type={dataType} style={badgeStyles}>
                {children}
            </IonBadge>
        </div>
    );
};
