import { HistoryViewModel } from '../support/HistoryViewModel';

const stripTrailingSlash = (path: string) => path.replace(/\/$/, '');

export class CareMapDrawerRouter {

    static inject = () => [HistoryViewModel];
    constructor(private history: HistoryViewModel) {}

    // keep route in sync with selected member
    // (see corresponding code in Drawer to keep selected member in sync with route)
    navigateToSelectedMemberId = ({ basePath, memberId }: { basePath: string, memberId?: string }) => {
        basePath = stripTrailingSlash(basePath);
        const currentPath = stripTrailingSlash(this.history.getCurrentLocation().pathname);

        if (!memberId) {
            // if on a member page then assume list is previous in history so go back
            // won't be true for deeplinking
            if (currentPath.includes(`${basePath}/member/`)) {
                this.history.back();
            }
            else {
                this.history.push(`${basePath}`);
            }
        }
        else {
            // if on a member page then replace it with the new member page
            if (currentPath.includes(`${basePath}/member/`)) {
                this.history.replace(`${basePath}/member/${memberId}`);
            }
            // if on member list, then push the member page (don't want to replace the member list)
            else if (currentPath === basePath) {
                this.history.push(`${basePath}/member/${memberId}`);
            }
            // otherwise, assume not in the drawer, so push drawer, then member
            else {
                this.history.push(`${basePath}`);
                this.history.push(`${basePath}/member/${memberId}`);
            }
        }
    };
}
