import { FC, useEffect, useRef } from 'react';
import { IonButton, IonItem, IonLabel, IonText } from '@ionic/react';
import dayjs from 'dayjs';
import { IAppointment } from './IAppointment';
import { AppointmentsState, NEW_APPOINTMENT_ID } from './AppointmentsState';
import { createUseStyles } from 'react-jss';
import { useSubscription } from '@meraki-internal/state';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { ISupportGroupKey } from '../caremap/ICareMap';
import { ISO8601 } from '../support/ISO8601';
import { useScrollIntoView } from '../utils/useScrollIntoView';
import { AddAppointmentButton } from './AddAppointmentButton';
import { DrawerSectionHeading } from '../components/DrawerSectionHeading';
import { RevenueCatModel } from '../innerhive-plus/revenue-cat/RevenueCatModel';

const useStyles = createUseStyles({
    addLink: {
        cursor: 'pointer',
        color: 'var(--ion-color-primary)'
    },

    dateHeading: {
        padding: 6,
        fontWeight: 500,
        textTransform: 'uppercase',
        background: 'var(--ion-color-step-50)',
        color: 'var(--ion-color-step-600, #666666)'
    },

    todayHeading: {
        color: 'var(--ion-color-secondary)',
    },

    appointment: {
        gap: 8,
        display: 'flex'
    },

    times: {
        fontSize: 16,
        fontWeight: '500',
        textAlign: 'end',
        color: 'var(--ion-color-step-600, #666666)'
    },

    endTime: {
        fontSize: 14,
        paddingTop: 2.2
    },

    details: {
        flexGrow: 1
    },

    title: {
        fontWeight: 500
    },

    location: {
        color: 'var(--ion-color-step-600, #666666)'
    },

    moreBtnWrapper: {
        display: 'flex',
        justifyContent: 'space-around',

        // push this into the DrawerSection bottom margin
        marginBottom: -24
    }
});

export type IAppointmentsListProps = {
    showCount?: number;
    showHeading?: boolean;
    'data-type'?: string;
    minDate?: ISO8601;
    numDaysToShow?: number;
    showViewMore?: boolean;
    supportMemberId?: string;
    supportGroupKey?: ISupportGroupKey;
    editAppointment: (apptId: string) => void;
    viewMore?: () => void;
};

export const AppointmentsList: FC<IAppointmentsListProps> = ({
    showCount,
    showHeading = true,
    'data-type': dataType = 'appointments-list',
    minDate,
    numDaysToShow,
    showViewMore = false,
    supportMemberId,
    supportGroupKey,
    editAppointment,
    viewMore = () => {}
}) => {
    const classes = useStyles();

    const scrollIntoView = useScrollIntoView();
    const scrollToRef = useRef<HTMLDivElement>(null);

    const appointmentsState = useInstance(AppointmentsState);
    useSubscription(() => appointmentsState);

    const revenueCat = useInstance(RevenueCatModel);
    useSubscription(() => revenueCat);

    const hasInnerhivePlus = revenueCat.hasInnerhivePlus();

    useEffect(() => {
        scrollIntoView(scrollToRef);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isOnOrAfterDate = (date: ISO8601, onOrAfterDate: ISO8601) => {
        const after = dayjs(onOrAfterDate).subtract(1, 'days');
        return dayjs(date).isAfter(after, 'day');
    };

    // utility to loop x times and call a function each time
    const times = (x: number) => (f: (y: number) => void) => {
        if (x > 0) {
            f(x);
            times(x-1)(f);
        }
    };

    const formatDateHeading = (date: string) => {
        const dayjsDate = dayjs(date);
        const today = dayjsDate.isToday();
        const format =  `${today ? '' : 'dddd, '}MMM D`;
        return `${today ? 'Today, ' : ''}${dayjsDate.format(format)}`;
    };

    const formatAppointmentTime = (appt: IAppointment, group: string, isStart: boolean) => {
        let format = 'MMM D';
        const dateToFormat = dayjs(isStart ? appt.start : appt.end);
        const isSameDay = dateToFormat.isSame(group, 'day');

        if (isSameDay) {
            format = 'h:mma';
        }

        return dateToFormat.format(format);
    };

    // get appropriate appointments and sort descending chronologically
    let appointments = supportGroupKey ? appointmentsState.getAppointmentsForSupportGroup(supportGroupKey) :
        supportMemberId ? appointmentsState.getAppointmentsForSupportMember(supportMemberId) : appointmentsState.state.appointments;
    appointments = (appointments || []).sort((n1, n2) => dayjs(n1.start).isAfter(n2.start) ? 1 : -1);

    const hasAppointments = appointments.length > 0;

    // only show minDate and forward
    if (minDate) {
        appointments = appointments.filter(appt => isOnOrAfterDate(appt.end, minDate));
    }

    // group dates by day
    type IGroupedAppointments = {[key: string]: IAppointment[]};
    const groupedAppointments: IGroupedAppointments = appointments.reduce((groups: IGroupedAppointments, appt) => {
        const sDate = dayjs(appt.start).format().split('T')[0];
        const diffInDays = dayjs(appt.end).diff(dayjs(appt.start), 'days');

        // if the end date is before minDate, don't add it
        if (!minDate || isOnOrAfterDate(appt.start, minDate)) {
            // if there's no start date collection, create it
            if (!groups[sDate]) {
                groups[sDate] = [];
            }
            groups[sDate].push(appt);
        }

        // loop over the number of days it spans, make sure it's in each days group
        times(diffInDays)((y) => {
            const d = dayjs(sDate).add(y, 'days').toISOString().split('T')[0];
            // if the group date is before minDate, don't add it
            if (!minDate || isOnOrAfterDate(d, minDate)) {
                if (!groups[d]) {
                    groups[d] = [];
                }
                groups[d].push(appt);
            }
        });

        return groups;
    }, {});

    // hack to make sure scrollRef is set on either today or next day in future
    let scrollRefFound = 0;

    const canAdd = appointmentsState.canAdd();
    const hasVisibleAppointments: boolean = Object.keys(groupedAppointments).length > 0;
    const isAnythingToSee = canAdd || hasVisibleAppointments;

    if(!isAnythingToSee){
        return null;
    }

    const noApptsMsg = <>
        No upcoming appointments{hasInnerhivePlus ? <>,&nbsp;
            <span data-id="add-one-link" className={classes.addLink} onClick={() => editAppointment(NEW_APPOINTMENT_ID)}>add one</span>
        </> : null}
    </>;

    return (
        <div style={{ width: '100%' }} data-type={dataType}>
            {showHeading && (
                <DrawerSectionHeading text="Appointments">
                    <AddAppointmentButton
                        onClick={editAppointment}
                        canAdd={appointmentsState.canAdd()}
                    />
                </DrawerSectionHeading>
            )}

            {!hasVisibleAppointments && canAdd && (
                <IonText color="medium" style={{paddingLeft: 12}}>
                    {noApptsMsg}
                </IonText>
            )}

            {/* TODO: consider using a map instead of object.keys.sort.map */}
            {hasVisibleAppointments && Object.keys(groupedAppointments).sort().map((date, idx) => {
                const isTodayGroup = dayjs(date).isToday();
                const isTodayOrLater = isOnOrAfterDate(date, dayjs().toISOString());

                if (isTodayOrLater) {
                    scrollRefFound++;
                }

                // only show the number of days we want
                if (idx >= numDaysToShow!) {
                    return null;
                }

                return (
                    <div
                        key={date}
                        data-type="appointments-date-heading"
                        ref={scrollRefFound === 1 ? scrollToRef : undefined}
                    >
                        <div className={`${classes.dateHeading} ${isTodayGroup ? classes.todayHeading : ''}`}>
                            {formatDateHeading(date)}
                        </div>

                        {groupedAppointments[date].map((appt, idx2) => {
                            // only show the number of appointments (showCount) we want
                            if (idx2 >= showCount!) {
                                return null;
                            }

                            return (
                                <IonItem
                                    button
                                    detail
                                    lines="none"
                                    key={appt.appointmentId}
                                    onClick={() => editAppointment(appt.appointmentId)}
                                >
                                    <IonLabel class="ion-text-wrap">
                                        <div className={classes.appointment}>
                                            <div className={classes.times}>
                                                <div data-type="start">{formatAppointmentTime(appt, date, true)}</div>
                                                <div data-type="end" className={classes.endTime}>{formatAppointmentTime(appt, date, false)}</div>
                                            </div>
                                            <div className={classes.details}>
                                                <div data-type="title" className={classes.title}>{appt.title}</div>
                                                <div data-type="location" className={classes.location}>{appt.location}</div>
                                            </div>
                                        </div>
                                    </IonLabel>
                                </IonItem>
                            );
                        })}
                    </div>
                );
            })}

            {showViewMore && hasAppointments && (
                <div className={classes.moreBtnWrapper}>
                    <IonButton fill="clear" onClick={viewMore} data-id="view-more-appointments-button">
                        View All Appointments
                    </IonButton>
                </div>
            )}
        </div>
    );
};
