import { State } from '@meraki-internal/state';
import { AsyncStorageProvider, IAsyncSmartStorageProvider } from '../AsyncStorageProvider';
import { RevenueCatAPIModel } from '../../innerhive-plus/revenue-cat/RevenueCatAPIModel';

const flags = {
    // add new flags here, for example:
    // 'can-see:my-feature': false
};

export type IFeatureFlag = keyof typeof flags;


export class FeatureFlagState extends State<Record<string, never>> {
    static inject = () => [AsyncStorageProvider, RevenueCatAPIModel];
    constructor(storageProvider: AsyncStorageProvider, private entitlementsProvider: RevenueCatAPIModel){
        super({});
        this.entitlementsProvider.subscribe(() => {
            this.setState({});
        });
        this.storage = storageProvider.getJSONProvider('FeatureFlagState.flags', { envNeutral: false, userNeutral: false});
    }

    private storage: IAsyncSmartStorageProvider<{ [key: string]: boolean }>;
    private flags: { [key: string]: boolean  } = {};


    getFlagValue = (key: IFeatureFlag): boolean => {
        if (this.entitlementsProvider.hasEntitlement(`e_${key}`)){
            return true;
        }
        return this.flags[key] || false;
    };

    setFlagValue = (key: IFeatureFlag, value: boolean) => {
        this.flags[key] = value;
        this.setState({ });
        this.storage.set(this.flags);
    };

    init = async () => {
        const _flags = await this.storage.get();
        this.flags = _flags || {};
    };

    setFlags = (enabledFlags: IFeatureFlag[]) => {
        this.flags = {};
        for (const flag of enabledFlags){
            this.flags[flag] = true;
        }
        this.setState({ });
        this.storage.set(this.flags);
    };

    getSupportedFlags = () => Object.keys(flags) as IFeatureFlag[];
}
