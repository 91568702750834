import { APIClient } from '@innerhive-internal/innerhive-api-client';
import { State } from '@meraki-internal/state';
import { AuthService, IAuthService } from '../auth/AuthService';
import { BufferedEventBus } from '../utils/BufferedEventBus';
import { MaybeWithLinks } from '../caremap/WithLinks';

export interface IUser {
    userId?: string;
    firstName?: string;
    lastName?: string;
    pictureUrl?: string;
    email?: string;
    updatedTime?: string;
}

/**
 * UserState is loaded in AppContainer before the app starts, and therefore will always be loaded.
 */
export class UserState extends State<MaybeWithLinks<IUser>> {

    isDirty = false;

    static inject = () => [
        APIClient,
        AuthService,
        BufferedEventBus
    ];

    constructor(
        private apiClient: APIClient,
        private authService: IAuthService,
        private events: BufferedEventBus
    ) {
        super({});
    }

    load = async () => {
        const entry = await this.apiClient.entry();
        if (!entry.links.user){
            return;
        }
        const user = await this.apiClient.get(entry.links.user);

        if (user) {
            this.setState(user);
        }
    };

    getName = (): string => {
        if (this.state.firstName && this.state.lastName){
            return `${this.state.firstName} ${this.state.lastName}`;
        }
        return this.state.firstName || '';
    };

    save = async () => {
        const entry = await this.apiClient.entry();
        if (!entry.links.user){
            throw new Error('not supported');
        }

        await this.apiClient.put(entry.links.user, this.state);
        this.isDirty = false;
        this.setState({ });

        this.events.emit('UserState.saved');
    };

    onSaved = (listener: () => void) => {
        return this.events.on('UserState.saved', listener);
    };

    deleteAccount = async () => {
        const entry = await this.apiClient.entry();
        if (!entry.links.user){
            throw new Error('not supported');
        }

        this.events.emit('UserState.deleted');

        await this.apiClient.post(entry.links.deleteAccount, undefined);

        await this.authService.logout();
    };

    setFirstName = (firstName: string) => {
        this.isDirty = true;
        this.setState({ firstName });
    };

    setLastName = (lastName: string) => {
        this.isDirty = true;
        this.setState({ lastName });
    };

}
