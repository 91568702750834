import { IonButton, IonButtons, IonItem, IonLabel, IonList, IonText, IonToolbar } from '@ionic/react';
import { InnerhivePage } from '../InnerhivePage';
import { createUseStyles } from 'react-jss';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { OrganizationsState } from './OrganizationsState';
import { useSubscription } from '@meraki-internal/state';
import { useEffect, useState } from 'react';
import { OrgNavigationMenuState } from './OrgNavigationMenuState';
import { Menu } from '../components/Menu';
import { DeleteIcon, PeopleArrowsIcon, EyeIcon } from '../theme/Icons';
import { IOrganizationUser } from './IOrganization';
import { Badge } from '../components/Badge';
import { Text } from '../components/Text';
import { AddUser } from './AddUser';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { UserState } from '../user/UserState';
import { TransferClientsModal } from './TransferClientsModal';
import { RemoveTeamMemberModal } from './RemoveTeamMemberModal';

const useStyles = createUseStyles({
    userList: {
        '& ion-item': {
            '--inner-padding-end': '12px'
        }
    },

    userRow: {
        marginTop: 20,
        display: 'flex !important',
        alignItems: 'start',
        justifyContent: 'space-between'
    },

    name: {
        fontWeight: 500,
        display: 'flex',
        gap: 20,
        alignItems: 'center'
    },

    email: {
        fontSize: '0.95em',
        color: 'var(--ion-color-step-750)'
    }
});

export const OrganizationTeam: React.FC<{ orgId: string; }> = ({ orgId }) => {
    const classes = useStyles();

    const orgState = useInstance(OrganizationsState);
    useSubscription(() => orgState);

    const userState = useInstance(UserState);

    const [ transferringClientsFromMemberId, setTransferringClientsFromMemberId ] = useState<string | undefined>();
    const [ removingMemberId, setRemovingMemberId ] = useState<string | undefined>();

    const history = useInstance(HistoryViewModel);
    const orgNavMenuState = useInstance(OrgNavigationMenuState);

    const [addUserOpen, setAddUserOpen] = useState<boolean>(false);

    const org = orgState.getOrganization(orgId);

    useEffect(() => {
        // if my user didn't have a name and now it's required, i enter it, need to refresh the list
        userState.onSaved(() => {
            orgState.load();
        });

        orgNavMenuState.setSelectedNavItem('team');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if(!org){
        return null;
    }

    const closeOrDismissAddUser = () => {
        setAddUserOpen(false);
    };

    const deleteUser = async (user: IOrganizationUser) => {
        setRemovingMemberId(user.userId);
    };

    const canAddStaff = orgState.canAddOrganizationUser(orgId, 'org-staff');
    const canAddAdmin = orgState.canAddOrganizationUser(orgId, 'org-administrator');
    const sortedTeamMembers = orgState.getOrganizationTeamMembers(orgId).sort((u1, u2) => u1.firstName.localeCompare(u2.firstName));

    return (
        <InnerhivePage title={org.name}>
            <IonToolbar>
                <Text type="title">Team Members</Text>
                {(canAddStaff || canAddAdmin) && (
                    <IonButtons slot="end">
                        <IonButton
                            fill="solid"
                            color="primary"
                            data-id="add-org-user"
                            onClick={() => setAddUserOpen(true)}
                        >+ Add Team Member</IonButton>
                    </IonButtons>
                )}
            </IonToolbar>

            <IonList className={classes.userList} data-id="org-user-list">
                {sortedTeamMembers.map(user => {
                    const canRemoveUser = orgState.canRemoveOrganizationUser(user);
                    const hasCoOwnedMaps = orgState.userHasClientCoOwnedCareMaps(orgId, user.userId);

                    return (
                        <IonItem key={user.email} data-user-email={user.email}>
                            <IonLabel>
                                <div className={classes.userRow}>
                                    <div>
                                        <div className={classes.name}>
                                            <div>{user.firstName} {user.lastName}</div>
                                            {user.role === 'org-administrator' && (
                                                <Badge data-type="admin-badge" size="xsmall" color="medium">Admin</Badge>
                                            )}
                                        </div>
                                        <IonText data-type="email" className={classes.email}>{user.email}</IonText>
                                    </div>
                                    {(canRemoveUser || hasCoOwnedMaps) && (
                                        <Menu actions={[
                                            {
                                                Icon: EyeIcon,
                                                text: 'View Clients',
                                                'data-type': 'view-clients-menuitem',
                                                handler: () => history.push(`/org/${orgId}/clients?user=${user.userId}`),
                                                hidden: !hasCoOwnedMaps
                                            },
                                            {
                                                Icon: PeopleArrowsIcon,
                                                text: 'Transfer Clients',
                                                'data-type': 'transfer-clients-menuitem',
                                                handler: () => setTransferringClientsFromMemberId(user.userId),
                                                hidden: !orgState.canTransferClients(user)
                                            },
                                            {
                                                Icon: DeleteIcon,
                                                text: 'Delete Team Member',
                                                role: 'destructive',
                                                handler: () => deleteUser(user),
                                                'data-type': 'delete-user',
                                                hidden: !canRemoveUser
                                            }
                                        ]} />
                                    )}
                                </div>
                            </IonLabel>
                        </IonItem>
                    );
                })}
            </IonList>

            <AddUser
                orgId={orgId}
                isOpen={addUserOpen}
                canAddAdmin={canAddAdmin}
                closeHandler={closeOrDismissAddUser}
            />

            {transferringClientsFromMemberId && (
                <TransferClientsModal
                    fromMemberId={transferringClientsFromMemberId} orgId={orgId}
                    closeHandler={() => setTransferringClientsFromMemberId(undefined)}
                    onTransferred={() => setTransferringClientsFromMemberId(undefined)}
                />
            )}

            {removingMemberId && (
                <RemoveTeamMemberModal
                    orgId={orgId}
                    memberId={removingMemberId}
                    onDeleted={() => setRemovingMemberId(undefined)}
                    onCancel={() => setRemovingMemberId(undefined)}
                />
            )}

        </InnerhivePage>
    );
};
