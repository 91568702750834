import { Preferences } from '@capacitor/preferences';

export interface IStorageOptions {
    envNeutral: boolean;
    userNeutral: boolean;
}

export interface IAsyncSmartStorageProvider<T> {
    exists: () => Promise<boolean>;
    get: () => Promise<T | undefined>;
    set: (value: T) => Promise<void>;
    remove: () => Promise<void>;
}

export class AsyncStorageProviderConfig {
    constructor(){
        throw new Error('expected to be set via IOC');
    }
    platform: 'web' | 'android' | 'ios';
    backwardsCompatibilityWebKeys: string [];
}

export class AsyncStorageProvider {
    static inject = () => [AsyncStorageProviderConfig];
    constructor(private config: AsyncStorageProviderConfig){}

    env: 'staging' | 'live' | 'unknown' = 'unknown';
    userId: string | undefined = undefined;

    setEnv = (env: 'staging' | 'live' | 'unknown') => {
        this.env = env;
        return this;
    };

    setUserId = (userId: string) => {
        this.userId = userId;
        return this;
    };

    private get = async (key: string, options?: Partial<IStorageOptions>): Promise<string | undefined> => {
        if (this.config.platform === 'web' && this.config.backwardsCompatibilityWebKeys.includes(key)){
            return window.localStorage.getItem(this.getFullKey(key, options)) || undefined;
        }
        const { value } = await Preferences.get({ key: this.getFullKey(key, options) });
        if (!value){
            return undefined;
        }
        return value;
    };

    private exists = async (key: string, options?: Partial<IStorageOptions>): Promise<boolean> => {
        if (this.config.platform === 'web' && this.config.backwardsCompatibilityWebKeys.includes(key)){
            return window.localStorage.getItem(this.getFullKey(key, options)) !== null;
        }
        const { value } = await Preferences.get({ key: this.getFullKey(key, options) });
        return value !== null;
    };

    private remove = async (key: string, options?: Partial<IStorageOptions>): Promise<void> => {
        if (this.config.platform === 'web' && this.config.backwardsCompatibilityWebKeys.includes(key)){
            window.localStorage.removeItem(this.getFullKey(key, options));
        }
        else {
            await Preferences.remove({ key: this.getFullKey(key, options) });
        }
    };

    private getJSON = async <T>(key: string, options?: Partial<IStorageOptions>): Promise<T | undefined> => {
        const json = await this.get(key, options);
        return json ? JSON.parse(json) as T : undefined;
    };

    private getFullKey = (key: string, options?: Partial<IStorageOptions>) => {
        options = options || {} as IStorageOptions;
        // default to NOT env neutral and NOT user neutral since that should be most things
        if (typeof options.envNeutral !== 'boolean'){
            options.envNeutral = false;
        }
        if (typeof options.userNeutral !== 'boolean'){
            options.userNeutral = false;
        }

        let fullKey = key;
        if (!options.envNeutral && this.env === 'unknown') {
            throw new Error(`attempted to get key "${key}" but env is not yet known`);
        }

        if (!options.userNeutral && !this.userId) {
            throw new Error(`attempted to get key "${key}" but userId is not yet known`);
        }

        if (!options.userNeutral) {
            fullKey = `${this.userId}:${fullKey}`;
        }

        if (!options.envNeutral) {
            fullKey = `${this.env}:${fullKey}`;
        }

        return fullKey;
    };

    private set = async (key: string, value: string, options?: Partial<IStorageOptions>) => {
        if (this.config.platform === 'web' && this.config.backwardsCompatibilityWebKeys.includes(key)){
            return window.localStorage.setItem(this.getFullKey(key, options), value);
        }
        else {
            await Preferences.set({
                key: this.getFullKey(key, options),
                value
            });
        }
    };

    private setJSON = async <T>(key: string, value: T, options?: Partial<IStorageOptions>) => {
        await this.set(key, JSON.stringify(value), options);
    };

    getStringProvider = (key: string, options?: Partial<IStorageOptions>): IAsyncSmartStorageProvider<string> => ({
        exists: () => this.exists(key, options),
        get: () => this.get(key, options),
        set: (value: string) => this.set(key, value, options),
        remove: () => this.remove(key, options)
    });

    getBooleanProvider = (key: string, options?: Partial<IStorageOptions>): IAsyncSmartStorageProvider<boolean> => ({
        exists: () => this.exists(key, options),
        get: async () => (await this.get(key, options) === 'true'),
        set: (value: boolean) => this.set(key, String(value), options),
        remove: () => this.remove(key, options)
    });

    getJSONProvider = <T>(key: string, options?: Partial<IStorageOptions>): IAsyncSmartStorageProvider<T> => ({
        exists: () => this.exists(key, options),
        get: () => this.getJSON<T>(key, options),
        set: (value: T) => this.setJSON<T>(key, value, options),
        remove: () => this.remove(key, options)
    });
}
