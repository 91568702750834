import * as Sentry  from '@sentry/react';
import { Container } from '@meraki-internal/react-dependency-injection';
import { DevSettings } from './DevSettings';
import { getEnvironment, IEnvironment } from '../config/EnvConfiguration';

// instantiate directly as we don't have ioc yet
// this is at parse time / pre-ioc so that errors early in the stack can go to sentry
const devSettings = new Container().get(DevSettings);
let environment = getEnvironment(devSettings);

// Set the sentry context so it's attached to logged data
// This is a no-op in a dev build as we don't initialize sentry
export let setSentryContext = ({ user, env }: { user: any, env: IEnvironment }) => {};

const errorIgnoredFromSentry = (error: any, _user: any): boolean => {
    try{
        if (!error) { // if there is no error, then there is nothing to send to Sentry
            return true;
        }

        // this is thrown sometimes from inside mapbox
        if (error.toString() === 'AbortError: Fetch is aborted') {
            return true;
        }

        // as far as we can tell, the only way to get these errorCodes is
        // if the request is aborted (they are navigating away) or a CORS
        // misconfiguration. The aborted request is too noisy (false positives)
        // and a CORS misconfiguration will fail our tests, so we're going to ignore
        if ([ 'fetch.failed', 'fetch.missingBody' ].includes(error.errorCode)){
            return true;
        }

        // if it looks like failed to fetch b/c we're offline
        if (error.toString().includes('TypeError: Failed to fetch')){
            return true;
        }

        // we don't support offline, so if we know we're offline, then don't report the error as it
        // won't be actionable. Some will still get through though (eg flaky internet)
        if (!window.navigator.onLine){
            return true;
        }

        if (error.errorCode === 'viewer.jwt.expired'){
            // this error is thrown during app start, making it not worth stopping
            // but we don't want this error to go to sentry because we expect it
            return true;
        }

        if (error.errorCode === 'device-not-allowed'){
            // we don't want sentry logging for unsupported device errors
            return true;
        }

        if (error.errorCode === 'login-aborted') {
            // we don't want sentry logging for login aborted errors
            return true;
        }

    } catch (err) {
        console.log('failed to evaluate errorIgnoredFromSentry, assuming not to ignore', err);
    }

    return false;
};

const transactionIgnoredFromSentry = (event: Sentry.Event): boolean => {
    try {
        // help keep transactions below sentry quota by filtering events we won't need
        if (String(event.user?.id).startsWith('auto_test')) {
            return true;
        }
        if (event.request?.url?.includes('?screenshots')) {
            return true;
        }

    } catch (err) {
        console.log('failed to evaluate transactionIgnoredFromSentry, assuming not to ignore', err);
    }

    return false;
};

if (import.meta.env.PROD) {
    console.log(`initializing sentry for "${environment}" env`);

    Sentry.init({
        release: `innerhive-app@${process.env.npm_package_version}.${(process.env.VITE_APP_SHA || '').substring(0,7)}`,
        dsn: 'https://b41cb56b835047dfa43407cd38266bb1@o4505289379414016.ingest.sentry.io/4505289429155840',
        environment,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        tracesSampleRate: 0.5,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        beforeSend: (sentryEvent, hint) => {
            if (errorIgnoredFromSentry(hint?.originalException, sentryEvent.user)) {
                return null;
            }
            return sentryEvent;
        },

        beforeSendTransaction: (sentryEvent) => {
            if (transactionIgnoredFromSentry(sentryEvent)) {
                return null;
            }

            // set the environment on each event because it may have changed since init
            // (if we switched to faa-testing)
            sentryEvent.environment = environment;

            return sentryEvent;
        }
    });

    setSentryContext = ({ user, env }) => {
        console.log('setting sentry context', user);

        Sentry.setUser({ id: user.userId, email: user.email });

        // override the global environment derived from the url
        // to support switching environments after login
        environment = env;
    };
}
