import { INewCareMap, ISupportGroupKey, ISupportGroupMember } from './ICareMap';
import { SupportGroupDataProvider } from './SupportGroupDataProvider';

const householdAvatarPath = '../../assets/images/v2avatars/';
export const householdMemberAvatars: string[] = [
    `${householdAvatarPath}1bee.png`,
    `${householdAvatarPath}2cat.png`,
    `${householdAvatarPath}3dog.png`,
    `${householdAvatarPath}4otter.png`,
    `${householdAvatarPath}5fish.png`,
    `${householdAvatarPath}6tree.png`,
    `${householdAvatarPath}7seedling.png`,
    `${householdAvatarPath}8cow.png`,
    `${householdAvatarPath}9horse.png`,
    `${householdAvatarPath}10frog.png`,
    `${householdAvatarPath}11hippo.png`,
    `${householdAvatarPath}12dragon.png`
];

export const isHouseholdAvatar = (url: string | undefined): boolean => {
    return url && url.indexOf(householdAvatarPath) >= 0 ? true : false;
};

export const householdMemberColors: string[] = [
    '#11789D',
    '#54C0CC',
    '#4763C7',
    '#923DAC',
    '#334155',
    '#059669',
    '#84CC16',
    '#CD5DAC',
    '#F5D14F',
    '#EE6C4E',
    '#EA580C',
    '#E02D3C'
];

export class CareMapFactory {
    static inject = () => [SupportGroupDataProvider];
    constructor(private dataProvider: SupportGroupDataProvider) {}

    getNodeId = (): string => {
        return String(Date.now());
    };

    createBlank = (): INewCareMap => {
        return {
            links: undefined,
            careMapId: undefined,
            patient: {
                avatar: householdMemberAvatars[0],
                contactMethods: [],
                notes: []
            },
            household: { members: [], notes: [], checklists: [] },
            community: { members: [], isRelevant: undefined, notes: [], checklists: [] },
            financial: { members: [], isRelevant: undefined, notes: [], checklists: [] },
            school: { members: [], isRelevant: undefined, notes: [], checklists: [] },
            medical: { members: [], isRelevant: undefined, notes: [], checklists: [] },
            specialists: { members: [], isRelevant: undefined, notes: [], checklists: [] },
            social: { members: [], isRelevant: undefined, notes: [], checklists: [] },
            initialWizardAnswers: {},
            essentialMapComplete: false
        };
    };

    createSupportGroupMember = (supportGroupKey: ISupportGroupKey, roleId?: string): ISupportGroupMember => {
        const role = (roleId ? this.dataProvider.getRoleLabel({ supportGroupKey, roleId }) : undefined);
        return {
            id: this.getNodeId(),
            roleId,
            role,
            contactMethods: [],
            notes: []
        };
    };
}
