import { IonButton } from '@ionic/react';
import { HeaderButton } from '../components/HeaderButton';
import { useState } from 'react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { HistoryViewModel } from '../support/HistoryViewModel';
import { CareMapState } from '../caremap/CareMapState';
import { ISupportGroup, ISupportGroupKey, SUPPORT_GROUP_KEYS } from '../caremap/ICareMap';
import { HeaderPopover } from '../components/HeaderPopover';
import { DeleteIcon, GearIcon, CalendarPlusIcon, InnerhivePlusIcon } from '../theme/Icons';
import { MenuItem } from '../components/MenuItem';
import { AlertPresenter } from '../AlertBinder';
import { WelcomePageState } from '../QuestionnaireWelcomePage';
import { useSubscription } from '@meraki-internal/state';
import { Divider } from '../components/Divider';
import { RevenueCatModel } from '../innerhive-plus/revenue-cat/RevenueCatModel';
import { InnerhivePlusDialogViewModel } from '../innerhive-plus/InnerhivePlusDialogViewModel';
import { ConnectCalendarViewModel } from '../appointments/ConnectCalendarViewModel';
import { DevSettings } from '../support/DevSettings';


export const CareMapConfigPopover: React.FC = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const careMapState = useInstance(CareMapState);
    const history = useInstance(HistoryViewModel);
    const alert = useInstance(AlertPresenter);

    const devSettings = useInstance(DevSettings);
    let allSupportGroups: ISupportGroupKey[] = SUPPORT_GROUP_KEYS.slice(0);
    if (!devSettings.canAddSocialCategory){
        allSupportGroups = allSupportGroups.filter(key => key !== 'social');
    }

    const welcomePageState = useInstance(WelcomePageState);
    useSubscription(() => welcomePageState);

    const revenueCat = useInstance(RevenueCatModel);
    useSubscription(() => revenueCat);

    const dialogVM = useInstance(InnerhivePlusDialogViewModel);
    const connectCalDialogVM = useInstance(ConnectCalendarViewModel);

    // if we don't have any caremaps, then we can't show the menu for the active caremap
    if (!careMapState.hasActiveCareMap()){
        return null;
    }

    const missingSupportGroups = allSupportGroups.filter(supportGroupKey => {
        return !careMapState.getActiveCaremap()?.[supportGroupKey].isRelevant;
    });
    const showAddCategory = careMapState.getActiveCaremap()?.essentialMapComplete && missingSupportGroups.length && careMapState.hasPermissionTo('put');

    const showDeleteMenu = careMapState.hasPermissionTo('delete');

    // if the menu would be empty, then there is nothing to show
    if (!showAddCategory && !showDeleteMenu){
        return null;
    }

    const toggleMenu = () => {
        setMenuOpen(oldState => {
            return !oldState;
        });
    };

    const onAdd = (supportGroupKey: ISupportGroupKey) => {
        setMenuOpen(false);

        const group = careMapState.getActiveCaremap()![supportGroupKey] as ISupportGroup;
        if (!group.isRelevant){
            careMapState.addOrUpdateSupportGroup({ supportGroupKey });
        }

        history.push(`/caremaps/${careMapState.getActiveCaremap()?.careMapId}/drawer/support-group/${supportGroupKey}`);
    };

    const onDeleteCareMap = async () => {
        toggleMenu();

        const isQuestionnaire = !careMapState.getActiveCaremap()?.essentialMapComplete;
        const message = isQuestionnaire ?
            'Your responses will be deleted and you can start a new questionnaire if desired.' :
            'All info including contacts, notes, photos and everything associated with this care map and care recipient will be deleted and cannot be recovered.';

        const destroyOrCancel = await alert.confirmDestructiveAction({
            wider: true,
            header: isQuestionnaire ? 'Discard Questionnaire?' : `Delete ${careMapState.getPatientFirstName()}'s Care Map?`,
            message: message,
            destroyLabel: isQuestionnaire ? 'Discard' : undefined,
        });

        if (destroyOrCancel === 'destroy'){
            try {
                await careMapState.delete();
            }
            catch (err){
                alert.showAndLogError(err);
            }
        }
    };

    const hasInnerhivePlus = revenueCat.hasInnerhivePlus();

    const connectCalendar = async () => {
        setMenuOpen(false);

        if (hasInnerhivePlus) {
            connectCalDialogVM.open();
        } else {
            const nowHasInnerhivePlus = await dialogVM.requirePurchase({ triggerSource: 'connect calendar' });
            if (nowHasInnerhivePlus) {
                connectCalDialogVM.open();
            }
            // do nothing, user canceled paywall
        }
    };

    return (
        <>
            <HeaderButton id="caremap-config-button" onClick={toggleMenu}>
                <div style={{marginTop: 2}}>
                    <GearIcon color="primary" />
                </div>
            </HeaderButton>

            <HeaderPopover
                maxWidth="smaller"
                trigger="caremap-config-button"
                isOpen={isMenuOpen}
                onDidDismiss={() => setMenuOpen(false)}
                title={showAddCategory ? 'Add Care Category' : undefined}
                content={
                    showAddCategory ? missingSupportGroups.map(supportGroupKey => (
                        <div key={supportGroupKey}>
                            <IonButton
                                fill="outline"
                                expand="block"
                                onClick={() => onAdd(supportGroupKey)}
                                data-id={`add-support-group--${supportGroupKey}`}
                            >
                                {careMapState.getSupportGroupLabels(supportGroupKey).title}
                            </IonButton>
                        </div>
                    )) : undefined
                }
                lowerContent={
                    <>
                        {careMapState.getActiveCaremap()?.essentialMapComplete && (
                            <>
                                <MenuItem
                                    data-id="connect-calendar"
                                    onClick={connectCalendar}
                                    label={
                                        <>
                                            Connect Your Calendar
                                            {!hasInnerhivePlus && <span style={{marginLeft: 8}}><InnerhivePlusIcon color="secondary" /></span>}
                                        </>
                                    }
                                    Icon={CalendarPlusIcon}
                                />
                                <Divider />
                            </>
                        )}

                        {showDeleteMenu && (
                            <MenuItem
                                data-id="delete-caremap-item"
                                onClick={onDeleteCareMap}
                                label={careMapState.getActiveCaremap()?.essentialMapComplete ? 'Delete Care Map' : 'Discard Questionnaire'}
                                color="danger"
                                Icon={DeleteIcon}
                            />
                        )}
                    </>
                }
            />
        </>
    );
};
