import { IonButton } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { CareMapState } from '../caremap/CareMapState';
import { IStepValidProps, Step } from './Step';
import { ISupportGroupKey } from '../caremap/ICareMap';
import { SupportGroupDataProvider } from '../caremap/SupportGroupDataProvider';
import { CareMapFactory } from '../caremap/CareMapFactory';
import { useEffect } from 'react';

export type ICommonRoleStepProps = IStepValidProps & {
    // NOTE: this is coupled to the route used by IntialWizardState
    supportGroupKey: ISupportGroupKey;
    getQuestion: () => string;
}

export const CommonRolesStep: React.FC<ICommonRoleStepProps> = ({ getQuestion, supportGroupKey, setStepValidity }) => {
    const caremap = useInstance(CareMapState);
    useSubscription(() => caremap);
    const supportGroupDataProvider = useInstance(SupportGroupDataProvider);
    const careMapFactory = useInstance(CareMapFactory);

    useEffect(() => {
        // selection on this step is optional, so always valid
        setStepValidity(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Step question={getQuestion()} data-id="school/common">
            {supportGroupDataProvider.getRolesArray({ supportGroupKey }).slice(0,10).map(({ roleId, label: role }) => {

                const fill: 'outline' | 'solid' = caremap.findSupportGroupMemberByRole({ supportGroupKey, roleId }) ? 'solid' : 'outline';
                const onClick = () => {
                    const member = caremap.findSupportGroupMemberByRole({ supportGroupKey, roleId });

                    if (member){
                        caremap.removeSupportGroupMember({ supportGroupKey, memberId: member.id });
                    }
                    else {
                        caremap.addSupportGroupMember(supportGroupKey, {
                            id: careMapFactory.getNodeId(),
                            roleId,
                            role,
                            contactMethods: [],
                            notes: []
                        });
                    }
                };
                return (
                    <div key={role} style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
                        <IonButton style={{width: '100%', marginBottom: 10}} fill={fill} data-role-id={roleId} onClick={onClick}>{role}</IonButton>
                    </div>
                );
            })}
        </Step>
    );
};
