import { ISupportGroupKey } from '../caremap/ICareMap';
import { ILabelKey } from '../config/LabelProvider';

interface ICustomTypeAddedMeta {
    // eg, school, household, patient
    category: string;

    // eg relationship, role
    // wanted this to be things like "advocacy" based on the question
    // I felt this would be very hard to reason about technically
    // and so field was added for technical reasons, so we can still tell what field we're talking about
    field: string;

    // see comment above, this is a label product wants in mixpanel, that is associated with the label the user saw
    // that doesn't change, when the user facing label changes
    fieldLabel: string;

    // this is the value they typed in, eg MiMa for relationship
    entry: string;

    // this is where the search field was used, driven by product
    origin: string;
}

class CustomTypeAddedMetaBuilderWithField<T extends string>{
    constructor(private meta: Partial<ICustomTypeAddedMeta>){
        if (!meta){
            this.meta = {};
        }
    }
    field = ({ field, fieldLabelKey, value }: { field: T; fieldLabelKey: ILabelKey; value: string; }) => {
        this.meta.field = field;
        this.meta.fieldLabel = this.getMixPanelFielLabel(fieldLabelKey);
        this.meta.entry = value;
        return this;
    };

    private getMixPanelFielLabel = (fieldLabelKey: ILabelKey): string => {

        const mapping: { fieldLabelKey: ILabelKey; mixPanelFieldLabel: string; }[] = [
            { fieldLabelKey: 'CARE_RECIPIENT_RELATIONSHIP_TO_CARE_MAP_CREATOR', mixPanelFieldLabel: 'Creator’s Relation to Care Recipient' },
            { fieldLabelKey: 'HOUSEHOLD_MEMBER__RELATIONSHIP', mixPanelFieldLabel: 'Household Relationship Type' },
            { fieldLabelKey: 'SUPPORT_GROUP__SCHOOL__ROLE', mixPanelFieldLabel: 'School Member Type' },
            { fieldLabelKey: 'SUPPORT_GROUP__MEDICAL__ROLE', mixPanelFieldLabel: 'Care Member Type' },
            { fieldLabelKey: 'SUPPORT_GROUP__SPECIALISTS__ROLE', mixPanelFieldLabel: 'Specialist Type' },
            { fieldLabelKey: 'SUPPORT_GROUP__COMMUNITY__ROLE', mixPanelFieldLabel: 'Community Support Type' },
            { fieldLabelKey: 'SUPPORT_GROUP__FINANCIAL__ROLE', mixPanelFieldLabel: 'Financial Support Type' },
            { fieldLabelKey: 'SUPPORT_GROUP__COMMUNITY__ROLE_EXTRA_CURRICULAR', mixPanelFieldLabel: 'Community Activity Type' },
            { fieldLabelKey: 'SUPPORT_GROUP__COMMUNITY__ROLE_ADVOCACY', mixPanelFieldLabel: 'Community Group Type' },
            { fieldLabelKey: 'SUPPORT_GROUP__COMMUNITY__ROLE_GENERAL', mixPanelFieldLabel: 'Community Support Type' },
            { fieldLabelKey: 'CARE_RECIPIENT_DIAGNOSIS', mixPanelFieldLabel: 'Care Recipient Diagnosis'},
            { fieldLabelKey: 'CARE_RECIPIENT_ALLERGY', mixPanelFieldLabel: 'Care Recipient Allergy'}
        ];

        const match = mapping.find(i => i.fieldLabelKey === fieldLabelKey);
        if (!match){
            throw new Error(`${fieldLabelKey} is not mapped to a mix panel label`);
        }

        return match.mixPanelFieldLabel;
    };

    build = (): ICustomTypeAddedMeta => {
        const { origin, field, fieldLabel, entry, category } = this.meta;
        if ([origin, field, fieldLabel, entry, category].some(v => !v)){
            throw new Error(`cannot build because all fields are required ${JSON.stringify(this.meta)}`);
        }
        return this.meta as ICustomTypeAddedMeta;
    };
}

/**
 * It is error prone to build the meta, and doing it purely with types didn't feel right (I started there)
 * so this builder helps ensure you mix and match things right, eg makes it impossible to do  category: household, field: role
 */
export class CustomTypeAddedMetaBuilder {
    private meta: Partial<ICustomTypeAddedMeta> = {};
    origin = (origin: 'caremap' | 'questionnaire') => {
        this.meta.origin = origin;
        return this;
    };
    patient = () => {
        this.meta.category = 'patient';
        return new CustomTypeAddedMetaBuilderWithField<'diagnosis' | 'allergy'>(this.meta!);
    };
    household = () => {
        this.meta.category = 'household';
        return new CustomTypeAddedMetaBuilderWithField<'relationship'>(this.meta!);
    };

    supportGroup = (supportGroup: ISupportGroupKey) => {
        this.meta.category = supportGroup;
        return new CustomTypeAddedMetaBuilderWithField<'role'>(this.meta!);
    };

    creator = () => {
        this.meta.category = 'creator';
        return new CustomTypeAddedMetaBuilderWithField<'relationship'>(this.meta!);
    };
}
