import { FC } from 'react';
import { IDiagnosis } from '../caremap/ICareMap';
import { DrawerSectionHeading } from '../components/DrawerSectionHeading';
import { createUseStyles } from 'react-jss';
import { ClipboardListIcon } from '../theme/Icons';
import { PrivacyBadge } from '../components/PrivacyBadge';
import { IonText } from '@ionic/react';
import { AppPrincipal, isCaremapViewer } from '../auth/AppPrincipal';
import { useInstance } from '@meraki-internal/react-dependency-injection';

const useStyles = createUseStyles({
    listWrapper: {
        paddingLeft: 16
    },

    row: {
        display: 'flex'
    },

    icon: {
        padding: '0px 8px 8px 0px'
    },

    diagnosis: {
        flexGrow: 1,
        paddingTop: 1
    }
});

export type IDiagnosisList = {
    diagnoses?: IDiagnosis[];
    isPublic?: boolean;
};

export const DiagnosisList: FC<IDiagnosisList> = ({ diagnoses, isPublic = false }) => {
    const styles = useStyles();
    const isViewer = isCaremapViewer(useInstance(AppPrincipal));

    if (!isPublic && isViewer) {
        return null;
    }

    return (
        <div data-type="diagnoses-list">
            <DrawerSectionHeading text="Diagnoses">
                {!isPublic && (
                    <div style={{marginRight: 38}}>
                        <PrivacyBadge data-type="diagnoses-private-badge" />
                    </div>
                )}
            </DrawerSectionHeading>
            <div className={styles.listWrapper}>
                {(!diagnoses || diagnoses.length === 0) && (
                    <IonText color="medium" style={{paddingLeft: 12}}>
                        No diagnoses
                    </IonText>
                )}
                {diagnoses?.map((diagnosis, idx) => (
                    <div key={`${diagnosis.diagnosisId}-${idx}`} className={styles.row}>
                        <div className={styles.icon}><ClipboardListIcon color="mediumtext" /></div>
                        <div className={styles.diagnosis}>{diagnosis.diagnosis}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
