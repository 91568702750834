import { householdMemberAvatars } from '../caremap/CareMapFactory';
import { CareMapState } from '../caremap/CareMapState';

export class DefaultAvatarProvider {
    getDefaultAvatar = ({ careMap }: { careMap: CareMapState; }): string => {
        const state = careMap.getActiveCaremap();

        const avatarsInUse = [
            state?.patient.avatar,
            ...(state?.household.members || []).map(mem => mem.avatar)
        ];
        const unusedAvatars = householdMemberAvatars.filter(a => !avatarsInUse.includes(a));
        return unusedAvatars[0] || householdMemberAvatars[0];
    };
}
