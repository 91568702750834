import { APIClient } from '@innerhive-internal/innerhive-api-client';
import { State } from '@meraki-internal/state';
import { AlertPresenter } from '../../AlertBinder';

export interface IUploadingFile{
    // this is a reference so we can keep track (eg if more than one have the same name)
    file: File;

    fileName: string;

    // we get this when we get a s3 signed url from our API
    contentType?: string;
    url?: string;

    // we set this to true once we upload to the API
    isUploaded?: boolean;

    // then record is removed once it is resolved to the caller that ALL files are done
}

export type IUploadCompleteInfo = {
    key: File;
    update: Partial<IUploadingFile>;
};
export class FileUploader extends State<Record<string, never>> {
    static inject = () => [
        APIClient,
        AlertPresenter
    ];
    constructor(
        private apiClient: APIClient,
        private alert: AlertPresenter
    ){
        super({ });
    }

    uploadingFiles: IUploadingFile[] = [];

    private updateFile = ({ key, update }: IUploadCompleteInfo) => {
        const match = this.uploadingFiles.find(f => f.file === key);
        if (!match){
            throw new Error('trying to update file we are not tracking');
        }
        const rest = this.uploadingFiles.filter(f => f.file !== key);
        this.uploadingFiles = [
            ...rest,
            {
                ...match,
                ...update
            }
        ];
        this.setState({ });
    };

    upload = async (files: File[], onUploadComplete?: ({ key, update }: IUploadCompleteInfo) => void) => {
        this.uploadingFiles = [
            ...this.uploadingFiles,
            ...files.map(file => ({
                file,
                fileName: file.name
            }))
        ];
        this.setState({ });

        const entry = await this.apiClient.entry();

        const response: { fileName: string; contentType: string; url: string; }[] = [];
        for(const file of files){
            try {
                const { uploadUrl, cdnUrl, headers, contentType } = await this.apiClient.post(entry.links.uploadsV2, { fileName: file.name });

                this.updateFile({ key: file, update: {
                    contentType,
                    url: cdnUrl
                }});

                const res = await window.fetch(uploadUrl, {
                    method: 'PUT',
                    body: file,
                    headers,
                });

                if (res.status !== 200){
                    throw new Error(`Upload failed with status ${res.status}`);
                }

                const uploadedInfo: IUploadCompleteInfo = {
                    key: file,
                    update: {
                        isUploaded: true
                    }
                };

                this.updateFile(uploadedInfo);

                if (onUploadComplete) {
                    onUploadComplete(uploadedInfo);
                }

                response.push({ fileName: file.name, url: cdnUrl, contentType });
            }
            catch (err){
                this.alert.showAndLogError(err);

                // remove the file that failed, b/c it is NOT uploading anymore
                this.uploadingFiles = this.uploadingFiles.filter(f => f.file !== file);
                this.setState({ });
            }
        }

        // remove the files as we're not tracking them anymore
        this.uploadingFiles = this.uploadingFiles.filter(f => !files.includes(f.file));
        this.setState({ });

        return response;
    };

}
