import { FC, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { IonInput, IonTextarea, isPlatform } from '@ionic/react';
import { IonTextareaCustomEvent, TextareaInputEventDetail, IonInputCustomEvent, InputInputEventDetail } from '@ionic/core';
import { INote } from './INote';
import { IAttachment } from './attachments/IAttachment';
import { PrivateCheckbox } from '../components/PrivateCheckbox';
import { Menu, IAction } from '../components/Menu';
import { DeleteIcon, PaperclipIcon, UploadIcon } from '../theme/Icons';
import { AttachmentList } from './attachments/AttachmentList';
import { DrawerSectionHeading } from '../components/DrawerSectionHeading';
import { Divider } from '../components/Divider';
import { useDropzone } from 'react-dropzone';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { FileUploader, IUploadCompleteInfo, IUploadingFile } from './attachments/FileUploader';
import { INoteTarget } from './INoteTarget';
import { getNoteTrackingLocation } from './NotesTrackingHelper';
import { MixPanelEventEmitter as MixpanelService } from '../metrics/MixPanelEventEmitter';
import { PaywallAwareButton } from '../components/PaywallAwareButton';
import { IFreeTierLimits } from '../innerhive-plus/revenue-cat/IFreeTierLimits';
import { NoteTemplatePicker } from './note-templates/NoteTemplatePicker';
import { TemplateProvider } from './note-templates/TemplateProvider';

const maxFileSizeMB = 50;
// this is the actual file size we validate against
// with some padding, so that a ${maxFileSizeMB}MB file on disk, that is say ${maxFileSizeMB}.01 MB in memory
// when we validate it, doesn't throw
const maxFileSize = (maxFileSizeMB + .5) * 1024 * 1024;

const isDesktop = isPlatform('desktop');
const useStyles = createUseStyles({
    dropZone: {
        padding: 2,
        borderRadius: 5,
        border: '2px dashed transparent'
    },

    dragAccept: {
        padding: 2,
        borderRadius: 5,
        border: '2px dashed var(--ion-color-success)'
    },

    browseLink: {
        display: 'flex',
        cursor: 'pointer',
        color: 'var(--ion-color-primary)'
    },

    notesTextArea: {
        flex: 1,
        display: 'flex',

        // 0 normal border, and important for focus border
        '--border-width': '0px !important',
        '--padding-top': '8px',
        // because it is not in an ion-label it gets padding-start 0, with higher specificity
        // hence !important
        '--padding-start': '0px !important',
        '--padding-end': '0px !important',
        '--padding-bottom': '12px !important',

        '& > label.textarea-wrapper': {
            flex: 1
        }
    },

    attachmentArea: isDesktop ? {
        minHeight: 170,

        '& hr': {
            width: '100%',
            marginBlockStart: -6
        },

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    } : {
        minHeight: 70,
        paddingBottom: 6,

        '& hr': {
            marginBlockStart: -6
        }
    },

    addAttachments: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',

        '& > div': {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }
});

export type INoteEditProps = {
    note: INote;
    isAdd?: boolean;
    canEdit: boolean;
    noteTarget: INoteTarget;
    hasDeleteLink?: boolean;
    hasPrivateCheckbox?: boolean;
    hasTitle?: boolean;
    addAttachmentRequiresInnerhivePlus?: (limits: IFreeTierLimits) => boolean;
    noteChanged: (updatedNote: INote) => void;
    noteDeleted?: (deletedNote: INote) => void;
};

export const NoteEdit: FC<INoteEditProps> = ({
    note,
    isAdd,
    canEdit,
    noteTarget,
    noteChanged,
    noteDeleted,
    hasDeleteLink,
    hasPrivateCheckbox,
    hasTitle,
    addAttachmentRequiresInnerhivePlus
}) => {
    const classes = useStyles();
    const textareaRef = useRef<HTMLIonTextareaElement>(null);

    const uploader = useInstance(FileUploader);
    useSubscription(() => uploader);

    const tracking = useInstance(MixpanelService);

    const templates = useInstance(TemplateProvider).getTemplates(noteTarget);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isAdd && (isPlatform('desktop') || isPlatform('capacitor')) && textareaRef.current) {
                textareaRef.current.setFocus();
            }
        }, 350);
        return () => clearTimeout(timer);
    }, [isAdd]);

    const onUploadComplete = (fileInfo: IUploadCompleteInfo) => {
        tracking.track(
            'Note File Uploaded',
            () => {
                return {
                    location: getNoteTrackingLocation(noteTarget),
                    type: fileInfo.key.name.substring(fileInfo.key.name.lastIndexOf('.')) || ''
                };
            }
        );
    };

    const onDrop = async (files: File[]) => {
        const uploadedFiles = await uploader.upload(files, onUploadComplete);
        noteChanged({
            ...note,
            attachments: [
                ...note.attachments,
                ...uploadedFiles
            ]
        });
    };

    const {getRootProps, getInputProps, open, isDragAccept} = useDropzone({
        noClick: true,
        noKeyboard: true,
        maxSize: maxFileSize,
        onDropAccepted: onDrop
        /*maxFiles: 1,
        multiple: false,
        accept: acceptedFileTypes,
        onDropRejected: rejected, */
    });

    // currently, if notes are private and you can't edit, then you also can't view them,
    // in which case we want to render nothing to avoid wasted whitespace in the drawer
    if (note.isPrivate && !canEdit) {
        return null;
    }

    const onNoteTitleChanged = (e: IonInputCustomEvent<InputInputEventDetail>) => {
        const title = e.target.value as string || '';
        noteChanged({...note, title: title });
    };

    const onNoteHtmlChanged = (e: IonTextareaCustomEvent<TextareaInputEventDetail>) => {
        noteChanged({...note, html: e.target.value || '' });
    };

    const onPrivacyChanged = (isPrivate: boolean) => {
        noteChanged({...note, isPrivate });
    };

    const deleteNote = () => {
        noteDeleted!(note);
    };

    const uploadingAttachments: IUploadingFile[] = uploader.uploadingFiles.filter(f => !f.isUploaded);
    const attachments: IAttachment[] = [
        ...note.attachments,
        // and files that are uploaded, but haven't been saved to the note yet,
        // because we are waiting for the rest to finish
        ...uploader.uploadingFiles.filter(f => f.isUploaded).map((f) => ({
            fileName: f.fileName,

            // we know we have these (!) since isUploaded === true
            contentType: f.contentType!,
            url: f.url!
        }))
    ];
    const hasAttachmentsOrUploading = attachments.length || uploadingAttachments.length;

    const actions: IAction[] = [];
    if (hasDeleteLink) {
        actions.push({ Icon: DeleteIcon, role: 'destructive', text: `Delete Note`, handler: deleteNote, 'data-type': 'delete-button' });
    }

    const attachmentList = (
        <AttachmentList
            note={note}
            canEdit={canEdit}
            noteTarget={noteTarget}
            noteChanged={noteChanged}
            attachments={attachments}
            uploadingAttachments={uploadingAttachments} />
    );

    const hasTemplates = templates.length > 0;
    const textAreaPlaceholder = hasTemplates ? 'Or, just start writing...' : 'Start writing here…';

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                {hasPrivateCheckbox && canEdit &&
                    <div style={{height: 36}}>
                        <PrivateCheckbox data-id="notes-visibility-checkbox" checked={note.isPrivate || false} onChange={onPrivacyChanged} />
                    </div>
                }
                {actions.length > 0 &&
                    <div style={{marginTop: -8, height: 36}}>
                        <Menu actions={actions} />
                    </div>
                }
            </div>

            {hasTemplates && (
                <div style={{display: 'flex', marginLeft: -17}}>
                    <NoteTemplatePicker
                        note={note}
                        noteTarget={noteTarget}
                        currentTextareaValue={textareaRef.current?.value}
                        onTemplateClicked={({body, focusLocation}, append) => {
                            noteChanged({ ...note, html: append ? `${textareaRef.current!.value}\n\n${body}` : body });
                            setTimeout(async () => {
                                const inputEl = await textareaRef.current!.getInputElement();
                                textareaRef.current!.setFocus();
                                inputEl.setSelectionRange(
                                    focusLocation || inputEl.value.length,
                                    focusLocation || inputEl.value.length
                                );

                                // otherwise focus at the end and scroll to bottom
                                inputEl.scrollTo({top: !append && focusLocation ? 0 : inputEl.scrollHeight});
                            }, 300);
                        }}
                    />
                </div>
            )}

            {hasTitle &&
                <IonInput
                    name="title"
                    placeholder="Title"
                    value={note.title}
                    disabled={!canEdit}
                    onIonInput={onNoteTitleChanged}
                />
            }

            <IonTextarea
                fill="outline"
                data-id="notes"
                ref={textareaRef}
                // debounce?
                value={note.html}
                disabled={!canEdit}
                className={classes.notesTextArea}
                onIonInput={onNoteHtmlChanged}
                placeholder={canEdit ? textAreaPlaceholder : ``}
            />

            {/* don't show if they can't edit (is a viewer) and there are no attachments to view */}
            {(canEdit || attachments.length > 0 || uploadingAttachments.length > 0) && (
                <div className={classes.attachmentArea}>
                    <DrawerSectionHeading text="Attachments">
                        <PaywallAwareButton
                            data-id="add-attachment-button"
                            requiresInnerhivePlus={addAttachmentRequiresInnerhivePlus}
                            fill="clear"
                            onClick={open}
                            triggerSource="attachment limit"
                        >
                            <PaperclipIcon />&nbsp;Add
                        </PaywallAwareButton>
                    </DrawerSectionHeading>

                    <Divider />

                    <div
                        data-id="add-attachments"
                        style={isDesktop ? {marginBottom: 6} : {display: 'none'}}
                        className={!hasAttachmentsOrUploading && isDesktop ? classes.addAttachments : undefined}
                    >
                        <div {...getRootProps({className: isDragAccept ? classes.dragAccept : classes.dropZone})}>
                            <input {...getInputProps()} data-type="dropzone" />

                            {!hasAttachmentsOrUploading && (
                                <>
                                    <div className={classes.browseLink} style={{display: 'inline-block'}} onClick={open}>
                                        <UploadIcon size="medium" />
                                    </div>
                                    <div style={{marginLeft: 4, display: 'inline-block'}}>
                                        Drag and drop or <div className={classes.browseLink} style={{display: 'inline-block'}} onClick={open}>Add</div>
                                    </div>
                                </>
                            )}

                            {attachmentList}
                        </div>
                    </div>

                    {/* on mobile, since the add-attachments area is hidden, show the attachment list */}
                    {!isDesktop && attachmentList}
                </div>
            )}
        </>
    );
};
