import { IAuthenticationClientRedirecter } from '@innerhive-internal/innerhive-api-client';
import { Browser } from '@capacitor/browser';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Logger } from '../support/Logger';
import { EnvConfiguration } from '../config/EnvConfiguration';
import { isPlatform } from '@ionic/react';

export class BrowserAuthenticationClientRedirecter implements IAuthenticationClientRedirecter {
    static inject = () => [EnvConfiguration, Logger];
    constructor(private config: EnvConfiguration, private log: Logger){}

    private isRedirecting = false;

    private redirectWithIOSASWebAuthenticationSession = async ({ urlWithoutQueryParams, queryParams }: { urlWithoutQueryParams: string, queryParams: URLSearchParams }): Promise<boolean> => {
        try {
            const appScheme = 'com.innerhive.app';

            const redirectURIHash = queryParams.get('redirect_uri')!.split('#')[1]!;

            queryParams.set('redirect_uri', `${appScheme}://#${redirectURIHash}`);

            const urlWithAppSchemeRedirect = `${urlWithoutQueryParams}?${queryParams.toString()}`;

            this.log.info('ASWebAuthSession.start', { urlWithAppSchemeRedirect });

            const callbackUrl: string = await new Promise((resolve, reject) => (window as any).plugins.ASWebAuthSession.start(appScheme, urlWithAppSchemeRedirect, resolve, reject));
            const hash = decodeURI(callbackUrl.split('com.innerhive.app://')[1]);

            this.log.info('ASWebAuthSession response', { callbackUrl, hash });

            // in the unexpected case that the url is not the expected format,
            // ignore it and let the reload take care of the auth state
            if (hash) {
                window.location.hash = hash;
            }

            // warning: make sure there is no async code between setting hash and reloading
            // (otherwise the hash change will be detected by the router and discarded)
            window.location.reload();

            // we could return true here to indicate success to the caller,
            // but we are reloading the app, so best to never resolve
            await new Promise(resolve  => {});

            // never get here, but need to make ts happy
            return true;

        } catch (e: any) {
            // unfortunately, e is always "error" so we can't determine what went wrong,
            // most likely auth flow was aborted by the user, so just do nothing
            return false;
        }
    };

    redirectWithInAppBrowser = async ({ urlWithoutQueryParams, queryParams }: { urlWithoutQueryParams: string, queryParams: URLSearchParams }) => {
        // the app will see this host, close the in app browser and trigger 'appUrlOpen'
        // (this also needs to be recognized by the api as a valid callback)
        const redirectBaseUrl = (this.config.ENV === 'live' ? 'https://app.innerhive.com' : 'https://app.staging.innerhive.com');

        const redirectURIHash = queryParams.get('redirect_uri')!.split('#')[1]!;

        queryParams.set('redirect_uri', `${redirectBaseUrl}/#${redirectURIHash}`);

        const urlWithRedirect = `${urlWithoutQueryParams}?${queryParams.toString()}`;

        return await new Promise<boolean>(resolve => {
            const appUrlListenerPromise = App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
                // auth flow successfully completed
                // example url: https://app.innerhive.com/#/caremap, path = #/caremap
                const path = event.url.split('.innerhive.com/').pop();

                this.log.info('appUrlOpen', { eventUrl: event.url, path });

                // in the unexpected case that the url is not the expected format,
                // ignore it and let the reload take care of the auth state
                if (path) {
                    window.location.hash = path;
                }

                // warning: make sure there is no async code between setting hash and reloading
                // (otherwise the hash change will be detected by the router and discarded)
                window.location.reload();

                // we could resolve(true) here to indicate success to the caller,
                // but we are reloading the app, so best to never resolve
            });

            const browserListenerPromise = Browser.addListener('browserFinished', () => {
                // user aborted without completing sign-in
                resolve(false);

                appUrlListenerPromise.then(listener => listener.remove);
                browserListenerPromise.then(listener => listener.remove);
            });

            this.log.info('Browser.open', { urlWithRedirect });

            // need a timeout here, otherwise on first app start after install, browserFinished
            // event fires immediately, causing the user to have to log in twice (but keeping
            // this timeout as short as possible to avoid significantly delaying every login)
            setTimeout(() => {
                Browser.open({ url: urlWithRedirect, windowName: '_self' });
            }, 1000);
        });
    };

    redirect = async (url: string): Promise<boolean> => {
        this.log.info('BrowserAuthenticationClientRedirecter.redirect', { url });

        // TODO: find root cause of intermittent double auth dialog on ios
        // in the meantime, block indefinitely to suppress it
        if (this.isRedirecting) {
            this.log.info('Blocked attempted auth redirect while already redirecting');
            return await new Promise(resolve  => {});
        }

        try {
            this.isRedirecting = true;

            const [ urlWithoutQueryParams, queryParamsString] = url.split('?');
            const queryParams = new URLSearchParams(queryParamsString);

            const isRedirectingToAuth0ForLogin = url.includes('innerhive.com/v1/oauth/authorize');

            if (isPlatform('capacitor') && isPlatform('ios') && isRedirectingToAuth0ForLogin){
                return await this.redirectWithIOSASWebAuthenticationSession({ urlWithoutQueryParams, queryParams });
            }
            else if (isPlatform('capacitor') && isPlatform('android') && isRedirectingToAuth0ForLogin){
                return await this.redirectWithInAppBrowser({ urlWithoutQueryParams, queryParams });
            }
            else {
                window.location.href = url;
                // block indefinitely (so that further code can't run while we're trying to redirect)
                return await new Promise(resolve  => {});
            }

        } finally {
            this.isRedirecting = false;
        }
    };
}
