import { IonButton } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { useSubscription } from '@meraki-internal/state';
import { CareMapState } from '../caremap/CareMapState';
import { ISupportGroupKey } from '../caremap/ICareMap';
import { IStepValidProps, Step } from './Step';
import { CareMapFactory } from '../caremap/CareMapFactory';
import { useEffect } from 'react';

export const CommonHealthInsuranceStep: React.FC<IStepValidProps> = ({ setStepValidity }) => {
    const careMap = useInstance(CareMapState);
    useSubscription(() => careMap);

    const options = [
        { name: 'Medicaid' },
        { name: 'Medicare' },
        { name: 'Private Insurance' },
        { name: 'State' },
    ];
    const careMapFactory = useInstance(CareMapFactory);
    const supportGroupKey: ISupportGroupKey = 'financial';
    const roleId = 'health-insurance';

    useEffect(() => {
        // selection on this step is optional, so always valid
        setStepValidity(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Step question={careMap.getLabel('HAVE_HEALTH_INSURANCE')}>
            {options.map(({ name }) => {
                const member = careMap.getActiveCaremap()!.financial.members.find(m => m.roleId === roleId && m.name === name);

                const fill: 'outline' | 'solid' =  member ? 'solid' : 'outline';
                const onClick = () => {
                    if (member){
                        careMap.removeSupportGroupMember({ supportGroupKey, memberId: member.id });
                    }
                    else {
                        careMap.addSupportGroupMember(supportGroupKey, {
                            ...careMapFactory.createSupportGroupMember(supportGroupKey, roleId),
                            name
                        });
                    }
                };
                return (
                    <div key={name} style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
                        <IonButton
                            fill={fill}
                            onClick={onClick}
                            style={{ width: '100%'}}
                            data-id={`wizard-financial-${name.toLocaleLowerCase()}-button`}
                        >{name}</IonButton>
                    </div>
                );
            })}
        </Step>
    );
};
