import { useInstance } from '@meraki-internal/react-dependency-injection';
import { OrganizationsState } from '../OrganizationsState';
import { TransferClientsModal } from '../TransferClientsModal';
import { AlertPresenter } from '../../AlertBinder';

interface IRemoveTeamMemberWithClientsModalProps {
    orgId: string;
    memberId: string;
    onRemoved: () => void;
    onCancel: () => void;
}

export const RemoveTeamMemberWithClientsModal: React.FC<IRemoveTeamMemberWithClientsModalProps> = ({ orgId, memberId, onRemoved, onCancel }) => {
    const orgs = useInstance(OrganizationsState);
    const alertPresenter = useInstance(AlertPresenter);
    const onTransferred = async () => {
        const user = orgs.getOrganizationUsers(orgId).find(u => u.userId === memberId);
        if(!user){
            throw new Error(`user ${memberId} as not found`);
        }
        try {
            await orgs.removeUser(user);
            onRemoved();
        }
        catch (err: any){
            alertPresenter.showAndLogError(err);
        }

    };
    return (
        <TransferClientsModal
            orgId={orgId}
            fromMemberId={memberId}
            onTransferred={onTransferred}
            title="Transfer Clients and Remove Member"
            transferButtonLabel="Remove"
            closeHandler={onCancel}
        />
    );
};
