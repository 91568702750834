import { FC, Ref } from 'react';
import {
    IonInput, isPlatform
} from '@ionic/react';
import { ITextInputProps } from './ITextInputProps';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    readOnly: {
        color: 'initial',
        opacity: '1 !important',
        '--border-color': 'transparent !important',

        // native devices need more
        '& input': {
            color: 'initial',
            opacity: '1 !important',
        }
    }
});

type ITextFieldProps = ITextInputProps & {
    inputRef?: Ref<HTMLIonInputElement>;
    autoFocus?: boolean;
    blurOnEnter?: boolean;
};

export const TextField: FC<ITextFieldProps> = ({
    name,
    label,
    labelPlacement = 'stacked',
    value,
    placeholder,
    'data-type': dataType,
    onChange = () => {},
    onInput = () => {},
    inputRef,
    readOnly,
    autoFocus,
    autocapitalize,
    enterkeyhint,
    blurOnEnter = false
}) => {
    const classes = useStyles();

    // don't autofocus on mobile browser
    const canAutoFocus = () => {
        return autoFocus && (isPlatform('desktop') || isPlatform('capacitor'));
    };

    const onKeyUp = (ev: React.KeyboardEvent<HTMLIonInputElement>) => {
        if (blurOnEnter && isPlatform('capacitor') && ev.code === 'Enter') {
            (ev.nativeEvent?.target as HTMLIonInputElement).blur();
        }
    };

    enterkeyhint = !enterkeyhint && blurOnEnter ? 'done' : enterkeyhint;

    return (
        <IonInput
            ref={inputRef}
            name={name}
            value={value}
            label={label}
            fill="outline"
            enterkeyhint={enterkeyhint}
            data-type={dataType}
            placeholder={placeholder}
            labelPlacement={labelPlacement}
            autocapitalize={autocapitalize}
            autofocus={canAutoFocus()}
            onKeyUp={onKeyUp}
            disabled={readOnly}
            className={readOnly ? classes.readOnly : undefined}
            onIonChange={(e) => onChange(e.detail.value || '')}
            onIonInput={(e) => onInput(e.detail.value || '')}
        />
    );
};
