import { useEffect, useState } from 'react';
import { IBreakpoint, mediaMaxWidth, mediaMinWidth } from './utils';

export const useBreakpoints = () => {

    const [, setSizeChangeCount] = useState(0);

    useEffect(() => {
        const increment = () => setSizeChangeCount(c => c + 1);
        window.addEventListener('resize', increment);
        return () => window.removeEventListener('resize', increment);
    }, []);

    return {
        isAboveBreakpoint: (breakpoint: IBreakpoint) => {
            if ((window as any).matchMedia) {
                const mediaQuery = mediaMinWidth[breakpoint].substring(7);
                return window.matchMedia(mediaQuery).matches;
            }
            return false;
        },
        isBelowBreakpoint: (breakpoint: IBreakpoint) => {
            if ((window as any).matchMedia) {
                const mediaQuery = mediaMaxWidth[breakpoint].substring(7);
                return window.matchMedia(mediaQuery).matches;
            }
            return false;
        }
    };
};
