import { ISupportGroupKey } from '../caremap/ICareMap';

export class SupportGroupKeySorter {
    sort = (map: { [key in ISupportGroupKey]: number }): ISupportGroupKey[] => {
        const keys = Object.keys(map) as ISupportGroupKey[];
        return keys
            .map(key => ({
                key,
                sortOrder: map[key]
            }))
            .sort((l, r) => l.sortOrder - r.sortOrder)
            .map(i => i.key);
    };
}
