import { IonButton, useIonAlert } from '@ionic/react';
import { useInstance } from '@meraki-internal/react-dependency-injection';
import { CareMapState } from '../caremap/CareMapState';
import { useSubscription } from '@meraki-internal/state';
import { ISupportGroupKey } from '../caremap/ICareMap';
import { HistoryViewModel } from '../support/HistoryViewModel';

export const DeleteSupportGroupButton: React.FC<{ supportGroupKey: ISupportGroupKey; }> = ({ supportGroupKey }) => {
    const careMapStateInstance = useInstance(CareMapState);
    const history = useInstance(HistoryViewModel);
    useSubscription(() => careMapStateInstance);
    const [presentAlert] = useIonAlert();

    const labels = careMapStateInstance.getSupportGroupLabels(supportGroupKey);

    const onDelete = () => {
        const destructiveRole = 'destructive';
        presentAlert({
            header: `Delete ${labels.title}?`,
            message: `The ${labels.title} category will be removed from the care map. You can always add it back later.`,
            cssClass: 'drawer-alert',
            buttons: [{
                text: 'Delete',
                role: destructiveRole,
                handler: () => {
                    // delete it
                    careMapStateInstance.removeSupportGroup({ supportGroupKey });

                    // close the drawer
                    history.push(`/caremaps/${careMapStateInstance.getActiveCaremap()?.careMapId}`);
                }
            }, {
                text: 'Cancel',
                role: 'cancel'
            }]
        });
    };


    return (
        <IonButton
            data-type="delete-support-group-button"
            fill="outline"
            color="danger"
            onClick={onDelete}
        >
            Delete {labels.title}
        </IonButton>
    );
};
